export default {
  title: 'Inactive Providers',

  titles: {
    fix: 'Creating duplicates of inactive providers',
    confirm: {
      update: 'Confirm update',
      cloneProvider: 'Confirm clone'
    }
  },

  labels: {
    update: {
      check: 'I have read and understood the confirmation text and want to update the list of inactive providers',
      showUpdateDialog: 'I am aware of what I am doing, do not show me this dialog anymore. Remember only for this browser.'
    }
  },

  contents: {
    confirm: {
      update: 'Are you sure you want to update the list of inactive providers?<br><br>The current list will be reset and a number of new requests will be made to form a new list, which may take some time and negatively affect the performance of the platform. Make this request no more than once every 5 minutes.<br><br>If something does not work or you think that it works incorrectly, contact the developers, do not continue to make requests.',
      cloneProvider: 'A clone of the provider will be created, with all properties, but with a different name. No actions will be taken with the current provider. After creation, an automatic transition to the card of the new provider will be made.'
    }
  },

  tooltips: {
    fix: 'After clicking on this button, a clone of the selected provider will be created. All properties of the old provider will be copied, name, server, credentials, and so on. The provider will be created immediately activated (isActive === true).<br>The provider from which the clone was created will be deactivated (isActive = false) and removed from the group of providers.<br>In its place, with the same priority, a new provider will be inserted.',
    cloneProvider: 'A clone of the provider will be created, with all properties, but with a different name. No actions will be taken with the current provider. After creation, an automatic transition to the card of the new provider will be made.'
  },

  warnings: {
    general: 'This is a page for searching groups of providers with inactive providers inside. The page is experimental!<br>All buttons on this page somehow make very complex and heavy requests to the platform, so please do not click on them more often than one cup of coffee along with a trip to the coffee machine.<br>If something does not work or you think that it works incorrectly, contact the developers, do not continue to make requests.'
  }
}
