import proxy from '@sigma-legacy-libs/g-proxy'

import { isEqual } from 'lodash'

import { isEmpty } from '@/utils'

import render from './render'

export default function(options) {
  return {
    name: 'PyrusCredentials',
    mixins: [ proxy({ type: 'object' }) ],

    computed: {
      disabled() {
        if (Object.values(this.proxy).some(isEmpty)) {
          return true
        }
        if (isEqual(this.proxy, this.value)) {
          return true
        }

        return false
      },

      clearDisabled() {
        return Object.values(this.proxy).every(isEmpty)
      }
    },

    methods: {
      watchProxyHandler() {
        return
      },
      receiveValue() {
        return
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
