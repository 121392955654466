export * from './millisecondsToHammerTime'
export * from './msToTime'

export const camelCaseToKebabCase = value => value.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()

export const numberToPxOrString = value => {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return undefined
  }

  return typeof value === 'string' ? value : `${value}px`
}
