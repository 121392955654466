import Vue from 'vue'
import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import { cloneDeep } from 'lodash'

import rulesConditionsRow from '@/components/misc/rules/conditions/row'
import rulesConditionsDialog from '@/components/misc/rules/conditions/dialog'

function renderDialog(h) {
  return h(
    rulesConditionsDialog({ tag: this.tag }),
    {
      props: {
        show: this.showDialog,
        value: cloneDeep(this.proxy[this.tag]),
        tag: this.tag
      },
      on: {
        dialog: event => {
          this.showDialog = event
        },
        input: event => {
          Vue.set(this.proxy, this.tag, event)
        },
        tag: event => {
          this.tag = event
        }
      }
    }
  )
}

function renderRow(h, tag) {
  return h(
    rulesConditionsRow,
    {
      props: {
        tag,
        value: this.proxy[tag]
      },
      on: {
        dialog: event => {
          this.tag = tag
          this.showDialog = event
        },
        clear: () => {
          Vue.delete(this.proxy, tag)
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        title: this.getTranslate('rules.titles.conditions'),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      Conditions.tagsOnly.filter(this._tagFilter).map(tag => renderRow.call(this, h, tag)),
      renderDialog.call(this, h)
    ]
  )
}
