import FileType from '@/components/file/type'
import FileMenu from '@/components/file/menu'
import FileInfo from '@/components/file/info'

function renderMenu(h) {
  if (this.$slots.default) {
    return this.$slots.default
  }

  if (this.displayMenu) {
    return h(
      FileMenu,
      {
        props: {
          value: this.file,
          readonly: this.readonly,
          include: this.include
        },
        on: {
          dialog: event => {
            this.$emit('dialog', event)
          }
        }
      }
    )
  }
}

function renderType(h) {
  return h(
    FileType,
    {
      class: 'mr-2',
      props: {
        value: this.file,
        small: this.small,
        readonly: this.readonly
      }
    }
  )
}

function renderInfo(h) {
  if (!this.compact) {
    return h(
      FileInfo,
      {
        class: 'pr-2',
        props: {
          value: this.file,
          readonly: this.readonly,
          includeOwner: !this.small
        }
      }
    )
  }
}

export default function(h) {
  if (this.file && this.file.id) {
    return h(
      'g-card',
      {
        class: {
          'pa-2': this.paddingAll,
          file__info: true,
          'file__info--current': this.currentChosenFileId === this.file.id
        },
        props: {
          rounded: this.rounded,
          outline: this.outline,
          flat: this.flat,
          hover: this.readonly,
          transparent: this.transparent,
          maxWidth: this.maxWidth,
          minWidth: this.minWidth,
          width: this.width
        },
        on: {
          click: () => {
            if (this.readonly) {
              this.$emit('input', this.file)
            }
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'ff faic'
          },
          [
            renderType.call(this, h),
            renderInfo.call(this, h),

            h('div', { class: 'ff' }),

            renderMenu.call(this, h)
          ]
        )
      ]
    )
  }
}
