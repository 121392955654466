import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Button',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },

  render
}
