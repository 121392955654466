import Prism from 'prismjs'
import 'prismjs/components/prism-json'

import render from './render'

export default {
  name: 'SourceBox',

  props: {
    value: null,

    language: {
      type: String,
      default: 'json',
      validator: value => {
        return !!~[ 'json' ].indexOf(value)
      }
    },

    rounded: Boolean
  },

  data() {
    return {
      preWrap: true,
      overflow: false
    }
  },

  computed: {
    parsedSource() {
      if (this.value !== undefined) {
        return Prism.highlight(JSON.stringify(this.value, null, 2), Prism.languages[this.language], this.language)
      }

      return ' '
    },

    contentStyle() {
      if (this.preWrap && !this.overflow) {
        return {
          'white-space': 'pre-wrap',
          'word-break': 'break-all'
        }
      }
    },

    sourceBoxStyle() {
      if (this.overflow && !this.preWrap) {
        return {
          overflow: 'auto'
        }
      }
    }
  },

  render
}
