import { defaultsDeep, toPlainObject } from 'lodash'
import { EMPTY_TARIFFS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import { splitRegExpStringToArray } from '@sigma-legacy-libs/essentials/lib/utils/regexp'

import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

export function setPrice(price, source) {
  if (!Array.isArray(price)) {
    price = [ price ]
  }

  price.push(0)

  switch (source) {
    case SENDING_TYPES.voice:
      price.length = 2
      break
    default:
      price.splice(1, price.length)
      break
  }

  return price
}

export function extractDefaultRule(rules, source) {
  const lastRule = rules[rules.length - 1]
  if (lastRule && Array.isArray(lastRule.conditions) && lastRule.conditions.length === 0) {
    rules.pop()

    lastRule.price = setPrice(lastRule.price, source)

    return {
      action: lastRule.action,
      price: lastRule.price
    }
  }

  return
}

export function ruleProcessor(rule, tagOrPath) {
  let result

  const tagMeta = Conditions.tagsMeta[tagOrPath]

  if (tagMeta) {
    switch (tagMeta.tag) {
      case 'text':
      case 'recipient': {
        result = typeof rule === 'string' ? rule : ''
        break
      }
      case 'type':
      case 'group':
      case 'sender':
      case 'keywords':
      case 'contactList': {
        if (Array.isArray(rule) && rule.length) {
          result = rule
          break
        }
        let rules = []
        if (typeof rule === 'string') {
          rules = splitRegExpStringToArray(rule)
        }
        result = rules
        break
      }
      case 'mccmnc': {
        result = toPlainObject(rule)
        break
      }
    }
  }

  return result
}

export function tariffRulesToGroup(rules = [], source) {
  const result = []

  if (Array.isArray(rules) && rules.length) {
    rules.sort((a, b) => a.priority - b.priority)

    let lastKey = ''

    for (let { action, price, conditions } of rules) {
      const rule = { conditions: [] }

      price = setPrice(price, source)

      const key = `${action}_${price.toString()}`

      if (conditions) {
        rule.conditions = conditions.reduce((accumulator, { tag, path, value }) => {
          const meta = Conditions.tagsMeta[tag || path]
          const rule = ruleProcessor(value, meta.tag)
          if (rule) {
            accumulator.push({
              tag: meta.tag,
              value: rule
            })
          }

          return accumulator
        }, [])
      }

      if (key === lastKey) {
        result[result.length - 1].rules.push(rule)
      } else {
        result.push({
          action,
          price,
          rules: [ rule ]
        })
      }

      lastKey = key
    }
  }

  return result
}
export function tariffGroupToRules(groups = []) {
  const result = []

  if (Array.isArray(groups) && groups.length) {
    for (const { action, price, rules } of groups) {
      if (Array.isArray(rules) && rules.length) {
        for (const { conditions } of rules) {
          result.push({
            action,
            price,
            priority: result.length,
            conditions: conditions.reduce((result, { tag, path, value }) => {
              const meta = Conditions.tagsMeta[tag || path]
              const rule = ruleProcessor(value, meta.tag)
              if (rule) {
                result.push({
                  tag: meta.tag,
                  value: rule
                })
              }

              return result
            }, [])
          })
        }
      }
    }
  }

  return result
}

export function userTariffsInputFilter(tariff) {
  const result = {
    $attach: true,
    id: tariff.id,
    title: tariff.title,
    source: tariff.source,
    data: {
      rules: []
    },
    priority: 0,
    _defaultRules: [],
    _defaultRule: {}
  }

  const source = tariff.source ? tariff.source.split('.')[1] : undefined

  if (tariff.data.rules) {
    const _defaultRule = extractDefaultRule(tariff.data.rules, source)
    if (_defaultRule) {
      result._defaultRule = _defaultRule
    }

    result._defaultRules = tariffRulesToGroup(tariff.data.rules, source)
  }

  if (tariff.usersTariffs) {
    result.priority = tariff.usersTariffs.priority

    if (tariff.usersTariffs.data.rules) {
      result.data.rules = tariffRulesToGroup(tariff.usersTariffs.data.rules, source)
    }
  }

  return result
}
export function userTariffsOutputFilter(tariff) {
  const result = {
    $attach: tariff.$attach === undefined || !!tariff.$attach,
    id: tariff.id,
    source: tariff.source,
    data: {
      rules: []
    },
    priority: tariff.priority || 0
  }

  if (tariff.data.rules) {
    result.data.rules = tariffGroupToRules(tariff.data.rules, result.source)
  }

  return result
}

export function tariffInputFilter(tariff = {}) {
  defaultsDeep(tariff, EMPTY_TARIFFS)

  const rules = tariff.data.rules
  const source = tariff.source ? tariff.source.split('.')[1] : undefined

  if (Array.isArray(rules) && rules.length) {
    const _defaultRule = extractDefaultRule(tariff.data.rules, source)
    if (_defaultRule) {
      tariff._defaultRule = _defaultRule
    }

    tariff.data.rules = tariffRulesToGroup(rules, source)
  }

  tariff.OwnerId = tariff.Owner && tariff.Owner.id || tariff.OwnerId

  return tariff
}
export function tariffOutputFilter(tariff = {}) {
  tariff.data.rules = tariffGroupToRules(tariff.data.rules)

  if (tariff._defaultRule) {
    tariff.data.rules.push({
      action: tariff._defaultRule.action,
      price: tariff._defaultRule.price,
      priority: tariff.data.rules.length,
      conditions: []
    })

    delete tariff._defaultRule
  }

  return tariff
}
