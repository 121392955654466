import { DICTIONARIES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'InfoButton',

  props: {
    dictionaryType: {
      type: String,
      default: DICTIONARIES['phone:mnp'],
      validation: value => !!~DICTIONARIES.indexOf(value)
    }
  },

  data() {
    return {
      showDialog: false
    }
  },

  render
}
