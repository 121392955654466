export const buttonModes = {
  filled: 'filled',
  outline: 'outline',
  flat: 'flat',
  'flat-icon': 'flat-icon',
  clear: 'clear'
}

export const inputModes = {
  default: 'default',
  solo: 'solo',
  outline: 'outline',
  'outline-label': 'outline-label',
  'line-label': 'line-label'
}
