import render from './render'

export default {
  name: 'RowWithMessageTypeIcon',

  props: {
    value: String,
    title: String,

    dense: {
      type: Boolean,
      default: true
    },

    as: {
      type: String,
      default: 'tag',
      validator: value => {
        return ~[ 'text', 'tag' ].indexOf(value)
      }
    }
  },

  computed: {
    $type() {
      switch (this.value) {
        case 'smsping': return 'sms'
        case 'apisender': return 'whatsapp'
        case 'tgBot': return 'telegram'
        case 'whatsappchat':
        case 'viberchat':
        case 'vkchat':
        case 'telegramchat':
        case 'facebookchat': return 'chat'
        default: return this.value
      }
    },

    attributes() {
      const result = {
        class: [ 'grid', 'grid-gap--8', 'faic' ],
        style: {}
      }

      const gridTemplateColumns = [ '1fr' ]
      if (this.as === 'text') {
        gridTemplateColumns.splice(0, 0, '28px')
      }
      result.style.gridTemplateColumns = gridTemplateColumns.join(' ')

      return result
    }
  },

  render
}
