import { colors, generateUniqueKey, inputModes, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import SendingTypes from '@/components/misc/SendingTypes'

function renderTypeIcon(h, type, size = sizes.medium) {
  if (type) {
    return h(
      squircle,
      {
        props: {
          color: colors[type],
          size
        }
      },
      [
        h(
          svgIcon,
          {
            props: {
              value: type,
              color: colors[type],
              size: size === sizes.small ? 18 : 24
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '1fr 38px' }
    },
    [
      h(
        SendingTypes,
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${this.serviceName}.labels.type`),
            permission: `advanced.${this.serviceName}.update`,
            mode: inputModes['line-label'],
            required: true
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          },
          key: generateUniqueKey()
        }
      ),

      renderTypeIcon.call(this, h, this.proxy.type)
    ]
  )
}
