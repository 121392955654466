import Vue from 'vue'

import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { downloadable, fillDependence, globalErrorHandler, globalErrorProcessor, processFilter, tableHeaderParser } from '@/utils'

import render from './render'

export default {
  name: 'SendingsGroup001',

  mixins: [ downloadable({ permissionAccept: 'advanced.reports.get' }) ],

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,

      report: [],
      order: {}
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          title: 'sendings.headers.owner',
          value: 'Owner.username',
          align: 'left',
          sortable: true,
          class: 'pl-2 pr-2',
          show: this.checkPermissions('advanced.users.get', [ 'reseller', true ])
        },
        {
          title: 'sendings.headers.type',
          value: 'type',
          align: 'left',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_delivered',
          value: 'count_delivered',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_seen',
          value: 'count_seen',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_failed',
          value: 'count_failed',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_sent',
          value: 'count_sent',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'sendings.headers.count_total',
          value: 'count_total',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        }
      ])
    },

    total() {
      let countDelivered = 0
      let countFailed = 0
      let countSeen = 0
      let countSent = 0
      let countTotal = 0

      this.report.forEach(item => {
        countDelivered += item.count_delivered
        countFailed += item.count_failed
        countSeen += item.count_seen
        countSent += item.count_sent
        countTotal += item.count_total
      })

      return {
        countDelivered,
        countFailed,
        countSeen,
        countSent,
        countTotal
      }
    },

    colspan() {
      if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
        return 2
      } else {
        return 1
      }
    }
  },

  methods: {
    async getReport() {
      try {
        this.loading = true

        const { data } = await Vue.$GRequest.find('reports', { query: processFilter(this.filter, { $preset: 'sendings.group001' }) })
        if (data) {
          this.report = await Promise.all(
            data.map(async data => {
              data.percent_delivered = Math.round(data.count_delivered / data.count_total * 100)

              if (!data.Owner) {
                await fillDependence.call(this, data, {
                  service: 'users',
                  permissions: [ 'reseller', true ],
                  pathToId: 'OwnerId',
                  pathToObject: 'Owner',
                  outputPath: 'Owner',
                  picks: PUBLIC_FIELDS_USER
                })
              }

              return data
            })
          )
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
