import { services } from '@/utils'

function renderServerField(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.ServerId,
        label: this.getTranslate(`${services.providers}.labels.server`),
        service: 'servers',
        mode: 'outline',
        rounded: true,
        dense: true,
        details: false,
        clearable: true,
        autocomplete: true
      },
      on: {
        input: event => {
          this.proxy.ServerId = event
        }
      }
    }
  )
}
function renderCredentialsField(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.CredentialsId,
        label: this.getTranslate(`${services.providers}.labels.credentials`),
        service: 'credentials',
        mode: 'outline',
        rounded: true,
        dense: true,
        details: false,
        clearable: true,
        autocomplete: true
      },
      on: {
        input: event => {
          this.proxy.CredentialsId = event
        }
      }
    }
  )
}

function renderIsActiveField(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.isActive
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--1': this.viewport.breakpoint.smDown
      },
      style: { 'grid-template-columns': this.viewport.breakpoint.mdUp ? 'repeat(2, 250px) auto' : undefined }
    },
    [
      renderServerField.call(this, h),
      renderCredentialsField.call(this, h),
      renderIsActiveField.call(this, h)
    ]
  )
}
