import Vue from 'vue'

import { serviceName } from '@/components/services/requisites/utils'

import render from './render'

export default {
  name: 'Title',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  methods: {
    clickHandler() {
      const to = {
        name: `${serviceName}.single`,
        params: { id: this.value.id }
      }

      switch (this.$route.name) {
        case serviceName: {
          Vue.router.push(to)
          break
        }

        case 'users.single':
        case 'account': {
          if (this.checkPermissions(`advanced.${serviceName}.get`, [ 'reseller', true ])) {
            Vue.router.push(to)

            break
          } else if (this.checkPermissions(`advanced.${serviceName}.get`, [ 'me' ])) {
            this.$emit('showDialog', this.value.id)

            break
          }

          break
        }
      }
    }
  },

  render
}
