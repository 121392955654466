export const RENDER_SETTINGS_USER_SCHEMA = [
  {
    key: 'sendings',
    icon: 'message',
    children: [
      { subtitle: 'sendings.duplicates' },
      'sendings.allowDuplicates',
      'sendings.duplicates.hashFields',
      'sendings.duplicates.timeout',
      { subtitle: 'sendings.rateLimit' },
      'sendings.rateLimit.maxAmount',
      'sendings.rateLimit.timeout',
      'sendings.tilda.sendFromForms'
    ]
  },

  {
    key: 'billings',
    icon: 'monetization_on',
    children: [
      'billings.processChildrenOperations',
      'billings.billOnErrors',
      'billings.overdraft',
      'billings.currency',
      { subtitle: 'billings.autoAccrual' },
      'billings.autoAccrual'
    ]
  },

  {
    key: 'referral',
    icon: 'group_work',
    children: [ 'referral.billings.processedChildOperations' ]
  },

  {
    key: 'websocket',
    icon: 'account_tree',
    children: [ 'websocket.channels' ]
  },

  {
    key: 'smpp',
    children: [
      'smpp.enabled',
      'smpp.rudeMode',
      'smpp.defaultMessageType',
      'smpp.ipWhitelist'
    ]
  },

  {
    key: 'webhooks',
    children: [ 'webhooks' ]
  },

  {
    key: 'notifications',
    icon: 'notifications',
    children: [ { service: 'notifications.settingsUser' } ]
  },

  {
    key: 'integrations',
    icon: 'extension',
    children: [ 'integrations.pyrus.credentials' ]
  }
]

export const RENDER_SETTINGS_USER_SCHEMA_ME = [
  {
    key: 'webhooks',
    children: [ 'webhooks' ]
  },

  {
    key: 'notifications',
    icon: 'notifications',
    children: [ { service: 'notifications.settingsUser' } ]
  }
]

export const RENDER_SETTINGS_USER_SCHEMA_RESELLER_CHILD = [
  {
    key: 'sendings',
    icon: 'message',
    children: [
      'sendings.allowDuplicates',
      'sendings.duplicates.hashFields',
      'sendings.duplicates.timeout'
    ]
  },

  ...RENDER_SETTINGS_USER_SCHEMA_ME
]

export const RENDER_SETTINGS_USER_SCHEMA_RESELLER = RENDER_SETTINGS_USER_SCHEMA_ME

export default {
  RENDER_SETTINGS_USER_SCHEMA,
  RENDER_SETTINGS_USER_SCHEMA_ME,
  RENDER_SETTINGS_USER_SCHEMA_RESELLER_CHILD,
  RENDER_SETTINGS_USER_SCHEMA_RESELLER
}
