import scanQrCode from '@/components/services/apisender/buttons/scanQrCode'
import renewInstance from '@/components/services/apisender/buttons/renewInstance'
import logoutInstance from '@/components/services/apisender/buttons/logoutInstance'

export default function(h) {
  if (this.account.id === this.value.OwnerId) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': 'repeat(3, 36px)' }
      },
      [
        h(
          scanQrCode,
          {
            props: {
              value: this.value
            },
            on: {
              update: () => {
                this.get()
              }
            }
          }
        ),
        h(
          renewInstance,
          {
            props: {
              value: this.value
            },
            on: {
              update: () => {
                this.get()
              }
            }
          }
        ),
        h(
          logoutInstance,
          {
            props: {
              value: this.value
            },
            on: {
              update: () => {
                this.get()
              }
            }
          }
        )
      ]
    )
  }
}
