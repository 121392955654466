import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: Boolean
  },

  data() {
    return {
      passwordVisibility: true
    }
  },

  computed: {
    recordsValidation() {
      return Object.values(this.proxy.records).some(Boolean)
    },

    validation() {
      return !!this.proxy.title && this.recordsValidation
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
