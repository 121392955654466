import { cloneDeep, defaultsDeep } from 'lodash'
import { EMPTY_REQUISITES, PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { appendFormattedDates, fillDependence, serviceTemplate } from '@/utils'
import { serviceName } from '@/components/services/requisites/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        defaultsDeep(result, cloneDeep(EMPTY_REQUISITES)) // потому что с сервера приходит урезанный payload

        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        appendFormattedDates.call(this, result)

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined,
          isActive: true,
          $scope: [ 'Owner' ]
        }
      },

      width: 800,
      formPaddles: true,
      view: 'panels',
      views: [ 'panels' ],

      has: { view: false }
    })
  ]
}
