import { states } from '@/utils'

import { serviceName } from '@/components/services/providers/utils'

function renderButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'delete',
        flat: true,
        color: 'error'
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    }
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        this.getTranslate(`${serviceName}.titles.remove`)
      ),

      h(
        'div',
        {
          class: 'body-1 pa-3'
        },
        this.getTranslate(`${serviceName}.contents.confirm.remove`)
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcsb',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel'),
                disabled: this.restData[serviceName].remove.state === states.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                label: this.getTranslate('misc.buttons.remove'),
                color: 'error',
                loading: this.restData[serviceName].remove.state === states.loading,
                disabled: this.restData[serviceName].remove.state === states.loading
              },
              on: {
                click: () => {
                  this.remove()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {},
    [
      renderButton.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
