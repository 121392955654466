import { serviceName } from '@/components/services/apisender/utils'

import { getLocaleDateString, getLocaleTimeString } from '@/utils'

import info from '@/components/services/apisender/item/info'
import date from '@/components/serviceTemplate/item/date'
import sticker from '@/components/misc/sticker'

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderExpiredAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      sticker,
      {
        props: {
          value: [ getLocaleDateString(this.value.expiredAt), getLocaleTimeString(this.value.expiredAt) ].join(' '),
          label: this.getTranslate(`${serviceName}.labels.expiredAt`),
          icon: 'date_range',
          borderStyle: 'dashed',
          color: 'grey'
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}__panel service-template-panel hover-parent hover-parent--opacity`
    },
    [
      h(
        info,
        {
          props: {
            value: this.value
          }
        }
      ),

      renderExpiredAt.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
