import Vue from 'vue'

import { isEqual } from 'lodash'

import { EVENTSHISTORY_ASSOCIATED_SERVICES, EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { globalErrorHandler, globalErrorProcessor, projectName, services } from '@/utils'

import { $order, calcChanges } from '@/components/services/eventsHistory/utils'

import render from './render'

export default {
  name: 'eventsHistoryTab',

  props: {
    entityId: {
      type: String,
      default: ''
    }
  },

  data() {
    let limitFromLocalStorage = 25
    try {
      limitFromLocalStorage = JSON.parse(window.localStorage.getItem(`${projectName}:pagination:limit:${services.eventsHistory}`))
    } catch (error) {
    }

    return {
      items: [],

      pagination: {
        limit: limitFromLocalStorage || 10,
        offset: 0,
        total: 0
      },

      loading: false,
      showDialog: false,

      currentTab: 'oldValues',

      compareData: {
        oldValues: {},
        newValues: {}
      },

      compareId: ''
    }
  },

  computed: {
    types() {
      return Object.keys(this.compareData).sort((a, b) => b.localeCompare(a))
    },

    tabForCreated() {
      return Object.keys(this.compareData).length === 1 && Object.keys(this.compareData).includes('newValues')
    }
  },

  watch: {
    'pagination.offset': {
      handler() {
        this.getItems()
      },
      deep: true
    }
  },

  mounted() {
    this.getItems()

    Vue.$socket.on(`${services.eventsHistory}.created`, data => {
      if (data.entityId && data.entityId === this.entityId) {
        if (this.pagination.offset === 0) {
          this.items.unshift(data)
          if (this.items.length > this.pagination.limit) {
            this.items.splice(this.items.length - 1, 1)
          }
        }
        this.pagination.total++
      }
    })
  },

  methods: {
    async getItems() {
      try {
        this.loading = true

        const { data } = await Vue.$GRequest.find(services.eventsHistory, {
          query: {
            entityId: this.entityId,
            $offset: this.pagination.offset,
            $limit: this.pagination.limit,
            $order
          }
        })

        if (data) {
          this.items = data.data
          this.pagination.total = data.total
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    async getNextItem(item, offset) {
      try {
        const query = {
          entityId: this.entityId,
          service: EVENTSHISTORY_ASSOCIATED_SERVICES[item.service],
          $offset: offset,
          $order
        }

        const { data } = await Vue.$GRequest.find(services.eventsHistory, { query })

        if (Array.isArray(data.data) && data.data.length) {
          const index = data.data.findIndex(v => v.data.id === item.data.id)
          if (index !== -1) {
            return data.data[index]
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    async compareItems(item, index) {
      this.compareId = item.data.id
      let nextItem
      if (this.items[index + 1]) {
        nextItem = this.items.find((v, i) => i > index && v.service === this.items[index].service && this.items[index].type === EVENTSHISTORY_TYPES.updated && v.data.id === this.items[index].data.id)
      }
      if (!nextItem) {
        const response = await this.getNextItem(item, index + 1)
        if (response) {
          nextItem = response
        }
      }
      if (nextItem && !isEqual(item.data, nextItem.data)) {
        this.compareData = calcChanges.call(this, item, nextItem)
        this.showDialog = true
      }

      if (this.items[index].type === EVENTSHISTORY_TYPES.created) {
        nextItem = { data: {} }
        this.compareData = calcChanges.call(this, item, nextItem)
        this.currentTab = 'newValues'
        this.showDialog = true
      }
    }
  },

  render
}
