import { currentTab, permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'SecondCol',

  mixins: [ currentTab('eventsHistory') ],

  computed: {
    tabs() {
      const result = []

      if (this.checkPermissions('advanced.eventsHistory.get', permissionPresets.admin)) {
        result.push('eventsHistory')
      }

      return result
    }
  },

  render
}
