import { getUrlToStorage } from '@/utils'

export default function(h) {
  if (this.$id) {
    return h(
      'div',
      {
        style: {
          'background-position': 'center',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
          'background-image': `url(${getUrlToStorage([ this.$id ])})`,
          height: '100%'
        }
      },
      [ this.$slots.default ]
    )
  }
}
