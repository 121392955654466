import { SOURCE_TYPE_COLORS, sendingTypeColors } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      style: {
        'background-color': this.color || sendingTypeColors[this.value] || SOURCE_TYPE_COLORS[this.value],
        'mask-image': `url(icons/${this.parsedType}.svg)`,
        'mask-position': 'center',
        'mask-repeat': 'no-repeat',
        'mask-size': 'contain',
        'min-height': `${this.size}px`,
        'max-height': `${this.size}px`,
        height: `${this.size}px`,
        'min-width': `${this.size}px`,
        'max-width': `${this.size}px`,
        width: `${this.size}px`
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.parsedTranslate
          }
        }
      ]
    }
  )
}
