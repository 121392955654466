import { services } from '@/utils'

import buttonRemove from '@/components/button/service/remove'

export default function(h) {
  return h(
    buttonRemove,
    {
      props: {
        id: this.value.id,
        component: this.viewport.breakpoint.lgUp ? 'btn' : 'g-list-item',
        labeled: this.viewport.breakpoint.mdDown,
        service: services.invoices
      }
    }
  )
}
