import title from '@/components/services/routingGroups/item/title'
import description from '@/components/services/routingGroups/item/description'
import date from '@/components/serviceTemplate/item/date'

function renderTitle(h) {
  return h(
    title,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderDescription(h) {
  return h(
    description,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'service-template-panel hover-parent hover-parent--opacity',
      style: { gridTemplateColumns: 'auto minmax(200px, 1fr) auto' }
    },
    [
      renderTitle.call(this, h),
      renderDescription.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
