import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'

export const dialogTypes = upgradeArray([ 'editMeta', 'remove', 'edit', 'source', 'export' ])

export const fileInfoIcons = {
  id: '123',
  hash: 'more_horiz',
  size: 'dns',
  type: 'type_specimen',
  owner: 'person_outline',
  createdAt: 'access_time',
  updatedAt: 'access_time',
  willBeDeleted: 'history'
}

export const serviceName = 'storage'

export default {
  dialogTypes,
  fileInfoIcons,
  serviceName
}
