import { DICTIONARIES_STATUSES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, globalErrorHandler, globalErrorProcessor, tableHeaderParser } from '@/utils'

import render from './render'

const name = 'operatorGroups'

export default {
  name: 'OperatorGroup',

  mixins: [
    generateServices({
      name,

      find: {
        defaultFilter: {
          DictionaryId: undefined,
          $scope: [ 'Dictionary' ],
          $search: undefined
        },
        alwaysCreateFromWebSocket: true,
        alwaysRemoveFromWebSocket: true
      },

      create: {
        params: {
          query: {
            $scope: [ 'Dictionary' ]
          }
        }
      }
    })
  ],

  props: {
    dictionary: {
      type: Object,
      default: () => ({})
    },

    removeCallback: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      showCreateDialog: false
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          title: 'dictionaries.titles.mcc',
          value: 'mcc',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: 'dictionaries.titles.mnc',
          value: 'mnc',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: 'dictionaries.titles.operator',
          value: 'operator',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: 'dictionaries.titles.group',
          value: 'group',
          align: 'left',
          class: 'pl-2 pr-2'
        },
        {
          title: '',
          value: 'actions',
          class: 'pl-0 pr-0'
        }
      ])
    },

    isCorrectStatus() {
      return !!~[
        DICTIONARIES_STATUSES.ready,
        DICTIONARIES_STATUSES.compiled,
        DICTIONARIES_STATUSES.activated
      ].indexOf(this.dictionary.status)
    }
  },

  watch: {
    dictionary: {
      handler() {
        this.getDictionary()
      },
      deep: true
    }
  },

  mounted() {
    this.getDictionary()
  },

  methods: {
    getDictionary() {
      if (this.isCorrectStatus) {
        this.restData[name].find.filter.DictionaryId = this.dictionary.id
      }
    },

    async createGroup() {
      if (this.checkPermissions(`advanced.${name}.create`, [ 'reseller', true ])) {
        try {
          const result = await this.rest[name].create(Object.assign({ DictionaryId: this.dictionary.id }, this.restData[name].create.data))
          if (result) {
            this.showCreateDialog = false
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
