import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'SendingsCreateFormWarning',

  mixins: [ proxy() ],

  props: {
    check: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      warningText: ''
    }
  },

  mounted() {
    this.setWarningText()
  },

  methods: {
    setWarningText() {
      switch (window.location.hostname) {
        case 'online.imlink.io': {
          this.warningText = 'Dear Customer,<br>To send test message, please contact your AM <strong>sale@imlink.io</strong> or Support Team <strong>support@imlink.io</strong>'
          break
        }
        default: {
          this.warningText = this.getTranslate('sendings.hints.warning')
          break
        }
      }
    }
  },

  render
}
