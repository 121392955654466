import proxy from '@sigma-legacy-libs/g-proxy'

import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { setPrice } from '@/utils'

import render from './render'

export default {
  name: 'PriceTextField',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    source: String
  },

  watch: {
    source() {
      this.proxy = setPrice(this.proxy, this.source)
    }
  },

  computed: {
    hint() {
      const result = []
      switch (this.source) {
        case 'apisender': {
          result.push(this.getTranslate('tariffs.hints.perPeriod'))
          break
        }
        case SENDING_TYPES.voice: {
          result.push(this.getTranslate('tariffs.hints.perCall'), this.getTranslate('tariffs.hints.perSecond'))
          break
        }
        default: {
          result.push(this.getTranslate('tariffs.hints.default'))
        }
      }

      return result
    }
  },

  render
}
