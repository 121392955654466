import render from './render'

import { balance } from '@/utils'

export default {
  name: 'ButtonAccount',

  mixins: [ balance() ],

  data() {
    return {
      show: false
    }
  },

  computed: {
    balanceColor() {
      if (this.balance > 0) {
        return 'success'
      } else if (this.balance <= 0) {
        if (this.balance + this.overdraft >= 0) {
          return 'warning'
        } else {
          return 'error'
        }
      } else {
        return 'grey'
      }
    }
  },

  render
}
