import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Actions',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    whitelist: {
      type: Array,
      default: () => []
    },
    blacklist: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      showDialog: false,
      tag: undefined
    }
  },

  methods: {
    tagFilter(tag) {
      if (this.whitelist.length) {
        if (!~this.whitelist.indexOf(tag)) {
          return false
        }
      }
      if (this.blacklist.length) {
        if (~this.blacklist.indexOf(tag)) {
          return false
        }
      }

      return true
    }
  },

  render
}
