export const localServices = {
  routingGroups: {
    association: 'RoutingRules',
    nextService: 'routingRules',
    hasDeep: true
  },
  routingRules: {
    association: 'ProviderGroups',
    nextService: 'providerGroups',
    hasDeep: true
  },
  providerGroups: {
    association: 'Providers',
    nextService: 'providers',
    hasDeep: true
  },
  providers: {
    hasDeep: false
  }
}

export const rowStatesIcon = {
  opened: 'keyboard_arrow_down',
  closed: 'keyboard_arrow_right',
  loading: 'sync'
}

