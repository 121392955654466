import { getUrlToStorage, serviceTemplate, services, toFloatOrUndefined } from '@/utils'

import { get } from 'lodash'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.invoices,

      backendGeneration: 'nest',

      outputFilter(result) {
        result.amount = toFloatOrUndefined(result.amount)

        return result
      },

      find: {
        defaultFilter: {
          scopes: [ 'full' ],
          order: [ [ 'createdAt', 'desc' ] ],
          status: undefined,
          docNumber: undefined,
          ownerId: undefined,
          customerId: undefined,
          requisiteId: undefined,
          companyId: undefined,
          createdAtPreset: 'current.week',
          createdAt: {
            gte: undefined,
            lt: undefined
          }
        },
        defaultPagination: {
          limit: 15
        }
      },
      get: {
        params: {
          query: {
            scopes: [ 'full' ]
          }
        }
      },
      create: {
        redirect: true,
        params: {
          query: {
            scopes: [ 'awaitDocument' ]
          }
        },
        after(data) {
          window.location.href = getUrlToStorage([ get(data, 'Owner.id', data.OwnerId), data.documentId ])
        }
      },
      update: false,

      view: 'panels',
      views: [ 'panels' ],

      has: {
        clone: false,
        search: false,
        view: false,
        support: true
      },

      titlePath: 'docNumber',
      formPaddles: true
    })
  ]
}
