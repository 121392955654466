import Vue from 'vue'

const _ = { isEqual: require('lodash/isEqual') }

// NOTE: https://github.com/vuejs/vue-router/issues/2872
const noop = () => {}

export const routerReplacer = (route = {}) => {
  const currentRoute = Vue.router.currentRoute
  const nextRoute = {
    ...currentRoute,
    ...route
  }
  if (!_.isEqual(currentRoute, nextRoute)) {
    Vue.router.replace(nextRoute).catch(noop)
  }
}

export const routerQueryReplace = (query = {}) => {
  const currentRoute = Vue.router.currentRoute
  Vue.router.replace({
    ...currentRoute,
    query: {
      ...currentRoute.query,
      ...query
    }
  }).catch(noop)
}

export const routerParamsReplace = (params = {}) => {
  const currentRoute = Vue.router.currentRoute
  Vue.router.replace({
    ...currentRoute,
    params: {
      ...currentRoute.params,
      ...params
    }
  }).catch(noop)
}

export default {
  routerReplacer,
  routerQueryReplace,
  routerParamsReplace
}
