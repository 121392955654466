import { services } from '@/utils'

import sendernameType from '@/components/services/sendernames/item/type'
import sendernameTitle from '@/components/services/sendernames/item/title'
import owner from '@/components/serviceTemplate/item/owner'

export default function(h) {
  return h(
    'div',
    {
      class: `${services.sendernames}-item__info`,
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      h(
        sendernameType, {
          props: {
            value: this.value
          }
        }
      ),
      h(
        sendernameTitle, {
          props: {
            value: this.value
          }
        }
      ),
      h(
        owner, {
          props: {
            value: this.value
          }
        }
      )
    ]
  )
}
