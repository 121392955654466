export default {
  title: 'Permissions',

  subtitles: {
    presets: 'Presets'
  },

  dialogs: {
    titles: {
      info: 'Information by level of permissions'
    }
  },

  labels: {
    create: 'Create',
    check: 'Check number',
    finalize: 'Finalize',
    find: 'Find',
    generate: 'Generate',
    set: 'Set',
    get: 'Get',
    getSendingsForTask: 'Get sendings for task',
    remove: 'Remove',
    update: 'Update',
    modify: 'Modify',
    seek: 'Seek',
    download: 'Download',
    upload: 'Upload',
    setRead: 'Set read',
    setPermissions: 'Set permissions',
    setRoutingGroups: 'Set routing groups',
    setMessageRuleGroups: 'Set message rule groups',
    setSettings: 'Set settings',
    setSendernames: 'Set sendernames',
    setPatterns: 'Set patterns',
    setTariffs: 'Set tariffs',
    showSecrets: 'Show secret permissions',
    trigger: 'Trigger',
    health: 'Health',
    loginAs: 'Login as',
    request: 'Request',
    controlDocuments: 'Control documents',
    edit: 'Edit',
    viewChangelogs: 'View changelogs',
    contracts: 'Contracts',
    companies: 'Companies',
    documentTemplates: 'Document templates',
    refund: 'Refund payment',
    presetUsersBalance: 'Download user list',
    matchKeywords: 'Match keywords',
    setOtpChannels: 'Set OTP channels'
  },

  hints: {
    false: 'Prohibits any actions that require corresponding permission.',
    me: 'Allows actions with objects owned by considered user.',
    manager: 'Allows actions with objects that belong to the user himself, or to users tied to the manager.',
    reseller: 'Allows actions with objects owned by considered user or any attached user.',
    true: 'Allows any actions with any object in system.',
    warning: 'Changing permissions does not require user update.'
  },

  tooltips: {
    info: 'Information by level of permissions'
  },

  buttons: {
    false: 'False',
    me: 'Пользователь',
    reseller: 'Реселлер'
  },

  services: {
    apisender: 'API sender',
    billings: 'Billings',
    changelogs: 'Changelogs',
    companies: 'Companies',
    components: 'Components',
    contacts: 'Contacts',
    contracts: 'Contracts',
    credentials: 'Credentials',
    dadata: 'Dadata',
    dictionaries: 'Dictionaries',
    documentTemplates: 'Document templates',
    eventsHistory: 'Events history',
    hlr: 'HLR',
    invoices: 'Invoices',
    keywords: 'Keywords',
    messageProcessors: 'Message processors',
    messageRuleGroups: 'Message rule groups',
    messageRules: 'Message rules',
    notifications: 'Notifications',
    operatorGroups: 'Operator groups',
    patterns: 'Patterns for moderation',
    payment: 'Payment',
    paymentLogs: 'Payment logs',
    payments: 'Payments',
    platform: 'Platform',
    providerGroups: 'Provider groups',
    providers: 'Providers',
    recurrentPayments: 'Recurrent payments',
    reports: 'Reports',
    requisites: 'Requisites',
    routingGroups: 'Routing groups',
    routingRules: 'Routing rules',
    secret: 'Secret',
    sendernames: 'Sendernames',
    sendings: 'Sendings',
    sendingsIncoming: 'Incoming messages',
    sendingsModeration: 'Sendings moderation',
    servers: 'Servers',
    settings: 'Site settings',
    settingsSite: 'Settings site',
    settingsUser: 'Settings user',
    storage: 'Storage',
    tariffs: 'Tariffs',
    tasks: 'Tasks',
    telemetry: 'Telemetry',
    templates: 'Templates messages',
    tokens: 'Tokens',
    userComments: 'User comments',
    users: 'Users',
    yclients: 'YClients',

    'otp-settings': {
      widgets: 'OTP Widgets',
      channels: 'OTP Channels',
      templates: 'OTP Templates'
    }
  },

  snackbars: {
    updated: 'Permission successfully updated'
  },

  tooltips: {
    presets: 'By clicking this button, all user permissions will be replaced with the selected preset, if such a preset exists for a specific permission. Changes are irreversible and will be applied immediately.'
  }
}
