import { colors, sizes } from '@/utils'

import { core } from '@/components/g/checkbox/mixins'

import render from './render'

const className = 'switcher'

export default {
  name: 'Switcher',

  mixins: [ core ],

  props: {
    icons: {
      type: Object,
      default: () => ({
        on: 'check',
        off: 'close'
      })
    },

    labels: {
      type: Object,
      default: () => ({})
    },

    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },

    disabled: Boolean,

    label: String,
    tooltip: String
  },

  computed: {
    $attrs() {
      return {
        role: 'checkbox',
        'aria-checked': typeof this.proxy === 'boolean' ? this.proxy.toString() : 'mixed'
      }
    },
    $class() {
      return {
        [`${className}`]: true,
        [`${className}--${this.size}`]: true,
        [`${className}--${this.checked ? colors.success : colors.error}`]: true,
        [`${className}--labeled`]: !!Object.keys(this.labels).length,
        [`${className}--checked`]: this.checked,
        [`${className}--disabled`]: this.disabled
      }
    },

    $label() {
      return this.label || this.labels[this.status]
    },

    status() {
      return this.checked ? 'on' : 'off'
    },

    $directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.tooltip,
            placement: 'top'
          }
        })
      }

      return result
    }
  },

  render
}
