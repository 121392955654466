import { serviceName } from '@/components/services/tariffs/utils'

import draggable from '@/components/misc/draggable'
import tariffsDefaultRules from '@/components/services/tariffs/defaultRules'
import messageTypeIcon from '@/components/message/type/icon'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

function renderAddTariffButton(h) {
  if (!this.readonly) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          flat: true,
          rounded: true,
          icon: 'add',
          label: this.getTranslate(`${serviceName}.add.tariff`),
          color: 'primary'
        },
        on: {
          click: () => {
            this.add()
          }
        }
      }
    )
  }
}

function renderHeader(h) {
  if (!this.readonly) {
    return h(
      'div',
      {
        class: `${serviceName}-rules__generator-header`
      },
      [ renderAddTariffButton.call(this, h) ]
    )
  }
}

function renderDragHandle(h) {
  if (!this.readonly) {
    return h(
      'div',
      {
        class: 'fjcc faic square--36 drag_handle'
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'drag_handle',
              color: 'grey'
            }
          }
        )
      ]
    )
  }
}

function renderLink(h, { id }) {
  if (!this.readonly) {
    return h(
      id ? 'router-link' : 'div',
      {
        class: {
          'fjcc faic square--36': true,
          link: !!id
        },
        props: {
          to: {
            name: 'tariffs.single',
            params: { id }
          }
        }
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'link',
              color: 'grey'
            }
          }
        )
      ]
    )
  }
}

function renderTariffField(h, item) {
  if (this.readonly) {
    const [ source, type ] = item.source && item.source.split('.') || []

    return h(
      'div',
      {
        class: 'grid faic ff',
        style: { 'grid-template-columns': '24px 1fr' }
      },
      [
        h(
          messageTypeIcon,
          {
            props: {
              value: type || source
            }
          }
        ),

        h('div', { class: 'headline text-overflow' }, this.forUsers ? this.getTranslate(`sendings.types.${type}`) : item.title)
      ]
    )
  } else {
    return h(
      'g-select',
      {
        props: {
          value: item.id,
          label: this.getTranslate('tariffs.tariff'),
          itemsDisabled: this.proxy.reduce((result, tariff) => {
            if (tariff.id !== item.id) {
              result.push(tariff.id)
            }

            return result
          }, []),
          query: { $scope: [ 'Owner' ] },
          picks: [ 'id', 'title', 'source', 'Owner' ],
          service: 'tariffs',
          mode: 'outline',
          dense: true,
          rounded: true,
          details: false,
          clearable: true,
          autocomplete: true
        },
        on: {
          input: event => {
            item.id = event
          }
        },
        scopedSlots: {
          selection: ({ item }) => {
            const [ source, type ] = item.source && item.source.split('.') || []

            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: type || source,
                  title: item.title
                }
              }
            )
          },
          item: ({ item }) => {
            const [ source, type ] = item.source && item.source.split('.') || []

            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: type || source,
                  title: item.title,
                  as: 'text'
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderRemoveButton(h, index) {
  if (!this.readonly) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          flat: true,
          icon: 'delete',
          color: 'error'
        },
        on: {
          click: () => {
            this.remove(index)
          }
        }
      }
    )
  }
}

function renderItemHeader(h, item, index) {
  return h(
    'div',
    {
      class: {
        [`${serviceName}-rules__generator-list-item-header`]: true,
        [`${serviceName}-rules__generator-list-item-header--readonly`]: this.readonly
      }
    },
    [
      renderDragHandle.call(this, h),
      renderLink.call(this, h, item),
      renderTariffField.call(this, h, item),
      renderRemoveButton.call(this, h, index)
    ]
  )
}

function renderTariffDefaultRules(h, item) {
  const [ source, type ] = item.source && item.source.split('.') || []

  return h(
    tariffsDefaultRules,
    {
      props: {
        rules: item._defaultRules,
        defaultRule: item._defaultRule,
        source: type || source
      }
    }
  )
}

function renderItemBody(h, item) {
  return h(
    'div',
    {
      class: {
        [`${serviceName}-rules__generator-list-item-body`]: true,
        [`${serviceName}-rules__generator-list-item-body--readonly`]: this.readonly
      }
    },
    [ renderTariffDefaultRules.call(this, h, item) ]
  )
}

function renderItem(h, item, index) {
  return h(
    'div',
    {
      class: {
        [`${serviceName}-rules__generator-list-item`]: true,
        [`${serviceName}-rules__generator-list-item--readonly`]: this.readonly
      },
      key: `tariff-${index}`
    },
    [
      renderItemHeader.call(this, h, item, index),
      renderItemBody.call(this, h, item)
    ]
  )
}

function renderItems(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    if (this.readonly) {
      return h(
        'div',
        {
          class: `${serviceName}-rules__generator-list`
        },
        this.proxy.map((item, index) => renderItem.call(this, h, item, index))
      )
    } else {
      return h(
        draggable,
        {
          class: `${serviceName}-rules__generator-list`,
          props: {
            value: this.proxy,
            handle: 'drag_handle'
          },
          on: {
            input: event => {
              this.proxy = event
            }
          },
          scopedSlots: {
            item: (item, index) => renderItem.call(this, h, item, index)
          }
        }
      )
    }
  } else {
    return h(
      'div',
      {
        class: 'fjcc faic'
      },
      [ h('g-empty') ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__generator`
    },
    [
      renderHeader.call(this, h),
      renderItems.call(this, h)
    ]
  )
}
