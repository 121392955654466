import { colors, getPrice, services } from '@/utils'

import { amountColors, amountIcons } from '@/components/services/billings/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.$value.amount) {
    return h(
      sticker,
      {
        props: {
          value: getPrice(this.$value.amount),
          icon: this.$value.source ? amountIcons[this.$value.source] : 'wallet',
          color: this.$value.source ? amountColors[this.$value.source] : colors.primary,
          label: this.getTranslate(`${services.payments}.labels.amount`),
          borderStyle: 'dashed'
        }
      }
    )
  }
}
