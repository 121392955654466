import { components } from '@/utils'

import svgIcon from '@/components/icon/svg'

function renderIcon(h) {
  if (this.hasIcon) {
    return h(
      this.iconSVG ? svgIcon : components.icon,
      {
        props: {
          value: this.iconSVG ? this.iconSVG : this.icon,
          color: this.color,
          size: this.$iconSize
        }
      }
    )
  }

  return this.$slots.default || this.$slots.icon
}

function renderPreLoader(h) {
  return h(
    components.progress,
    {
      props: {
        indeterminate: true,
        color: this.color,
        size: this.$iconSize
      }
    }
  )
}

function renderContent(h) {
  if (this.loading) {
    return renderPreLoader.call(this, h)
  }

  return renderIcon.call(this, h)
}

export default function(h) {
  return h(
    'div',
    {
      class: this.$class,
      directives: this.$directives
    },
    [ renderContent.call(this, h) ]
  )
}
