import { createClicker, states } from '@/utils'

import render from './render'

export default {
  name: 'RefreshButton',

  props: {
    label: String,
    tooltip: String,

    shortClickMethod: {
      type: Function,
      default: () => {}
    },

    longClickMethod: {
      type: Function,
      default: () => {}
    },

    disabled: {
      type: Boolean,
      default: undefined
    },

    state: [ String, Boolean ]
  },

  computed: {
    computedDisabled() {
      if (this.disabled) {
        return true
      }

      return typeof this.state === 'boolean' ? this.state : this.state === states.loading
    }
  },

  methods: {
    clickHandler: createClicker({
      shortClickHandler(event) {
        if (event.shiftKey) {
          this.longClickMethod()
        } else {
          this.shortClickMethod()
        }
      },
      longClickHandler() {
        this.longClickMethod()
      },
      longClickPreFire: true
    })
  },

  render
}
