export default {
  title: 'YClients',

  titles: {
    create: 'Создать салон'
  },

  labels: {
    salon: 'ID cалона',
    token: 'Токен',
    isActive: 'Активен',
    owner: 'Владелец',
    error: 'Ошибка',
    errorIsEmpty: 'Ошибок нет'
  },

  tooltips: {
    active: 'Активен',
    notActive: 'Не активен',
    filter: {
      isActive: 'Показать неактивные записи'
    },
    updateStatus: 'Обновить статус'
  },

  snackbars: {
    created: 'Салон создан',
    updated: 'Салон обновлен',
    removed: 'Салон удален'
  }
}
