export const names = {
  'aws:maxim': 'Maxim',
  'aws:tatyana': 'Tatyana',
  'yandex:oksana': 'Oksana',
  'yandex:jane': 'Jane',
  'yandex:omazh': 'Omazh',
  'yandex:zahar': 'Zahar',
  'yandex:ermil': 'Ermil',
  'yandex:madirus': 'Madirus',
  'yandex:lea': 'Lea',
  'yandex:john': 'John',
  'yandex:amira': 'Amira',
  'yandex:madi': 'Madi',
  'yandex:nigora': 'Nigora',
  'yandex:alena': 'Alena',
  'yandex:filipp': 'Filipp',
  'yandex:silaerkan': 'Silaerkan',
  'yandex:erkanyavas': 'Erkanyavas',
  'yandex:alyss': 'Alyss',
  'yandex:nick': 'Nick'
}

export default { names }
