export const serviceName = 'apisender'

export const stateColors = {
  authorized: 'success',
  notAuthorized: 'error',
  sleep: 'grey',
  deleted: 'error'
}

export const stateIcons = {
  authorized: 'check_circle_outline',
  notAuthorized: 'highlight_remove',
  sleep: 'info_outline',
  deleted: 'remove_circle_outline'
}

export default {
  serviceName,
  stateColors,
  stateIcons
}
