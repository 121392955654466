import { services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        icon: 'subtitles',
        color: 'grey',
        label: this.getTranslate(`${services.credentials}.labels.title`),
        service: services.credentials,
        maxWidth: 200
      }
    }
  )
}
