import { hasPermission } from '@/components/services/invoices/utils'

import render from './render'

export default {
  name: 'FooterActions',

  mixins: [ hasPermission ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  render
}
