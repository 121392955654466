import Vue from 'vue'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'Token',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    isPage: {
      type: Boolean,
      default: true
    },

    sensitivity: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    label() {
      if (this.isPage) {
        if (this.checkPermissions(`advanced.${services.tokens}.get`, [ 'reseller', true ])) {
          if (this.viewport.breakpoint.xs) {
            return this.value.Owner.username
          }
        }
      }

      return this.getTranslate(`${services.tokens}.labels.token`)
    }
  },

  methods: {
    clickHandler() {
      const to = {
        name: `${services.tokens}.single`,
        params: { id: this.value.id }
      }

      switch (this.$route.name) {
        case services.tokens: {
          Vue.router.push(to)
          break
        }

        case 'users.single':
        case 'account': {
          if (this.checkPermissions(`advanced.${services.tokens}.get`, [ 'reseller', true ])) {
            Vue.router.push(to)

            break
          } else if (this.checkPermissions(`advanced.${services.tokens}.get`, [ 'me' ])) {
            this.$emit('showDialog', this.value.id)

            break
          }

          break
        }
      }
    }
  },

  render
}
