import proxy from '@sigma-legacy-libs/g-proxy'

import { actions } from '@/components/services/billings/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    owner: String,
    currency: String
  },

  data() {
    return {
      advanced: false,

      action: actions.addFunds
    }
  },

  watch: {
    action() {
      this.setDefaults()
    }
  },

  mounted() {
    this.setDefaults()
  },

  methods: {
    setDefaults() {
      if (this.action === actions.addFunds) {
        this.proxy.source = 'accrual'
      } else {
        this.proxy.source = 'subtract'
      }
    }
  },

  render
}
