import { inputModes, portsRegex, services } from '@/utils'

import serversProperties from '@/components/services/servers/properties'
import routesFormTitle from '@/components/services/routes/form/title'

function renderTitleField(h) {
  return h(
    routesFormTitle,
    {
      props: {
        value: this.proxy,
        serviceName: services.servers
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3',
      style: { 'grid-template-columns': 'repeat(1, minmax(200px, 1fr))' }
    },
    [
      renderTitleField.call(this, h),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.host,
            label: this.getTranslate(`${services.servers}.labels.host`),
            mode: inputModes['line-label'],
            rounded: true,
            dense: true,
            clearable: true,
            required: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.host = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.port,
            label: this.getTranslate(`${services.servers}.labels.port`),
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            clearable: true,
            required: true,
            details: false,
            mask: portsRegex
          },
          on: {
            input: event => {
              this.proxy.port = event
            }
          }
        }
      ),

      h(
        'g-select',
        {
          props: {
            value: this.proxy.processor,
            label: this.getTranslate(`${services.servers}.labels.processor`),
            items: this.processors,
            loading: this.processorsLoading,
            mode: inputModes['line-label'],
            rounded: true,
            clearable: true,
            autocomplete: true,
            dense: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.processor = event
            }
          }
        }
      ),

      h(
        serversProperties,
        {
          props: {
            value: this.proxy.properties,
            processor: this.proxy.processor
          },
          on: {
            input: event => {
              this.proxy.properties = event
            }
          }
        }
      )
    ]
  )
}
