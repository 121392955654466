import { mapGetters } from 'vuex'
import { RECURRENT_PAYMENTS_STATUSES, RECURRENT_PAYMENTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { backendServerIP, generateServices, getPrice, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'RecurrentPaymentsItem',

  mixins: [
    generateServices({
      name: services.recurrentPayments,

      find: false,
      create: false,

      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    isPage: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({ token: 'authentication/token' }),

    result() {
      switch (this.value.type) {
        case RECURRENT_PAYMENTS_TYPES.threshold: {
          return this.getTranslate(`${services.recurrentPayments}.contents.${this.value.type}`, { threshold: getPrice(this.value.threshold) })
        }
        case RECURRENT_PAYMENTS_TYPES.frequency: {
          return this.getTranslate(`tasks.frequencies.${this.value.frequency}`)
        }
      }
    },
    isPaused() {
      return this.value.status === RECURRENT_PAYMENTS_STATUSES.pause
    },

    status() {
      if (this.isPage) {
        return this.getTranslate(`${services.recurrentPayments}.statuses.${this.value.status}`)
      }

      return this.getTranslate(`${services.recurrentPayments}.hints.statuses.${this.value.status}`)
    },

    gridTemplateColumns() {
      const result = [ 'repeat(2, minmax(60px, 120px))', 'minmax(80px, 250px)' ]
      result.unshift(this.viewport.breakpoint.lgUp ? 'minmax(38px, 180px)' : '38px')

      if (this.checkPermissions('advanced.users.get', permissionPresets.true)) {
        if (this.viewport.breakpoint.mdUp && this.isPage) {
          result.push('minmax(80px, 150px)')
        }
      }

      return result.join(' ')
    }
  },

  methods: {
    async repeatRecurrentPayment() {
      if (this.checkPermissions(`advanced.${services.recurrentPayments}.update`, permissionPresets.meUp)) {
        if (~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status)) {
          try {
            const url = new URL(`/api/${services.recurrentPayments}/${this.value.id}/reInit`, backendServerIP)
            const urlSearchParams = new URLSearchParams({ method: this.value.method })
            url.search = urlSearchParams.toString()

            const response = await fetch(url.toString(), {
              method: 'get',
              headers: { authorization: this.token }
            })
            if (response.ok) {
              const { url } = await response.json()
              if (url) {
                window.location.href = url
              }
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    async activateRecurrentPayment() {
      if (this.checkPermissions(`advanced.${services.recurrentPayments}.update`, permissionPresets.meUp)) {
        if (!~[ RECURRENT_PAYMENTS_STATUSES.init, RECURRENT_PAYMENTS_STATUSES.error ].indexOf(this.value.status)) {
          try {
            await this.rest[services.recurrentPayments].update(this.value.id, Object.assign(
              {},
              this.value,
              { status: this.isPaused ? RECURRENT_PAYMENTS_STATUSES.ready : RECURRENT_PAYMENTS_STATUSES.pause }
            ))
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    }
  },

  render
}
