export const PATTERNS_FILTER = {
  $search: undefined,
  $scope: [ 'full' ],
  type: undefined,
  OwnerId: undefined,
  moderation: undefined,
  isActive: true
}

export default { PATTERNS_FILTER }
