import { cloneDeep, get } from 'lodash'

import { DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD, buttonModes, colors, components, getPrice, icons, inputModes, services, sizes } from '@/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import InvoiceDialog from '@/components/services/notifications/dialog/balance/limit/InvoiceDialog'

const path = 'notifications.events.balance.limit'

function renderLimitTag(h, limit) {
  let amount = 0
  if (limit.includeOverdraft) {
    amount = getPrice(limit.balance - this.overdraft)
  } else {
    amount = getPrice(limit.balance)
  }

  return h(
    tag,
    {
      props: {
        label: this.getTranslate(`${services.settingsUser}.hints.${path}.title`, { amount })
      }
    }
  )
}
function renderLimitField(h, limit) {
  return h(
    components['text-field'],
    {
      props: {
        value: limit.balance,
        label: this.getTranslate(`${services.settingsUser}.labels.${path}.limit`),
        defaultValue: 0,
        clearable: true,
        autofocus: true,
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        details: false,
        suffix: this.getTranslate(`currency.${this.globalSettings.billings.currency}.symbol.unicode`),
        type: 'number',
        step: 'any'
      },
      on: {
        input: event => {
          limit.balance = event
        }
      }
    }
  )
}
function renderLimitButton(h, limit) {
  return h(
    components.menu,
    {
      props: {
        value: this.showLimitMenu,
        closeOnContentClick: false,
        transparent: true,
        maxWidth: 150,
        offsetDistance: 8,
        placement: 'left'
      },
      on: {
        input: event => {
          this.showLimitMenu = event
        }
      }
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.edit,
            mode: buttonModes.flat,
            size: sizes.small
          },
          on: {
            click: () => {
              this.showLimitMenu = true
            }
          },
          slot: 'activator'
        }
      ),

      renderLimitField.call(this, h, limit)
    ]
  )
}
function renderPanelHeader(h, limit, index) {
  return h(
    'div',
    {
      class: `${services.notifications}-events-balance-limit__panel-header`
    },
    [
      renderLimitTag.call(this, h, limit),
      renderLimitButton.call(this, h, limit, index)
    ]
  )
}
function renderOverdraftButton(h, limit) {
  const props = {}
  if (limit.includeOverdraft) {
    props.label = this.getTranslate(`${services.settingsUser}.labels.${path}.include.overdraft`, { overdraft: getPrice(this.overdraft) })
    props.color = colors.success
  } else {
    props.label = this.getTranslate(`${services.settingsUser}.labels.${path}.notInclude.overdraft`)
    props.color = colors.error
  }

  return h(
    button,
    {
      props: {
        label: props.label,
        mode: buttonModes.flat,
        size: sizes.tiny,
        color: props.color
      },
      on: {
        click: () => {
          limit.includeOverdraft = !limit.includeOverdraft
        }
      }
    }
  )
}
function renderInvoiceButton(h, limit, index) {
  const props = {}
  if (limit.invoice) {
    const amount = get(limit, 'invoice.data.SERVICE_PRICE')
    if (amount) {
      props.label = this.getTranslate(`${services.settingsUser}.labels.${path}.include.invoice`, { amount: getPrice(amount) })
      props.color = colors.success
    }
  } else {
    props.label = this.getTranslate(`${services.settingsUser}.buttons.${path}.invoice.attach`)
    props.color = colors.primary
  }

  return h(
    button,
    {
      props: {
        label: props.label,
        mode: buttonModes.flat,
        size: sizes.tiny,
        color: props.color
      },
      on: {
        click: () => {
          if (!limit.invoice) {
            limit.invoice = cloneDeep(DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD)
          }
          this.index = index
          this.showInvoiceDialog = true
        }
      }
    }
  )
}
function renderRemoveButton(h, index) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.remove'),
            mode: buttonModes.flat,
            size: sizes.tiny,
            color: colors.error
          },
          on: {
            click: () => {
              this.remove(index)
            }
          }
        }
      )
    ]
  )
}
function renderPanelFooter(h, limit, index) {
  return h(
    'div',
    {
      class: `${services.notifications}-events-balance-limit__panel-footer`
    },
    [
      renderOverdraftButton.call(this, h, limit, index),
      renderInvoiceButton.call(this, h, limit, index),
      renderRemoveButton.call(this, h, index)
    ]
  )
}
function renderPanel(h, limit, index) {
  return h(
    'div',
    {
      class: `${services.notifications}-events-balance-limit__panel`,
      key: index
    },
    [
      renderPanelHeader.call(this, h, limit, index),
      renderPanelFooter.call(this, h, limit, index)
    ]
  )
}
function renderPanels(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    return h(
      'div',
      {
        class: `${services.notifications}-events-balance-limit__panels`
      },
      [ this.proxy.map((limit, index) => renderPanel.call(this, h, limit, index)) ]
    )
  }
}

function renderAddLimitButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.add'),
        mode: buttonModes.flat,
        size: sizes.small
      },
      on: {
        click: () => {
          this.add()
        }
      }
    }
  )
}
function renderSaveLimitButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.save'),
        mode: buttonModes.flat,
        size: sizes.small,
        color: colors.primary
      },
      on: {
        click: () => {
          this.$emit('input', this.proxy)
        }
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8'
    },
    [
      renderAddLimitButton.call(this, h),
      renderSaveLimitButton.call(this, h)
    ]
  )
}

function renderInvoiceDialog(h) {
  if (Array.isArray(this.proxy) && this.proxy.length) {
    if (this.proxy[this.index].invoice) {
      return h(
        InvoiceDialog,
        {
          props: {
            value: this.proxy[this.index].invoice,
            show: this.showInvoiceDialog,
            OwnerId: this.UserId,
            ResellerId: this.ResellerId
          },
          key: this.index,
          on: {
            input: event => {
              this.proxy[this.index].invoice = event
            },
            show: ({ event, validation }) => {
              this.showInvoiceDialog = event
              if (!event && !validation) {
                this.proxy[this.index].invoice = false
              }
            }
          }
        }
      )
    }
  }
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        title: this.getTranslate(`${services.settingsUser}.subtitles.${path}`) + ' ' + this.getTranslate(`${services.sendings}.types.email`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [
      renderPanels.call(this, h),
      renderFooter.call(this, h),
      renderInvoiceDialog.call(this, h)
    ]
  )
}
