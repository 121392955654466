import { serviceTemplate } from '@/utils'
import { serviceName } from '@/components/services/providers/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      find: {
        defaultFilter: {
          ServerId: undefined,
          CredentialsId: undefined,
          isActive: true,
          $scope: [ 'Server', 'Credentials' ]
        }
      },

      view: 'panels',
      views: [ 'panels' ],

      has: { view: false }
    })
  ]
}
