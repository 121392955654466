import { buttonModes } from '@/utils'

import button from '@/components/button'
import ContactsForm from '@/components/services/contacts/form'

function renderFields(h) {
  return h(
    ContactsForm,
    {
      class: {
        'faic grid-gap--8': true,
        'grid-cols--4': this.viewport.breakpoint.mdUp,
        'grid-cols--2': this.viewport.breakpoint.sm,
        'grid grid-gap--12': this.viewport.breakpoint.xs
      },
      props: {
        value: this.proxy,
        isFilter: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderClearButton(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('filter.clear'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.clearFilter()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 px-2 pb-2',
      props: {
        rounded: true,
        outline: true
      }
    },
    [

      renderFields.call(this, h),
      renderClearButton.call(this, h)
    ]
  )
}
