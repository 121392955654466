export default {
  title: 'WebSockets',

  labels: {
    connected: 'Connected via WebSockets',
    disconnected: 'Disconnected from WebSockets'
  },

  tooltips: {
    connection: 'This status means that your current tab is connected to our platform via WebSockets protocol. This means that you will receive updates of statuses, balance, etc. in real time. If you see that this status is disabled, you need to reload the page. If it does not help, check your internet connection. If the internet connection is OK, then we may have technical problems. Try to reload the page later.'
  }
}
