import { services } from '@/utils'

import owner from '@/components/serviceTemplate/item/owner'
import actions from '@/components/serviceTemplate/item/actions'
import date from '@/components/serviceTemplate/item/date'
import sendingType from '@/components/services/patterns/item/type'
import title from '@/components/services/patterns/item/title'
import patternText from '@/components/services/patterns/item/text'
import moderation from '@/components/services/patterns/item/moderation'

function renderType(h) {
  return h(
    sendingType,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderTitle(h) {
  return h(
    title,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderOwner(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderPatternText(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      patternText,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderModeration(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      moderation,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderServiceButton(h) {
  return h(
    actions,
    {
      props: {
        value: this.value,
        service: services.patterns
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.patterns}-panel__info`,
      style: { gridTemplateColumns: this.gridTemplateColumns }

    },
    [
      renderType.call(this, h),
      renderTitle.call(this, h),
      renderModeration.call(this, h),
      renderOwner.call(this, h),
      renderPatternText.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${services.patterns}-panel`]: true,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderServiceButton.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
