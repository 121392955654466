import formatTable from '@/components/format/table'

import { SOURCE_TYPE_COLORS, getPrice, sendingTypeColors } from '@/utils'

import { serviceName } from '@/components/services/billings/utils'

const _ = { get: require('lodash/get') }

function renderStatus(h, status) {
  if (status) {
    const billingsStatus = {
      pending: {
        name: 'pending',
        color: 'info',
        icon: 'query_builder'
      },
      refunded: {
        name: 'refunded',
        color: 'info',
        icon: 'replay'
      },
      complete: {
        name: 'complete',
        color: 'success',
        icon: 'done'
      },
      invalid: {
        name: 'invalid',
        color: 'error',
        icon: 'clear'
      }
    }

    const statusName = _.get(billingsStatus, `${status}.name`)
    const statusIcon = _.get(billingsStatus, `${status}.icon`)
    const statusColor = _.get(billingsStatus, `${status}.color`)

    return h(
      'g-chip',
      {
        class: 'text-upper',
        props: {
          small: true,
          outline: true,
          icon: statusIcon,
          color: statusColor,
          label: this.getTranslate(`${serviceName}.statuses.${statusName}`)
        }
      }
    )
  }
}

function renderHeader(h, { source, status, amount }) {
  const [ s, t ] = source.split('.')
  const type = t || s

  return h(
    'div',
    {
      class: 'faic px-3'
    },
    [
      h(
        'g-chip',
        {
          class: 'ml-0 text-upper',
          props: {
            small: true,
            outline: true,
            color: sendingTypeColors[type] || SOURCE_TYPE_COLORS[type],
            label: this.getTranslate(`${serviceName}.sources.${type}`)
          }
        }
      ),

      renderStatus.call(this, h, status),

      h(
        'g-chip',
        {
          props: {
            small: true,
            outline: true,
            color: 'primary',
            label: getPrice(amount)
          }
        }
      )
    ]
  )
}

function renderBody(h, value) {
  return h(
    formatTable,
    {
      props: {
        value,
        service: serviceName
      }
    }
  )
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      renderHeader.call(this, h, item),
      renderBody.call(this, h, item)
    ]
  )
}

function renderItems(h) {
  if (Array.isArray(this.data) && this.data.length) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 py-3'
      },
      this.data.map(item => renderItem.call(this, h, item))
    )
  }

  return h(
    'div',
    {
      class: 'faic fjcc'
    },
    [ h('g-empty') ]
  )
}

export default function(h) {
  return renderItems.call(this, h)
}
