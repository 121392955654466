import svgIcon from '@/components/icon/svg'

const className = 'ws'

export default function(h) {
  return h(
    'div',
    {
      class: `${className}-connection`,
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${className}.tooltips.connection`)
          }
        }
      ]
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: 'ws',
            color: this.ws.connected ? 'success' : 'error'
          }
        }
      ),

      h(
        'div',
        {
          class: `${className}-connection__label text--grey`
        },
        [ this.getTranslate(`${className}.labels.${this.ws.connected ? 'connected' : 'disconnected'}`) ]
      )
    ]
  )
}
