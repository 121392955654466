import Vue from 'vue'

import { isEqualWith } from 'lodash'

import { RequestCollector, globalErrorHandler, globalErrorProcessor, projectName } from '@/utils'

const requestCollector = new RequestCollector({
  request: async data => {
    const response = await Vue.$GRequest._request({
      method: 'POST',
      url: '/dictionaries/getOperatorsData',
      headers: { Authorization: window.localStorage.getItem(`${projectName}:token`) },
      data: { data }
    })

    return response.data || []
  },

  dataFilter: (data, bucket) => {
    const result = []
    const customizer = (d, b) => {
      d = Array.isArray(d) ? d : [ d ]
      b = Array.isArray(b) ? b : [ b ]

      return d.every(item => ~b.indexOf(item))
    }

    for (const { mcc, mnc } of data) {
      const bucketIndexByMcc = bucket.findIndex(item => item.mcc === mcc)
      const bucketElement = bucket[bucketIndexByMcc]
      if (~bucketIndexByMcc) {
        if (bucketElement.mnc === 'all' || isEqualWith(mnc, bucketElement.mnc, customizer)) {
          continue
        }
      }

      result.push({
        mcc,
        mnc
      })
    }

    return result
  }
})

export const mccObjectToArray = {
  props: {
    value: {
      type: Object,
      default: () => ({})
    },
    count: {
      type: Number,
      default: 3
    }
  },

  data() {
    return {
      loading: false,

      search: undefined,

      groups: []
    }
  },

  computed: {
    values() {
      const result = []

      if (this.value) {
        for (const mcc in this.value) {
          const mnc = this.value[mcc]
          switch (true) {
            case Array.isArray(mnc): {
              for (const item of mnc) {
                result.push({
                  mcc,
                  mnc: item
                })
              }
              break
            }
            case typeof mnc === 'string': {
              result.push({
                mcc,
                mnc: '00',
                fullCountry: true
              })
              break
            }
          }
        }
      }

      return result.reduce((result, { mcc, mnc, fullCountry }) => {
        const group = this.groups.find(group => group.mcc === mcc && group.mnc === mnc) || {}
        const { country, operator } = group

        let check = true

        if (this.search) {
          const search = `${this.search}`.toLowerCase()
          const values = [ mcc, mnc, country, operator ].map(value => `${value}`.toLowerCase())

          check = !!~values.findIndex(element => element.includes(search))
        }

        if (check) {
          result.push({
            mcc,
            mnc,
            country,
            operator,
            fullCountry
          })
        }

        return result
      }, []).sort((a, b) => a.mcc - b.mcc)
    }
  },

  watch: {
    value: {
      handler() {
        if (!this.loading) {
          this.find()
        }
      },
      deep: true
    }
  },

  mounted() {
    this.find()
  },

  methods: {
    async find() {
      try {
        this.loading = true

        if (this.value) {
          const parsedValue = Object.keys(this.value).map(mcc => {
            return {
              mcc,
              mnc: Array.isArray(this.value[mcc]) ? this.value[mcc].sort() : this.value[mcc]
            }
          })
          const response = await requestCollector.getData(parsedValue)
          if (response) {
            this.groups = response
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  }
}

export default { mccObjectToArray }
