export default {
  title: 'Список контактов',

  contactList: 'Список контактов',

  labels: {
    title: 'Название адресной книги',
    type: 'Тип',
    exportTo: 'Экспортировать в'
  },

  titles: {
    export: 'Экспорт',
    create: 'Создать новый список контаков'
  },

  types: {
    regular: 'Обычный',
    blacklist: 'Черный список'
  },

  exportTypes: {
    new: 'Новый список контактов',
    existing: 'Существующий список контактов',
    file: 'Файл'
  },

  dialogs: {
    titles: {
      create: 'Создать список контаков',
      update: 'Изменить список контаков',
      remove: 'Удалить список контактов'
    },
    createAndCopy: 'Создать и скопировать список контактов'
  },

  emptyState: {
    title: 'Выберите список контактов',
    description: 'Выберите список контактов в левой части страницы или {0}'
  },

  buttons: {
    create: 'Создать список контактов',
    createAndCopy: 'Создать и скопировать',
    remove: 'Удалить'
  },

  tooltips: {
    blacklist: 'Черный список',
    create: 'Создать новый список контаков',
    remove: 'Удалить список контактов',
    import: 'Импортировать контакты в текущий список контактов из файла'
  },

  stepper: {
    headers: {
      1: 'Выбрать тип экспорта',
      2: {
        new: 'Создать новый список контактов и экспортировать в него',
        existing: 'Выбрать список контаков и экспортировать в него',
        file: 'Экспорт в файл'
      }
    }
  },

  contents: {
    confirm: {
      remove: 'Вы уверены что хотите удалить список контаков?'
    }
  },

  snackbars: {
    created: 'Список контатков успешно создан.',
    updated: 'Список контатков успешно обновлён.',
    removed: 'Список контатков успешно удалён.'
  }
}
