export default {
  name: 'Row',

  props: {
    index: Number,
    source: {
      type: Object,
      default: () => ({})
    }
  },

  render(h) {
    if (this.$scopedSlots.item) {
      return this.$scopedSlots.item(this.source, this.index)
    }

    return h('div', { class: 'text--error' }, 'Scoped slot ITEM is required.')
  }
}
