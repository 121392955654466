import sticker from '@/components/misc/sticker'

import { serviceName } from '@/components/services/apisender/utils'

export default function(h) {
  if (this.viewport.size.width > 375) {
    return h(
      sticker,
      {
        props: {
          value: this.getTranslate(`${serviceName}.states.isPaid.${this.value.isPaid}`),
          label: this.getTranslate(`${serviceName}.labels.isPaid`),
          color: this.value.isPaid ? 'success' : 'error',
          icon: this.value.isPaid ? 'attach_money' : 'money_off',
          iconTooltip: this.getTranslate(`${serviceName}.states.isPaid.${this.value.isPaid}`),
          borderStyle: 'dashed'
        }
      }
    )
  }
}
