import OwnerSelect from '@/components/misc/OwnerSelect'

import { serviceName } from '@/components/services/contracts/utils'

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderRequisiteSelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.RequisiteId,
        label: this.getTranslate(`${serviceName}.labels.requisite`),
        service: 'requisites',
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        autocomplete: true
      },
      on: {
        input: event => {
          this.proxy.RequisiteId = event
        }
      }
    }
  )
}

function renderCompanySelect(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.CompanyId,
        label: this.getTranslate(`${serviceName}.labels.company`),
        service: 'companies',
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        autocomplete: true
      },
      on: {
        input: event => {
          this.proxy.CompanyId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: {
        gridTemplateColumns: this.gridTemplateColumns
      }
    },
    [
      renderOwnerSelect.call(this, h),
      renderRequisiteSelect.call(this, h),
      renderCompanySelect.call(this, h)
    ]
  )
}
