import { SENDING_HASHABLE_FIELDS } from '@sigma-legacy-libs/essentials/lib/constants'

export default function(h, { path, serviceName }) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        items: SENDING_HASHABLE_FIELDS,
        mode: 'outline-label',
        dense: true,
        rounded: true,
        details: false,
        multiple: true,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
