import { mapActions, mapGetters } from 'vuex'

import { balance } from '@/utils'

import render from './render'

export default {
  name: 'UserWidget',

  mixins: [ balance() ],

  props: {
    flat: Boolean,
    rounded: Boolean
  },

  computed: {
    ...mapGetters({ isLoggedAs: 'login/isLoggedAs' }),

    $name() {
      return [ this.account.data.firstName, this.account.data.lastName ].filter(item => !!item).join(' ')
    }
  },

  methods: {
    ...mapActions({
      logout: 'login/logout',
      loginAs: 'login/loginAs'
    })
  },

  render
}
