import { buttonModes, colors, icons, inputModes } from '@/utils'

import button from '@/components/button'

function renderLoginField(h, path, serviceName) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.login,
        label: this.getTranslate(`${serviceName}.labels.${path}.login`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        required: true
      },
      on: {
        input: event => {
          this.proxy.login = event
        }
      }
    }
  )
}
function renderKeyField(h, path, serviceName) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.security_key,
        label: this.getTranslate(`${serviceName}.labels.${path}.security_key`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        required: true
      },
      on: {
        input: event => {
          this.proxy.security_key = event
        }
      }
    }
  )
}
function renderConfirmButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.done,
        color: colors.success,
        mode: buttonModes.flat,
        disabled: this.disabled,
        tooltip: this.getTranslate('misc.buttons.save')
      },
      on: {
        click: () => {
          this.$emit('input', this.proxy)
        }
      }
    }
  )
}
function renderClearButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: icons.clear,
        color: colors.error,
        tooltip: this.getTranslate('misc.buttons.clear'),
        disabled: this.clearDisabled
      },
      on: {
        click: () => {
          this.proxy = {
            login: undefined,
            security_key: undefined
          }
          this.$emit('input', this.proxy)
        }
      }
    }
  )
}

function renderCardContent(h, path, serviceName) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: 'repeat(2, 1fr) repeat(2, 38px)' }
    },
    [
      renderLoginField.call(this, h, path, serviceName),
      renderKeyField.call(this, h, path, serviceName),
      renderConfirmButton.call(this, h),
      renderClearButton.call(this, h)
    ]
  )
}

export default function(h, { path, serviceName }) {
  return h(
    'g-card',
    {
      class: 'pt-3 pb-2 px-2',
      props: {
        title: this.getTranslate(`${serviceName}.subtitles.${path}`),
        dashed: true,
        outline: true,
        rounded: true
      }
    },
    [ renderCardContent.call(this, h, path, serviceName) ]
  )
}
