import { services } from '@/utils'

import squircle from '@/components/icon/squircle'
import sticker from '@/components/misc/sticker'
import svgIcon from '@/components/icon/svg'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.providerGroups}.labels.title`),
        service: services.providerGroups,
        width: 'fit-content'
      }
    },
    [
      h(
        squircle,
        {
          props: { color: this.value.type },
          slot: 'icon'
        },
        [
          h(
            svgIcon,
            {
              props: {
                value: this.value.type,
                color: this.value.type,
                size: 18
              }
            }
          )
        ]
      )
    ]
  )
}
