import { mapGetters } from 'vuex'

import { inputModes, proxy, services } from '@/utils'

import render from './render'

export default {
  name: 'PaymentsMethodSelect',

  mixins: [ proxy() ],

  props: {
    items: {
      type: Array,
      default: undefined
    },

    mode: {
      type: String,
      default: inputModes.outline,
      validator: value => Object.values(inputModes).includes(value)
    },

    as: {
      type: String,
      default: 'select',
      validator: value => [ 'select', 'buttons' ].includes(value)
    },

    dense: Boolean,
    clearable: Boolean,
    required: Boolean,
    disabled: Boolean
  },

  computed: {
    ...mapGetters({ paymentsMethods: 'payments/methods' }),

    $items() {
      const result = this.items || this.paymentsMethods

      return result.map(value => ({
        title: this.getTranslate(`${services.payments}.methods.${value}`),
        value
      }))
    },

    $disabled() {
      if (this.disabled) {
        return true
      }

      if (this.required && this.$items.length === 1) {
        return true
      }
    }
  },

  mounted() {
    if (this.required && !this.proxy) {
      this.proxy = this.$items[0].value
    }
  },

  render
}
