import { buttonModes, colors, permissionPresets, services, sizes } from '@/utils'

import tag from '@/components/tag'

export default function(h) {
  if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`, permissionPresets.true)) {
    if (this.count > 0) {
      return h(
        tag,
        {
          props: {
            size: sizes.small,
            label: this.count,
            color: colors.primary,
            mode: buttonModes.filled
          }
        }
      )
    }
  }
}
