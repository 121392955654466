import { permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
    if (this.hasOwner) {
      return h(
        sticker,
        {
          props: this.$props
        }
      )
    }
  }
}
