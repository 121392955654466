import { components, numberToPxOrString } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      class: {
        [components.divider]: true,
        [`${components.divider}--${this.type}`]: true
      },
      style: {
        'border-style': this.border.style,
        'border-color': this.border.color,
        [`border-${this.type === 'horizontal' ? 'top' : 'left'}-width`]: numberToPxOrString(this.border.size)
      }
    }
  )
}
