import Vue from 'vue'

import { cloneDeep } from 'lodash'

import { DEFAULT_PAYMENT_PAYLOAD, backendServerIP, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'Template',

  data() {
    return {
      loading: false,

      payload: cloneDeep(DEFAULT_PAYMENT_PAYLOAD)
    }
  },

  watch: {
    $route: {
      handler() {
        this.setMethod()
        this.setAmount()
        this.setOwner()
      },
      deep: true
    }
  },

  mounted() {
    this.setMethod()
    this.setAmount()
    this.setOwner()
  },

  methods: {
    async requestPayment() {
      const amount = this.payload.amount
      if (amount && this.payload.acceptOffer) {
        try {
          this.loading = true

          const method = this.payload.method
          const OwnerId = this.payload.OwnerId

          const { data } = await Vue.$GRequest._request({
            url: `pay/${OwnerId}`,
            method: 'post',
            data: {
              method,
              amount,
              urlSuccess: `${backendServerIP}/api/pay/webhook/${method}/success/`,
              urlFail: `${backendServerIP}/api/pay/webhook/${method}/fail/`,
              OwnerId
            }
          })

          if (data && data.url) {
            window.location.href = data.url
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading = false
        }
      }
    },

    setMethod() {
      const method = this.$route.query.method
      if (method) {
        this.payload.method = method
      }
    },
    setAmount() {
      const amount = this.$route.query.amount
      if (amount) {
        this.payload.amount = amount
      }
    },
    setOwner() {
      const owner = this.$route.params.owner
      if (owner && !this.payload.OwnerId) {
        this.payload.OwnerId = owner
      }
    }
  },

  render
}
