export default function(h) {
  return h(
    'i',
    {
      class: this.$class,
      style: this.$style,
      on: this.$eventHandlers
    }
  )
}
