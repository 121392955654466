import FileManager from '@/components/file/manager'
import FileDialog from '@/components/file/dialog'
import FileItemCard from '@/components/file/item/card'
import FileMenu from '@/components/file/menu'
import settingRow from '@/components/services/settings/row'

const name = 'storage'

function renderDefaultState(h) {
  return h(
    settingRow,
    {
      props: {
        title: this.title,
        label: this.hint,
        icon: this.icon
      },
      on: {
        click: () => {
          this.showFileManager = true
        }
      }
    }
  )
}

function renderContent(h) {
  if (this.proxy) {
    if (this.restData[name].get.state === 'ready') {
      if (this.restData[name].get.data) {
        return h(
          'div',
          {
            class: 'fc'
          },
          [
            h('div', { class: 'subtitle-1 mb-2' }, this.title),

            h(
              FileItemCard,
              {
                props: { value: this.restData[name].get.data }
              },
              [
                h(
                  FileMenu,
                  {
                    props: {
                      value: this.restData[name].get.data,
                      include: [ 'edit', 'download', 'export' ]
                    },
                    on: {
                      dialog: event => {
                        this.currentEditFile = event.currentEditFile
                        this.googleFileURL = event.googleFileURL
                        this.editDialogType = event.editDialogType
                        this.showEditDialog = true
                      }
                    }
                  },
                  [
                    h(
                      'g-list-item',
                      {
                        props: {
                          icon: 'clear',
                          color: 'error',
                          label: this.getTranslate('misc.buttons.remove'),
                          dense: true
                        },
                        on: {
                          click: () => {
                            this.proxy = undefined
                          }
                        }
                      }
                    )
                  ]
                )
              ]
            )
          ]
        )
      } else {
        return renderDefaultState.call(this, h)
      }
    } else {
      return h('g-progress', { props: { indeterminate: true } })
    }
  } else {
    return renderDefaultState.call(this, h)
  }
}

function renderFileManager(h) {
  return h(
    FileManager,
    {
      props: {
        currentChosenFileId: this.proxy,
        flat: true,
        readonly: true,
        embedded: true,
        isPublic: true,
        show: this.showFileManager,
        types: [ 'doc', 'docx', 'pdf' ]
      },
      on: {
        show: event => {
          this.showFileManager = event
        },
        input: event => {
          this.proxy = event.id
          this.showFileManager = false
        },
        finish: event => {
          this.proxy = event.id
          this.showFileManager = false
        }
      }
    }
  )
}

function renderFileDialog(h) {
  return h(
    FileDialog,
    {
      props: {
        value: this.currentEditFile,
        googleFileURL: this.googleFileURL,
        show: this.showEditDialog,
        type: this.editDialogType
      },
      on: {
        show: event => {
          this.showEditDialog = event
        },
        input: event => {
          this.currentEditFile = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderContent.call(this, h),
      renderFileManager.call(this, h),
      renderFileDialog.call(this, h)
    ]
  )
}
