export const TASKS_FILTER = {
  id: undefined,
  ListId: undefined,
  OwnerId: undefined,
  CreatorId: undefined,
  state: undefined,
  type: undefined,
  isActive: true,
  startsAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAtPreset: 'today'
}

export default { TASKS_FILTER }
