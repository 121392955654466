import { colors, components, isHEX, numberToPxOrString, size } from '@/utils'

import render from './render'

export default {
  name: components.footer,

  mixins: [ size ],

  props: {
    fixed: Boolean,

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    }
  },

  computed: {
    $class() {
      return {
        [components.footer]: true,

        [`${components.footer}--dense`]: this.dense,
        [`${components.footer}--fixed`]: this.fixed,

        [`${components.footer}--${this.color}`]: !!this.color && !isHEX(this.color)
      }
    },

    $style() {
      return {
        color: isHEX(this.color) ? this.color : null,
        'min-height': numberToPxOrString(this.minHeight),
        'max-height': numberToPxOrString(this.maxHeight),
        height: numberToPxOrString(this.height),
        'min-width': numberToPxOrString(this.minWidth),
        'max-width': numberToPxOrString(this.maxWidth),
        width: numberToPxOrString(this.width)
      }
    }
  },

  mounted() {
    const footer = this.$refs.footer
    if (footer) {
      const height = footer.offsetHeight
      const content = document.querySelector('.g-content')
      if (content) {
        const { paddingBottom } = getComputedStyle(content)
        content.style.paddingBottom = parseInt(paddingBottom) + height + 'px'
      }
    }
  },

  render
}
