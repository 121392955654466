import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'RoutesFormTitle',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    serviceName: {
      type: String,
      required: true
    },

    showIcon: {
      type: Boolean,
      default: true
    },

    showSwitch: {
      type: Boolean,
      default: true
    },

    disabled: Boolean
  },

  computed: {
    gridTemplateColumns() {
      const result = []
      result.push('1fr')

      if (this.showSwitch) {
        result.push('38px')
      }

      return result.join(' ')
    }
  },

  render
}
