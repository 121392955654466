export default {
  unknown: {
    types: 'Unknown error with type {type}',
    fields: 'Unknown error with field {field}'
  },

  types: {
    required: 'The {field} field is required!',
    string: 'The {field} field must be a string!',
    stringEmpty: 'The {field} field must not be empty!',
    stringMin: 'The {field} field length must be larger than or equal to {expected} characters long!',
    stringMax: 'The {field} field length must be less than or equal to {expected} characters long!',
    stringLength: 'The {field} field length must be {expected} characters long!',
    stringPattern: 'The {field} field fails to match the required pattern!',
    stringContains: 'The {field} field must contain the {expected} text!',
    stringEnum: 'The {field} field does not match any of the allowed values!',
    number: 'The {field} field must be a number!',
    numberMin: 'The {field} field must be larger than or equal to {expected}!',
    numberMax: 'The {field} field must be less than or equal to {expected}!',
    numberEqual: 'The {field} field must be equal with {expected}!',
    numberNotEqual: 'The {field} field can`t be equal with {expected}!',
    numberInteger: 'The {field} field must be an integer!',
    numberPositive: 'The {field} field must be a positive number!',
    numberNegative: 'The {field} field must be a negative number!',
    array: 'The {field} field must be an array!',
    arrayEmpty: 'The {field} field must not be an empty array!',
    arrayMin: 'The {field} field must contain at least {expected} items!',
    arrayMax: 'The {field} field must contain less than or equal to {expected} items!',
    arrayLength: 'The {field} field must contain {expected} items!',
    arrayContains: 'The {field} field must contain the {expected} item!',
    arrayEnum: 'The {field} field value {expected} does not match any of the allowed values!',
    boolean: 'The {field} field must be a boolean!',
    function: 'The {field} field must be a function!',
    date: 'The {field} field must be a Date!',
    dateMin: 'The {field} field must be larger than or equal to {expected}!',
    dateMax: 'The {field} field must be less than or equal to {expected}!',
    forbidden: 'The {field} field is forbidden!',
    email: 'The {field} field must be a valid e-mail!',
    phoneNumber: 'The {field} field must be a valid phone number in any popular format!',
    uniqPhoneNumber: 'A contact with this phone number already exists.',
    invalid: 'The {field} field invalid!',
    uniqueViolation: 'The {field} field already exists!',
    fileMaxSize: 'Max file size exceeded! [{expected}]',
    duplicateSending: 'Duplicate sending.',
    overdraftLimitExceeded: 'Overdraft limit exceeded.',
    userNotFound: 'User with such login or e-mail not found',
    pattern: 'The field should contain only numbers',
    serverNotAvailable: 'Server is not available',
    invalidType: 'Invalid type',
    wrongLoginOrPassword: 'Wrong login or password',
    rateLimitExceeded: 'Rate limit exceeded. Retry after {count} seconds.',
    INVALID_JWT: 'Invalid token',
    clone: 'Failed to clone entity.',
    uuid: 'The {field} field must be a valid UUID'
  },

  fields: {
    login: 'login',
    name: 'name',
    username: 'username',
    password: 'password',
    owner: 'owner',
    OwnerId: 'owner',
    action: 'action',
    source: 'source',
    amount: 'amount',
    multiplier: 'multiplier',
    comment: 'comment',
    moderation: 'moderation',
    contact: {
      phone: 'phone'
    },
    payload: {
      sender: 'sender',
      text: 'text'
    },
    data: {
      comment: 'comment',
      sendingType: 'sending type',
      sender: 'sender',
      firstName: 'first name',
      lastName: 'last name',
      email: 'e-mail',
      phone: 'phone',
      sum: 'sum',
      multiplier: 'multiplier',
      button: {
        url: 'button url',
        text: 'button text'
      }
    },
    settings: {
      check: {
        protocol: 'protocol',
        processor: 'processor',
        credentials: {
          username: 'username',
          password: 'password'
        },
        server: {
          host: 'host',
          port: 'port'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrency',
          rpm: 'rpm'
        }
      },
      send: {
        protocol: 'protocol',
        processor: 'processor',
        credentials: {
          username: 'username',
          password: 'password'
        },
        server: {
          host: 'host',
          port: 'port'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrency',
          rpm: 'rpm'
        }
      },
      ping: {
        protocol: 'protocol',
        processor: 'processor',
        credentials: {
          username: 'username',
          password: 'password'
        },
        server: {
          host: 'host',
          port: 'port'
        },
        limits: {
          bulk: 'bulk',
          concurrency: 'concurrency',
          rpm: 'rpm'
        }
      },
      sendings: {
        anySendername: 'any sender name'
      },
      billing: {
        overdraft: 'overdraft'
      },
      websocket: {
        channels: 'channels'
      }
    },
    phone: 'phone',
    email: 'e-mail',
    firstName: 'first name',
    lastName: 'last name',
    middleName: 'middle name',
    gender: 'gender',
    title: 'title',
    type: 'type',
    key: 'key',
    opcode: 'operator code',
    group: 'group',
    groupName: 'group name',
    operatorName: 'operator name',
    button: {
      url: 'URL',
      text: 'text'
    },
    DictionaryId: 'dictionary id',
    mcc: 'mcc',
    mnc: 'mnc'
  }
}
