export default {
  title: 'Routing rules',

  titles: {
    create: 'Create routing rule'
  },

  routingRule: 'Routing rule',
  routingRules: 'Routing rules',
  one: 'Routing rule',
  many: 'Routing rules',
  condition: 'Condition',
  conditions: 'Conditions',
  ruleType: 'Rule type',
  percentage: 'Percentage',

  labels: {
    title: 'Rule title',
    type: 'Type',
    routingRule: 'Rule',
    condition: 'Condition',
    providerGroup: 'Providers group',
    percentage: 'Percentage',
    search: 'Search',
    textToCheck: 'Text to check',
    phone: 'Phone',
    isActive: 'Is active',

    group: {
      pattern: 'Pattern for search group'
    },

    add: {
      condition: 'Add condition'
    }
  },

  tabs: {
    eventsHistory: 'Events history'
  },

  dialogs: {
    new: 'New routing rule'
  },

  rules: {
    payload: {
      text: 'Text',
      sender: 'Sender',
      recipient: 'Recipient'
    },
    meta: {
      _recipientData: {
        code: 'Operator code',
        group: 'Operator group'
      }
    }
  },

  hints: {
    notFound: 'Routing rules not found',
    wrongType: 'Unsupported message type specified.',
    willBeCreated: 'Will be created',
    noRule: 'Condition not selected yet. Click on this line to select.',
    noRules: 'No conditions have been selected. Click add condition.',
    click: 'Click to edit.'
  },

  tooltips: {
    rules: 'List of rules for message routing',
    conditions: 'List of conditions for message routing',
    providerGroups: 'List of provider groups',
    isActive: 'Activate or deactivate a routing rule',
    favorites: 'Make the routing rule a favorite',

    add: {
      condition: 'Add condition'
    },

    filter: {
      isActive: 'Show only active or inactive routing rules'
    }
  },

  subtitles: {
    rules: 'Rules',
    conditions: 'Conditions',
    providerGroups: 'Provider groups'
  },

  buttons: {
    add: {
      condition: 'Add condition',
      providerGroup: 'Add provider group'
    },
    routingRule: 'Choose rule'
  },

  snackbars: {
    created: 'Routing rule successfully created.',
    updated: 'Routing rule successfully updated.',
    removed: 'Routing rule successfully removed.'
  },

  errors: {
    regexp: 'Regular expression error',
    phone: 'Phone not found'
  }
}
