import render from './render'

export default {
  name: 'Stepper',

  props: {
    type: String,

    value: {
      type: Array,
      default: () => []
    }
  },

  render
}
