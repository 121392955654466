export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': 'repeat(3, 36px)' }
    },
    [ 'small', 'medium', 'large' ].map(size => {
      return h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            round: true,
            color: this.proxy === size ? 'primary' : undefined,
            label: size.charAt(0)
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`commons.sizes.${size}`),
                placement: 'top'
              }
            }
          ],
          on: {
            click: () => {
              this.set(size)
            }
          }
        }
      )
    })
  )
}
