import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { REPORTS_ENCODINGS } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, delimiters, permissionPresets, platforms, processFilter, services } from '@/utils'

import render from './render'

export default {
  name: 'ButtonReport',

  mixins: [ proxy() ],

  props: {
    preset: {
      type: String,
      default: ''
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    icon: Boolean,

    type: {
      type: String,
      default: 'button'
    }
  },

  data() {
    const flags = {}
    switch (this.preset) {
      case 'sendings.details': {
        flags.includeRefunded = false
        break
      }
    }

    return {
      loading: false,

      encoding: REPORTS_ENCODINGS.win1251,

      delimiter: delimiters.semicolon,

      flags
    }
  },

  computed: {
    platformButtons() {
      return {
        [platforms.windows]: {
          mode: ~[ REPORTS_ENCODINGS.win1251, REPORTS_ENCODINGS.win1252 ].indexOf(this.encoding) ? buttonModes.filled : buttonModes.outline,
          encoding: REPORTS_ENCODINGS.win1251
        },
        [platforms.macintosh]: {
          mode: this.encoding === REPORTS_ENCODINGS.maccyrillic ? buttonModes.filled : buttonModes.outline,
          encoding: REPORTS_ENCODINGS.maccyrillic
        }
      }
    },

    encodingSettings() {
      return {
        encoding: {
          items: REPORTS_ENCODINGS.map(encoding => {
            return {
              title: this.getTranslate(`misc.encodings.${encoding}`),
              value: REPORTS_ENCODINGS[encoding]
            }
          })
        },
        delimiter: {
          items: Object.keys(delimiters).map(delimiter => {
            return {
              title: this.getTranslate(`misc.delimiters.${delimiter}`),
              value: delimiters[delimiter]
            }
          })
        }
      }
    }
  },

  mounted() {
    if (this.platform) {
      this.setEncodingPreset()
    }
  },

  methods: {
    setEncodingPreset() {
      switch (this.platform) {
        case platforms.macintosh: {
          this.encoding = REPORTS_ENCODINGS.maccyrillic
          break
        }

        default: {
          this.encoding = REPORTS_ENCODINGS.win1251
          break
        }
      }
    },

    getReport() {
      this.loading = true

      const params = {
        preset: this.preset,
        encoding: this.encoding,
        delimiter: this.delimiter,
        where: processFilter(this.filter, {}, [ 'createdAtPreset' ])
      }

      if (this.checkPermissions(`advanced.${services.reports}.generate`, permissionPresets.true)) {
        switch (this.preset) {
          case 'sendings.details': {
            params.flags = this.flags
            break
          }
        }
      }

      Vue.$GRequest.create(services.reports, params)

      this.addSnackbar({
        text: this.getTranslate(`${services.reports}.snackbars.requested`),
        type: 'success'
      })

      this.loading = false
      this.proxy = false
    }
  },

  render
}
