export default {
  title: 'Компании',

  titles: {
    create: 'Создать компанию',
    vat: 'НДС'
  },

  labels: {
    title: 'Название компании',
    vat: 'Порядок расчета',
    owner: 'Владелец'
  },

  vat: {
    true: 'С НДС',
    false: 'Без НДС'
  },

  hints: {
    notFound: 'Компании не найдены'
  },

  snackbars: {
    created: 'Компания успешно создана.',
    updated: 'Компания успешно обновлена.',
    removed: 'Компания успешно удалена.'
  }
}
