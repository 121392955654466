export default {
  hints: {
    tree: {
      country: 'country',
      operator: 'operator',
      group: 'group'
    }
  },

  snackbars: {
    created: 'Group created',
    updated: 'Group updated',
    removed: 'Group removed'
  },

  contents: {
    confirm: {
      remove: 'Are you sure you want to remove group {title}?'
    }
  },

  tabs: {
    all: 'All',
    checked: 'Checked'
  },

  pills: {
    all: 'Available',
    checked: 'Checked'
  }
}
