import Columns from '@/components/misc/Columns'

const name = 'dictionaries'
const service = 'operatorGroups'

export default function(h) {
  return h(
    'g-form',
    {
      class: 'fc faic pa-3',
      on: {
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      h(Columns, {}, [
        h('g-select', {
          props: {
            value: this.proxy.operator,
            label: this.getTranslate(`${name}.labels.operatorName`),
            error: this.errors.operator,
            service,
            query: {
              DictionaryId: this.dictionary.id,
              $scope: [ 'GroupByOperator' ]
            },
            itemTitle: 'operator',
            itemValue: 'operator',
            setOnBlur: true,
            clearable: true,
            autocomplete: true,
            combobox: true
          },
          on: {
            input: event => {
              this.proxy.operator = event
            }
          }
        }),

        h('g-select', {
          props: {
            value: this.proxy.group,
            label: this.getTranslate(`${name}.labels.group`),
            error: this.errors.group,
            service,
            query: {
              DictionaryId: this.dictionary.id,
              $scope: [ 'GroupByGroup' ]
            },
            itemTitle: 'group',
            itemValue: 'group',
            setOnBlur: true,
            clearable: true,
            autocomplete: true,
            combobox: true
          },
          on: {
            input: event => {
              this.proxy.group = event
            }
          }
        })
      ]),

      h(Columns, {}, [
        h('g-text-field', {
          props: {
            value: this.proxy.mcc,
            label: this.getTranslate(`${name}.labels.mcc`),
            error: this.errors.mcc,
            flat: true,
            required: true,
            type: 'number',
            step: 'any'
          },
          on: {
            input: event => {
              this.proxy.mcc = event
            }
          }
        }),
        h('g-text-field', {
          props: {
            value: this.proxy.mnc,
            label: this.getTranslate(`${name}.labels.mnc`),
            error: this.errors.mnc,
            flat: true,
            required: true,
            type: 'number',
            step: 'any'
          },
          on: {
            input: event => {
              this.proxy.mnc = event
            }
          }
        })
      ])
    ]
  )
}
