import { components, isEmpty } from '@/utils'
import { core } from '@/components/g/checkbox/mixins'

import render from './render'

export default {
  name: components.switch,

  mixins: [ core ],

  props: {
    label: String,
    loading: Boolean,
    tooltip: String,
    outline: Boolean
  },

  computed: {
    $attrs() {
      return {
        role: 'checkbox',
        'aria-checked': typeof this.proxy === 'boolean' ? `${this.proxy}` : 'mixed'
      }
    },
    $class() {
      return {
        [`${components.switch}`]: true,
        [`${components.switch}--checked`]: this.checked,
        [`${components.switch}--disabled`]: this.disabled,
        [`${components.switch}--unknown`]: isEmpty(this.proxy),
        [`${components.switch}--labeled`]: !!this.label,
        [`${components.switch}--outline`]: this.outline
      }
    },
    $directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.tooltip || this.title,
            placement: 'top'
          }
        })
      }

      return result
    }
  },

  render
}
