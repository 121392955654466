import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import paymentsOffer from '@/components/services/payments/offer'
import paymentsMethod from '@/components/services/payments/method'

function renderMethodField(h) {
  if (this.paymentsMethods.length > 0) {
    return h(
      paymentsMethod,
      {
        props: {
          value: this.proxy.method,
          disabled: this.loading,
          mode: inputModes['line-label'],
          as: 'buttons',
          required: true
        },
        on: {
          input: event => {
            this.proxy.method = event
          }
        }
      }
    )
  }
}
function renderAmountField(h) {
  if (this.proxy.method !== PAYMENTS_METHODS.stripe) {
    return h(
      components['text-field'],
      {
        props: {
          value: this.proxy.amount,
          label: this.getTranslate(`${services.payments}.labels.amount`),
          hint: this.commissionHint,
          suffix: this.commissionSuffix,
          disabled: this.loading,
          mode: inputModes['line-label'],
          type: 'number',
          name: 'amount',
          min: 1,
          step: 1,
          rounded: true,
          autofocus: true,
          clearable: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy.amount = event
          }
        }
      }
    )
  }
}
function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        label: this.getTranslate(`${services.payments}.hints.owner`),
        mode: inputModes['line-label'],
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderAmountAndOwner(h) {
  if (this.proxy.method) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderAmountField.call(this, h),
        renderOwnerField.call(this, h)
      ]
    )
  }
}
function renderForm(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--12'
    },
    [
      renderMethodField.call(this, h),
      renderAmountAndOwner.call(this, h)
    ]
  )
}

function renderOffer(h) {
  return h(
    paymentsOffer,
    {
      props: {
        value: this.proxy.acceptOffer,
        method: this.proxy.method
      },
      on: {
        input: event => {
          this.proxy.acceptOffer = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid grid-gap--8'
    },
    [
      renderForm.call(this, h),
      renderOffer.call(this, h)
    ]
  )
}
