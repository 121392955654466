import { mapGetters } from 'vuex'

export const currentTab = currentTab => {
  return {
    data() {
      return {
        currentTab
      }
    },

    computed: {
      ...mapGetters({ tab: 'currentTab/tab' })
    },

    watch: {
      $route: {
        handler() {
          this.setTabFromRoute()
        },
        deep: true
      },
      tab: {
        handler() {
          if (this.tab.name) {
            this.currentTab = this.tab.name
          }
        },
        deep: true
      }
    },

    mounted() {
      this.setTabFromRoute()
    },

    methods: {
      setTabFromRoute() {
        const { hash, query } = this.$route
        if (hash) {
          this.currentTab = hash.replace('#', '')
        } else if (query && query.currentTab && query.currentTab !== this.currentTab) {
          this.currentTab = query.currentTab
        }
      }
    }
  }
}

export default { currentTab }
