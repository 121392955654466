import { get } from 'lodash'
import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    type: {
      type: String,
      default: 'row',
      validator: value => {
        return !!~[ 'row', 'panel' ].indexOf(value)
      }
    }
  },

  computed: {
    messageOnModeration() {
      let moderation
      const contentHistory = get(this.value, 'meta.contentHistory')
      if (Array.isArray(contentHistory) && contentHistory.length) {
        moderation = get(contentHistory.at(-1), 'state.moderation')
      }

      return moderation === MODERATION_VALUES.pending
    }
  },

  render
}
