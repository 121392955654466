import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'CreatedAtPreset',

  mixins: [ proxy() ],

  computed: {
    items() {
      const presets = [
        'minutes.5',
        'minutes.15',
        'minutes.30',
        'hours.1',
        'hours.6',
        'today',
        'yesterday',
        'current.week',
        'last.week',
        'current.month',
        'last.month'
      ]

      return presets.map(preset => ({
        title: this.getTranslate(`filter.date.presets.${preset}`),
        value: preset
      }))
    }
  },

  render
}
