const fields = {
  title: 'Title',
  owner: 'Owner',
  requisite: 'Requisite',
  company: 'Company',
  createdAt: 'Created at',
  updatedAt: 'Updated at'
}

export default {
  title: 'Contracts',

  titles: {
    create: 'Create contract',
    upload: 'Upload existing contract',
    confirm: {
      remove: 'Confirm remove'
    }
  },

  headers: {
    ...fields
  },

  labels: {
    ...fields
  },

  buttons: {
    attach: 'Attach file'
  },

  actions: {
    edit: 'Edit',
    download: 'Download PDF',
    remove: 'Remove'
  },

  contents: {
    confirm: {
      remove: 'Confirm remove contract'
    }
  },

  tooltips: {
    edit: 'Edit file'
  },

  snackbars: {
    created: 'Contract successfully created.',
    updated: 'Contract successfully updated.',
    removed: 'Contract successfully removed.'
  }
}
