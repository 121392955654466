import toc from '@/components/services/documentation/toc'

const name = 'documentation'

function renderContent(h) {
  if (this.html) {
    return h(
      'g-card',
      {
        style: {
          'margin-top': this.viewport.breakpoint.smDown ? '60px' : null, // Для кнопки содержание
          'margin-bottom': this.viewport.breakpoint.mdUp ? '768px' : null // Для того чтобы можно было доскролить до нижних элементов
        }
      },
      [
        h(
          'div',
          {
            class: `${name}-content`,
            domProps: { innerHTML: this.html }
          }
        )
      ]
    )
  }
}

function renderToc(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(toc, { props: { headings: this.headings } })
  } else {
    return h(
      'div',
      {
        class: {
          [`${name}-toc-container`]: true,
          [`${name}-toc-container--min`]: this.scrollTop > 0,
          [`${name}-toc-container--auth`]: this.token
        }
      },
      [
        h(
          'g-menu',
          {
            class: 'w--100',
            props: {
              placement: 'bottom',
              width: '100%'
            }
          },
          [
            h(
              'g-button',
              {
                class: 'ma-0',
                props: {
                  block: true,
                  large: this.scrollTop === 0,
                  color: 'white'
                },
                slot: 'activator'
              },
              [
                h(
                  'div',
                  {
                    class: 'text--black'
                  },
                  this.getTranslate(`${name}.buttons.toc`)
                )
              ]
            ),

            h(
              'g-card',
              {
                class: 'pa-3',
                props: {
                  minHeight: 300,
                  minWidth: 300
                }
              },
              [
                h(
                  toc,
                  {
                    props: {
                      headings: this.headings,
                      scrollable: false
                    }
                  }
                )
              ]
            )
          ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'w--100': true,
        faifs: this.viewport.breakpoint.mdUp,
        fc: this.viewport.breakpoint.smDown
      }
    },
    [
      renderToc.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
