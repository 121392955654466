const today = new Date()
today.setHours(0, 0, 0, 0)

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'g-date-picker__title'
    },
    this.computedDates.map((date, index) => {
      return h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            label: date,
            flat: true,
            small: true,
            rounded: true,
            disabled: date === '---'
          },
          on: {
            click: () => {
              this.state = 'days'
              const dateFromDate = new Date(this.proxy[index])
              this.date.year = dateFromDate.getFullYear()
              this.date.month = dateFromDate.getMonth()
              this.date.day = dateFromDate.getDate()
            }
          }
        },
        [ date ]
      )
    })
  )
}

function renderArrow(h, direction = -1) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: direction < 0 ? 'keyboard_arrow_left' : 'keyboard_arrow_right',
        flat: true
      },
      on: {
        click: () => {
          this.arrowHandler(direction)
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'g-date-picker__header'
    },
    [ renderArrow.call(this, h), renderTitle.call(this, h), renderArrow.call(this, h, 1) ]
  )
}

function renderInfo(h) {
  const month = new Date(this.date.year, this.date.month).toLocaleString(this.locale, { month: 'long' })

  return h(
    'div',
    {
      class: 'g-date-picker__info'
    },
    [ 'months', 'years' ].map(state => {
      return h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            label: state === 'months' ? month : this.date.year,
            flat: true,
            small: true,
            rounded: true
          },
          on: {
            click: () => {
              this.state = state
            }
          }
        }
      )
    })
  )
}

function renderDay(h, day) {
  const currentDay = today.getTime()
  const uTime = this.getUnixTimeByDay(day)

  const { isActiveDate, isLeftActiveEdge, isRightActiveEdge } = this.isActiveDay(uTime)

  const isActive = isActiveDate || isLeftActiveEdge || isRightActiveEdge || uTime === currentDay || false

  if (day) {
    return h(
      'g-button',
      {
        class: 'g-date-picker__days-day',
        props: {
          label: day,
          flat: !isActive,
          round: true,
          depressed: isActive,
          outline: uTime === currentDay && !isActiveDate,
          color: isActive ? 'primary' : undefined,
          disabled: this.isDisabledDay(uTime)
        },
        on: {
          click: event => {
            event.preventDefault()
            event.stopPropagation()

            this.pickDateHandler(day)
          },
          mouseover: () => {
            this.hoveringDate = this.getUnixTimeByDay(day)
          },
          mouseout: () => {
            this.hoveringDate = undefined
          }
        },
        key: `g-date-picker-day-${day}`
      }
    )
  }
}

function renderDays(h) {
  return h(
    'div',
    {
      class: 'g-date-picker__days'
    },
    [
      h(
        'div',
        {
          class: 'g-date-picker__days-row'
        },
        this.daysOfWeek.map(day => {
          return h(
            'div',
            {
              class: 'g-date-picker__days-day-of-week'
            },
            day
          )
        })
      ),

      this.computedDate.daysMatrix.map(week => {
        return h(
          'div',
          {
            class: 'g-date-picker__days-row'
          },
          week.map(day => {
            const uTime = this.getUnixTimeByDay(day)

            const { isInRange, isLeftActiveEdge, isRightActiveEdge } = this.isActiveDay(uTime)

            let isLeftActiveHoverDate = false
            let isRightActiveHoverDate = false

            const arrTimeForHover = []

            if (this.hoveringDate) {
              arrTimeForHover.push(this.convertDate(this.proxy[0]))
              arrTimeForHover.push(this.hoveringDate)
            }

            arrTimeForHover.sort()

            if (arrTimeForHover.length === 2 && this.proxy.length === 1) {
              if (uTime === arrTimeForHover[0]) {
                isLeftActiveHoverDate = true
              }
              if (uTime === arrTimeForHover[1]) {
                isRightActiveHoverDate = true
              }
            }

            return h(
              'div',
              {
                class: {
                  'g-date-picker__days-day-cell': true,
                  'g-date-picker__days-day-cell--active': day && isInRange,
                  'g-date-picker__days-day-cell--active-left': day && isLeftActiveEdge,
                  'g-date-picker__days-day-cell--active-right': day && isRightActiveEdge,
                  'g-date-picker__days-day-cell--active-hover': day && this.isActiveHoverDay(uTime) && this.range,
                  'g-date-picker__days-day-cell--active-hover-left': day && isLeftActiveHoverDate && !isLeftActiveEdge && this.range,
                  'g-date-picker__days-day-cell--active-hover-right': day && isRightActiveHoverDate && !isRightActiveEdge && this.range
                }
              },
              [ renderDay.call(this, h, day) ]
            )
          })
        )
      })
    ]
  )
}

function renderMonths(h) {
  return h(
    'div',
    {
      class: 'g-date-picker__months'
    },
    this.computedDate.monthsMatrix.map(quarter => {
      return h(
        'div',
        {
          class: 'g-date-picker__months-row'
        },
        quarter.map(month => {
          const outline = today.getFullYear() === this.date.year && today.getMonth() === month.number && !this.isActiveMonth(this.date.year, month.number)
          const color = this.isActiveMonth(this.date.year, month.number) || today.getFullYear() === this.date.year && today.getMonth() === month.number ? 'primary' : undefined

          return h(
            'g-button',
            {
              class: 'g-date-picker__months-month',
              props: {
                label: month.short,
                flat: !this.isActiveMonth(this.date.year, month.number),
                rounded: true,
                block: true,
                depressed: true,
                color,
                outline
              },
              on: {
                click: event => {
                  event.preventDefault()
                  event.stopPropagation()

                  this.date.month = month.number
                  this.state = 'days'
                }
              },
              key: `g-date-picker-month-${month.number}`
            }
          )
        })
      )
    })
  )
}

function renderYears(h) {
  return h(
    'div',
    {
      class: 'g-date-picker__years',
      ref: 'years'
    },
    this.computedDate.yearsMatrix.map(quarter => {
      return h(
        'div',
        {
          class: 'g-date-picker__years-row'
        },
        quarter.map(year => {
          const current = today.getFullYear() === year

          return h(
            'g-button',
            {
              class: {
                'g-date-picker__years-year': true,
                'g-date-picker__years-year--active': this.isActiveYear(year) || current
              },
              props: {
                label: year,
                flat: !this.isActiveYear(year),
                rounded: true,
                depressed: this.isActiveYear(year),
                color: this.isActiveYear(year) || current ? 'primary' : undefined,
                outline: current && !this.isActiveYear(year)
              },
              on: {
                click: event => {
                  event.preventDefault()
                  event.stopPropagation()

                  this.date.year = year
                  this.state = 'months'
                }
              },
              key: `g-date-picker-year-${year}`
            }
          )
        })
      )
    })
  )
}

function renderContent(h) {
  switch (this.state) {
    case 'months': return renderMonths.call(this, h)
    case 'years': return renderYears.call(this, h)
    case 'days':
    default: return renderDays.call(this, h)
  }
}

function renderHolder(h) {
  return h(
    'div',
    {
      class: 'g-date-picker__holder'
    },
    [ renderContent.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'g-date-picker': true,
        'g-date-picker--range': this.range,
        'g-date-picker--outline': this.outline
      },
      ref: 'date-picker'
    },
    [
      renderHeader.call(this, h),
      renderInfo.call(this, h),
      renderHolder.call(this, h)
    ]
  )
}
