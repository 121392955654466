import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, isEqual } from 'lodash'

import render from './render'

export default function(options) {
  const tag = options?.tag
  let type = 'array'
  switch (tag) {
    case 'type':
    case 'moderation': {
      type = 'string'
      break
    }
  }

  return {
    name: 'Dialog',

    mixins: [ proxy({ type }) ],

    props: {
      tag: String,
      tagEdit: Boolean,

      tagDisabled: {
        type: Array,
        default: () => []
      },

      show: Boolean,

      readonly: Boolean
    },

    watch: {
      show() {
        this.watchValueHandler()
      }
    },

    methods: {
      watchProxyHandler() {
        return
      },

      watchValueHandler() {
        const value = cloneDeep(this._inputFilter(this.value))
        if (!isEqual(this.proxy, value)) {
          this.proxy = value
        }
      }
    },

    render
  }
}
