import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { inputModes, moderationColors, permissionPresets, services, sizes } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import SendingTypes from '@/components/misc/SendingTypes'
import ModerationStatusBarWithIcon from '@/components/misc/ModerationStatusBarWithIcon'
import tag from '@/components/tag'

export default function(h) {
  let cols = 2
  if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
    cols = 3
  }

  return h(
    'div',
    {
      class: `${services.sendernames}-filter`,
      style: { 'grid-template-columns': this.viewport.breakpoint.mdUp ? `repeat(${cols}, minmax(170px, 200px))` : '1fr' }
    },
    [
      h(
        SendingTypes,
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${services.sendernames}.labels.type`),
            permission: `advanced.${services.sendernames}.get`
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      ),
      h(
        'g-select', {
          props: {
            value: this.proxy.moderation,
            label: this.getTranslate(`${services.sendernames}.labels.moderation`),
            items: MODERATION_VALUES.map(moderation => {
              return {
                title: this.getTranslate(`misc.moderation.${moderation}`),
                value: moderation
              }
            }),
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          scopedSlots: {
            selection: ({ item }) => {
              return h(
                tag,
                {
                  props: {
                    label: this.getTranslate(`misc.moderation.${item.value}`),
                    color: moderationColors[item.value],
                    size: sizes.small,
                    as: 'select'
                  }
                }
              )
            },
            item: ({ item }) => {
              return h(
                ModerationStatusBarWithIcon,
                {
                  props: {
                    type: item.value,
                    title: item.title
                  }
                }
              )
            }
          },
          on: {
            input: event => {
              this.proxy.moderation = event
            }
          }
        }
      ),

      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: inputModes.outline,
            dense: true
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      )
    ]
  )
}
