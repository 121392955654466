const fileNames = require('../fileNames')

const _ = { defaultsDeep: require('lodash/defaultsDeep') }

module.exports = fileNames.reduce((result, name) => {
  const currentFile = require(`./${name}`).default
  const commonsFile = require('./commons').default
  result[name] = _.defaultsDeep(currentFile, commonsFile)

  return result
}, {})
