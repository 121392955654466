import { PAYMENTS_LOG_DIRECTION } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, icons, states } from '@/utils'

import sourceBox from '@/components/source/box'
import sticker from '@/components/misc/sticker'
import date from '@/components/serviceTemplate/item/date'

const name = 'paymentLogs'

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-3'
    },
    [ h('g-progress', { props: { indeterminate: true } }) ]
  )
}

function renderList(h) {
  if (this.restData[name].find.state === states.ready) {
    if (this.restData[name].find.pagination.total > 0) {
      return this.restData[name].find.data.map(item => {
        return renderItem.call(this, h, item)
      })
    } else {
      return h('g-empty')
    }
  } else if (this.restData[name].find.state === states.loading) {
    return renderPreloader.call(this, h)
  }
}

function renderItemId(h, id) {
  if (this.viewport.size.width < 425) {
    return
  }

  if (id) {
    return h(
      sticker,
      {
        props: {
          value: { entityId: id },
          label: this.getTranslate(`${name}.labels.id`),
          pathToTitle: 'entityId',
          icon: icons.numbers,
          color: colors.grey
        }
      }
    )
  }
}

function renderItem(h, item) {
  return h(
    'div',
    {
      class: `${name}__panel service-template-panel`,
      on: {
        click: () => {
          this.request = item.request
          this.response = item.response
          this.showSourceDialog = true
        }
      }
    },
    [
      h(
        'div',
        {
          class: `${name}__panel-info`
        },
        [
          h(
            sticker,
            {
              props: {
                value: this.getTranslate(`${name}.directions.${item.direction}`),
                label: this.getTranslate(`${name}.labels.type`),
                icon: item.direction === PAYMENTS_LOG_DIRECTION.incoming ? 'arrow_drop_down' : 'arrow_drop_up',
                iconTooltip: this.getTranslate(`${name}.directions.${item.direction}`),
                color: item.direction === PAYMENTS_LOG_DIRECTION.incoming ? 'success' : 'primary',
                borderStyle: 'dashed'
              }
            }
          ),

          renderItemId.call(this, h, item.id)
        ]
      ),

      h(date, { props: { value: item } })
    ]
  )
}

function renderSourceDialog(h) {
  const tabs = [ 'request', 'response' ]

  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showSourceDialog,
        maxWidth: 1000
      },
      on: {
        input: event => {
          if (event === false) {
            this.currentTab = undefined
            this.request = undefined
            this.response = undefined
          }
          this.showSourceDialog = event
        }
      }
    },
    [
      h(
        'g-tabs-header',
        {
          props: {
            value: this.currentTab,
            tabs: tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${name}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              this.currentTab = event
            }
          },
          slot: 'header'
        }
      ),

      h(
        'g-tabs',
        {
          props: { value: this.currentTab }
        },
        tabs.map(key => {
          return h(
            sourceBox,
            {
              props: { value: this[key] },
              key
            }
          )
        })
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showSourceDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  if (this.checkPermissions('advanced.paymentLogs.get')) {
    return h(
      'div',
      {
        class: `${name} fc ff`
      },
      [
        renderSourceDialog.call(this, h),
        renderList.call(this, h)
      ]
    )
  }
}
