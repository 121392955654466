import { services } from '@/utils'

import date from '@/components/serviceTemplate/item/date'
import owner from '@/components/serviceTemplate/item/owner'
import token from '@/components/services/tokens/item/token'
import description from '@/components/services/tokens/item/description'

function renderToken(h) {
  return h(
    token,
    {
      props: {
        value: this.value,
        sensitivity: this.sensitivity
      }
    }
  )
}

function renderOwner(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    owner,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderDescription(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'div',
      {
        class: `${services.tokens}-panel__description`
      },
      [
        h(
          description,
          {
            props: {
              value: this.value
            }
          }
        )
      ]
    )
  }
}

function renderDetails(h) {
  return h(
    'div',
    {
      class: `${services.tokens}-panel__details`
    },
    [
      renderToken.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.smDown) {
    return
  }

  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${services.tokens}-panel`]: true,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderDetails.call(this, h),
      renderDescription.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
