import { sizes } from '@/utils'
import { typeColors, typeIcons } from '@/components/services/tasks/utils'

import tag from '@/components/tag'
import squircle from '@/components/icon/squircle'

export default function(h) {
  if (this.value.type) {
    if (this.viewport.size.width > 720) {
      return h(
        tag,
        {
          props: {
            label: this.getTranslate(`tasks.types.${this.value.type}`),
            color: typeColors[this.value.type],
            size: sizes.huge,
            as: 'button'
          }
        }
      )
    } else {
      return h(
        squircle,
        {
          props: {
            icon: typeIcons[this.value.type],
            color: typeColors[this.value.type],
            size: sizes.medium,
            tooltip: this.getTranslate(`tasks.types.${this.value.type}`)
          }
        }
      )
    }
  }
}
