import { permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'Panel',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = [ '38px', '150px' ]

      if (this.viewport.breakpoint.smUp) {
        result.push('120px')

        if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
          result.push('150px')
        }
      }

      if (this.viewport.breakpoint.mdUp) {
        result.push('1fr')
      }

      return result.join(' ')
    }
  },

  render
}
