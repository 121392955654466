import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, merge } from 'lodash'

import { CONTACTS_FILTER } from '@/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ proxy({ type: 'object' }) ],

  methods: {
    getDefaultFilter() {
      return merge(cloneDeep(CONTACTS_FILTER), { ListId: this.$route.params.id })
    },

    clearFilter() {
      this.proxy = cloneDeep(this._inputFilter(this.getDefaultFilter()))
    }
  },

  render
}
