import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'RoutesFormTitle',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    value: {
      type: Object,
      required: true
    },
    service: {
      type: String,
      required: true
    }
  },

  computed: {
    $component() {
      return this.value.id ? 'router-link' : 'div'
    },

    $to() {
      const result = {}
      if (this.value.id) {
        result.name = [ this.service, 'single' ].join('.')
        result.params = { id: this.value.id }
      }

      return result
    },

    $class() {
      return this.value.id ? 'link link--passive' : undefined
    },

    $icon() {
      return this.value.id ? 'link' : 'link_off'
    }
  },

  render
}
