import { cloneDeep, defaultsDeep } from 'lodash'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { PAYMENTS_FILTER, fillDependence, generateServices, permissionPresets, services } from '@/utils'

import render from './render'

export default function(options = {}) {
  const defaultFilter = cloneDeep(PAYMENTS_FILTER)
  if (options.isPage) {
    defaultsDeep(defaultFilter, { createdAtPreset: 'today' })
  }

  return {
    name: 'List',

    mixins: [
      generateServices({
        name: services.payments,

        async inputFilter(result) {
          if (!result.Owner) {
            await fillDependence.call(this, result, {
              service: services.users,
              permissions: permissionPresets.resellerUp,
              pathToId: 'OwnerId',
              pathToObject: 'Owner',
              outputPath: 'Owner',
              picks: PUBLIC_FIELDS_USER
            })
          }

          result.OwnerId = result.OwnerId || result.Owner && result.Owner.id

          return result
        },

        find: {
          defaultFilter,
          alwaysCreateFromWebSocket: true,
          alwaysUpdateFromWebSocket: true,
          alwaysRemoveFromWebSocket: true,

          defaultPagination: {
            limit: 10
          }
        },

        get: false,
        create: false,
        update: false,
        remove: false
      })
    ],

    data() {
      return {
        showPaymentLogsDialog: false,
        showBillingsDialog: false,

        paymentsBillings: [],

        paymentId: undefined
      }
    },

    mounted() {
      if (options.isPage) {
        this.rest[services.payments].find()
      } else {
        this.restData[services.payments].find.filter.RecurrentPaymentId = options.RecurrentPaymentId
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
