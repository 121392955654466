import proxy from '@sigma-legacy-libs/g-proxy'

import { getEmptyFallback } from '@/utils'

import render from './render'

export default {
  name: 'TemplatesForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    fallbacks() {
      return this.proxy && this.proxy.fallbacks && this.proxy.fallbacks.length > 0
    }
  },

  methods: {
    setFallbacks() {
      this.proxy.fallbacks.splice(this.proxy.fallbacks.length, 0, getEmptyFallback())
    }
  },

  render
}
