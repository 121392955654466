import { buttonModes, colors, permissionPresets } from '@/utils'

import { serviceName } from '@/components/services/billings/utils'
import { renderReleaseBucketButton } from '@/components/misc'

import RefreshButton from '@/components/button/refresh'
import BillingsDetailsItem from '@/components/services/billings/details/item'
import pagination from '@/components/misc/pagination'
import detailsDialog from '@/components/services/billings/details/dialog'
import button from '@/components/button'

function renderCreateButton(h) {
  if (this.checkPermissions(`advanced.${serviceName}.generate`, permissionPresets.resellerUp)) {
    return h(
      button,
      {
        props: {
          label: this.viewport.breakpoint.xs ? undefined : this.getTranslate('misc.buttons.create'),
          icon: 'add',
          mode: buttonModes.flat,
          color: colors.secondary
        },
        on: {
          click: () => {
            this.$emit('create')
          }
        }
      }
    )
  }
}
function renderRefreshButton(h) {
  return h(
    RefreshButton,
    {
      props: {
        disabled: this.downloadable,
        label: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${serviceName}.buttons.refresh`),
        tooltip: this.getTranslate(`${serviceName}.tooltips.refresh`),
        state: this.restData[serviceName].find.state,
        shortClickMethod: () => this.rest[serviceName].find(),
        longClickMethod: () => this.rest[serviceName].find({}, { noCache: true })
      }
    }
  )
}
function renderTemplateActions(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [
      renderReleaseBucketButton.call(this, h, { name: serviceName }),
      renderCreateButton.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}
function renderTitle(h) {
  return h(
    'div',
    {
      class: {
        faic: true,
        fw: this.viewport.breakpoint.smDown
      }
    },
    [
      this.$slots.title,

      h('div', { class: 'ff' }),

      renderTemplateActions.call(this, h)
    ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderTitle.call(this, h),
      this.$slots.filter
    ]
  )
}

function renderSourceButton(h, item) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        icon: 'code',
        color: 'info'
      },
      on: {
        click: () => {
          this.source = item
          this.showDialog = true
        }
      }
    }
  )
}

function renderContentByBreakpoint(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'g-table',
      {
        props: {
          value: this.restData[serviceName].find.order,
          items: this.restData[serviceName].find.data,
          headers: this.headers,
          hideHead: this.restData[serviceName].find.pagination.total < 1,
          noDataText: this.getTranslate('misc.no.data.text')
        },
        on: {
          input: event => {
            this.restData[serviceName].find.order = event
          }
        },
        scopedSlots: {
          items: ({ item }) => {
            return h(
              BillingsDetailsItem,
              {
                props: {
                  value: item,
                  mode: 'table'
                },
                scopedSlots: {
                  sourceButton: () => renderSourceButton.call(this, h, item)
                }
              }
            )
          }
        }
      }
    )
  } else {
    return h(
      'g-expansion-panels',
      {
        props: { flat: true }
      },
      this.restData[serviceName].find.data.map(item => {
        return h(
          BillingsDetailsItem,
          {
            props: {
              value: item,
              mode: 'panels'
            },
            scopedSlots: {
              sourceButton: () => renderSourceButton.call(this, h, item)
            }
          }
        )
      })
    )
  }
}

function renderDivider(h) {
  if (Array.isArray(this.restData[serviceName].find.data) && this.restData[serviceName].find.data.length) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[serviceName].find.pagination,
            service: serviceName
          },
          on: {
            input: event => {
              this.restData[serviceName].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

function renderDetailsDialog(h) {
  return h(
    detailsDialog,
    {
      props: {
        value: this.source,
        show: this.showDialog
      },
      on: {
        close: () => {
          this.showDialog = false
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderHeader.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderContentByBreakpoint.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDetailsDialog.call(this, h)
    ]
  )
}
