export const generateKey = value => {
  if (value) {
    switch (typeof value) {
      case 'object':
        return [ value.Owner && value.Owner.id || value.OwnerId, value.id ].filter(item => !!item).join('/')
      case 'string':
      default:
        return value
    }
  }
}
