import { get } from 'lodash'

import messages from '@/messages'

import { isString, isStringNotEmpty } from '../validators'

export const getTranslate = (path, options = {}) => {
  if (isStringNotEmpty(path)) {
    let result = get(messages, [ options.locale || 'en', path ].join('.'))

    if (!isString(result)) {
      console.warn(`[getTranslate] Translation must be a string - ${path}`)

      return path
    }

    if (result === '') {
      return result
    }

    if (isStringNotEmpty(result)) {
      if (options) {
        Object.keys(options).forEach(key => {
          result = result.replace(`\{${key}\}`, options[key])
        })
      }

      return result
    } else {
      console.warn(`[getTranslate] No translation found for this path - ${path}`)

      return path
    }
  }
}
