import Vue from 'vue'

import { get } from 'lodash'

import { services } from '@/utils'

export default {
  namespaced: true,

  state: {
    methods: []
  },

  mutations: {
    setPaymentsMethods(state, methods) {
      state.methods = methods
    }
  },

  actions: {
    async getPaymentsMethods({ commit, rootState }) {
      const UserId = get(rootState.account, 'account.id')
      if (UserId) {
        const { data } = await Vue.$GRequest.get(services.payments, 'methods', { query: { UserId } })
        if (data) {
          commit('setPaymentsMethods', data)

          return data
        }
      }
    },

    setPaymentsMethods({ commit }, methods) {
      commit('setPaymentsMethods', methods)
    }
  },

  getters: {
    methods: state => state.methods
  }
}
