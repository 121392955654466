import { get } from 'lodash'

import { serviceName, stateColors, stateIcons } from '@/components/services/apisender/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  const idInstance = get(this.value, 'instance.idInstance')

  return h(
    sticker,
    {
      props: {
        value: idInstance || this.getTranslate(`${serviceName}.hints.badInstance`),
        label: this.getTranslate(`${serviceName}.labels.instance.idInstance`),
        icon: this.value.$state ? stateIcons[this.value.$state] : 'help_outline',
        color: this.value.$state ? stateColors[this.value.$state] : 'grey',
        iconTooltip: this.getTranslate(`${serviceName}.states.instance.${this.value.$state}`),
        disabled: !idInstance,
        to: {
          name: `${serviceName}.single`,
          params: { id: this.value.id }
        }
      }
    }
  )
}
