import { getIconByType } from '@/utils'

import render from './render'

export default {
  name: 'Type',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    $icon() {
      return getIconByType(this.value.type)
    }
  },

  render
}
