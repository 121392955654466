import { serviceName } from '@/components/services/routingGroups/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.value.description) {
    return h(
      sticker,
      {
        props: {
          value: this.value.description,
          label: this.getTranslate(`${serviceName}.labels.description`),
          borderStyle: 'dashed',
          maxWidth: 400
        }
      }
    )
  }

  return h('div')
}
