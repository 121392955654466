import { components } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      class: {
        [components['card-actions']]: true,
        [`${components['card-actions']}--dense`]: this.dense
      }
    },
    [ this.$slots.default ]
  )
}
