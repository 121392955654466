import { serviceName } from '@/components/services/apisender/utils'

import title from '@/components/services/apisender/item/title'
import isPaid from '@/components/services/apisender/item/isPaid'
import phone from '@/components/services/apisender/item/phone'
import owner from '@/components/services/apisender/item/owner'

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}__panel-info`
    },
    [ title, isPaid, phone, owner ].filter(Boolean).map(sticker => {
      return h(
        sticker,
        {
          props: {
            value: this.value
          }
        }
      )
    })
  )
}
