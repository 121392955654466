import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { getLocaleDateString, getLocaleTimeString, services } from '@/utils'

import render from './render'

const getCurrentSeconds = value => {
  const date = value ? new Date(value) : Date.now()

  return Math.floor(date / 1000)
}

export default {
  name: 'TasksState',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      now: getCurrentSeconds(),

      interval: undefined
    }
  },

  computed: {
    stateLabel() {
      if (this.value.state === TASK_STATES.scheduled) {
        const date = getCurrentSeconds(this.value.startsAt)
        if (date > this.now) {
          const diff = date - this.now
          const days = Math.floor(diff / 60 / 60 / 24)
          const hours = Math.floor(diff / 60 / 60) % 24
          const minutes = Math.floor(diff / 60) % 60
          const seconds = diff % 60

          if (days > 0) {
            return this.getTranslateTemplate('misc.measure.time.template.days', { count: days })
          } else if (days === 0 && hours > 0) {
            return this.getTranslateTemplate('misc.measure.time.template.hours', { count: hours })
          } else if (days === 0 && hours === 0 && minutes > 0) {
            return this.getTranslateTemplate('misc.measure.time.template.minutes', { count: minutes })
          } else if (days === 0 && hours === 0 && minutes === 0 && seconds > 0) {
            return this.getTranslateTemplate('misc.measure.time.template.seconds', { count: seconds })
          }
        }

        return this.getTranslateTemplate('misc.measure.time.template.seconds', { count: 0 })
      }

      return this.value.state
    },
    stateTooltip() {
      if (this.value.state === TASK_STATES.scheduled) {
        return [
          this.getTranslate('tasks.schedule.delayed'),
          getLocaleDateString(this.value.startsAt),
          this.getTranslate('misc.to').toLowerCase(),
          getLocaleTimeString(this.value.startsAt)
        ].join(' ')
      }
    },

    $value() {
      if (this.value.state === TASK_STATES.scheduled) {
        return this.stateLabel
      }

      return this.getTranslate(`${services.tasks}.states.${this.value.state}`)
    }
  },

  mounted() {
    this.clearInterval()
    this.setInterval()
  },

  beforeDestroy() {
    this.clearInterval()
  },

  methods: {
    setInterval() {
      if (this.value.state === TASK_STATES.scheduled) {
        this.interval = setInterval(() => {
          this.now = getCurrentSeconds()
        }, 1000)
      }
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  },

  render
}
