import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'

import render from './render'

export default {
  name: 'Row',

  props: {
    value: null,

    tag: String,

    readonly: Boolean
  },

  computed: {
    hasValue() {
      return Actions.validateValue(this.value, this.tag)
    }
  },

  render
}
