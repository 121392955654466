import Vue from 'vue'

import { inputModes, permissionPresets, positiveNumberTwoSymbolsAfterDotRegex, services, states } from '@/utils'

import info from '@/components/misc/info'
import OwnerSelect from '@/components/misc/OwnerSelect'
import readonly from '@/components/services/invoices/form/readonly'

function renderOwner(h) {
  if (!this.OwnerId) {
    if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
      return h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.customerId,
            details: false,
            mode: inputModes['line-label'],
            disabled: this.disabled,
            combobox: true,
            clearable: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy.customerId = event
            }
          }
        }
      )
    }
  }
}

function renderRequisite(h) {
  if (this.restData.requisites.find.state === states.ready && this.restData.requisites.find.pagination.total === 0) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        h(
          info,
          {
            props: {
              value: this.proxy.customerId === this.account.id
                ? this.getTranslate(`${services.invoices}.warnings.notFound.requisites`)
                : this.getTranslate(`${services.invoices}.warnings.notFound.requisitesForAdmin`),
              color: 'error'
            }
          }
        ),
        h(
          'g-button',
          {
            class: 'ma-0',
            props: {
              label: this.getTranslate(`${services.invoices}.labels.requisites`),
              flat: true,
              rounded: true,
              color: 'primary'
            },
            on: {
              click: () => {
                Vue.router.push({
                  name: this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.resellerUp) ? services.requisites : 'account',
                  query: { currentTab: services.requisites }
                })
                this.$emit('show', false)
              }
            }
          }
        )
      ]
    )
  }

  return h(
    'g-select',
    {
      props: {
        value: this.proxy.requisiteId,
        label: this.getTranslate(`${services.invoices}.labels.requisite`),
        items: this.restData.requisites.find.data,
        itemValue: 'id',
        mode: inputModes['line-label'],
        loading: this.restData.requisites.find.state === states.loading,
        disabled: this.restData.requisites.find.state === states.loading || this.disabled,
        details: false,
        rounded: true,
        required: true
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: 'repeat(2, auto)' }
            },
            [
              h(
                'div',
                {
                  class: 'g-select__chip'
                },
                [
                  h(
                    'div',
                    {
                      class: 'g-select__chip-title'
                    },
                    [ item.title ]
                  )
                ]
              ),
              h(
                'g-chip',
                {
                  props: {
                    label: this.getTranslate(`${services.requisites}.vat.${this.vat}`),
                    tiny: true,
                    color: 'primary'
                  }
                }
              )
            ]
          )
        },
        item: ({ item }) => {
          return h(
            'div',
            {
              class: 'w--100 grid grid-gap--8 faic fjcsb',
              style: { gridTemplateColumns: '1fr auto' }
            },
            [
              h('div', { class: 'text-overflow ' }, item.title),
              h(
                'g-chip',
                {
                  props: {
                    label: this.getTranslate(`${services.requisites}.vat.${item._item.data.tax.vat}`),
                    tiny: true,
                    disabled: this.proxy.requisiteId !== item.id,
                    color: this.proxy.requisiteId === item.id ? 'primary' : undefined
                  }
                }
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          this.proxy.requisiteId = event
        }
      }
    }
  )
}

function renderCompany(h) {
  if (this.proxy.requisiteId) {
    if (this.restData.companies.find.state === states.ready && this.restData.companies.find.pagination.total === 0) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8'
        },
        [
          h(
            info,
            {
              props: {
                value: this.getTranslate(`${services.invoices}.warnings.notFound.companies`),
                color: 'error'
              }
            }
          ),
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                label: this.getTranslate('misc.buttons.support'),
                flat: true,
                rounded: true,
                color: 'primary'
              },
              on: {
                click: () => {
                  Vue.router.push({ name: 'support' })
                  this.$emit('show', false)
                }
              }
            }
          )
        ]
      )
    }

    return h(
      'g-select',
      {
        props: {
          value: this.proxy.companyId,
          label: this.getTranslate(`${services.invoices}.labels.company`),
          items: this.restData.companies.find.data.map(item => {
            return {
              title: item.title,
              value: item.id
            }
          }),
          mode: inputModes['line-label'],
          loading: this.restData.companies.find.state === states.loading,
          disabled: this.restData.companies.find.state === states.loading || this.disabled,
          details: false,
          rounded: true,
          required: true
        },
        on: {
          input: event => {
            this.proxy.companyId = event
          }
        }
      }
    )
  }
}

function renderAmount(h) {
  if (this.proxy.customerId && this.proxy.requisiteId && this.proxy.companyId) {
    if (this.hasInvoiceTemplate) {
      return h(
        'g-text-field',
        {
          props: {
            value: this.proxy.amount,
            label: this.getTranslate(`${services.invoices}.labels.amount`),
            suffix: this.getTranslate(`currency.${this.globalSettings.billings.currency}.symbol.unicode`),
            mode: inputModes['line-label'],
            disabled: this.disabled,
            autofocus: true,
            rounded: true,
            clearable: true,
            required: true,
            details: false,
            mask: positiveNumberTwoSymbolsAfterDotRegex,
            processValue: value => value?.toString().replace(',', '.')
          },
          on: {
            input: event => {
              this.proxy.amount = event
            }
          }
        }
      )
    } else {
      return h(
        info,
        {
          props: {
            value: this.getTranslate(`${services.invoices}.warnings.notFound.template`),
            color: 'error'
          }
        }
      )
    }
  }
}

export default function(h) {
  if (this.isNew) {
    return h(
      'g-form',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': '100%' }
      },
      [
        h(
          'div',
          {
            class: 'grid grid-gap--16 pa-2'
          },
          [
            renderOwner.call(this, h),
            renderRequisite.call(this, h),
            renderCompany.call(this, h),
            renderAmount.call(this, h)
          ]
        )
      ]
    )
  }

  return h(
    readonly,
    {
      props: {
        value: this.proxy
      }
    }
  )
}
