import { buttonModes, colors, icons } from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'

function renderSlotHeader(h) {
  if (this.$slots.title) {
    return this.$slots.title
  } else {
    return h(
      HeadTitle,
      {
        props: { value: this.title || this.getTranslate('commons.titles.confirm.remove') },
        slot: 'header'
      }
    )
  }
}

function renderSlotContent(h) {
  if (this.$slots.content) {
    return this.$slots.content
  } else {
    return h(
      'div',
      {
        class: 'pa-3 subheading'
      },
      [ this.content || this.getTranslate('commons.contents.confirm.remove') ]
    )
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        maxWidth: this.maxWidth
      },
      on: {
        input: event => {
          this.show = event
        }
      }
    },
    [
      renderSlotHeader.call(this, h),
      renderSlotContent.call(this, h),

      h(
        'div',
        {
          class: 'fjcfe facfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.show = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                mode: buttonModes.flat,
                loading: this.loading,
                disabled: this.disabled,
                color: colors.error
              },
              on: {
                click: async () => {
                  await this.callback()
                  this.show = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        label: this.buttonText,
        loading: this.loading,
        disabled: this.disabled,
        icon: icons.delete,
        color: colors.error
      },
      on: {
        click: event => {
          if (this.stopPropagation) {
            event.stopPropagation()
          }
          this.show = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
