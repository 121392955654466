import proxy from '@sigma-legacy-libs/g-proxy'

import { EVENTSHISTORY_ASSOCIATED_SERVICES } from '@sigma-legacy-libs/essentials/lib/constants'

import { camelCaseToKebabCase } from '@/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    services() {
      const result = []
      for (const item of EVENTSHISTORY_ASSOCIATED_SERVICES) {
        switch (item) {
          case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
            result.push({
              title: 'contacts',
              value: 'contactListsContacts'
            })
            break
          }
          case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
            const [ service ] = camelCaseToKebabCase(item).split('-')

            result.push({
              title: service,
              value: item
            })
            break
          }

          default: {
            result.push({
              title: item,
              value: item
            })
          }
        }
      }

      return result
    }
  },

  render
}
