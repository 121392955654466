import { buttonModes } from '@/utils'

import button from '@/components/button'
import date from '@/components/serviceTemplate/item/date'
import title from '@/components/services/requisites/item/title'
import inn from '@/components/services/requisites/item/inn'
import vat from '@/components/services/requisites/item/vat'

import { serviceName } from '@/components/services/requisites/utils'

function renderTitle(h) {
  return h(
    title,
    {
      props: {
        value: this.value
      },
      on: {
        showDialog: event => this.$emit('showDialog', event)
      }
    }
  )
}
function renderInn(h) {
  return h(
    inn,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderVAT(h) {
  if (!this.viewport.breakpoint.xs) {
    return h(
      vat,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderRemoveButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: 'delete',
        color: 'error'
      },
      on: {
        click: () => {
          this.$emit('remove', this.value.id)
        }
      }
    }
  )
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: {
        gridTemplateColumns: this.gridTemplateColumns
      }
    },
    [
      renderTitle.call(this, h),
      renderInn.call(this, h),
      renderVAT.call(this, h)
    ]
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.xl) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${serviceName}-panel`]: true
      }
    },
    [
      renderInfo.call(this, h),
      renderRemoveButton.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
