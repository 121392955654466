import { PROTOCOL_TYPES, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

export const PROVIDERS_PROCESSORS = SENDING_TYPES.reduce(
  (result, type) => {
    if (!result[type]) {
      result[type] = {}
    }

    PROTOCOL_TYPES.forEach(protocol => {
      if (!Array.isArray(result[type][protocol])) {
        result[type][protocol] = []
      }
    })

    return result
  },
  {
    [SENDING_TYPES.sms]: {
      smpp: [ 'smpp-default' ],
      http: [
        'http-sms-mtt-v1',
        'http-sms-mtt-v2',
        'http-sms-karen-v1',
        'http-sms-shkolainfo',
        'http-sms-mediarik-v1',
        'http-flashcall-miatel-v1'
      ]
    },
    [SENDING_TYPES.viber]: {
      smpp: [],
      http: [
        'http-viber-zagruzka',
        'http-viber-onviber',
        'http-viber-smsc-v1',
        'http-viber-mediarik-v1',
        'http-viber-smartdelivery-v1'
      ]
    },
    [SENDING_TYPES.vk]: {
      smpp: [],
      http: [ 'http-vk-mailru-v1' ]
    },
    [SENDING_TYPES.whatsapp]: {
      smpp: [],
      http: [
        'http-whatsapp-chat2desk-v1',
        'http-whatsapp-server-v1',
        'http-whatsapp-onviber',
        'http-whatsapp-telerobot-v1',
        'http-whatsapp-tyntec-v1'
      ],
      'websocket-serve': [ 'websocket-serve-whatsapp-desktop-v1' ]
    },
    [SENDING_TYPES.voice]: {
      http: [
        'http-voice-awsast-v1',
        'http-voice-telerobot-v1'
      ]
    },
    [SENDING_TYPES.whatsappchat]: { http: [ 'http-chat-chatpult-v1' ] },
    [SENDING_TYPES.viberchat]: { http: [ 'http-chat-chatpult-v1' ] },
    [SENDING_TYPES.vkchat]: { http: [ 'http-chat-chatpult-v1' ] },
    [SENDING_TYPES.telegramchat]: { http: [ 'http-chat-chatpult-v1' ] },
    [SENDING_TYPES.facebookchat]: { http: [ 'http-chat-chatpult-v1' ] },
    [SENDING_TYPES.flashcall]: { http: [ 'http-flashcall-miatel-v1', 'http-flashcall-newtel-v1', 'http-flashcall-unibell-v1' ] }
  }
)

export default { PROVIDERS_PROCESSORS }
