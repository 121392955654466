import Vue from 'vue'

import { buttonModes, colors, components, inputModes, permissionAccepts, permissionColors, permissionPresets, services, sizes } from '@/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import button from '@/components/button'
import preloader from '@/components/misc/preloader'
import tag from '@/components/tag'

function renderPreset(h, preset) {
  return h(
    button,
    {
      props: {
        label: preset,
        mode: buttonModes.flat,
        color: permissionColors[preset],
        loading: this.loading.preset[preset],
        disabled: Object.keys(this.loading.preset) > 0,
        tooltip: this.getTranslate(`${services.permissions}.tooltips.presets`)
      },
      on: {
        click: () => {
          this.setByPreset(preset)
        }
      }
    }
  )
}
function renderPresets(h) {
  if (process.env.NODE_ENV === 'development') {
    if (this.checkPermissions(`advanced.${services.users}.setPermissions`)) {
      return h(
        'div',
        {
          class: 'fjcfe grid-gap--8'
        },
        [ Object.values(permissionAccepts).map(preset => renderPreset.call(this, h, preset)) ]
      )
    }
  }
}
function renderSearch(h) {
  if (this.permissions) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 grid-cols--2'
      },
      [
        h(
          components['text-field'],
          {
            props: {
              value: this.permissionSearch,
              label: this.getTranslate('misc.search'),
              defaultValue: '',
              mode: inputModes.outline,
              dense: true,
              rounded: true,
              clearable: true,
              details: false
            },
            on: {
              input: event => {
                this.permissionSearch = event
              }
            }
          }
        ),

        renderPresets.call(this, h)
      ]
    )
  }
}

function renderTags(h, parent) {
  return h(
    'div',
    {
      class: 'fw faic grid-gap--4'
    },
    [
      parent.children.map(child => {
        return h(
          tag,
          {
            props: {
              label: this.getTranslate(`${services.permissions}.labels.${child.$name}`),
              size: sizes.tiny,
              color: permissionColors[this.permissions[child.$key]],
              tooltip: this.permissions[child.$key].toString()
            }
          }
        )
      })
    ]
  )
}

function renderPermissionName(h, child) {
  return h(
    'td',
    {
      class: 'text-nowrap py-1 px-2 body-1 w--100'
    },
    [ this.getTranslate(`${services.permissions}.labels.${child.$name}`) ]
  )
}
function renderPermissionAccepts(h, child) {
  return Object.values(permissionAccepts).map(accept => {
    const optionIndex = child.$options.findIndex(option => option.$value === accept)
    const option = child.$options[optionIndex] || {}
    const isActive = this.permissions[child.$key] === option.$value
    const disabled = accept !== option.$value

    return h(
      'td',
      {},
      [
        h(
          button,
          {
            props: {
              label: accept,
              size: sizes.tiny,
              mode: isActive ? buttonModes.filled : buttonModes.flat,
              color: isActive ? permissionColors[accept] : colors.grey,
              tooltip: this.getTranslate(`${services.permissions}.hints.${accept}`),
              loading: this.loading.update[`${child.$key}:${accept}`],
              disabled
            },
            on: {
              click: () => {
                if (this.permissions[child.$key] !== accept) {
                  Vue.set(this.loading.update, `${child.$key}:${accept}`, true)
                }
                this.updatePermission(child.$key, accept)
              }
            }
          }
        )
      ]
    )
  })
}
function renderPermission(h, child) {
  if (child.$multiple) {
    return child.$options.map(option => {
      return h(
        'g-checkbox',
        {
          style: { 'margin-left': '-14px' },
          props: {
            value: this.permissions[child.$key],
            label: option.$value,
            trueValue: option.$value,
            color: 'primary'
          },
          on: {
            input: event => {
              this.updatePermission(child.$key, event)
            }
          }
        }
      )
    })
  } else {
    return h(
      'tr',
      {},
      [
        renderPermissionName.call(this, h, child),
        renderPermissionAccepts.call(this, h, child)
      ]
    )
  }
}

function renderHeader(h, expanded, parent) {
  return h(
    'div',
    {
      class: 'default-expansion-panel-header pl-2 pr-0'
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--4 py-1 ff'
        },
        [
          h(
            tag,
            {
              props: {
                label: parent.title,
                size: sizes.small,
                as: 'select'
              }
            }
          ),
          renderTags.call(this, h, parent)
        ]
      ),

      h(ExpandArrow, { props: { expanded } })
    ]
  )
}
function renderBody(h, parent) {
  return h(
    'div',
    {
      style: { 'overflow-x': this.viewport.breakpoint.smDown ? 'scroll' : undefined }
    },
    [
      h(
        'table',
        {
          class: 'permissions-table'
        },
        parent.children.map(child => {
          return renderPermission.call(this, h, child)
        })
      )
    ]
  )
}
function renderPanel(h, parent) {
  return h(
    'g-expansion-panel',
    {
      scopedSlots: {
        header: ({ expanded }) => renderHeader.call(this, h, expanded, parent),
        default: () => renderBody.call(this, h, parent)
      }
    }
  )
}
function renderPanels(h) {
  if (this.permissions) {
    return h(
      'g-expansion-panels',
      {
        props: {
          outline: true,
          rounded: true
        }
      },
      [ this.$permissions.map(parent => renderPanel.call(this, h, parent)) ]
    )
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc faic ff pa-5'
    },
    [ h(preloader, { props: { value: true } }) ]
  )
}
function renderContent(h) {
  if (this.loading.find) {
    return renderPreloader.call(this, h)
  } else {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderSearch.call(this, h),
        renderPanels.call(this, h)
      ]
    )
  }
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.users}.setPermissions`, permissionPresets.resellerUp)) {
    return h(
      'div',
      {
        class: 'pa-2'
      },
      [ renderContent.call(this, h) ]
    )
  }
}
