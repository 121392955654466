import { isUUID, projectName } from '@/utils'

const prefixKey = service => `${projectName}:lastVisitedIds:${service}`
const limit = 5

export default {
  namespaced: true,

  state: {
    ids: []
  },

  mutations: {
    setIds(state, ids) {
      state.ids = ids
    }
  },

  actions: {
    setIds({ commit }, { service, ids }) {
      const result = JSON.parse(localStorage.getItem(prefixKey(service))) || []

      if (ids.length) {
        for (const id of ids) {
          if (isUUID(id) && !~result.indexOf(id)) {
            result.unshift(id)
          }
        }
      }

      const limitedResult = result.slice(0, limit)

      if (limitedResult.length <= limit) {
        commit('setIds', limitedResult)
        window.localStorage.setItem(prefixKey(service), JSON.stringify(limitedResult))
      }
    },

    setId({ dispatch }, { service, id }) {
      dispatch('setIds', {
        service,
        ids: [ id ]
      })
    },

    removeId({ commit }, { service, id }) {
      const result = JSON.parse(localStorage.getItem(prefixKey(service))) || []

      if (result.length) {
        const index = result.indexOf(id)
        if (~index) {
          result.splice(index, 1)
        }
      }

      const limitedResult = result.slice(0, limit)

      if (limitedResult.length <= limit) {
        commit('setIds', limitedResult)
        window.localStorage.setItem(prefixKey(service), JSON.stringify(limitedResult))
      }
    },

    getIds(_, service) {
      return JSON.parse(localStorage.getItem(prefixKey(service))) || []
    }
  },

  getters: {
    ids: state => state.ids
  }
}
