import render from './render'

export default {
  name: 'TreesListReadonly',

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      search: undefined
    }
  },

  computed: {
    values() {
      if (Array.isArray(this.value) && this.value.length) {
        return this.value.filter(value => {
          if (this.search) {
            const search = `${this.search}`.toLowerCase()
            const values = [ value ].map(item => `${item}`.toLowerCase())

            return !!~values.findIndex(element => element.includes(search))
          }

          return true
        })
      }

      return []
    }
  },

  render
}
