import { moderationColors, moderationIcons } from '@/utils'

export default function(h) {
  return h(
    'g-chip',
    {
      props: {
        [this.size]: true,
        rounded: this.rounded,
        color: moderationColors[this.value]
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': '14px 1fr' }
        },
        [
          h(
            'g-icon',
            {
              props: {
                size: 15,
                color: 'white',
                value: moderationIcons[this.value]
              }
            }
          ),

          this.getTranslate(`misc.moderation.${this.value}`)
        ]
      )
    ]
  )
}
