import { get } from 'lodash'

import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, permissionPresets, serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/contracts/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: permissionPresets.reseller,
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = get(result, 'Owner.id')
        result.CompanyId = get(result, 'Company.id')
        result.RequisiteId = get(result, 'Requisite.id')
        result.FileId = get(result, 'File.id')

        return result
      },

      async outputFilter(result) {
        if (!result.FileId) {
          if (result.File && result.File.id) {
            result.FileId = result.File.id
          }
        }

        return result
      },

      find: {
        defaultFilter: {
          $scope: [ 'Owner', 'Requisite', 'Company', 'File' ],
          $search: undefined,
          OwnerId: undefined,
          RequisiteId: undefined,
          CompanyId: undefined
        }
      },

      view: 'panels',
      views: [ 'panels' ],

      has: {
        clone: false,
        view: false
      },

      formPaddles: true,
      width: 420
    })
  ]
}
