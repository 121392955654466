import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

export const processors = {
  data() {
    return {
      processors: [],
      processorsLoading: false
    }
  },

  mounted() {
    this.getProcessors()
  },

  methods: {
    async getProcessors() {
      try {
        this.processorsLoading = true
        const { data } = await Vue.$GRequest.find('providers/processors')
        if (Array.isArray(data) && data.length) {
          this.processors = data
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.processorsLoading = false
      }
    }
  }
}

export const properties = (options = {}) => {
  return {
    data() {
      return {
        properties: [],
        propertiesLoading: false
      }
    },

    mounted() {
      this.getProperties()
    },

    methods: {
      async getProperties() {
        try {
          this.propertiesLoading = true
          const { data } = await Vue.$GRequest.find(`${options.serviceName}/properties`)
          if (Array.isArray(data) && data.length) {
            this.properties = data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.propertiesLoading = false
        }
      }
    }
  }
}

export default {
  processors,
  properties
}
