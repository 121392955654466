import { buttonModes, colors } from '@/utils'

import button from '@/components/button'
import draggable from '@/components/misc/draggable'

function renderDragHandle(h) {
  if (this.sortable) {
    return h(
      'g-icon',
      {
        class: 'drag_handle faic fjcc square--38',
        props: {
          value: 'drag_handle',
          color: colors.grey,
          size: 24
        }
      }
    )
  }
}

function renderRemoveButton(h, index) {
  return h(
    button,
    {
      props: {
        icon: 'remove',
        color: colors.error,
        mode: buttonModes.flat,
        disabled: this.disabled
      },
      on: {
        click: () => {
          this.removeField(index)
        }
      }
    }
  )
}

function renderRow(h, item, index) {
  const sizes = []
  if (this.sortable) {
    sizes.push('38px')
  }
  sizes.push('1fr', '38px')

  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': sizes.join(' ') }
    },
    [
      renderDragHandle.call(this, h),

      this.$scopedSlots.row ? this.$scopedSlots.row({
        item,
        index
      }) : this.$slots.row,

      renderRemoveButton.call(this, h, index)
    ]
  )
}

function renderFields(h) {
  const callback = (item, index) => renderRow.call(this, h, item, index)
  if (this.sortable) {
    return h(
      draggable,
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': '100%' },
        props: {
          value: this.proxy,
          disabled: this.disabled,
          handle: 'drag_handle'
        },
        on: {
          input: event => {
            this.proxy = event
          }
        },
        scopedSlots: { item: callback }
      }
    )
  } else {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': '100%' }
      },
      this.proxy.map(callback)
    )
  }
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe'
    },
    [
      h(
        button,
        {
          props: {
            icon: 'add',
            color: colors.primary,
            mode: buttonModes.flat,
            disabled: this.disabled || this.addDisabled
          },
          on: {
            click: () => {
              this.addField()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderFields.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
