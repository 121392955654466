import messageTypeIcon from '@/components/message/type/icon'

import { states } from '@/utils'

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic px-3 headline',
      slot: 'header'
    },
    [
      h(
        'div',
        {
          class: 'text--grey pr-2'
        },
        this.getTranslate('routes.titles.direct.title')
      ),

      h(
        'div',
        {
          class: 'faic fjcc square--48'
        },
        [
          h(
            messageTypeIcon,
            {
              props: {
                value: this.type
              }
            }
          )
        ]
      ),

      this.getTranslate(`sendings.types.${this.type}`)
    ]
  )
}

function renderProviderField(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.provider,
        label: this.getTranslate('routes.labels.direct.provider.choose'),
        service: 'providers',
        query: {
          type: this.type,
          isActive: true
        },
        mode: 'outline',
        rounded: true,
        details: false,
        autocomplete: true
      },
      on: {
        input: event => {
          this.provider = event
        }
      }
    }
  )
}

function renderItem(h, { title, id }) {
  return h(
    'div',
    {
      class: 'faic hover-background cursor-pointer lh-32 px-3',
      on: {
        click: () => {
          this.$emit('input', id)
          this.clear()
          this.$emit('showDialog', false)
        }
      }
    },
    [ title ]
  )
}

function renderItems(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--0'
    },
    [ this.restData.routingGroups.find.data.map(item => renderItem.call(this, h, item)) ]
  )
}

function renderWarning(h) {
  if (!this.responsibility) {
    return h(
      'div',
      {
        class: 'grid grid-gap--16'
      },
      [
        h(
          'div',
          {
            class: 'caption text--grey px-3',
            domProps: { innerHTML: this.getTranslate('routes.hints.direct.choose') }
          }
        ),

        renderItems.call(this, h)
      ]
    )
  }
}

function renderRoutingGroups(h) {
  if (this.restData.routingGroups.find.state === states.ready) {
    if (this.restData.routingGroups.find.pagination.total) {
      return h(
        'div',
        {
          class: 'grid grid-gap--16'
        },
        [
          h(
            'div',
            {
              class: 'px-3'
            },
            [
              h(
                'g-switch',
                {
                  props: {
                    value: this.responsibility,
                    label: this.getTranslate('routes.labels.direct.responsibility')
                  },
                  on: {
                    input: event => {
                      this.responsibility = event
                    }
                  }
                }
              )
            ]
          ),

          renderWarning.call(this, h)
        ]
      )
    }
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--16 pa-3'
    },
    [
      renderProviderField.call(this, h),
      renderRoutingGroups.call(this, h)
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcfe pa-2',
      style: { gridTemplateColumns: 'repeat(2, auto)' },
      slot: 'footer'
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            flat: true,
            rounded: true,
            disabled: this.loading,
            label: this.getTranslate('misc.buttons.cancel')
          },
          on: {
            click: () => {
              this.clear()
              this.$emit('showDialog', false)
            }
          }
        }
      ),

      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            rounded: true,
            depressed: true,
            loading: this.loading,
            disabled: this.disabled,
            color: 'primary',
            label: this.getTranslate('misc.buttons.create')
          },
          on: {
            click: () => {
              this.create()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.value,
        rounded: true,
        overflow: true,
        maxWidth: 500,
        minHeight: 300
      },
      on: {
        input: event => {
          this.$emit('showDialog', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
