import Vue from 'vue'

import formatTable from '@/components/format/table'
import detailsDialog from '@/components/services/sendings/outgoing/details/dialog'

import { SENDING_STATES, sendingTypeColors, states } from '@/utils'

import { serviceName } from '@/components/services/sendings/utils'

const _ = { get: require('lodash/get') }

function renderStatus(h, status) {
  if (status) {
    const status_color = _.get(SENDING_STATES, `${status}.color`, '')
    const status_name = _.get(SENDING_STATES, `${status}.name`, '')
    const status_icon = _.get(SENDING_STATES, `${status}.icon`, '')

    return h(
      'g-chip',
      {
        class: 'text-upper',
        props: {
          small: true,
          outline: true,
          icon: status_icon,
          color: status_color,
          label: this.getTranslate(`${serviceName}.statuses.${status_name}`)
        }
      }
    )
  }
}

function renderMessageButton(h, id) {
  if (id) {
    return h(
      'g-button',
      {
        class: 'my-0',
        props: {
          flat: true,
          small: true,
          rounded: true,
          label: this.getTranslate(`${serviceName}.labels.message`),
          color: 'primary'
        },
        on: {
          click: () => {
            this.showSendingDialog(id)
          }
        }
      }
    )
  }
}

function renderHeader(h, { id, type, status }) {
  return h(
    'div',
    {
      class: 'faic px-3'
    },
    [
      h(
        'g-chip',
        {
          class: 'ml-0 text-upper',
          props: {
            small: true,
            outline: true,
            color: sendingTypeColors[type],
            label: type
          }
        }
      ),

      renderStatus.call(this, h, status),
      renderMessageButton.call(this, h, id)
    ]
  )
}

function renderBody(h, fallback) {
  return h(
    formatTable,
    {
      props: {
        value: fallback,
        service: 'sendingsFallbacks'
      }
    }
  )
}

function renderDialog(h, { id }) {
  if (id) {
    return h(
      detailsDialog,
      {
        props: {
          value: this.sending,
          show: this.dialogs[id]
        },
        on: {
          close: () => {
            Vue.delete(this.dialogs, id)
          }
        }
      }
    )
  }
}

function renderItem(h, fallback) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1 grid-gap--8'
    },
    [
      renderHeader.call(this, h, fallback),
      renderBody.call(this, h, fallback),
      renderDialog.call(this, h, fallback)
    ]
  )
}

function renderItems(h) {
  if (this.restData[serviceName].get.state === states.ready) {
    if (this.restData[serviceName].get.data) {
      const fallbacks = _.get(this.restData, `${serviceName}.get.data.fallbacks`, [])
      if (Array.isArray(fallbacks) && fallbacks.length) {
        return h(
          'div',
          {
            class: 'grid grid-cols--1 py-3'
          },
          fallbacks.map(fallback => renderItem.call(this, h, fallback))
        )
      }
    }
  }

  return h(
    'div',
    {
      class: 'faic fjcc'
    },
    [ h('g-empty') ]
  )
}

export default function(h) {
  return renderItems.call(this, h)
}
