import { get } from 'lodash'

import { serviceName } from '@/components/services/requisites/utils'
import { opfListShort } from '@/components/services/requisites/utils'

import buttonPrint from '@/components/button/print'

export default function(h) {
  if (this.checkPermissions(`advanced.${serviceName}.get`)) {
    const opf = get(this.value, 'data.legal.opf')
    const name = get(this.value, 'data.legal.name')
    const city = get(this.value, 'data.postalAddress.city')
    const index = get(this.value, 'data.postalAddress.index')
    const address = get(this.value, 'data.postalAddress.address')

    return h(
      buttonPrint, {
        class: 'ma-0',
        props: {
          value: `<html style="height: 100%;"><body style="margin: 0px; height: 100%;"><div style="float: right; display: flex; align-items: flex-end; align-content: flex-end; justify-content: flex-end; height: 100%;"><table style="border-collapse: collapse; min-width: 350px; max-width: 350px;"><tr><td style="padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 8px; vertical-align: top;">Кому:</td><td style="padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 8px; vertical-align: top; width: 100%;">${opfListShort[opf]} "${name}"</td><td style="padding-right: 48px;"></td></tr><tr><td style="padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 8px; vertical-align: top;">Куда:</td><td style="padding-top: 4px; padding-bottom: 8px; padding-left: 8px; padding-right: 8px; vertical-align: top; width: 100%;">${address}<br>${city}</td><td style="padding-right: 48px;"></td></tr><tr><td style="padding-top: 4px; padding-bottom: 4px; padding-left: 8px; padding-right: 8px; vertical-align: top;">Индекс:</td><td style="padding-top: 4px; padding-bottom: 80px; padding-left: 8px; padding-right: 8px; vertical-align: top; width: 100%;">${index}</td><td style="padding-right: 48px;"></td></tr></table></div></body></html>`,
          disabled: !address
        }
      }
    )
  }
}
