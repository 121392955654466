import { services } from '@/utils'

import { cloneDeep } from 'lodash'

import { attach, serviceTemplate } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.messageRuleGroups,

      inputFilter(result) {
        if (Array.isArray(result.MessageRules) && result.MessageRules.length) {
          result._MessageRules = cloneDeep(result.MessageRules)
          result.MessageRules = result.MessageRules.map(item => {
            return {
              id: item.id,
              priority: item.priority,
              $attach: true
            }
          }).sort((a, b) => (a.priority || 0) - (b.priority || 0))
        } else {
          result.MessageRules = []
        }

        return result
      },
      outputFilter(result) {
        if (Array.isArray(result.MessageRules) && result.MessageRules.length) {
          attach(result, 'MessageRules')
        } else {
          result.MessageRules = undefined
        }

        return result
      },

      view: 'panels',
      views: [ 'panels' ],

      has: {
        filter: false,
        view: false
      }
    })
  ]
}
