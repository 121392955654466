export const ruleIcons = {
  text: 'text_fields',
  sender: 'person_outline',
  recipient: 'person',
  group: 'groups',
  mccmnc: 'public',
  contactList: 'perm_contact_cal',
  type: 'messenger',
  keywords: 'device_hub',
  moderation: 'add_moderator',
  fallbacks: 'wrap_text',
  buttons: 'library_add_check'
}
