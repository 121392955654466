import VirtualList from 'vue-virtual-scroll-list'

import SourceBox from '@/components/source/box'
import row from '@/components/misc/virtualScroll/row'

import { getLocaleDateString, getLocaleTimeString } from '@/utils'

const _ = { get: require('lodash/get') }

const directionIcons = {
  incoming: 'arrow_drop_down',
  outgoing: 'arrow_drop_up'
}

function renderDirectionIcon(h, log) {
  if (log.direction) {
    return h(
      'div',
      {
        class: 'faic fjcc square--18'
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: directionIcons[log.direction],
              color: 'grey',
              size: 15
            },
            directives: [
              {
                name: 'g-tooltip',
                options: {
                  value: this.getTranslate(`providers.tooltips.${log.direction}`),
                  placement: 'left'
                }
              }
            ]
          }
        )
      ]
    )
  }
}

function renderSearchField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.search,
        loading: this.loading,
        label: this.getTranslate('commons.labels.search'),
        beforeIcon: 'search',
        rounded: true,
        clearable: true,
        mode: 'outline',
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.search = event
        }
      }
    }
  )
}

function renderIsActiveField(h) {
  return h(
    'g-switch',
    {
      props: { value: this.online },
      directives: [
        {
          name: 'g-tooltip',
          options: { value: this.getTranslate('providers.tooltips.logs.online') }
        }
      ],
      on: {
        input: event => {
          this.online = event
        }
      }
    }
  )
}

function renderDateFilter(h) {
  return h(
    'g-menu',
    {
      props: {
        placement: 'bottom-end',
        offsetDistance: 10,
        closeOnContentClick: false,
        transparent: true
      }
    },
    [
      h(
        'g-button',
        {
          class: 'my-0',
          props: {
            icon: 'date_range',
            flat: true,
            rounded: true
          },
          slot: 'activator'
        }
      ),

      h(
        'g-card',
        {
          class: 'pb-2',
          props: { rounded: true }
        },
        [
          h(
            'g-date-picker',
            {
              props: {
                value: this.date,
                localeTag: this.locale
              },
              on: {
                input: event => {
                  this.date = event
                }
              }
            }
          ),

          h(
            'div',
            {
              class: 'fjcc facc faic px-2'
            },
            [
              h(
                'g-text-field',
                {
                  class: 'mr-2',
                  props: {
                    value: this.time.gt,
                    mode: 'outline',
                    dense: true,
                    rounded: true,
                    details: false,
                    type: 'time',
                    step: 1
                  },
                  on: {
                    input: event => {
                      this.time.gt = event
                      this.parseTime()
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.time.lt,
                    mode: 'outline',
                    dense: true,
                    rounded: true,
                    details: false,
                    type: 'time',
                    step: 1
                  },
                  on: {
                    input: event => {
                      this.time.lt = event
                      this.parseTime()
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}

function renderRefreshButton(h) {
  return h(
    'g-button',
    {
      class: 'my-0 mr-0',
      props: {
        flat: true,
        icon: 'refresh',
        loading: this.loading,
        disabled: this.loading
      },
      on: {
        click: () => {
          this.get()
        }
      }
    }
  )
}

function renderFilter(h) {
  if (!this.readonly) {
    return h(
      'div',
      {
        class: 'faic pa-2'
      },
      [
        renderSearchField.call(this, h),
        renderDateFilter.call(this, h),
        renderIsActiveField.call(this, h),
        renderRefreshButton.call(this, h)
      ]
    )
  }
}

function renderDate(h, log) {
  return h(
    'div',
    {
      class: 'faic fjcc square--18'
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: 'date_range',
            color: 'grey',
            size: 15
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: getLocaleDateString(log.time),
                placement: 'left'
              }
            }
          ]
        }
      )
    ]
  )
}

function renderChip(h, log, path) {
  let content = _.get(log, path)
  if (path === 'time') {
    content = getLocaleTimeString(content)
  }
  if (content) {
    return h(
      'div',
      {
        class: 'providers-logs__chip'
      },
      content
    )
  }
}

function renderTitle(h, log) {
  return h(
    'div',
    {
      class: 'providers-logs__title'
    },
    [
      h(
        'div',
        {
          class: 'faic'
        },
        [
          renderDate.call(this, h, log),
          renderChip.call(this, h, log, 'time'),
          renderChip.call(this, h, log, 'direction'),
          renderChip.call(this, h, log, 'protocol'),
          renderChip.call(this, h, log, 'type')
        ]
      ),

      h(
        'div',
        {
          class: 'faic'
        },
        [
          renderDirectionIcon.call(this, h, log),

          h('div', { class: 'small text--grey ml-1' }, log.SendingId)
        ]
      )
    ]
  )
}

function renderPayload(h, log) {
  if (log.payload) {
    return h(
      SourceBox,
      {
        props: { value: log.payload }
      }
    )
  }
}

function renderLog(h, log) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderTitle.call(this, h, log),
      renderPayload.call(this, h, log)
    ]
  )
}

function renderLogs(h) {
  if (Array.isArray(this.logs) && this.logs.length) {
    return h(
      VirtualList,
      {
        class: {
          'providers-logs': true,
          'providers-logs--dialog': this.dialog
        },
        props: {
          dataKey: 'ProviderId',
          dataSources: this.logs,
          dataComponent: row,
          itemScopedSlots: {
            item: source => renderLog.call(this, h, source)
          }
        }
      }
    )
  } else {
    return h('g-empty')
  }
}

function renderSeparator(h) {
  if (!this.readonly) {
    return h('g-divider')
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'fc ff',
      key: 'logs'
    },
    [
      renderFilter.call(this, h),
      renderSeparator.call(this, h),
      renderLogs.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
