const _ = {
  mergeWith: require('lodash/mergeWith')
}

export function mergeReplacingArrays(...args) {
  return _.mergeWith(...args, (a, b) => {
    if (Array.isArray(a) || Array.isArray(b)) {
      return b
    }

    return undefined
  })
}
export default mergeReplacingArrays
