import tariffsConditions from '@/components/services/tariffs/conditions'

import { getPrice } from '@/utils'

import { serviceName } from '@/components/services/tariffs/utils'

function renderOr(h, index) {
  if (index > 0) {
    return h(
      'div',
      {
        class: `${serviceName}-rules__group-or`
      },
      [
        h(
          'div',
          {
            class: `${serviceName}-rules__group-or-text`
          },
          this.getTranslate('misc.or')
        )
      ]
    )
  }
}

function renderConditions(h, { rules }) {
  if (Array.isArray(rules) && rules.length) {
    return h(
      'div',
      {
        class: `${serviceName}-rules__group-set`
      },
      rules.map(({ conditions }, index) => {
        return h(
          'div',
          {
            class: `${serviceName}-rules__group-set-item`,
            key: `rule-${index}`
          },
          [
            renderOr.call(this, h, index),

            h(
              tariffsConditions,
              {
                props: {
                  value: conditions,
                  type: this.source,
                  readonly: true
                }
              }
            )
          ]
        )
      })
    )
  }
}

function renderRulePrice(h, { price }) {
  if (Array.isArray(price) && price.length) {
    return h(
      'div',
      {
        class: `${serviceName}-rules__default-rule-prices`
      },
      price.map((price, index) => {
        return h(
          'div',
          {
            class: `${serviceName}-rules__default-rule-price`
          },
          [
            h(
              'div',
              {
                class: `${serviceName}-rules__default-rule-price-value`
              },
              getPrice(price)
            ),

            h(
              'div',
              {
                class: `${serviceName}-rules__default-rule-price-description`
              },
              this.priceHint[index]
            )
          ]
        )
      })
    )
  }
}

function renderActionPrefix(h, isDefault) {
  if (isDefault) {
    return h(
      'div',
      {
        class: `${serviceName}-rules__default-rule-action-prefix`
      },
      this.getTranslate(`${serviceName}.${this.rules.length ? 'otherCases' : 'always'}`)
    )
  }
}

function renderAction(h, { action }, index, isDefault) {
  if (action) {
    return h(
      'div',
      {
        class: `${serviceName}-rules__default-rule-action`
      },
      [
        renderActionPrefix.call(this, h, isDefault),

        h(
          'div',
          {
            class: {
              [`${serviceName}-rules__default-rule-action-title`]: true,
              [`${serviceName}-rules__default-rule-action-title--prefixed`]: isDefault
            }
          },
          this.getTranslate(`${serviceName}.actions.main.${action}`)
        ),

        h(
          'g-icon',
          {
            class: `${serviceName}-rules__default-rule-action-icon`,
            props: {
              value: action === 'decrease' ? 'arrow_drop_down' : 'arrow_drop_up',
              color: action === 'decrease' ? 'error' : 'success'
            }
          }
        )
      ]
    )
  }
}

function renderTitle(h, rule, index, isDefault) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__default-rule-title`
    },
    [
      renderAction.call(this, h, rule, index, isDefault),
      renderRulePrice.call(this, h, rule)
    ]
  )
}

function renderRule(h, rule, index, isDefault = false) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__default-rule`,
      key: `rule-${index}`
    },
    [
      renderTitle.call(this, h, rule, index, isDefault),
      renderConditions.call(this, h, rule, index)
    ]
  )
}

function renderDefaultRule(h) {
  if (this.defaultRule) {
    return renderRule.call(this, h, this.defaultRule, 'defaultRule', true)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__default-rules`
    },
    [
      ...this.rules.map((rule, index) => renderRule.call(this, h, rule, index)),

      renderDefaultRule.call(this, h)
    ]
  )
}
