import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import { dictionaryToService } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

export default {
  name: 'LocalSearch',

  props: {
    dictionary: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      searchValue: undefined,
      searchResult: undefined,
      searchError: undefined,

      searchValueIsValid: false,

      loading: false
    }
  },

  methods: {
    cachedRequest: Cache.wrapWithCache(async (key, params) => {
      return await Vue.$GRequest.create(`/${params.service}/search`, {
        id: params.id,
        OwnerId: params.OwnerId,
        value: params.value
      })
    }),

    async search() {
      if (this.loading) {
        return
      }

      try {
        this.loading = true

        if (this.searchValueIsValid) {
          this.searchResult = undefined
          this.searchError = undefined

          const { data } = await this.cachedRequest(`${this.dictionary.type}:${this.dictionary.OwnerId}:${this.dictionary.id}:${this.searchValue}`, {
            service: dictionaryToService(this.dictionary.type),
            id: this.dictionary.id,
            OwnerId: this.dictionary.OwnerId,
            value: this.searchValue
          })

          if (data) {
            this.searchResult = data
          }
        }
      } catch (error) {
        this.searchError = true
        this.addSnackbar({
          text: error.message,
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  },

  render
}
