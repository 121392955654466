export default {
  title: 'Credentials',

  titles: {
    create: 'Create credentials',
    update: 'Update credentials',
    remove: 'Remove credentials'
  },

  labels: {
    title: 'Title',
    records: {
      username: 'Username',
      password: 'Password',
      token: 'Token',
      apikey: 'API key'
    },
    search: 'Search'
  },

  contents: {
    confirm: {
      remove: 'Confirm remove credentials.'
    }
  },

  hints: {
    notFound: 'Credentials not found.',
    data: 'Data',
    info: 'Please fill in at least one of the fields below to create Credentials.'
  },

  tooltips: {
    favorites: 'Make the credentials a favorite'
  },

  snackbars: {
    created: 'Credentials created',
    updated: 'Credentials updated',
    removed: 'Credentials removed'
  }
}
