export default function(h) {
  return h(
    'g-card',
    {
      class: 'pa-3',
      props: { rounded: true }
    },
    [
      h(
        'div',
        {
          class: 'faic'
        },
        [
          h(
            'g-icon',
            {
              props: {
                value: 'info',
                color: 'error'
              }
            }
          ),

          h('div', { class: 'ml-3' }, this.getTranslate('misc.hints.componentNotFound'))
        ]
      )
    ]
  )
}
