export const PROVIDERS_PROPERTIES_TYPES = {
  defaultWebhookId: 'text',
  customWebhookId: 'text',
  messagePartIdPrefix: 'text',
  keepAlive: 'boolean',
  noSeenStatus: 'boolean',
  failurePercent: 'text',
  chatPultIntegration: 'number',
  tts: 'text',
  trunk: 'text',
  prefix: 'text',
  trycount: 'number',
  trydelay: 'number',
  length: 'number',
  record_timeout: 'number',
  wait_timeout: 'number',
  dtmf_timeout: 'number',
  menu: 'number',
  asr: 'number',
  resendSms: 'boolean',
  priority: 'text',
  validityPeriodSec: 'number',
  rus: 'number',
  traffic_type: 'number',
  proxy: 'number',
  accountId: 'number',
  routes: 'text',
  delivery_policy: 'text',
  ttl: 'text',
  dlr: 'number',
  dlr_timeout: 'number',
  expiry_txt: 'number',
  system_type: 'text',
  preferredDataCodings: 'text',
  registeredDelivery: 'number',
  interface_version: 'number',
  tls: 'boolean',
  enquireLinkTimeout: 'number',
  source_addr_ton: 'number',
  source_addr_npi: 'number',
  dest_addr_ton: 'number',
  dest_addr_npi: 'number',
  senderName: 'text',
  _testType: 'text',
  _testSendername: 'text',
  _testRecipient: 'text',
  _testPayloadText: 'text',
  rateLimit_concurrency: 'number',
  rateLimit_messagesPerSecond: 'number'
}

export default { PROVIDERS_PROPERTIES_TYPES }
