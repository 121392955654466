export default {
  title: 'Шаблоны документов',

  titles: {
    generate: 'Скачать документ',
    contract: 'Шаблон договора',
    invoice: 'Шаблон счёта',
    empty: {
      contract: 'Нет шаблона договора',
      invoice: 'Нет шаблона счёта'
    }
  },

  labels: {
    requisite: 'Ваши реквизиты',
    company: 'Контрагент',
    type: 'Тип документа',
    sum: 'Сумма платежа'
  },

  singular: {
    requisite: 'Реквизиты',
    company: 'Контрагент',
    type: 'Тип'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаление шаблона документа.'
    }
  },

  buttons: {
    download: 'Скачать документ',
    add: {
      template: 'Добавить'
    },
    create: {
      requisite: 'Создать реквизиты'
    }
  },

  types: {
    contract: 'Договор',
    invoice: 'Счёт'
  },

  hints: {
    searching: {
      requisites: 'Ищем ваши реквизиты...',
      companies: 'Ищем ваши компании...'
    }
  },

  warnings: {
    notFound: {
      requisites:
        'К сожалению, мы не можем найти ваши реквизиты.<br><a href="/#/account#requisites" class="link link--active link--underline">Создайте</a> их и попробуйте снова.',
      requisitesForAdmin:
        'К сожалению, мы не можем найти реквизиты для этого пользователя.<br>Вы можете <a href="/#/admin/users/{OwnerId}#requisites" class="link link--active link--underline">создать</a> их для него самостоятельно.',
      companies:
        'К сожалению, мы не можем найти ни одного контрагента для выбранных реквизитов.<br>Обратитесь в поддержку для решения этого вопроса.',
      types:
        'К сожалению, мы не можем сгенерировать документы для данного контрагента.<br>Обратитесь в поддержку для решения этого вопроса.'
    }
  },

  snackbars: {
    created: 'Документ успешно создан.',
    updated: 'Документ успешно обновлён.',
    removed: 'Документ успешно удалён.'
  }
}
