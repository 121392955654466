export default {
  labels: {
    accent: 'Акцентный',
    error: 'Ошибка',
    info: 'Инфо',
    primary: 'Основной',
    secondary: 'Второстепенный',
    success: 'Успех',
    warning: 'Предупреждение'
  },

  hints: {
    accent: 'Акцентный',
    error: 'При ошибочных действиях',
    info: 'Информационный',
    primary: 'Основной',
    secondary: 'Второстепенный',
    success: 'При успешных действиях',
    warning: 'Предупреждение'
  }
}
