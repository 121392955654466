import { buttonModes, colors, components, inputModes, services } from '@/utils'

import button from '@/components/button'
import info from '@/components/misc/info'

function renderSumField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.sum,
        label: this.getTranslate(`${services.payments}.labels.sum`),
        hint: this.getTranslate(`${services.payments}.hints.sum`),
        suffix: this.getTranslate(),
        type: 'number',
        step: 1,
        min: 0,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        autofocus: true,
        details: true
      },
      on: {
        input: event => {
          this.sum = event
        }
      }
    }
  )
}
function renderPaymentTypeField(h) {
  return h(
    components.select,
    {
      props: {
        value: this.paymentType,
        label: this.getTranslate(`${services.payments}.labels.paymentType`),
        items: [ 'PC', 'AC' ].map(value => {
          return {
            title: this.getTranslate(`${services.payments}.yooMoney.paymentTypes.${value}`),
            value
          }
        }),
        hint: this.getTranslate(`${services.payments}.hints.fee.${this.paymentType}`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        details: true
      },
      on: {
        input: event => {
          this.paymentType = event
        }
      }
    }
  )
}
function renderFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--12'
    },
    [
      renderSumField.call(this, h),
      renderPaymentTypeField.call(this, h)
    ]
  )
}

function renderHiddenFields(h) {
  return Object.keys(this.payload).map(field => {
    return h(
      'input',
      {
        attrs: {
          hidden: true,
          value: this.payload[field],
          name: field
        }
      }
    )
  })
}

function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${services.payments}.yooMoney.warning`),
        color: colors.info
      }
    }
  )
}

function renderRequestButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.filled,
        color: colors.primary,
        label: this.getTranslate('misc.buttons.pay'),
        loading: this.loading,
        disabled: !this.validation || this.loading,
        type: 'submit'
      }
    }
  )
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe'
    },
    [ renderRequestButton.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'form',
    {
      class: 'grid grid-gap--8 pa-2',
      attrs: {
        action: 'https://yoomoney.ru/quickpay/confirm.xml',
        method: 'post'
      }
    },
    [
      renderHiddenFields.call(this, h),
      renderFields.call(this, h),
      renderInfo.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
