import Vue from 'vue'
import mime from 'mime'

import { getTranslate, states } from '@/utils'

export default {
  namespaced: true,

  state: {
    file: undefined,
    state: undefined,
    error: undefined,

    attributes: {
      isPublic: undefined,
      multiple: undefined,
      name: undefined,
      types: undefined,
      url: undefined,
      method: undefined,
      maxFileSize: undefined
    }
  },

  mutations: {
    setFile(state, value) {
      state.file = value
    },

    setState(state, value) {
      state.state = value
    },

    setError(state, value) {
      state.error = value
    },

    setAttributes(state, value) {
      state.attributes = value
    }
  },

  actions: {
    setFile({ commit }, value) {
      commit('setFile', value)
    },

    setState({ commit }, value) {
      commit('setState', value)
    },

    setError({ commit }, value) {
      commit('setError', value)
    },

    setAttributes({ commit }, value) {
      commit('setAttributes', {
        isPublic: value.isPublic,
        multiple: value.multiple,
        name: value.name,
        types: value.types,
        url: value.url,
        method: value.method,
        maxFileSize: value.maxFileSize
      })
    },

    async uploadFile({ state, commit, rootState }, value) {
      try {
        commit('setState', states.loading)

        const params = {}
        const headers = {
          Accept: 'application/json',
          Authorization: rootState.authentication.token
        }

        let data
        if (state.attributes.name && typeof state.attributes.name === 'string') {
          data = new FormData()
          data.append(state.attributes.name, value, value.name)
        } else {
          data = value
          params.filename = value.name

          let type = value.type
          const match = value.name.match(/.*\.(.+)/)
          if (match) {
            type = mime.getType(match[1])
          }

          if (type) {
            headers['Content-Type'] = type
          }
        }

        if (state.attributes.isPublic) {
          params.isPublic = true
        }

        const result = await Vue.$GRequest._request({
          url: state.attributes.url,
          method: state.attributes.method,
          data,
          headers,
          params
        })

        if (result) {
          commit('setState', states.success)
          commit('setFile', result)
        } else {
          throw new Error('Server did not respond')
        }
      } catch (error) {
        commit('setState', states.error)
        commit('setError', error)
      }
    },

    handler({ state, dispatch }, value) {
      const { target, dataTransfer } = value || {}
      const files = dataTransfer ? dataTransfer?.files : target?.files
      if (files) {
        for (const file of files) {
          if (file.size <= state.attributes.maxFileSize) {
            dispatch('file/uploadFile', file, { root: true })
          } else {
            dispatch(
              'notifications/addSnackbar',
              {
                text: getTranslate('errors.types.fileMaxSize', { expected: `${state.attributes.maxFileSize}b` }),
                type: 'error'
              },
              { root: true }
            )
          }
        }
      }
    }
  },

  getters: {
    file: state => state.file,
    state: state => state.state,
    error: state => state.error,
    attributes: state => state.attributes
  }
}
