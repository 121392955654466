import { TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'Title',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    to() {
      if (this.value.type === TASK_TYPES['payments:recurrent']) {
        if (this.value.data.recurrentPaymentId) {
          return {
            name: services.payments,
            query: { recurrentPaymentId: this.value.data.recurrentPaymentId }
          }
        }
      }
    }
  },

  render
}
