export default {
  title: 'Account',

  current: {
    balance: 'Current balance',
    overdraft: 'Current overdraft'
  },

  tooltips: {
    warning: 'You`re currently using overdraft.',
    error: 'You`ve reached an overdraft limit.'
  }
}
