import FileDialog from '@/components/file/dialog'

function renderEditDialog(h) {
  return h(
    FileDialog,
    {
      props: {
        value: this.currentEditFile,
        googleFileURL: this.googleFileURL,
        show: this.showDialog,
        type: 'edit'
      },
      on: {
        show: event => {
          this.showDialog = event
        },
        input: event => {
          this.currentEditFile = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    this.view,
    {
      class: { 'ma-0': this.view === 'g-button' },
      props: this.$props,
      directives: this.$directives,
      on: { click: () => this.edit() },
      scopedSlots: {
        dialog: () => {
          return renderEditDialog.call(this, h)
        }
      }
    }
  )
}
