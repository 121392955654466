import { TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.title,
        label: this.getTranslate(`${services.tasks}.labels.title`),
        copy: true,
        borderStyle: this.to ? 'solid' : 'dashed',
        readonly: this.value.type !== TASK_TYPES['payments:recurrent'],
        to: this.to
      }
    }
  )
}
