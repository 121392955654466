export default function(h) {
  const directives = []
  if (this.tooltip) {
    directives.push({
      name: 'g-tooltip',
      options: { value: this.tooltip }
    })
  }

  return h(
    'g-icon',
    {
      attrs: { 'data-clipboard-text': `${this.value}` },
      class: {
        [`link copy-icon-${this._uid}`]: true,
        'mx-1': !this.marginless
      },
      props: {
        value: this.icon,
        library: this.library,
        size: this.size,
        color: this.color
      },
      directives
    }
  )
}
