import Vue from 'vue'
import Vuex from 'vuex'

import global from './modules/global'

import account from './modules/account'
import audio from './modules/audio'
import authentication from './modules/authentication'
import currentTab from './modules/currentTab'
import dialog from './modules/dialog'
import favorites from './modules/favorites'
import file from './modules/file'
import locale from './modules/locale'
import login from './modules/login'
import visited from './modules/visited'
import notifications from './modules/notifications'
import payments from './modules/payments'
import settings from './modules/settings'
import sortable from './modules/sortable'
import viewport from './modules/viewport'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    global,

    account,
    audio,
    authentication,
    currentTab,
    dialog,
    favorites,
    file,
    locale,
    login,
    visited,
    notifications,
    payments,
    settings,
    sortable,
    viewport
  },

  strict: process.env.NODE_ENV !== 'production'
})
