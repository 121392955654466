import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'

export const contactFields = upgradeArray([ 'phone', 'lastName', 'firstName', 'middleName', 'email', 'custom01', 'custom02', 'custom03', 'actions', 'createdAt' ])

export function translateHandler(item) {
  switch (item.field) {
    case contactFields.phone: {
      return this.getTranslate('errors.types.uniqPhoneNumber')
    }

    default: {
      return this.getTranslate(`errors.types.${item.type}`, {
        field: item.field && this.getTranslate(`errors.fields.${item.field}`) || undefined,
        expected: item.expected || undefined
      })
    }
  }
}

export function errorProcessor(errors) {
  if (!Array.isArray(errors)) {
    errors = [ errors ]
  }

  const resultErrors = []

  for (const error of errors) {
    if (Array.isArray(error.data) && error.data.length) {
      for (const item of error.data) {
        if (item.field !== 'ListId') {
          resultErrors.push({
            type: 'field',
            field: item.field,
            translate: translateHandler.call(this, item),
            raw: error
          })
        }
      }
    }
  }

  return resultErrors
}

export function errorProcessorHandler(errors) {
  return errorProcessor.call(this.ctx, errors)
}
