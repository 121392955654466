import { components } from '@/utils'

import render from './render'

export default {
  name: components.list,

  props: {
    dense: Boolean,
    rounded: Boolean,
    outline: Boolean,
    disabled: Boolean,
    transparent: Boolean,

    items: {
      type: Array,
      default: () => []
    }
  },

  render
}
