import { mapGetters } from 'vuex'
import { generateAmount } from '@sigma-legacy-libs/essentials/lib/utils'
import { PAYMENTS_COMMISSIONS } from '@sigma-legacy-libs/essentials/lib/constants'

import { proxy, services } from '@/utils'

import render from './render'

export default {
  name: 'PaymentsForm',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    ...mapGetters({ paymentsMethods: 'payments/methods' }),

    commission() {
      if (this.proxy.method) {
        return PAYMENTS_COMMISSIONS[this.proxy.method]
      }
    },
    commissionSuffix() {
      if (this.commission) {
        return [
          [
            '+',
            this.getCommission(),
            this.getTranslate(`currency.${this.globalSettings.billings.currency}.symbol.unicode`)
          ].join(''),
          this.getTranslate(`${services.payments}.hints.commissions`)
        ].join(' ')
      }
    },
    commissionHint() {
      if (this.commission) {
        return `${this.getTranslate(`${services.payments}.hints.commission`)} ${this.commission}%`
      }
    }
  },

  watch: {
    $route: {
      handler() {
        this.setOwnerId()
      },
      deep: true
    }
  },

  mounted() {
    this.setOwnerId()
  },

  methods: {
    setOwnerId() {
      const owner = this.$route.params.owner
      if (owner && !this.proxy.OwnerId) {
        this.proxy.OwnerId = owner
      }
    },

    getCommission() {
      const amount = this.proxy.amount || 0
      const amountWithCommission = generateAmount(amount, this.commission)

      return (amountWithCommission - amount).toFixed(2)
    }
  },

  render
}
