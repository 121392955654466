import { generateUniqueKey, services } from '@/utils'
import { stateColors, stateIcons } from '@/components/services/providers/utils'

import squircle from '@/components/icon/squircle'

export default function(h) {
  return h(
    squircle,
    {
      props: {
        icon: stateIcons[this.state] || 'help_outline',
        color: stateColors[this.state] || 'grey',
        size: this.size,
        loading: this.loading,
        tooltip: this.getTranslate(`${services.providers}.states.${this.state}`)
      },
      key: generateUniqueKey()
    }
  )
}
