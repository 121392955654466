import { RENDER_SETTINGS_SITE_SCHEMA, RENDER_SETTINGS_SITE_SCHEMA_RESELLER } from '@/utils'

import { serviceName } from '@/components/services/settingsSite/utils'

import render from './render'

export default function(options) {
  return {
    computed: {
      renderSchema() {
        if (options.UserId && this.checkPermissions(`advanced.${serviceName}.set`) || this.checkPermissions(`advanced.${serviceName}.set`, 'reseller')) {
          return RENDER_SETTINGS_SITE_SCHEMA_RESELLER
        } else if (this.checkPermissions(`advanced.${serviceName}.set`)) {
          return RENDER_SETTINGS_SITE_SCHEMA
        }
      }
    },

    render(h) {
      return render.call(this, h, Object.assign({
        renderSchema: this.renderSchema,
        serviceName
      }, options))
    }
  }
}
