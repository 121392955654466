import { cloneDeep, defaultsDeep } from 'lodash'

import { EMPTY_REQUISITES, EMPTY_USERS } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices } from '@/utils'

import { serviceName } from '@/components/services/requisites/utils'

import render from './render'

export default {
  name: 'UsersRequisites',

  mixins: [
    generateServices({
      name: serviceName,

      inputFilter(result) {
        return defaultsDeep(result, cloneDeep(EMPTY_REQUISITES)) // потому что с сервера приходит урезанный payload
      },

      find: {
        defaultFilter: {
          OwnerId: undefined
        }
      },

      get: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  props: {
    OwnerId: String,
    User: {
      type: Object,
      default: () => cloneDeep(EMPTY_USERS)
    }
  },

  data() {
    return {
      requisiteId: undefined,
      dialogType: undefined,

      showDialog: false
    }
  },

  mounted() {
    this.restData[serviceName].find.filter.OwnerId = this.OwnerId
  },

  render
}
