export default {
  title: 'Шаблоны',

  labels: {
    id: 'ID',
    title: 'Название',
    type: 'Тип',
    owner: 'Владелец',
    text: 'Текст',
    isActive: 'Активен'
  },

  buttons: {
    create: 'Создать шаблон',
    apply: 'Применить'
  },
  links: { edit: 'Изменить шаблон' },
  dialogs: { create: 'Назовите ваш шаблон' },

  contents: {
    confirm: {
      remove: 'Вы уверен что хотите удалить шаблон {title}?'
    }
  },

  tooltips: {
    isActive: 'Активировать или деактивировать шаблон.'
  },

  snackbars: {
    created: 'Шаблон успешно создан.',
    updated: 'Шаблон успешно обновлён.',
    removed: 'Шаблон успешно удалён.'
  }
}
