import { get } from 'lodash'

import { colors, getLocaleDateString, getLocaleTimeString, icons } from '@/utils'

import render from './render'

export default {
  name: 'Date',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    pathToDate: {
      type: String,
      default: 'createdAt'
    },

    compact: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    $props() {
      const value = get(this.value, this.pathToDate)
      const label = this.getTranslate(`commons.labels.${this.pathToDate}`)
      const result = {
        value: [ getLocaleDateString(value), getLocaleTimeString(value) ].join(' '),
        label,
        icon: icons.date_range,
        color: colors.grey,
        minWidth: 170
      }

      if (this.compact) {
        result.value = getLocaleTimeString(value)
        result.label = getLocaleDateString(value),
        result.minWidth = 105,
        result.width = 105,
        result.iconTooltip = label
      }

      return result
    }
  },

  render
}
