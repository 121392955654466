import { SENDING_PAYLOADS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateKey } from './generateKey'

const fileDataFields = [ 'file', 'audio', 'image' ]

const _ = {
  get: require('lodash/get'),
  pick: require('lodash/pick')
}

export const sendingsOutputFilter = data => {
  const { type, fallbacks } = data

  data.payload = _.pick(
    data.payload,
    SENDING_PAYLOADS[type].filter(item => item !== 'recipient')
  )

  if (type === SENDING_TYPES.voice) {
    if (data.payload.audio) {
      delete data.payload.text
      delete data.payload.tts
    } else {
      delete data.payload.audio
    }
  }

  if (type === SENDING_TYPES.whatsapp) {
    for (const field of fileDataFields) {
      if (!data.payload[field]) {
        delete data.payload[field]
      }
    }

    if (!data.payload.text) {
      delete data.payload.text
    }
  }

  for (const field of fileDataFields) {
    if (data.payload[field]) {
      data.payload[field] = generateKey(data.payload[field])
    }
  }

  if (Array.isArray(fallbacks) && fallbacks.length) {
    for (const { payload, $options } of fallbacks) {
      if (payload) {
        for (const field of fileDataFields) {
          if (payload[field]) {
            payload[field] = generateKey(payload[field])
          }
        }
      }
      if ($options) {
        for (const key in $options) {
          const option = _.get($options, key)
          if (option && typeof option === 'object') {
            if (!Array.isArray(option)) {
              if (!Object.keys(option).every(k => !!option[k])) {
                delete $options[key]
              }
            }
            if (typeof option.timeout === 'string') {
              option.timeout = parseInt(option.timeout)
            }
          }
        }
      }
    }
  }

  return data
}

export default { sendingsOutputFilter }
