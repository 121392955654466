import { serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.credentials,

      view: 'panels',
      views: [ 'panels' ],

      has: {
        clone: false,
        filter: false,
        view: false
      }
    })
  ]
}
