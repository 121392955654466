import Clipboard from 'clipboard'

import render from './render'

export default {
  name: 'CopyIcon',

  props: {
    value: [ String, Number ],

    snackbar: {
      type: Object,
      default: () => ({})
    },

    icon: {
      type: String,
      default: 'content_copy'
    },
    size: {
      type: Number,
      default: 14
    },
    color: {
      type: String,
      default: 'grey'
    },

    library: String,
    tooltip: String,

    marginless: Boolean
  },

  data() {
    return {
      cp: undefined
    }
  },

  mounted() {
    this.cp = new Clipboard(`.copy-icon-${this._uid}`)
    this.cp.on('success', () => {
      if (this.snackbar.text || this.snackbar.type) {
        this.addSnackbar(this.snackbar)
      } else {
        this.addSnackbar({
          text: this.getTranslate('misc.copied'),
          type: 'success'
        })
      }
    })
  },

  beforeDestroy() {
    if (this.cp && typeof this.cp.destroy === 'function') {
      this.cp.destroy()
    }
  },

  render
}
