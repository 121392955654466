import Vue from 'vue'

import { get } from 'lodash'

import { isStringNotEmpty, permissionPresets } from '@/utils'

function renderVerified(h, right) {
  if (this.data.$verified || this.verified) {
    return h(
      'g-icon',
      {
        style: {
          'font-size': 'inherit',
          cursor: 'default'
        },
        props: {
          color: 'accent',
          value: 'check_circle',
          right
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate('misc.verified'),
              placement: 'top'
            }
          }
        ]
      }
    )
  }
}

export default function(h) {
  if (this.data) {
    let title = get(this.data, this.pathToTitle, this.data.username)
    if (isStringNotEmpty(title) && title.length > this.length) {
      title = title.slice(0, this.length) + '...'
    }

    let type = this.type
    if (!this.checkPermissions(`advanced.${this.service}.get`, permissionPresets.managerUp)) {
      type = 'text'
    }

    const to = this.to || {
      name: `${this.service}.single`,
      params: { id: get(this.data, this.pathToId) }
    }

    switch (type) {
      case 'link': {
        return h(
          'div',
          {
            class: 'faic text-nowrap lh-n'
          },
          [
            h(
              'router-link',
              {
                attrs: { to },
                class: 'link text-overflow mr-1'
              },
              title
            ),

            renderVerified.call(this, h)
          ]
        )
      }
      case 'button': {
        return h(
          'g-button',
          {
            props: {
              flat: this.flat,
              rounded: this.rounded,
              small: this.small,
              large: this.large,
              depressed: this.depressed,
              color: this.color
            },
            on: {
              click: () => {
                Vue.router.push(to)
              }
            }
          },
          [ title, renderVerified.call(this, h, true) ]
        )
      }
      case 'text':
      default: {
        return h(
          'div',
          {
            attrs: { 'data-clipboard-text': `${title}` },
            class: `faic text-nowrap lh-n copy-single-link-${this._uid}`
          },
          [
            h('div', { class: 'text-overflow mr-1' }, title),

            renderVerified.call(this, h)
          ]
        )
      }
    }
  }
}
