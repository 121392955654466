import { permissionPresets, tableHeaderParser } from '@/utils'

import render from './render'

const serviceName = 'storage'

export default {
  name: 'FileItems',

  props: {
    type: {
      type: String,
      default: 'cards'
    },

    items: {
      type: Array,
      default: () => []
    },

    order: {
      type: Object,
      default: () => ({})
    },

    currentChosenFileId: String,

    readonly: Boolean,
    embedded: Boolean
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          value: 'type'
        },
        {
          title: `${serviceName}.headers.title`,
          value: 'title',
          align: 'left',
          class: 'pl-0 pr-0'
        },
        {
          title: `${serviceName}.headers.owner`,
          value: 'owner',
          align: 'left',
          class: 'pl-2 pr-2',
          show: this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)
        },
        {
          title: `${serviceName}.headers.size`,
          value: 'size',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: `${serviceName}.headers.createdAt`,
          value: 'createdAt',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          value: 'actions',
          show: this.editable
        }
      ])
    },

    editable() {
      return this.checkPermissions([ `advanced.${serviceName}.update`, `advanced.${serviceName}.remove` ], permissionPresets.meUp) && !this.readonly
    },

    $_class() {
      const result = { 'grid grid-gap--8 px-2': true }
      if (this.viewport.size.width > 1600) {
        if (!this.embedded) {
          result['grid-cols--4'] = true
        } else {
          result['grid-cols--3'] = true
        }
      } else if (this.viewport.size.width > 960) {
        result['grid-cols--3'] = true
      } else if (this.viewport.size.width > 600) {
        result['grid-cols--2'] = true
      }

      return result
    }
  },

  render
}
