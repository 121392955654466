export default {
  title: 'Восстановление пароля',

  labels: {
    login: 'Имя пользователя или e-mail',
    password: 'Пароль'
  },

  hints: {
    recover: 'Забыли пароль?',
    password: 'Введите новый пароль и нажмите кнопку сбросить'
  },

  buttons: {
    reset: 'Сбросить'
  },

  contents: {
    validation: {
      request:
        'На указанный вами при регистрации e-mail было отправлено письмо с дальнейшими инструкциями по восстановлению пароля.'
    }
  }
}
