export default {
  titles: {
    generate: 'Сгенерировать текст сообщения',
    additional: 'Дополнительные настройки',
    text: 'Сгенерированный текст'
  },

  labels: {
    text: 'Текст сообщения',
    messageTheme: 'Тематика сообщения',
    occasion: 'Ситуация или случай',
    smsSegments: 'Количество сегментов СМС',
    otherChars: 'Количество символов',
    additionalNotes1: 'Пожелания',
    rephraseText: 'Перефразировать',
    addEmojis: 'Использовать смайлики',
    endQuestion: 'Закончить следующим вопросом',
    emotionalTone: 'Эмоциональный оттенок',
    language: 'Язык сообщения'
  },

  emotionalTones: {
    joyful: 'Радостный',
    neutral: 'Нейтральный',
    businesslike: 'Деловой',
    energetic: 'Энергичный',
    enthusiastic: 'Задорный',
    excited: 'Возбужденный',
    optimistic: 'Оптимистичный',
    informative: 'Информативный',
    inspiring: 'Вдохновляющий'
  },

  infos: {
    additional: 'Для более точного результата заполните "Дополнительные настройки" расположенные ниже',
    text: 'Здесь отобразится сгенерированный текст'
  },

  hints: {
    messageTheme: 'Ключевая тема сообщения',
    occasion: 'Опишите основную суть ситуации',
    smsSegments: 'От 1 до 10 сегментов максимум',
    otherChars: 'До 1000 символов максимум',
    additionalNotes1: 'Дополнительные пожелания к тексту',
    rephraseText: 'Текст, который необходимо перефразировать'
  },

  tooltips: {
    generate: 'Использовать генеративную модель для создания текста сообщения'
  },

  warnings: {
    limit: 'Превышен лимит запросов. Попробуйте позже.'
  },

  buttons: {
    generate: 'Генерация',
    additional: 'Дополнительные настройки'
  },

  stats: {
    characters: 'Символов: {value}',
    segments: 'Сегментов: {value}',
    encoding: 'Кодировка: {value}'
  }
}
