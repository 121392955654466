import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import { globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

export default {
  name: 'Requisites',

  props: {
    OwnerId: {
      type: String,
      default: undefined
    },

    foreign: Boolean
  },

  data() {
    return {
      items: []
    }
  },

  mounted() {
    this.getRequisites()
  },

  methods: {
    cachedFind: Cache.wrapWithCache(async (key, query) => {
      return await Vue.$GRequest.find(services.requisites, { query })
    }),

    async getRequisites() {
      if (this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.resellerUp)) {
        if (this.foreign && !this.OwnerId) {
          return false
        }

        try {
          const { data } = await this.cachedFind(`${services.requisites}:${this.OwnerId}`, { OwnerId: this.OwnerId })
          if (data && Array.isArray(data.data) && data.data.length) {
            this.items = data.data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
