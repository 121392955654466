function renderFileInput(h) {
  return h(
    'input',
    {
      attrs: {
        id: 'csvFile',
        hidden: 'hidden',
        type: 'file'
      },
      ref: 'csvFile',
      on: {
        change: event => {
          this.import(event.target.files)
        }
      }
    }
  )
}

function renderButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'file_upload',
        color: 'success'
      },
      on: {
        click: () => {
          const csvFile = this.$refs.csvFile
          if (csvFile) {
            csvFile.click()
          }
        }
      }
    }
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      renderFileInput.call(this, h),
      renderButton.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
