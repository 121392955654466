import { components, inputModes, services } from '@/utils'

export default function(h) {
  return h(
    components.select,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${services.notifications}.labels.${services.settingsSite}.sms.senderUserId`),
        service: services.users,
        query: { isActive: true },
        itemTitle: 'username',
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        autocomplete: true,
        details: false,
        loading: this.loading,
        disabled: this.loading
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
