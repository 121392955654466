import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    validation() {
      if (!this.proxy.title) {
        return false
      }

      if (Array.isArray(this.proxy.MessageRules)) {
        if (this.proxy.MessageRules.length === 0) {
          return false
        }
        if (this.proxy.MessageRules.some(({ id }) => id === undefined)) {
          return false
        }
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
