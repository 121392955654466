export const ROOT_ID = '3e194b3e-a2ee-40ce-a986-17fdc918541e'
export const projectName = 'sigma'

export const states = {
  empty: 'empty',
  ready: 'ready',
  loading: 'loading',
  error: 'error',
  success: 'success'
}
