function renderDivider(h) {
  return h(
    'g-divider',
    {
      props: {
        border: { style: this.borderStyle }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fjcc faic'
    },
    [
      renderDivider.call(this, h),

      h(
        'div',
        {
          class: `text-overflow fz-${this.size} text--${this.color} px-2`,
          domProps: { innerHTML: this.value }
        }
      ),

      renderDivider.call(this, h)
    ]
  )
}
