import { invoiceStatues } from '@/components/services/invoices/utils'

import moderation from '@/components/services/invoices/item/buttons/moderation'

export default function(h) {
  if (this.hasPermission()) {
    return h(
      'div',
      {
        class: 'faic grid-gap--8'
      },
      [ invoiceStatues.paid, invoiceStatues.rejected ].map(status => {
        return h(
          moderation,
          {
            props: {
              status,
              value: this.value,
              view: 'form'
            }
          }
        )
      })
    )
  }
}
