import render from './render'

export default {
  name: 'Panel',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    pathToTranslate() {
      let result = 'isActive'
      if (this.value.isBlocked && this.value.isActive) {
        result = 'isBlocked'
      }

      return result
    }
  },

  render
}
