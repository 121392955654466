import proxy from '@sigma-legacy-libs/g-proxy'

import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'
import { NOTIFICATIONS_TEMPLATES, NOTIFICATIONS_TEMPLATES_MANAGER } from '@sigma-legacy-libs/essentials/lib/schemas/notifications'

import { notificationsSettings } from '@/utils/mixins/notifications'

import render from './render'

export default {
  name: 'NotificationsSettingsSite',

  mixins: [
    proxy({ type: 'array' }),
    notificationsSettings()
  ],

  data() {
    return {
      templates: {
        users: {},
        managers: {}
      },

      dialog: {
        value: undefined,
        path: undefined,
        show: false
      }
    }
  },

  mounted() {
    this.createTemplates()
  },

  methods: {
    createTemplates() {
      NOTIFICATIONS_AVAILABLE_TYPES.map(type => {
        this.templates.users[type] = []
        this.templates.managers[type] = []
      })

      for (const type of NOTIFICATIONS_AVAILABLE_TYPES) {
        this.templates.users[type] = Object.keys(NOTIFICATIONS_TEMPLATES).filter(template => ~template.split('.').indexOf(type))
        this.templates.managers[type] = Object.keys(NOTIFICATIONS_TEMPLATES_MANAGER).filter(template => ~template.split('.').indexOf(type))
      }
    }
  },

  render
}
