function minOrHour(time) {
  return (time - time % 60) / 60
}

function substituteZero(time) {
  return (time % 60 > 9 ? ':' : ':0') + time % 60
}

export const timeConverter = (ms, millisec = false, hours = false) => {
  ms = parseFloat(ms)

  if (typeof ms === 'number' && !isNaN(ms)) {
    const msec = ms % 1000
    const sec = (ms - msec) / 1000
    let min = minOrHour(sec)
    const hour = minOrHour(min)

    let result = ''

    if (hours) {
      min = substituteZero(min)
    }

    result = (hours ? hour : '') + min + substituteZero(sec) + (millisec ? `.${msec}` : '')

    return result
  }
}
