import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'NotificationIcon',

  props: {
    value: {
      type: String,
      default: NOTIFICATIONS_AVAILABLE_TYPES.email,
      validator: value => {
        return ~[ ...NOTIFICATIONS_AVAILABLE_TYPES ].indexOf(value)
      }
    },

    size: {
      type: Number,
      default: 22
    }
  },

  render
}
