import { sudoData } from '@/utils'

import render from './render'

export default {
  name: 'Item',

  mixins: [ sudoData ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    mode: {
      type: String,
      default: 'table',
      validator: value => {
        return !!~[ 'table', 'panels', 'list' ].indexOf(value)
      }
    }
  },

  render
}
