import { cloneDeep, isEmpty } from 'lodash'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { TEMPLATES_FILTER, fillDependence, generateServices, sendingsOutputFilter, services } from '@/utils'

import render from './render'

export default {
  name: 'TemplatesTemplate',

  mixins: [
    generateServices({
      name: services.templates,

      async inputFilter(data) {
        if (!data.Owner) {
          await fillDependence.call(this, data, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        data.OwnerId = data.Owner && data.Owner.id || data.OwnerId

        return data
      },

      outputFilter: sendingsOutputFilter,

      find: {
        defaultFilter: cloneDeep(TEMPLATES_FILTER),
        defaultPagination: { limit: 10 }
      },
      get: false,
      update: false,
      create: false,
      remove: false
    })
  ],

  data() {
    return {
      filter: cloneDeep(TEMPLATES_FILTER)
    }
  },

  watch: {
    filter: {
      handler() {
        this.restData[services.templates].find.filter = cloneDeep(this.filter)
      },
      deep: true
    }
  },

  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.restData[services.templates].find.filter.id = this.$route.params.id
    } else {
      this.rest[services.templates].find()
    }
  },

  render
}
