import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      passwordVisibility: true
    }
  },

  render
}
