import { components } from '@/utils'

function renderOverlay(h) {
  return h(
    'div',
    {
      class: `${components.dialog}__overlay`,
      on: {
        click: () => {
          if (this.closeOnClick) {
            this.hide()
          }
        }
      }
    }
  )
}

function renderCloseButton(h) {
  if (this.close) {
    return h(
      'g-button',
      {
        class: `${components.dialog}__close-button`,
        props: {
          flat: true,
          icon: 'clear',
          color: 'white'
        },
        on: {
          click: () => {
            this.toggle()
          }
        }
      }
    )
  }
}

function renderHandle(h) {
  if (this.isMobileView) {
    return h(
      'div',
      {
        class: `${components.dialog}__handle`,
        on: {
          touchstart: event => {
            this.touchStart(event)
          },
          touchend: () => {
            this.touchEnd()
          }
        }
      }
    )
  }
}

function renderTitle(h) {
  if (this.title) {
    return h(
      'div',
      {
        class: `${components.dialog}__title`
      },
      [ this.title ]
    )
  }
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${components.dialog}__header`
    },
    [ this.$slots.header || renderTitle.call(this, h) ]
  )
}

function renderBody(h) {
  return h(
    'div',
    {
      class: `${components.dialog}__body`,
      style: this.bodyStyle,
      ref: 'body'
    },
    [ this.$slots.default ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: `${components.dialog}__footer`
    },
    [ this.$slots.footer ]
  )
}

function renderContent(h) {
  if (this.proxy) {
    return h(
      'div',
      {
        class: this.$class,
        style: { 'z-index': this.zIndex }
      },
      [
        h(
          'div',
          {
            class: `${components.dialog}__holder`,
            style: this.holderStyle,
            ref: 'holder'
          },
          [
            renderCloseButton.call(this, h),
            renderHandle.call(this, h),
            renderHeader.call(this, h),
            renderBody.call(this, h),
            renderFooter.call(this, h)
          ]
        ),

        renderOverlay.call(this, h)
      ]
    )
  }
}

export default function(h) {
  return h(
    'transition',
    {
      props: { name: `show-dialog${this.isMobileView ? '--mobile' : ''}` }
    },
    [ renderContent.call(this, h) ]
  )
}
