import { getUrlToStorage } from '@/utils'

import FileItemPlayer from '@/components/file/item/player'

function renderContentByType(h) {
  switch (this.fileType.icon) {
    case 'jpg': {
      return h(
        'div',
        {
          class: 'file__type-image',
          style: `background-image: url(${getUrlToStorage([ this.OwnerId, this.value.id ])})`
        }
      )
    }
    case 'wave': {
      return h(FileItemPlayer, { props: { value: this.value } })
    }
    default: {
      return h(
        'g-icon',
        {
          class: 'file__type-icon',
          props: {
            library: 'icofont',
            value: `file-${this.fileType.icon}`,
            color: this.fileType.color
          }
        }
      )
    }
  }
}

function renderDownloadButton(h) {
  if (this.fileType.icon !== 'wave' && !this.readonly) {
    return h(
      'g-button',
      {
        class: 'file__type-download',
        props: {
          icon: 'file_download',
          flat: true,
          depressed: true,
          color: 'primary'
        },
        on: {
          click: () => {
            window.location.href = getUrlToStorage([ this.OwnerId, this.value.id ])
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        file__type: true,
        'file__type--small': this.small,
        'file__type--readonly': this.readonly
      }
    },
    [
      renderContentByType.call(this, h),
      renderDownloadButton.call(this, h)
    ]
  )
}
