import Vue from 'vue'

import cloneButton from '@/components/services/inactiveProviders/buttons/clone'

export default function(h) {
  return h(
    cloneButton,
    {
      props: { provider: this.value },
      on: {
        clone: id => {
          Vue.router.push({
            name: 'providers.single',
            params: { id }
          })
        }
      }
    }
  )
}
