import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'MessageTypeIcon',

  props: {
    value: {
      type: String,
      default: SENDING_TYPES.sms,
      validator: value => {
        return ~[ 'accrual', 'subtract', ...SENDING_TYPES, 'apisender', 'hlr' ].indexOf(value)
      }
    },

    size: {
      type: Number,
      default: 24
    },

    color: String,
    translate: String
  },

  computed: {
    parsedType() {
      switch (this.value) {
        case 'smsping': return 'sms'
        case 'apisender': return 'whatsapp'
        case 'whatsappchat':
        case 'viberchat':
        case 'vkchat':
        case 'telegramchat':
        case 'facebookchat': return 'chat'
        default: return this.value
      }
    },

    parsedTranslate() {
      if (this.translate) {
        return this.translate
      }

      switch (this.value) {
        case 'apisender':
        case 'hlr': return this.getTranslate(`tariffs.sources.${this.value}`)
        default: return this.getTranslate(`sendings.types.${this.value}`)
      }
    }
  },

  render
}
