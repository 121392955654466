export default function(h) {
  return h(
    'nav',
    {
      attrs: { id: 'navigation' },
      class: this.$class,
      style: this.$style
    },
    [ this.$slots.default ]
  )
}
