import services from './services'

export default {
  title: 'Events history',

  labels: {
    eventsHistory: 'History of changes',
    type: 'Type',
    userId: 'User',
    entityId: 'Entity',
    service: 'Service',
    oldValues: 'Was',
    newValues: 'Became',
    author: 'Author',
    changesType: 'Type',
    created: 'Created',
    compareId: 'ID of entity'
  },

  updatedValues: {

    ...services,

    data: 'Data',
    isActive: 'Activate/Deactivate',
    updatedAt: 'Updated at',
    title: 'Title',
    serverId: 'Server',
    credentialsId: 'Credentials',
    comment: 'Comment',
    properties: 'Properties',
    replacements: 'Replacements of content',
    createdAt: 'Created at',
    deletedAt: 'Deleted at',

    type: 'Type',
    provider: 'Provider',
    name: 'Name',

    rules: 'Rules',

    description: 'Description',

    source: 'Source',
    ownerId: 'Owner',

    resellerId: 'Reseller',
    password: 'Password',
    referralCode: 'Referral code',
    sudo: 'Sudo',
    username: 'User name',

    amount: 'Amount',
    status: 'Status',
    tariffId: 'Tariff',

    listId: 'ListId',
    date: 'Date',
    email: 'Email',
    firstName: 'Name',
    gender: 'Gender',
    id: 'Id',
    lastName: 'Surname',
    middleName: 'Patronymic',
    phone: 'Phone',

    fallbacks: 'Fallbacks',
    payload: 'Payload',
    recipient: 'Recipient',

    meta: 'Meta',
    moderation: 'Moderation',

    conditions: 'Conditions',
    trafficType: 'Traffic type'
  },

  types: {
    created: 'Created',
    updated: 'Updated',
    removed: 'Removed'
  },

  services: {
    contacts: 'Contacts',
    billingsUsers: 'Financial transaction'
  },

  tooltips: {
    historyButton: 'View change history'
  }
}
