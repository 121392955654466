import { colorsHEX, components, isEmpty, isHEX } from '@/utils'

function renderPreLoader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: `${components.chip}__loading`
      },
      [
        h(
          'g-progress',
          {
            props: {
              indeterminate: true,
              width: this.small ? 1 : 2,
              size: this.$size
            }
          }
        )
      ]
    )
  }
}

function renderIcon(h, icon) {
  if (icon) {
    return h(
      'g-icon',
      {
        class: `${components.chip}__icon`,
        props: {
          value: icon,
          size: this.$size
        }
      }
    )
  }
}

function renderIconHolder(h, content) {
  if (content) {
    return h(
      'div',
      {
        class: `${components.chip}__holder`
      },
      [ content ]
    )
  }
}

function renderCancelable(h) {
  if (this.cancelable) {
    return renderIconHolder.call(
      this,
      h,
      h(
        'g-icon',
        {
          props: {
            value: this.cancelIcon,
            size: this.$size,
            color: this.color ? 'white' : undefined
          },
          on: {
            click: () => {
              if (typeof this.cancelCallback === 'function') {
                this.cancelCallback()
              }
            }
          }
        }
      )
    )
  }
}

function renderContent(h) {
  let content
  if (this.$slots.default) {
    content = this.$slots.default
  } else if (
    isEmpty(this.label) ||
    typeof this.label !== 'string' &&
    typeof this.label !== 'number' &&
    typeof this.label !== 'boolean'
  ) {
    content = ''
  } else {
    content = this.label.toString()
    if (this.cut && content.length > this.length) {
      content = `${content.substring(0, this.length)}...`
    }
  }

  if (content) {
    return h(
      'div',
      {
        class: `${components.chip}__content`
      },
      [ content ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: this.$class,
      style: { color: isHEX(this.color) ? this.color : colorsHEX[this.color] },
      on: this.$eventHandlers
    },
    [
      renderIconHolder.call(this, h, renderIcon.call(this, h, this.icon || this.prefix)),
      renderContent.call(this, h, this.icon),
      renderIconHolder.call(this, h, renderIcon.call(this, h, this.suffix)),
      renderCancelable.call(this, h),
      renderPreLoader.call(this, h)
    ]
  )
}
