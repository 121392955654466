export default {
  key: 'Key',
  value: 'Value',
  all: 'All',
  from: 'From',
  by: 'By',
  at: 'at',
  in: 'in',
  if: 'If',
  to: 'To',
  of: 'of',
  and: 'And',
  or: 'Or',
  yet: 'Yet',
  new: 'New',
  isActive: 'Enabled',
  search: 'Search',
  loadMore: 'Load more',
  pay: 'Pay',
  payment: 'Payment',
  contacts: 'Contacts',
  statistic: 'Statistic',
  refreshSite: 'New version available! Refresh page to apply updates.',
  createdAt: 'Created at',
  updatedAt: 'Updated at',
  total: 'Total',
  true: 'Yes',
  false: 'No',
  empty: '',
  verified: 'Verified',
  advanced: 'Advanced',
  owner: 'Owner',
  symbols: 'Symbols',
  unavailable: 'Temporarily unavailable',
  clear: 'Clear',
  copied: 'Copied',
  nothingFound: 'Nothing found',
  sentToday: 'Sent today',
  filteredBy: 'Filtered by {filter}:',
  presets: 'Presets',
  chooseTypes: 'Choose types',
  understand: 'Understand',
  price: 'Price',
  current: 'Current',
  previous: 'Previous',
  sourceData: 'Source Data',
  attention: 'Attention!',
  preview: 'Preview',
  token: 'Token',

  titles: {
    confirm: {
      clone: 'Cloning'
    }
  },

  contents: {
    confirm: {
      clone: 'Are you sure you want to clone this entity?'
    }
  },

  hints: {
    countriesImportCSV: 'Import only supports semicolon delimited CSV files.',
    formNotFound: 'Form component not found.',
    componentNotFound: 'Component not found.',
    from0To24: 'From 0 to 24',

    need: {
      save: 'Need to save changes.'
    }
  },

  theme: {
    title: 'Theme',
    light: 'Light theme',
    dark: 'Dark theme'
  },

  subheaders: {
    general: 'General',
    administrative: 'Administrative'
  },

  no: {
    data: {
      text: 'No data available'
    }
  },

  time: {
    hours: 'hours',
    minutes: 'minutes',
    seconds: 'seconds'
  },

  date: {
    from: 'from',
    by: 'by',
    creation: 'Date of creation',
    update: 'Date of update',
    days: 'days'
  },

  locales: {
    en: 'English',
    ru: 'Русский'
  },

  platforms: {
    windows: 'Windows',
    macintosh: 'Macintosh',
    linux: 'Linux',
    android: 'Android'
  },

  confirm: {
    remove: 'Confirm remove',
    send: 'Confirm send',
    scheduled: 'Confirm scheduled'
  },

  pagination: {
    rows: 'Rows',
    perPage: 'Rows per page:',
    of: 'of'
  },

  buttons: {
    cancel: 'Cancel',
    apply: 'Apply',
    remove: 'Remove',
    create: 'Create',
    edit: 'Edit',
    copy: 'Copy',
    update: 'Update',
    retry: 'Retry',
    upload: 'Upload',
    uploadImage: 'Upload image',
    continue: 'Continue',
    add: 'Add',
    save: 'Save',
    download: 'Download',
    done: 'Done',
    approve: 'Approve',
    reject: 'Reject',
    signin: 'Sign in',
    signup: 'Sign up',
    login: 'Login',
    registration: 'Registration',
    back: 'Back',
    send: 'Send',
    ok: 'Ok',
    restoring: 'Restoring',
    customize: 'Customize',
    addFunds: 'Add funds',
    close: 'Close',
    show: 'Show',
    scheduled: 'Scheduled',
    import: 'Import',
    fallback: 'Fallback',
    refresh: 'Refresh',
    export: 'Export',
    filter: 'Filter',
    look: 'Look',
    bill: 'Bill',
    passwordEdit: 'Edit password',
    match: 'Match',
    goto: {
      files: 'Go to files',
      requestAndFiles: 'Request and go to files'
    },
    reports: {
      request: 'Report an report',
      get: 'Get report'
    },
    statistic: 'Statistic',
    clear: 'Clear',
    pay: 'Pay',
    support: 'Support',
    makeRequest: 'Apply filter',
    rename: 'Rename',
    exports: {
      pdf: 'Export to PDF'
    },
    choose: 'Choose',
    source: 'Source data',
    properties: 'Properties',
    accept: 'Accept',
    report: 'Report an issue',
    yes: 'Yes',
    no: 'No',
    confirm: 'Confirm',
    understand: 'Understand',
    rules: 'Rules',
    unlock: 'Unlock',
    logout: 'Logout',
    clone: 'Clone',
    notifications: 'Notifications',
    attach: 'Attach',
    detach: 'Detach',
    generate: 'Generate',
    documentation: 'Documentation'
  },

  moderation: {
    pending: 'Pending',
    approved: 'Approved',
    rejected: 'Rejected'
  },

  moderationStatus: {
    disabled: 'Disabled',
    requested: 'Requested',
    moderation: 'Moderation',
    approved: 'Approved',
    rejected: 'Rejected'
  },

  encodings: {
    utf8: 'UTF-8',
    win1251: 'WIN-1251',
    win1252: 'WIN-1252',
    maccyrillic: 'MacCyrillic'
  },

  delimiters: {
    comma: 'Comma',
    semicolon: 'Semicolon'
  },

  comparators: {
    regexp: 'Regular expression',
    pattern: 'Pattern',
    lt: 'Lower then',
    lte: 'Lower then or equal',
    gt: 'Greater then',
    gte: 'Greater then or equal',
    eq: 'Equal'
  },

  paths: {
    payload: {
      sender: 'Sender',
      text: 'Text'
    },
    meta: {
      stats: {
        segments: 'Segments',
        characters: 'Characters'
      },
      _recipientData: {
        group: 'Operator group',
        code: 'Operator code'
      }
    }
  },

  genders: {
    male: 'Male',
    female: 'Female'
  },

  sudo: {
    data: {
      title: 'Data only for awesome programmers!'
    }
  },

  measure: {
    time: {
      s: 'S',
      sec: 'Sec',
      second: 'Second',
      seconds: 'Seconds',
      m: 'M',
      min: 'Min',
      minute: 'Minute',
      minutes: 'Minutes',
      h: 'H',
      hour: 'Hour',
      hours: 'Hours',

      from: 'From',
      to: 'To',

      short: {
        ms: 'ms',
        seconds: 's',
        minutes: 'm',
        hours: 'h'
      },

      template: {
        seconds: '0 second | One second | {count} seconds',
        minutes: '0 minute | One minute | {count} minutes',
        hours: '0 hour | One hour | {count} hours',
        days: '0 day | One day | {count} days'
      }
    }
  },

  links: {
    documentation: 'Documentation',
    platform: 'Platform',
    api: 'API',
    instruction: 'Instruction',
    support: 'Contacts',
    to: {
      recurrentPayments: 'Link to recurrent payments'
    }
  },

  fileTypes: {
    documents: 'Documents',
    tables: 'Tables',
    images: 'Images',
    audio: 'Audio',
    pdf: 'PDF',
    video: 'Video'
  },

  emptyList: 'Nothing found',

  entity: {
    info: {
      owner: 'Owner',
      reseller: 'Reseller',
      source: 'Source',
      createdAt: 'Created at',
      updatedAt: 'Updated at'
    }
  },

  tooltips: {
    clone: 'Cloning',
    navigationList: {
      sortable: 'Change the order of menu items',
      clear: 'Clear order of menu items'
    },
    favorites: {
      disabled: 'Maximum number of favorites available 4'
    }
  }
}
