import render from './render'

export default {
  name: 'Badge',

  props: {
    value: [ String, Number ],

    top: {
      type: Boolean,
      default: false
    },
    bottom: {
      type: Boolean,
      default: true
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: true
    },

    size: {
      type: Number,
      default: 16
    },

    color: {
      type: String,
      default: 'info'
    },

    offsetIn: {
      type: Boolean,
      default: false
    },
    offsetOut: {
      type: Boolean,
      default: false
    }
  },

  render
}
