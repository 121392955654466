import { mapActions, mapGetters } from 'vuex'

import { AUDIO_STATUS, generateUniqueKey, getUrlToStorage } from '@/utils'

import render from './render'

export default {
  name: 'FileItemPlayer',

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    value: {
      type: [ String, Object ],
      default: () => ({})
    },

    autoplay: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      localPlayerId: generateUniqueKey()
    }
  },

  computed: {
    ...mapGetters({
      src: 'audio/src',
      currentTime: 'audio/currentTime',
      duration: 'audio/duration',
      status: 'audio/status',
      playerId: 'audio/playerId'
    }),

    sourceUrl() {
      switch (typeof this.value) {
        case 'object':
          return getUrlToStorage([ this.value.OwnerId || this.value.Owner && this.value.Owner.id, this.value.id || this.value.fileId ])
        case 'string':
        default:
          return getUrlToStorage([ this.value ])
      }
    },

    isCurrentSource() {
      return this.src === this.sourceUrl
    },
    isCurrentPlayedSource() {
      return this.isCurrentSource && this.playerId === this.localPlayerId
    },

    sourceDuration() {
      if (this.isCurrentPlayedSource && this.value && this.duration) {
        return Math.round(this.duration * 1000)
      }
    },
    sourceCurrentTime() {
      if (this.isCurrentPlayedSource && this.currentTime && this.sourceDuration) {
        return this.sourceDuration - this.currentTime * 1000
      }
    },
    sourceProgress() {
      if (this.isCurrentPlayedSource && this.currentTime && this.sourceDuration) {
        return Math.round(this.currentTime * 1000 / this.sourceDuration * 100)
      }
    },

    playing() {
      return this.isCurrentPlayedSource && this.status === AUDIO_STATUS.play
    },
    loading() {
      return this.isCurrentPlayedSource && this.status === AUDIO_STATUS.loading
    }
  },

  mounted() {
    if (this.autoplay && !this.disabled && !this.isCurrentSource) {
      this.setPlayerId(this.localPlayerId)
      this.setSrc(this.sourceUrl)
    }
  },

  methods: {
    ...mapActions({
      setSrc: 'audio/setSrc',
      setPlayerId: 'audio/setPlayerId'
    })
  },

  render
}
