import { get } from 'lodash'

import { colors, inputModes, negativeNumberRegex } from '@/utils'
import { actions, serviceName } from '@/components/services/billings/utils'

import ExpandArrow from '@/components/misc/ExpandArrow'
import OwnerSelect from '@/components/misc/OwnerSelect'
import switcher from '@/components/switcher'
import info from '@/components/misc/info'

function renderAmount(h) {
  const currency = this.currency || this.globalSettings.billings.currency

  return h(
    'div',
    {
      class: 'grid-gap--16 faifs'
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.amount,
            label: this.getTranslate(`${serviceName}.labels.amount`),
            suffix: currency ? this.getTranslate(`currency.${currency}.symbol.unicode`) : null,
            mode: inputModes['line-label'],
            autofocus: true,
            rounded: true,
            clearable: true,
            required: true,
            dense: true,
            details: false,
            mask: negativeNumberRegex,
            processValue: value => value?.toString().replace(',', '.')
          },
          on: {
            input: event => {
              this.proxy.amount = event
            }
          }
        }
      ),

      h(
        switcher,
        {
          props: {
            value: this.action,
            trueValue: actions.addFunds,
            falseValue: actions.charge,
            tooltip: this.getTranslate(`${serviceName}.tooltips.actions`),
            icons: {
              on: 'add',
              off: 'remove'
            }
          },
          on: {
            input: event => {
              this.action = event
            }
          }
        }
      )
    ]
  )
}
function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        error: this.errors.owner,
        mode: inputModes['line-label'],
        dense: true,
        details: false,
        rounded: true,
        itemsDisabled: !this.checkPermissions('advanced.billings.generate') ? [ this.account.id ] : [],
        required: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}
function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${serviceName}.hints.amount`),
        color: colors.primary,
        icon: 'info'
      }
    }
  )
}
function renderContent(h) {
  if (~actions.indexOf(this.action)) {
    return h(
      'div',
      {
        class: 'grid'
      },
      [
        renderAmount.call(this, h),
        renderOwnerSelect.call(this, h),
        renderInfo.call(this, h)
      ]
    )
  }
}

function renderMultiplier(h) {
  return h(
    'div',
    {
      class: 'grid faic',
      style: { gridTemplateColumns: 'auto 200px' }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.data.multiplier,
            label: this.getTranslate(`${serviceName}.labels.multiplier`),
            error: get(this.errors, 'data.multiplier'),
            mode: inputModes['line-label'],
            dense: true,
            details: false,
            rounded: true,
            type: 'number',
            min: 1,
            step: 'any',
            clearable: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy.data.multiplier = event
            }
          }
        }
      ),

      h(
        switcher,
        {
          props: {
            value: this.proxy.data.hidden.noRef,
            labels: {
              on: this.getTranslate(`${serviceName}.labels.ref.${this.proxy.data.hidden.noRef}`),
              off: this.getTranslate(`${serviceName}.labels.ref.${this.proxy.data.hidden.noRef}`)
            }
          },
          on: {
            input: event => {
              this.proxy.data.hidden.noRef = event
            }
          }
        }
      )
    ]
  )
}
function renderComment(h) {
  return h(
    'g-textarea',
    {
      props: {
        value: this.proxy.data.hidden.comment,
        label: this.getTranslate(`${serviceName}.labels.comment`),
        error: get(this.errors, 'data.hidden.comment'),
        mode: inputModes['line-label'],
        dense: true,
        details: false,
        rounded: true,
        grow: true,
        rows: 3,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy.data.hidden.comment = event
        }
      }
    }
  )
}
function renderAdditional(h) {
  return h(
    'g-expansion-panels',
    {
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      h(
        'g-expansion-panel',
        {
          scopedSlots: {
            header: ({ expanded }) => {
              return h(
                'div',
                {
                  class: 'default-expansion-panel-header pr-0'
                },
                [
                  h('div', {}, this.getTranslate('misc.advanced')),
                  h('div', { class: 'ff' }),
                  h(ExpandArrow, { props: { expanded } })
                ]
              )
            },
            default: () => {
              return h(
                'div',
                {
                  class: 'grid pa-3'
                },
                [
                  renderMultiplier.call(this, h),
                  renderComment.call(this, h)
                ]
              )
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3',
      on: {
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [
      renderContent.call(this, h),
      renderAdditional.call(this, h)
    ]
  )
}
