import render from './render'

export default {
  name: 'Chip',

  props: {
    value: String,

    size: {
      type: String,
      default: 'small'
    },
    rounded: {
      type: Boolean,
      default: true
    }
  },

  render
}
