export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.dialog.isShowed,
        maxWidth: 400,
        rounded: true
      },
      on: {
        input: event => {
          this.setIsShowed(event)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        this.getTranslate('commons.titles.confirm.remove')
      ),

      h(
        'div',
        {
          class: 'body-1 pa-3'
        },
        this.getTranslate('commons.contents.confirm.remove')
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.hide()
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                color: 'error',
                label: this.getTranslate('misc.buttons.remove')
              },
              on: {
                click: async () => {
                  await this.dialog.callback()
                  this.hide()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
