import render from './render'

export default {

  name: 'UsersTokensItem',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = [ 'minmax(160px, 400px)' ]
      if (!this.viewport.breakpoint.xs) {
        result.push('minmax(60px, 140px)')
      }
      result.push('1fr')

      return result.join(' ')
    }
  },

  render
}
