import { components } from '@/utils'

import render from './render'

export default {
  name: components['card-actions'],

  props: {
    dense: {
      type: Boolean,
      default: false
    }
  },

  render
}
