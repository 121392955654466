import { full as emoji } from 'markdown-it-emoji'

import MarkdownIt from 'markdown-it'
import MarkdownItAnchor from 'markdown-it-anchor'
import MarkdownItReplaceLink from 'markdown-it-replace-link'
import MarkdownItLinkAttributes from 'markdown-it-link-attributes'

import slugify from 'slugify'

import Prism from 'prismjs'

import 'prismjs/components/prism-http.js'
import 'prismjs/components/prism-json.js'
import 'prismjs/components/prism-markup.js'

import { mapGetters } from 'vuex'

import { backendServerIP, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const name = 'documentation'

function removeClass(selector) {
  const elements = document.querySelectorAll(`.${selector}`)
  if (elements.length > 0) {
    for (const element of elements) {
      if (document.contains(element)) {
        element.classList.remove(selector)
      }
    }
  }
}

const _ = {
  get: require('lodash/get'),
  debounce: require('lodash/debounce'),
  throttle: require('lodash/throttle'),
  isEqual: require('lodash/isEqual')
}

const linkTest = /^#(.+)/

function toSlag(slug) {
  slug = slugify(slug, {
    replacement: '-',
    strict: true,
    lower: true
  })

  return slug
}

export default {
  name: 'Template',

  data() {
    return {
      headings: [],
      slugs: [],

      md: undefined,
      html: undefined,

      scrollTop: 0
    }
  },

  computed: {
    ...mapGetters({ token: 'authentication/token' })
  },

  methods: {
    async render(category, filename) {
      try {
        const pathToFile = [ 'docs', this.locale, category, filename ].join('/')
        const fileDir = pathToFile.replace(/\/[^/]*$/, '')

        this.headings = []
        this.slugs = []

        const response = await fetch(`${backendServerIP}/${pathToFile}.md`, { cache: 'no-cache' })
        if (!response.ok) {
          throw new Error(response.status)
        }
        let text = await response.text()
        text = text.replace(/51\.250\.24\.45/g, window.location.hostname)
        if (window.location.hostname === 'online.sigmasms.ru') {
          text = text.replace(/51\.250\.24\.170/g, 'smpp.sigmasms.ru')
        }
        text = text.replace(/(!\[.*?\]\()(?!https?:)(?:\.\/)?(\w.*\))/gm, `$1${fileDir}/$2`)

        if (this.md) {
          this.html = this.md.render(text)
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    onScroll(scroll) {
      this.scrollTop = _.get(scroll, 'target.scrollingElement.scrollTop', 0)

      let maxMin
      let current

      const doStuff = (element, callback) => {
        callback(element)

        if (element.parentElement) {
          switch (element.parentElement.nodeName.toLowerCase()) {
            case 'ul': {
              if (element.parentElement.parentElement && element.parentElement.parentElement.nodeName.toLowerCase() === 'li') {
                doStuff(element.parentElement.parentElement, callback)
              }
              break
            }
            case 'li': {
              doStuff(element.parentElement, callback)
              break
            }
          }
        }
      }

      const prefix = 'documentation-toc-list-level'
      this.slugs.forEach(slug => {
        const link = document.getElementById(slug)
        if (link) {
          const header = document.getElementById(`${prefix}-${slug}`)
          const offset = link.offsetTop - _.get(scroll, 'target.scrollingElement.scrollTop', 0) - 64 - 16

          if (header && header.classList && header.matches(`.${prefix}--open`)) {
            header.classList.remove(`${prefix}--active`)
            header.classList.remove(`${prefix}--open`)
          }

          if (maxMin === undefined || offset < 0 && offset > maxMin) {
            maxMin = offset
            current = header
          }
        }
      })

      if (current) {
        doStuff(current, element => {
          element.classList.add(`${prefix}--active`)
          element.classList.add(`${prefix}--open`)
        })
      }
    },

    async getDocument(category = 'platform', file = 'instruction') {
      removeClass(`${name}-toc-list-level--active`)
      removeClass(`${name}-toc-list-level--open`)

      category = this.$route.params.category || category
      file = this.$route.params.file || file

      await this.render(category, file)

      const element = document.getElementById(`${name}-category-${category}-file-${file}`)
      if (element) {
        element.classList.add(`${name}-toc-list-level--active`)
        element.classList.add(`${name}-toc-list-level--open`)
      }

      this.scrollTo()
    },

    scrollTo(id) {
      id = this.$route.query.id || id
      const element = document.getElementById(id)
      if (id && element) {
        const toolbar = document.getElementsByClassName('g-toolbar')[0]
        const offset = (toolbar ? toolbar.offsetHeight : 0) + 16
        const bodyTop = document.body.getBoundingClientRect().top
        const elementTop = element.getBoundingClientRect().top
        const elementPosition = elementTop - bodyTop
        window.scrollTo({
          top: elementPosition - offset,
          behavior: 'smooth'
        })
      } else {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    },

    async init() {
      this.md = await new Promise((resolve, reject) => {
        try {
          const md = new MarkdownIt({
            html: true,
            linkify: true,
            typography: true,

            highlight(code, lang) {
              const source = Prism.highlight(code, Prism.languages[lang] || Prism.languages.markup, lang)

              return `<pre class="language-${lang || 'markup'}"><code class="language-${lang || 'markup'}">${source}</code></pre>`
            },

            replaceLink: link => {
              link = decodeURIComponent(link)
              if (linkTest.test(link)) {
                link = `#${this.$route.path}?id=${toSlag(link.replace(linkTest, '$1'))}`
              }

              return link
            }
          })

          md.use(MarkdownItAnchor, {
            level: [ 2, 3, 4, 5, 6 ],
            slugify: toSlag,
            permalink: MarkdownItAnchor.permalink.linkInsideHeader({
              symbol: '#',
              placement: 'before',
              renderHref: slug => `#${this.$route.path}?id=${slug}`,
              space: true,
              class: 'documentation-anchor'
            }),
            callback: (token, info) => {
              this.slugs.push(info.slug)
              this.headings.push({
                level: Number(token.tag.substring(1)),
                slug: info.slug,
                title: info.title
              })
            }
          })
          md.use(MarkdownItReplaceLink)
          md.use(MarkdownItLinkAttributes, {
            pattern: /^https/,
            // attrs: { target: '_blank' } // FIXME: не работает с паттерном
            attrs: { target: '_self' }
          })
          md.use(emoji)

          resolve(md)
        } catch (error) {
          reject(error)
        }
      })
    }
  },

  async mounted() {
    await this.init()
    this.getDocument()

    window.onscroll = _.throttle(this.onScroll, 100)
  },

  watch: {
    $route: {
      async handler(from, to) {
        const id = to.query.id || from.query.id
        if (id) {
          this.scrollTo(id)
        }
        if (from.params.file !== to.params.file) {
          await this.getDocument(to.params.category, to.params.file)
        }
      },
      deep: true
    },
    locale() {
      this.getDocument()
    }
  },

  render
}
