import proxy from '@sigma-legacy-libs/g-proxy'

import { permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'RecurrentPaymentsFilter',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isPage: Boolean
  },

  computed: {
    gridTemplateColumns() {
      const result = []
      if (this.viewport.breakpoint.lgUp) {
        let cols = 3
        if (this.isPage) {
          if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
            cols = 4
          }
        }
        result.push(`repeat(${cols}, minmax(80px, 1fr))`)

        if (this.checkPermissions(`advanced.${services.recurrentPayments}.create`, permissionPresets.meUp)) {
          result.push('38px')
        }
      } else if (this.viewport.breakpoint.smUp) {
        result.push('repeat(2, 1fr)')
      } else {
        result.push('1fr')
      }

      return result.join(' ')
    }
  },

  render
}
