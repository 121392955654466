export const CONTACTS_FILTER = {
  phone: undefined,
  email: undefined,
  lastName: undefined,
  firstName: undefined,
  middleName: undefined,
  date: undefined,
  gender: undefined,
  ListId: undefined,
  isActive: true
}

export default { CONTACTS_FILTER }
