export default {
  name: 'Playground',

  metaInfo: {
    title: 'playground.title'
  },

  data() {
    return {}
  },

  computed: {},

  render(h) {
    return h(
      'div',
      {
        class: 'playground grid'
      },
      []
    )
  }
}
