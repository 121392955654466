import { cloneDeep } from 'lodash'

import { SENDINGS_OUTGOING_FILTER, dateFilter, minMax } from '@/utils'

import render from './render'

export default {
  name: 'SendingsFilter',

  mixins: [ dateFilter, minMax ],

  methods: {
    getDefaultFilter() {
      return cloneDeep(SENDINGS_OUTGOING_FILTER)
    },

    clearFilter() {
      this.proxy = cloneDeep(this._inputFilter(this.getDefaultFilter()))
    }
  },

  render
}
