import render from './render'

export default {
  name: 'ExpandArrow',

  props: {
    expanded: Boolean,

    toggle: {
      type: Function,
      default: () => {}
    },

    size: {
      type: Number,
      default: 48
    }
  },

  render
}
