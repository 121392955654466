import proxy from '@sigma-legacy-libs/g-proxy'

import { DOCUMENT_TEMPLATES_TYPES, REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, permissionPresets, services, toFloatOrUndefined } from '@/utils'
import { hasPermission } from '@/components/services/invoices/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [
    hasPermission,

    proxy({ type: 'object' }),

    generateServices([
      {
        name: services.requisites,

        find: {
          useCache: true,
          defaultFilter: {
            isActive: true,
            type: REQUISITE_TYPES.business,
            OwnerId: undefined
          }
        },
        get: false,
        update: false,
        create: false,
        remove: false,

        disableWatchers: true
      },
      {
        name: services.companies,

        find: {
          useCache: true,
          defaultFilter: {
            vat: undefined,
            $scope: [ 'documentTypes' ],
            OwnerId: undefined
          }
        },
        get: false,
        update: false,
        create: false,
        remove: false,

        disableWatchers: true
      }
    ])
  ],

  props: {
    isNew: Boolean,
    disabled: Boolean,

    OwnerId: String,
    ResellerId: String
  },

  data() {
    return {
      vat: false
    }
  },

  computed: {
    hasInvoiceTemplate() {
      const company = this.restData.companies.find.data.find(item => item.id === this.proxy.companyId)
      if (!company) {
        return false
      }

      const { DocumentTemplatesTypes } = company
      if (Array.isArray(DocumentTemplatesTypes) && DocumentTemplatesTypes.includes(DOCUMENT_TEMPLATES_TYPES.invoice)) {
        return true
      }

      return false
    },

    validation() {
      const propertiesToCheck = [ 'customerId', 'requisiteId', 'companyId', 'amount' ]

      return propertiesToCheck.every(prop => this.proxy[prop]) && this.hasInvoiceTemplate
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    },

    'proxy.customerId'() {
      this.getByCustomerId()
    },

    'proxy.requisiteId'(value) {
      this.proxy.companyId = undefined
      if (value) {
        const requisite = this.restData.requisites.find.data.find(item => item.id === value)
        if (requisite.data.tax.vat !== this.vat) {
          this.vat = requisite.data.tax.vat
        }
        this.getCompanies()
      }
    }
  },

  mounted() {
    if (this.proxy.customerId === undefined) {
      this.proxy.customerId = this.OwnerId || this.account.id
    }
    this.getByCustomerId()
    this.setAmount()
  },

  beforeDestroy() {
    this.proxy.customerId = undefined
    this.proxy.requisiteId = undefined
    this.proxy.companyId = undefined
    this.proxy.amount = undefined
  },

  methods: {
    _outputFilter(data) {
      data.amount = toFloatOrUndefined(data.amount)

      return data
    },

    async getRequisites() {
      this.restData.requisites.find.filter.OwnerId = this.proxy.customerId

      const requisites = await this.rest.requisites.find()
      if (Array.isArray(requisites) && requisites.length) {
        this.proxy.requisiteId = requisites[0].id
        this.vat = requisites[0].data.tax.vat
      } else {
        this.proxy.requisiteId = undefined
        this.vat = undefined
      }
    },

    async getCompanies() {
      this.restData.companies.find.filter.vat = this.vat
      this.restData.companies.find.filter.OwnerId = this.proxy.customerId

      if (this.checkPermissions(`advanced.${services.companies}.get`, permissionPresets.resellerUp)) {
        this.restData.companies.find.filter.OwnerId = this.ResellerId || '$global'
      }

      const companies = await this.rest.companies.find()
      if (Array.isArray(companies) && companies.length) {
        this.proxy.companyId = companies[0].id
      } else {
        this.proxy.companyId = undefined
      }
    },

    getByCustomerId() {
      this.proxy.requisiteId = undefined
      this.proxy.companyId = undefined
      this.getRequisites()
    },

    setAmount() {
      if (!this.proxy.amount) {
        this.proxy.amount = 1000
      }
    }
  },

  render
}

