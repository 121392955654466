export const detachable = {
  mounted() {
    if (document.body) {
      document.body.append(this.$el)
    }
  },

  beforeDestroy() {
    if (this.$el && this.$el.parentNode && typeof this.$el.parentNode.removeChild === 'function') {
      this.$el.parentNode.removeChild(this.$el)
    }
  }
}
