import { components, leadZero } from '@/utils'

import render from './render'

export default {
  name: components.progress,

  props: {
    value: Number,

    type: {
      type: String,
      default: 'circular'
    },

    size: {
      type: Number,
      default: 32
    },

    height: {
      type: Number,
      default: 2
    },
    width: {
      type: Number,
      default: 2
    },

    rotate: {
      type: Number,
      default: 0
    },

    color: {
      type: String,
      default: 'primary'
    },

    indeterminate: {
      type: Boolean,
      default: false
    },
    info: {
      type: Boolean,
      default: false
    },
    counter: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      interval: null
    }
  },

  mounted() {
    if (this.indeterminate && this.counter) {
      this.start()
    }
  },

  beforeDestroy() {
    this.stop()
  },

  methods: {
    start() {
      const counter = document.getElementById('counter')
      let count = 0
      let lastTime = 0

      const updateCounter = currentTime => {
        const deltaTime = currentTime - lastTime
        if (deltaTime >= 100) {
          count += deltaTime / 1000
          if (counter) {
            const minutes = Math.floor(count / 60)
            const seconds = count % 60
            counter.innerText = `${leadZero(minutes.toFixed(0))}:${leadZero(seconds.toFixed(0))}`
          }
          lastTime = currentTime
        }
        requestAnimationFrame(updateCounter)
      }

      this.interval = requestAnimationFrame(updateCounter)
    },

    stop() {
      cancelAnimationFrame(this.interval)
    }
  },

  render
}
