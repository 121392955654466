import { isHEX, normalizedValue, numberToPxOrString } from '@/utils'

const radius = 20

function renderCircle(h) {
  const viewBoxSize = radius / (1 - Number(this.width) / +this.size)
  const circumference = 2 * Math.PI * radius
  const strokeDashArray = Math.round(circumference * 1000) / 1000
  const strokeDashOffset = (100 - normalizedValue(this.value)) / 100 * circumference + 'px'
  const strokeWidth = Number(this.width) / +this.size * viewBoxSize * 2

  return h(
    'svg',
    {
      attrs: {
        xmlns: 'http://www.w3.org/2000/svg',
        viewBox: `${viewBoxSize} ${viewBoxSize} ${2 * viewBoxSize} ${2 * viewBoxSize}`
      },
      style: { transform: `rotate(${Number(this.rotate)}deg)` }
    },
    [
      h(
        'circle',
        {
          attrs: {
            fill: 'transparent',
            cx: 2 * viewBoxSize,
            cy: 2 * viewBoxSize,
            r: radius,
            'stroke-width': strokeWidth,
            'stroke-dasharray': strokeDashArray,
            'stroke-dashoffset': strokeDashOffset
          },
          class: 'g-progress-circular__circle'
        }
      )
    ]
  )
}

function renderBar(h) {
  return h(
    'div',
    {
      class: {
        'g-progress-linear__bar': true,
        'g-progress-linear__bar--determinate': this.value
      },
      style: { width: !this.indeterminate ? `${this.value || 0}%` : null }
    }
  )
}

function renderContent(h) {
  switch (this.type) {
    case 'circular':
      return renderCircle.call(this, h)
    case 'linear':
      return renderBar.call(this, h)
  }
}

function renderInfo(h) {
  if (this.info) {
    return h(
      'div',
      {
        class: 'g-progress__info'
      },
      [ this.$slots.default || this.value ]
    )
  }
}

function renderCounter(h) {
  if (this.counter) {
    return h(
      'div',
      {
        attrs: { id: 'counter' },
        class: 'g-progress__counter'
      },
      '00:00'
    )
  }
}

export default function(h) {
  const style = {
    color: isHEX(this.color) ? this.color : null
  }
  if (this.type === 'circular') {
    style.minHeight = numberToPxOrString(this.size)
    style.height = numberToPxOrString(this.size)
    style.minWidth = numberToPxOrString(this.size)
    style.width = numberToPxOrString(this.size)
  } else {
    style.minHeight = numberToPxOrString(this.height)
    style.height = numberToPxOrString(this.height)
  }

  return h(
    'div',
    {
      attrs: {
        role: 'progressbar',
        'aria-valuemin': 0,
        'aria-valuemax': 100,
        'aria-valuenow': this.indeterminate ? undefined : this.value
      },
      class: {
        'g-progress': true,
        [`g-progress-${this.type}`]: true,
        [`g-progress-${this.type}--indeterminate`]: this.indeterminate,
        [`g-progress--${this.color}`]: !!this.color && !isHEX(this.color)
      },
      style
    },
    [
      renderContent.call(this, h),
      renderInfo.call(this, h),
      renderCounter.call(this, h)
    ]
  )
}
