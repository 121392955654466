import { colors, getPrice } from '@/utils'

import tag from '@/components/tag'
import preloader from '@/components/misc/preloader'

export default function(h) {
  if (this.billingAmount !== undefined) {
    return h(
      tag,
      {
        props: {
          label: getPrice(this.billingAmount),
          color: this.color,
          tooltip: this.tooltip,
          strikethrough: this.refunded
        }
      }
    )
  }

  return h(
    preloader,
    {
      props: {
        value: true,
        color: colors.grey,
        size: 12
      }
    }
  )
}
