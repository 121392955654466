import { permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'Panel',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = []

      if (this.viewport.breakpoint.xs) {
        result.push('1fr')
      } else {
        result.push('minmax(200px, 350px)')
      }

      if (this.viewport.breakpoint.smUp) {
        result.push('300px', '170px', '100px')
      }

      if (this.viewport.breakpoint.lgUp) {
        if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
          result.push('minmax(100px, 160px)')
        }
      }

      return result.join(' ')
    }
  },

  render
}
