import { get, merge } from 'lodash'

export async function findInArrayAndReplaceOrDelete(result, item, callback) {
  let replacement
  let find = item

  if (typeof item !== 'string') {
    replacement = item
    find = get(item, this.idField)
  }

  if (Array.isArray(result) && result.length) {
    const index = result.findIndex(item => get(item, this.idField) === find)
    if (index > -1) {
      if (typeof callback === 'function') {
        replacement = await callback()
      }
      if (replacement !== undefined) {
        merge(result[index], replacement)
      } else {
        result.splice(index, 1)
      }

      return true
    }
  }

  return false
}

export async function defaultWebsocketFindCreatedEvent(instance) {
  if (!this.findData.filterIsEqualToDefault && !this.options.find.alwaysCreateFromWebSocket) {
    return
  }

  if (this.findData.filterIsEqualToDefault || this.options.find.alwaysCreateFromWebSocket) {
    this.findData.pagination.total++
  }

  let workingArray = this.findData.data
  let limit = this.findData.pagination.limit

  if (this.options.find.bucketEnabled) {
    workingArray = this.bucket

    limit = this.options.find.bucketMaxLength
  } else {
    instance = await this.options.inputFilter.call(this.ctx, instance)
  }

  if (workingArray) {
    const index = workingArray.findIndex(item => item.id === instance.id)

    if (!~index) {
      workingArray.unshift(instance)
    }

    if (workingArray.length > limit && !this.options.find.appendMode) {
      workingArray.splice(limit)
    }

    if (workingArray === this.bucket) {
      this.findData.bucketLength = this.bucket.length
    }
  }
}
export async function defaultWebsocketFindUpdatedEvent(instance) {
  if (!this.findData.filterIsEqualToDefault && !this.options.find.alwaysUpdateFromWebSocket) {
    return
  }

  let alreadyProcessed = false

  if (this.options.find.bucketEnabled) {
    alreadyProcessed = await findInArrayAndReplaceOrDelete.call(this, this.bucket, instance)
  }

  if (!alreadyProcessed) {
    await findInArrayAndReplaceOrDelete.call(
      this,
      this.findData.data,
      instance[this.idField],
      () => this.options.inputFilter.call(this.ctx, instance)
    )
  }
}
export async function defaultWebsocketFindRemovedEvent(instance) {
  if (!this.findData.filterIsEqualToDefault && !this.options.find.alwaysRemoveFromWebSocket) {
    return
  }

  if (this.findData.filterIsEqualToDefault || this.options.find.alwaysRemoveFromWebSocket) {
    this.findData.pagination.total--
  }

  let alreadyProcessed = false

  if (this.options.find.bucketEnabled) {
    alreadyProcessed = await findInArrayAndReplaceOrDelete.call(this, this.bucket, instance.id)
  }

  if (!alreadyProcessed) {
    await findInArrayAndReplaceOrDelete.call(this, this.findData.data, instance.id)
  }
}

export const generateDefaultWebsocketFindEvents = function(options) {
  const nameViaPoint = options.name.split('/').join('.')

  return [
    {
      event: `${nameViaPoint}.created`,
      handler: options.find.websocketFindCreatedEvent ? options.find.websocketFindCreatedEvent : defaultWebsocketFindCreatedEvent
    },
    {
      event: `${nameViaPoint}.updated`,
      handler: options.find.websocketFindUpdatedEvent ? options.find.websocketFindUpdatedEvent : defaultWebsocketFindUpdatedEvent
    },
    {
      event: `${nameViaPoint}.updated2`,
      handler: options.find.websocketFindUpdatedEvent ? options.find.websocketFindUpdatedEvent : defaultWebsocketFindUpdatedEvent
    },
    {
      event: `${nameViaPoint}.removed`,
      handler: options.find.websocketFindRemovedEvent ? options.find.websocketFindRemovedEvent : defaultWebsocketFindRemovedEvent
    },
    {
      event: `${nameViaPoint}.removed2`,
      handler: options.find.websocketFindRemovedEvent ? options.find.websocketFindRemovedEvent : defaultWebsocketFindRemovedEvent
    }
  ]
}
