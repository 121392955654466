import { permissionPresets, services } from '@/utils'

import tasksItem from '@/components/services/sendings/tasks/item'

export default function(h) {
  if (this.checkPermissions(`advanced.${services.tasks}.find`, permissionPresets.meUp)) {
    return h(
      'g-card',
      {
        props: {
          rounded: true,
          outline: true
        }
      },
      [
        h(
          'tasks-template',
          {
            scopedSlots: {
              item: ({ item }) => h(tasksItem, { props: { value: item } })
            }
          }
        )
      ]
    )
  }
}
