import { components } from '@/utils'

function renderIcon(h, icon) {
  if (icon) {
    return h(
      'div',
      {
        class: `${components['tabs-header']}__icon`
      },
      [ h('g-icon', { props: { value: icon } }) ]
    )
  }
}

function renderArrow(h, direction = 'prev') {
  if (this.showArrows) {
    return h(
      'div',
      {
        class: {
          [`${components['tabs-header']}__arrow ${components['tabs-header']}__arrow--${direction}`]: true,
          [`${components['tabs-header']}__arrow--disabled`]: this[`${direction}Disabled`]
        },
        on: {
          click: () => {
            this.slide(direction)
          }
        }
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: direction === 'prev' ? 'keyboard_arrow_left' : 'keyboard_arrow_right'
            }
          }
        )
      ]
    )
  }
}

function renderIndicator(h) {
  return h(
    'div',
    {
      class: `${components['tabs-header']}__indicator`,
      ref: 'indicator'
    }
  )
}

function renderItem(h, key, title, icon, callback) {
  return h(
    'div',
    {
      class: {
        [`${components['tabs-header']}__item`]: true,
        [`${components['tabs-header']}__item--active`]: this.proxy === key,
        [`${components['tabs-header']}__item--has-icon`]: !!icon
      },
      on: {
        click: event => {
          this.proxy = key
          if (typeof callback === 'function') {
            callback()
          }
          this.indicatorSlide(event.target)
        }
      }
    },
    [ renderIcon.call(this, h, icon), title ]
  )
}

function renderItems(h) {
  return h(
    'div',
    {
      class: `${components['tabs-header']}__items`,
      ref: 'items'
    },
    [
      this.tabs.map(({ key, title, icon, callback }) => {
        switch (this.isLink) {
          case true: {
            return h(
              'router-link',
              {
                class: 'link link--passive',
                props: {
                  to: {
                    name: this.$route.name,
                    query: {
                      currentTab: key
                    }
                  }
                }
              },
              [ renderItem.call(this, h, key, title, icon, callback) ]
            )
          }

          default: {
            return renderItem.call(this, h, key, title, icon, callback)
          }
        }
      }),
      renderIndicator.call(this, h)
    ]
  )
}

export default function(h) {
  if (Array.isArray(this.tabs) && this.tabs.length) {
    return h(
      'div',
      {
        class: {
          [`${components['tabs-header']}`]: true,
          [`${components['tabs-header']}--arrows`]: this.showArrows
        }
      },
      [
        renderArrow.call(this, h, 'prev'),
        h(
          'div',
          {
            class: `${components['tabs-header']}__holder`,
            ref: 'holder',
            on: {
              wheel: this.wheelSlide
            }
          },
          [ renderItems.call(this, h) ]
        ),
        renderArrow.call(this, h, 'next')
      ]
    )
  }
}
