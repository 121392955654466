export const toNumberOrUndefined = (value, convertor = Number) => {
  if (value === undefined || value === null || value === '' || typeof value !== 'string' && typeof value !== 'number') {
    return undefined
  }

  const number = convertor(value)

  return isNaN(number) ? undefined : number
}

export const toIntegerOrUndefined = value => toNumberOrUndefined(value, parseInt)
export const toFloatOrUndefined = value => toNumberOrUndefined(value, parseFloat)
