import Vue from 'vue'

import { cloneDeep, get } from 'lodash'
import { DOCUMENT_TEMPLATES_TYPES, REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { generateServices, permissionPresets, proxy, services, toFloatOrUndefined } from '@/utils'
import { hasPermission } from '@/components/services/invoices/utils'

import render from './render'

export default {
  name: 'GenerateDocumentForm',

  mixins: [
    proxy({ type: 'object' }),
    generateServices([
      {
        name: services.requisites,

        find: {
          useCache: true,
          defaultFilter: {
            isActive: true,
            type: REQUISITE_TYPES.business,
            OwnerId: undefined
          }
        },
        get: false,
        update: false,
        create: false,
        remove: false,

        disableWatchers: true
      },
      {
        name: services.companies,

        find: {
          useCache: true,
          defaultFilter: {
            vat: undefined,
            $scope: [ 'documentTypes' ],
            OwnerId: undefined
          }
        },
        get: false,
        update: false,
        create: false,
        remove: false,

        disableWatchers: true
      }
    ]),
    hasPermission
  ],

  props: {
    allowedTypes: {
      type: Array,
      default: () => []
    },

    disabled: {
      type: Boolean,
      default: false
    },

    OwnerId: String,
    ResellerId: String
  },

  data() {
    return {
      customer: undefined,
      customerId: this.OwnerId,
      vat: false,

      firstProxy: cloneDeep(this.value)
    }
  },

  computed: {
    $types() {
      const company = this.restData.companies.find.data.find(item => item.id === this.proxy.companyId)
      if (company) {
        return DOCUMENT_TEMPLATES_TYPES.reduce((result, type) => {
          if (
            Array.isArray(company.DocumentTemplatesTypes) &&
            company.DocumentTemplatesTypes.length &&
            ~company.DocumentTemplatesTypes.indexOf(type) &&
            (!this.allowedTypes.length || ~this.allowedTypes.indexOf(type))
          ) {
            result.push(type)
          }

          return result
        }, [])
      }
    },

    $hasTemplate() {
      if (Array.isArray(this.$types) && this.$types.length) {
        return true
      }

      return false
    },

    validation() {
      if (!this.proxy.requisiteId) {
        return false
      }
      if (!this.proxy.companyId) {
        return false
      }
      if (!this.$hasTemplate) {
        return false
      }

      const amount = get(this.proxy, 'data.SERVICE_PRICE', 0)
      if (!amount) {
        return false
      }

      return true
    },

    proxyIsEmpty() {
      if (!this.firstProxy) {
        return true
      }

      return !this.firstProxy.requisiteId && !this.firstProxy.companyId
    },

    currency() {
      return get(this.customer?.settings, 'billings.currency', this.globalSettings.billings.currency)
    }
  },

  watch: {
    $types() {
      if (this.$types && this.$types.length) {
        this.proxy.type = this.$types[0]
      }
    },

    validation() {
      this.$emit('validation', this.validation)
    },

    'proxy.requisiteId'(value) {
      if (this.proxyIsEmpty) {
        this.proxy.companyId = undefined
        this.proxy.type = undefined
        if (value) {
          const requisite = this.restData.requisites.find.data.find(item => item.id === value)
          if (requisite.data.tax.vat !== this.vat) {
            this.vat = requisite.data.tax.vat
          }
          this.getCompanies()
        }
      }
    },

    customerId(value) {
      if (value) {
        this.getByCustomerId()
      }
    }
  },

  mounted() {
    if (this.customerId === undefined) {
      this.customerId = this.OwnerId || this.account.id
    }
    this.getByCustomerId()
    this.setAmount()
    if (this.$types && this.$types.length) {
      this.proxy.type = this.$types[0]
    }
  },

  beforeDestroy() {
    this.customerId = undefined
    this.proxy.requisiteId = undefined
    this.proxy.companyId = undefined
    this.proxy.type = undefined
    this.setAmount()
  },

  methods: {
    _outputFilter(data) {
      if (data.type === DOCUMENT_TEMPLATES_TYPES.invoice) {
        data.amount = toFloatOrUndefined(data.amount)
      }

      return data
    },

    async getCustomer() {
      if (this.customerId) {
        const { data } = await Vue.$GRequest.get(services.users, this.customerId, { query: { $scope: [ 'full' ] } })
        if (data) {
          this.customer = data
        }
      }
    },
    async getRequisites() {
      this.restData.requisites.find.filter.OwnerId = this.customerId

      const requisites = await this.rest.requisites.find()
      if (Array.isArray(requisites) && requisites.length) {
        if (this.proxyIsEmpty) {
          this.proxy.requisiteId = requisites[0].id
        }
        this.vat = requisites[0].data.tax.vat
      } else {
        this.proxy.requisiteId = undefined
        this.vat = undefined
      }
    },
    async getCompanies() {
      this.restData.companies.find.filter.vat = this.vat
      this.restData.companies.find.filter.OwnerId = this.customerId

      if (this.checkPermissions(`advanced.${services.companies}.get`, permissionPresets.resellerUp)) {
        this.restData.companies.find.filter.OwnerId = this.ResellerId || '$global'
      }

      const companies = await this.rest.companies.find()
      if (Array.isArray(companies) && companies.length) {
        if (this.proxyIsEmpty) {
          this.proxy.companyId = companies[0].id
        }
      } else {
        this.proxy.companyId = undefined
      }
    },

    getByCustomerId() {
      this.getCustomer()
      if (this.proxyIsEmpty) {
        this.proxy.requisiteId = undefined
        this.proxy.companyId = undefined
        this.getRequisites()
      } else {
        this.getRequisites()
        this.getCompanies()
      }
    },

    setAmount(value) {
      if (!this.proxy.data) {
        this.proxy.data = {}
      }
      if (!this.proxy.data.SERVICE_PRICE) {
        this.proxy.data.SERVICE_PRICE = value || 1000
      }
    }
  },

  render
}

