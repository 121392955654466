import render from './render'

export default {
  name: 'OneRowString',

  props: {
    value: String,

    length: {
      type: Number,
      default: 50
    },

    direction: {
      type: String,
      default: 'top'
    }
  },

  render
}
