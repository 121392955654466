import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'DefaultRule',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    source: String,
    isMultiple: Boolean
  },

  render
}
