import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/providers/utils'

import render from './render'

export default {
  name: 'Remove',

  mixins: [
    generateServices({
      name: serviceName,

      find: false,
      get: false,
      create: false,
      update: false,

      remove: {
        manipulateData: false,
        redirect: serviceName
      }
    })
  ],

  props: {
    id: String
  },

  data() {
    return {
      showDialog: false
    }
  },

  methods: {
    async remove() {
      if (this.checkPermissions(`advanced.${serviceName}.remove`)) {
        try {
          const result = await this.rest[serviceName].remove(this.id)
          if (result) {
            this.showDialog = false
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
