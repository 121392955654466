export default function(h) {
  return h(
    'form',
    {
      attrs: this.$attrs,
      style: this.$style,
      on: {
        input: event => {
          this.$emit('input', event)
        },
        change: event => {
          this.$emit('change', event)
        },
        invalid: event => {
          this.$emit('invalid', event)
        },
        submit: event => {
          event.preventDefault()
          this.$emit('submit', event)
        }
      },
      ref: 'form'
    },
    [ this.$slots.default ]
  )
}
