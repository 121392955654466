import OneRowString from '@/components/misc/OneRowString'

function renderSlot(slot) {
  if (this.$scopedSlots[slot]) {
    return this.$scopedSlots[slot]()
  }

  return this.$slots[slot]
}
function renderContent(h) {
  const result = []
  for (const slot of [ 'first', 'second' ]) {
    if (this[slot]) {
      result.push(
        h(
          OneRowString,
          {
            class: slot === 'second' ? 'text--grey' : undefined,
            props: {
              value: this[slot],
              length: this.length
            }
          }
        )
      )
    } else {
      result.push(h(OneRowString, {}, [ renderSlot.call(this, slot) ]))
    }
  }
  if (Array.isArray(result) && result.length) {
    return result
  } else {
    return [ this.$slots.default ]
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [ renderContent.call(this, h) ]
  )
}
