export default {
  title: 'Moderation',

  labels: {
    task: 'Moderation task',
    pattern: 'Message text and template',
    defaultRoute: 'Default route',
    sender: 'Sender name',
    text: 'Text',
    moderation: 'Moderation',
    status: 'Status',
    id: 'Id',
    providerId: 'Provider',
    OwnerId: 'Owner',
    recipientsCount: 'Recipients / segments',
    updatedAt: 'Updated at',
    createdAt: 'Created at',
    operator: 'Operator',
    tasks: {
      status: {
        init: 'Initialized',
        processing: 'Processing',
        success: 'Success',
        error: 'Error'
      },
      moderation: {
        rejected: 'Rejected',
        approved: 'Approved'
      }
    },
    sendings: {
      sendingId: 'Id'
    },
    sendingsList: 'Sendings list'
  },

  tabs: {
    sendings: 'Sendings',
    tasks: 'Tasks'
  },

  actions: {
    approved: 'Approve',
    rejected: 'Reject',
    rejectedRefound: 'Reject and return the money',
    massive: {
      approved: 'Approve all',
      rejected: 'Reject all',
      rejectedRefound: 'Reject all and return the money'
    }
  },

  tooltips: {
    info: 'In the default route field, you can select a specific provider to send a message to. If you leave the field blank, the route for the message will be selected on the server side as usual, using the settings of a specific user.',
    buttons: {
      openInNew: 'Open in new tab',
      counter: 'Count of messages on moderation',
      refresh: 'Refresh'
    },
    recipientsCount: 'Recipients count / segments',
    tasks: {
      status: {
        init: 'Initialized',
        processing: 'Processing',
        success: 'Success',
        error: 'Error'
      },
      moderation: {
        rejected: 'Rejected',
        approved: 'Approved'
      }
    },
    createdAt: {
      min: 'First message time',
      max: 'Last message time'
    }
  },

  hints: {
    sendings: {
      info: 'The message list below might be empty for some time.'
    },
    provider: 'By route'
  }
}
