export const eventHandlers = {
  computed: {
    $eventHandlers() {
      const events = this.$options._parentListeners || {}
      const keys = Object.keys(events)

      if (keys.length) {
        return keys.reduce((acc, key) => {
          acc[key] = this._eventHandler

          return acc
        }, {})
      }

      return {
        click: this._eventHandler,
        focus: this._eventHandler,
        blur: this._eventHandler,
        mousedown: this._eventHandler,
        mouseup: this._eventHandler,
        keypress: this._eventHandler,
        keydown: this._eventHandler,
        keyup: this._eventHandler
      }
    }
  },

  methods: {
    _eventHandler(event) {
      this.$emit(event.type, event)
    }
  }
}
