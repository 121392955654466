import { components } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      class: {
        [components.subheader]: true,
        [`${components.subheader}--dense`]: this.dense
      }
    },
    [ this.$slots.default || this.value ]
  )
}
