export default {
  title: 'Replacement',

  labels: {
    fallback: 'Fallback'
  },

  hints: {
    replacement: {
      original: 'Original',
      replace: 'Replace',
      final: 'Final'
    },
    fallbacks: 'Fallbacks not specified'
  },

  types: {
    type: 'Type',
    state: {
      moderation: 'Moderation'
    },
    payload: {
      sender: 'Sender',
      text: 'Text'
    },
    fallbacks: 'Fallbacks'
  }
}
