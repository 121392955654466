import { components } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      class: components['text-overflow'],
      directives: this.$directives,
      key: this._uid
    },
    [
      h(
        'div', {
          class: `${components['text-overflow']}-content`,
          domProps: { innerHTML: this.$value },
          ref: 'content'
        }
      )
    ]
  )
}
