import Vue from 'vue'

import { get } from 'lodash'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'Routing',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      routingGroups: undefined
    }
  },

  watch: {
    value: {
      handler() {
        this.getRoutingGroups()
      },
      deep: true
    }
  },

  mounted() {
    this.getRoutingGroups()
  },

  methods: {
    async getRoutingGroups() {
      const OwnerId = get(this.value, 'Owner.id', this.value.OwnerId)
      if (OwnerId) {
        try {
          const { data } = await Vue.$GRequest.get('users', OwnerId, { query: { $scope: [ 'RoutingGroups' ] } })
          if (data && data.RoutingGroups && Array.isArray(data.RoutingGroups) && data.RoutingGroups.length) {
            this.routingGroups = data.RoutingGroups.reduce((result, { id, type, title }) => {
              if (!result[type]) {
                result[type] = []
              }
              result[type].push({
                id,
                type,
                title
              })

              return result
            }, {})
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
