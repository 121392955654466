export default function(h) {
  return h(
    'div',
    {
      class: this.$class,
      directives: this.directives,
      on: this.$eventHandlers,
      domProps: { innerHTML: this.value }
    }
  )
}
