import { isEqual } from 'lodash'

import { buttonModes, colors, components, icons, inputModes, services } from '@/utils'

import button from '@/components/button'

const textFieldProps = {
  mode: inputModes['line-label'],
  dense: true,
  rounded: true,
  clearable: true,
  details: false
}

function renderBody(h) {
  return h(
    'div',
    {
      class: 'grid px-2'
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            components['text-field'],
            {
              props: {
                value: this.value.host,
                label: this.getTranslate(`${services.notifications}.labels.${services.settingsSite}.smtp.host`),
                disabled: this.loading,
                ...textFieldProps
              },
              on: {
                input: event => {
                  this.proxy.host = event
                }
              }
            }
          ),

          h(
            components['text-field'],
            {
              props: {
                value: this.value.port,
                label: this.getTranslate(`${services.notifications}.labels.${services.settingsSite}.smtp.port`),
                type: 'number',
                step: 'any',
                disabled: this.loading,
                ...textFieldProps
              },
              on: {
                input: event => {
                  this.proxy.port = event
                }
              }
            }
          )
        ]
      ),

      h(
        components['text-field'],
        {
          props: {
            value: this.value.from,
            label: this.getTranslate(`${services.notifications}.labels.${services.settingsSite}.smtp.from`),
            ...textFieldProps,
            disabled: this.loading
          },
          on: {
            input: event => {
              this.proxy.from = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: {
            'grid grid-gap--8': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            components['text-field'],
            {
              props: {
                value: this.value.login,
                label: this.getTranslate(`${services.notifications}.labels.${services.settingsSite}.smtp.login`),
                disabled: this.loading,
                ...textFieldProps
              },
              on: {
                input: event => {
                  this.proxy.login = event
                }
              }
            }
          ),
          h(
            components['text-field'],
            {
              props: {
                value: this.value.password,
                label: this.getTranslate(`${services.notifications}.labels.${services.settingsSite}.smtp.password`),
                name: 'smtp-password',
                disabled: this.loading,
                afterIcon: this.passwordVisibility ? 'visibility' : 'visibility_off',
                afterIconCallback: () => {
                  this.passwordVisibility = !this.passwordVisibility
                },
                type: this.passwordVisibility ? 'password' : 'text',
                ...textFieldProps
              },
              on: {
                input: event => {
                  this.proxy.password = event
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        button,
        {
          props: {
            icon: icons.save,
            color: colors.primary,
            mode: buttonModes.flat,
            label: this.getTranslate('misc.buttons.save'),
            loading: this.loading,
            disabled: this.loading || isEqual(this.proxy, this.value)
          },
          on: {
            click: () => {
              this.clickHandler()
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    components.card,
    {
      class: 'ptpx-12',
      props: {
        title: this.getTranslate(`${services.notifications}.subtitles.${services.settingsSite}.smtp`),
        outline: true,
        rounded: true
      }
    },
    [
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
