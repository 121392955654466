import render from './render'

export default {
  name: 'TitleDivider',

  props: {
    value: {
      type: [ String, Number ],
      default: ''
    },

    color: {
      type: String,
      default: 'grey'
    },
    size: {
      type: Number,
      default: 12
    },
    borderStyle: {
      type: String,
      default: 'solid'
    }
  },

  render
}
