import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import DocumentTemplatesSelector from '@/components/services/documentTemplates/selector'

function renderTitleField(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${services.companies}.labels.title`),
        mode: inputModes.outline,
        dense: true,
        rounded: true,
        required: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}
function renderVatSwitch(h) {
  return h(
    components.switch,
    {
      props: {
        value: this.proxy.vat,
        label: this.getTranslate(`${services.companies}.titles.vat`)
      },
      on: {
        input: event => {
          this.proxy.vat = event
        }
      }
    }
  )
}
function renderFirstRow(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '1fr auto' }
    },
    [
      renderTitleField.call(this, h),
      renderVatSwitch.call(this, h)
    ]
  )
}

function renderDocumentButtons(h) {
  if (this.proxy.id) {
    return h(
      'div',
      {
        class: {
          'grid grid-gap--16': true,
          'grid-cols--2': !this.viewport.breakpoint.xs
        }
      },
      DOCUMENT_TEMPLATES_TYPES.map(type => {
        return h(
          DocumentTemplatesSelector,
          {
            props: {
              CompanyId: this.proxy.id,
              type
            }
          }
        )
      })
    )
  }
}

function renderOwnerSelect(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        dense: true,
        required: true,
        clearable: false,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid grid-gap--16 pa-3'
    },
    [
      renderFirstRow.call(this, h),
      renderDocumentButtons.call(this, h),
      renderOwnerSelect.call(this, h)
    ]
  )
}
