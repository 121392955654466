import button from '@/components/button'
import { buttonModes, colors, icons } from '@/utils'
import Vue from 'vue'

function renderButtonSupport(h) {
  if (this.paymentResult === 'fail') {
    return h(
      button,
      {
        props: {
          label: this.getTranslate('misc.buttons.support'),
          mode: buttonModes.flat,
          color: colors.primary
        },
        on: {
          click: () => {
            Vue.router.push({ name: 'support' })
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.show,
        closeOnEsc: false,
        closeOnClick: false,
        maxWidth: 550
      },
      on: {
        input: event => {
          this.show = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic pa-3'
        },
        [
          h(
            'g-icon',
            {
              class: 'mr-3',
              props: {
                value: this.paymentResult === 'success' ? icons.check_circle : icons.error,
                color: this.paymentResult === 'success' ? colors.success : colors.error,
                size: 48
              }
            }
          ),

          h('div', { domProps: { innerHTML: this.paymentResult && this.getTranslate(`payments.contents.payment.result.${this.paymentResult}`) } })
        ]
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          renderButtonSupport.call(this, h),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.show = false
                  Vue.router.push('/')
                }
              }
            }
          )
        ]
      )
    ]
  )
}
