export default {
  namespaced: true,

  state: {
    tab: {
      name: undefined,
      to: {}
    },
    showPaymentDialog: false,
    showCreateRecurrentPaymentDialog: false
  },

  mutations: {
    setCurrentTab(state, tab) {
      state.tab = tab
    },
    setShowPaymentDialog(state, showPaymentDialog) {
      state.showPaymentDialog = showPaymentDialog
    },
    setShowCreateRecurrentPaymentDialog(state, showCreateRecurrentPaymentDialog) {
      state.showCreateRecurrentPaymentDialog = showCreateRecurrentPaymentDialog
    }
  },

  actions: {
    setCurrentTab({ commit }, tab) {
      commit('setCurrentTab', tab)
    },
    setShowPaymentDialog({ commit }, showPaymentDialog) {
      commit('setShowPaymentDialog', showPaymentDialog)
    },
    setShowCreateRecurrentPaymentDialog({ commit }, showCreateRecurrentPaymentDialog) {
      commit('setShowCreateRecurrentPaymentDialog', showCreateRecurrentPaymentDialog)
    }
  },

  getters: {
    tab: state => state.tab,
    showPaymentDialog: state => state.showPaymentDialog,
    showCreateRecurrentPaymentDialog: state => state.showCreateRecurrentPaymentDialog
  }
}
