export default {
  title: 'Списки ключевых слов',

  one: 'Список ключевых слов',
  many: 'Списоки ключевых слов',

  titles: {
    create: 'Создать список ключевых слов'
  },

  labels: {
    title: 'Название',
    search: 'Поиск'
  },

  dialogs: {
    new: 'Новый список ключевых слов'
  },

  hints: {
    notFound: 'Список ключевых слов не найден'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаления {title}?'
    }
  },

  tooltips: {
    isActive: 'Активировать или деактивировать список ключевых слов.',
    favorites: 'Добавить в избранное список ключевых слов',
    active: 'Активен',
    notActive: 'Не активен'
  },

  snackbars: {
    created: 'Список ключевых слов успешно создан.',
    updated: 'Список ключевых слов успешно обновлён.',
    removed: 'Список ключевых слов успешно удалён.'
  }
}
