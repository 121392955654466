import { colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.patterns}.labels.title`),
        icon: this.value.isActive ? icons.check_circle_outline : icons.highlight_remove,
        color: this.value.isActive ? colors.success : colors.error,
        iconTooltip: this.getTranslate(`${services.patterns}.tooltips.isActive.${this.value.isActive}`),
        service: services.patterns
      }
    }
  )
}
