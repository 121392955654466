import { serviceName } from '@/components/services/yclients/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-filter`
    },
    [
      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: 'outline',
            dense: true,
            rounded: true,
            clearable: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.salonId,
            label: this.getTranslate(`${serviceName}.labels.salon`),
            clearable: true,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.salonId = event
            }
          }
        }
      )
    ]
  )
}
