import { dateFilter, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ dateFilter ],

  computed: {
    gridTemplateColumns() {
      const canFindUsers = this.checkPermissions(`advanced.${services.users}.find`, permissionPresets.resellerUp)
      switch (true) {
        case this.viewport.breakpoint.smDown: return '1fr'
        case this.viewport.breakpoint.mdDown: return canFindUsers ? 'repeat(2, 1fr)' : '1fr'
        case this.viewport.breakpoint.lgUp: return canFindUsers ? 'repeat(2, 260px) auto' : '260px auto'
      }
    },

    flagsIsDefault() {
      return this.proxy.isActive && !this.proxy.isBlocked && this.proxy.captchaIsRequired === undefined
    }
  },

  methods: {
    clearFlags() {
      this.proxy.isActive = true
      this.proxy.isBlocked = false
      this.proxy.captchaIsRequired = undefined
    }
  },

  render
}
