import render from './render'

export default {
  name: 'ButtonPrint',

  props: {
    value: String,
    label: String,

    icon: {
      type: String,
      default: 'print'
    },

    disabled: Boolean
  },

  render
}
