export default {
  title: 'Companies',

  titles: {
    create: 'Create company',
    vat: 'VAT'
  },

  labels: {
    title: 'Title',
    vat: 'Calculation procedure',
    owner: 'Owner'
  },

  vat: {
    true: 'With VAT',
    false: 'Without VAT'
  },

  hints: {
    notFound: 'Companies not found'
  },

  snackbars: {
    created: 'Company successfully created.',
    updated: 'Company successfully updated.',
    removed: 'Company successfully removed.'
  }
}
