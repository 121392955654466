import Vue from 'vue'

import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/sendings/utils'

import render from './render'

async function inputFilter(result) {
  if (!result.Owner) {
    await fillDependence.call(this, result, {
      service: 'users',
      permissions: [ 'reseller', true ],
      pathToId: 'OwnerId',
      pathToObject: 'Owner',
      outputPath: 'Owner',
      picks: PUBLIC_FIELDS_USER
    })
  }

  result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

  await fillDependence.call(this, result, {
    service: 'providers',
    permissions: [ 'reseller', true ],
    pathToId: 'meta._routing.provider',
    picks: [ 'id', 'title' ]
  })

  if (result && result.payload && (result.payload.file || result.payload.image || result.payload.audio)) {
    const [ id1, id2 ] = (result.payload.file || result.payload.image || result.payload.audio).split('/')

    result.payload.fileId = id2 || id1

    await fillDependence.call(this, result, {
      service: 'storage',
      permissions: [ 'me', 'reseller', true ],
      pathToId: 'payload.fileId',
      pathToObject: 'File',
      outputPath: 'File',
      requestParams: {
        query: {
          return: 'meta'
        }
      }
    })
  }

  return result
}

export default {
  name: 'Fallbacks',

  mixins: [
    generateServices([
      {
        name: serviceName,

        inputFilter,

        get: {
          params: {
            query: {
              $scope: [ 'full' ]
            }
          }
        },

        find: false,
        update: false,
        create: false,
        remove: false
      },
      {
        name: serviceName,
        as: 'sendings2',

        inputFilter,

        get: {
          params: {
            query: {
              $scope: [ 'full' ]
            }
          }
        },

        find: false,
        update: false,
        create: false,
        remove: false
      }
    ])
  ],

  props: {
    id: String
  },

  data() {
    return {
      dialogs: {},
      sending: undefined
    }
  },

  watch: {
    id() {
      this.rest[serviceName].get(this.id)
    }
  },

  mounted() {
    this.rest[serviceName].get(this.id)
  },

  beforeDestroy() {
    this.restData[serviceName].get.data = undefined
  },

  methods: {
    async showSendingDialog(id) {
      if (id) {
        try {
          const result = await this.rest.sendings2.get(id)
          if (result) {
            this.sending = result
            Vue.set(this.dialogs, id, true)
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
