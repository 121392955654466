import Vue from 'vue'

import { getCurrencyFromStorage, globalErrorHandler, globalErrorProcessor } from '@/utils'

export const currency = {
  data() {
    return {
      currency: getCurrencyFromStorage()
    }
  },

  methods: {
    async getCurrency(ownerId) {
      try {
        const { data } = await Vue.$GRequest.get('settingsUser', `${ownerId}/billings.currency`)
        if (data && data.result) {
          this.currency = data.result
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  }
}
