import { backendServerIP } from '../constants'

export async function getCustomFrontendSettings() {
  const response = await fetch(`${backendServerIP}/settings.json`, { method: 'get' })
  if (!response.ok) {
    throw new Error(response.status)
  }

  return await response.json()
}

export default { getCustomFrontendSettings }
