import { buttonModes, colors, moderationColors, sizes } from '@/utils'

import { ruleIcons } from '@/components/misc/rules/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import squircle from '@/components/icon/squircle'

function renderLabel(h) {
  return h(
    'div',
    {
      class: 'condition__row-label'
    },
    this.getTranslate(`conditions.tags.${this.tag}`)
  )
}

function renderValueByTag(h) {
  switch (this.tag) {
    case 'sender': {
      return [
        this.value.map((sender, index) => {
          if (index < 1) {
            return h(
              tag,
              {
                props: {
                  label: sender,
                  size: sizes.small,
                  color: colors.primary
                }
              }
            )
          }
        }),

        this.value.length > 1 ? h(
          tag,
          {
            props: {
              label: `+${this.value.length - 1}`,
              size: sizes.small,
              color: colors.primary
            }
          }
        ) : undefined
      ]
    }

    case 'type': {
      return h(
        tag,
        {
          props: {
            label: this.getTranslate(`sendings.types.${this.value}`),
            color: colors[this.value],
            size: sizes.small
          }
        }
      )
    }

    case 'moderation': {
      return h(
        tag,
        {
          props: {
            label: this.getTranslate(`misc.moderation.${this.value}`),
            color: moderationColors[this.value],
            size: sizes.small
          }
        }
      )
    }

    case 'text':
    case 'fallbacks':
    case 'buttons': {
      return h(
        tag,
        {
          props: {
            label: `${this.getTranslate(`conditions.labels.${this.tag}`)} | ${this.value.length}`,
            size: sizes.small,
            color: colors.primary
          }
        }
      )
    }
  }
}

function renderValue(h) {
  if (this.tag) {
    if (this.hasValue) {
      return h(
        'div',
        {
          class: 'faic grid-gap--8'
        },
        [ renderValueByTag.call(this, h) ]
      )
    } else {
      return renderLabel.call(this, h)
    }
  }
}

function renderRow(h) {
  if (this.tag) {
    return h(
      'div',
      {
        class: {
          [`condition__row condition__row--${this.tag}`]: true,
          ['condition__row--empty']: !this.hasValue
        },
        on: {
          click: () => {
            this.$emit('dialog', true)
          }
        }
      },
      [ renderValue.call(this, h) ]
    )
  }
}

function renderIcon(h) {
  return h(
    squircle,
    {
      props: {
        icon: ruleIcons[this.tag],
        color: colors.grey,
        size: sizes.medium,
        tooltip: this.getTranslate(`conditions.tags.${this.tag}`)
      }
    }
  )
}

function renderCondition(h) {
  if (this.tag) {
    return h(
      'div',
      {
        class: 'condition__holder'
      },
      [ renderRow.call(this, h) ]
    )
  }
}

function renderClearButton(h) {
  if (!this.readonly) {
    return h(
      button,
      {
        class: {
          'hover-child': this.viewport.breakpoint.mdUp
        },
        props: {
          mode: buttonModes.flat,
          icon: 'clear',
          disabled: !this.hasValue
        },
        on: {
          click: () => {
            this.$emit('clear', true)
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'condition hover-parent hover-parent--opacity': true,
        'condition--readonly': this.readonly
      }
    },
    [
      renderIcon.call(this, h),
      renderCondition.call(this, h),
      renderClearButton.call(this, h)
    ]
  )
}
