import { svgIcons } from '@/utils'

import svg from '@/components/misc/svg'

const className = 'icon-svg'

export default function(h) {
  const { children, viewBox } = svgIcons[this.$icon] || {}
  if (children && viewBox) {
    return h(
      svg,
      {
        class: {
          [`${className}`]: true,
          [`${className}--${this.color}`]: !!this.color
        },
        directives: this.$directives,
        props: {
          children,
          viewBox,
          color: this.color,
          height: this.size,
          width: this.size
        }
      }
    )
  }
}
