export default {
  title: 'OTP Settings',

  widgets: {
    title: 'Widgets',

    titles: {
      create: 'Create Widget',
      settings: 'Settings',
      channels: 'Channels'
    },

    labels: {
      name: 'Name',
      isActive: 'Is Active',
      isBlocked: 'Is Blocked',
      captchaIsRequired: 'Captcha is Required',
      owner: 'Owner',
      channels: 'Channels'
    },

    hints: {
      notFound: 'No widgets found',
      channels: 'To successfully attach channels to the widget, you need to select the required channels and click the "Attach" button.'
    },

    tooltips: {
      isActive: {
        true: 'Widget is active',
        false: 'Widget is not active'
      },

      isBlocked: {
        true: 'Widget is blocked by the administrator'
      }
    },

    warnings: {
      channels: 'No available active channels. Please contact support.'
    },

    snackbars: {
      created: 'Widget has been created',
      updated: 'Widget has been updated',
      removed: 'Widget has been removed',

      channels: {
        attached: 'Channels have been attached'
      }
    }
  },

  channels: {
    title: 'Channels',

    titles: {
      create: 'Create Channel',
      templates: 'Templates',

      additionalSettings: {
        title: 'Additional Settings',
        code: 'Code Settings'
      }
    },

    labels: {
      name: 'Name',
      channelType: 'Channel Type',
      templates: 'Templates',

      additionalSettings: {
        code: {
          length: 'Code Length',
          useNumbers: 'Use Numbers',
          useSymbols: 'Use Symbols',
          useLowerCaseLetters: 'Use Lower Case Letters',
          useUpperCaseLetters: 'Use Upper Case Letters',
          symbols: 'Symbols',
          exclude: 'Exclude',
          excludeSimilarCharacters: 'Exclude Similar Characters',
          strict: 'Strict Mode',
          beautify: 'Beautify Code'
        },
        lifetime: 'Code Lifetime, sec',
        numberOfTries: 'Number of Tries'
      }
    },

    hints: {
      notFound: 'No channels found',
      templates: 'To successfully attach templates to the channel, you need to select the required templates and click the "Attach" button.',
      additionalSettings: 'Lifetime and number of tries should be greater than 0'
    },

    warnings: {
      templates: 'No available templates.'
    },

    snackbars: {
      created: 'Channel has been created',
      updated: 'Channel has been updated',
      removed: 'Channel has been removed',

      templates: {
        attached: 'Templates have been attached'
      }
    }
  },

  templates: {
    title: 'Templates',

    titles: {
      create: 'Create Template',
      conditions: 'Conditions'
    },

    labels: {
      name: 'Name',
      channelType: 'Channel Type',

      additionalSettings: {
        senderId: 'Sender',
        template: 'Template'
      }
    },

    hints: {
      notFound: 'No templates found',
      conditions: 'At least one condition must be filled.',

      additionalSettings: {
        template: 'For code substitution use {{ code }}'
      }
    },

    snackbars: {
      created: 'Template has been created',
      updated: 'Template has been updated',
      removed: 'Template has been removed'
    }
  }
}
