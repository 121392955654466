// import Vue from 'vue'

import { /* globalErrorHandler, globalErrorProcessor, */ isObjectNotEmpty } from '@/utils'

import render from './render'

export default {
  name: 'ProvidersMetric',

  props: {
    id: String,

    value: {
      type: Object,
      default: () => ({})
    },

    period: {
      type: Number,
      default: 5
    },

    mode: {
      type: String,
      default: 'compact',
      validator: value => {
        return ~[ 'compact', 'full' ].indexOf(value)
      }
    }
  },

  data() {
    return {
      metrics: undefined,
      interval: undefined,

      loading: false
    }
  },

  watch: {
    value: {
      handler() {
        if (isObjectNotEmpty(this.value)) {
          this.metrics = this.value
        }
      },
      deep: true
    }
  },

  mounted() {
    if (isObjectNotEmpty(this.value)) {
      this.metrics = this.value
    } else {
      this.get()
      if (this.mode === 'full') {
        this.setInterval()
      }
    }
  },

  beforeDestroy() {
    this.clearInterval()
  },

  methods: {
    async get() {
      // try {
      //   this.loading = true

      //   const { data } = await Vue.$GRequest.get('providers', `${this.id}/metrics`)

      //   this.metrics = data
      // } catch (error) {
      //   globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      // } finally {
      //   this.loading = false
      // }
    },

    setInterval() {
      this.interval = setInterval(() => {
        if (this.loading === false) {
          this.get()
        }
      }, this.period * 1000)
    },
    clearInterval() {
      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  },

  render
}
