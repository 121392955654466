import { defaultWebsocketFindCreatedEvent, generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default function(UserId) {
  return {
    name: services.userComments,

    mixins: [
      generateServices({
        name: services.userComments,

        find: {
          defaultFilter: { UserId },
          defaultPagination: { limit: 10 },

          async websocketFindCreatedEvent(instance) {
            if (UserId !== instance.UserId) {
              return
            }

            defaultWebsocketFindCreatedEvent.call(this, instance)
          }
        },

        get: false,
        update: false,
        remove: false
      })
    ],

    data() {
      return {
        payload: {
          text: undefined,
          UserId: undefined
        }
      }
    },

    mounted() {
      this.rest[services.userComments].find()
    },

    methods: {
      async create() {
        try {
          await this.rest[services.userComments].create(Object.assign({}, this.payload, { UserId }))
          this.payload.text = undefined
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    },

    render
  }
}
