import Vue from 'vue'
import { Macro } from '@sigma-legacy-libs/cache'

import render from './render'

const name = 'hlr'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

export default {
  name,

  data() {
    return {
      searchValue: undefined,
      searchResult: undefined,

      isValid: false,
      loading: false
    }
  },

  methods: {
    cachedRequest: Cache.wrapWithCache(async (key, value) => {
      return await Vue.$GRequest.get(name, value)
    }),

    async getHlr(value) {
      if (value) {
        try {
          this.loading = true
          const { data } = await this.cachedRequest(`${name}:${value}`, value)
          this.searchResult = data
        } catch (error) {
          this.addSnackbar({
            text: error.message || error.error,
            type: 'error'
          })
        } finally {
          this.loading = false
        }
      }
    },

    cleanData() {
      this.searchValue = undefined
      this.searchResult = undefined
    }
  },

  render
}
