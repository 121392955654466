import { serviceName } from '@/components/services/yclients/utils'

export default function(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: 'update',
        color: 'primary',
        loading: this.loading,
        disabled: this.loading
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.tooltips.updateStatus`),
            placement: 'top'
          }
        }
      ],
      on: {
        click: () => {
          this.updateStatus()
        }
      }
    }
  )
}
