import { permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'Panel',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    hasCustomer() {
      return !!(this.value.customer || this.value.customerId)
    },
    hasOwner() {
      return !!(this.value.owner || this.value.ownerId)
    },

    infoGridTemplateColumns() {
      const result = [ 'minmax(80px, 100px) 120px' ]

      switch (true) {
        case this.viewport.breakpoint.mdDown: {
          result.push('repeat(2, minmax(80px, 150px))')
          break
        }
        case this.viewport.breakpoint.mdUp: {
          result.push('repeat(2, minmax(80px, 150px))')
          if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
            if (this.hasCustomer || this.hasOwner) {
              result.push('auto')
            }
          }
          break
        }
      }

      return result.join(' ')
    },

    panelGridTemplateColumns() {
      const result = [ 'auto 1fr' ]
      if (this.viewport.breakpoint.xl) {
        result.push('auto')
      }

      return result.join(' ')
    }
  },

  render
}
