import { components } from '@/utils'

import render from './render'

export default {
  name: components['list-group'],

  props: {
    label: [ String, Number ],

    active: Boolean,
    hovered: Boolean,
    dense: Boolean,
    rounded: Boolean,
    outline: Boolean,
    disabled: Boolean,

    icon: String,
    iconColor: String
  },

  data() {
    return {
      value: false
    }
  },

  watch: {
    active(value) {
      if (typeof value === 'boolean') {
        this.value = value
      }
    }
  },

  mounted() {
    if (this.active) {
      this.value = true
    }
  },

  render
}
