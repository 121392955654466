import { getUnix } from '@/utils'

const _ = { get: require('lodash/get') }

/**
 *
 *
 * @param {Object} [options={}] Объект опций.
 * @param {String} [options.permissionAccept] Строка по которой будет происходить проверка прав, например: 'advanced.seindings.find'.
 * @returns {Object}
 */
export function downloadable(options = {}) {
  return {
    props: {
      preset: String
    },

    computed: {
      downloadable() {
        const oneMonth = 31 * 24 * 60 * 60 * 1000
        const lt = getUnix(_.get(this.filter, 'createdAt.$lt'))
        const gt = getUnix(_.get(this.filter, 'createdAt.$gt'))
        const diff = lt - gt
        switch (true) {
          case this.checkPermissions(options.permissionAccept, 'me'): {
            if (diff >= oneMonth) {
              return true
            }
            break
          }
          case this.checkPermissions(options.permissionAccept, 'reseller'): {
            if (diff >= 3 * oneMonth) {
              return true
            }
            break
          }
          case this.checkPermissions(options.permissionAccept):
          default: {
            return false
          }
        }

        return false
      }
    }
  }
}

export default { downloadable }
