import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'Template',

  data() {
    return {
      payload: {
        username: undefined,
        password: undefined
      },

      isValid: false
    }
  },

  computed: {
    ...mapGetters({
      errors: 'login/errors',
      status: 'login/status'
    })
  },

  watch: {
    errors: {
      handler() {
        if (this.errors && this.errors.message) {
          this.addSnackbar({
            type: 'error',
            text: this.errors.message
          })
        }
      },
      deep: true
    }
  },

  methods: {
    ...mapActions({ login: 'login/login' })
  },

  render
}
