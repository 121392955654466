import { colors } from '@/utils'

import render from './render'

export default {
  name: 'NativeSVG',

  props: {
    children: {
      type: Array,
      default: () => []
    },
    viewBox: {
      type: String,
      default: '0 0 24 24'
    },

    height: {
      type: Number,
      default: 24
    },
    width: {
      type: Number,
      default: 24
    },

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    fill: {
      type: String,
      default: undefined
    },

    opacity: {
      type: Number,
      default: undefined
    }
  },

  render
}
