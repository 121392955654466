import Vue from 'vue'

import { getUrlToStorage, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

export default {
  name: 'Buttons',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false,

      dialogType: undefined
    }
  },

  methods: {
    async download() {
      try {
        this.dialogType = 'export'
        this.showDialog = true
        const { data: file } = await Vue.$GRequest.find(`storage/export/${this.value.File.id}`, { query: { OwnerId: this.value.File.OwnerId } })
        if (file) {
          window.location.href = getUrlToStorage([ file.OwnerId || file.Owner && file.Owner.id, file.id ])
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showDialog = false
      }
    }
  },

  render
}
