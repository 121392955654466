export default {
  title: 'Комментарии',

  labels: {
    text: 'Комментарий'
  },

  snackbars: {
    created: 'Комментарий успешно создан.',
    updated: 'Комментарий успешно обновлён.',
    removed: 'Комментарий успешно удалён.'
  }
}
