import { cloneDeep } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { SENDINGS_OUTGOING_FILTER, appendFormattedDates, downloadable, fillDependence, generateServices, permissionPresets, services, tableHeaderParser } from '@/utils'

import render from './render'

export default {
  name: 'SendingsDetails',

  mixins: [
    generateServices({
      name: services.sendings,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: permissionPresets.resellerUp,
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        if (result && result.payload && (result.payload.file || result.payload.image || result.payload.audio)) {
          const [ id1, id2 ] = (result.payload.file || result.payload.image || result.payload.audio).split('/')

          result.payload.fileId = id2 || id1

          await fillDependence.call(this, result, {
            service: 'storage',
            permissions: permissionPresets.meUp,
            pathToId: 'payload.fileId',
            pathToObject: 'File',
            outputPath: 'File',
            requestParams: {
              query: {
                return: 'meta'
              }
            }
          })
        }

        appendFormattedDates.call(this, result)

        return result
      },

      find: {
        bucketEnabled: true,
        defaultFilter: cloneDeep(SENDINGS_OUTGOING_FILTER),
        disableWatcherFilter: true
      },
      get: false,
      update: false,
      create: false,
      remove: false
    }),

    downloadable({ permissionAccept: `advanced.${services.sendings}.find` })
  ],

  data() {
    return {
      showDialog: false,
      source: undefined
    }
  },

  props: {
    filter: {
      type: Object,
      default: () => cloneDeep(SENDINGS_OUTGOING_FILTER),
      required: true
    }
  },

  computed: {
    ...mapGetters({
      findSendingFromRouteParams: 'global/findSendingFromRouteParams'
    }),

    headers() {
      return tableHeaderParser.call(this, [
        {
          value: 'type',
          class: 'px-0'
        },
        {
          value: 'status',
          class: 'px-0'
        },
        {
          title: `${services.sendings}.headers.owner`,
          value: 'owner',
          align: 'left',
          class: 'px-0',
          show: this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)
        },
        {
          title: `${services.sendings}.headers.recipient`,
          value: 'payload.recipient',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: `${services.sendings}.headers.sender`,
          value: 'payload.sender',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: `${services.sendings}.headers.text`,
          value: 'payload.text',
          align: 'left',
          class: 'px-2',
          sortable: true
        },
        {
          title: '',
          value: 'custom.field.1',
          class: 'px-2'
        },
        {
          value: 'segments',
          class: 'px-2'
        },
        {
          value: 'billSec',
          class: 'px-2'
        },
        {
          title: `${services.sendings}.headers.operator`,
          value: 'operator',
          align: 'left',
          class: 'px-2'
        },
        {
          title: `${services.sendings}.headers.provider`,
          value: 'provider',
          align: 'left',
          class: 'px-2',
          show: this.checkPermissions(`advanced.${services.providers}.get`, [ 'reseller', true ])
        },
        {
          title: `${services.sendings}.headers.amount`,
          value: 'meta.billing.amount',
          align: 'left',
          class: 'px-2'
        },
        {
          title: 'misc.createdAt',
          value: 'createdAt',
          align: 'right',
          class: 'px-2',
          sortable: true
        },
        {
          title: 'misc.updatedAt',
          value: 'updatedAt',
          align: 'right',
          class: 'px-2',
          sortable: true
        }
      ])
    }
  },

  watch: {
    filter: {
      handler() {
        this.restData[services.sendings].find.filter = cloneDeep(this.filter)
        if (this.restData[services.sendings].find.pagination.offset !== 0) {
          this.restData[services.sendings].find.pagination.offset = 0
        }
      },
      deep: true
    },
    $route: {
      handler() {
        this.getFilterFromRouter()
      },
      deep: true
    },

    findSendingFromRouteParams() {
      this.getFilterFromRouter()
    }
  },

  mounted() {
    this.getFilterFromRouter()
  },

  methods: {
    ...mapActions({
      setFindSendingFromRouteParams: 'global/setFindSendingFromRouteParams'
    }),

    async getFilterFromRouter() {
      if (this.$route.query.id || this.$route.params.id) {
        const id = this.$route.query.id || this.$route.params.id
        const { createdAt } = this.$route.params
        this.showDialog = false
        this.restData[services.sendings].find.filter.id = id
        if (createdAt) {
          this.restData[services.sendings].find.filter.createdAt = createdAt
        }
        await this.rest[services.sendings].find()
        this.setFindSendingFromRouteParams(false)
      }
    }
  },

  render
}
