import { colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.viewport.size.width >= 640) {
    return h(
      sticker,
      {
        props: {
          value: this.value.phone || this.getTranslate(`${services.apisender}.hints.phoneNotFound`),
          label: this.getTranslate(`${services.apisender}.labels.phone`),
          icon: icons.phone,
          color: colors.grey
        }
      }
    )
  }
}

