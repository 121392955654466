import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

export const allowedSendingTypes = [
  SENDING_TYPES.sms,
  SENDING_TYPES.viber,
  SENDING_TYPES.vk,
  SENDING_TYPES.whatsapp,
  SENDING_TYPES.push,
  SENDING_TYPES.voice,
  SENDING_TYPES.flashcall
]

export const allowedSendingTypesForImage = [
  SENDING_TYPES.viber,
  SENDING_TYPES.whatsapp
]

export default {
  allowedSendingTypes,
  allowedSendingTypesForImage
}
