import buttonToolbar from '@/components/button/toolbar'

export default function(h) {
  return h(
    buttonToolbar,
    {
      props: {
        icon: 'wallet-add',
        tooltip: this.getTranslate('payments.buttons.add.funds')
      },
      on: {
        click: () => {
          this.setShowPaymentDialog(true)
        }
      }
    }
  )
}
