import { sizes } from '@/utils'

import providersState from '@/components/services/providers/state'

export default function(h) {
  return h(
    providersState,
    {
      props: {
        id: this.value.id,
        size: sizes.medium
      }
    }
  )
}
