import { colors, icons, services } from '@/utils'

import pagination from '@/components/misc/pagination'
import buttonRefresh from '@/components/button/refresh'

function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.loading,
        disabled: this.loading,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.buttons.refresh`),
        shortClickMethod: () => this.$emit('refresh')
      }
    }
  )
}

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.pagination,
        selectRows: false,
        service: services.sendingsModeration
      },
      on: {
        input: event => {
          this.pagination = event
        }
      }
    }
  )
}

function renderPaginationBlock(h, place) {
  return h(
    'div',
    {
      class: {
        [`${services.sendingsModeration}__pagination ${services.sendingsModeration}__pagination--${place}`]: true,
        [`${services.sendingsModeration}__pagination--fill`]: this.pagination.total > 0
      }
    },
    [
      renderPagination.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}

function renderUser(h, user) {
  return h(
    'g-list-item',
    {
      props: {
        active: this.OwnerId === user.OwnerId,
        button: {
          color: colors.primary,
          icon: icons.open_in_new,
          tooltip: {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate(`${services.sendingsModeration}.tooltips.buttons.openInNew`)
            }
          },
          callback: () => {
            this.openUser(user.OwnerId)
          }
        }
      },
      on: {
        click: () => {
          this.OwnerId = user.OwnerId
          this.$emit('userId', user.OwnerId)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'fjcsb faic'
        },
        [
          h('div', { class: 'text-overflow caption' }, user.username),
          h('div', { class: 'text--grey caption' }, user.total)
        ]
      )
    ]
  )
}

function renderUsersList(h) {
  if (this.users.length) {
    return this.users.map(user => renderUser.call(this, h, user))
  }

  return h('g-empty')
}

export default function(h) {
  return h(
    'g-card',
    {
      class: `${services.sendingsModeration}-users`,
      props: {
        rounded: true,
        outline: true,
        overflow: true
      }
    },
    [
      renderPaginationBlock.call(this, h, 'top'),
      renderUsersList.call(this, h)
    ]
  )
}
