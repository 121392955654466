import { generateUniqueKey } from '@/utils'

import render from './render'

export default function(options = {}) {
  return {
    name: 'Item',

    props: {
      value: {
        type: Object,
        default: () => ({})
      },

      view: {
        type: String,
        default: 'row',
        validator: value => {
          return ~[ 'row', 'card', 'panel' ].indexOf(value)
        }
      },

      size: {
        type: String,
        default: 'small',
        validator: value => {
          return ~[ 'small', 'medium', 'large' ].indexOf(value)
        }
      },

      keyProp: {
        type: String,
        default: generateUniqueKey()
      },

      sensitivity: {
        type: Boolean,
        default: false
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
