import { get } from 'lodash'

import ConfirmButton from '@/components/button/confirm'

export default function(h, { path, serviceName, payload }) {
  return h(
    ConfirmButton,
    {
      props: { value: this.proxy },
      on: {
        input: event => {
          this.$emit('input', event)
        }
      }
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy,
            hint: this.getTranslate(`${serviceName}.labels.${path}`),
            type: 'number',
            step: 'any',
            suffix: this.getTranslate(`currency.${get(payload.settings, 'billings.currency')}.symbol.unicode`),
            mode: 'outline',
            rounded: true,
            dense: true,
            clearable: true
          },
          on: {
            input: event => {
              this.proxy = event
            },
            keypress: event => {
              if (event.keyCode === 13) {
                this.$emit('input', this.proxy)
              }
            }
          }
        }
      )
    ]
  )
}
