import { buttonModes, colors, services } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: this.isActive ? 'check_circle_outline' : 'remove_circle',
        color: this.isActive ? colors.success : colors.error,
        mode: buttonModes.flat,
        loading: this.loading,
        disabled: this.loading,
        tooltip: this.getTranslate(`${services.providers}.tooltips.buttons.isActive`)
      },
      key: this.value.id,
      on: {
        click: () => {
          this.update()
        }
      }
    }
  )
}
