export default {
  titles: {
    generate: 'Generate message text',
    additional: 'Additional settings',
    text: 'Generated text'
  },

  labels: {
    text: 'Message text',
    messageTheme: 'Message theme',
    occasion: 'Situation or case',
    smsSegments: 'Number of SMS segments',
    otherChars: 'Number of characters',
    additionalNotes1: 'Wishes',
    rephraseText: 'Rephrase',
    addEmojis: 'Use emojis',
    endQuestion: 'End with the next question',
    emotionalTone: 'Emotional tone',
    language: 'Message language'
  },

  emotionalTones: {
    joyful: 'Joyful',
    neutral: 'Neutral',
    businesslike: 'Businesslike',
    energetic: 'Energetic',
    enthusiastic: 'Enthusiastic',
    excited: 'Excited',
    optimistic: 'Optimistic',
    informative: 'Informative',
    inspiring: 'Inspiring'
  },

  infos: {
    additional: 'For a more accurate result, fill in the "Additional settings" located below',
    text: 'The generated text will be displayed here'
  },

  hints: {
    messageTheme: 'Specify the key theme of the message',
    occasion: 'Describe the main essence of the situation',
    smsSegments: 'From 1 to 10 segments maximum',
    otherChars: 'Up to 1000 characters maximum',
    additionalNotes1: 'Additional wishes for the text',
    rephraseText: 'Text that needs to be rephrased'
  },

  tooltips: {
    generate: 'Use a generative model to create message text'
  },

  warnings: {
    limit: 'The request limit has been exceeded. Try again later.'
  },

  buttons: {
    generate: 'Generate',
    additional: 'Additional settings'
  },

  stats: {
    characters: 'Characters: {value}',
    segments: 'Segments: {value}',
    encoding: 'Encoding: {value}'
  }
}
