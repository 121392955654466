import { cloneDeep, merge } from 'lodash'
import { TASK_STATES, TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { TASKS_FILTER, getChosenMonth } from '@/utils'

import TasksTemplate from '@/components/services/tasks/template'

import render from './render'

const tasksDefaultFilter = merge(
  cloneDeep(TASKS_FILTER),
  { type: [ TASK_TYPES['payments:recurrent'], TASK_TYPES['billings:operations'] ] }
)

export default {
  name: 'Tasks',

  components: {
    'tasks-template': TasksTemplate({
      defaultFilter: tasksDefaultFilter,
      presets: [
        {
          title: 'today',
          filter: merge(
            cloneDeep(tasksDefaultFilter),
            {
              state: undefined,
              createdAt: {
                $gt: undefined,
                $lt: undefined
              },
              createdAtPreset: 'today'
            }
          )
        },
        {
          title: 'twoWeeks',
          filter: Object.assign(
            cloneDeep(tasksDefaultFilter),
            {
              state: TASK_STATES.scheduled,
              createdAt: getChosenMonth(-1),
              createdAtPreset: undefined
            }
          )
        }
      ]
    })
  },

  render
}
