import { inputModes } from '@/utils'

function renderText(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.text,
        label: this.getTranslate('sendings.labels.button.text'),
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        dense: true,
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.proxy.text = event
        }
      }
    }
  )
}

function renderAction(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.action,
        label: this.getTranslate('sendings.labels.button.action'),
        mode: inputModes['line-label'],
        rounded: true,
        details: false,
        dense: true,
        disabled: this.disabled
      },
      on: {
        input: event => {
          this.proxy.action = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8 border border--dashed border--rounded pa-3': true,
        'grid-cols--2': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderText.call(this, h),
      renderAction.call(this, h)
    ]
  )
}
