import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'Type',

    mixins: [ proxy({ type: options.type }) ],

    props: {
      disabled: Boolean,
      readonly: Boolean
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
