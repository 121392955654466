export default {
  title: 'Модерация',

  labels: {
    task: 'Задача на модерацию',
    pattern: 'Текст сообщения и шаблона',
    defaultRoute: 'Маршрут по умолчанию',
    sender: 'Имя отправителя',
    text: 'Текст',
    moderation: 'Модерация',
    status: 'Статус',
    id: 'Id',
    providerId: 'Провайдер',
    OwnerId: 'Владелец',
    recipientsCount: 'Получатели / сегменты',
    updatedAt: 'Обновлено',
    createdAt: 'Создано',
    operator: 'Оператор',
    tasks: {
      status: {
        init: 'Инициализированa',
        processing: 'Выполняется',
        success: 'Выполнена',
        error: 'Ошибка'
      },
      moderation: {
        rejected: 'Отклонено',
        approved: 'Одобрено'
      }
    },
    sendings: {
      sendingId: 'Id'
    },
    sendingsList: 'Список отправлений'
  },

  tabs: {
    sendings: 'Сообщения',
    tasks: 'Задачи'
  },

  actions: {
    approved: 'Одобрить',
    rejected: 'Отклонить',
    rejectedRefound: 'Отклонить и вернуть деньги',
    massive: {
      approved: 'Одобрить все',
      rejected: 'Отклонить все',
      rejectedRefound: 'Отклонить все и вернуть деньги'
    }
  },

  tooltips: {
    info: 'В поле маршрут по умолчанию вы можете выбрать конкретного провайдера на которого отправить сообщение. Если вы оставите поле пустым, то маршрут для сообщения будет выбран на стороне сервера как обычно, используя настройки конкретного пользователя.',
    buttons: {
      openInNew: 'Открыть в новой вкладке',
      counter: 'Счетчик сообщений на модерации',
      refresh: 'Обновить'
    },
    recipientsCount: 'Количество получателей / сегментов',
    tasks: {
      status: {
        init: 'Инициализирована',
        processing: 'Выполняется',
        success: 'Выполнена',
        error: 'Ошибка'
      },
      moderation: {
        rejected: 'Отклонено',
        approved: 'Одобрено'
      }
    },
    createdAt: {
      min: 'Время первого сообщения',
      max: 'Время последнего сообщения'
    }
  },

  hints: {
    sendings: {
      info: 'Список сообщений ниже может быть пустым некоторое время.'
    },
    provider: 'По маршруту'
  }
}
