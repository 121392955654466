import { mccObjectToArray } from '@/components/trees/mccmnc/utils'

import render from './render'

export default {
  name: 'MCCMNCReadonly',

  mixins: [ mccObjectToArray ],

  render
}
