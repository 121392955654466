import Vue from 'vue'

import { mapGetters } from 'vuex'

export default {
  name: 'SnackbarWrapper',

  computed: {
    ...mapGetters({ snackbars: 'notifications/snackbars' })
  },

  mounted() {
    Vue.$bus.on('rest.**', this.restListener)
  },

  beforeDestroy() {
    Vue.$bus.off('rest.**', this.restListener)
  },

  methods: {
    restListener(serviceName, method) {
      const snackbar = {
        text: this.getTranslate(`${serviceName}.snackbars.${method}`)
      }

      switch (method) {
        case 'created':
          snackbar.type = 'success'
          break
        case 'updated':
          snackbar.type = 'info'
          break
        case 'removed':
          snackbar.type = 'warning'
          break
      }

      this.addSnackbar(snackbar)
    }
  },

  render(h) {
    return h('g-snackbar', { props: { items: this.snackbars } })
  }
}
