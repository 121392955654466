import proxy from '@sigma-legacy-libs/g-proxy'

import { serviceName } from '@/components/services/providerGroups/utils'

import render from './render'

export default {
  name: `${serviceName}Filter`,

  mixins: [ proxy({ type: 'object' }) ],

  render
}
