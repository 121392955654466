import render from './render'

export default {
  name: 'Task',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = []

      const { status, moderation, sender, pattern } = this.value
      if (status) {
        if (this.viewport.breakpoint.smUp) {
          result.push('130px')
        } else {
          result.push('auto')
        }
      }
      if (moderation) {
        if (this.viewport.breakpoint.smUp) {
          result.push('120px')
        } else {
          result.push('auto')
        }
      }
      if (sender && this.viewport.breakpoint.lgUp) {
        result.push('120px')
      }
      if (this.viewport.breakpoint.mdUp) {
        result.push('120px')
      }
      if (pattern) {
        result.push('auto')
      }

      return result.join(' ')
    }
  },

  render
}
