import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'
import { TASK_STATES, TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors } from '@/utils'

export const countsTypes = upgradeArray([ 'completed', 'errored', 'remaining', 'total' ])

export const countsColors = {
  completed: colors.success,
  errored: colors.error,
  remaining: colors.primary,
  total: colors.black
}

export const typeColors = {
  [TASK_TYPES['payments:recurrent']]: colors.info,
  [TASK_TYPES['billings:operations']]: colors.success
}
export const typeIcons = {
  [TASK_TYPES['payments:recurrent']]: 'repeat',
  [TASK_TYPES['billings:operations']]: 'account_balance'
}
export const statesColors = {
  [TASK_STATES.completed]: colors.success,
  [TASK_STATES.paused]: colors.warning,
  [TASK_STATES.running]: colors.primary,
  [TASK_STATES.scheduled]: colors.epic,
  [TASK_STATES.failed]: colors.error
}

export const statesIcons = {
  [TASK_STATES.completed]: 'check_circle_outline',
  [TASK_STATES.paused]: 'pause_circle_outline',
  [TASK_STATES.running]: 'keyboard_double_arrow_right',
  [TASK_STATES.scheduled]: 'access_time',
  [TASK_STATES.failed]: 'error_outline'
}
