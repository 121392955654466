import { PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'
import { site } from '@sigma-legacy-libs/essentials/lib/schemas'

import { buttonModes, colors, inputModes, sizes } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import settingRow from '@/components/services/settings/row'
import button from '@/components/button'

function renderIcon(h) {
  return h(
    squircle,
    {
      props: {
        color: colors.grey,
        size: sizes.medium
      }
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: 'RUB',
            color: colors.grey,
            size: 32
          }
        }
      )
    ]
  )
}

function renderPaymentMethodProp(h, { method, prop }) {
  switch (typeof this.proxy[method][prop]) {
    case 'boolean': {
      return h(
        'div',
        {
          class: 'border border--dashed border--rounded pa-2 faic'
        },
        [
          h(
            'g-switch',
            {
              props: {
                value: this.proxy[method][prop],
                label: this.getTranslate(`payments.labels.methods.${method}.${prop}`)
              },
              on: {
                input: event => {
                  this.proxy[method][prop] = event
                }
              }
            }
          )
        ]
      )
    }

    case 'string':
    default: {
      const visibilityProps = {}
      switch (prop) {
        case 'Password':
        case 'ekamToken':
        case 'secret': {
          visibilityProps.afterIcon = this.visibility[method][prop] ? 'visibility' : 'visibility_off'
          visibilityProps.afterIconCallback = () => {
            this.visibility[method][prop] = !this.visibility[method][prop]
          }
          visibilityProps.type = this.visibility[method][prop] ? undefined : 'password'
          break
        }
      }

      return h(
        'g-text-field',
        {
          props: {
            value: this.proxy[method][prop],
            label: this.getTranslate(`payments.labels.methods.${method}.${prop}`),
            mode: inputModes['line-label'],
            defaultValue: '',
            dense: true,
            rounded: true,
            clearable: true,
            details: false,

            ...visibilityProps
          },
          on: {
            input: event => {
              this.proxy[method][prop] = event
            }
          }
        }
      )
    }
  }
}
function renderPaymentMethodProps(h, method) {
  const { SETTINGS_SITE_SCHEMA } = site
  const props = Object.keys(SETTINGS_SITE_SCHEMA['payments.methods'].$default[method])

  return h(
    'div',
    {
      class: 'grid grid-gap--12'
    },
    [
      props.map(prop => renderPaymentMethodProp.call(this, h, {
        method,
        prop
      }))
    ]
  )
}
function renderPaymentMethod(h, method) {
  return h(
    'g-card',
    {
      class: 'grid grid-gap--8 pt-3 pb-2 px-2',
      props: {
        title: this.getTranslate(`payments.methods.${method}`),
        outline: true,
        rounded: true
      }
    },
    [ renderPaymentMethodProps.call(this, h, method) ]
  )
}
function renderPaymentMethods(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--24 pa-2'
    },
    [ PAYMENTS_METHODS.map(method => renderPaymentMethod.call(this, h, method)) ]
  )
}
function renderDialog(h, { path, serviceName }) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        maxWidth: 410
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${serviceName}.subtitles.${path}`) },
          slot: 'header'
        }
      ),

      renderPaymentMethods.call(this, h, { path }),

      h(
        'div',
        {
          class: 'fjcfe faic grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.save'),
                color: colors.primary
              },
              on: {
                click: () => {
                  this.$emit('input', this.proxy)
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h, options) {
  return h(
    settingRow,
    {
      props: {
        title: this.getTranslate(`${options.serviceName}.contents.${options.path}`),
        label: this.getTranslate(`${options.serviceName}.hints.${options.path}`)
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        icon: () => {
          return renderIcon.call(this, h)
        },
        dialog: () => {
          return renderDialog.call(this, h, options)
        }
      }
    }
  )
}
