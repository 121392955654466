import { inputModes } from '@/utils'

function renderRemoveFieldButton(h, item, index, length) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'remove',
        flat: true,
        disabled: length === 1,
        color: 'error'
      },
      on: {
        click: () => {
          this.remove(index)
        }
      }
    }
  )
}
function renderAddFieldButton(h, item, index, length) {
  if (index === length - 1) {
    return h(
      'g-button',
      {
        class: 'ma-0',
        props: {
          icon: 'add',
          flat: true,
          disabled: !(item.key && item.value),
          color: 'success'
        },
        on: {
          click: () => {
            this.add()
          }
        }
      }
    )
  }
}
function renderKeyField(h, { path, serviceName }, item) {
  return h(
    'g-text-field',
    {
      props: {
        value: item.key,
        label: this.getTranslate(`${serviceName}.labels.${path}.domain`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          item.key = event
        }
      }
    }
  )
}
function renderValueField(h, { path, serviceName }, item) {
  return h(
    'g-select',
    {
      props: {
        value: item.value,
        label: this.getTranslate(`${serviceName}.labels.${path}.user`),
        service: 'users',
        query: { isActive: true },
        itemTitle: 'username',
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        clearable: true,
        autocomplete: true,
        details: false
      },
      on: {
        input: event => {
          item.value = event
        }
      }
    }
  )
}
function renderRow(h, options, item, index, length) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': '36px 1fr 1fr 36px' }
    },
    [
      renderRemoveFieldButton.call(this, h, item, index, length),
      renderKeyField.call(this, h, options, item),
      renderValueField.call(this, h, options, item),
      renderAddFieldButton.call(this, h, item, index, length)
    ]
  )
}
function renderRows(h, options) {
  return this.proxy.map((item, index, items) => {
    return renderRow.call(this, h, options, item, index, items.length)
  })
}

function renderSaveButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        rounded: true,
        label: this.getTranslate('misc.buttons.save'),
        icon: 'save',
        color: 'primary'
      },
      on: {
        click: () => {
          this.$emit('input', this._outputFilter(this.proxy))
        }
      }
    }
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe facfe'
    },
    [ renderSaveButton.call(this, h) ]
  )
}

export default function(h, options) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderRows.call(this, h, options),
      renderFooter.call(this, h, options)
    ]
  )
}
