import { isArrayNotEmpty } from '@/utils'

export default function(h) {
  if (isArrayNotEmpty(this.$slots.default)) {
    return h(
      'div',
      {
        class: {
          'grid faifs': true,
          'grid-cols--1': this.viewport.breakpoint.xs,
          [`grid-cols--${Math.round(this.$slots.default.length)}`]: this.viewport.breakpoint.smUp
        }
      },
      [ this.$slots.default ]
    )
  }
}
