export const serviceName = 'routingGroups'

export const categoriesIcons = {
  routingGroups: 'align_horizontal_right',
  routingRules: 'rule',
  providerGroups: 'folder_open',
  providers: 'file_present'
}

export default {
  serviceName,
  categoriesIcons
}
