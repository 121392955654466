import { services } from '@/utils'

import date from '@/components/serviceTemplate/item/date'
import sticker from '@/components/misc/sticker'

function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: `${this.getTranslate(`${services.messageRules}.labels.title`)}`,
        icon: this.value.isActive ? 'check_circle_outline' : 'error_outline',
        color: this.value.isActive ? 'success' : 'error',
        iconTooltip: this.getTranslate(`${services.messageRules}.tooltips.${this.value.isActive ? 'active' : 'notActive'}`),
        service: services.messageRules,
        width: 'fit-content'
      }
    }
  )
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.messageRules}-panel__info`
    },
    [ renderTitle.call(this, h) ]
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${services.messageRules}-panel`]: true
      }
    },
    [
      renderInfo.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
