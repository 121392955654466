export default {
  title: 'Документация',

  categories: {
    platform: 'Платформа',
    api: 'API'
  },

  file: {
    title: 'Документация'
  },

  files: {
    instruction: 'Пользовательская документация',
    'HTTP-REST': 'HTTP-REST',
    SMPP: 'SMPP'
  },

  buttons: {
    toc: 'Содержание'
  },

  platform: {
    instruction: {
      title: 'Пользовательская документация'
    }
  },

  api: {
    'HTTP-REST': {
      title: 'HTTP-REST'
    },
    SMPP: {
      title: 'SMPP'
    }
  }
}
