import Vue from 'vue'

import { get } from 'lodash'

import render from './render'

export default {
  name: 'SupportButton',

  methods: {
    openSupport() {
      const jivositeId = get(this.globalSettings, 'frontend.jivosite')
      const routeToSupport = { name: 'support' }

      switch (this.locale) {
        case 'en': {
          Vue.router.push(routeToSupport)
          break
        }

        case 'ru':
        default: {
          if (jivositeId) {
            if (window.jivo_api) {
              const container = document.querySelector('jdiv')
              container?.classList.remove('jivo-override')
              window.jivo_api.open()
            }
          } else {
            Vue.router.push(routeToSupport)
          }
          break
        }
      }
    }
  },

  render
}
