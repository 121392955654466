import Vue from 'vue'

import render from './render'

export default {
  name: 'ButtonClone',

  props: {
    value: {
      type: Object,
      default: () => ({}),
      required: true
    },

    service: {
      type: String,
      required: true
    },

    clone: {
      type: Function,
      default: () => {},
      required: true
    }
  },

  data() {
    return {
      loading: false,
      showDialog: false
    }
  },

  methods: {
    async _clone() {
      try {
        this.loading = true
        const response = await this.clone()
        if (response.id) {
          this.showDialog = false
          Vue.router.push({
            name: `${this.service}.single`,
            params: { id: response.id }
          })
        }
      } catch (error) {
        this.addSnackbar({
          text: this.getTranslate('errors.types.clone'),
          type: 'error'
        })
      } finally {
        this.loading = false
      }
    }
  },

  render
}
