import render from './render'

export default {
  name: 'TasksItem',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = [
        '235px', // renderTasksType
        '150px', // renderTasksState
        '120px', // renderTasksAmount
        '350px', // renderTasksTitle
        '150px' // renderOwner
      ]
      if (this.viewport.breakpoint.xl) {
        result.push('150px') // renderCreator
      }

      return result.join(' ')
    }
  },

  render
}
