import { cloneDeep, isEqual } from 'lodash'

export const proxy = (options = {}) => {
  const { type, defaultValue } = options

  const props = {
    value: {
      type: null,
      default: defaultValue
    },

    defaultValue: {
      type: null,
      default: defaultValue
    }
  }

  const data = function() {
    return {
      proxy: this._inputFilter(cloneDeep(this.value)) || cloneDeep(defaultValue)
    }
  }

  const watch = {
    value: {
      handler() {
        this.watchValueHandler()
      }
    },
    proxy: {
      handler() {
        this.watchProxyHandler()
      }
    }
  }

  const mounted = function() {
    this.receiveValue()
  }

  const methods = {
    _inputFilter: value => value,
    _outputFilter: value => value,

    receiveValue() {
      const value = this._inputFilter(cloneDeep(this.value))
      if (!isEqual(this.proxy, value)) {
        this.proxy = value
      }
    },
    transmitValue() {
      const proxy = this._outputFilter(cloneDeep(this.proxy))
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    },

    watchValueHandler() {
      this.receiveValue()
    },
    watchProxyHandler() {
      this.transmitValue()
    }
  }

  switch (type) {
    case 'string': {
      props.value.type = String
      props.defaultValue.type = String
      break
    }
    case 'number': {
      props.value.type = Number
      props.defaultValue.type = Number
      break
    }
    case 'boolean': {
      props.value.type = Boolean
      props.defaultValue.type = Boolean
      break
    }
    case 'array': {
      props.value.type = Array
      props.value.default = () => defaultValue || []
      props.defaultValue.type = Array
      props.defaultValue.default = () => defaultValue || []
      watch.value.deep = true
      watch.proxy.deep = true
      break
    }
    case 'object': {
      props.value.type = Object
      props.value.default = () => defaultValue || {}
      props.defaultValue.type = Object
      props.defaultValue.default = () => defaultValue || {}
      watch.value.deep = true
      watch.proxy.deep = true
      break
    }
  }

  return {
    props,
    data,
    watch,
    mounted,
    methods
  }
}
