import { borderStyles, moderationColors, moderationIcons, services } from '@/utils'
import { statusColors, statusIcons } from '@/components/services/sendingsModeration/utils'

import date from '@/components/serviceTemplate/item/date'
import sticker from '@/components/misc/sticker'

function renderStatus(h) {
  if (this.value.status) {
    return h(
      sticker,
      {
        props: {
          value: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.sendingsModeration}.labels.tasks.status.${this.value.status}`),
          label: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.sendingsModeration}.labels.status`),
          icon: statusIcons[this.value.status],
          color: statusColors[this.value.status],
          iconTooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.tasks.status.${this.value.status}`),
          borderStyle: borderStyles.dashed
        }
      }
    )
  }

  return h('div')
}

function renderModeration(h) {
  if (this.value.moderation) {
    return h(
      sticker,
      {
        props: {
          value: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.sendingsModeration}.labels.tasks.moderation.${this.value.moderation}`),
          label: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.sendingsModeration}.labels.moderation`),
          icon: moderationIcons[this.value.moderation],
          color: moderationColors[this.value.moderation],
          iconTooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.tasks.moderation.${this.value.moderation}`),
          borderStyle: borderStyles.dashed
        }
      }
    )
  }

  return h('div')
}

function renderSender(h) {
  if (this.viewport.breakpoint.lgUp) {
    if (this.value.sender) {
      return h(
        sticker,
        {
          props: {
            value: this.value.sender,
            label: this.getTranslate(`${services.sendingsModeration}.labels.sender`),
            borderStyle: borderStyles.dashed
          }
        }
      )
    }
  }

  return h('div')
}

function renderPattern(h) {
  if (this.value.pattern) {
    return h(
      sticker,
      {
        props: {
          value: this.value.pattern,
          label: this.getTranslate(`${services.sendingsModeration}.labels.text`),
          borderStyle: borderStyles.dashed
        }
      }
    )
  }

  return h('div')
}

function renderProvider(h) {
  if (this.viewport.breakpoint.mdUp) {
    if (this.value.providerId) {
      return h(
        sticker,
        {
          props: {
            value: this.value.providerId,
            label: this.getTranslate(`${services.sendingsModeration}.labels.providerId`),
            borderStyle: borderStyles.dashed,
            service: services.providers,
            pathToTitle: 'title'
          }
        }
      )
    } else {
      return h(
        sticker,
        {
          props: {
            value: this.getTranslate(`${services.sendingsModeration}.hints.provider`),
            label: this.getTranslate(`${services.sendingsModeration}.labels.providerId`),
            borderStyle: borderStyles.dashed
          }
        }
      )
    }
  }

  return h('div')
}

function renderDetails(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-task__details`,
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderStatus.call(this, h),
      renderModeration.call(this, h),
      renderSender.call(this, h),
      renderProvider.call(this, h),
      renderPattern.call(this, h)
    ]
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${services.sendingsModeration}-task`]: true
      },
      on: {
        click: () => {
          this.$emit('click')
        }
      }
    },
    [
      renderDetails.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
