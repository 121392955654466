import { serviceName } from '@/components/services/requisites/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.Owner,
        label: this.getTranslate(`${serviceName}.labels.owner`),
        icon: 'person',
        color: 'grey',
        pathToTitle: 'username'
      }
    }
  )
}
