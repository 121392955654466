import Vue from 'vue'

import { cloneDeep, get, pick } from 'lodash'
import { Macro } from '@sigma-legacy-libs/cache'

import { attach, globalErrorHandler, globalErrorProcessor } from '@/utils'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const cachedGetProvider = Cache.wrapWithCache(async (key, id) => {
  try {
    const { data } = await Vue.$GRequest.get('providers', id)
    if (data) {
      return data
    }
  } catch (error) {
    globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
  }
})

export const commonProperties = [
  'title',
  'comment',
  'properties',
  'replacements',
  'ServerId',
  'CredentialsId'
]

export async function inputFilter(result) {
  const getState = async id => {
    try {
      const { data } = await Vue.$GRequest.get('providers', id, { query: { state: true } })
      if (data && data.result) {
        return data.result
      }
    } catch (error) {
      globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
    }
  }

  if (Array.isArray(result.Providers) && result.Providers.length > 0) {
    result._Providers = cloneDeep(result.Providers)
    result.Providers = await Promise.all(result.Providers.map(async item => {
      const provider = await cachedGetProvider(item.id, item.id)
      const state = await getState(item.id)

      return {
        ...item,
        ...provider,
        state,
        $attach: true
      }
    }))
    result.Providers.sort((a, b) => (a.priority || 0) - (b.priority || 0))
  } else {
    result.Providers = []
  }

  return result
}

export async function outputFilter(result) {
  if (Array.isArray(result.Providers) && result.Providers.length > 0) {
    attach(result, 'Providers')
    result.Providers = result.Providers.map((item, index) => {
      const { id, $attach } = item

      return {
        id,
        priority: item.priority || index,
        $attach
      }
    })
  } else {
    result.Providers = undefined
  }

  return result
}

export const cloneProvider = async provider => {
  let { title, comment, properties, replacements, Server, ServerId, Credentials, CredentialsId } = pick(cloneDeep(provider), [ 'Server', 'Credentials', ...commonProperties ])

  const regex = /\((\d+)\)$/
  const match = title.match(regex)
  if (match) {
    const index = parseInt(match[1])
    title = title.replace(regex, `(${index + 1})`)
  } else {
    title = `${title} (1)`
  }

  if (!ServerId) {
    ServerId = get(Server, 'id')
  }
  if (!CredentialsId) {
    CredentialsId = get(Credentials, 'id')
  }

  return await Vue.$GRequest.create('providers', {
    title,
    comment,
    properties,
    replacements,
    ServerId,
    CredentialsId,
    isActive: true
  })
}
