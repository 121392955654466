import { TASK_STATES, TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, icons, services } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (this.value.type === TASK_TYPES['sendings:bulk']) {
    if (this.checkPermissions(`advanced.${services.tasks}.update`)) {
      const disabled = this.proxy.state === TASK_STATES.completed || this.proxy.state === TASK_STATES.failed

      return h(
        'g-menu',
        {
          props: {
            value: this.showMenu,
            closeOnContentClick: false,
            placement: !this.viewport.breakpoint.xs ? 'left' : 'top',
            offsetDistance: 8,
            rounded: true,
            disabled
          },
          on: {
            input: event => {
              this.showMenu = event
            }
          }
        },
        [
          h(
            button,
            {
              props: {
                label: [ this.proxy.data.cycle.batch, this.proxy.data.cycle.every ].join(' / '),
                mode: buttonModes.flat,
                icon: icons.speed,
                color: colors.primary,
                disabled
              },
              slot: 'activator'
            }
          ),

          h(
            'div',
            {
              class: 'faic pa-2 grid-gap--8'
            },
            [
              h(
                'div',
                {
                  class: 'grid grid-gap--8',
                  style: 'grid-template-columns: repeat(2, 70px)'
                },
                [
                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.data.cycle.batch,
                        label: this.getTranslate(`${services.tasks}.labels.cycle.batch`),
                        type: 'number',
                        step: 'any',
                        mode: buttonModes.outline,
                        dense: true,
                        rounded: true,
                        autofocus: true,
                        details: false
                      },
                      on: {
                        input: event => {
                          this.proxy.data.cycle.batch = event
                        }
                      }
                    }
                  ),

                  h(
                    'g-text-field',
                    {
                      props: {
                        value: this.proxy.data.cycle.every,
                        label: this.getTranslate(`${services.tasks}.labels.cycle.every`),
                        type: 'number',
                        step: 'any',
                        mode: buttonModes.outline,
                        dense: true,
                        rounded: true,
                        details: false
                      },
                      on: {
                        input: event => {
                          this.proxy.data.cycle.every = event
                        }
                      }
                    }
                  )
                ]
              ),

              h(
                button,
                {
                  props: {
                    mode: buttonModes.flat,
                    icon: icons.done,
                    loading: this.loading,
                    disabled: this.loading,
                    color: colors.primary
                  },
                  on: {
                    click: () => {
                      this.updateTask()
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    }
  }
}
