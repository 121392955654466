export default function(h) {
  return h(
    'router-link',
    {
      class: 'link link__button link--passive square--36',
      props: { to: this.to }
    },
    [
      h(
        'g-icon',
        {
          props: {
            value: this.icon,
            color: this.color,
            size: this.size
          }
        }
      )
    ]
  )
}
