import titleDivider from '@/components/title/divider'
import messageRuleGroupsTree from '@/components/trees/messageRules'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    [
      h(titleDivider, { props: { value: this.getTranslate('messageRuleGroups.subtitles.groups') } }),

      h(
        messageRuleGroupsTree,
        {
          props: {
            value: this.proxy.MessageRuleGroups
          },
          on: {
            input: event => {
              this.proxy.MessageRuleGroups = event
            }
          }
        }
      )
    ]
  )
}
