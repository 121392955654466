import generatorRow from '@/components/generator/row'
import rulesActionsDialogGeneratorsButtonsButton from '@/components/misc/rules/actions/dialog/generators/buttons/button'

export default function(h) {
  return h(
    generatorRow,
    {
      props: {
        value: this.proxy,
        defaultValue: {
          text: undefined,
          action: undefined
        }
      },
      scopedSlots: {
        row: ({ item }) => {
          return h(
            rulesActionsDialogGeneratorsButtonsButton,
            {
              props: {
                value: item
              },
              on: {
                input: event => {
                  item = event
                }
              }
            }
          )
        }
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
