import { services, sizes } from '@/utils'
import { countsColors, countsTypes } from '@/components/services/tasks/utils'

import tag from '@/components/tag'

function renderCount(h, type) {
  return h(
    tag,
    {
      props: {
        label: this.counts.values[type],
        color: countsColors[type],
        size: sizes.huge,
        as: 'button',
        tooltip: this.counts.tooltips[type]
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.sendings}-tasks__counts`
    },
    [ countsTypes.map(type => renderCount.call(this, h, type)) ]
  )
}
