import { colors, icons } from '../enums'

export const isActiveIcons = {
  true: icons.check_circle_outline,
  false: icons.highlight_remove
}

export const isActiveColors = {
  true: colors.success,
  false: colors.error
}
