import { cloneDeep } from 'lodash'
import { EMPTY_PROVIDERGROUPS, EMPTY_ROUTINGRULES } from '@sigma-legacy-libs/essentials/lib/constants'

import draggable from '@/components/misc/draggable'
import routingRulesForm from '@/components/services/routingRules/form'
import providerGroupsForm from '@/components/services/providerGroups/form'
import messageTypeIcon from '@/components/message/type/icon'

function renderSectionTitle(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      h(
        'g-icon',
        {
          class: 'mr-3',
          props: {
            value: 'subtitles',
            color: 'grey'
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.payload.title,
            label: this.getTranslate('routes.labels.title'),
            disabled: this.colsToShow > 1,
            mode: 'outline',
            required: true,
            autofocus: true,
            dense: true,
            details: false,
            rounded: true,
            clearable: true
          },
          on: {
            input: event => {
              this.payload.title = event
            }
          }
        }
      )
    ]
  )
}

function renderRoutingRuleRow(h, item, index) {
  const readonly = !!item.id

  return h(
    'div',
    {
      class: 'faic hover-parent hover-parent--opacity'
    },
    [
      h(
        'g-icon',
        {
          class: 'drag_handle',
          props: {
            value: 'drag_handle',
            color: 'grey'
          }
        }
      ),

      h(
        'div',
        {
          class: {
            'routingRules-row': true,
            'routingRules-row--read-only': readonly
          },
          on: {
            click: () => {
              if (!readonly) {
                this.payloads.routingRules = item
                this.routingRuleIndex = index
                this.colsToShow++
              }
            }
          }
        },
        [
          h(
            'div',
            {
              class: 'fz-14 lh-24 ff'
            },
            item.title
          ),

          h(
            'div',
            {
              class: {
                'fz-11': true,
                'text--grey': readonly,
                'text--warning': !item.id
              }
            },
            item.id || this.getTranslate('routingRules.hints.willBeCreated')
          )
        ]
      ),

      h(
        'g-button',
        {
          class: 'hover-child ma-0',
          props: {
            flat: true,
            icon: 'remove',
            color: 'error'
          },
          on: {
            click: () => {
              this.payload.RoutingRules.splice(index, 1)
            }
          }
        }
      )
    ]
  )
}

function renderSelectedRoutingRules(h) {
  if (Array.isArray(this.payload.RoutingRules) && this.payload.RoutingRules.length) {
    return h(
      draggable,
      {
        class: 'grid grid-cols--1 grid-gap--8 py-3',
        props: {
          value: this.payload.RoutingRules,
          handle: 'drag_handle'
        },
        scopedSlots: {
          item: (item, index) => {
            return renderRoutingRuleRow.call(this, h, item, index)
          }
        },
        on: {
          input: event => {
            this.payload.RoutingRules = event
          }
        }
      }
    )
  }
}

function renderSectionRoutingRules(h) {
  return h(
    'div',
    {
      class: 'faifs'
    },
    [
      h(
        'g-icon',
        {
          class: 'mr-3',
          props: {
            value: 'rule',
            color: 'grey'
          }
        }
      ),

      h(
        'div',
        {
          class: 'fc ff'
        },
        [
          h(
            'div',
            {
              class: 'fz-16 lh-24',
              style: 'padding-left: 12px'
            },
            this.getTranslate('routes.subtitles.routingRules')
          ),

          h(
            'div',
            {
              class: 'faic mt-3'
            },
            [
              h(
                'g-select',
                {
                  props: {
                    label: this.getTranslate('routes.labels.routingRules'),
                    service: 'routingRules',
                    query: { isActive: true },
                    itemsDisabled: this.payload.RoutingRules.map(({ id }) => id),
                    disabled: this.colsToShow > 1,
                    mode: 'outline',
                    dense: true,
                    rounded: true,
                    details: false,
                    autocomplete: true
                  },
                  on: {
                    input: event => {
                      this.addRoutingRuleFromSelect(event)
                    }
                  }
                }
              )
            ]
          ),

          renderSelectedRoutingRules.call(this, h)
        ]
      )
    ]
  )
}

function renderFirstColContent(h) {
  if (this.colsToShow === 1) {
    return h(
      'div',
      {
        class: 'grid grid-cols--1 pa-3'
      },
      [
        renderSectionTitle.call(this, h),
        renderSectionRoutingRules.call(this, h)
      ]
    )
  }
}

function renderFirstCol(h) {
  return h(
    'div',
    {
      class: 'fc ff',
      key: 'first-col'
    },
    [
      h(
        'div',
        {
          class: {
            'faic text--grey pa-3': true,
            'fz-21': this.colsToShow === 1,
            'fz-15': this.colsToShow > 1
          }
        },
        [
          h(
            'div',
            {
              class: {
                index: true,
                'index--small': this.colsToShow > 1
              }

            },
            1
          ),
          h(
            'div',
            {
              class: 'pl-3'
            },
            this.getTranslate('routes.titles.full.create.route')
          )
        ]
      ),

      renderFirstColContent.call(this, h)
    ]
  )
}

function renderSecondColContent(h) {
  if (this.colsToShow === 2) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        h(
          routingRulesForm,
          {
            class: 'pa-3',
            props: {
              value: this.payloads.routingRules,
              disabled: this.colsToShow > 2,
              type: this.type
            },
            on: {
              input: event => {
                this.payloads.routingRules = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderSecondCol(h) {
  if (this.colsToShow > 1) {
    return h(
      'div',
      {
        class: 'fc ff',
        key: 'second-col'
      },
      [
        h(
          'div',
          {
            class: {
              'faic text--grey pa-3': true,
              'fz-21': this.colsToShow === 2,
              'fz-15': this.colsToShow > 2
            }
          },
          [
            h(
              'div',
              {
                class: {
                  index: true,
                  'index--small': this.colsToShow > 2
                }
              },
              2
            ),
            h(
              'div',
              {
                class: 'pl-3'
              },
              this.getTranslate('routes.titles.full.create.routingRules')
            )
          ]
        ),

        renderSecondColContent.call(this, h)
      ]
    )
  }
}

function renderThirdCol(h) {
  if (this.colsToShow > 2) {
    return h(
      'div',
      {
        class: 'fc ff',
        key: 'third-col'
      },
      [
        h(
          'div',
          {
            class: 'grid grid-cols--1 pa-3'
          },
          [
            h(
              'div',
              {
                class: 'faic fz-19 text--grey'
              },
              [
                h('div', { class: 'index' }, 3),
                h(
                  'div',
                  {
                    class: 'pl-3'
                  },
                  this.getTranslate('routes.titles.full.create.providerGroups')
                )
              ]
            ),

            h(
              providerGroupsForm,
              {
                props: {
                  value: this.payloads.providerGroups,
                  type: this.type
                },
                on: {
                  input: event => {
                    this.payloads.providerGroups = event
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderFooter(h) {
  switch (this.colsToShow) {
    case 1: {
      return h(
        'g-card-actions',
        {
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel')
              },
              on: {
                click: () => {
                  this.$emit('showDialog', false)
                }
              }
            }
          ),

          h('div', { class: 'ff' }),

          h(
            'g-button',
            {
              class: 'mr-0',
              props: {
                flat: true,
                rounded: true,
                color: 'secondary',
                icon: 'add',
                label: this.getTranslate('routes.buttons.create.rule'),
                disabled: this.colsToShow > 1
              },
              on: {
                click: () => {
                  this.colsToShow += 1
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                disabled: !this.validation.routingGroups,
                label: this.getTranslate('misc.buttons.create'),
                color: 'primary'
              },
              on: {
                click: () => {
                  this.create()
                }
              }
            }
          )
        ]
      )
    }
    case 2: {
      return h(
        'g-card-actions',
        {
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                icon: 'keyboard_arrow_left',
                label: this.viewport.breakpoint.lgUp ? this.getTranslate('misc.buttons.back') : undefined,
                disabled: this.colsToShow > 2
              },
              on: {
                click: () => {
                  this.payloads.routingRules = cloneDeep(EMPTY_ROUTINGRULES)
                  this.colsToShow -= 1
                }
              }
            }
          ),

          h('div', { class: 'ff' }),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                color: 'secondary',
                icon: 'add',
                disabled: this.colsToShow > 2,
                label: this.getTranslate('routes.buttons.create.group')
              },
              on: {
                click: () => {
                  this.colsToShow += 1
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                color: 'primary',
                disabled: !this.validation.routingRules || this.colsToShow > 2,
                label: this.getTranslate('routes.buttons.inRoute')
              },
              on: {
                click: () => {
                  this.addRoutingRuleFromForm()
                }
              }
            }
          )
        ]
      )
    }
    case 3: {
      return h(
        'g-card-actions',
        {
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                icon: 'keyboard_arrow_left',
                label: this.viewport.breakpoint.lgUp ? this.getTranslate('misc.buttons.back') : undefined
              },
              on: {
                click: () => {
                  this.payloads.providerGroups = cloneDeep(EMPTY_PROVIDERGROUPS)
                  this.colsToShow -= 1
                }
              }
            }
          ),

          h('div', { class: 'ff' }),

          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                color: 'primary',
                disabled: !this.validation.providerGroups,
                label: this.getTranslate('routes.buttons.inRule')
              },
              on: {
                click: () => {
                  this.addProviderGroupToRuleFromForm()
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderContent(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.show,
        rounded: true,
        overflow: true,
        scroll: true,
        minHeight: 600,
        minWidth: 300,
        width: 'auto'
      },
      on: {
        input: event => {
          this.$emit('showDialog', event)
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'faic px-3 headline',
          slot: 'header'
        },
        [
          h(
            'div',
            {
              class: 'text--grey pr-2'
            },
            this.getTranslate('routes.titles.full.title')
          ),

          h(
            'div',
            {
              class: 'faic fjcc square--48'
            },
            [
              h(
                messageTypeIcon,
                {
                  props: {
                    value: this.type
                  }
                }
              )
            ]
          ),

          this.getTranslate(`sendings.types.${this.type}`)
        ]
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--0',
          style: { 'grid-template-columns': this.gridTemplateColumns }
        },
        [
          h(
            'transition-group',
            {
              props: { name: 'route-slide' }
            },
            [
              renderFirstCol.call(this, h),
              renderSecondCol.call(this, h),
              renderThirdCol.call(this, h)
            ]
          )
        ]
      ),

      renderFooter.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
