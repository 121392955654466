import { get } from 'lodash'

import { NOTIFICATIONS_CHANNELS_PER_TYPE } from '@sigma-legacy-libs/essentials/lib/schemas/notifications'

import { proxy } from '@/utils'

import render from './render'

export default {
  name: 'NotificationPanel',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    path: {
      type: String,
      default: undefined
    },

    loading: {
      type: Array,
      default: () => []
    },

    options: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    availableChannels() {
      let result
      Object.keys(NOTIFICATIONS_CHANNELS_PER_TYPE).map(type => {
        if (this.path.includes(type)) {
          result = NOTIFICATIONS_CHANNELS_PER_TYPE[type]
        }
      })

      return result
    },

    hasSettings() {
      const settings = get(this.value, 'settings', [])

      return Array.isArray(settings) && settings.length > 0
    }
  },

  methods: {
    update(channel) {
      if (this.proxy.channels.includes(channel)) {
        this.proxy.channels.splice(this.proxy.channels.indexOf(channel), 1)
      } else {
        this.proxy.channels.splice(this.proxy.channels.length, 0, channel)
      }
      if (this.proxy.channels.length === 0) {
        this.proxy.enabled = false
      }
    }
  },

  render
}
