import { isPlainObject, set } from 'lodash'

import { backendServerIP, isHEX, projectName } from '@/utils'

import schemas from '@sigma-legacy-libs/essentials/lib/schemas'

export default {
  namespaced: true,

  state: {
    global: {
      frontend: {
        title: 'Cabinet',
        colors: {
          primary: '#767676',
          secondary: '#939393',
          accent: '#C0C0C0',
          error: '#A9A9A9',
          info: '#8A8A8A',
          success: '#7E7E7E',
          warning: '#828282'
        },
        documents: {
          privacyPolicy: undefined,
          publicOffer: undefined
        },
        jivosite: undefined
      },
      payments: {
        methods: []
      },
      sendings: {
        sendernamesCheck: []
      },
      billings: {
        overdraft: 0
      },
      contacts: undefined
    }
  },

  mutations: {
    setGlobalSettings(state, settings) {
      state.global = settings
    }
  },

  actions: {
    async getGlobalSettings({ commit, dispatch, rootState }) {
      const { authentication } = rootState
      const { token } = authentication

      const headers = {}
      if (token) {
        headers.Authorization = token
      }

      const response = await fetch(`${backendServerIP}/api/settings`, {
        method: 'get',
        headers
      })

      if (!response.ok) {
        throw new Error(response.status)
      }

      const { settings, permissions } = await response.json()

      if (isPlainObject(settings)) {
        const result = {}

        for (const key in settings) {
          const value = settings[key]
          switch (key) {
            case 'frontend.colors': {
              const colors = schemas.site.SETTINGS_SITE_SCHEMA['frontend.colors'].$default
              const frontendColors = {}
              for (const color in colors) {
                frontendColors[color] = isHEX(value[color]) ? value[color] : colors[color]
              }

              set(result, key, frontendColors)
              break
            }

            case 'payments.methods': {
              let methods = await dispatch('payments/getPaymentsMethods', null, { root: true })
              if (!methods) {
                methods = value
                dispatch('payments/setPaymentsMethods', value, { root: true })
              }
              window.localStorage.setItem(`${projectName}:settings:${key}`, JSON.stringify(methods))
              set(result, key, methods)
              break
            }

            case 'frontend.title':
            case 'frontend.documents.privacyPolicy':
            case 'frontend.documents.publicOffer':
            case 'frontend.jivosite':
            case 'sendings.sendernamesCheck':
            case 'billings.overdraft':
            case 'billings.currency': {
              window.localStorage.setItem(`${projectName}:settings:${key}`, JSON.stringify(value))
              set(result, key, value)
              break
            }
          }
        }

        switch (window.location.hostname) {
          case 'localhost':
          case '52.29.25.132':
          case 'devonline.sigmasms.ru':
          case 'online.sigmasms.ru': {
            set(result, 'frontend.title', schemas.site.SETTINGS_SITE_SCHEMA['frontend.title'].$default)
            result.contacts = {
              phones: [
                {
                  value: '8-800-333-16-50',
                  type: 'fixed'
                },
                {
                  value: '8-812-313-24-88',
                  type: 'fixed'
                },
                {
                  value: '8-495-225-50-36',
                  type: 'fixed'
                }
              ],
              emails: [ { value: 'support@sigmasms.ru' } ],
              websites: [ { value: 'online.sigmasms.ru' } ]
            }
            break
          }
          case 'online.mirsms.ru': {
            set(result, 'frontend.title', 'Мир SMS')
            result.contacts = {
              phones: [
                {
                  value: '8-800-333-35-85',
                  type: 'fixed'
                },
                {
                  value: '8-812-385-56-50',
                  type: 'fixed'
                },
                {
                  value: '8-495-649-83-50',
                  type: 'fixed'
                }
              ],
              emails: [ { value: 'support@mirsms.ru' } ],
              websites: [ { value: 'online.mirsms.ru' } ]
            }
            break
          }
          case 'online.imlink.io': {
            set(result, 'frontend.title', 'Imlink')
            dispatch('locale/setLocale', 'en', { root: true })
            dispatch('locale/setLocales', [ 'en' ], { root: true })
            result.contacts = {
              emails: [
                { value: 'sale@imlink.io' },
                { value: 'support@imlink.io' }
              ]
            }
            break
          }
        }

        commit('setGlobalSettings', result)
      }

      return {
        settings,
        permissions
      }
    }
  },

  getters: {
    global: state => state.global
  }
}
