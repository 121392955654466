export default {
  title: 'Password recovery',

  labels: {
    login: 'Username or e-mail',
    password: 'Password'
  },

  hints: {
    recover: 'Forgot your password?',
    password: 'Enter your new password and click the reset button'
  },

  buttons: {
    reset: 'Reset'
  },

  contents: {
    validation: {
      request: 'Instructions on password recovery was sent to the e-mail address you specified during registration.'
    }
  }
}
