import { buttonModes, colors, getLocaleTimeString, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'
import svgIcon from '@/components/icon/svg'
import tag from '@/components/tag'

const className = 'clock'

function renderTime(h) {
  return h(
    'div',
    {
      class: `${className}-time`
    },
    [ getLocaleTimeString(this.date) ]
  )
}
function renderTimezone(h) {
  return h(
    tag,
    {
      props: {
        label: this.timezone,
        color: colors.grey,
        size: sizes.tiny,
        mode: buttonModes.filled
      }
    }
  )
}
function renderContent(h) {
  return h(
    'div',
    {
      class: `${className}-content`
    },
    [
      renderTime.call(this, h),
      renderTimezone.call(this, h)
    ]
  )
}

function renderIcon(h) {
  return h(
    squircle,
    {
      props: {
        color: colors.white,
        size: sizes.medium
      }
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: 'clock',
            color: colors.white,
            size: 32
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: className
    },
    [
      renderContent.call(this, h),
      renderIcon.call(this, h)
    ]
  )
}
