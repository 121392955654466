import ProvidersForm from '@/components/services/providers/form'

import { states } from '@/utils'

import { serviceName } from '@/components/services/providers/utils'

function renderButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'edit',
        flat: true
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    }
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'headline pa-3',
          slot: 'header'
        },
        this.getTranslate(`${serviceName}.titles.update`)
      ),

      h(
        ProvidersForm,
        {
          props: {
            value: this.restData[serviceName].get.data
          },
          on: {
            input: event => {
              this.restData[serviceName].get.data = event
            },
            validation: event => {
              this.restData[serviceName].update.isValid = event
            }
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcsb',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.cancel'),
                disabled: this.restData[serviceName].update.state === states.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            'g-button',
            {
              props: {
                rounded: true,
                depressed: true,
                label: this.getTranslate('misc.buttons.update'),
                color: 'primary',
                loading: this.restData[serviceName].update.state === states.loading,
                disabled: this.restData[serviceName].update.state === states.loading || !this.restData[serviceName].update.isValid
              },
              on: {
                click: () => {
                  this.update()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {},
    [
      renderButton.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
