import { isHEX, numberToPxOrString } from '@/utils'

function renderPreLoader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'g-button__loading'
      },
      [
        h(
          'g-progress',
          {
            props: {
              indeterminate: true,
              size: 24
            }
          }
        )
      ]
    )
  }
}

function renderIconHolder(h, icon) {
  if (icon) {
    return h(
      'div',
      {
        class: {
          'g-button__icon': true,
          'square--28': icon && this.small,
          'square--36': icon && !this.small && !this.large,
          'square--44': icon && this.large
        }
      },
      [ renderIcon.call(this, h, icon) ]
    )
  }
}

function renderIcon(h, icon) {
  return h(
    'g-icon',
    {
      props: {
        value: icon,
        library: this.iconLibrary,
        size: this.small ? 18 : this.large ? 26 : this.fab ? 30 : 22
      }
    }
  )
}

function renderDialogSlot() {
  if (typeof this.$scopedSlots.dialog === 'function') {
    return this.$scopedSlots.dialog()
  }

  return this.$slots.dialog
}

function renderContent(h) {
  if (this.label || this.$slots.default) {
    return h(
      'div',
      {
        class: 'g-button__content'
      },
      [ this.label || this.$slots.default ]
    )
  }
}

export default function(h) {
  return h(
    'button',
    {
      attrs: {
        tabindex: this.tabindex,

        disabled: this.disabled,
        autofocus: this.autofocus,

        form: this.form,
        formaction: this.formaction,
        formenctype: this.formenctype,
        formmethod: this.formmethod,
        formnovalidate: this.formnovalidate,
        formtarget: this.formtarget,

        name: this.name,
        type: this.type,

        value: this.value
      },
      class: {
        'g-button': true,

        'g-button--tiny': this.tiny,
        'g-button--small': this.small,
        'g-button--large': this.large,

        'g-button--fab': this.fab,
        'g-button--flat': this.flat,
        'g-button--block': this.block,
        'g-button--round': this.round ? true : (!!this.icon || !!this.prefix || !!this.suffix) && (!this.label && !this.$slots.default),
        'g-button--rounded': this.rounded,
        'g-button--outline': this.outline,
        'g-button--depressed': this.depressed,

        'g-button--prefix': this.prefix || this.icon,
        'g-button--suffix': this.suffix,

        'g-button--toolbar': this.toolbar,

        'g-button--fixed': this.fixed,
        'g-button--absolute': this.absolute,

        'g-button--top': typeof this.top === 'boolean' && this.top,
        'g-button--bottom': typeof this.bottom === 'boolean' && this.bottom,
        'g-button--left': typeof this.left === 'boolean' && this.left,
        'g-button--right': typeof this.right === 'boolean' && this.right,

        'g-button--disabled': this.disabled,
        'g-button--loading': this.loading,

        [`g-button--${this.type}`]: !!this.type,
        [`g-button--${this.color}`]: !!this.color && !isHEX(this.color)
      },
      style: {
        color: isHEX(this.color) ? this.color : null,
        top: numberToPxOrString(this.top),
        bottom: numberToPxOrString(this.bottom),
        left: numberToPxOrString(this.left),
        right: numberToPxOrString(this.right)
      },
      on: this.$eventHandlers
    },
    [
      renderIconHolder.call(this, h, this.prefix || this.icon),
      renderContent.call(this, h),
      renderIconHolder.call(this, h, this.suffix),
      renderPreLoader.call(this, h),
      renderDialogSlot.call(this, h)
    ]
  )
}
