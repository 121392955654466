export const getHz = value => {
  value = parseFloat(value)
  if (typeof value === 'number' && !isNaN(value)) {
    return `${value} Hz`
  }
}

export const getBit = value => {
  value = parseFloat(value)
  if (typeof value === 'number' && !isNaN(value)) {
    return `${value} bit`
  }
}
