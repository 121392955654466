import componentNotFound from '@/components/misc/componentNotFound'

export default function(h, { serviceName }) {
  try {
    return h(
      require(`@/components/services/${serviceName}/item/${this.view}`).default,
      {
        props: {
          value: this.value,
          size: this.size,
          sensitivity: this.sensitivity
        },
        key: this.keyProp
      }
    )
  } catch (error) {
    return h(componentNotFound)
  }
}
