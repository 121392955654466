import { get } from 'lodash'

import { moderationColors, permissionPresets, services } from '@/utils'

import sticker from '@/components/misc/sticker'

function renderComment(h, comment, type) {
  if (comment) {
    return h(
      sticker,
      {
        props: {
          value: comment,
          label: this.getTranslate(`${services.sendernames}.labels.meta.${type}`),
          borderStyle: 'dashed',
          borderColor: type === 'adminComment' && comment.length ? moderationColors[this.value.moderation] : undefined,
          copy: type === 'comment' ? true : undefined
        }
      }
    )
  }
}

function renderCommentFromUser(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.get`, permissionPresets.resellerUp)) {
    return renderComment.call(this, h, get(this.value, 'meta.comment'), 'comment')
  }
}

function renderCommentFromAdmin(h) {
  if (this.showCommentFromAdminOrReseller) {
    return renderComment.call(this, h, this.commentFromAdminOrReseller, 'adminComment')
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.sendernames}-item__comments`
    },
    [
      renderCommentFromUser.call(this, h),
      renderCommentFromAdmin.call(this, h)
    ]
  )
}
