import { components } from '@/utils'

import overflowString from '@/components/misc/overflowString'

function renderLabel(h) {
  return h(
    overflowString,
    {
      class: {
        [`${components.tag}__label`]: true,
        [`${components.tag}__label--${this.labelColor}`]: !!this.labelColor
      },
      props: {
        value: this.$label,
        font: this.$font
      }
    }
  )
}

export default function(h) {
  if (this.$label) {
    return h(
      'div',
      {
        class: this.$class,
        directives: this.$directives,
        on: { click: this.eventHandler }
      },
      [ renderLabel.call(this, h) ]
    )
  }
}
