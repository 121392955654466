import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'

import { buttonModes, colors, inputModes } from '@/utils'

import button from '@/components/button'
import rulesActionsDialogGeneratorsText from '@/components/misc/rules/actions/dialog/generators/text'
import rulesActionsDialogGeneratorsFallbacks from '@/components/misc/rules/actions/dialog/generators/fallbacks'
import rulesActionsDialogGeneratorsButtons from '@/components/misc/rules/actions/dialog/generators/buttons'
import rulesActionsDialogGeneratorsModeration from '@/components/misc/rules/actions/dialog/generators/moderation'
import rulesCommonsType from '@/components/misc/rules/commons/type'

function renderHeaderContent(h) {
  if (this.tag) {
    if (this.tagEdit && !this.readonly) {
      return h(
        'g-select',
        {
          props: {
            value: this.tag,
            label: this.getTranslate(`conditions.tags.${this.tag}`),
            items: Actions.tagsOnly.map(tag => {
              return {
                title: this.getTranslate(`conditions.tags.${tag}`),
                value: tag
              }
            }),
            itemsDisabled: this.tagDisabled,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            required: true
          },
          on: {
            input: event => {
              this.$emit('tag', event)
            }
          }
        }
      )
    } else {
      return h(
        'div',
        {
          class: 'headline'
        },
        this.getTranslate(`conditions.tags.${this.tag}`)
      )
    }
  }
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'pa-3',
      slot: 'header'
    },
    [ renderHeaderContent.call(this, h) ]
  )
}

function renderMainComponent(h) {
  if (this.tag) {
    let component = 'g-select'
    let props

    const defaultProps = {
      value: this.proxy,
      label: this.getTranslate(`conditions.tags.${this.tag}`),
      mode: inputModes['line-label'],
      disabled: this.disabled,
      dense: true,
      details: false,
      rounded: true,
      autofocus: true,
      clearable: true,
      readonly: this.readonly
    }

    switch (this.tag) {
      case 'text': {
        component = rulesActionsDialogGeneratorsText
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'fallbacks': {
        component = rulesActionsDialogGeneratorsFallbacks
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'type': {
        component = rulesCommonsType({ type: 'string' })
        props = Object.assign({}, defaultProps, { value: this.proxy })
        break
      }

      case 'sender': {
        props = Object.assign({}, defaultProps, {
          service: 'sendernames',
          itemTitle: 'name',
          itemValue: 'name',
          multiple: true,
          combobox: true,
          autocomplete: true
        })
        break
      }

      case 'moderation': {
        component = rulesActionsDialogGeneratorsModeration
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'buttons': {
        component = rulesActionsDialogGeneratorsButtons
        props = Object.assign({}, { value: this.proxy })
        break
      }

      default: {
        props = Object.assign({}, defaultProps, { value: this.proxy })
        break
      }
    }

    return h(
      component,
      {
        props,
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}
function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid pa-2'
    },
    [ renderMainComponent.call(this, h) ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            label: this.getTranslate('misc.buttons.close')
          },
          on: {
            click: () => {
              this.$emit('dialog', false)
            }
          }
        }
      ),

      h('div', { class: 'ff' }),

      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            label: this.getTranslate('misc.buttons.clear'),
            color: colors.error
          },
          on: {
            click: () => {
              this.proxy = undefined
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.save'),
            color: colors.primary,
            disabled: this.readonly
          },
          on: {
            click: () => {
              this.$emit('input', this.proxy)
              this.$emit('dialog', false)
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.show,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: 600
      },
      on: {
        input: event => {
          this.$emit('dialog', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
