import { buttonModes, components, icons, inputModes, isHEX } from '@/utils'

import sticker from '@/components/misc/sticker'
import button from '@/components/button'

function renderSticker(h) {
  return h(
    sticker,
    {
      props: {
        color: this.color,
        label: this.label,
        value: this.proxy || this.defaultValue,
        icon: icons.color_lens,
        copy: true
      }
    }
  )
}

function renderInput(h) {
  return h(
    components['text-field'],
    {
      props: {
        value: this.proxy,
        label: this.label,
        defaultValue: this.defaultValue,
        error: !isHEX(this.proxy) ? this.getTranslate('commons.errors.hex') : undefined,
        mode: inputModes.outline,
        flat: true,
        dense: true,
        rounded: true,
        clearable: true,
        details: false,
        autofocus: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
function renderButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.edit,
        color: this.color,
        mode: buttonModes.flat
      },
      slot: 'activator'
    }
  )
}
function renderMenu(h) {
  return h(
    'g-menu',
    {
      props: {
        closeOnContentClick: false,
        offsetDistance: 8,
        placement: 'bottom-end',
        rounded: true,
        maxWidth: 300
      }
    },
    [
      renderButton.call(this, h),
      renderInput.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      renderSticker.call(this, h),
      renderMenu.call(this, h)
    ]
  )
}
