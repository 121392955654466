import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { allowedSendingTypesForImage, getLocaleDateString, getLocaleTimeString } from '@/utils'

import OneRowString from '@/components/misc/OneRowString'
import MessageImage from '@/components/message/image'

const _ = { get: require('lodash/get') }

function renderSender(h) {
  const sender = _.get(this.value, 'payload.sender')
  if (sender) {
    return h(
      OneRowString,
      {
        class: 'sendings-message__title',
        props: { value: sender }
      }
    )
  }
}

function renderMenu(h) {
  if (this.value.type !== 'viber') {
    return [
      h('div', { class: 'ff' }),
      h(
        'g-icon',
        {
          class: 'px-3',
          props: {
            value: 'more_vert',
            color: 'white'
          }
        }
      )
    ]
  }
}

function renderHeadText(h) {
  const sender = _.get(this.value, 'payload.sender')
  if (sender && this.value.type === 'viber') {
    return h(
      'div',
      {
        class: 'sendings-message__head sendings-message__head--second'
      },
      [
        h(
          OneRowString,
          {
            class: 'sendings-message__head-text',
            props: { value: [ 'Messages from', sender ].join(' ') }
          }
        ),

        h(
          OneRowString, {
            class: 'sendings-message__head-subtext',
            props: { value: 'Why am I seeing this?' }
          }
        )
      ]
    )
  }
}

function renderText(h) {
  const text = _.get(this.value, 'payload.text')
  if (text) {
    return h(
      'div',
      {
        class: 'sendings-message__text',
        domProps: { innerHTML: text.replace(/\n/g, '<br>') }
      }
    )
  }
}

function renderImage(h) {
  if (~allowedSendingTypesForImage.indexOf(this.value.type)) {
    const image = _.get(this.value, 'payload.image')
    if (image) {
      return h(
        'div',
        {
          class: 'sendings-message__image'
        },
        [
          h(
            'div',
            {
              class: 'sendings-message__image-src'
            },
            [
              h(
                MessageImage,
                {
                  props: {
                    image,
                    owner: this.account.id
                  }
                }
              )
            ]
          )
        ]
      )
    }
  }
}

function renderButton(h) {
  if (this.value.type === SENDING_TYPES.viber) {
    const text = _.get(this.value, 'payload.button.text')
    const url = _.get(this.value, 'payload.button.url')
    if (text && url) {
      return h('div', { class: 'sendings-message__button' }, text)
    }
  }
}

export default function(h) {
  const sender = _.get(this.value, 'payload.sender', '')
  const firstCharSender = sender.charAt(0)

  return h(
    'div',
    {
      class: {
        'sendings-message': true,
        [`sendings-message--${this.value.type}`]: true
      }
    },
    [
      h(
        'div',
        {
          class: 'sendings-message__head'
        },
        [
          h(
            'g-icon',
            {
              class: 'pl-3 pr-3',
              props: {
                value: 'arrow_back',
                color: 'white'
              }
            }
          ),

          renderSender.call(this, h),
          renderMenu.call(this, h)
        ]
      ),

      renderHeadText.call(this, h),

      h(
        'div',
        {
          class: 'sendings-message__today'
        },
        [ getLocaleDateString(new Date()) ]
      ),

      h(
        'div',
        {
          class: 'faifs'
        },
        [
          h(
            'div',
            {
              class: 'sendings-message__avatar'
            },
            firstCharSender
          ),

          h(
            'div',
            {
              class: 'sendings-message__message'
            },
            [ renderText.call(this, h), renderImage.call(this, h), renderButton.call(this, h) ]
          )
        ]
      ),

      h(
        'div',
        {
          class: 'sendings-message__time'
        },
        getLocaleTimeString(new Date())
      )
    ]
  )
}
