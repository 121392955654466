import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

const defaultValue = undefined

const _ = { isEqual: require('lodash/isEqual') }

export default {
  name: 'GeneratorText',

  mixins: [ proxy({ type: 'array' }) ],

  methods: {
    _inputFilter(data) {
      if (!Array.isArray(data)) {
        data = []
      }
      if (data.length === 0) {
        data.push(defaultValue)
      }

      return data
    },

    add() {
      this.proxy.push(defaultValue)
    },
    remove(index) {
      this.proxy.splice(index, 1)
      if (this.proxy.length === 0) {
        this.add()
      }
    },

    receiveValue() {
      const value = this._inputFilter(this.value)
      if (!_.isEqual(this.proxy, value)) {
        this.proxy = value
      }
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!_.isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  },

  render
}
