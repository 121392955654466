import status from '@/components/services/sendernames/item/moderation/status'

export default function(h) {
  return h(
    status,
    {
      props: {
        value: this.value
      }
    }
  )
}
