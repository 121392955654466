import { SENDINGSMODERATION_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, icons } from '@/utils'

export const statusIcons = {
  [SENDINGSMODERATION_STATUS.init]: icons.add_circle_outline,
  [SENDINGSMODERATION_STATUS.processing]: icons.access_time,
  [SENDINGSMODERATION_STATUS.success]: icons.check_circle_outline,
  [SENDINGSMODERATION_STATUS.error]: icons.error_outline
}

export const statusColors = {
  [SENDINGSMODERATION_STATUS.init]: colors.secondary,
  [SENDINGSMODERATION_STATUS.processing]: colors.info,
  [SENDINGSMODERATION_STATUS.success]: colors.success,
  [SENDINGSMODERATION_STATUS.error]: colors.error
}

