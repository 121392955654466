import { proxy } from '@/utils'

import render from './render'

export default {
  name: 'NotificationsSMSSenderUserId',

  mixins: [ proxy() ],

  props: {
    loading: Boolean
  },

  render
}
