export default {
  title: 'Отладка',

  titles: {
    browser: 'Браузер',
    ws: 'WebSocket',
    account: 'Аккаунт',
    sendings: 'Последнее отправление',

    site: {
      address: 'Адрес сайта'
    }
  },

  labels: {
    version: 'Версия платформы',
    date: 'Дата и время открытия страницы',
    viewport: 'Размер окна',
    host: 'Хост',
    protocol: 'Протокол',

    ws: {
      connection: 'Соединение',
      error: 'Ошибка'
    },

    frontend: {
      title: 'Название платформы'
    },

    browser: {
      name: 'Название',
      version: 'Версия'
    },

    account: {
      username: 'Имя пользователя',
      balance: 'Баланс',
      id: 'ID',
      sendingTypes: 'Разрешённые типы отправлений'
    },

    sending: {
      id: 'ID',
      recipient: 'Получатель',
      type: 'Тип отправления'
    }
  },

  ws: {
    connected: 'Подключено',
    disconnected: 'Отключено'
  }
}
