export const timeoutAction = {
  props: {
    timeoutAction: {
      type: Number,
      default: 0
    },
    timeoutActionHandler: {
      type: Function,
      default: () => null
    }
  },

  data() {
    return {
      _timeoutInterval: null,
      _timeoutSeconds: 0
    }
  },

  computed: {
    timeoutPercentage() {
      return this.timeoutAction && this._data._timeoutSeconds / this.timeoutAction * 100
    }
  },

  beforeDestroy() {
    this._stopTimeoutAction()
  },

  methods: {
    _startTimeoutAction() {
      if (this.timeoutAction) {
        this._stopTimeoutAction()
        this._data._timeoutInterval = setInterval(() => {
          this._data._timeoutSeconds++
          if (this._data._timeoutSeconds > this.timeoutAction) {
            this._stopTimeoutAction()
            this._timeoutActionHandler()
          }
        }, 1000)
      }
    },
    _stopTimeoutAction() {
      if (this._data._timeoutInterval) {
        clearInterval(this._data._timeoutInterval)
        this._data._timeoutSeconds = 0
      }
    },
    _timeoutActionHandler() {
      return this.timeoutActionHandler()
    }
  }
}

export default { timeoutAction }
