import { getLocaleTimeString } from '@/utils'

function renderItem(h, message, long) {
  if (message && message.id) {
    return h(
      'div',
      {
        class: {
          'online-message__item': true,
          'online-message__item--long': long,
          [`online-message__item--${message.state}`]: true
        },
        on: {
          click: () => {
            this.goToSendings(message, long)
          }
        }
      },
      Object.entries(message).map(([ key, value ]) => {
        if (key === 'createdAt') {
          return h('span', { class: `online-message__item-value online-message__item-value--${key}` }, `[${getLocaleTimeString(value)}]`)
        }

        return h('span', { class: `online-message__item-value online-message__item-value--${key}` }, value)
      })
    )
  }
}

function renderList(h) {
  if (Array.isArray(this.messages)) {
    if (this.messages.length) {
      return h(
        'div',
        {
          class: 'online-message__list'
        },
        this.messages.map(message => {
          return renderItem.call(this, h, message, true)
        })
      )
    } else {
      return h('g-empty')
    }
  }
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        fullscreen: this.fullscreen,
        rounded: !this.fullscreen,
        maxWidth: this.viewport.breakpoint.mdUp ? '1000px' : '100%'
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'online-message__dialog-header',
          slot: 'header'
        },
        [
          h(
            'div',
            {
              class: 'online-message__dialog-header-title'
            },
            this.getTranslate('commons.titles.online.messages')
          ),

          h(
            'div',
            {
              class: 'online-message__dialog-header-actions'
            },
            [
              h(
                'g-text-field',
                {
                  props: {
                    value: this.limit,
                    label: this.getTranslate('commons.labels.limit'),
                    type: 'number',
                    mode: 'outline',
                    dense: true,
                    rounded: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      this.limit = event
                    }
                  }
                }
              ),
              h(
                'g-button',
                {
                  class: 'ma-0',
                  props: {
                    icon: this.pause ? 'play_arrow' : 'pause',
                    flat: true,
                    rounded: true
                  },
                  on: {
                    click: () => {
                      this.pause = !this.pause
                    }
                  }
                }
              ),
              h(
                'g-button',
                {
                  class: 'ma-0',
                  props: {
                    icon: 'fullscreen',
                    flat: true,
                    rounded: true
                  },
                  on: {
                    click: () => {
                      this.fullscreen = !this.fullscreen
                    }
                  }
                }
              ),
              h(
                'g-button',
                {
                  class: 'ma-0',
                  props: {
                    icon: 'clear',
                    flat: true,
                    rounded: true
                  },
                  on: {
                    click: () => {
                      this.showDialog = false
                    }
                  }
                }
              )
            ]
          )
        ]
      ),

      renderList.call(this, h)
    ]
  )
}

function renderMessage(h) {
  return h(
    'div',
    {
      class: 'online-message',
      on: {
        click: () => {
          this.showDialog = true
        }
      }
    },
    [ renderItem.call(this, h, this.message) ]
  )
}

export default function(h) {
  if (this.validation) {
    return h(
      'div',
      {
        class: 'online'
      },
      [
        renderMessage.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}
