import render from './render'

export default {
  name: 'Item',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    filter: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    hasOperator() {
      const operator = this.value.operator

      return operator && operator !== null && typeof operator === 'string'
    },

    gridTemplateColumns() {
      const result = [ 'minmax(70px, 135px)' ]

      if (this.viewport.breakpoint.smUp) {
        result.push('150px')
      }

      result.push('minmax(140px, 1fr)')

      if (this.viewport.breakpoint.mdUp && this.hasOperator) {
        result.push('120px')
      }

      if (this.viewport.breakpoint.xl) {
        result.push('auto', 'repeat(2, 140px')
      } else {
        result.push('38px')
      }

      return result.join(' ')
    }
  },

  render
}

