import { colors } from '@/utils'

import render from './render'

export default {
  name: 'Preloader',

  props: {
    value: {
      type: Boolean,
      default: false
    },

    size: {
      type: Number,
      default: 24
    },

    count: {
      type: Number,
      default: 3
    },

    color: {
      type: String,
      default: colors.description,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    }
  },

  data() {
    return {
      currentIndex: 0,
      interval: undefined
    }
  },

  computed: {
    gridTemplateColumns() {
      return `repeat(${this.count}, ${this.size}px)`
    },

    gap() {
      return `${this.size / 4}px`
    },

    $size() {
      return `${this.size}px`
    }
  },

  mounted() {
    this.interval = setInterval(() => {
      this.currentIndex = this.currentIndex === this.count - 1 ? 0 : this.currentIndex + 1
    }, 500)
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  render
}
