import proxy from '@sigma-legacy-libs/g-proxy'

import { upperFirst } from 'lodash'

import render from './render'

export default function(options) {
  return {
    name: options.path.split('.').map(upperFirst).join(''),

    mixins: [ proxy() ],

    render(h) {
      return render.call(this, h, options)
    }
  }
}
