import { upperFirst } from 'lodash'

import { colors, components, getLocaleDateString, getPrice, icons, services } from '@/utils'

import buttonCopy from '@/components/button/copy'
import buttonRefresh from '@/components/button/refresh'
import pagination from '@/components/misc/pagination'
import sticker from '@/components/misc/sticker'
import preloader from '@/components/misc/preloader'
import filter from '@/components/services/referrals/filter'

function renderLastMonthSum(h) {
  const date = new Date()
  date.setMonth(date.getMonth() - 1)

  return h(
    sticker,
    {
      props: {
        value: getPrice(this.lastMonthSum),
        label: upperFirst(date.toLocaleString(this.locale, { month: 'long' })),
        loading: this.loading,
        iconSVG: this.globalSettings.billings.currency,
        color: colors.grey
      }
    }
  )
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'referrals-buttons'
    },
    [
      h(
        buttonCopy,
        {
          props: {
            value: this.referralCode,
            snackbar: {
              text: this.getTranslate('users.snackbars.refCopied'),
              type: colors.success
            },
            tooltip: this.getTranslate('users.tooltips.copy.referral.code')
          }
        }
      ),
      h(
        buttonCopy,
        {
          props: {
            value: new URL(`#/registration?ref=${this.referralCode}`, window.location.origin).href,
            icon: icons.link,
            snackbar: {
              text: this.getTranslate('users.snackbars.linkCopied'),
              type: colors.success
            },
            tooltip: this.getTranslate('users.tooltips.copy.referral.link')
          }
        }
      )
    ]
  )
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: 'referrals-info'
    },
    [
      renderLastMonthSum.call(this, h),
      renderButtons.call(this, h)
    ]
  )
}

function renderDateTimeFilter(h) {
  return h(
    filter,
    {
      props: {
        value: this.restData[services.billings].find.filter
      },
      on: {
        input: event => {
          this.restData[services.billings].find.filter = event
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'referrals-header'
    },
    [
      renderInfo.call(this, h),
      renderDateTimeFilter.call(this, h)
    ]
  )
}

function renderPagination(h) {
  if (this.restData[services.billings].find.pagination.total) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 fjcfe faic pa-2',
        style: { 'grid-template-columns': '1fr auto' }
      },
      [
        h(
          pagination,
          {
            props: {
              value: this.restData[services.billings].find.pagination,
              service: services.billings
            },
            on: {
              input: event => {
                this.restData[services.billings].find.pagination = event
              }
            }
          }
        ),

        h(
          buttonRefresh,
          {
            props: {
              state: this.restData[services.billings].find.state,
              shortClickMethod: () => this.rest[services.billings].find(),
              longClickMethod: () => this.rest[services.billings].find({}, { noCache: true })
            }
          }
        )
      ]
    )
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-5'
    },
    [
      h(
        preloader,
        {
          props: {
            value: true
          }
        }
      )
    ]
  )
}
function renderContent(h) {
  if (this.restData[services.billings].find.state === 'ready') {
    return h(
      components.table,
      {
        props: {
          value: this.restData[services.billings].find.order,
          items: this.restData[services.billings].find.data,
          headers: this.headers,
          hideHead: this.restData[services.billings].find.pagination.total < 1,
          noDataText: this.getTranslate('referral.no.data.text')
        },
        scopedSlots: {
          items: ({ item }) => {
            return [
              h(
                'tr',
                {
                  key: item.id
                },
                [
                  h(
                    'td',
                    {
                      class: 'w--100 pl-2 pr-2 text-left'
                    },
                    [ item.data.hidden.referredUser ]
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text-right'
                    },
                    [ getPrice(item.amount) ]
                  ),

                  h(
                    'td',
                    {
                      class: 'pl-2 pr-2 text--grey text-right',
                      style: { 'white-space': 'pre' }
                    },
                    [ getLocaleDateString(item.createdAt) ]
                  )
                ]
              )
            ]
          }
        }
      }
    )
  } else {
    return renderPreloader.call(this, h)
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'referrals'
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h),
      renderPagination.call(this, h)
    ]
  )
}
