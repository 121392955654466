import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, debounce, get, upperFirst } from 'lodash'
import { EMPTY_USERS, REQUISITE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { getRUDate, globalErrorHandler, globalErrorProcessor, isUUID, isValidEmail, isValidPhone } from '@/utils'
import { bikRegExp, indexRegExp, innRegExp, ksRegExp, ogrnRegExp, rsRegExp, serviceName } from '@/components/services/requisites/utils'

import render from './render'

const apiPrefix = '/n/dadata'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    User: {
      type: Object,
      default: () => cloneDeep(EMPTY_USERS)
    }
  },

  data() {
    return {
      contactMatches: true,

      Owner: undefined,

      suggestion: {
        inn: undefined,
        bank: undefined,
        address: undefined,
        fio: undefined
      },

      suggestions: {
        inn: [],
        bank: [],
        address: [],
        fio: []
      },

      basis: {
        nominative: [ 'Устав', 'Свидетельство о государственной регистрации' ],
        genitive: [ 'Устава', 'Свидетельства о государственной регистрации' ]
      },

      loading: {
        Owner: false,
        inn: false,
        bank: false,
        address: false,
        fio: false
      }
    }
  },

  computed: {
    phoneValidation() {
      if (this.proxy.contact.phone && !isValidPhone(this.proxy.contact.phone)) {
        return this.getTranslate(`${serviceName}.errors.types.phoneNumber`)
      }
    },
    emailValidation() {
      if (this.proxy.contact.email && !isValidEmail(this.proxy.contact.email)) {
        return this.getTranslate(`${serviceName}.errors.contact.email`)
      }
    },
    contactValidation() {
      let result = true

      if (!this.proxy.contact.lastName) {
        result = false
      }
      if (!this.proxy.contact.firstName) {
        result = false
      }
      if (!this.proxy.contact.email || !!this.emailValidation) {
        result = false
      }
      if (!this.proxy.contact.phone || !!this.phoneValidation) {
        result = false
      }

      return result
    },
    signerValidation() {
      let result = true

      if (!this.proxy.signer.fullName.nominative) {
        result = false
      }
      if (!this.proxy.signer.position.nominative) {
        result = false
      }
      if (!this.proxy.signer.basis.nominative) {
        result = false
      }
      if (!this.proxy.signer.fullName.genitive) {
        result = false
      }
      if (!this.proxy.signer.position.genitive) {
        result = false
      }
      if (!this.proxy.signer.basis.genitive) {
        result = false
      }

      return result
    },
    legalValidation() {
      if (!this.proxy.data.legal.opf) {
        return false
      }
      if (!this.proxy.data.legal.name) {
        return false
      }
      if (!innRegExp.test(this.proxy.data.legal.inn)) {
        return false
      }
      if (!ogrnRegExp.test(this.proxy.data.legal.ogrn)) {
        return false
      }

      return true
    },
    bankValidation() {
      if (!this.proxy.data.bank.name) {
        return false
      }

      if (!rsRegExp.test(this.proxy.data.bank.rs)) {
        return false
      }
      if (!ksRegExp.test(this.proxy.data.bank.ks)) {
        return false
      }
      if (!bikRegExp.test(this.proxy.data.bank.bik)) {
        return false
      }

      return true
    },
    legalAddressValidation() {
      if (!this.proxy.data.legalAddress.country) {
        return false
      }
      if (!this.proxy.data.legalAddress.city) {
        return false
      }
      if (!indexRegExp.test(this.proxy.data.legalAddress.index)) {
        return false
      }

      return true
    },
    postalAddressValidation() {
      if (!this.proxy.data.postalAddress.country) {
        return false
      }
      if (!this.proxy.data.postalAddress.city) {
        return false
      }
      if (!indexRegExp.test(this.proxy.data.postalAddress.index)) {
        return false
      }

      return true
    },
    validation() {
      if (!this.proxy.title) {
        return false
      }

      if (!this.proxy.type || !~REQUISITE_TYPES.indexOf(this.proxy.type)) {
        return false
      }

      return this.signerValidation && this.legalValidation && this.bankValidation && this.legalAddressValidation && this.postalAddressValidation
    },

    ownerSelectDisabled() {
      if (this.User && this.User.id) {
        return true
      }

      return false
    }
  },

  watch: {
    User: {
      handler() {
        this.setOwnerId()
      },
      deep: true
    },

    contactMatches() {
      if (this.contactMatches) {
        this.setContact(this.Owner || this.User)
      }
    },

    validation() {
      this.$emit('validation', this.validation)
    },

    'proxy.data.legal.opf'() {
      this.setTitle()
    },

    'proxy.data.legal.name'() {
      this.setTitle()
    },

    'suggestion.inn': {
      handler() {
        if (this.suggestion.inn) {
          this.proxy.data.legal.opf = this.suggestion.inn.legal.opf
          this.proxy.data.legal.name = this.suggestion.inn.legal.name
          this.proxy.data.legal.inn = this.suggestion.inn.legal.inn
          this.proxy.data.legal.ogrn = this.suggestion.inn.legal.ogrn
          this.proxy.data.legal.kpp = this.suggestion.inn.legal.kpp
          this.proxy.data.legal.okpo = this.suggestion.inn.legal.okpo

          this.suggestion.fio = this.suggestion.inn.manager.fullName

          if (this.suggestion.inn.legal.opf) {
            const basis = {
              nominative: this.getBasis('Свидетельство'),
              genitive: this.getBasis('Свидетельства')
            }

            this.basis.nominative.splice(1, this.basis.nominative.length, basis.nominative)
            this.basis.genitive.splice(1, this.basis.genitive.length, basis.genitive)

            if (upperFirst(this.suggestion.inn.legal.opf) === 'Индивидуальный предприниматель') {
              this.proxy.signer.position.nominative = 'Руководитель'

              this.proxy.signer.basis.nominative = basis.nominative
              this.proxy.signer.basis.genitive = basis.genitive
            } else {
              this.proxy.signer.position.nominative = upperFirst(this.suggestion.inn.manager.post)
              this.proxy.signer.basis.nominative = 'Устав'
              this.proxy.signer.basis.genitive = 'Устава'
            }
          }

          if (this.suggestion.inn.address) {
            for (const key of Object.keys(this.proxy.data.legalAddress)) {
              this.proxy.data.legalAddress[key] = this.suggestion.inn.address[key]
            }
          }
        }
      },
      deep: true
    },

    'suggestion.bank': {
      handler() {
        if (this.suggestion.bank) {
          this.proxy.data.bank.name = this.suggestion.bank.name
          this.proxy.data.bank.ks = this.suggestion.bank.ks
          this.proxy.data.bank.bik = this.suggestion.bank.bik
        }
      },
      deep: true
    },

    'suggestion.fio': {
      handler() {
        this.getCleanData()
      }
    },

    'suggestion.address': {
      handler() {
        if (this.suggestion.address) {
          for (const key of Object.keys(this.proxy.data.legalAddress)) {
            this.proxy.data.legalAddress[key] = this.suggestion.address.data[key]
          }
        }
      },
      deep: true
    },

    'proxy.signer.position.nominative'() {
      switch (this.proxy.signer.position.nominative) {
        case 'Руководитель': {
          this.proxy.signer.position.genitive = 'Руководителя'
          break
        }

        case 'Генеральный директор': {
          this.proxy.signer.position.genitive = 'Генерального директора'
          break
        }

        case 'Исполнительный директор': {
          this.proxy.signer.position.genitive = 'Исполнительного директора'
          break
        }

        case 'Директор': {
          this.proxy.signer.position.genitive = 'Директора'
          break
        }

        case undefined: {
          this.proxy.signer.position.genitive = ''
          break
        }
      }
    },

    'proxy.signer.basis.nominative'() {
      if (!this.proxy.signer.basis.nominative) {
        this.proxy.signer.basis.genitive = ''
      }
    },

    'proxy.OwnerId'() {
      if (this.proxy.OwnerId) {
        this.getOwner(this.proxy.OwnerId)
      } else {
        this.setContact(undefined)
        this.contactMatches = false
      }
    }
  },

  mounted() {
    this.proxy.type = REQUISITE_TYPES.business
    this.setOwnerId()
    this.$emit('validation', this.validation)
  },

  methods: {
    setOwnerId() {
      if (this.User.id) {
        this.proxy.OwnerId = this.User.id
      }
    },

    setContact(user) {
      for (const key in this.proxy.contact) {
        this.proxy.contact[key] = get(user, `data[${key}]`)
      }
    },

    setTitle() {
      const title = []
      if (this.proxy.data.legal.opf) {
        const split = this.proxy.data.legal.opf.split(' ')
        const opfShort = split.reduce((result, word) => {
          if (word.length > 1) {
            result += word.substring(0, 1).toUpperCase()
          }

          return result
        }, '')
        title.push(opfShort)
      }
      if (this.proxy.data.legal.name) {
        title.push(`"${this.proxy.data.legal.name}"`)
      }
      this.proxy.title = title.join(' ')
    },

    async getOwner(id) {
      if (id && isUUID(id)) {
        try {
          if (this.contactMatches) {
            this.loading.Owner = true
          }
          const { data } = await Vue.$GRequest.get('users', id)
          if (data) {
            this.Owner = data
            if (this.contactMatches) {
              this.setContact(data)
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          if (this.contactMatches) {
            this.loading.Owner = false
          }
        }
      }
    },

    getRequisitesByInn: debounce(
      async function(value) {
        const result = {
          title: undefined,
          value: undefined
        }

        if (!innRegExp.test(value.toString())) {
          return
        }

        try {
          this.loading.inn = true
          const { data } = await Vue.$GRequest.find(`${apiPrefix}/getRequisitesByInn/${value}`)
          if (data && Object.keys(data).length) {
            result.title = [ data.legal.inn, data.title ].join(', ')
            result.value = data
          }
          this.suggestions.inn.splice(0, this.suggestions.inn.length, result)
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading.inn = false
        }
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),

    getSuggestions: debounce(
      async function(value, field) {
        const result = []

        if (!value || !field) {
          return
        }

        try {
          this.loading[field] = true
          const { data } = await Vue.$GRequest.find(`${apiPrefix}/suggest/${field}?query=${value}`)
          if (data.suggestions) {
            data.suggestions.map(suggestion => {
              const item = {}
              switch (field) {
                case 'address': {
                  delete suggestion.value
                  item.title = suggestion.unrestricted_value
                  item.value = suggestion
                  break
                }
                case 'fio': {
                  item.title = suggestion
                  item.value = suggestion
                  break
                }
                case 'bank': {
                  item.title = suggestion.name
                  item.value = suggestion
                  break
                }
              }
              result.push(item)
            })
            this.suggestions[field].splice(0, this.suggestions[field].length, ...result)
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading[field] = false
        }
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),

    async getCleanData() {
      const result = {
        nominative: undefined,
        genitive: undefined
      }
      if (!this.suggestion.fio) {
        return
      }

      try {
        const { data } = await Vue.$GRequest.find(`${apiPrefix}/clean/fio?query=${this.suggestion.fio}`)
        if (data) {
          result.nominative = data.nominative
          result.genitive = data.genitive
        }
        this.proxy.signer.fullName.nominative = result.nominative
        this.proxy.signer.fullName.genitive = result.genitive
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },

    getBasis(value) {
      let result

      if (value) {
        const basis = [ `о государственной регистрации №${this.suggestion.inn.legal.ogrn} от ${getRUDate(this.suggestion.inn.legal.ogrn_date)}` ]
        basis.unshift(value)
        result = basis.join(' ')

        return result
      }
    },

    checkField(value, regexp, error) {
      if (value && !regexp.test(value)) {
        return error
      }

      return undefined
    }
  },

  render
}
