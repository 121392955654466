import render from './render'

export default {
  name: 'DefaultLayout',

  data() {
    return {
      show: false,

      paymentResult: undefined
    }
  },

  watch: {
    $route: {
      handler() {
        if (!this.$route.query.paymentResult) {
          this.show = false
        }
        if (typeof this.$route.query.paymentResult === 'boolean') {
          this.setPaymentResult()
        }
      },
      deep: true
    }
  },

  mounted() {
    this.setPaymentResult()
  },

  methods: {
    setPaymentResult() {
      const paymentResult = this.$route.query.paymentResult
      if (paymentResult) {
        this.paymentResult = paymentResult
        this.show = true
      }
    }
  },

  render
}
