import render from './render'

export default {
  name: 'Actions',

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      showDialog: false,
      dialogData: [],
      dialogType: undefined
    }
  },

  render
}
