export const getIconByType = type => {
  switch (type) {
    case 'smsping': return 'sms'
    case 'apisender': return 'whatsapp'
    case 'whatsappchat':
    case 'viberchat':
    case 'vkchat':
    case 'telegramchat':
    case 'facebookchat': return 'chat'
    case 'hlr': return 'search-settings'
    default: return type
  }
}
