export default {
  title: 'Учетные данные',

  titles: {
    create: 'Создать учетные данные',
    update: 'Обновить учетные данные',
    remove: 'Удалить учетные данные'
  },

  labels: {
    title: 'Название',
    records: {
      username: 'Имя пользователя',
      password: 'Пароль',
      token: 'Токен',
      apikey: 'API ключ'
    },
    search: 'Поиск'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаление учетных данных.'
    }
  },

  hints: {
    notFound: 'Учетные данные не найдены.',
    data: 'Данные',
    info: 'Чтобы создать учетные данные, пожалуйста, заполните хотя бы одно из нижеперечисленных полей.'
  },

  tooltips: {
    favorites: 'Сделать учетные данные избранными'
  },

  snackbars: {
    created: 'Учетные данные успешно создан',
    updated: 'Учетные данные успешно обновлён',
    removed: 'Учетные данные успешно удалён'
  }
}
