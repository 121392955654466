import render from './render'

export default {
  name: 'ButtonLink',

  props: {
    to: {
      type: Object,
      default: () => ({})
    },

    icon: {
      type: String,
      default: 'link'
    },

    color: {
      type: String,
      default: 'gray'
    },

    size: {
      type: Number,
      default: 24
    }
  },

  render
}
