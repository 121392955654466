import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'RoutesFormType',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    serviceName: {
      type: String,
      required: true
    }
  },

  render
}
