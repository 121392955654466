import { flatten, get } from 'lodash'
import { isPhoneNumber } from '@sigma-legacy-libs/essentials/lib/validators'
import { REGEXP_IS_EMAIL_SIMPLE, SENDING_PAYLOADS, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { passwordRegex, usernameRegex } from '../constants'

export * from '@sigma-legacy-libs/essentials/lib/validators'

export const isEmpty = value => [ undefined, null, '' ].includes(value)

export const isBoolean = value => typeof value === 'boolean'

export const isString = value => typeof value === 'string'
export const isStringEmpty = value => isString(value) && isEmpty(value)
export const isStringNotEmpty = value => isString(value) && !isEmpty(value)

export const isArray = value => Array.isArray(value)
export const isArrayEmpty = value => isArray(value) && value.length === 0
export const isArrayNotEmpty = value => isArray(value) && value.length > 0

export const isObject = value => typeof value === 'object'
export const isObjectEmpty = value => isObject(value) && !isEmpty(value) && Object.keys(value).length === 0
export const isObjectNotEmpty = value => isObject(value) && !isEmpty(value) && Object.keys(value).length > 0

export const isPlainObject = value => {
  if (isObjectNotEmpty(value)) {
    if (typeof Object.getPrototypeOf === 'function') {
      const proto = Object.getPrototypeOf(value)

      return proto === Object.prototype || proto === null
    }

    return Object.prototype.toString.call(value) === '[object Object]'
  }

  return false
}

export const isValidPhone = (value = '') => {
  const { isValid } = isPhoneNumber(value)

  return Boolean(isValid)
}
export const isValidEmail = (value = '', regex = REGEXP_IS_EMAIL_SIMPLE) => {
  return regex.test(value)
}
export const isValidUserName = (value = '', regex = usernameRegex) => {
  return regex.test(value) || isValidEmail(value)
}
export const isValidPassword = (value = '', regex = passwordRegex) => {
  return regex.test(value)
}
export const isValidDate = value => value instanceof Date && !isNaN(value)

export const isHEX = (value = '') => {
  return /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value)
}
export const isRGB = (value = '', alpha = false) => {
  if (alpha) {
    return /^rgba\((\d{1,3}),(\d{1,3}),(\d{1,3}),(\d{1,3})\)$/.test(value)
  }

  return /^rgb\((\d{1,3}),(\d{1,3}),(\d{1,3})\)$/.test(value)
}

export const isChildOf = (element, potentialParent) => {
  if (potentialParent.contains) {
    return potentialParent.contains(element)
  }

  let target = element
  while (target != null) {
    if (target === potentialParent) {
      return true
    }
    target = target.parentNode
  }

  return false
}

export const checkPermissions = (params, logic = null, accept = null, permissions = {}) => {
  if (logic && !~[ 'and', 'or' ].indexOf(logic)) {
    accept = logic
    logic = null
  }

  const result = {
    permissions: [],
    logic: 'or'
  }

  if (isStringNotEmpty(params)) {
    result.permissions = [
      {
        permission: params,
        accept: accept || true
      }
    ]
    result.logic = logic || result.logic
  }

  if (isArrayNotEmpty(params)) {
    result.permissions = params.map(item => {
      return {
        permission: item.permission || item,
        accept: item.accept || accept || true
      }
    })
    result.logic = 'or'
  }

  const cmp = result.logic === 'and' ? 'every' : 'some'

  return result.permissions[cmp](item => {
    return ~flatten([ item.accept ]).indexOf(get(permissions, item.permission, false))
  })
}

export const sendingPayloadValidator = (type, payload = {}) => {
  if (!type || !payload) {
    return false
  }

  if (!~Object.keys(SENDING_PAYLOADS).indexOf(type)) {
    return false
  }

  for (const key of [ 'sender', 'text' ]) {
    switch (type) {
      case SENDING_TYPES.voice:
      case SENDING_TYPES.whatsappchat:
      case SENDING_TYPES.viberchat:
      case SENDING_TYPES.vkchat:
      case SENDING_TYPES.telegramchat:
        continue
    }
    if (isEmpty(payload[key])) {
      return false
    }
  }

  switch (type) {
    case SENDING_TYPES.viber: {
      if (!isEmpty(payload.image)) {
        if (payload.button) {
          if (isEmpty(payload.button.text) || !isEmpty(payload.button.text) && payload.button.text.length > 30) {
            return false
          }
          if (isEmpty(payload.button.url) || !isEmpty(payload.button.url) && payload.button.url.length > 2048) {
            return false
          }
        }
      }
      break
    }

    case SENDING_TYPES.voice: {
      if (isEmpty(payload.sender)) {
        return false
      }
      if (isEmpty(payload.text)) {
        if (isEmpty(payload.audio)) {
          return false
        }
      } else {
        if (isEmpty(payload.tts)) {
          return false
        }
      }
      break
    }

    case SENDING_TYPES.whatsappchat:
    case SENDING_TYPES.viberchat:
    case SENDING_TYPES.vkchat:
    case SENDING_TYPES.telegramchat: {
      if (isEmpty(payload.sender)) {
        return false
      }
      break
    }
  }

  return true
}

export default {
  isEmpty,

  isString,
  isStringNotEmpty,

  isArray,
  isArrayNotEmpty,

  isObject,
  isObjectNotEmpty,
  isPlainObject,

  isValidUserName,
  isValidEmail,
  isValidPassword,
  isValidDate,

  checkPermissions,

  isChildOf,

  sendingPayloadValidator
}
