import { services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.value.description) {
    return h(
      sticker,
      {
        props: {
          value: this.value.description,
          label: this.getTranslate(`${services.tokens}.labels.description`),
          borderStyle: 'dashed'
        }
      }
    )
  }
}
