export default {
  title: 'Личный кабинет',

  current: {
    balance: 'Текущий баланс',
    overdraft: 'Текущий кредитный лимит'
  },

  tooltips: {
    warning: 'Вы используете средства кредитного лимита.',
    error: 'Вы достигли установленного порога кредитного лимита.'
  }
}
