export const catchSymbolsMenus = {
  $: [
    {
      start: '${',
      end: '}',
      sections: [
        {
          menu: {
            firstName: 'contacts.labels.firstName',
            middleName: 'contacts.labels.middleName',
            lastName: 'contacts.labels.lastName',
            phone: 'contacts.labels.phone',
            email: 'contacts.labels.email',
            date: 'contacts.labels.date',
            gender: 'contacts.labels.gender',
            custom01: 'contacts.labels.custom01',
            custom02: 'contacts.labels.custom02',
            custom03: 'contacts.labels.custom03',
            custom04: 'contacts.labels.custom04',
            custom05: 'contacts.labels.custom05',
            custom06: 'contacts.labels.custom06',
            custom07: 'contacts.labels.custom07',
            custom08: 'contacts.labels.custom08',
            custom09: 'contacts.labels.custom09',
            custom10: 'contacts.labels.custom10',
            custom11: 'contacts.labels.custom11',
            custom12: 'contacts.labels.custom12',
            custom13: 'contacts.labels.custom13',
            custom14: 'contacts.labels.custom14',
            custom15: 'contacts.labels.custom15',
            custom16: 'contacts.labels.custom16',
            custom17: 'contacts.labels.custom17',
            custom18: 'contacts.labels.custom18',
            custom19: 'contacts.labels.custom19',
            custom20: 'contacts.labels.custom20'
          }
        },
        true

        /*
         * {
         *   menu: {
         *     '/.*?/': 'sendings.filterMenu.any',
         *     '/[А-яЁё\\-]+/': 'sendings.filterMenu.string',
         *     '/^\\+?(7|8|\\d{1,2})[\\s-(]?(\\d{3})[\\s-)]?(\\d{3})[\\s-]?(\\d{2})[\\s-]?(\\d{2})$/':
         *       'sendings.filterMenu.phone',
         *     '/.*?@.*?\\.\\w{2,}/': 'sendings.filterMenu.email',
         *     '/(\\d{2}\\.){2}\\d{2}/': 'sendings.filterMenu.date'
         *   }
         * }
         */
      ]
    }
  ],
  '#': [
    {
      start: '#',
      end: '#',
      sections: [
        {
          menu: {
            БУКВЫ: 'sendings.catchSymbolsMenu.symbols',
            ЦИФРЫ: 'sendings.catchSymbolsMenu.numbers',
            БУКВЫЦИФРЫ: 'sendings.catchSymbolsMenu.symbolsNumbers',
            БУКВЫЦИФРЫПРОБЕЛЫ: 'sendings.catchSymbolsMenu.symbolsNumbersSpaces',
            РУССКИЕБУКВЫ: 'sendings.catchSymbolsMenu.russianSymbols',
            РУССКИЕБУКВЫЦИФРЫ: 'sendings.catchSymbolsMenu.russianSymbolsNumber',
            РУССКИЕБУКВЫЦИФРЫПРОБЕЛЫ: 'sendings.catchSymbolsMenu.russianSymbolsNumbersSpaces'
          }
        }
      ]
    }
  ],
  custom: {
    $: [
      {
        title: 'contacts.labels.firstName',
        value: '${firstName}'
      },
      {
        title: 'contacts.labels.middleName',
        value: '${middleName}'
      },
      {
        title: 'contacts.labels.lastName',
        value: '${lastName}'
      },
      {
        title: 'contacts.labels.phone',
        value: '${phone}'
      },
      {
        title: 'contacts.labels.email',
        value: '${email}'
      },
      {
        title: 'contacts.labels.date',
        value: '${date}'
      },
      {
        title: 'contacts.labels.gender',
        value: '${gender}'
      },

      {
        title: 'contacts.labels.custom01',
        value: '${custom01}'
      },
      {
        title: 'contacts.labels.custom02',
        value: '${custom02}'
      },
      {
        title: 'contacts.labels.custom03',
        value: '${custom03}'
      },
      {
        title: 'contacts.labels.custom04',
        value: '${custom04}'
      },
      {
        title: 'contacts.labels.custom05',
        value: '${custom05}'
      },
      {
        title: 'contacts.labels.custom06',
        value: '${custom06}'
      },
      {
        title: 'contacts.labels.custom07',
        value: '${custom07}'
      },
      {
        title: 'contacts.labels.custom08',
        value: '${custom08}'
      },
      {
        title: 'contacts.labels.custom09',
        value: '${custom09}'
      },
      {
        title: 'contacts.labels.custom10',
        value: '${custom10}'
      },
      {
        title: 'contacts.labels.custom11',
        value: '${custom11}'
      },
      {
        title: 'contacts.labels.custom12',
        value: '${custom12}'
      },
      {
        title: 'contacts.labels.custom13',
        value: '${custom13}'
      },
      {
        title: 'contacts.labels.custom14',
        value: '${custom14}'
      },
      {
        title: 'contacts.labels.custom15',
        value: '${custom15}'
      },
      {
        title: 'contacts.labels.custom16',
        value: '${custom16}'
      },
      {
        title: 'contacts.labels.custom17',
        value: '${custom17}'
      },
      {
        title: 'contacts.labels.custom18',
        value: '${custom18}'
      },
      {
        title: 'contacts.labels.custom19',
        value: '${custom19}'
      },
      {
        title: 'contacts.labels.custom20',
        value: '${custom20}'
      }
    ],
    '#': [
      {
        title: 'sendings.catchSymbolsMenu.symbols',
        value: '#БУКВЫ#'
      },
      {
        title: 'sendings.catchSymbolsMenu.numbers',
        value: '#ЦИФРЫ#'
      },
      {
        title: 'sendings.catchSymbolsMenu.symbolsNumbers',
        value: '#БУКВЫЦИФРЫ#'
      },
      {
        title: 'sendings.catchSymbolsMenu.symbolsNumbersSpaces',
        value: '#БУКВЫЦИФРЫПРОБЕЛЫ#'
      },
      {
        title: 'sendings.catchSymbolsMenu.russianSymbols',
        value: '#РУССКИЕБУКВЫ#'
      },
      {
        title: 'sendings.catchSymbolsMenu.russianSymbolsNumber',
        value: '#РУССКИЕБУКВЫЦИФРЫ#'
      },
      {
        title: 'sendings.catchSymbolsMenu.russianSymbolsNumbersSpaces',
        value: '#РУССКИЕБУКВЫЦИФРЫПРОБЕЛЫ#'
      }
    ]
  }
}

export default { catchSymbolsMenus }
