import { serviceName } from '@/components/services/servers/utils'

import title from '@/components/services/servers/item/title'
import processor from '@/components/services/servers/item/processor'
import host from '@/components/services/servers/item/host'
import port from '@/components/services/servers/item/port'

function renderTitle(h) {
  return h(
    title,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderProcessor(h) {
  if (this.viewport.size.width >= 600) {
    return h(
      processor,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderHost(h) {
  if (this.viewport.size.width >= 800) {
    return h(
      host,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderPort(h) {
  if (this.viewport.size.width >= 1000) {
    return h(
      port,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-item__info`
    },
    [
      renderTitle.call(this, h),
      renderProcessor.call(this, h),
      renderHost.call(this, h),
      renderPort.call(this, h)
    ]
  )
}
