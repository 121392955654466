import VirtualList from 'vue-virtual-scroll-list'

import { states } from '@/utils'

import { serviceName } from '@/components/services/operatorGroups/utils'

import row from '@/components/misc/virtualScroll/row'

function renderSearch(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.searchValue,
        label: this.getTranslate('commons.labels.search'),
        beforeIcon: 'search',
        afterIcon: 'refresh',
        afterIconCallback: () => {
          this.search()
        },
        loading: this.restData[serviceName].find.state === states.loading,
        mode: 'outline',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.search(event)
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic ff pb-3'
    },
    [ renderSearch.call(this, h) ]
  )
}

function renderPills(h) {
  return h(
    'div',
    {
      class: 'faic pb-3'
    },
    [ 'all', 'checked' ].map(tab => {
      let total = this.proxy.length
      if (tab === 'all') {
        total = this.restData[serviceName].find.pagination.total
      }

      return h(
        'g-button',
        {
          class: 'my-0 ml-0',
          props: {
            flat: this.tab !== tab,
            rounded: true,
            small: true,
            depressed: true,
            color: this.tab === tab ? 'primary' : 'grey',
            label: this.getTranslate(`${serviceName}.pills.${tab}`) + ` ${total}`
          },
          on: {
            click: () => {
              this.tab = tab
            }
          }
        }
      )
    })
  )
}

function renderGroup(h, group) {
  if (group) {
    return h(
      'div',
      {
        class: 'tree__row',
        on: {
          click: () => {
            const index = this.proxy.indexOf(group)
            if (~index) {
              this.proxy.splice(index, 1)
            } else {
              this.proxy.push(group)
            }
          }
        }
      },
      [
        h(
          'div',
          {
            class: {
              ['tree__checker']: true,
              ['tree__checker--checked']: ~this.proxy.indexOf(group)
            }
          }
        ),

        h(
          'div',
          {
            class: 'tree__content'
          },
          [
            h(
              'div',
              {
                class: 'tree__title'
              },
              group
            )
          ]
        )
      ]
    )
  }
}

function renderCheckedGroups(h) {
  if (this.tab === 'checked') {
    if (Array.isArray(this.checkedGroups)) {
      if (this.checkedGroups.length) {
        return h(
          'div',
          {
            class: 'tree__level tree__level--special'
          },
          this.checkedGroups.sort((a, b) => a - b).map(group => renderGroup.call(this, h, group))
        )
      } else {
        return h(
          'div',
          {
            class: 'tree__empty'
          },
          [ h('g-empty') ]
        )
      }
    }
  }
}
function renderGroups(h) {
  if (this.tab === 'all') {
    if (Array.isArray(this.restData[serviceName].find.data)) {
      if (this.restData[serviceName].find.data.length) {
        return h(
          VirtualList,
          {
            class: 'tree__level tree__level--1',
            props: {
              dataKey: 'group',
              dataSources: this.restData[serviceName].find.data,
              dataComponent: row,
              itemScopedSlots: {
                item: source => renderGroup.call(this, h, source.group)
              }
            },
            on: {
              tobottom: () => {
                const loadMore = this.restData[serviceName].find.pagination.limit + this.restData[serviceName].find.pagination.offset < this.restData[serviceName].find.pagination.total
                if (this.restData[serviceName].find.state === states.ready && loadMore) {
                  this.restData[serviceName].find.pagination.offset += this.restData[serviceName].find.pagination.limit
                }
              }
            }
          }
        )
      } else {
        return h(
          'div',
          {
            class: 'tree__empty'
          },
          [ h('g-empty') ]
        )
      }
    }
  }
}

function renderBody(h) {
  return h(
    'div',
    {
      class: 'tree'
    },
    [
      renderPills.call(this, h),
      renderCheckedGroups.call(this, h),
      renderGroups.call(this, h)
    ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
