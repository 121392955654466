import { percentageRegex, services } from '@/utils'

import generatorRow from '@/components/generator/row'
import rulesConditions from '@/components/misc/rules/conditions'
import routesFormLink from '@/components/services/routes/form/link'
import routesFormType from '@/components/services/routes/form/type'
import routesFormTitle from '@/components/services/routes/form/title'

function renderTypeField(h) {
  if (!this.type) {
    return h(
      routesFormType,
      {
        props: {
          value: this.proxy,
          serviceName: services.routingRules
        },
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}

function renderTitleField(h) {
  return h(
    routesFormTitle,
    {
      props: {
        value: this.proxy,
        disabled: this.disabled,
        serviceName: services.routingRules
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderRulesGenerator(h) {
  return h(
    rulesConditions,
    {
      props: {
        value: this.proxy.rules,
        type: this.proxy.type,
        blacklist: [ 'type', 'group' ]
      },
      on: {
        input: event => {
          this.proxy.rules = event
        }
      }
    }
  )
}

function renderSelectorProviderGroup(h, item) {
  if (item.title) {
    return h(
      'div',
      {
        class: 'fc ff plpx-12'
      },
      [
        h(
          'div',
          {
            class: 'medium'
          },
          [ item.title ]
        ),

        h(
          'div',
          {
            class: 'small text--warning'
          },
          this.getTranslate(`${services.routingRules}.hints.willBeCreated`)
        )
      ]
    )
  }

  return h(
    'g-select',
    {
      props: {
        value: item.id,
        label: this.getTranslate(`${services.routingRules}.labels.providerGroup`),
        query: {
          type: this.proxy.type,
          isActive: true
        },
        itemsDisabled: this.proxy.ProviderGroups.map(({ id }) => id),
        service: 'providerGroups',
        disabled: this.disabled,
        autocomplete: true,
        hideSelections: true,
        clearable: true,
        mode: 'outline',
        combobox: true,
        rounded: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          item.id = event
        }
      }
    }
  )
}

function renderProviderGroupsFields(h) {
  if (Array.isArray(this.proxy.ProviderGroups)) {
    return h(
      'g-card',
      {
        class: 'grid grid-gap--8 pt-3 px-2 pb-2',
        props: {
          title: this.getTranslate(`${services.routingRules}.subtitles.providerGroups`),
          dashed: true,
          outline: true,
          rounded: true
        }
      },
      [
        h(
          generatorRow,
          {
            props: {
              value: this.proxy.ProviderGroups,
              disabled: this.disabled,
              defaultValue: {
                id: undefined,
                percentage: 100
              }
            },
            on: {
              input: event => {
                this.proxy.ProviderGroups = event
              }
            },
            scopedSlots: {
              row: ({ item }) => {
                return h(
                  'div',
                  {
                    class: 'grid grid-gap--8',
                    style: { 'grid-template-columns': '1fr 70px auto' }
                  },
                  [
                    renderSelectorProviderGroup.call(this, h, item),

                    h(
                      'g-text-field',
                      {
                        props: {
                          value: item.percentage,
                          defaultValue: 100,
                          label: this.getTranslate(`${services.routingRules}.labels.percentage`),
                          disabled: this.disabled,
                          mode: 'outline',
                          dense: true,
                          rounded: true,
                          required: true,
                          details: false,
                          mask: percentageRegex
                        },
                        on: {
                          input: event => {
                            item.percentage = event
                          }
                        }
                      }
                    ),

                    h(
                      routesFormLink,
                      {
                        props: {
                          value: item,
                          service: services.providerGroups
                        }
                      }
                    )
                  ]
                )
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' }
    },
    [
      renderTypeField.call(this, h),
      renderTitleField.call(this, h),
      renderRulesGenerator.call(this, h),
      renderProviderGroupsFields.call(this, h)
    ]
  )
}
