import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, isEqual } from 'lodash'

import render from './render'

export default {
  name: 'GeneratorRow',

  mixins: [ proxy({ type: 'array' }) ],

  props: {
    defaultValue: {
      type: [ Object, Array, String ],
      default: () => ({})
    },

    disabled: {
      type: Boolean,
      default: false
    },
    addDisabled: {
      type: Boolean,
      default: false
    },

    sortable: {
      type: Boolean,
      default: false
    },
    sortableProperty: {
      type: String,
      default: 'value'
    },

    min: {
      type: Number,
      default: undefined
    },
    max: {
      type: Number,
      default: undefined
    }
  },

  methods: {
    addField() {
      if (this.max && this.proxy.length >= this.max) {
        return
      }

      this.proxy.push(cloneDeep(this.defaultValue))
    },
    removeField(index) {
      if (this.min && this.proxy.length <= this.min) {
        return
      }

      this.proxy.splice(index, 1)
      if (this.proxy.length === 0) {
        this.addField()
      }
    },

    sorter(items) {
      if (this.sortable) {
        for (let index = 0; index < items.length; index++) {
          items[index][this.sortableProperty] = index
        }
      }
    },

    _inputFilter(value) {
      const result = cloneDeep(value) || []
      if (result.length === 0) {
        result.push(cloneDeep(this.defaultValue))
      }
      this.sorter(result)

      return result
    },
    _outputFilter(value) {
      const result = cloneDeep(value) || []
      this.sorter(result)

      return result
    },

    watchProxyHandler() {
      if (!isEqual(this.proxy, this.value)) {
        this.transmitValue()
      }
    }
  },

  render
}
