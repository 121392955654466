import { generateServices } from '@/utils'

import render from './render'

const name = 'paymentLogs'

export default function({ PaymentId }) {
  return {
    name,

    mixins: [
      generateServices({
        name,

        find: {
          defaultFilter: {
            PaymentId,
            $scope: [ 'full' ]
          }
        },

        get: false,
        create: false,
        update: false,
        remove: false
      })
    ],

    data() {
      return {
        showSourceDialog: false,

        request: undefined,
        response: undefined,

        currentTab: undefined
      }
    },

    mounted() {
      if (this.checkPermissions('advanced.paymentLogs.get')) {
        this.rest[name].find()
      }
    },

    render
  }
}
