import { PROVIDER_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

export default function(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: 'auto_fix_high',
        flat: true,
        color: 'success',
        loading: this.loading[this.provider.id] || this.providersLoading,
        disabled: this.provider.state !== PROVIDER_STATES.inactive || this.loading[this.provider.id] || this.providersLoading
      },
      directives: [
        {
          name: 'g-tooltip',
          value: this.getTranslate('inactiveProviders.tooltips.fix')
        }
      ],
      key: this.provider.id,
      on: {
        click: () => {
          this.fixProvider()
        }
      }
    }
  )
}
