import { components, eventHandlers } from '@/utils'

import render from './render'

export default {
  name: components['list-item'],

  mixins: [ eventHandlers ],

  props: {
    label: [ String, Number ],

    active: Boolean,
    hovered: Boolean,
    dense: Boolean,
    rounded: Boolean,
    outline: Boolean,
    loading: Boolean,
    disabled: Boolean,

    color: String,
    icon: String,
    iconColor: String,
    iconSize: Number,
    prefix: String,
    suffix: String,

    to: {
      type: [ Object, String ],
      default: undefined
    },

    button: {
      type: Object,
      default: undefined
    }
  },

  computed: {
    hasIcon() {
      return !!this.icon || typeof this.$scopedSlots.icon === 'function' || this.$slots.icon
    },

    hasPrefix() {
      return !!this.prefix || typeof this.$scopedSlots.prefix === 'function' || this.$slots.prefix
    },

    hasSuffix() {
      return !!this.suffix || typeof this.$scopedSlots.suffix === 'function' || this.$slots.suffix
    },

    hasButton() {
      return this.button && !!this.button.icon && !!this.button.callback
    },

    computedIconColor() {
      let result = this.iconColor || this.color

      if (this.active) {
        result = 'primary'
      } else if (this.disabled) {
        result = 'grey'
      }

      return result
    },

    computedIconSize() {
      return this.iconSize || (this.dense ? 21 : 24)
    },

    computedButtonTooltip() {
      const result = []
      if (this.button && this.button.tooltip) {
        result.push(this.button.tooltip)
      }

      return result
    },

    $class() {
      return {
        [`${components['list-item']}`]: true,

        [`${components['list-item']}--active`]: this.active,
        [`${components['list-item']}--hovered`]: this.hovered,
        [`${components['list-item']}--dense`]: this.dense,
        [`${components['list-item']}--outline`]: this.outline,
        [`${components['list-item']}--rounded`]: this.rounded,
        [`${components['list-item']}--loading`]: this.loading,
        [`${components['list-item']}--disabled`]: this.disabled,

        [`${components['list-item']}--icon`]: this.hasIcon,
        [`${components['list-item']}--prefix`]: this.hasPrefix,
        [`${components['list-item']}--suffix`]: !!this.hasSuffix,
        [`${components['list-item']}--button`]: this.hasButton,

        [`${components['list-item']}--${this.color}`]: !!this.color
      }
    }
  },

  render
}
