import Vue from 'vue'

import { get } from 'lodash'
import { Macro } from '@sigma-legacy-libs/cache'

import { colors, isUUID, nestjsServices, permissionPresets, services } from '@/utils'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})
const cachedGet = Cache.wrapWithCache(async (key, service, id, params) => {
  const path = []
  if (Object.keys(nestjsServices).includes(service)) {
    path.push('n')
  }
  path.push(service)

  return await Vue.$GRequest.get(path.join('/'), id, params)
})

export default {
  name: 'Owner',

  props: {
    value: {
      type: [ Object, String ],
      default: () => ({})
    },

    label: String,

    copy: Boolean,

    pathToData: {
      type: String,
      default: 'Owner'
    }
  },

  data() {
    return {
      data: undefined,

      loading: false
    }
  },

  computed: {
    Id() {
      return get(this.data, this.pathToData + 'Id')
    },
    id() {
      return get(this.data, this.pathToData.toLowerCase() + 'Id')
    },
    $id() {
      return typeof this.value === 'string' ? this.value : this.Id || this.id
    },

    Owner() {
      return get(this.data, this.pathToData, this.$id)
    },
    owner() {
      return get(this.data, this.pathToData.toLowerCase(), this.$id)
    },
    $owner() {
      return this.Owner || this.owner
    },

    hasOwner() {
      return !!this.$owner
    },

    verified() {
      return get(this.$owner, '$verified', false)
    },

    $props() {
      return {
        value: this.$owner,
        pathToTitle: 'username',
        label: this.label || this.getTranslate('commons.labels.owner'),
        iconSVG: this.verified ? 'user-verified' : 'user',
        iconTooltip: this.verified ? this.getTranslate('commons.has.contract') : undefined,
        color: this.verified ? colors.info : colors.grey,
        copy: this.copy
      }
    }
  },

  value: {
    handler() {
      this.getData()
    },
    deep: true
  },

  mounted() {
    this.getData()
  },

  methods: {
    async getData() {
      try {
        if (this.value) {
          if (isUUID(this.$id)) {
            if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.resellerUp)) {
              this.loading = true
              const { data } = await cachedGet(`${services.users}:${this.$id}`, services.users, this.$id)
              if (data) {
                this.data = data
              }
            }
          } else if (typeof this.value === 'object') {
            this.data = this.value
          }
        }
      } catch (error) {
      } finally {
        this.loading = false
      }
    }
  },

  render
}
