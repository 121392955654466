import { millisecondsToHummerTime } from '@/utils'

function renderProgressBar(h) {
  if (!this.disabled) {
    return h(
      'g-progress',
      {
        props: {
          value: this.sourceProgress,
          indeterminate: this.loading,
          size: 36,
          rotate: -90
        }
      }
    )
  }
}

function renderButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: this.playing ? 'pause' : 'play_arrow',
        flat: true,
        outline: true,
        disabled: this.disabled,
        color: 'primary'
      },
      on: {
        click: () => {
          this.setPlayerId(this.localPlayerId)
          this.setSrc(this.sourceUrl)
        }
      }
    }
  )
}

function renderDurationOrCurrentTime(h) {
  let time = this.sourceCurrentTime || this.sourceDuration
  if (time) {
    time = millisecondsToHummerTime(time)
  }
  if (!this.disabled) {
    return h(
      'div',
      {
        class: 'file__player-time'
      },
      time
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'file__player'
    },
    [
      renderProgressBar.call(this, h),
      renderButton.call(this, h),
      renderDurationOrCurrentTime.call(this, h)
    ]
  )
}
