import { get } from 'lodash'

import render from './render'

export default {
  name: 'Error',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      tooltip: undefined
    }
  },

  computed: {
    error() {
      let result
      try {
        const error = JSON.parse(this.value.error)
        if (error) {
          const message = get(error, 'meta.message')
          if (message) {
            this.tooltip = message
            result = message
          }
        }
      } catch (error) {
      } finally {
        if (this.value.error && !result) {
          this.tooltip = this.value.error
          result = this.value.error
        }
      }

      return result
    }
  },

  render
}
