import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import render from './render'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})
const cachedGet = Cache.wrapWithCache(async (key, service, id, params) => await Vue.$GRequest.get(service, id, params))

export default {
  name: 'ServicesValue',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    count: {
      type: Number,
      default: 2
    },
    service: {
      type: String
    }
  },

  data() {
    return {
      loading: false,

      proxy: []
    }
  },

  computed: {
    isMany() {
      return this.value.length > this.count
    }
  },

  mounted() {
    this.fillData()
  },

  methods: {
    async fillData() {
      this.loading = true

      for (const item of this.value) {
        const result = {
          id: undefined,
          title: undefined
        }
        if (typeof item === 'string') {
          result.id = item
        } else {
          result.id = item.id
          result.title = item.title
        }
        if (!result.title) {
          const { data } = await cachedGet(`${this.service}:${result.id}`, this.service, result.id)
          result.title = data.title
        }

        this.proxy.push(result)
      }

      this.loading = false
    }
  },

  render
}
