import Vue from 'vue'

import { cloneDeep, get } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { BILLING_CURRENCY, DOCUMENT_TEMPLATES_TYPES, PAYMENTS_METHODS, RECURRENT_PAYMENTS_METHODS } from '@sigma-legacy-libs/essentials/lib/constants'

import { DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD, DEFAULT_PAYMENT_PAYLOAD, backendServerIP, getUrlToStorage, globalErrorHandler, globalErrorProcessor, hostnames, services } from '@/utils'

import render from './render'

export default {
  name: 'GlobalPaymentDialog',

  data() {
    return {
      step: 'first',

      show: false,
      loading: false,

      payload: {
        legal: cloneDeep(DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD),
        individual: cloneDeep(DEFAULT_PAYMENT_PAYLOAD)
      }
    }
  },

  computed: {
    ...mapGetters({
      paymentsMethods: 'payments/methods',
      showPaymentDialog: 'currentTab/showPaymentDialog'
    }),

    validation() {
      let result = false
      if (this.payload.legal.type === DOCUMENT_TEMPLATES_TYPES.contract) {
        result = true
      } else if (this.payload.legal.type === DOCUMENT_TEMPLATES_TYPES.invoice) {
        const amount = get(this.payload.legal, 'data.SERVICE_PRICE', 0)
        result = amount > 0
      }

      return !!(this.payload.legal.requisiteId && this.payload.legal.companyId && result)
    },

    paymentTypes() {
      const result = [
        {
          name: 'legal',
          priority: 2
        }
      ]
      if (Array.isArray(this.$paymentsMethods) && this.$paymentsMethods.length) {
        result.push({
          name: 'individual',
          priority: 1
        })
      }

      return result.sort((a, b) => a.priority - b.priority).map(({ name }) => name)
    },

    $paymentsMethods() {
      const result = []
      if (this.paymentsMethods.some(method => ~PAYMENTS_METHODS.indexOf(method))) {
        result.push({
          name: 'cards',
          priority: 1
        })
      }
      if (this.currencyIsRUB && this.hostnameIsOwn) {
        result.push({
          name: 'yooMoney',
          priority: 3
        })
      }
      if (this.paymentsMethods.some(method => ~RECURRENT_PAYMENTS_METHODS.indexOf(method))) {
        result.push({
          name: 'recurrent',
          priority: 9
        })
      }

      return result.sort((a, b) => a.priority - b.priority).map(({ name }) => name)
    },

    currencyIsRUB() {
      return this.globalSettings.billings.currency === BILLING_CURRENCY.RUB
    },
    hostnameIsOwn() {
      return hostnames.includes(window.location.hostname)
    }
  },

  mounted() {
    this.payload.individual.OwnerId = this.account.id
  },

  methods: {
    ...mapActions({
      setShowPaymentDialog: 'currentTab/setShowPaymentDialog',
      setShowCreateRecurrentPaymentDialog: 'currentTab/setShowCreateRecurrentPaymentDialog'
    }),

    async requestPayment() {
      if (!this.payload.individual.acceptOffer) {
        return
      }

      const method = this.payload.individual.method
      const amount = this.payload.individual.amount
      if (method !== PAYMENTS_METHODS.stripe && (!amount || amount <= 0)) {
        return
      }

      try {
        this.loading = true
        const body = {
          method,
          urlSuccess: `${backendServerIP}/api/${services.payments}/webhook/${method}/success/`,
          urlFail: `${backendServerIP}/api/${services.payments}/webhook/${method}/fail/`
        }
        if (method !== PAYMENTS_METHODS.stripe) {
          body.amount = amount
        }
        const { data } = await Vue.$GRequest.create(services.payments, body)
        if (data && data.url) {
          window.location.href = data.url
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    async requestDocument() {
      try {
        this.loading = true
        delete this.payload.type
        const { data } = await Vue.$GRequest.create(
          `/n/${services.invoices}`,
          this.payload.legal,
          { query: { scopes: [ 'awaitDocument' ] } }
        )
        if (data) {
          window.location.href = getUrlToStorage([ get(data, 'Owner.id', data.OwnerId), data.documentId ])
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    paymentMethodClickHandler(method) {
      switch (method) {
        case 'recurrent': {
          Vue.router.push({
            name: 'account',
            hash: 'recurrentPayments'
          })
          this.setShowPaymentDialog(false)
          this.setShowCreateRecurrentPaymentDialog(true)
          break
        }

        case 'cards': {
          if (this.paymentsMethods && this.paymentsMethods.length === 1 && this.paymentsMethods[0] === PAYMENTS_METHODS.stripe) {
            this.payload.individual.method = PAYMENTS_METHODS.stripe
            this.requestPayment()
            break
          }

          this.step = method
          break
        }

        default: {
          this.step = method
          break
        }
      }
    }
  },

  render
}
