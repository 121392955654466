export default function(h) {
  return h(
    'div',
    {
      attrs: { 'data-clipboard-text': `${this.value}` },
      class: `copy-${this._uid}`,
      domProps: { innerHTML: this.value }
    }
  )
}
