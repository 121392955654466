import Vue from 'vue'

import { get } from 'lodash'

import { buttonModes } from '@/utils'
import { serviceName } from '@/components/services/requisites/utils'

import button from '@/components/button'
import owner from '@/components/serviceTemplate/item/owner'
import date from '@/components/serviceTemplate/item/date'
import opf from '@/components/services/requisites/item/opf'
import inn from '@/components/services/requisites/item/inn'
import vat from '@/components/services/requisites/item/vat'
import title from '@/components/services/requisites/item/title'

function renderTitle(h) {
  return h(
    title,
    {
      props:
      {
        value: this.value
      }
    }
  )
}
function renderOPF(h) {
  return h(
    opf,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderInn(h) {
  return h(
    inn,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderVAT(h) {
  if (!this.viewport.breakpoint.xs) {
    return h(
      vat,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderOwner(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderInfo(h) {
  return h(
    'div',
    {
      class: `${serviceName}-panel__info`,
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderTitle.call(this, h),
      renderOPF.call(this, h),
      renderInn.call(this, h),
      renderVAT.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderEditButton(h) {
  const id = get(this.value, 'id')
  if (id) {
    return h(
      button,
      {
        class: 'hover-child',
        props: {
          mode: buttonModes.flat,
          icon: 'edit'
        },
        on: {
          click: () => {
            Vue.router.push({
              name: `${serviceName}.single`,
              params: { id }
            })
          }
        }
      }
    )
  }
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${serviceName}-panel`]: true,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderEditButton.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
