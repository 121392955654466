import { components, isHEX } from '@/utils'

import render from './render'

export default {
  name: components.toolbar,

  props: {
    color: String,

    fixed: Boolean
  },

  computed: {
    $class() {
      const result = {
        [components.toolbar]: true,
        [`${components.toolbar}--fixed`]: this.fixed
      }
      if (this.color && !isHEX(this.color)) {
        result[`${components.toolbar}--${this.color}`] = true
      }

      return result
    },

    $style() {
      const result = {}
      if (isHEX(this.color)) {
        result.color = this.color
      }

      return result
    }
  },

  render
}
