import { generateKey } from '@/utils'

import render from './render'

export default {
  name: 'MessageImage',

  props: {
    image: {
      type: [ String, Object ],
      required: true
    },

    owner: {
      type: String,
      default: ''
    }
  },

  computed: {
    $id() {
      if (typeof this.image === 'object') {
        return generateKey(this.image)
      }
      if (typeof this.image === 'string') {
        return ~this.image.indexOf('/') ? this.image : [ this.owner, this.image ].join('/')
      }
    }
  },

  render
}
