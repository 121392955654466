import { permissionAccepts } from './enums'

export const permissionPresets = {
  false: [ permissionAccepts.false ],
  me: [ permissionAccepts.me ],
  meUp: [ permissionAccepts.me, permissionAccepts.reseller, permissionAccepts.manager, permissionAccepts.true ],
  reseller: [ permissionAccepts.reseller ],
  resellerUp: [ permissionAccepts.reseller, permissionAccepts.manager, permissionAccepts.true ],
  manager: [ permissionAccepts.manager ],
  managerUp: [ permissionAccepts.manager, permissionAccepts.true ],
  true: [ permissionAccepts.true ]
}

