import { buttonModes } from '@/utils'

import button from '@/components/button'

export default function(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        label: this.label,
        icon: this.icon,
        disabled: this.disabled
      },
      on: {
        click: () => {
          const printWindow = window.open('', '_blank')
          printWindow.document.write(`
            <html>
              <head>
                <title>Print</title>
              </head>
              <body>
                ${this.value}
              </body>
            </html>
          `)
          printWindow.document.close()
          printWindow.focus()
          printWindow.print()
          printWindow.close()
        }
      }
    }
  )
}
