import Vue from 'vue'

import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, globalErrorHandler, globalErrorProcessor, icons, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Actions',

  props: {
    sending: {
      type: Object,
      default: () => ({})
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    massive: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      loading: false,

      providerId: undefined,

      state: false,

      refund: undefined
    }
  },

  computed: {
    icon() {
      const result = {
        approved: {
          icon: icons.done,
          color: colors.success
        },
        rejected: {
          icon: icons.close,
          color: colors.error
        }
      }
      if (this.massive) {
        result.approved = {
          icon: icons.done_all,
          color: colors.success
        }
        result.rejected = {
          icon: icons.remove_done,
          color: colors.error
        }
      }

      return result
    },

    disabled() {
      return this.loading || this.state || this.massive && !this.filter.OwnerId
    },

    gridTemplateColumns() {
      if (this.viewport.breakpoint.xl || this.massive) {
        return [ 'minmax(120px, 240px)', 'auto' ].join(' ')
      }

      return '38px'
    }
  },

  methods: {
    async createSendingsModerationTask(moderation) {
      if (this.checkPermissions(`advanced.${services.sendingsModeration}.create`, permissionPresets.true)) {
        this.loading = true

        if (moderation == MODERATION_VALUES.rejected) {
          this.providerId = undefined
        }

        try {
          await Vue.$GRequest.create(services.sendingsModeration, {
            range: this.filter.range,
            sender: this.massive ? this.filter.sender : this.sending.sender,
            pattern: this.massive ? this.filter.pattern : this.sending.text,
            refund: this.refund,
            providerId: this.providerId,
            moderation: MODERATION_VALUES[moderation],
            OwnerId: this.massive ? this.filter.OwnerId : this.sending.OwnerId || this.filter.OwnerId
          })

          this.addSnackbar({
            text: this.getTranslate('tasks.snackbars.created'),
            type: 'success'
          })

          this.$emit('clear')

          this.state = true
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))

          this.state = false
        } finally {
          this.loading = false
          this.providerId = undefined
        }
      }
    }
  },

  render
}
