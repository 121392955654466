import { platforms } from '@/utils'

let interval

export default {
  namespaced: true,

  state: {
    date: new Date(),
    findSendingFromRouteParams: false,
    platform: undefined,
    browser: {
      name: undefined,
      version: undefined
    }
  },

  mutations: {
    setDate(state, value) {
      state.date = value
    },

    setFindSendingFromRouteParams(state, value) {
      state.findSendingFromRouteParams = value
    },

    setPlatform(state, value) {
      state.platform = value
    },

    setBrowser(state, value) {
      state.browser = value
    }
  },

  actions: {
    startTimeUpdater({ commit }) {
      if (!interval) {
        interval = setInterval(() => {
          commit('setDate', new Date())
        }, 1000)
      }
    },

    setFindSendingFromRouteParams({ commit }, value) {
      commit('setFindSendingFromRouteParams', value)
    },

    setPlatform({ commit }) {
      const userAgent = navigator.userAgent.toLowerCase()
      const platformKey = Object.keys(platforms).find(key => userAgent.includes(key))
      if (platformKey) {
        commit('setPlatform', platforms[platformKey])
      }
    },

    setBrowser({ commit }) {
      const browserRegExp = new RegExp(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/, 'i')
      const result = browserRegExp.exec(navigator.userAgent) || []

      const browser = {
        name: undefined,
        version: undefined
      }

      if (result && result.length >= 3) {
        browser.name = result[1]
        browser.version = result[2]
      }

      commit('setBrowser', browser)
    }
  },

  getters: {
    date: state => state.date,
    findSendingFromRouteParams: state => state.findSendingFromRouteParams,
    platform: state => state.platform,
    browser: state => state.browser
  }
}
