import { components } from '@/utils'

import render from './render'

export default {
  name: components['group-button'],

  props: {
    small: Boolean,
    large: Boolean,
    disabled: Boolean,

    buttons: {
      type: Array,
      default: () => []
    }
  },

  render
}
