import Vue from 'vue'

import { cloneDeep } from 'lodash'

import { getHammerTime, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

const defaultDate = {
  date: undefined,
  time: getHammerTime()
}

export default {
  name: 'RunDelayPause',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,

      changeableDate: cloneDeep(defaultDate)
    }
  },

  computed: {
    computedDate() {
      if (this.changeableDate.date) {
        return new Date(this.changeableDate.date + 'T' + this.changeableDate.time)
      }

      return null
    }
  },

  methods: {
    async updateTask(id, state, startsAt = null) {
      try {
        this.loading = true
        await Vue.$GRequest.update(services.tasks, id, {
          state,
          startsAt
        })
        this.addSnackbar({
          text: this.getTranslate(`${services.tasks}.snackbars.updated`),
          type: 'success'
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
