import title from '@/components/services/providerGroups/item/title'
import date from '@/components/serviceTemplate/item/date'

function renderTitle(h) {
  return h(
    title,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'service-template-panel hover-parent hover-parent--opacity',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      renderTitle.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
