import routes from '@/routes'

import render from './render'

function filterNavChildren(children) {
  return children.filter(({ meta }) => {
    if (!meta) {
      return false
    } else {
      let hasPermit = true
      if (meta.permission) {
        hasPermit = this.checkPermissions(meta.permission, meta.permissionAccept)
      }
      if (meta && meta.nav && hasPermit) {
        return true
      }
    }
  })
}

export default {
  name: 'NavigationList',

  computed: {
    routes() {
      return routes.reduce((result, { name, meta, children }) => {
        if (meta) {
          let hasPermit = true
          if (meta.permission) {
            hasPermit = this.checkPermissions(meta.permission, meta.permissionAccept)
          }
          if (meta.nav && hasPermit) {
            result.push({
              name,
              meta,
              children: children ? filterNavChildren.call(this, children) : []
            })
          }
        }

        if (name === 'admin') {
          if (children) {
            for (const child of children) {
              if (child.meta) {
                let hasPermitChild = true
                if (child.meta.permission) {
                  hasPermitChild = this.checkPermissions(child.meta.permission, child.meta.permissionAccept)
                }
                if (child.meta.nav && hasPermitChild) {
                  result.push({
                    name: child.name,
                    meta: child.meta,
                    children: child.children ? filterNavChildren.call(this, child.children) : []
                  })
                }
              }
            }
          }
        }

        return result
      }, [])
    }
  },

  render
}
