export default {
  title: 'Подмена',

  labels: {
    fallback: 'Переотправление'
  },

  hints: {
    replacement: {
      original: 'Оригинал',
      replace: 'Подмена',
      final: 'Финальный результат'
    },
    fallbacks: 'Переотправления не были указаны'
  },

  types: {
    type: 'Тип',
    state: {
      moderation: 'Модерация'
    },
    payload: {
      sender: 'Отправитель',
      text: 'Текст'
    },
    fallbacks: 'Переотправления'
  }
}
