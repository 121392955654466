import { settingsSiteUser } from '@/utils'

import render from './render'

export default function(options) {
  options.loading = {
    find: false,
    update: false
  }

  return {
    mixins: [
      settingsSiteUser({
        serviceName: options.serviceName,
        UserId: options.UserId
      })
    ],

    data() {
      return {
        currentKey: undefined
      }
    },

    watch: {
      settingKey() {
        if (this.settingKey) {
          this.currentKey = this.settingKey
        }
      },

      loading: {
        handler() {
          options.loading.find = this.loading.find
          options.loading.update = this.loading.update
        },
        deep: true
      }
    },

    mounted() {
      if (options.renderSchema) {
        this.currentKey = options.renderSchema[0].key
      }
      if (this.settingKey) {
        this.currentKey = this.settingKey
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
