function renderItem(h, item) {
  return h(
    'div',
    {
      class: 'info-table__item'
    },
    [
      h(
        'div',
        {
          class: 'info-table__item-title'
        },
        [ item.title ]
      ),
      h(
        'div',
        {
          class: 'info-table__item-value'
        },
        [ item.value ]
      )
    ]
  )
}

function renderItems(h) {
  return h(
    'div',
    {
      class: 'info-table__items'
    },
    [ this.value.map(item => renderItem.call(this, h, item)) ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'info-table'
    },
    [ renderItems.call(this, h) ]
  )
}
