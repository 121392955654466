import { cloneDeep } from 'lodash'

import { buttonModes, colors, services } from '@/utils'

import button from '@/components/button'
import GenerateDocumentForm from '@/components/services/documentTemplates/generate/form'

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        title: this.getTranslate(`${services.invoices}.labels.create`),
        value: this.show,
        rounded: true,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.$emit('show', {
            event,
            validation: this.validation
          })
        }
      }
    },
    [
      h(
        GenerateDocumentForm,
        {
          props: {
            value: this.proxy,
            OwnerId: this.OwnerId,
            ResellerId: this.ResellerId
          },
          on: {
            input: event => {
              this.proxy = event
            },
            validation: event => {
              this.formValidation = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.$emit('show', {
                    event: false,
                    validation: this.validation
                  })
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.detach'),
                mode: buttonModes.flat,
                color: colors.error
              },
              on: {
                click: () => {
                  this.$emit('input', false)
                  this.$emit('show', {
                    event: false,
                    validation: this.validation
                  })
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.save'),
                color: colors.primary,
                disabled: !this.validation
              },
              on: {
                click: () => {
                  // здесь нужен cloneDeep чтобы когда форма GenerateDocumentForm
                  // данные по ссылке снаружи не превратились в undefined
                  // так как у этой формы есть цикл beforeDestroy
                  // который обнуляет все поля
                  this.$emit('input', cloneDeep(this.proxy))
                  this.$emit('show', {
                    event: false,
                    validation: this.validation
                  })
                }
              }
            }
          )
        ]
      )
    ]
  )
}
