import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

export const sendingTypeColors = {
  [SENDING_TYPES.sms]: '#0D47A1',
  [SENDING_TYPES.smsping]: '#0D47A1',
  [SENDING_TYPES.email]: '#0099e1',
  [SENDING_TYPES.viber]: '#59267c',
  [SENDING_TYPES.whatsapp]: '#00E676',
  [SENDING_TYPES.vk]: '#4c75a3',
  [SENDING_TYPES.ok]: '#ed812b',
  [SENDING_TYPES.telegram]: '#0088cc',
  [SENDING_TYPES.facebook]: '#3b5998',
  [SENDING_TYPES.instagram]: '#e4405f',
  [SENDING_TYPES.push]: '#0099e1',
  [SENDING_TYPES.voice]: '#F48FB1',
  [SENDING_TYPES.flashcall]: '#E91E63',
  [SENDING_TYPES.whatsappchat]: '#FFD668',
  [SENDING_TYPES.viberchat]: '#FFD668',
  [SENDING_TYPES.vkchat]: '#FFD668',
  [SENDING_TYPES.telegramchat]: '#FFD668',
  [SENDING_TYPES.facebookchat]: '#FFD668',

  apisender: '#FF8F00',
  hlr: '#2E7D32'
}

export default { sendingTypeColors }
