export const PAYMENTS_FILTER = {
  method: undefined,
  status: undefined,
  createdAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAtPreset: 'today',
  OwnerId: undefined,
  RecurrentPaymentId: undefined,
  $scope: [ 'full' ]
}

export default { PAYMENTS_FILTER }
