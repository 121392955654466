import proxy from '@sigma-legacy-libs/g-proxy'

import { services } from '@/utils'
import { getChannelTypes } from '@/components/services/otp-settings/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isNew: Boolean,
    disabled: Boolean
  },

  computed: {
    validation() {
      return !!this.proxy.name
      && !!this.proxy.channelType
      && !!this.proxy.conditions.length
      && !!this.proxy.additionalSettings.senderId
      && !!this.proxy.additionalSettings.template
    },

    channelTypes() {
      return getChannelTypes(this.sendingTypes, services['otp-settings/templates']).map(value => {
        return {
          title: this.getTranslate(`sendings.types.${value}`),
          value
        }
      })
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}

