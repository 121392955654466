import { DICTIONARIES } from '@sigma-legacy-libs/essentials/lib/constants'

export function dictionaryToService(dictionaryType) {
  switch (dictionaryType) {
    case DICTIONARIES['phone:mnp']:
      return 'mnp'
    case DICTIONARIES['phone:ranges']:
      return 'prefixes'
    case DICTIONARIES['phone:opgroups']:
      return 'operatorGroups'
  }
}

export default { dictionaryToService }
