import { components } from '@/utils'

export default function(h) {
  return h(
    this.component,
    {
      class: components.input,
      attrs: this.attributes,
      domProps: { value: this.proxy },
      on: {
        input: this._inputHandler,
        change: this._eventHandler,
        click: this._eventHandler,
        focus: this._eventHandler,
        blur: this._eventHandler,
        mousedown: this._eventHandler,
        mouseup: this._eventHandler,
        keypress: this._eventHandler,
        keydown: this._eventHandler,
        keyup: this._eventHandler,
        paste: this._eventHandler
      }
    }
  )
}
