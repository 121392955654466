import { colors, colorsHEX } from '@/utils'

function renderChildren(h) {
  if (Array.isArray(this.children) && this.children.length > 0) {
    return this.children.map(({ name, attributes, children }) => {
      if (this.opacity && attributes.opacity) {
        attributes.opacity = this.opacity
      }

      const style = {}
      if (attributes.fill) {
        style.fill = attributes.fill
      }

      if (attributes['stroke-width']) {
        style.stroke = colorsHEX[this.color || colors.text]
        style.fill = 'none'
      }

      return h(
        name,
        {
          attrs: attributes,
          style
        },
        children?.map(child => renderChildren.call(this, h, child))
      )
    })
  }
}

export default function(h) {
  return h(
    'svg',
    {
      attrs: {
        viewBox: this.viewBox,
        height: this.height,
        width: this.width,
        fill: this.fill,
        xmlns: 'http://www.w3.org/2000/svg'
      }
    },
    [ renderChildren.call(this, h) ]
  )
}
