import { permissionPresets } from '@/utils'

import render from './render'

export default {
  name: 'Dialog',

  props: {
    value: null,

    show: Boolean
  },

  data() {
    return {
      showDialog: false,

      currentTab: 'source'
    }
  },

  computed: {
    tabs() {
      const result = [ 'source' ]
      if (this.checkPermissions('advanced.apisender.get', permissionPresets.true)) {
        result.push('eventsHistory')
      }

      return result
    }
  },

  watch: {
    show() {
      this.showDialog = !!this.show
    }
  },

  mounted() {
    this.showDialog = !!this.show
  },

  render
}

