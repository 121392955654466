export default {
  total: 'Итого',

  no: {
    data: {
      text: 'Нет доступных данных'
    }
  },

  contents: {
    lastMonthSum: 'Реферальные начисления за',
    referralCode: 'Реферальный код: '
  },

  headers: {
    amount: 'Сумма',
    referral: 'Реферал',
    date: 'Дата'
  }
}
