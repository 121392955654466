import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, debounce, isEqual } from 'lodash'
import { generateRegExpFromString } from '@sigma-legacy-libs/essentials/lib/utils/regexp'

import { serviceName } from '@/components/services/routingRules/utils'

import ConditionsFrontend from '@/utils/classes/Conditions'

import render from './render'

export default function(options) {
  const tag = options?.tag
  let type = 'array'
  switch (tag) {
    case 'recipient':
    case 'moderation':
    case 'text': {
      type = 'string'
      break
    }

    case 'mccmnc': {
      type = 'object'
      break
    }
  }

  return {
    name: 'Dialog',

    mixins: [ proxy({ type }) ],

    props: {
      tag: String,
      tagEdit: Boolean,

      tagDisabled: {
        type: Array,
        default: () => []
      },

      show: Boolean,

      readonly: Boolean
    },

    data() {
      return {
        pattern: undefined,
        text: undefined,
        phone: undefined,
        phoneCheck: undefined,
        phoneCheckLoading: false,
        error: undefined
      }
    },

    watch: {
      proxy: {
        handler() {
          this.error = undefined
          this.phoneCheck = undefined
          this.checkPhone()
        },
        deep: true
      },
      phone() {
        this.checkPhone()
      },

      show() {
        this.watchValueHandler()
      }
    },

    methods: {
      watchProxyHandler() {
        return
      },

      watchValueHandler() {
        const value = cloneDeep(this._inputFilter(this.value))
        if (!isEqual(this.proxy, value)) {
          this.proxy = value
        }
      },

      checkPattern() {
        if (this.tag === 'text') {
          if (this.text) {
            try {
              const pattern = generateRegExpFromString(this.proxy)

              return pattern.test(this.text)
            } catch (error) {
              this.error = this.getTranslate(`${serviceName}.errors.regexp`)

              return false
            }
          }

          return true
        }
      },

      checkPhone: debounce(async function() {
        if (this.phone) {
          this.phoneCheckLoading = true
          const result = await ConditionsFrontend.check({
            tag: this.tag,
            value: this.value
          },
          { payload: { recipient: ~this.phone.indexOf('+') ? this.phone : '+' + this.phone } })
          if (result) {
            this.phoneCheck = 'success'
            this.error = undefined
          } else {
            this.phoneCheck = 'error'
            this.error = this.getTranslate(`${serviceName}.errors.phone`)
          }
          this.phoneCheckLoading = false
        } else {
          this.phoneCheck = undefined
          this.error = undefined
        }
      }, 500, {
        leading: false,
        trailing: true
      })
    },

    render
  }
}
