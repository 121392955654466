import { inputModes } from '@/utils'

import titleDivider from '@/components/title/divider'

function renderSelect(h, key, type, index) {
  const regexps = {
    hours: /^([01]?[0-9]|2[0-3])$/,
    minutes: /^(0?[0-9]|[1-5][0-9])$/,
    seconds: /^(0?[0-9]|[1-5][0-9])$/
  }

  return h(
    'g-text-field',
    {
      props: {
        value: this.time[key][type],
        disabled: this.disabled,
        label: this.getTranslate(`misc.measure.time.${type}`),
        suffix: this.getTranslate(`misc.measure.time.short.${type}`),
        mode: inputModes['line-label'],
        details: false,
        rounded: true,
        dense: true,
        mask: regexps[type],
        tabindex: index + 1
      },
      on: {
        input: value => {
          this.time[key][type] = value
        }
      },
      key: `${key}-${type}-${index}`
    }
  )
}

function renderSections(h) {
  return h(
    'div',
    {
      class: 'filter-date-time__time-sections'
    },
    [ 'gt', 'lt' ].map(key => {
      return h(
        'div',
        {
          class: 'grid grid-gap--12'
        },
        [
          h(
            titleDivider,
            {
              props: {
                value: this.getTranslate(`filter.time.${key}`)
              }
            }
          ),
          h(
            'div',
            {
              class: {
                'filter-date-time__time-section': true,
                'filter-date-time__time-section--labels': this.labels && !this.hints,
                'filter-date-time__time-section--hints': this.hints && !this.labels
              }
            },
            [ 'hours', 'minutes', 'seconds' ].map((type, index) => renderSelect.call(this, h, key, type, index))
          )
        ]
      )
    })
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'filter-date-time__time'
    },
    [ renderSections.call(this, h) ]
  )
}
