import { colors, eventsHistoryColors, eventsHistoryIcons, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import date from '@/components/serviceTemplate/item/date'
import EditButton from '@/components/services/eventsHistory/item/buttons/edit'

function renderService(h) {
  if (this.viewport.size.width < 768) {
    return
  }

  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(this.service.title),
        label: this.getTranslate(`${services.eventsHistory}.labels.service`),
        to: {
          name: this.service.name,
          params: { id: this.value.service === 'contactLists' ? this.service.entityId : undefined },
          query: { currentTab: this.service.currentTab }
        }
      }
    }
  )
}

function renderEntityId(h) {
  return h(
    sticker,
    {
      props: {
        value: { entityId: this.service.entityId },
        label: this.getTranslate(`${services.eventsHistory}.labels.id`),
        pathToTitle: 'entityId',
        borderStyle: this.computedTo ? 'solid' : 'dashed',
        icon: eventsHistoryIcons[this.value.type],
        color: eventsHistoryColors[this.value.type],
        iconTooltip: this.getTranslate(`${services.eventsHistory}.types.${this.value.type}`),
        to: this.computedTo
      }
    }
  )
}

function renderUser(h) {
  if (this.viewport.breakpoint.smDown) {
    return
  }

  return h(
    sticker,
    {
      props: {
        value: this.value.User,
        label: this.getTranslate(`${services.eventsHistory}.labels.author`),
        pathToTitle: 'username',
        icon: icons.person,
        color: colors.grey
      }
    }
  )
}

function renderButtons(h) {
  return h(
    'div',
    {
      class: {
        'eventsHistory-panel__buttons': true,
        'hover-child': this.viewport.breakpoint.mdUp
      }
    },
    [
      h(
        EditButton,
        {
          props: {
            id: this.value.id
          }
        }
      )
    ]
  )
}

function renderDetails(h) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}-panel__details`
    },
    [
      renderEntityId.call(this, h),
      renderService.call(this, h),
      renderUser.call(this, h)
    ]
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${services.eventsHistory}-panel`]: true,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderDetails.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
