export default {
  labels: {
    accent: 'Accent',
    error: 'Error',
    info: 'Info',
    primary: 'Primary',
    secondary: 'Secondary',
    success: 'Success',
    warning: 'Warning'
  },

  hints: {
    accent: 'Accent color',
    error: 'Error color',
    info: 'Info color',
    primary: 'Primary color',
    secondary: 'Secondary color',
    success: 'Success color',
    warning: 'Warning color'
  }
}
