import { allowedSendingTypes, inputModes } from '@/utils'

import SendingTypes from '@/components/misc/SendingTypes'

export default function(h, { path, serviceName }) {
  return h(
    SendingTypes,
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        items: allowedSendingTypes,
        mode: inputModes['line-label'],
        required: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
