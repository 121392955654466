import { components } from '@/utils'

import render from './render'

export default {
  name: components.sidebar,

  props: {
    value: Boolean,
    mobile: Boolean
  },

  render
}
