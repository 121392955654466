export function prioritizeAccountInList(items) {
  if (this.search) {
    return items
  }

  const index = items.findIndex(item => item.id === this.account.id)
  if (index > 0) {
    const [ item ] = items.splice(index, 1)
    items.unshift(item)
  } else if (index === -1) {
    items.unshift(this.account)
  }

  return items
}
