export default {
  title: 'Журнал событий платежей',

  labels: {
    type: 'Тип',
    id: 'Id'
  },

  tabs: {
    request: 'Запрос',
    response: 'Ответ'
  },

  directions: {
    incoming: 'Входящий',
    outgoing: 'Исходящий'
  },

  hints: {
    notFound: 'Не найдено ни одной записи'
  }
}
