import { components } from '@/utils'

import render from './render'

export default {
  name: components.subheader,

  props: {
    value: String,

    dense: Boolean
  },

  render
}
