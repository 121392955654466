import { isStringNotEmpty, projectName } from '@/utils'

const _ = { uniqueId: require('lodash/uniqueId') }

export default {
  namespaced: true,

  state: {
    snackbars: [],
    localSnackbars: []
  },

  mutations: {
    addSnackbar(state, snackbar) {
      if (window.innerWidth > 768) {
        state.snackbars.push(snackbar)
      } else {
        const prefixKey = `${projectName}:notifications`
        const snackbars = JSON.parse(window.localStorage.getItem(prefixKey)) || []
        const data = {
          ...snackbar,
          timestamp: Date.now()
        }
        state.localSnackbars.push(data)
        window.localStorage.setItem(prefixKey, JSON.stringify([
          ...snackbars,
          data
        ]))
      }
    },

    updateSnackbar(state, snackbar) {
      if (snackbar && isStringNotEmpty(snackbar.id)) {
        const index = state.snackbars.findIndex(item => item.id === snackbar.id)
        if (~index) {
          state.snackbars.splice(index, 1, Object.assign({}, state.snackbars[index], snackbar))
        }
      }
    },

    removeSnackbar(state, id) {
      if (isStringNotEmpty(id)) {
        const index = state.snackbars.findIndex(item => item.id === id)
        if (~index) {
          state.snackbars.splice(index, 1)
        }
      }
    }
  },

  actions: {
    addSnackbar({ dispatch, commit }, snackbar) {
      const id = _.uniqueId('snackbar-')
      const duration = snackbar.duration || 10

      if (snackbar.button && snackbar.button.on && typeof snackbar.button.on.click === 'function') {
        const callback = snackbar.button.on.click
        snackbar.button.on.click = () => {
          dispatch('removeSnackbar', id)
          callback()
        }
      }

      commit('addSnackbar', {
        id,
        text: snackbar.text,
        type: snackbar.type,
        button: snackbar.button,
        classes: snackbar.classes,
        cancelable: snackbar.cancelable || true,
        remove: () => dispatch('removeSnackbar', id),
        interval: snackbar.interval || setInterval(() => dispatch('removeSnackbar', id), duration * 1000),
        duration
      })
    },

    removeSnackbar({ state, commit }, id) {
      const index = state.snackbars.findIndex(item => item.id === id)
      if (~index) {
        if (state.snackbars[index].interval) {
          clearInterval(state.snackbars[index].interval)
        }
        commit('updateSnackbar', {
          id,
          classes: [ 'fade-out-down' ]
        })
        setTimeout(() => commit('removeSnackbar', id), 150)
      }
    }
  },

  getters: {
    snackbars: state => state.snackbars,
    localSnackbars: state => state.localSnackbars
  }
}
