export function hexToRgb(hex, asString) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (result) {
    const object = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    if (asString) {
      return Object.keys(object).map(key => object[key])
        .join(',')
    }

    return object
  }
}

export default { hexToRgb }
