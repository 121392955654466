import { FILE_TYPES } from '@/utils'

import render from './render'

export default {
  name: 'FileType',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    showPlayer: {
      type: Boolean,
      default: true
    },

    small: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    fileType() {
      let icon = FILE_TYPES.file.icon
      let color = FILE_TYPES.file.color

      if (this.value) {
        if (/(ms-?word|wordprocessingml)/.test(this.value.type)) {
          icon = FILE_TYPES.doc.icon
          color = FILE_TYPES.doc.color
        }
        if (/(ms-excel|spreadsheetml)/.test(this.value.type)) {
          icon = FILE_TYPES.csv.icon
          color = FILE_TYPES.csv.color
        }
        if (/.*?\/csv/.test(this.value.type)) {
          icon = FILE_TYPES.csv.icon
          color = FILE_TYPES.csv.color
        }
        if (/.*?\/pdf/.test(this.value.type)) {
          icon = FILE_TYPES.pdf.icon
          color = FILE_TYPES.pdf.color
        }
        if (~this.value.type.indexOf('image/')) {
          icon = FILE_TYPES.jpg.icon
          color = FILE_TYPES.jpg.color
        }
        if (~this.value.type.indexOf('audio/')) {
          icon = FILE_TYPES.wav.icon
          color = FILE_TYPES.wav.color
        }
        if (~this.value.type.indexOf('video/')) {
          icon = FILE_TYPES.mp4.icon
          color = FILE_TYPES.mp4.color
        }
      }

      return {
        icon,
        color
      }
    },

    OwnerId() {
      return this.value.OwnerId || this.value.Owner && this.value.Owner.id || undefined
    }
  },

  render
}
