export const RECURRENT_PAYMENTS_FILTER = {
  id: undefined,
  type: undefined,
  method: undefined,
  status: undefined,
  OwnerId: undefined,
  $scope: [ 'full' ]
}

export default { RECURRENT_PAYMENTS_FILTER }
