import { colors, icons } from '@/utils'

import render from './render'

export default {
  name: 'Info',

  props: {
    value: String,

    icon: {
      type: String,
      default: icons.info_outline,
      validator: value => {
        return Object.values(icons).includes(value)
      }
    },

    iconSVG: {
      type: String,
      default: undefined
    },

    color: {
      type: String,
      default: colors.info,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    rounded: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    class() {
      return {
        ['info']: true,
        [`info--${this.color}`]: !!this.color,
        ['info--rounded']: this.rounded
      }
    },

    hasContent() {
      return !!this.value || this.$slots.default
    }
  },

  render
}
