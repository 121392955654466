import { buttonModes } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import button from '@/components/button'

function renderConfirmDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        closeOnClick: true,
        closeOnEsc: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate('commons.titles.confirm.remove') },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate('commons.contents.confirm.remove')
      ),

      h(
        'div',
        {
          class: 'pa-2 fjcfe facfe grid-gap--8',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                mode: buttonModes.clear,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                mode: buttonModes.flat,
                color: 'error',
                disabled: this.loading,
                loading: this.loading
              },
              on: {
                click: () => {
                  this.remove()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  if (this.checkPermissions(this.$permissions.path, this.$permissions.accept)) {
    return h(
      this.component === 'btn' ? button : this.component,
      {
        class: this.component === 'g-button' ? 'ma-0' : undefined,
        props: this.$props,
        on: {
          click: event => {
            if (this.component !== 'g-list-item') {
              event.stopPropagation()
            }
            this.showDialog = true
          }
        },
        scopedSlots: {
          dialog: () => {
            return renderConfirmDialog.call(this, h)
          }
        }
      }
    )
  }
}
