import { serviceName } from '@/components/services/requisites/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(`${serviceName}.vat.${this.vat}`),
        label: this.getTranslate(`${serviceName}.labels.vat`),
        borderStyle: 'dashed'
      }
    }
  )
}
