import SettingsSiteTemplate from '@/components/services/settingsSite/template'

const service = 'settingsSite'

function renderResellerSelect(h) {
  if (this.checkPermissions(`advanced.${service}.get`)) {
    return h(
      'g-select',
      {
        props: {
          value: this.UserId,
          label: this.getTranslate(`${service}.labels.chooseReseller`),
          itemsDisabled: [ this.account.id ],
          service: 'users',
          query: { isActive: true },
          itemTitle: 'username',
          mode: 'outline',
          dense: true,
          rounded: true,
          clearable: true,
          details: false,
          autocomplete: true
        },
        on: {
          input: event => {
            this.UserId = event
          }
        }
      }
    )
  }
}

function renderContent(h) {
  const props = {
    rounded: true,
    outline: true
  }

  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'max-width': '800px' }
    },
    [
      h(
        'div',
        {
          style: { 'max-width': this.viewport.breakpoint.mdUp ? '260px' : undefined }
        },
        [ renderResellerSelect.call(this, h) ]
      ),

      h(
        SettingsSiteTemplate({
          UserId: this.UserId,
          card: { props },
          panels: { props }
        })
      )
    ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
