import { moderationColors, moderationIcons } from '@/utils'

import squircle from '@/components/icon/squircle'
import overflowString from '@/components/misc/overflowString'

export default function(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [
      h(
        squircle,
        {
          props: {
            iconSize: this.size,
            icon: moderationIcons[this.type],
            color: moderationColors[this.type]
          }
        }
      ),

      h(
        overflowString,
        {
          props: {
            value: this.title,
            font: { size: 13 }
          }
        }
      )
    ]
  )
}
