import render from './render'

export default {
  name: 'ConfirmRemoveButton',

  props: {
    title: String,
    content: String,
    buttonText: String,

    maxWidth: {
      type: String,
      default: '400px'
    },

    callback: {
      type: Function,
      default: v => v
    },

    rounded: {
      type: Boolean,
      default: true
    },
    small: Boolean,
    large: Boolean,
    depressed: Boolean,
    flat: {
      type: Boolean,
      default: true
    },

    size: {
      type: Number,
      default: 21
    },

    loading: Boolean,
    disabled: Boolean,

    stopPropagation: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      show: false
    }
  },

  render
}
