import { components } from '@/utils'

function renderActivator(h) {
  return h(
    'div',
    {
      class: `${components.menu}__activator`,
      on: {
        click: () => {
          if (!this.disabled) {
            this.proxy = !this.proxy
          }
        }
      },
      ref: 'activator'
    },
    [ this.$scopedSlots.activator ? this.$scopedSlots.activator() : this.$slots.activator ]
  )
}

function renderContent(h) {
  return h(
    'div',
    {
      class: {
        [`${components.menu}__content`]: true,
        [`${components.menu}__content--active`]: !!this.popperInstance || this.proxy,
        [`${components.menu}__content--rounded`]: this.rounded,
        [`${components.menu}__content--transparent`]: this.transparent
      },
      style: this.styles,
      on: {
        click: event => {
          this.contentClickHandler(event)
        }
      },
      ref: 'content'
    },
    [ this.$scopedSlots.default ? this.$scopedSlots.default() : this.$slots.default ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${components.menu}`]: true,
        [`${components.menu}--disabled`]: this.disabled
      },
      ref: 'holder'
    },
    [ renderActivator.call(this, h), renderContent.call(this, h) ]
  )
}
