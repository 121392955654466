import { cloneDeep, get, isEqual } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { currency } from '@/utils'

import { serviceName } from '@/components/services/apisender/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }), currency ],

  props: {
    isNew: Boolean,

    updatedData: null
  },

  data() {
    return {
      showDialog: false,
      initProxy: undefined
    }
  },

  computed: {
    validation() {
      return !!this.priceByTariff
    },
    priceByTariff() {
      const tariffs = cloneDeep(this.account.Tariffs)
      tariffs.sort((a, b) => a.usersTariffs.priority - b.usersTariffs.priority)
      const tariff = tariffs.find(({ source }) => source === `subscription.${serviceName}`)

      return get(tariff, 'usersTariffs.finalRules[0].price[0]', false)
    },
    proxyChanged() {
      return !isEqual(this.proxy, this.initProxy)
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    },

    updatedData: {
      handler() {
        this.initProxy = cloneDeep(this.proxy)
      },
      deep: true
    }
  },

  mounted() {
    this.getCurrency(this.account.id)
    this.initProxy = cloneDeep(this.proxy),
    this.$emit('validation', this.validation)
  },

  render
}
