import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, icons, notificationsColors, services, sizes } from '@/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import overflowString from '@/components/misc/overflowString'
import BalanceLimit from '@/components/services/notifications/dialog/balance/limit'

function renderTitle(h) {
  if (this.viewport.size.width > 768) {
    return h(
      overflowString,
      {
        props: {
          value: this.getTranslate(this.path),
          font: { size: 13 }
        }
      }
    )
  }

  return h(
    tag,
    {
      props: {
        label: this.getTranslate(this.path),
        color: colors.grey
      }
    }
  )
}
function renderEditButton(h) {
  if (this.hasSettings) {
    return h(
      button,
      {
        props: {
          icon: icons.edit,
          mode: buttonModes.flat,
          size: sizes.small
        }
      }
    )
  }

  return h('div')
}
function renderChannel(h, channel) {
  const loading = this.loading.includes(channel)
  const disabled = !~this.availableChannels.indexOf(channel)

  return h(
    button,
    {
      props: {
        label: this.getTranslate(`${services.sendings}.types.${channel}`),
        size: sizes.tiny,
        color: disabled ? colors.grey : notificationsColors[channel],
        mode: !this.proxy.channels.includes(channel) ? disabled ? buttonModes.clear : buttonModes.flat : buttonModes.filled,
        loading,
        disabled: disabled || loading
      },
      key: [ this.path, channel ].join(':'),
      on: {
        click: event => {
          event.stopPropagation()
          this.update(channel)
          this.$emit('click', channel)
        }
      }
    }
  )
}
function renderChannels(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-${services.settingsUser}-panel__channels`
    },
    [ NOTIFICATIONS_AVAILABLE_TYPES.map(type => renderChannel.call(this, h, type)) ]
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-${services.settingsUser}-panel__header`
    },
    [
      renderTitle.call(this, h),
      renderEditButton.call(this, h),
      renderChannels.call(this, h)
    ]
  )
}

function renderBodyByPath(h) {
  switch (this.path) {
    case 'notifications.events.balance.limit':
    case 'notifications.childrenUsersEvents.balance.limit': {
      return h(
        BalanceLimit,
        {
          props: {
            value: this.proxy.settings,
            UserId: this.options.UserId,
            ResellerId: this.options.ResellerId
          },
          on: {
            input: event => {
              this.proxy.settings = event
            }
          }
        }
      )
    }
  }
}
function renderBody(h) {
  return h(
    'div',
    {
      class: `${services.notifications}-${services.settingsUser}-panel__body`
    },
    [ renderBodyByPath.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'g-expansion-panel',
    {
      props: { preventClick: !this.hasSettings },
      scopedSlots: {
        header: ({ expanded }) => renderHeader.call(this, h, expanded),
        default: () => renderBody.call(this, h)
      },
      key: this.path
    }
  )
}
