import { buttonModes, colors, components, icons, services } from '@/utils'

import form from '@/components/services/documentTemplates/generate/form'
import button from '@/components/button'

function renderDialog(h) {
  return h(
    components.dialog,
    {
      props: {
        value: this.showDialog,
        title: this.getTranslate(`${services.documentTemplates}.titles.generate`),
        rounded: this.viewport.breakpoint.smUp,
        closeOnEsc: false,
        closeOnClick: false,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        form,
        {
          props: {
            value: this.payload,
            disabled: this.loading,
            OwnerId: this.OwnerId,
            ResellerId: this.ResellerId
          },
          on: {
            input: event => {
              this.proxy = event
            },
            validation: event => {
              this.$emit('validation', event)
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.download'),
                color: colors.primary,
                loading: this.loading,
                disabled: this.loading || !this.validation
              },
              on: {
                click: () => {
                  this.generate()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: icons.description,
        mode: buttonModes.flat,
        tooltip: this.getTranslate(`${services.documentTemplates}.buttons.download`)
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
