export default {
  title: 'Получить отчёт',

  titles: {
    encoding: 'Кодировка файла',
    settings: 'Тонкая настройка кодировки'
  },

  labels: {
    encoding: 'Кодировка',
    delimiter: 'Разделитель',
    includeRefunded: 'Включить возвраты'
  },

  warnings: {
    beforeRequest: '<b>Для формирования отчета требуется от 10 до 60 минут.</b>',
    repeatRequest: 'Повторный запрос идентичного отчета увеличивает время на формирование.',
    afterRequest: 'После нажатия на кнопку "Запросить отчет" формирование отчета продолжится на нашем сервере. Отчет будет доступен после формирования в разделе "Файлы".'
  },

  snackbars: {
    requested: 'Отчёт успешно запрошен',
    already: {
      requested: 'Отчёт уже запрошен. Необходимо подождать пока он будет сформирован и будет доступен в разделе "Файлы".'
    }
  }
}
