import Vue from 'vue'

import render from './render'

export default {
  name: 'Connection',

  data() {
    return {
      ws: {
        connected: Vue.$socket.connected,
        error: undefined
      }
    }
  },

  mounted() {
    Vue.$socket.on('connect', () => Vue.set(this.ws, 'connected', true))
    Vue.$socket.on('disconnect', () => Vue.set(this.ws, 'connected', false))
    Vue.$socket.on('error', error => Vue.set(this.ws, 'error', error))

    setInterval(() => {
      Vue.set(this.ws, 'connected', Vue.$socket.connected)
    }, 5 * 1000)
  },

  render
}
