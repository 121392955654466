import { inputModes, services } from '@/utils'

import rulesConditions from '@/components/misc/rules/conditions'
import SendernameSelect from '@/components/misc/SendernameSelect'
import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'
import info from '@/components/misc/info'

const serviceNameViaPoint = services['otp-settings/templates'].split('/').join('.')

function renderNameField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.name,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.name`),
        disabled: this.disabled,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        autofocus: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.name = event
        }
      }
    }
  )
}
function renderSenderNameField(h) {
  return h(
    SendernameSelect,
    {
      props: {
        value: this.proxy.additionalSettings.senderId,
        type: this.proxy.channelType,
        disabled: this.disabled,
        mode: inputModes['line-label'],
        itemValue: 'id',
        dense: true
      },
      on: {
        input: event => {
          this.proxy.additionalSettings.senderId = event
        }
      }
    }
  )
}
function renderChannelTypeField(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.channelType,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.channelType`),
        items: this.channelTypes,
        mode: inputModes['line-label'],
        disabled: this.disabled,
        dense: true,
        rounded: true,
        details: false,
        required: true
      },
      on: {
        input: event => {
          this.proxy.channelType = event
        }
      },
      scopedSlots: {
        selection: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title
              }
            }
          )
        },
        item: ({ item }) => {
          return h(
            RowWithMessageTypeIcon,
            {
              props: {
                value: item.value,
                title: item.title,
                as: 'text'
              }
            }
          )
        }
      }
    }
  )
}
function renderTemplateField(h) {
  return h(
    'g-textarea',
    {
      props: {
        value: this.proxy.additionalSettings.template,
        label: this.getTranslate(`${serviceNameViaPoint}.labels.additionalSettings.template`),
        hint: this.getTranslate(`${serviceNameViaPoint}.hints.additionalSettings.template`),
        mode: inputModes['line-label'],
        disabled: this.disabled,
        dense: true,
        rounded: true,
        required: true
      },
      on: {
        input: event => {
          this.proxy.additionalSettings.template = event
        }
      }
    }
  )
}

function renderConditions(h) {
  return h(
    rulesConditions,
    {
      props: {
        value: this.proxy.conditions,
        whitelist: [ 'type', 'recipient', 'mccmnc', 'contactList', 'group' ]
      },
      on: {
        input: event => {
          this.proxy.conditions = event
        }
      }
    }
  )
}
function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.getTranslate(`${serviceNameViaPoint}.hints.conditions`)
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid',
      style: { 'grid-template-columns': '100%' }
    },
    [
      h(
        'div',
        {
          class: {
            grid: true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          renderNameField.call(this, h),
          renderChannelTypeField.call(this, h),
          renderSenderNameField.call(this, h)
        ]
      ),
      renderTemplateField.call(this, h),
      renderConditions.call(this, h),
      renderInfo.call(this, h)
    ]
  )
}
