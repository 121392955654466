import { borderStyles, colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.payload.text,
        label: this.getTranslate(`${services.patterns}.labels.text`),
        icon: icons.text_snippet,
        color: colors.grey,
        width: 'fit-content',
        borderStyle: borderStyles.dashed
      }
    }
  )
}
