function renderContent() {
  if (this.value) {
    return `${this.value}`
  }

  return this.$slots.default
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        ['badge']: true,
        ['badge--top']: this.top,
        ['badge--bottom']: this.bottom,
        ['badge--left']: this.left,
        ['badge--right']: this.right,
        [`badge--${this.color}`]: true,
        ['badge--offset-in']: this.offsetIn,
        ['badge--offset-out']: this.offsetOut
      },
      style: {
        'min-height': `${this.size}px`,
        'max-height': `${this.size}px`,
        height: `${this.size}px`,
        'min-width': `${this.size}px`,
        'max-width': `${this.size}px`,
        width: `${this.size}px`
      }
    },
    [ renderContent.call(this, h) ]
  )
}
