export const sudoData = {
  data() {
    return {
      sudoData: {
        showDialog: false,
        source: undefined
      }
    }
  }
}

export default { sudoData }
