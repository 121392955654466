export default {
  presets: 'Пресеты',
  clear: 'Очистить фильтр',

  date: {
    title: 'Дата',
    label: 'Фильтр по дате и времени',
    from: 'Дата с',
    by: 'Дата до',
    empty: 'Выберите дату',

    titles: {
      createdAt: 'Выберите дату создания',
      startsAt: 'Выберите дату запуска',
      endsAt: 'Выберите дату окончания'
    },

    labels: {
      createdAt: 'Диапазон дат создания',
      startsAt: 'Диапазон дат запуска'
    },

    presets: {
      minutes: {
        5: '5 минут',
        15: '15 минут',
        30: '30 минут'
      },
      hours: {
        1: '1 час',
        3: '3 часа',
        6: '6 часов'
      },
      last: {
        week: 'Прошлая неделя',
        month: 'Прошлый месяц'
      },
      current: {
        week: 'Текущая неделя',
        month: 'Текущий месяц'
      },
      yesterday: 'Вчера',
      today: 'Сегодня'
    }
  },

  time: {
    gt: 'Начало временного диапазона',
    lt: 'Конец временного диапазона',
    from: 'Время с',
    by: 'Время до',
    labels: 'Подписи',
    hints: 'Подсказки',

    current: {
      time: 'Текущее время',
      date: 'Текущая дата'
    },

    tooltips: {
      current: {
        time: 'Установить текущее время'
      }
    }
  }
}
