import Vue from 'vue'

import { cloneDeep, debounce, isEqual } from 'lodash'
import { permission as SCHEMA } from '@sigma-legacy-libs/essentials/lib/schemas'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

const findString = (value, string) => !!~value.toLowerCase().indexOf(string.toLowerCase())

export default function(options) {
  return {
    name: services.permissions,

    data() {
      return {
        loading: {
          find: false,
          update: {},
          preset: {}
        },

        permissions: undefined,
        permissionSearch: '',

        showInfoDialog: false
      }
    },

    computed: {
      $permissions() {
        const result = {}
        const pathToTranslate = `${services.permissions}.services`

        let schema = SCHEMA.RESELLER_PERMISSIONS_SCHEMA
        if (this.checkPermissions(`advanced.${services.users}.setPermissions`)) {
          schema = SCHEMA.PERMISSIONS_SCHEMA
        }

        for (const key in schema) {
          let [ root, service, action, sub ] = key.split('.')

          if (root) {
            switch (root) {
              case 'advanced': {
                if (service && action) {
                  if (sub) {
                    service = [ service, action ].join('.')
                    action = sub
                  }
                  if (findString(service, this.permissionSearch) || findString(this.getTranslate(`${pathToTranslate}.${service}`), this.permissionSearch)) {
                    if (!result[service]) {
                      result[service] = {
                        group: service,
                        title: this.getTranslate(`${pathToTranslate}.${service}`),
                        children: []
                      }
                    }

                    result[service].children.push(
                      Object.assign(
                        {
                          $key: key,
                          $name: action
                        },
                        schema[key]
                      )
                    )
                  }
                }
                break
              }
              case 'secret': {
                if (findString(root, this.permissionSearch) || findString(this.getTranslate(`${pathToTranslate}.${root}`), this.permissionSearch)) {
                  if (service) {
                    if (!result[root]) {
                      result[root] = {
                        group: root,
                        title: this.getTranslate(`${pathToTranslate}.${root}`),
                        children: []
                      }
                    }

                    result[root].children.push(
                      Object.assign(
                        {
                          $key: key,
                          $name: service
                        },
                        schema[key]
                      )
                    )
                  }
                }
                break
              }
            }
          }
        }

        return Object.values(result).sort((a, b) => a.title.localeCompare(b.title))
      }
    },

    mounted() {
      this.findPermissions()
    },

    methods: {
      async findPermissions() {
        try {
          this.loading.find = true
          const { data } = await Vue.$GRequest.find([ services.permissions, options.UserId ].filter(Boolean).join('/'))
          if (data) {
            this.permissions = data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading.find = false
        }
      },

      updatePermission: debounce(
        async function(field, value) {
          const previousValue = cloneDeep(this.permissions[field])
          if (!isEqual(previousValue, value)) {
            try {
              const { data } = await Vue.$GRequest.update(services.permissions, `${options.UserId}/${field}`, { value })
              if (data) {
                this.permissions[field] = data.value
                this.addSnackbar({
                  type: 'success',
                  text: this.getTranslate(`${services.permissions}.snackbars.updated`)
                })
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            } finally {
              Vue.set(this.loading.update, `${field}:${value}`, false)
            }
          }
        },
        250,
        {
          leading: false,
          trailing: true
        }
      ),

      async setByPreset(preset) {
        Vue.set(this.loading.preset, preset, true)
        for (const { children } of this.$permissions) {
          for (const { $key, $options } of children) {
            if ($options.find(({ $value }) => $value === preset)) {
              const { data } = await Vue.$GRequest.update(services.permissions, `${options.UserId}/${$key}`, { value: preset })
              if (data) {
                this.permissions[$key] = data.value
              }
            }
          }
        }
        Vue.set(this.loading.preset, preset, false)
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
