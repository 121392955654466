import { get } from 'lodash'

import { fillStringZeros } from '../strings'

export function logger(prefix, data, suffix) {
  if (window.localStorage.getItem('debug')) {
    const date = new Date()
    const hours = fillStringZeros(date.getHours())
    const minutes = fillStringZeros(date.getMinutes())
    const seconds = fillStringZeros(date.getSeconds())
    const milliseconds = fillStringZeros(date.getMilliseconds(), 3)
    const timestamp = `[${hours}:${minutes}:${seconds}.${milliseconds}]`
    const route = get(this, '$route.name')
    const result = [ timestamp, route, prefix, data, suffix ].filter(v => !!v).join(' ')
    console.debug(result) // eslint-disable-line
  }
}
