import { colors, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import pagination from '@/components/misc/pagination'
import buttonRefresh from '@/components/button/refresh'
import usersTokensItem from '@/components/services/users/tokens/item'
import tokensDialogs from '@/components/services/users/tokens/dialog'

function renderCreateButton(h) {
  if (this.checkPermissions(`advanced.${services.tokens}.create`, permissionPresets.meUp)) {
    return h(
      button,
      {
        props: {
          label: this.viewport.breakpoint.xs ? undefined : this.getTranslate(`${services.tokens}.buttons.add`),
          icon: 'add',
          color: colors.secondary
        },
        on: {
          click: () => {
            this.dialogType = 'create'
            this.showDialog = true
          }
        }
      }
    )
  }
}
function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.restData[services.tokens].find.state,
        shortClickMethod: () => this.rest[services.tokens].find(),
        longClickMethod: () => this.rest[services.tokens].find({}, { noCache: true })
      }
    }
  )
}
function renderButtons(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcfe pa-2',
      style: { gridTemplateColumns: 'auto 38px' }
    },
    [
      renderCreateButton.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}

function renderDivider(h) {
  if (Array.isArray(this.restData[services.tokens].find.data) && this.restData[services.tokens].find.data.length) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.tokens].find.pagination,
            service: services.tokens
          },
          on: {
            input: event => {
              this.restData[services.tokens].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-5'
    },
    [
      h(
        'g-progress',
        {
          props: {
            indeterminate: true
          }
        }
      )
    ]
  )
}

function renderList(h) {
  if (this.restData[services.tokens].find.state === states.loading) {
    return renderPreloader.call(this, h)
  } else if (this.restData[services.tokens].find.state === states.ready) {
    if (this.restData[services.tokens].find.pagination.total > 0) {
      return this.restData[services.tokens].find.data.map(item => {
        return h(
          usersTokensItem,
          {
            props: { value: item },
            key: item.id,
            on: {
              showDialog: event => {
                this.tokenId = event
                this.dialogType = 'update'
                this.showDialog = true
              },
              remove: event => {
                this.tokenId = event
                this.dialogType = 'remove'
                this.showDialog = true
              }
            }
          }
        )
      })
    }
  }

  return h('g-empty')
}

function renderDialogs(h) {
  return h(
    tokensDialogs,
    {
      props: {
        id: this.tokenId,
        type: this.dialogType,
        show: this.showDialog,
        OwnerId: this.OwnerId
      },
      on: {
        show: event => {
          if (event === false) {
            this.tokenId = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderButtons.call(this, h),
      renderDivider.call(this, h),
      renderList.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDialogs.call(this, h)
    ]
  )
}
