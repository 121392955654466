import { get } from 'lodash'

import { buttonModes, colors, components, getPrice, icons, inputModes, isStringNotEmpty, permissionPresets, services, states } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import entityInfo from '@/components/entity/info'
import billingsForm from '@/components/services/billings/form'
import eventsHistoryTab from '@/components/services/eventsHistory/tab'
import documentTemplatesGenerateButton from '@/components/services/documentTemplates/generate/button'
import userComments from '@/components/services/userComments/template'
import usersBalance from '@/components/services/users/balance'
import button from '@/components/button'
import buttonReport from '@/components/button/report'
import sticker from '@/components/misc/sticker'
import overflowString from '@/components/misc/overflowString'

function renderReportButton(h) {
  if (this.checkPermissions(`advanced.${services.reports}.presetUsersBalance`, permissionPresets.resellerUp)) {
    return h(
      buttonReport,
      {
        props: {
          icon: false,
          type: 'list-item',
          preset: 'users_balance'
        }
      }
    )
  }
}
function renderReseller(h, item) {
  const reseller = get(item, 'Reseller.username')
  if (reseller) {
    return h(
      'div',
      {
        class: 'caption text--grey'
      },
      [ reseller ]
    )
  }
}
function renderManager(h, item) {
  const manager = get(item, 'data.partnership.Manager.username')
  if (manager) {
    return h(
      'div',
      {
        class: 'caption text--grey'
      },
      [ manager ]
    )
  }
}
function renderSearchGeneratorItem(h, item) {
  return h(
    components['list-item'],
    {
      props: {
        active: item.id === this.$route.params.id,
        to: {
          name: `${services.users}.single`,
          params: { id: item.id }
        }
      },
      key: item.id
    },
    [
      h(
        'div',
        {
          class: 'fc ff'
        },
        [
          h(
            'div',
            {
              class: 'fjcsb faic'
            },
            [
              h(
                overflowString,
                {
                  props: {
                    value: item.username,
                    font: { size: 13 }
                  }
                }
              ),

              h(
                'div',
                {
                  class: 'text--grey caption'
                },
                [ getPrice(item.balance, { currency: get(item.settings, `${services.billings}.currency`) }) ]
              )
            ]
          ),

          h(
            'div',
            {
              class: 'faic caption'
            },
            [
              renderReseller.call(this, h, item),
              h('div', { class: 'ff' }),
              renderManager.call(this, h, item)
            ]
          )
        ]
      )
    ]
  )
}
function renderSearchGenerator(h) {
  return h(
    'search-generator',
    {
      scopedSlots: {
        default: ({ item }) => renderSearchGeneratorItem.call(this, h, item),
        report: () => renderReportButton.call(this, h),
        titleCreate: () => h(HeadTitle, { props: { value: this.getTranslate(`${services.users}.titles.create`) } })
      }
    }
  )
}

function renderTokenDialog(h) {
  if (this.checkPermissions('secret.loginAs', permissionPresets.resellerUp)) {
    return h(
      components.dialog,
      {
        props: {
          title: this.getTranslate('misc.token'),
          value: this.showTokenDialog,
          rounded: this.viewport.breakpoint.smUp,
          maxWidth: 500
        },
        on: {
          input: event => {
            this.showTokenDialog = event
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'pa-3'
          },
          [
            h(
              'g-textarea',
              {
                props: {
                  value: this.token,
                  hint: isStringNotEmpty(this.token) ? `${this.token.length}` : '0',
                  mode: inputModes.outline,
                  grow: true,
                  rounded: true,
                  readonly: true
                }
              }
            )
          ]
        ),

        h(
          'div',
          {
            class: 'fjcfe facfe grid-gap--8 pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: buttonModes.flat
                },
                on: {
                  click: () => this.closeTokenDialog()
                }
              }
            ),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.copy'),
                  color: colors.primary
                },
                on: {
                  click: () => this.copyToken()
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderLoginAsButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.exit_to_app,
        mode: buttonModes.flat,
        color: colors.epic,
        loading: this.statusLoginAs === states.loading,
        disabled: this.statusLoginAs === states.loading || !this.checkPermissions('secret.loginAs', permissionPresets.resellerUp)
      },
      on: {
        mousedown: this.clickHandler,
        mouseup: this.clickHandler,
        touchstart: this.clickHandler,
        touchend: this.clickHandler
      },
      scopedSlots: {
        dialog: () => renderTokenDialog.call(this, h)
      }
    }
  )
}
function renderUserInfo(h, data) {
  const { firstName, lastName } = data.data

  return h(
    sticker,
    {
      props: {
        value: data.username,
        label: [ firstName, lastName ].filter(Boolean).join(' '),
        iconSVG: data.$verified ? 'user-verified' : 'user',
        color: data.$verified ? colors.info : colors.grey,
        copy: true
      }
    }
  )
}
function renderTemplateTitle(h, data) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': 'auto 1fr' }
    },
    [
      renderLoginAsButton.call(this, h),
      renderUserInfo.call(this, h, data)
    ]
  )
}
function renderBalanceButton(h, data) {
  const currency = get(data.settings, `${services.billings}.currency`, this.globalSettings.billings.currency)

  return h(
    usersBalance,
    {
      props: {
        currency,
        UserId: data.id
      }
    }
  )
}
function renderAddFundsDialog(h, { id, settings }) {
  if (this.checkPermissions(`advanced.${services.billings}.generate`, permissionPresets.resellerUp)) {
    return h(
      components.dialog,
      {
        props: {
          title: this.getTranslate(`${services.users}.titles.add.funds`),
          rounded: true,
          value: this.showAddFundsDialog,
          maxWidth: 500
        },
        on: {
          input: event => {
            this.showAddFundsDialog = event
          }
        }
      },
      [
        h(
          billingsForm,
          {
            props: {
              value: this.restData.billings.create.data,
              errors: this.restData.billings.create.errors,
              owner: id,
              currency: get(settings, `${services.billings}.currency`)
            },
            on: {
              input: event => {
                this.restData.billings.create.data = event
              },
              validation: event => {
                this.restData.billings.create.isValid = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'faic fjcfe grid-gap--8 pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.cancel'),
                  mode: buttonModes.flat
                },
                on: {
                  click: () => {
                    this.showAddFundsDialog = false
                  }
                }
              }
            ),
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.create'),
                  loading: this.restData.billings.create.state === 'loading',
                  disabled: !this.createDataIsValid,
                  color: 'primary'
                },
                on: {
                  click: () => this.createBilling()
                }
              }
            )
          ]
        )
      ]
    )
  }
}
function renderAddFundsButton(h, { id, settings }) {
  return h(
    button,
    {
      props: {
        icon: icons.add,
        mode: buttonModes.flat,
        color: colors.secondary,
        tooltip: this.getTranslate(`${services.users}.tooltips.add.funds`),
        disabled: !this.checkPermissions(`advanced.${services.billings}.generate`, permissionPresets.resellerUp)
      },
      on: {
        click: () => {
          this.restData.billings.create.data.OwnerId = id
          this.showAddFundsDialog = true
        }
      },
      scopedSlots: {
        dialog: () => renderAddFundsDialog.call(this, h, {
          id,
          settings
        })
      }
    }
  )
}
function renderDocumentButton(h, data) {
  let { id: OwnerId, ResellerId } = data
  ResellerId = get(data, 'Reseller.id', ResellerId)

  return h(
    documentTemplatesGenerateButton,
    {
      props: {
        currency: get(data.settings, `${services.billings}.currency`),
        OwnerId,
        ResellerId
      }
    }
  )
}
function renderTemplateHeader(h, data) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjcfe',
      style: { 'grid-template-columns': 'repeat(3, auto)' }
    },
    [
      renderBalanceButton.call(this, h, data),
      renderAddFundsButton.call(this, h, data),
      renderDocumentButton.call(this, h, data)
    ]
  )
}
function renderTemplateAfterForm(h, data) {
  return h(
    entityInfo,
    {
      props: {
        value: data
      }
    }
  )
}
function renderTab(h, data) {
  switch (this.tab) {
    case 'userComments': {
      return h(userComments(data.id))
    }
    case 'eventsHistoryTab': {
      return h(
        eventsHistoryTab, {
          props: {
            service: services.users,
            entityId: this.$route.params.id
          }
        }
      )
    }
  }
}
function renderTabItem(h, tab, data) {
  return h(
    components.card,
    {
      props: {
        rounded: true,
        outline: true,
        width: '100%'
      },
      key: tab
    },
    [ renderTab.call(this, h, data) ]
  )
}
function renderTemplateRight(h, data) {
  if (this.computedTabs.length) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        h(
          components['tabs-header'],
          {
            class: 'pl-3',
            props: {
              value: this.tab,
              tabs: this.computedTabs.map(key => {
                return {
                  key,
                  title: this.getTranslate(`${services.users}.tabs.${key}`)
                }
              })
            },
            on: {
              input: event => {
                this.tab = event
              }
            }
          }
        ),

        h(
          components.tabs,
          {
            props: { value: this.tab },
            scopedSlots: {
              body: () => this.computedTabs.map(tab => renderTabItem.call(this, h, tab, data))
            }
          }
        )
      ]
    )
  }
}
function renderTemplate(h) {
  return h(
    'get-create-template',
    {
      scopedSlots: {
        title: ({ data }) => renderTemplateTitle.call(this, h, data),
        header: ({ data }) => renderTemplateHeader.call(this, h, data),
        'after-form': ({ data }) => renderTemplateAfterForm.call(this, h, data),
        right: ({ data }) => renderTemplateRight.call(this, h, data)
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: services.users
    },
    [
      renderSearchGenerator.call(this, h),
      renderTemplate.call(this, h)
    ]
  )
}
