export default {
  titles: {
    conditions: 'Условия',
    actions: 'Действия'
  },

  labels: {
    title: 'Название правила',
    type: 'Тип',
    text: 'Текст',
    sender: 'Отправитель',
    routingRule: 'Правило',
    condition: 'Условие',
    providerGroup: 'Группа провайдеров',
    percentage: 'Процент',
    search: 'Поиск',
    textToCheck: 'Текст для проверки',
    group: {
      pattern: 'Шаблон группы'
    },
    add: {
      condition: 'Добавить условие'
    },
    phone: 'Телефон',
    keywords: 'Списки ключевых слов',
    contactList: 'Адресные книги',
    fallbacks: 'Переотправления',
    buttons: 'Кнопки'
  },

  tags: {
    text: 'Текст',
    sender: 'Отправитель',
    recipient: 'Получатель',
    code: 'Код оператора',
    group: 'Группа оператора',
    mcc: 'MCC',
    mnc: 'MNC',
    mccmnc: 'MCC MNC',
    contactList: 'Адресные книги',
    type: 'Тип',
    moderation: 'Модерация',
    keywords: 'Ключевые слова',
    fallbacks: 'Переотправления',
    buttons: 'Кнопки'
  },

  hints: {
    noRule: 'Условие ещё не выбрано. Нажмите на эту строку чтобы выбрать.'
  }
}
