import { get } from 'lodash'

import { inputModes } from '@/utils'
import { serviceName } from '@/components/services/yclients/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import buttonCopy from '@/components/button/copy'
import linkButton from '@/components/button/link'

function renderCopyButton(h, value) {
  if (!this.isNew) {
    if (value && (typeof value === 'string' || typeof value === 'number')) {
      return h(
        buttonCopy,
        {
          props: {
            value
          }
        }
      )
    }
  }
}

function renderLinkButton(h, service, id) {
  if (!this.isNew) {
    return h(
      linkButton,
      {
        props: {
          to: {
            name: `${service}.single`,
            params: { id }
          }
        }
      }
    )
  }
}

function renderSalonIdField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.salonId,
        label: this.getTranslate(`${serviceName}.labels.salon`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        required: true,
        autofocus: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.salonId = event
        }
      }
    }
  )
}

function renderIsActiveField(h) {
  if (!this.isNew) {
    return h(
      'g-switch',
      {
        props: {
          value: this.proxy.isActive
        },
        on: {
          input: event => {
            this.proxy.isActive = event
          }
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.getTranslate(`${serviceName}.labels.isActive`)
            }
          }
        ]
      }
    )
  }
}

function renderOwnerIdField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderTokenIdField(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.TokenId,
        label: this.getTranslate(`${serviceName}.labels.token`),
        service: 'tokens',
        query: { OwnerId: this.proxy.OwnerId },
        itemTitle: 'token',
        itemValue: 'token',
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        tooltip: false
      },
      on: {
        input: event => {
          this.proxy.TokenId = event
        }
      }
    }
  )
}

function renderSalon(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': [ '1fr', this.isNew ? undefined : '36px' ].filter(Boolean).join(' ') }
    },
    [
      renderSalonIdField.call(this, h),
      renderIsActiveField.call(this, h)
    ]
  )
}

function renderOwner(h) {
  const username = get(this.proxy, 'Owner.username')
  const id = get(this.proxy, 'OwnerId')

  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': this.isNew ? '1fr' : '1fr repeat(2, 36px)' }
    },
    [
      renderOwnerIdField.call(this, h),
      renderCopyButton.call(this, h, username),
      renderLinkButton.call(this, h, 'users', id)
    ]
  )
}

function renderToken(h) {
  const tokenId = get(this.proxy, 'TokenId')

  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { 'grid-template-columns': this.isNew ? '1fr' : '1fr repeat(2, 36px)' }
    },
    [
      renderTokenIdField.call(this, h),
      renderCopyButton.call(this, h, tokenId),
      renderLinkButton.call(this, h, 'tokens', tokenId)
    ]
  )
}

function renderError(h) {
  if (this.proxy.error) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 faic border border--dashed border--rounded pa-2',
        style: { 'grid-template-columns': '24px 1fr' }
      },
      [
        h(
          'g-icon',
          {
            props: {
              value: 'error_outline',
              color: 'error'
            }
          }
        ),

        h(
          'div',
          {
            class: 'caption'
          },
          [ this.proxy.error ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid'
    },
    [
      renderSalon.call(this, h),
      renderOwner.call(this, h),
      renderToken.call(this, h),
      renderError.call(this, h)
    ]
  )
}
