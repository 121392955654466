import { cloneDeep, isEmpty, merge } from 'lodash'
import { mapGetters } from 'vuex'

import { SENDINGS_OUTGOING_FILTER, currentTab, permissionPresets, services, sudoData } from '@/utils'

import SendingsDetails from '@/components/services/sendings/outgoing/details'
import SendingsGroup001 from '@/components/services/sendings/outgoing/group001'
import SendingsGroup002 from '@/components/services/sendings/outgoing/group002'
import SendingsGroup003 from '@/components/services/sendings/outgoing/group003'

import render from './render'

export default {
  name: 'SendingsTemplate',

  components: {
    [`${services.sendings}-details`]: SendingsDetails,
    [`${services.sendings}-group001`]: SendingsGroup001,
    [`${services.sendings}-group002`]: SendingsGroup002,
    [`${services.sendings}-group003`]: SendingsGroup003
  },

  mixins: [ currentTab('outgoing'), sudoData ],

  data() {
    return {
      filter: cloneDeep(SENDINGS_OUTGOING_FILTER),

      preset: `${services.sendings}.details`
    }
  },

  computed: {
    ...mapGetters({ isLoggedAs: 'login/isLoggedAs' }),

    validateUser() {
      let userPermission = this.checkPermissions(`advanced.${services.sendings}.find`, permissionPresets.resellerUp)
      if (!userPermission && this.isLoggedAs) {
        userPermission = this.checkPermissions(
          `advanced.${services.sendings}.find`,
          'or',
          permissionPresets.resellerUp,
          JSON.parse(window.localStorage.getItem('prevAccount')).permissions
        )
      }

      return userPermission
    },

    presets() {
      return [
        {
          title: `${services.sendings}.presets.${services.sendings}.details`,
          value: `${services.sendings}.details`,
          show: true
        },
        {
          title: `${services.sendings}.presets.${services.sendings}.group001`,
          value: `${services.sendings}.group001`,
          show: true
        },
        {
          title: `${services.sendings}.presets.${services.sendings}.group002`,
          value: `${services.sendings}.group002`,
          show: true
        },
        {
          title: `${services.sendings}.presets.${services.sendings}.group003`,
          value: `${services.sendings}.group003`,
          show: this.validateUser
        }
      ]
    },

    presetNameKebab() {
      return this.preset.replace('.', '-')
    },

    tabs() {
      const result = []

      if (this.checkPermissions(`advanced.${services.sendings}.find`, permissionPresets.meUp)) {
        result.push('outgoing')
      }
      if (this.checkPermissions(`advanced.${services.sendingsIncoming}.get`, permissionPresets.meUp)) {
        result.push('incoming')
      }
      if (this.checkPermissions(`advanced.${services.tasks}.find`, permissionPresets.meUp)) {
        result.push(services.tasks)
      }

      return result
    }
  },

  watch: {
    $route: {
      handler() {
        if (!isEmpty(this.$route.params.filter)) {
          merge(this.filter, this.$route.params.filter)
        } else {
          this.filter = cloneDeep(SENDINGS_OUTGOING_FILTER)
        }
      },
      deep: true
    },

    preset() {
      this.filter = cloneDeep(SENDINGS_OUTGOING_FILTER)
    }
  },

  render
}
