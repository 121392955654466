import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'LocaleSwitch',

  props: {
    as: {
      type: String,
      default: 'link',
      validator: value => {
        return !!~[ 'button', 'link' ].indexOf(value)
      }
    }
  },

  computed: {
    ...mapGetters({
      locale: 'locale/locale',
      locales: 'locale/locales'
    })
  },

  methods: {
    ...mapActions({ setLocale: 'locale/setLocale' })
  },

  render
}
