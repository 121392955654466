import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { appendFormattedDates, fillDependence, permissionPresets, serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.eventsHistory,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: permissionPresets.true,
            pathToId: 'userId',
            pathToObject: 'User',
            outputPath: 'User',
            picks: PUBLIC_FIELDS_USER
          })
        }

        appendFormattedDates.call(this, result)

        return result
      },

      find: {
        defaultFilter: {
          userId: undefined,
          service: undefined,
          type: undefined
        }
      },

      create: false,
      update: false,
      remove: false,

      view: 'panels',
      views: [ 'panels' ],

      has: {
        clone: false,
        search: false,
        view: false
      },

      formPaddles: true,
      width: 440
    })
  ]
}
