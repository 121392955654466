export default {
  title: 'Invoices',

  titles: {
    create: 'Creating new bill',
    confirm: {
      reject: 'Rejecting bill',
      paid: 'Approving bill'
    }
  },

  labels: {
    docNumber: 'Number',
    invoice: 'Invoice',
    contract: 'Contact',
    type: 'Type',
    status: 'Status',
    amount: 'Amount',
    requisite: 'Requisite',
    company: 'Company',
    customer: 'Customer',
    owner: 'Creator',
    balance: 'Balance',
    requisites: 'Requisites',
    create: 'Creating bill',
    edit: 'View',
    paid: 'Approve',
    rejected: 'Reject',
    download: 'Download invoice',
    delete: 'Delete',

    statuses: {
      paid: 'Paid',
      rejected: 'Rejected',
      pending: 'Pending'
    }
  },

  contents: {
    confirm: {
      reject: 'Are you sure you want to reject this invoice?',
      paid: 'Are you sure you want to approve this invoice?'
    }
  },

  warnings: {
    notFound: {
      requisites: 'Unfortunately, we could not find your requisites.<br>Please create a new one and try again.',
      requisitesForAdmin: 'Unfortunately, we could not find requisites for this user.<br>You can create them for him yourself.',
      companies: 'Unfortunately, we could not find any contractors for selected requisites.<br>Please contact support to resolve this issue.',
      template: 'Unfortunately, we could not find any invoice templates for selected company.<br>Please contact support to resolve this issue.'

    }
  },

  tooltips: {
    download: 'Download invoice',
    paid: 'Approve',
    rejected: 'Reject',
    delete: 'Delete',
    edit: 'View',
    balance: 'Update balance'
  },

  snackbars: {
    created: 'Invoice successfully created.',
    updated: 'Invoice successfully updated.',
    deleted: 'Invoice successfully deleted.',
    paid: 'Invoice successfully approved.',
    rejected: 'Invoice successfully rejected.'
  }
}
