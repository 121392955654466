import { cloneDeep, isEmpty } from 'lodash'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import {
  BILLINGS_FILTER,
  appendFormattedDates,
  downloadable,
  fillDependence,
  generateServices,
  tableHeaderParser
} from '@/utils'

import render from './render'

import { serviceName } from '@/components/services/billings/utils'

export default {
  name: 'Details',

  mixins: [
    generateServices({
      name: serviceName,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        await fillDependence.call(this, result, {
          service: 'tariffs',
          permissions: [ 'reseller', true ],
          pathToId: 'TariffId',
          outputPath: 'Tariff',
          picks: [ 'id', 'title' ]
        })

        appendFormattedDates.call(this, result)

        return result
      },
      get: false,
      find: {
        bucketEnabled: true,
        defaultFilter: cloneDeep(BILLINGS_FILTER),
        disableWatcherFilter: true
      },
      create: false,
      update: false,
      remove: false
    }),

    downloadable({ permissionAccept: `advanced.${serviceName}.find` })
  ],

  props: {
    filter: {
      type: Object,
      default: () => cloneDeep(BILLINGS_FILTER),
      required: true
    }
  },

  data() {
    return {
      showDialog: false,
      source: undefined
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          value: 'source',
          class: 'px-0'
        },
        {
          value: 'status',
          class: 'px-0'
        },
        {
          title: `${serviceName}.headers.owner`,
          value: 'owner',
          align: 'left',
          class: 'px-0',
          show: this.checkPermissions('advanced.users.get', [ 'reseller', true ])
        },
        {
          title: `${serviceName}.headers.comment`,
          value: 'comment',
          align: 'left',
          class: 'px-2'
        },
        {
          value: 'actions',
          class: 'px-2',
          show: this.checkPermissions(`advanced.${serviceName}.update`, true)
        },
        {
          title: `${serviceName}.headers.tariff`,
          value: 'tariff',
          align: 'left',
          class: 'px-2',
          show: this.checkPermissions('advanced.tariffs.get', [ 'reseller', true ])
        },
        {
          title: `${serviceName}.headers.amount`,
          value: 'amount',
          align: 'right',
          class: 'px-2'
        },
        {
          title: 'misc.createdAt',
          value: 'createdAt',
          align: 'right',
          class: 'px-2',
          sortable: true
        },
        {
          title: 'misc.updatedAt',
          value: 'updatedAt',
          align: 'right',
          class: 'px-2',
          sortable: true
        }
      ])
    }
  },

  watch: {
    filter: {
      handler() {
        this.restData[serviceName].find.filter = cloneDeep(this.filter)
        if (this.restData[serviceName].find.pagination.offset !== 0) {
          this.restData[serviceName].find.pagination.offset = 0
        }
      },
      deep: true
    }
  },

  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.restData[serviceName].find.filter.id = this.$route.params.id
      this.restData[serviceName].find.filter.createdAt = this.$route.params.createdAt
      this.rest[serviceName].find()
    }
  },

  render
}
