import Chart from 'chart.js/auto'

import render from './render'

export default {
  name: 'Chart',

  props: {
    type: {
      type: String,
      default: 'line',
      validator: value => {
        return ~[
          'line',
          'bar',
          'radar',
          'doughnut',
          'polarArea',
          'bubble',
          'scatter'
        ].indexOf(value)
      }
    },

    data: {
      type: Object,
      default: () => ({})
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      chart: undefined
    }
  },

  watch: {
    type: {
      handler: 'update'
    },
    data: {
      handler: 'update',
      deep: true
    },
    options: {
      handler: 'update',
      deep: true
    }
  },

  mounted() {
    this.init()
  },

  methods: {
    init() {
      this.chart = new Chart(this.$el, {
        type: this.type,
        data: this.data,
        options: this.options
      })
    },

    update() {
      if (this.chart) {
        this.chart.update()
      }
    }
  },

  render
}
