export default function(h) {
  if (this.show) {
    return h(
      'div',
      {
        style: {
          position: 'fixed',
          bottom: '16px',
          right: '16px'
        },
        class: 'fc fjcc faic'
      },
      [
        h(
          'g-button',
          {
            class: 'mb-2',
            props: {
              fab: true,
              flat: true,
              icon: 'keyboard_arrow_up',
              color: 'primary'
            },
            on: {
              click: () => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth'
                })
              }
            }
          }
        ),

        h(
          'g-button',
          {
            props: {
              fab: true,
              flat: true,
              icon: 'keyboard_arrow_down',
              color: 'primary'
            },
            on: {
              click: () => {
                window.scrollTo({
                  top: this.scrollHeight,
                  left: 0,
                  behavior: 'smooth'
                })
              }
            }
          }
        )
      ]
    )
  }
}
