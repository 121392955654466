import { colors, services, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'
import overflowString from '@/components/misc/overflowString'

function renderIcon(h) {
  if (this.icon) {
    return h(
      squircle,
      {
        class: `${services.settingsSite}-row__icon`,
        props: {
          icon: this.icon,
          color: colors.grey,
          size: sizes.medium,
          tooltip: this.iconTooltip
        }
      }
    )
  }

  if (this.$scopedSlots.icon) {
    return this.$scopedSlots.icon()
  }

  return this.$slots.icon
}

function renderTitle(h) {
  if (this.title) {
    return h(
      overflowString,
      {
        class: `${services.settingsSite}-row__title`,
        props: {
          value: this.title,
          font: {
            size: 14
          }

        }
      }
    )
  }
}

function renderLabel(h) {
  if (this.label) {
    return h(
      'div',
      {
        class: `${services.settingsSite}-row__label`
      },
      [ this.label ]
    )
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: `${services.settingsSite}-row__content`
    },
    [
      renderTitle.call(this, h),
      renderLabel.call(this, h)
    ]
  )
}

function renderDialogSlot() {
  if (typeof this.$scopedSlots.dialog === 'function') {
    return this.$scopedSlots.dialog()
  }

  return this.$slots.dialog
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.settingsSite}-row`,
      on: this.$eventHandlers
    },
    [
      renderIcon.call(this, h),
      renderContent.call(this, h),
      renderDialogSlot.call(this, h)
    ]
  )
}
