import Vue from 'vue'

import render from './render'

import { serviceName } from '@/components/services/apisender/utils'
import { globalErrorHandler, globalErrorProcessor } from '@/utils'

export default {
  name: 'LogoutInstance',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false,
      loading: false
    }
  },

  computed: {
    disabled() {
      return this.loading || this.value.$state === 'notAuthorized'
    }
  },

  methods: {
    async logoutInstance() {
      try {
        this.loading = true
        await Vue.$GRequest.create(`${serviceName}/logoutInstance/${this.value.id}`)
        this.$emit('update')
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.showDialog = false
        this.loading = false
      }
    }
  },

  render
}
