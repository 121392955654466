import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'FormTitle',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    isActive: String,
    placeholder: String,

    pathToIsActive: {
      type: String,
      default: 'isActive'
    },
    pathToTitle: {
      type: String,
      default: 'title'
    },

    errors: {
      type: Object,
      default: () => ({})
    }
  },

  render
}
