import { serviceName } from '@/components/services/yclients/utils'

import render from './render'

export default {
  name: 'Salon',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    status() {
      const result = {
        icon: 'error_outline',
        color: 'error',
        tooltip: this.getTranslate(`${serviceName}.tooltips.notActive`)
      }
      if (this.value.isActive) {
        result.icon = 'check_circle_outline',
        result.color = 'success',
        result.tooltip = this.getTranslate(`${serviceName}.tooltips.active`)
      }

      return result
    }
  },

  render
}
