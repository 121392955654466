import { components } from '@/utils'

function renderContent(h) {
  return h(
    'div',
    {
      class: `${components.sidebar}__content`
    },
    [ this.$slots.default ]
  )
}

function renderOverlay(h) {
  if (this.mobile && this.value) {
    return h(
      'div',
      {
        class: `${components.sidebar}__overlay`,
        on: {
          click: () => {
            this.$emit('input', !this.value)
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'aside',
    {
      attrs: { id: 'sidebar' },
      class: {
        [components.sidebar]: true,
        [`${components.sidebar}--hide`]: !this.value,
        [`${components.sidebar}--mobile`]: this.mobile
      }
    },
    [ renderContent.call(this, h), renderOverlay.call(this, h) ]
  )
}
