export default {
  hints: {
    tree: {
      country: 'страна',
      operator: 'оператор',
      group: 'группа'
    }
  },

  snackbars: {
    created: 'Группа создана',
    updated: 'Группа обновлена',
    removed: 'Группа удалена'
  },

  contents: {
    confirm: {
      remove: 'Вы уверены что хотите удалить группу {title}?'
    }
  },

  tabs: {
    all: 'Все',
    checked: 'Выбранные'
  },

  pills: {
    all: 'Доступные',
    checked: 'Выбранные'
  }
}
