import Vue from 'vue'

import { generateServices, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'Tasks',

  mixins: [
    generateServices({
      name: services.sendingsModeration,

      find: {
        defaultPagination: {
          limit: 100
        }
      },

      get: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  data() {
    return {
      task: {},
      showDialog: false
    }
  },

  mounted() {
    if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`, permissionPresets.true)) {
      this.rest[services.sendingsModeration].find()
    }

    Vue.$socket.on(`${services.sendingsModeration}.tasks.updated`, task => {
      const index = this.restData[services.sendingsModeration].find.data.findIndex(item => item.id === task.id)
      if (index !== -1) {
        this.restData[services.sendingsModeration].find.data.splice(index, 1, task)
      }
    })
  },

  render
}
