import pkg from '../../../../package.json'

import Copy from '../Copy'

const _ = { get: require('lodash/get') }

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--4 caption text--grey',
      style: { 'grid-template-columns': 'repeat(4, minmax(min-content, max-content))' }
    },
    [
      h(
        Copy,
        {
          class: 'link link--passive',
          props: { value: `v${pkg.version}` }
        }
      ),
      h('div', {}, '©'),
      h('div', {}, _.get(this.globalSettings, 'frontend.title')),
      h('div', {}, new Date().getFullYear())
    ]
  )
}
