import Vue from 'vue'

import { cloneDeep, debounce, get, isEqual, merge } from 'lodash'
import { Macro } from '@sigma-legacy-libs/cache'

import { components, isChildOf, isEmpty, isUUID, nestjsServices } from '@/utils'

import { iMaskProps } from '@/components/g/input/mixins/iMask'

import render from './render'

const cachedItems = new Map()
const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

const pathToService = service => {
  if (Object.values(nestjsServices).includes(service)) {
    return `/n/${service}`
  }

  return service
}

const cachedFind = Cache.wrapWithCache(async (key, service, params) => {
  return await Vue.$GRequest?.find(pathToService(service), params)
})
const cachedGet = Cache.wrapWithCache(async (key, service, id) => {
  return await Vue.$GRequest?.get(pathToService(service), id)
})

const removeDollarSigns = query => {
  const result = {}
  for (const key in query) {
    const newKey = key.startsWith('$') ? key.slice(1) : key
    result[newKey] = query[key]
  }

  return result
}

async function requestItems() {
  const params = {
    query: {
      $offset: 0,
      $limit: 25,
      $order: [ [ 'createdAt', 'desc' ] ],
      $search: this.search || undefined
    }
  }

  if (this.query) {
    merge(params.query, cloneDeep(this.query))
  }
  if (Object.values(nestjsServices).includes(this.service)) {
    params.query = removeDollarSigns(params.query)
  }
  const key = [ this._uid, this.service, JSON.stringify(params) ].filter(Boolean).join(':')

  const { data } = await cachedFind(key, this.service, params) || {}
  if (data && Array.isArray(data.data) && data.data.length) {
    return data.data
  }

  return []
}

async function requestItem(id) {
  const key = [ this._uid, this.service, id ].filter(Boolean).join(':')

  const { data } = await cachedGet(key, this.service, id) || {}

  return data
}

export default {
  name: components.select,

  props: {
    value: null,

    items: {
      type: Array,
      default: () => []
    },
    itemsPrepend: {
      type: Array,
      default: () => []
    },
    itemsDisabled: {
      type: Array,
      default: () => []
    },

    itemTitle: {
      type: String,
      default: 'title'
    },
    itemValue: {
      type: String,
      default: undefined
    },
    itemDisabled: {
      type: String,
      default: 'disabled'
    },

    multiple: {
      type: Boolean,
      default: false
    },
    selectionType: {
      type: String,
      default: 'chip',
      validator: value => {
        return !!~[ 'chip', 'text' ].indexOf(value)
      }
    },
    separator: {
      type: String,
      default: ','
    },

    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },

    defaultValue: null,

    label: String,
    placeholder: String,

    hint: String,

    focusedClass: {
      type: String,
      default: 'text--primary'
    },

    menuProps: {
      type: [ Object, Array, String ],
      default: () => ({})
    },

    mode: {
      type: String,
      default: 'default',
      validator: value => {
        return !!~[ 'default', 'solo', 'outline', 'outline-label', 'line-label' ].indexOf(value)
      }
    },

    backgroundColor: {
      type: String,
      default: null
    },

    paste: Boolean,
    pasteFilter: {
      type: Function,
      default: v => [ v ]
    },

    tabindex: [ String, Number ],

    autocomplete: {
      type: Boolean,
      default: false
    },
    combobox: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    details: {
      type: Boolean,
      default: true
    },
    flat: {
      type: Boolean,
      default: false
    },
    dense: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },

    error: String,

    active: {
      type: Boolean,
      default: false
    },
    focused: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },

    beforeIcon: String,
    beforeIconCallback: {
      type: Function,
      default: v => v
    },

    afterIcon: {
      type: String,
      default: 'arrow_drop_down'
    },
    afterIconCallback: {
      type: Function,
      default: v => v
    },

    freeInput: {
      type: Boolean,
      default: false
    },
    freeInputTriggers: {
      type: Array,
      default: () => [ 9, 13 ]
    },

    setOnBlur: Boolean,
    clearOnFocus: Boolean,

    tooltip: {
      type: Boolean,
      default: true
    },

    hideSelections: {
      type: Boolean,
      default: false
    },

    count: [ String, Number ],

    searchValidation: {
      type: Function,
      default: undefined
    },

    requestItems: {
      type: Function,
      default: requestItems
    },
    requestItem: {
      type: Function,
      default: requestItem
    },

    service: String,

    query: {
      type: Object,
      default: () => ({})
    },

    clearItems: {
      type: Function,
      default: undefined
    },

    picks: {
      type: Array,
      default: () => []
    },

    ...iMaskProps
  },

  data() {
    return {
      search: '',

      selectedItems: [],

      _items: undefined,

      _filterInputValues: [ undefined, null, '' ],

      _show: {
        dropdown: false,
        dialog: false
      },

      _resizeObserver: undefined,
      _windowWidth: window.innerWidth,

      _cursorPosition: -1,

      _active: false,
      _loading: false,
      _focused: false,
      _error: undefined,
      _errorOnBlur: false,
      _errorOnRequired: false,

      _initQuery: cloneDeep(this.query),
      _debouncedFindAll: debounce(
        function() {
          this.findAll()
        },
        200,
        {
          leading: false,
          trailing: true
        }
      )
    }
  },

  computed: {
    proxy: {
      // стоить отметить что данные извне, то есть те что в this.value
      // могут быть различными, вплоть до undefined
      // важно сохранить их все для дальнейшей работы
      get() {
        const value = Array.isArray(this.value) ? this.value : [ this.value ] // преобразуем значение в массив, если это еще не массив
        const result = value.filter(item => item !== undefined && item !== null && item !== '') // фильтруем все undefined, null и пустые строки

        return result // возвращаем результат
      },

      // к proxy.value нужно приравнивать массив
      // любые попытки изменить proxy.value через
      // .push или .splice будут проигнорированы
      // метод set не реагирует на вызовы встроенных методов массива
      set(value) {
        if (!this.isDisabled) { // присваиваем значение только если компонент не заблокирован
          if (Array.isArray(value)) { // выполняем обработку только если значение массив
            const result = cloneDeep(this.proxy) // копируем текущее значение
            if (value.length) {
              for (const item of value) { // перебираем все элементы
                if (item === undefined || item === null || item === '') { // если элемент undefined, null или пустая строка
                  continue
                }

                const index = result.findIndex(v => this._compareValues(v, item)) // ищем индекс элемента в текущем значении proxy

                if (index === -1) { // если элемент не найден
                  if (this.multiple) { // если множественный выбор
                    result.push(item) // добавляем элемент в конец
                  } else { // если одиночный выбор
                    result.splice(0, result.length, item) // заменяем все элементы на новый
                  }
                } else { // если элемент найден
                  if (this.required) { // если выбор обязателен
                    if (result.length === 0) { // если текущее значение пустое
                      result.push(item) // добавляем элемент в конец
                    }
                  } else { // если выбор не обязателен или в текущем значении больше одного элемента
                    if (this.multiple) { // если множественный выбор
                      result.splice(index, 1) // удаляем элемент из текущего значения
                    }
                  }
                }
              }
            } else {
              if (this.search) {
                this._clearSearchValue()
              }

              if (!this.required) {
                result.splice(0, result.length) // очищаем текущее значение
              }
            }
            this.$emit('input', this.multiple ? result : result[0]) // отправляем событие c новым значением
          }
        }
      }
    },

    $itemValue() {
      if (this.itemValue) {
        return this.itemValue
      }
      if (this.service) {
        return 'id'
      }

      return 'value'
    },

    $menuProps() {
      return merge(
        {
          value: this._data._show.dropdown,
          attach: this.attach,
          closeOnClick: false,
          closeOnContentClick: !this.multiple,
          maxHeight: 300,
          minWidth: this.$el ? this.$el.offsetWidth : null,
          maxWidth: this.$el ? this.$el.offsetWidth : null,
          offsetDistance: 2,
          overflowY: 'auto'
        },
        this.menuProps
      )
    },

    displayItems() {
      return [].concat(this.itemsPrepend, this._data._items || this.items).map(this._clearItem)
    },
    filteredItems() {
      return this.displayItems.filter(({ _filtered }) => _filtered)
    },

    filteredProxy() {
      return this.proxy.filter(value => value !== undefined && value !== null && value !== '')
    },
    proxyIsEmpty() {
      return this.filteredProxy.length === 0
    },

    input() {
      if (this.isMobile) {
        return this.$refs && this.$refs['dialog-input'] && (this.$refs['dialog-input'].$el || this.$refs['dialog-input'])
      }

      return this.$refs && this.$refs.input && (this.$refs.input.$el || this.$refs.input)
    },
    menu() {
      return this.$refs && this.$refs.menu && (this.$refs.menu.$el || this.$refs.menu)
    },
    attach() {
      return this.$refs && this.$refs.attach && (this.$refs.attach.$el || this.$refs.attach)
    },
    dialog() {
      return this.$refs && this.$refs.dialog && (this.$refs.dialog.$el || this.$refs.dialog)
    },

    isDisabled() {
      return this.disabled || this.readonly
    },
    isClearable() {
      return !this.isDisabled && this.clearable && !this.proxyIsEmpty
    },
    isFreeInput() {
      return this.freeInput || this.combobox
    },

    isActive() {
      return this._data._active || this._data._show.dropdown || this._data._show.dialog
    },
    isLoading() {
      return this._data._loading
    },
    isFocused() {
      return this._data._focused
    },
    isError() {
      return this._data._errorOnBlur || this._data._errorOnRequired || !!this._data._error
    },

    hasBefore() {
      return this.hasBeforeSlot || this.beforeIcon
    },
    hasBeforeSlot() {
      return !!(this.$scopedSlots.before || this.$slots.before)
    },

    hasAfter() {
      return this.hasAfterSlot || this.loading && this.mode !== 'default' || this.isClearable || this.afterIcon
    },
    hasAfterSlot() {
      return !!(this.$scopedSlots.after || this.$slots.after)
    },

    hasLabel() {
      if (!this.label) {
        return false
      }

      switch (this.mode) {
        case 'box':
        case 'solo':
        case 'outline': {
          if (this.search || this.proxy.length) {
            return false
          }
          break
        }
        default: {
          return true
        }
      }

      return true
    },

    hasInput() {
      return this.autocomplete || this.combobox
    },

    isMobile() {
      return this._data._windowWidth < 768
    }
  },

  watch: {
    proxy: {
      handler() {
        this._fillSelectedItems()
      },
      deep: true
    },

    active(value) {
      this._data._active = value
    },
    loading(value) {
      this._data._loading = value
    },
    focused(value) {
      this._data._focused = value
    },
    error(value) {
      this._data._error = !!value
    },

    displayItems: {
      handler(value) {
        for (const item of value) {
          const key = get(item, this.$itemValue, item).toString()
          cachedItems.set(key, item)
        }
        if (value.length) {
          this._fillSelectedItems()
        }
      },
      deep: true
    },

    service() {
      this._data._debouncedFindAll.call(this)
    },

    search() {
      this._data._debouncedFindAll.call(this)
    },

    query: {
      handler(value) {
        if (!isEqual(value, this._data._initQuery)) {
          this._data._debouncedFindAll.call(this)
          this._data._initQuery = cloneDeep(this.query)
        }
      },
      deep: true
    }
  },

  async mounted() {
    if (document) {
      if (document.body) {
        document.body.addEventListener('click', this._clickHandler)
        if (this.isMobile) {
          document.addEventListener('backbutton', this._clickOutside)
        }
      }
    }

    this.$nextTick(() => {
      this._data._resizeObserver = new ResizeObserver(entries => {
        window.requestAnimationFrame(() => {
          for (const entry of entries) {
            this._data._windowWidth = entry.contentRect.width
          }
        })
      })
      this._data._resizeObserver.observe(document.body)
    })

    await this.findAll()
    this._setRequired()
    this._fillSelectedItems()
  },

  beforeDestroy() {
    if (document) {
      if (document.body) {
        document.body.removeEventListener('click', this._clickHandler)
      }
      if (this.isMobile) {
        document.removeEventListener('backbutton', this._clickOutside)
      }
    }

    if (this._data._resizeObserver !== undefined) {
      this._data._resizeObserver.disconnect()
    }
  },

  methods: {
    _setRequired() {
      if (this.required) {
        if (this.proxyIsEmpty) {
          if (this.displayItems.length) {
            const item = this.displayItems.find(item => item._disabled !== true)
            this.proxy = [ get(item, this.$itemValue, item) ]
          }
        }
      }
    },

    async _fillSelectedItems() {
      const result = []
      await Promise.allSettled(this.proxy.map(async value => {
        let item = this.displayItems.find(item => this._compareValues(item, value)) || cachedItems.get(value)
        if (!item) {
          if (this.service && isUUID(value)) {
            const response = await this.requestItem(value)
            if (response) {
              item = response
            }
          }
        }

        result.push(this._clearItem(!isEmpty(item) ? item : value))
      }))
      this.selectedItems.splice(0, this.selectedItems.length, ...result)
    },

    _findTitleInSelectedItemsByValue(value) {
      if (value === undefined) {
        return undefined
      }

      const item = this.selectedItems.find(v => this._compareValues(get(v, this.$itemValue, v), get(value, this.$itemValue, value)))
      const itemValue = get(item, this.$itemValue, item)
      const itemTitle = get(item, this.itemTitle, itemValue)

      return item && (itemTitle || ([ 'number', 'string', 'boolean' ].includes(typeof itemTitle) ? itemTitle.toString() : undefined))
    },

    _getDisabled(item) {
      const disabled = get(item, this.itemDisabled, false)
      const itemDisabled = !!~this.itemsDisabled.findIndex(value => this._compareValues(value, item))

      return disabled || itemDisabled
    },

    _compareValues(a, b) {
      const valueA = get(a, this.$itemValue, a)
      const valueB = get(b, this.$itemValue, b)

      return isEqual(valueA, valueB)
    },
    _compareStrings(value, search) {
      const v = ('' + value).toLowerCase()
      const s = ('' + search).toLowerCase()

      return this._compareValues(v, s) || v.includes(s)
    },
    _checkSearchValidity(title, value, item) {
      const checkValue = this._compareStrings(value, this.search)
      const checkTitle = this._compareStrings(title, this.search)

      let result = checkValue || checkTitle
      if (typeof this.searchValidation === 'function') {
        result = this.searchValidation(this.search, item)
      }

      return result
    },

    _clearItem(item, index = 0) {
      const value = get(item, this.$itemValue, item)
      const title = get(item, this.itemTitle, value)

      const result = {
        [this.itemTitle]: title,
        [this.$itemValue]: value
      }

      const picks = cloneDeep(this.picks)
      for (const pick of picks) {
        if (pick !== this.itemTitle && pick !== this.$itemValue) {
          result[pick] = get(item, pick)
        }
      }

      result._item = item
      result._hovered = this._data._cursorPosition === index
      result._selected = !!~this.proxy.findIndex(v => this._compareValues(v, value))
      result._filtered = this.service ? true : !this.search || this._checkSearchValidity(title, value, item)
      result._disabled = this._getDisabled(item)
      result._cleared = true

      return result
    },
    _clearSearchValue() {
      this.$nextTick(() => {
        this.search = undefined
      })
    },
    _clearCurrentError() {
      this._data._errorOnRequired = false
      this._data._errorOnBlur = false
      this._data._error = undefined
    },

    _updateMenu() {
      this.$nextTick(() => {
        if (this.menu && this.menu.updateDimensions) {
          this.menu.updateDimensions()
        }
      })
    },

    addByValue(value) {
      if (this.isDisabled) {
        return false
      }

      value = get(value, this.$itemValue, value)

      const index = this.proxy.findIndex(v => this._compareValues(v, value))

      if (index === -1) {
        if (this.multiple) {
          this.proxy = [ value ]
        } else {
          this.proxy = [ value ]

          if (this.search) {
            this._clearSearchValue()
          }

          if (this.activateMobile) {
            this._data._show.dialog = false
          } else {
            this._data._show.dropdown = false
          }

          this._data._focused = false
        }

        this._updateMenu()

        return true
      }

      return false
    },
    removeByValue(value) {
      if (this.isDisabled) {
        return false
      }

      value = get(value, this.$itemValue, value)

      const proxy = cloneDeep(this.proxy)
      const index = proxy.findIndex(v => this._compareValues(v, value))

      if (index > -1) {
        if (proxy.length === 1 && this.required) {
          return false
        }

        this.proxy = [ ...proxy.splice(index, 1) ]
        this._updateMenu()

        return true
      }

      return false
    },
    toggleByValue(value) {
      return this.addByValue(value) || this.removeByValue(value)
    },

    _onKeyup(event) {
      if (this.isFreeInput) {
        const triggers = this.freeInputTriggers

        const checkKey = (keySample, event) => {
          return (
            typeof keySample === 'number' && event.keyCode === keySample ||
            typeof keySample === 'string' && event.key === keySample ||
            false
          )
        }

        if (
          (Array.isArray(triggers) && triggers.some(key => checkKey(key, event)) ||
          (typeof triggers === 'string' || typeof triggers === 'number') && checkKey(triggers, event)) &&
          event.target &&
          this.search
        ) {
          let index = this.search.indexOf(event.key)

          if (!~index) {
            index = this.search.length
          }

          const valueToAdd = this.search.substring(0, index).trim()

          if (valueToAdd) {
            this._setValue(valueToAdd)
          }

          this._clearSearchValue()
        }
      }

      if (event.keyCode === 9) {
        this._data._focused = true

        if (this.isMobile) {
          this._data._show.dialog = true
        } else {
          this._data._show.dropdown = true
        }
      }
    },
    _onKeydown(event) {
      if (event.keyCode === 9) {
        this._data._focused = false

        if (this.isMobile) {
          this._data._show.dialog = false
        } else {
          this._data._show.dropdown = false
        }

        if (this.search && this.isFreeInput) {
          let valueToAdd

          if (this.combobox) {
            valueToAdd = this.search
          } else {
            const item = this.displayItems.find(({ _filtered }) => _filtered)
            if (item) {
              valueToAdd = get(item, this.$itemValue, item)
            }
          }

          if (valueToAdd) {
            this._setValue(valueToAdd)
          }
        }

        this._clearSearchValue()
      }

      if (this.menu) {
        if (event.keyCode === 40) {
          this._clickOnMain()
          this._data._cursorPosition++
          if (this._data._cursorPosition > this.displayItems.length - 1) {
            this._data._cursorPosition = 0
          }
        }

        if (event.keyCode === 38) {
          this._clickOnMain()
          this._data._cursorPosition--
          if (this._data._cursorPosition < 0) {
            this._data._cursorPosition = this.displayItems.length - 1
          }
        }

        if (this.menu.$refs && this.menu.$refs[`menu-${this.menu._uid}`]) {
          const height = this.menu.$refs[`menu-${this.menu._uid}`].offsetHeight
          const scrollTop = this.menu.$refs[`menu-${this.menu._uid}`].scrollTop
          const currentPosition = this._data._cursorPosition * 48
          const bottomEdge = scrollTop + height
          const currentBottomEdge = currentPosition + 48 + 12
          const bottomEdgeDiff = currentBottomEdge - bottomEdge
          const topEdgeDiff = scrollTop - currentPosition + 12

          if (bottomEdgeDiff > 0) {
            this.menu.$refs[`menu-${this.menu._uid}`].scrollTop += bottomEdgeDiff
          } else if (topEdgeDiff > 0) {
            this.menu.$refs[`menu-${this.menu._uid}`].scrollTop -= topEdgeDiff
          }
        }

        if (event.keyCode === 13) {
          const item = this.displayItems.find(({ _hovered }) => _hovered)

          if (item) {
            this.addByValue(get(item, this.$itemValue, item))
          } else if (this.isFreeInput) {
            this.addByValue(this.search)
          }

          if (!this.multiple) {
            this._clickOutside()
          }

          this._data._cursorPosition = -1

          this._clearSearchValue()
        }
      }
    },

    _setValue(value) {
      if (value) {
        if (this.multiple) {
          this.toggleByValue(value)
        } else {
          this.addByValue(value)
        }
      }
    },

    _preventClick(event) {
      event.preventDefault()
      event.stopPropagation()
    },

    _clickHandler(event) {
      if (this.isDisabled) {
        return false
      }

      const { target } = event

      if (this.$el && isChildOf(target, this.$el)) {
        this._clickOnMain(event)
      } else if (this.menu && isChildOf(target, this.menu)) {
        this._clickOnMenu(event)
      } else if (this.dialog && isChildOf(target, this.dialog)) {
        this._clickOnDialog(event)
      } else {
        this._clickOutside(event)
      }
    },
    _clickOnMain() {
      this._data._focused = true

      if (this.isMobile) {
        if (!this._data._show.dialog) {
          this._data._show.dialog = true
        }
      } else {
        if (!this._data._show.dropdown) {
          this._data._show.dropdown = true
        }
      }

      this._focus()

      if (this.combobox && this.clearOnFocus) {
        this._clearSearchValue()
      }

      if (this.isError) {
        this._clearCurrentError()
      }
    },
    _clickOnMenu() {
      if (!this.multiple) {
        this._data._focused = false
        this._data._show.dropdown = false
      }

      this._clearSearchValue()
      this._focus()
    },
    _clickOnDialog(event) {
      this._preventClick(event)
    },
    _clickOutside(event, fromItem) {
      this._data._focused = false

      if (this.isMobile) {
        this._data._show.dialog = false
      } else {
        this._data._show.dropdown = false
      }

      this._blur()

      if (this.search && this.isFreeInput && this.setOnBlur && !fromItem) {
        this._setValue(this.search)
      }

      this._clearSearchValue()
    },

    _itemClickHandler(event, item) {
      this._preventClick(event)

      if (!item._disabled) {
        this.proxy = [ get(item, this.$itemValue, item) ]

        if (!this.multiple) {
          this._clickOutside(event, true)
        }
      }
    },

    _inputHandler(event) {
      this.search = event
      this.$emit('search', event)
      this._focus()
    },
    _pasteHandler(event) {
      if (this.paste) {
        event.preventDefault()

        const clipboardData = event.clipboardData || window.clipboardData

        let text = ''
        if (clipboardData && typeof clipboardData.getData === 'function') {
          text = clipboardData.getData('text')
        }
        let output = this.pasteFilter(text)
        if (!Array.isArray(output)) {
          output = [ output ]
        }
        this.proxy = [ ...output ]
      }
    },

    _focus() {
      this.$nextTick(() => {
        if (this.input && this.input.focus) {
          this.input.focus()
        }
      })
    },
    _blur() {
      this.$nextTick(() => {
        if (this.input && this.input.blur) {
          this.input.blur()
        }
      })
    },

    async findAll() {
      if (this.service) {
        try {
          this._data._loading = true
          const result = await this.requestItems()
          if (result) {
            this.$emit('fetched', result)
            if (this.clearItems && typeof this.clearItems === 'function') {
              this._data._items = await this.clearItems(result)
            } else {
              this._data._items = result
            }
          }
        } catch (error) {
        } finally {
          this._data._loading = false
        }
      }
    }
  },

  render
}
