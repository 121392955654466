export const SENDINGS_INCOMING_FILTER = {
  OwnerId: undefined,
  type: undefined,
  from: undefined,
  createdAt: {
    $gt: undefined,
    $lt: undefined
  },
  createdAtPreset: 'minutes.5'
}

export default { SENDINGS_INCOMING_FILTER }
