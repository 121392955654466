import Vue from 'vue'

import { isArrayNotEmpty } from '@/utils'

const _ = {
  camelCase: require('lodash/camelCase'),
  pickBy: require('lodash/pickBy')
}

export const catchErrors = function(error) {
  const code = error.error
  if (!code) {
    throw new Error('No error code found')
  }

  error.$showAs = []
  error.$translates = []

  if (process.env.NODE_ENV === 'development') {
    error.$showAs.push('console')
  }

  switch (code) {
    case 422:
      error.$showAs.push('field')
      if (isArrayNotEmpty(error.data)) {
        error.data.map(item => {
          if (item.type) {
            const options = {
              field: item.field && this.getTranslate(`errors.fields.${item.field}`),
              expected: item.expected
            }
            item.$translate = this.getTranslate(
              `errors.types.${_.camelCase(item.type)}`,
              _.pickBy(options, option => !!option)
            )
          }

          return item
        })
      }
      break
    default:
      error.$showAs.push('snackbar')
      error.$translates.push(this.getTranslate(`errors.types.${error.type}`))
      break
  }

  return error
}

export const setErrors = function(error, payload) {
  if (~error.$showAs.indexOf('field') && payload) {
    if (isArrayNotEmpty(error.data)) {
      error.data.forEach(item => Vue.set(payload.errors, item.field, item.$translate))
    }
  }
  if (~error.$showAs.indexOf('snackbar')) {
    if (isArrayNotEmpty(error.$translates)) {
      error.$translates.forEach(translate => this.addSnackbar({
        text: translate,
        type: 'error'
      }))
    }
  }
  if (~error.$showAs.indexOf('console')) {
    console.error('[ERROR]', error)
  }
}

export const fastErrors = function(errors) {
  if (isArrayNotEmpty(errors)) {
    errors.map(item => {
      if (item.type) {
        const options = {
          field: item.field && this.getTranslate(`errors.fields.${item.field}`),
          expected: item.expected
        }
        item.$translate = this.getTranslate(
          `errors.types.${_.camelCase(item.type)}`,
          _.pickBy(options, option => !!option)
        )
      }

      return item
    })
  }

  return errors
}

export default {
  catchErrors,
  setErrors,
  fastErrors
}
