import { buttonModes, colors, icons } from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'
import ContactListsForm from '@/components/services/contactLists/form'

function renderRecipientTypeSelect(h, type) {
  const proxyKeys = Object.keys(this.proxy)

  return h(
    'g-select',
    {
      props: {
        value: this.proxy[type],
        itemsDisabled: this.proxy[proxyKeys[Math.abs(proxyKeys.indexOf(type) - 1)]],
        label: this.getTranslate(`sendings.labels.targets.${type}`),
        hint: this.getTranslate('sendings.labels.targets.info'),
        error: this.error,
        mode: 'outline',
        rounded: true,
        service: 'contactLists',
        paste: true,
        pasteFilter: this.pasteFilter,
        setOnBlur: true,
        autocomplete: true,
        combobox: true,
        multiple: true,
        freeInputTriggers: [ 9, 13, 32, /* 188,  */194 ] // FIXME: 188 код на кнопке с буквой Б
      },
      on: {
        input: event => {
          this.proxy[type] = this.checkValues(event)
        }
      }
    }
  )
}
function renderAddButton(h) {
  return h(
    button,
    {
      props: {
        icon: icons.add,
        color: colors.secondary,
        mode: buttonModes.flat,
        tooltip: this.getTranslate('contactLists.tooltips.create')
      },
      on: {
        click: () => {
          this.contactLists.show.dialog = true
        }
      }
    }
  )
}
function renderRecipientField(h, type) {
  return h(
    'div',
    {
      class: 'faifs grid-gap--8'
    },
    [
      renderRecipientTypeSelect.call(this, h, type),
      renderAddButton.call(this, h)
    ]
  )
}

function renderRecipientCreateDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.contactLists.show.dialog,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.contactLists.show.dialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate('contactLists.titles.create') },
          slot: 'header'
        }
      ),

      h(
        ContactListsForm,
        {
          class: 'grid-gap--12 px-2 pt-2',
          props: {
            value: this.restData.contactLists.create.data
          },
          on: {
            input: event => {
              this.restData.contactLists.create.data = event
            },
            submit: async () => {
              this.createContactList()
            },
            validation: event => {
              this.restData.contactLists.create.isValid = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe facfe grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.contactLists.show.dialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                loading: this.restData.contactLists.create.state === 'loading',
                disabled: this.restData.contactLists.create.state === 'loading' || !this.restData.contactLists.create.isValid,
                color: colors.primary
              },
              on: {
                click: async () => {
                  this.createContactList()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderRecipientField.call(this, h, 'include'),
      renderRecipientField.call(this, h, 'exclude'),
      renderRecipientCreateDialog.call(this, h)
    ]
  )
}
