import { debounce, defaultsDeep } from 'lodash'

import { generateServices, globalErrorHandler, globalErrorProcessor, projectName } from '@/utils'

import render from './render'

export default function(options = {}) {
  options = Object.assign(
    {
      filterByIsActive: true,
      dense: false
    },
    options
  )

  const localStorageKeys = {
    lastVisited: `${projectName}:generator:search:${options.name}:lastVisited`,
    isActive: `${projectName}:generator:search:${options.name}:isActive`
  }

  const find = defaultsDeep(
    {
      defaultFilter: {
        $search: undefined,
        isActive: undefined,
        t: undefined
      },
      appendMode: true,
      abortPrevious: true
    },
    options.find
  )

  return {
    name: 'GeneratorSearch',

    mixins: [
      generateServices({
        name: options.name,

        inputFilter: options.inputFilter,
        outputFilter: options.outputFilter,

        find,

        cacher: options.cacher,

        create: false,
        update: false,
        remove: false
      })
    ],

    props: {
      maxWidth: {
        type: Number,
        default: 800
      }
    },

    data() {
      return {
        isActive: true,

        showFilter: false,
        showLastVisited: false,

        lastVisitedIds: []
      }
    },

    computed: {
      computedShowSearch() {
        if (this.viewport.breakpoint.mdDown) {
          if (!this.$route.params.id) {
            return true
          } else if (this.$route.params.id) {
            return false
          }
        }

        return true
      },

      showLoadMore() {
        return (
          this.restData[options.name].find.pagination.limit + this.restData[options.name].find.pagination.offset <
          this.restData[options.name].find.pagination.total
        )
      },

      hasFilter() {
        return !!this.$slots.filter || typeof this.$scopedSlots.filter === 'function'
      },
      hasReport() {
        return !!this.$slots.report || typeof this.$scopedSlots.report === 'function'
      },
      hasCreate() {
        return !options.excludeCreate || (this.$slots.create || typeof this.$scopedSlots.create === 'function')
      },

      lastVisitedIsShowed() {
        return this.showLastVisited && this.lastVisitedIds.length && !this.restData[options.name].find.filter.$search
      },

      gridTemplateRows() {
        const result = [ '38px' ]

        if (this.showFilter) {
          result.push('auto')
        }
        if (this.lastVisitedIsShowed) {
          result.push('auto')
        }

        result.push('1fr')

        return result.join(' ')
      }
    },

    watch: {
      [`restData.${options.name}.find.filter.$search`]() {
        this.rest[options.name].options.find.abort()
        this.restData[options.name].find.pagination.offset = 0
      },

      'account.id'() {
        this.find()
      },

      $route: {
        handler() {
          if (this.$route.name === `${options.name}.single`) {
            this.addVisitedId({
              service: options.name,
              id: this.$route.params.id
            })
          }
          this.getLastVisitedItems()
        },
        deep: true
      }
    },

    created() {
      this.onScroll = debounce(this.onScroll, 500, {
        leading: false,
        trailing: true
      })
    },

    async mounted() {
      await this.getIsActive()
      await this.find()
      this.getLastVisitedState()
      this.getLastVisitedItems()
    },

    methods: {
      onScroll(event) {
        if (
          this.restData[options.name].find.state === 'ready' &&
          event.target.scrollHeight - event.target.clientHeight - event.target.scrollTop <= 160 &&
          this.showLoadMore
        ) {
          this.restData[options.name].find.pagination.offset += this.restData[options.name].find.pagination.limit
        }
      },

      async find() {
        const accept = [ 'advanced', options.serviceForCheckPermissions || options.name, options.methodForCheckPermissions || 'find' ].join('.')
        if (this.checkPermissions(accept, [ 'me', 'manager', 'reseller', true ])) {
          const params = {}
          if (options.avoid304) {
            params.query = { t: Date.now() }
          }
          await this.rest[options.name].find(params)
        }
      },

      async getLastVisitedItems() {
        if (this.showLastVisited === false) {
          return
        }

        try {
          const prevToken = window.localStorage.getItem('prevToken')
          if (!prevToken) {
            const ids = await this.getVisitedIds(options.name)
            if (Array.isArray(ids) && ids.length) {
              const result = []
              for (const id of ids) {
                let item
                const data = this.restData[options.name].find.data
                if (Array.isArray(data) && data.length) {
                  item = data.find(item => item.id === id)
                }
                if (item) {
                  result.push(item)
                } else {
                  item = await this.rest[options.name].get(id, {
                    t: Date.now(),
                    query: {
                      $scope: [ 'full' ]
                    }
                  })
                  if (item) {
                    result.push(item)
                  } else {
                    this.removeVisitedId({
                      service: options.name,
                      id
                    })
                  }
                }
              }
              this.lastVisitedIds.splice(0, this.lastVisitedIds.length, ...result)
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      },

      getLastVisitedState() {
        let state = false
        try {
          state = JSON.parse(window.localStorage.getItem(localStorageKeys.lastVisited))
        } catch (error) {
          state = false
        } finally {
          this.showLastVisited = state
        }
      },

      setLastVisitedState(state) {
        window.localStorage.setItem(localStorageKeys.lastVisited, state || false)
        if (state) {
          this.getLastVisitedItems()
        }
      },

      toggleLastVisitedState() {
        this.showLastVisited = !this.showLastVisited
        this.setLastVisitedState(this.showLastVisited)
      },

      async getIsActive() {
        let isActive = true
        try {
          isActive = await JSON.parse(window.localStorage.getItem(localStorageKeys.isActive))
        } catch (error) {
          isActive = true
        } finally {
          if (isActive === null || isActive === undefined) {
            isActive = true
          }
          this.setIsActive(isActive)
        }
      },

      setIsActive(event) {
        if (typeof event !== 'boolean') {
          event = true
        }
        window.localStorage.setItem(localStorageKeys.isActive, event)
        this.isActive = event
        this.toggleFilterByIsActive(event)
      },

      toggleFilterByIsActive(event) {
        if (event === false) {
          this.restData[options.name].find.filter.isActive = undefined
        } else {
          this.restData[options.name].find.filter.isActive = true
        }
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
