import { generateServices, userTariffsInputFilter } from '@/utils'

import { serviceName } from '@/components/services/users/utils'

import render from './render'

export default {
  name: 'Template',

  mixins: [
    generateServices({
      name: serviceName,

      inputFilter(result) {
        if (!Array.isArray(result.Tariffs)) {
          result.Tariffs = []
        }

        result.Tariffs = result.Tariffs
          .map(item => userTariffsInputFilter.call(this, item))
          .sort((a, b) => a.priority - b.priority)

        return result
      },

      outputFilter(result) {
        return Object.keys(result).reduce((res, key) => {
          if (!key[0].match(/[A-Z]/)) {
            res[key] = result[key]
          }

          return res
        }, {})
      },

      find: false,
      create: false,
      remove: false,
      get: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      }
    })
  ],

  computed: {
    username() {
      const data = this.restData[serviceName].get.data
      const { firstName, lastName } = data.data || {}

      return [ firstName, lastName ].filter(Boolean).join(' ') ?? data.username
    }
  },

  mounted() {
    this.rest[serviceName].get(this.account.id)
  },

  render
}
