export default {
  title: 'Comments',

  labels: {
    text: 'Comments'
  },

  snackbars: {
    created: 'Comment successfully created.',
    updated: 'Comment successfully updated.',
    removed: 'Comment successfully removed.'
  }
}
