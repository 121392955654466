import render from './render'

export default {
  name: 'HeadTitle',

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  render
}
