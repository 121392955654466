import { cloneDeep, isEqual } from 'lodash'

import { buttonModes, colors, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import TasksForm from '@/components/services/tasks/form'
import TasksFilter from '@/components/services/tasks/filter'
import RefreshButton from '@/components/button/refresh'
import HeadTitle from '@/components/misc/HeadTitle'
import pagination from '@/components/misc/pagination'

function renderPresetsButtons(h) {
  if (Array.isArray(this.presets) && this.presets.length > 0) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { gridTemplateColumns: `repeat(${this.presets.length}, auto)` }
      },
      this.presets.map(preset => {
        return h(
          button,
          {
            props: {
              label: this.getTranslate(`${services.tasks}.buttons.${preset.title}`),
              mode: buttonModes.flat,
              color: isEqual(this.filter, preset.filter) ? colors.primary : colors.grey
            },
            on: {
              click: () => {
                this.filter = cloneDeep(preset.filter)
              }
            }
          }
        )
      })
    )
  }
}

function renderCreateButton(h) {
  if (this.$route.name === 'billings') {
    if (this.checkPermissions(`advanced.${services.tasks}.create`, permissionPresets.resellerUp)) {
      return h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.create'),
            icon: 'add',
            mode: buttonModes.flat,
            color: colors.secondary
          },
          on: {
            click: () => {
              this.showCreateTask = true
            }
          },
          scopedSlots: {
            dialog: () => renderCreateDialog.call(this, h)
          }
        }
      )
    }
  }
}
function renderRefreshButton(h) {
  return h(
    RefreshButton,
    {
      props: {
        label: this.getTranslate(`${services.tasks}.buttons.refresh`),
        tooltip: this.getTranslate(`${services.tasks}.tooltips.refresh`),
        state: this.restData[services.tasks].find.state,
        shortClickMethod: () => this.rest[services.tasks].find(),
        longClickMethod: () => this.rest[services.tasks].find({}, { noCache: true })
      }
    }
  )
}
function renderCreateRefreshButtons(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8'
    },
    [
      renderCreateButton.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}
function renderTitle(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 fjifs',
      style: { gridTemplateColumns: this.viewport.breakpoint.mdUp ? '1fr auto' : '1fr' }
    },
    [
      renderPresetsButtons.call(this, h),
      renderCreateRefreshButtons.call(this, h)
    ]
  )
}
function renderFilter(h) {
  return h(
    TasksFilter,
    {
      props: {
        defaultFilter: this.defaultFilter,
        value: this.filter,
        type: this.restData[services.tasks].find.filter.type
      },
      on: {
        input: event => {
          this.filter = event
        }
      }
    }
  )
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderTitle.call(this, h),
      renderFilter.call(this, h)
    ]
  )
}

function renderList(h) {
  if (this.restData[services.tasks].find.pagination.total) {
    const component = item => this.$scopedSlots.item ? this.$scopedSlots.item({ item }) : this.$slots.item
    if (this.viewport.breakpoint.lgUp) {
      return this.restData[services.tasks].find.data.map(component)
    } else {
      return h(
        'g-expansion-panels',
        {
          props: { flat: true }
        },
        [ this.restData[services.tasks].find.data.map(component) ]
      )
    }
  } else {
    return h('g-empty')
  }
}

function renderCreateDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        closeOnEsc: false,
        closeOnClick: false,
        value: this.showCreateTask,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showCreateTask = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${services.tasks}.dialogs.create`) },
          slot: 'header'
        }
      ),

      h(
        TasksForm,
        {
          props: {
            value: this.restData[services.tasks].create.data,
            errors: this.restData[services.tasks].create.errors
          },
          on: {
            input: event => {
              this.restData[services.tasks].create.data = event
            },
            validation: event => {
              this.restData[services.tasks].create.isValid = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fiac fjcfe pa-2 grid-gap--8',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat,
                disabled: this.restData[services.tasks].create.state === states.loading
              },
              on: {
                click: () => {
                  this.showCreateTask = false
                }
              }
            }
          ),
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.create'),
                loading: this.restData[services.tasks].create.state === 'loading',
                disabled: !this.createDataIsValid,
                color: colors.primary
              },
              on: {
                click: async () => {
                  await this.createTask()
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderDivider(h) {
  if (Array.isArray(this.restData[services.tasks].find.data) && this.restData[services.tasks].find.data.length) {
    return h('g-divider')
  }
}

function renderPagination(h) {
  return h(
    'div',
    {
      class: 'fjcfe pa-2'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.tasks].find.pagination,
            service: services.tasks
          },
          on: {
            input: event => {
              this.restData[services.tasks].find.pagination = event
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      renderHeader.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h),
      renderDivider.call(this, h),
      renderList.call(this, h),
      renderDivider.call(this, h),
      renderPagination.call(this, h)
    ]
  )
}
