import { cloneDeep, isEqual } from 'lodash'

import { createdAtFilter, getLocaleDateString, timeParams, timeToArrayStringsWithLeadZero } from '@/utils'

import render from './render'

export default {
  name: 'DateTimeFilter',

  mixins: [ createdAtFilter ],

  props: {
    title: {
      type: String,
      default: 'createdAt'
    },
    label: {
      type: String,
      default: 'createdAt'
    },

    min: [ Number, String, Date ],
    max: [ Number, String, Date ],

    disabled: {
      type: Boolean,
      default: false
    },

    preset: {
      type: String,
      default: undefined
    },
    presets: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      date: []
    }
  },

  computed: {
    activatorContent() {
      if (this.preset) {
        return this.getTranslate(`filter.date.presets.${this.preset}`)
      } else {
        const { date, time } = cloneDeep(this.proxy)
        const result = []
        const dates = date.sort().map(item => getLocaleDateString(item, this.locale))
        const times = []

        if (dates.length) {
          for (const key in time) {
            const value = time[key]
            if (value) {
              const [ hours, minutes ] = timeToArrayStringsWithLeadZero(value)
              times.push(`${hours}:${minutes}`)
            } else {
              times.push('---')
            }
          }

          result.push(dates.join(' - '), times.join(' - '))
        } else {
          if (this.title) {
            result.push(this.getTranslate(`filter.date.titles.${this.title}`))
          } else {
            result.push(this.getTranslate('filter.date.empty'))
          }
        }

        return result.join(' | ')
      }
    }
  },

  watch: {
    proxy: {
      handler() {
        if (!isEqual(this.date, this.proxy.date)) {
          this.date = cloneDeep(this.proxy.date)
          this.$emit('preset', undefined)
        }
      },
      deep: true
    },

    date: {
      handler() {
        if (!isEqual(this.date, this.proxy.date)) {
          this.proxy.date = cloneDeep(this.date)
        }
      },
      deep: true
    }
  },

  mounted() {
    this.date = cloneDeep(this.proxy.date)
  },

  methods: {
    _presetInputHandler(event) {
      if (event) {
        this.proxy = {
          date: [],
          time: timeParams
        }
      }

      this.$emit('preset', event)
    },

    _inputHandler(event) {
      if (event) {
        this.$emit('preset', undefined)
        this.date = event
      }
    },

    _pickHandler(event) {
      if (event) {
        this.$emit('preset', undefined)
        this.$emit('pick', event)
        this.$nextTick(() => {
          const dates = cloneDeep(this.date)
          if (dates.length === 0) {
            dates.push(event)
          }
          if (dates.length === 1) {
            dates.push(event)
          }
          if (dates.length === 2) {
            const [ gt, lt ] = dates.sort()
            if (gt && lt && gt <= lt) {
              this.proxy.date = cloneDeep(dates)
              this.proxy.time = cloneDeep(timeParams)
            }
          }
        })
      }
    }
  },

  render
}
