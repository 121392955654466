function renderSwitch(h, { path, serviceName }) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`)
      }
    }
  )
}

export default function(h, options) {
  return h(
    'div',
    {
      class: `${options.serviceName}-row-switch`,
      on: {
        click: () => {
          this.proxy = !this.proxy
        }
      }
    },
    [ renderSwitch.call(this, h, options) ]
  )
}
