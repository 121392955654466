import { permissionPresets } from '@/utils'
import { serviceName } from '@/components/services/contracts/utils'

import buttonRemove from '@/components/button/service/remove'
import FileDialog from '@/components/file/dialog'
import EditButton from '@/components/services/contracts/item/buttons/edit'

function renderEditDialog(h) {
  return h(
    FileDialog,
    {
      props: {
        show: this.showDialog,
        type: 'export'
      },
      on: {
        show: event => {
          this.showDialog = event
        },
        input: event => {
          this.currentEditFile = event
        }
      }
    }
  )
}

function renderEditListItem(h) {
  if (this.checkPermissions('advanced.contracts.update', permissionPresets.resellerUp)) {
    return h(
      EditButton,
      {
        props: {
          value: this.value,
          view: 'g-list-item'
        }
      }
    )
  }
}

function renderDownloadListItem(h) {
  return h(
    'g-list-item',
    {
      props: {
        icon: 'file_download',
        label: this.getTranslate(`${serviceName}.actions.download`),
        color: 'primary',
        dense: true
      },
      on: {
        click: () => {
          this.download()
        }
      }
    }
  )
}

function renderRemoveListItem(h) {
  if (this.checkPermissions('advanced.contracts.remove', permissionPresets.resellerUp)) {
    return h(
      buttonRemove,
      {
        props: {
          id: this.value.id,
          component: 'g-list-item',
          service: serviceName
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'g-menu',
    {
      props: {
        rounded: true,
        closeOnContentClick: true,
        placement: 'bottom-end'
      }
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            icon: 'more_vert',
            flat: true
          },
          slot: 'activator'
        }
      ),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [
          renderEditListItem.call(this, h),
          renderDownloadListItem.call(this, h),
          renderRemoveListItem.call(this, h)
        ]
      ),
      renderEditDialog.call(this, h)
    ]
  )
}
