import proxy from '@sigma-legacy-libs/g-proxy'

import { services } from '@/utils'

import render from './render'

export default {
  name: `${services.sendingsModeration}Filter`,

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    preset: {
      type: String,
      default: undefined
    }
  },

  data() {
    return {
      showMenu: false
    }
  },

  computed: {
    pattern() {
      return this.proxy?.pattern?.replace(/[\n\r]/gm, ' ')
    }
  },

  watch: {
    showMenu() {
      const textarea = this.$refs.pattern.$el.querySelector('textarea')
      if (textarea) {
        this.$nextTick(() => textarea.focus())
      }
    }
  },

  render
}
