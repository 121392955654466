import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    gridTemplateColumns() {
      const result = []

      let cols = 2
      if (this.checkPermissions('advanced.users.get')) {
        cols = 3
      }

      if (this.viewport.breakpoint.mdUp) {
        result.push(`repeat(${cols}, minmax(100px, 200px))`, '36px')
      }

      return result.join(' ')
    }
  },

  render
}
