export default function(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        flat: true,
        icon: this.icon
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`commons.views.${this.proxy}`),
            placement: 'top'
          }
        }
      ],
      on: {
        click: () => {
          const index = this.views.indexOf(this.proxy)
          this.proxy = this.views[(index + 1) % this.views.length]
          this.set(this.proxy)
        }
      }
    }
  )
}
