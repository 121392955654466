import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence } from '@/utils'

export const serviceName = 'sendingsIncoming'

export async function inputFilter(result) {
  if (!result.Owner) {
    await fillDependence.call(this, result, {
      service: 'users',
      permissions: [ 'reseller', true ],
      pathToId: 'OwnerId',
      pathToObject: 'Owner',
      outputPath: 'Owner',
      picks: PUBLIC_FIELDS_USER
    })
  }

  result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

  if (result && result.payload && result.payload.file) {
    await fillDependence.call(this, result, {
      service: 'storage',
      permissions: [ 'me', 'reseller', true ],
      pathToId: 'payload.file.id',
      pathToObject: 'File',
      outputPath: 'File',
      requestParams: {
        query: {
          return: 'meta'
        }
      }
    })
  }

  return result
}

export default {
  serviceName,
  inputFilter
}
