import { services, states } from '@/utils'

import pagination from '@/components/misc/pagination'
import buttonRefresh from '@/components/button/refresh'
import item from '@/components/services/sendingsModeration/tasks/item'
import dialog from '@/components/services/sendingsModeration/tasks/dialog'

function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.restData[services.sendingsModeration].find.state,
        disabled: this.restData[services.sendingsModeration].find.state === states.loading,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.buttons.refresh`),
        shortClickMethod: () => this.rest[services.sendingsModeration].find(),
        longClickMethod: () => this.rest[services.sendingsModeration].find({}, { noCache: true })
      }
    }
  )
}

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.restData[services.sendingsModeration].find.pagination,
        selectRows: false,
        service: services.sendingsModeration
      },
      on: {
        input: event => {
          this.restData[services.sendingsModeration].find.pagination = event
        }
      }
    }
  )
}

function renderPaginationBlock(h, place) {
  return h(
    'div',
    {
      class: {
        [`${services.sendingsModeration}__pagination ${services.sendingsModeration}__pagination--${place}`]: true,
        [`${services.sendingsModeration}__pagination--fill`]: this.restData[services.sendingsModeration].find.pagination.total > 0
      }
    },
    [
      renderPagination.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}

function renderItem(h, value) {
  return h(
    item,
    {
      props: { value },
      on: {
        click: () => {
          this.task = value
          this.showDialog = true
        }
      }
    }
  )
}

function renderItems(h) {
  if (this.restData[services.sendingsModeration].find.pagination.total > 0) {
    return this.restData[services.sendingsModeration].find.data.map(task => {
      return renderItem.call(this, h, task)
    })
  }

  return h('g-empty')
}

function renderDialog(h) {
  return h(
    dialog,
    {
      props: {
        task: this.task,
        status: this.status,
        sendings: this.sendings,
        show: this.showDialog
      },
      on: {
        close: () => {
          this.showDialog = false
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${services.sendingsModeration}-tasks`
    },
    [
      renderPaginationBlock.call(this, h, 'top'),
      renderItems.call(this, h),
      renderDialog.call(this, h)
    ]
  )
}
