export default {
  title: 'Серверы',

  titles: {
    one: 'Сервер',
    create: 'Создать сервер',
    update: 'Обновить сервер',
    remove: 'Удалить сервер'
  },

  labels: {
    title: 'Название',
    host: 'Хост',
    port: 'Порт',
    processor: 'Процессор',
    search: 'Поиск'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаление сервера.'
    }
  },

  hints: {
    notFound: 'Серверы не найдены.',
    other: 'Прочее'
  },

  tooltips: {
    favorites: 'Сделать сервер избранным',
    isActive: 'Сервер активен'
  },

  snackbars: {
    created: 'Сервер успешно создан',
    updated: 'Сервер успешно обновлён',
    removed: 'Сервер успешно удалён'
  }
}
