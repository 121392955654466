import { cloneDeep } from 'lodash'

import draggable from '@/components/misc/draggable'
import tariffsRulesGroup from '@/components/services/tariffs/rules/group'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      h(
        draggable,
        {
          class: 'tariffs-rules',
          props: {
            value: this.proxy,
            handle: 'drag_handle'
          },
          on: {
            input: event => {
              this.proxy = event
            }
          },
          scopedSlots: {
            item: (item, index) => {
              return h(
                tariffsRulesGroup, {
                  props: {
                    value: cloneDeep(item),
                    target: this.proxy,
                    source: this.source,
                    index
                  },
                  key: `rules-group-${index}`,
                  on: {
                    input: event => {
                      this.proxy[index] = event
                    }
                  }
                }
              )
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe'
        },
        [
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                label: this.getTranslate('tariffs.addRule'),
                icon: 'add',
                flat: true,
                rounded: true,
                color: 'primary'
              },
              on: {
                click: () => {
                  this.add()
                }
              }
            }
          )
        ]
      )
    ]
  )
}
