import { eventsHistoryColors, eventsHistoryIcons, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import date from '@/components/serviceTemplate/item/date'

function renderService(h) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(this.service.label),
        label: this.getTranslate(`${services.eventsHistory}.labels.service`),
        borderStyle: 'dashed',
        icon: eventsHistoryIcons[this.value.type],
        iconTooltip: this.getTranslate(`${services.eventsHistory}.types.${this.value.type}`),
        color: eventsHistoryColors[this.value.type]
      }
    }
  )
}

function renderUser(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.userId,
        label: this.getTranslate(`${services.eventsHistory}.labels.author`),
        pathToTitle: 'username',
        to: {
          name: 'users.single',
          params: { id: this.value.userId }
        }
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderDetails(h) {
  return h(
    'div',
    {
      class: `${services.eventsHistory}-tab__panel-details`
    },
    [
      renderService.call(this, h),
      renderUser.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `service-template-panel ${services.eventsHistory}-tab__panel`,
      on: {
        click: () => {
          this.clickHandler()
        }
      }
    },
    [
      renderDetails.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
