import { components } from '@/utils'

function renderText(h, { text }) {
  if (text) {
    return h(
      'div',
      {
        class: `${components.snackbar}__text`,
        domProps: { innerHTML: text }
      }
    )
  }
}

function renderButton(h, { button }) {
  if (button) {
    return h(
      'g-button',
      {
        class: button.class,
        props: Object.assign({
          flat: true,
          rounded: true,
          color: 'white'
        }, button.props || {}),
        on: { click: button.on.click }
      }
    )
  }
}

function renderCancelable(h, { cancelable, remove }) {
  if (cancelable) {
    return h(
      'g-button',
      {
        props: {
          flat: true,
          icon: 'clear',
          color: 'white'
        },
        on: {
          click: () => {
            remove()
          }
        }
      }
    )
  }
}

function renderItem(h, item) {
  const classes = [ `${components.snackbar}__item`, `${components.snackbar}__item--${item.type}` ]
  if (Array.isArray(item.classes) && item.classes.length) {
    classes.push(...item.classes)
  }

  return h(
    'div',
    {
      class: classes,
      key: item.id
    },
    [
      renderText.call(this, h, item),
      renderButton.call(this, h, item),
      renderCancelable.call(this, h, item)
    ]
  )
}
function renderItems(h) {
  return this.items.map(item => renderItem.call(this, h, item))
}

export default function(h) {
  return h(
    'div',
    {
      class: this.direction.reduce(
        (result, direction) => {
          result.push(`${components.snackbar}--${direction}`)

          return result
        },
        [ `${components.snackbar}` ]
      )
    },
    [ renderItems.call(this, h) ]
  )
}
