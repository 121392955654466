import render from './render'

export default {
  name: 'EntityTable',

  props: {
    rows: {
      type: Array,
      default: () => []
    }
  },

  render
}
