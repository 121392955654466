import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'GlobalDialog',

  computed: {
    ...mapGetters({ dialog: 'dialog/dialog' })
  },

  methods: {
    ...mapActions({
      hide: 'dialog/hide',
      setIsShowed: 'dialog/setIsShowed'
    })
  },

  render
}
