import { components } from '@/utils'

import render from './render'

export default {
  name: components['text-overflow'],

  props: {
    value: [ String, Number, Boolean ],

    placement: {
      type: String,
      default: 'right'
    }
  },

  data() {
    return {
      isOverflowed: false
    }
  },

  computed: {
    $value() {
      return String(this.value)
    },

    $directives() {
      const result = []
      if (this.isOverflowed) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.$value,
            placement: this.placement
          }
        })
      }

      return result
    }
  },

  mounted() {
    this.$el.addEventListener('mouseover', this.onHover)
    this.onHover()
  },

  beforeDestroy() {
    this.$el.removeEventListener('mouseover', this.onHover)
  },

  methods: {
    onHover() {
      const target = this.$refs.content
      if (target) {
        const clientWidth = target.clientWidth
        const scrollWidth = target.scrollWidth

        this.isOverflowed = clientWidth < scrollWidth
      }
    }
  },

  render
}
