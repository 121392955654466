import { cloneDeep } from 'lodash'

import { PAYMENTS_FILTER, dateFilter } from '@/utils'

import render from './render'

export default {
  name: 'PaymentsFilter',

  mixins: [ dateFilter ],

  methods: {
    getDefaultFilter() {
      return cloneDeep(PAYMENTS_FILTER)
    },

    clearFilter() {
      this.proxy = cloneDeep(this._inputFilter(this.getDefaultFilter()))
    }
  },

  render
}
