export default function(h, { path, serviceName }) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        mode: 'line-label',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
