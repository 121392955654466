import render from './render'

export default {
  name: 'Sendings',

  props: {
    sendings: {
      type: Array,
      default: () => []
    },

    filter: {
      type: Object,
      default: () => ({})
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      pagination: {
        limit: 100,
        offset: 0,
        total: 0
      }
    }
  },

  computed: {
    items() {
      if (Array.isArray(this.sendings) && this.sendings.length) {
        return this.sendings.slice(this.pagination.offset, this.pagination.offset + this.pagination.limit)
      }

      return []
    }
  },

  watch: {
    sendings: {
      handler() {
        this.pagination.total = this.sendings.length
      },
      deep: true
    }
  },

  mounted() {
    this.pagination.total = this.sendings.length
  },

  render
}
