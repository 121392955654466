import { colors, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    sticker,
    {
      props: {
        value: this.inn,
        label: this.getTranslate(`${services.requisites}.labels.data.legal.inn`),
        copy: true,
        icon: icons.numbers,
        color: colors.grey
      }
    }
  )
}
