import { jwtDecode } from 'jwt-decode'
import { toSafeInteger } from 'lodash'

import { projectName, socketDisconnect, socketsRenew } from '@/utils'

const key = `${projectName}:token`
let timer = false

export default {
  namespaced: true,

  state: {
    token: false
  },

  mutations: {
    setToken(state, token) {
      if (token) {
        window.localStorage.setItem(key, token)
        state.token = token
      } else {
        window.localStorage.removeItem(key)
        state.token = false
      }
    }
  },

  actions: {
    setTimer({ state, dispatch }) {
      if (timer) {
        clearInterval(timer)
      }
      timer = setInterval(() => {
        if (state.token) {
          dispatch('login/logout', null, { root: true })
        }
        clearInterval(timer)
      }, 60 * 60 * 1000)
    },

    setToken({ commit }, token) {
      let decoded
      if (token) {
        const now = toSafeInteger(Date.now() / 1000)
        decoded = jwtDecode(token)
        if (now >= decoded.exp) {
          token = false
        }
      }

      commit('setToken', token)

      return decoded
    },

    async setAccountFromToken({ dispatch, state }) {
      if (state.token) {
        const decode = jwtDecode(state.token)

        await dispatch('setTimer')
        await dispatch('account/getAccount', decode.id, { root: true })

        socketsRenew()
      } else {
        if (timer) {
          clearInterval(timer)
        }

        dispatch('account/setAccount', false, { root: true })

        socketDisconnect()
      }
    }
  },

  getters: {
    token: state => state.token
  }
}
