import { serviceName } from '@/components/services/providers/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  const comment = this.value.comment
  if (comment && this.viewport.breakpoint.xl) {
    return h(
      sticker,
      {
        props: {
          icon: 'comment',
          color: 'grey',
          value: comment,
          label: this.getTranslate(`${serviceName}.labels.comment`),
          borderStyle: 'dashed'
        }
      }
    )
  }
}
