import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'ColorPicker',

  mixins: [ proxy() ],

  props: {
    defaultValue: {
      type: String,
      default: '#CCCCCC'
    },

    color: String,
    label: String,
    hint: String
  },

  render
}
