import { serviceName } from '@/components/services/yclients/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.Owner || this.value.OwnerId,
        pathToTitle: 'username',
        label: this.getTranslate(`${serviceName}.labels.owner`)
      }
    }
  )
}
