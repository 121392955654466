import { buttonModes, colors, services } from '@/utils'

import button from '@/components/button'
import FileManager from '@/components/file/manager'
import RoutesFormTitle from '@/components/services/routes/form/title'
import keywordsList from '@/components/services/keywords/list'

function renderTitleField(h) {
  return h(
    RoutesFormTitle,
    {
      props: {
        value: this.proxy,
        showIcon: false,
        serviceName: services.keywordLists
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}

function renderImportButton(h) {
  if (!this.isNew) {
    return h(
      'div',
      {
        class: 'fjcc'
      },
      [
        h(
          button,
          {
            props: {
              label: this.getTranslate('misc.buttons.import'),
              icon: 'import_export',
              color: colors.primary,
              mode: buttonModes.flat
            },
            on: {
              click: () => {
                this.showFileManager = true
              }
            }
          }
        )
      ]
    )
  }
}

function renderFileManager(h) {
  if (!this.isNew) {
    return h(
      FileManager,
      {
        props: {
          show: this.showFileManager,
          flat: true,
          readonly: true,
          embedded: true,
          types: [ 'csv', 'xls', 'xlsx' ]
        },
        on: {
          show: event => {
            this.showFileManager = event
          },
          input: event => {
            this.showFileManager = false
            this.import(event.id)
          }
        }
      }
    )
  }
}

function renderKeywordsList(h) {
  if (!this.isNew) {
    return h(keywordsList(this.$route.params.id, this.query.keyword))
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid'
    },
    [
      renderTitleField.call(this, h),
      renderImportButton.call(this, h),
      renderFileManager.call(this, h),
      renderKeywordsList.call(this, h)
    ]
  )
}
