import Vue from 'vue'

import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, globalErrorHandler, globalErrorProcessor, processFilter, tableHeaderParser } from '@/utils'

import render from './render'

export default {
  name: 'Group001',

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      loading: false,

      report: [],
      order: {}
    }
  },

  computed: {
    headers() {
      return tableHeaderParser.call(this, [
        {
          title: 'billings.headers.source',
          value: 'source',
          align: 'left',
          sortable: true
        },
        {
          title: 'billings.headers.owner',
          value: 'Owner.username',
          align: 'left',
          sortable: true,
          class: 'pl-2 pr-2',
          show: this.checkPermissions('advanced.users.get', [ 'reseller', true ])
        },
        {
          title: 'billings.headers.tariff',
          value: 'Tariff.title',
          align: 'left',
          sortable: true,
          class: 'pl-2 pr-2',
          show: this.checkPermissions('advanced.tariffs.get', [ 'reseller', true ])
        },
        {
          title: 'billings.headers.count_pending',
          value: 'count_pending',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'billings.headers.count_complete',
          value: 'count_complete',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'billings.headers.count_total',
          value: 'count_total',
          align: 'right',
          width: '90px',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'billings.headers.count_refunded',
          value: 'count_refunded',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        },
        {
          title: 'billings.headers.count_invalid',
          value: 'count_invalid',
          align: 'right',
          class: 'pl-2 pr-2',
          sortable: true
        }
      ])
    },

    total() {
      let countComplete = 0
      let countInvalid = 0
      let countPending = 0
      let countRefunded = 0
      let countTotal = 0
      let sumComplete = 0
      let sumInvalid = 0
      let sumPending = 0
      let sumRefunded = 0
      let sumTotal = 0

      this.report.forEach(item => {
        countComplete += item.count_multiplier_complete
        countInvalid += item.count_multiplier_invalid
        countPending += item.count_multiplier_pending
        countRefunded += item.count_multiplier_refunded
        countTotal += item.count_multiplier_total
        sumComplete += item.sum_complete
        sumInvalid += item.sum_invalid
        sumPending += item.sum_pending
        sumRefunded += item.sum_refunded
        sumTotal += item.sum_total
      })

      return {
        countComplete,
        countInvalid,
        countPending,
        countRefunded,
        countTotal,
        sumComplete,
        sumInvalid,
        sumPending,
        sumRefunded,
        sumTotal
      }
    },

    colspan() {
      if (
        this.checkPermissions('advanced.users.get', [ 'reseller', true ]) &&
        this.checkPermissions('advanced.tariffs.get', [ 'reseller', true ])
      ) {
        return 3
      } else if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
        return 2
      } else if (this.checkPermissions('advanced.tariffs.get', [ 'reseller', true ])) {
        return 2
      } else {
        return 1
      }
    }
  },

  watch: {
    filter: {
      handler() {
        this.getReport()
      },
      deep: true
    }
  },

  methods: {
    async getReport() {
      try {
        this.loading = true

        const result = await Vue.$GRequest.find('reports', { query: processFilter(this.filter, { $preset: 'billings.group001' }) })

        if (result) {
          this.report = await Promise.all(
            result.data.map(async data => {
              if (!data.Owner) {
                await fillDependence.call(this, data, {
                  service: 'users',
                  permissions: [ 'reseller', true ],
                  pathToId: 'OwnerId',
                  pathToObject: 'Owner',
                  outputPath: 'Owner',
                  picks: PUBLIC_FIELDS_USER
                })
              }

              if (!data.Tariff) {
                await fillDependence.call(this, data, {
                  service: 'tariffs',
                  permissions: [ 'reseller', true ],
                  pathToId: 'TariffId',
                  outputPath: 'Tariff',
                  picks: [ 'id', 'title' ]
                })
              }

              return data
            })
          )
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
