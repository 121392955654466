import Vue from 'vue'

import { toSafeInteger } from 'lodash'

import proxy from '@sigma-legacy-libs/g-proxy'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'BatchParams',

  mixins: [ proxy({ type: 'object' }) ],

  data() {
    return {
      loading: false,
      showMenu: false
    }
  },

  watch: {
    showMenu(value) {
      this.$emit('menu', value)
    }
  },

  methods: {
    async updateTask() {
      try {
        this.loading = true
        await Vue.$GRequest.update(services.tasks, this.proxy.id, {
          data: {
            cycle: {
              batch: toSafeInteger(this.proxy.data.cycle.batch),
              every: toSafeInteger(this.proxy.data.cycle.every)
            }
          }
        })
        this.addSnackbar({
          text: this.getTranslate(`${services.tasks}.snackbars.updated`),
          type: 'success'
        })
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    transmitValue() {}
  },

  render
}
