import { types } from './sendings'

export const notificationTypes = {
  tariff: {
    update: 'Tariff updated'
  },

  balance: {
    limit: 'Balance limit reached',
    accrual: 'Balance accrual',
    daily: 'Daily balance'
  },

  payments: {
    failed: 'Payment failed'
  },

  auth: {
    registration: 'Registration',
    recover: 'Password recovery'
  },

  pattern: {
    approved: 'Pattern approved',
    rejected: 'Pattern rejected'
  },

  sendername: {
    approved: 'Sender name approved',
    rejected: 'Sender name rejected'
  },

  subscription: {
    enabled: 'Subscription enabled',
    renew: {
      failed: 'Subscription renewal failed'
    }
  },

  apisender: {
    instance: {
      created: 'API sender instance created',
      connected: 'API sender instance connected',
      disconnected: 'API sender instance disconnected'
    }
  },

  sendings: {
    bulk: {
      created: 'Bulk sending created',
      paused: 'Bulk sending paused',
      resumed: 'Bulk sending resumed'
    }
  },

  system: {
    platform: {
      issues: 'Platform issues'
    },

    route: {
      issues: 'Route issues'
    }
  }
}

const templates = { ...notificationTypes }

export default {
  title: 'Notifications',

  titles: {
    variables: 'Variables'
  },

  subtitles: {
    settingsSite: {
      smtp: 'SMTP connection',
      sms: 'SMS notifications',
      templates: 'Notification templates'
    }
  },

  labels: {
    notification: 'Notification',
    subject: 'Subject',
    body: 'Body template',

    channel: 'Channel',
    owner: 'Recipient',
    targetUser: 'Recipient',
    type: 'Type',
    text: 'Text',
    sentAt: 'Sent at',
    isRead: 'Status',

    widget: {
      header: 'Last notifications',
      hint: 'Last 10 notifications received via Web channel',
      empty: 'Your notifications will appear here'
    },

    settingsSite: {
      smtp: {
        host: 'Host',
        port: 'Port',
        from: 'From',
        login: 'Login',
        password: 'Password'
      },

      sms: {
        senderUserId: 'Sender',
        sendername: 'Sender name'
      },

      templates: {
        users: 'Templates for users',
        managers: 'Templates for managers'
      }
    },

    settingsUser: {
      templates: {
        my: 'My events',
        users: 'Users events'
      },

      balance: {
        title: 'Balance notifications',

        include: {
          overdraft: 'Including overdraft',
          invoice: 'invoice included'
        },
        notInclude: {
          overdraft: 'Excluding overdraft',
          invoice: ''
        },

        limit: 'Limit',

        onLimit: 'When balance is',
        noLimit: '<not specified>',
        notifyBy: 'notify via',
        noNotifications: '<no method selected>',
        activator: 'We will send you notification when your balance will reach {limit}',
        overdraft: ' + user overdraft',
        extraRecipients: 'Notifications on the balance status of all users of the system',
        invoice: 'Attach invoice for the amount {amount}',

        buttons: {
          add: 'Add limit',
          invoice: {
            attach: 'Attach invoice',
            detach: 'Detach invoice',
            attached: 'Invoice will be attached for an amount {amount}'
          }
        }
      }
    }
  },

  hints: {
    variables: 'Please refer to the documentation for detailed guidance on correctly filling out templates and using variables.',

    widget: {
      header: 'Here are the latest notifications on topics for which web notifications are enabled.'
    }
  },

  channels: types,
  types: notificationTypes,
  events: notificationTypes,
  childrenUsersEvents: notificationTypes,

  email: {
    smtp: {
      host: 'Host',
      port: 'Port',
      from: 'From',
      login: 'Login',
      password: 'Password'
    },

    templates
  },

  sms: {
    sendername: 'Sender name',
    senderUserId: 'Sender',
    templates
  },

  web: { templates },
  push: { templates },
  tgBot: { templates },

  snackbars: {
    updated: 'Setting successfully updated.'
  },

  isRead: {
    true: 'Read',
    false: 'Unread',
    sent: 'Sent: {date}',
    delivered: 'Delivered: {date}'
  }
}

