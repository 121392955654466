import Vue from 'vue'

import { Macro } from '@sigma-legacy-libs/cache'

import render from './render'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

const Cache = new Macro({
  ttl: 5 * 1000,
  ttlInterval: 1000
})

export default {
  name: 'FileItemCard',

  props: {
    value: {
      type: [ String, Object ],
      default: () => ({})
    },

    currentChosenFileId: String,
    readonly: Boolean,
    compact: Boolean,

    rounded: {
      type: Boolean,
      default: true
    },
    outline: {
      type: Boolean,
      default: true
    },

    displayMenu: {
      type: Boolean,
      default: true
    },

    flat: {
      type: Boolean,
      default: false
    },

    include: {
      type: Array,
      default: () => [ 'edit', 'update', 'download', 'export', 'remove' ]
    },

    maxWidth: [ Number, String ],
    minWidth: [ Number, String ],
    width: [ Number, String ],

    paddingAll: {
      type: Boolean,
      default: true
    },

    small: {
      type: Boolean,
      default: false
    },

    transparent: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      file: undefined
    }
  },

  watch: {
    value: {
      handler() {
        this.getFileMeta()
      },
      deep: true
    }
  },

  mounted() {
    this.getFileMeta()
  },

  methods: {
    cachedGet: Cache.wrapWithCache(async (key, id) => {
      return await Vue.$GRequest.get('storage', id, { query: { return: 'meta' } })
    }),

    async getFileMeta() {
      if (typeof this.value === 'string') {
        try {
          const [ id1, id2 ] = this.value.split('/')
          const fileId = id2 || id1
          if (fileId) {
            const { data } = await this.cachedGet(`storage:${fileId}`, fileId)
            if (data) {
              this.file = data
            }
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      } else {
        this.file = this.value
      }
    }
  },

  render
}
