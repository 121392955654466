import { serviceTemplate } from '@/utils'

import { serviceName } from '@/components/services/apisender/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName,

      find: {
        defaultFilter: {
          OwnerId: undefined
        }
      },
      create: { redirect: true },

      view: 'panels',
      views: [ 'panels' ],
      titlePath: 'instance.idInstance',

      has: {
        view: false,
        clone: false
      },

      formPaddles: true,
      width: 420
    })
  ]
}
