import { colors } from '@/utils'

import render from './render'

export default {
  name: 'svgIcon',

  props: {
    value: {
      type: String,
      default: undefined
    },

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    size: {
      type: Number,
      default: 24
    },

    tooltip: {
      type: String,
      default: undefined
    }
  },

  computed: {
    $directives() {
      const result = []
      if (this.tooltip) {
        result.push({
          name: 'g-tooltip',
          options: {
            value: this.tooltip,
            placement: 'top'
          }
        })
      }

      return result
    },

    $icon() {
      switch (this.value) {
        case 'smsping': return 'sms'
        case 'apisender': return 'whatsapp'
        case 'mobileid_mts_push':
        case 'mobileid_mts_push_sms': return 'mobileid'
        case 'incoming_tg_bot': return 'telegram'
        case 'whatsappchat':
        case 'viberchat':
        case 'vkchat':
        case 'telegramchat':
        case 'facebookchat': return 'chat'
        default: return this.value
      }
    }
  },

  render
}
