import { DICTIONARIES } from '@sigma-legacy-libs/essentials/lib/constants'

import HintRow from '@/components/misc/HintRow'
import HeadTitle from '@/components/misc/HeadTitle'

const columns = {
  [DICTIONARIES['phone:mnp']]: [ 'Number', 'OwnerId', 'MCC', 'MNC' ],
  [DICTIONARIES['phone:ranges']]: [ 'NumberFrom', 'NumberTo', 'OwnerId', 'RegionCode', 'MCC', 'MNC' ],
  [DICTIONARIES['phone:opgroups']]: [ 'GROUP', 'OrgCode', 'TYPE', 'MCC', 'MNC' ]
}

const name = 'dictionaries'

function renderInfoColumns(h) {
  return h(
    'div',
    {
      class: 'pa-3'
    },
    columns[this.dictionaryType].map(column => {
      return h(
        'div',
        {
          class: 'faic pb-1'
        },
        [
          h(
            'div',
            {
              class: 'subtitle-2',
              style: {
                'min-width': '85px'
              }
            },
            column
          ),
          h(
            'div',
            {
              class: 'ml-2'
            },
            this.getTranslate(`${name}.info.columns.${column}`)
          )
        ]
      )
    })
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        minWidth: 300,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate(`${name}.titles.${this.dictionaryType}`) },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'fc'
        },
        [
          renderInfoColumns.call(this, h),

          h(HintRow, { props: { value: this.getTranslate(`${name}.info.hint`) } })
        ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-button',
    {
      props: {
        flat: true,
        icon: 'info_outline',
        color: 'primary'
      },
      on: {
        click: () => {
          this.showDialog = true
        }
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${name}.tooltips.info`),
            placement: 'top'
          }
        }
      ],
      scopedSlots: {
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
