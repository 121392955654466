export const hostnames = [
  'localhost',
  '52.29.25.132',
  'devonline.sigmasms.ru',
  'stage.sigmasms.ru',
  'online.sigmasms.ru',
  'online.mirsms.ru',
  'online.freesender.net',
  'online.apisender.com',
  'stage-online.sigmasms.ru',
  'stage-user.sigmasms.ru',
  '76v76js02-legacy.frontend.dev.sigmasms.ru',
  '76v76js02-client-side.frontend.dev.sigmasms.ru'
]
