import proxy from '@sigma-legacy-libs/g-proxy'

import { dateFilter, datePresets } from '@/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ dateFilter, proxy({ type: 'object' }) ],

  data() {
    return {
      preset: datePresets.today
    }
  },

  render
}
