import download from '@/components/services/invoices/item/buttons/download'

export default function(h) {
  return h(
    download,
    {
      props: {
        value: this.value,
        view: 'form'
      }
    }
  )
}
