import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'RoutingRulesFields',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    type: String,
    disabled: Boolean
  },

  computed: {
    tag: {
      get() {
        return this.proxy.tag || this.proxy.type
      },
      set(value) {
        this.proxy.tag = value
      }
    }
  },

  render
}
