import { colors, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import svgIcon from '@/components/icon/svg'
import squircle from '@/components/icon/squircle'

function renderTokenIcon(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    squircle,
    {
      props: {
        color: colors.grey,
        tooltip: this.getTranslate(`${services.tokens}.labels.token`)
      },
      slot: 'icon'
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: 'ai-digital-key',
            color: colors.grey,
            size: 20
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.token,
        label: this.label,
        copy: true,
        sensitivity: this.sensitivity,
        onClick: () => this.clickHandler()
      }
    },
    [ renderTokenIcon.call(this, h) ]
  )
}
