import { get, isPlainObject } from 'lodash'

export const getFileId = data => {
  const result = []
  if (Array.isArray(data)) {
    result.push(...data)
  } else if (isPlainObject(data)) {
    const { id, Owner, OwnerId } = data
    result.push(get(Owner, 'id', OwnerId))
    result.push(id)
  } else if (typeof data === 'string') {
    const [ id1, id2 ] = data.split('/')
    result.push(id1, id2)
  }

  return result.filter(item => !!item).join('/')
}
