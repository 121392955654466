import { currentTab, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'PaymentsTemplate',

  mixins: [ currentTab(services.payments) ],

  computed: {
    tabs() {
      const result = []
      for (const service of [ services.payments, services.recurrentPayments ]) {
        if (this.checkPermissions(`advanced.${service}.get`, permissionPresets.meUp)) {
          result.push(service)
        }
      }

      return result
    }
  },

  render
}
