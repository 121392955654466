import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import dateTimeFilter from '@/components/filter/date'
import OwnerSelect from '@/components/misc/OwnerSelect'
import createdAtPreset from '@/components/misc/createdAtPreset'
import SendingTypes from '@/components/misc/SendingTypes'

function renderFieldSendingsType(h) {
  return h(
    SendingTypes,
    {
      props: {
        value: this.proxy.data.sending.type,
        label: this.getTranslate(`${services.tasks}.labels.type`),
        permission: `advanced.${services.tasks}.find`
      },
      on: {
        input: event => {
          this.proxy.data.sending.type = event
        }
      }
    }
  )
}

function renderFieldContactList(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.ListId,
        label: this.getTranslate(`${services.tasks}.labels.contactLists`),
        service: 'contactLists',
        clearable: true,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.ListId = event
        }
      }
    }
  )
}

function renderFieldState(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.state,
        label: this.getTranslate(`${services.tasks}.labels.state`),
        items: TASK_STATES.map(state => {
          return {
            title: this.getTranslate(`${services.tasks}.states.${state}`),
            value: state
          }
        }),
        clearable: true,
        mode: 'outline',
        dense: true,
        rounded: true,
        details: false
      },
      on: {
        input: event => {
          this.proxy.state = event
        }
      }
    }
  )
}

function renderDateTimeFilter(h, field, fieldToClear) {
  return h(
    dateTimeFilter,
    {
      props: {
        value: this.proxy[field],
        title: field,
        label: field
      },
      on: {
        input: event => {
          if (event) {
            this.proxy.createdAtPreset = undefined
          }

          this.proxy[field] = event
          this.dropDateFilter(fieldToClear)
        }
      }
    }
  )
}

function renderCreatorSelect(h) {
  if (this.$route.name === 'billings') {
    return h(
      OwnerSelect,
      {
        props: {
          value: this.proxy.CreatorId,
          label: this.getTranslate(`${services.tasks}.labels.creator`),
          mode: 'outline',
          dense: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.CreatorId = event
          }
        }
      }
    )
  } else {
    return h(
      'g-text-field',
      {
        props: {
          value: this.proxy.id,
          label: this.getTranslate(`${services.tasks}.labels.id`),
          mode: 'outline',
          dense: true,
          rounded: true,
          clearable: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.id = event
          }
        }
      }
    )
  }
}

function renderFirstColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        OwnerSelect,
        {
          props: {
            value: this.proxy.OwnerId,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.proxy.OwnerId = event
            }
          }
        }
      ),

      renderCreatorSelect.call(this, h)
    ]
  )
}
function renderSecondColumn(h) {
  if (~this.type.indexOf('sendings:bulk')) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderFieldSendingsType.call(this, h),
        renderFieldContactList.call(this, h)
      ]
    )
  }
}
function renderThirdColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      renderFieldState.call(this, h),
      renderDateTimeFilter.call(this, h, 'startsAt', 'createdAt')
    ]
  )
}
function renderFourthColumn(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8'
    },
    [
      h(
        createdAtPreset,
        {
          props: {
            value: this.proxy.createdAtPreset
          },
          on: {
            input: event => {
              if (event) {
                this.proxy.createdAt = {
                  $gt: undefined,
                  $lt: undefined
                }
              }

              this.proxy.createdAtPreset = event
            }
          }
        }
      ),

      renderDateTimeFilter.call(this, h, 'createdAt', 'startsAt')
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'grid grid-gap--8': true,
        'grid-cols--4': this.viewport.breakpoint.lgUp && ~this.type.indexOf('sendings:bulk'),
        'grid-cols--3': this.viewport.breakpoint.md || !~this.type.indexOf('sendings:bulk') && this.viewport.breakpoint.lgUp,
        'grid-cols--2': this.viewport.breakpoint.sm
      }
    },
    [
      renderFirstColumn.call(this, h),
      renderSecondColumn.call(this, h),
      renderThirdColumn.call(this, h),
      renderFourthColumn.call(this, h)
    ]
  )
}
