import { get } from 'lodash'

import render from './render'

export default {
  name: 'OPF',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    opf() {
      return get(this.value, 'data.legal.opf')
    }
  },

  render
}

