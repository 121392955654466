import { hasPermission } from '@/components/services/invoices/utils'

import render from './render'

export default {
  name: 'Buttons',

  mixins: [ hasPermission ],

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    view: {
      type: String,
      default: 'panel',
      validator: value => {
        return ~[ 'panel', 'form' ].indexOf(value)
      }
    }
  },

  render
}
