import { moderationColors, moderationStatusIcons, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'

export default function(h) {
  return h(
    squircle,
    {
      props: {
        icon: moderationStatusIcons[this.value.moderation],
        color: moderationColors[this.value.moderation],
        size: sizes.medium,
        tooltip: this.getTranslate(`misc.moderation.${this.value.moderation}`)
      }
    }
  )
}
