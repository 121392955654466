import { components } from '@/utils'

function renderTitle(h) {
  if (this.title) {
    return h(
      'div',
      {
        class: `${components.card}__title`
      },
      [ this.title ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: this.$class,
      style: this.$style,
      on: this.$eventHandlers
    },
    [
      renderTitle.call(this, h),
      this.$slots.default
    ]
  )
}
