import proxy from '@sigma-legacy-libs/g-proxy'

import { EVENTSHISTORY_ASSOCIATED_SERVICES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    rows() {
      const rows = []
      const excludeKeys = [ 'createdAt', 'updatedAt', 'User', '$formattedCreatedAt', '$formattedUpdatedAt', 'data' ]
      for (const key in this.value) {
        if (excludeKeys.includes(key)) {
          continue
        }

        const result = {
          title: this.getTranslate(`${services.eventsHistory}.labels.${key}`),
          copy: true,
          value: this.value[key]
        }
        switch (key) {
          case 'userId': {
            result.link = true
            result.to = {
              name: 'users.single',
              params: { id: this.value[key] }
            }
            break
          }
          case 'entityId': {
            result.link = true
            result.to = {
              name: this.value.service + '.single',
              params: { id: this.value[key] }
            }

            switch (this.value.service) {
              case EVENTSHISTORY_ASSOCIATED_SERVICES.tasks: {
                result.to = {
                  name: 'billings',
                  params: {
                    id: this.value.data.id,
                    createdAt: this.getCreatedAt()
                  },
                  query: { currentTab: 'tasks' }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
                result.to = {
                  name: 'billings',
                  params: {
                    id: this.value.data.id,
                    createdAt: this.getCreatedAt()
                  },
                  query: { currentTab: 'billings' }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactLists: {
                result.to = {
                  name: 'contacts.single',
                  params: {
                    id: this.value.data.id
                  }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
                result.to = {
                  name: 'contacts.single',
                  params: {
                    id: this.value.data.ListId
                  }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.templates: {
                result.to = {
                  name: 'create',
                  params: {
                    id: this.value.data.id
                  }
                }
                break
              }
            }

            break
          }
          case 'service': {
            let title = `${this.value.service}.title`
            switch (this.value.service) {
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
                title = `${services.eventsHistory}.services.contacts`
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
                title = `${services.eventsHistory}.services.billingsUsers`
                break
              }
            }

            result.value = this.getTranslate(title)
            result.link = true
            result.to = { name: this.value[key] }

            switch (this.value.service) {
              case EVENTSHISTORY_ASSOCIATED_SERVICES.tasks: {
                result.to = {
                  name: 'billings',
                  query: { currentTab: 'tasks' }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
                result.to = {
                  name: EVENTSHISTORY_ASSOCIATED_SERVICES.billings,
                  query: { currentTab: 'billings' }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactLists: {
                result.to = {
                  name: 'contacts.single',
                  params: {
                    id: this.value.data.id
                  }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
                result.to = {
                  name: 'contacts',
                  params: {
                    id: this.value.data.ListId
                  }
                }
                break
              }
              case EVENTSHISTORY_ASSOCIATED_SERVICES.templates: {
                result.to = {
                  name: 'create'
                }
                break
              }
            }

            break
          }
          case 'type': {
            result.value = this.getTranslate(`${services.eventsHistory}.types.${this.value[key]}`)
          }
        }
        rows.push(result)
      }

      return rows
    }
  },

  methods: {
    getCreatedAt() {
      const gt = this.value.data.createdAt
      const lt = new Date(gt).setSeconds(new Date(gt).getSeconds() + 1)

      return {
        $gt: gt,
        $lt: new Date(lt).toISOString()
      }
    }
  },

  render
}
