import { isStringNotEmpty } from '@/utils'

export default function(h) {
  if (isStringNotEmpty(this.value)) {
    if (this.value.length > this.length) {
      return h(
        'span',
        {
          class: 'text-overflow',
          slot: 'activator',
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.value,
                placement: this.direction
              }
            }
          ]
        },
        this.value.slice(0, this.length) + '...'
      )
    } else {
      return h(
        'div',
        {
          class: 'text-overflow'
        },
        this.value
      )
    }
  } else {
    return h(
      'div',
      [ this.$slots.default ]
    )
  }
}
