export default {
  title: 'WebSockets',

  labels: {
    connected: 'Подключено по WebSockets',
    disconnected: 'Отключено от WebSockets'
  },

  tooltips: {
    connection: 'Этот статус означает что ваша текущая вкладка подключена к нашей платформе по протоколу WebSockets. Это означает что вы будете получать обновления статусов, баланса и т.п. в реальном времени. Если вы видите что этот статус отключен, то вам необходимо перезагрузить страницу. Если это не помогло, то проверьте ваше интернет соединение. Если интернет соединение в порядке, то возможно у нас возникли технические неполадки. Попробуйте перезагрузить страницу позже.'
  }
}
