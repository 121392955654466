import { allowedSendingTypes } from '@/utils'

import SendingTypes from '@/components/misc/SendingTypes'

export default function(h, options) {
  return h(
    SendingTypes,
    {
      props: {
        value: this.proxy,
        items: allowedSendingTypes,
        view: 'buttons',
        multiple: options.type === 'array',
        disabled: this.disabled,
        readonly: this.readonly
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
