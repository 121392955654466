import priceTextField from '@/components/services/tariffs/priceTextField'

import { serviceName } from '@/components/services/tariffs/utils'

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__group-main hover-parent hover-parent--opacity`
    },
    [
      h(
        'div',
        {
          class: 'fjcc faic square--38'
        },
        [
          h(
            'g-button',
            {
              class: 'ma-0',
              props: {
                flat: true,
                small: true,
                icon: 'remove',
                color: 'error'
              },
              on: {
                click: () => {
                  this.$emit('remove')
                }
              }
            }
          )
        ]
      ),

      h(
        'div',
        {
          class: `${serviceName}-rules__group-main-action-prefix`
        },
        this.getTranslate(`${serviceName}.${this.isMultiple ? 'otherCases' : 'always'}`)
      ),

      h(
        'g-menu',
        {
          props: { rounded: true }
        },
        [
          h(
            'div',
            {
              class: {
                [`${serviceName}-rules__group-main-action-title link`]: true,
                [`${serviceName}-rules__group-main-action-title--prefixed`]: this.isMultiple
              },
              slot: 'activator'
            },
            this.getTranslate(`${serviceName}.actions.main.${this.proxy.action}`)
          ),

          h(
            'g-list',
            {
              props: {
                dense: true,
                rounded: true
              }
            },
            [ 'decrease', 'increase' ].map(action => {
              return h(
                'g-list-item',
                {
                  props: {
                    color: action === 'decrease' ? 'error' : 'success',
                    label: this.getTranslate(`${serviceName}.actions.main.${action}`),
                    dense: true
                  },
                  on: {
                    click: () => {
                      this.proxy.action = action
                    }
                  }
                }
              )
            })
          )
        ]
      ),

      h(
        'div',
        {
          class: `${serviceName}-rules__group-main-action-icon`
        },
        [
          h(
            'g-icon',
            {
              props: {
                color: this.proxy.action === 'decrease' ? 'error' : 'success',
                value: this.proxy.action === 'decrease' ? 'arrow_drop_down' : 'arrow_drop_up'
              }
            }
          )
        ]
      ),

      h(
        priceTextField,
        {
          props: {
            value: this.proxy.price,
            source: this.source
          }
        }
      )
    ]
  )
}
