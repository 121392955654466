import FileType from '@/components/file/type'
import FileMenu from '@/components/file/menu'
import FileInfo from '@/components/file/info'

export default function(h) {
  return h(
    'g-list-item',
    {
      class: {
        'pa-2': true,
        file__info: true,
        'file__info--current': this.currentChosenFileId === this.value.id
      },
      style: { 'max-height': 'unset' },
      on: {
        click: () => {
          if (this.readonly) {
            this.$emit('input', this.value)
          }
        }
      },
      key: 'file-as-list:' + this.value.id
    },
    [
      h(
        FileType,
        {
          class: 'mr-3',
          props: { value: this.value }
        }
      ),

      h(
        FileInfo,
        {
          props: {
            value: this.value,
            readonly: this.readonly
          }
        }
      ),

      h('div', { class: 'ff' }),

      h(
        FileMenu,
        {
          props: {
            value: this.value,
            readonly: this.readonly
          },
          on: {
            dialog: event => {
              this.$emit('dialog', event)
            }
          }
        }
      )
    ]
  )
}
