export default function(h, { path, serviceName }) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        type: 'number',
        step: 'any',
        suffix: this.getTranslate('misc.measure.time.sec'),
        mode: 'outline-label',
        dense: true,
        rounded: true,
        details: false,
        clearable: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
