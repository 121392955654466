import { balance } from '@/utils'

import render from './render'

export default {
  name: 'Balance',

  mixins: [ balance() ],

  render
}
