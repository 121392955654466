import { buttonModes, colors } from '@/utils'

import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'

function renderConfirmDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showConfirmDialog,
        rounded: true,
        width: 400
      },
      on: {
        input: value => {
          this.showConfirmDialog = value
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: {
            value: this.getTranslate('inactiveProviders.titles.confirm.cloneProvider')
          },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate('inactiveProviders.contents.confirm.cloneProvider')
      ),

      h(
        'div',
        {
          class: 'fjcfe faic grid-gap--8 pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                mode: buttonModes.clear
              },
              on: {
                click: () => {
                  this.showConfirmDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.clone'),
                color: colors.primary,
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.cloneProvider()
                  this.showConfirmDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    button,
    {
      props: {
        icon: 'copy_all',
        mode: buttonModes.flat,
        color: colors.secondary,
        loading: this.loading,
        disabled: this.loading,
        tooltip: this.getTranslate('inactiveProviders.tooltips.cloneProvider')
      },
      scopedSlots: {
        dialog: () => renderConfirmDialog.call(this, h)
      },
      key: this.provider.id,
      on: {
        click: () => {
          this.showConfirmDialog = true
        }
      }
    }
  )
}
