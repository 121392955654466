import 'core-js/stable'
import 'regenerator-runtime/runtime'

import Vue from 'vue'
import VueMeta from 'vue-meta'

import app from '@/app'
import store from '@/store'
import mixins from '@/mixins'

import { EventEmitter2 } from 'eventemitter2'

import { locales, projectName } from '@/utils'
import { router } from '@/plugins/router'
import { Components } from '@/components/g'

import 'prismjs/themes/prism-okaidia.css'

import '@/assets/main.scss'

import '@/registerServiceWorker'

import '@/plugins/numeral'
import '@/plugins/gRequest'

import GTooltip from '@/plugins/gTooltip'

const instructionElement = document.querySelector('.instruction')
if (instructionElement && typeof instructionElement.remove === 'function') {
  instructionElement.remove()
}

Vue.use(VueMeta)
Vue.use(GTooltip)

for (const name in Components) {
  Vue.component(name, Components[name])
}

Vue.mixin(mixins)

Vue.$bus = new EventEmitter2({
  wildcard: true,
  newListener: false,
  maxListeners: 20,
  verboseMemoryLeak: true
})

const initToken = window.localStorage.getItem(`${projectName}:token`)
store.dispatch('authentication/setToken', initToken, { root: true })

window.onstorage = event => {
  if (document.hidden) {
    switch (event.key) {
      case 'token':
      case 'prevToken': {
        window.location.reload()
        break
      }
    }
  }
}

store.dispatch('viewport/init', null, { root: true })
store.dispatch('global/setPlatform', null, { root: true })
store.dispatch('global/setBrowser', null, { root: true })

window.addEventListener('mouseup', () => store.dispatch('authentication/setTimer', null, { root: true }))
window.addEventListener('keyup', () => store.dispatch('authentication/setTimer', null, { root: true }))

let locale = window.location.href.replace(/.*?#.*?\?.*?locale=([A-z]+)(&|$)/, '$1')
locale = locale.replace(/.*?\/\/(.*?)\..*/, '$1')

if (!~locales.indexOf(locale)) {
  locale = window.localStorage.getItem('locale')

  if (!~locales.indexOf(locale)) {
    const domains = window.location.hostname.split('.')
    const topLevelDomain = domains[domains.length - 1]
    switch (topLevelDomain) {
      case 'com': {
        locale = 'en'
        break
      }
      case 'ru':
      default: {
        locale = 'ru'
        break
      }
    }
  }

  if (!~locales.indexOf(locale)) {
    locale = 'ru'
  }
}

store.dispatch('locale/setLocale', locale)

store.dispatch('authentication/setAccountFromToken', null, { root: true }).then(() => {
  new Vue({
    router,
    store,
    render: h => h(app)
  }).$mount('#app')
})
