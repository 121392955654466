import render from './render'

export default {
  name: 'eventsHistoryButton',

  props: {
    entityId: {
      type: String,
      default: ''
    },

    label: {
      type: Boolean,
      default: false
    },

    flat: {
      type: Boolean,
      default: true
    },

    rounded: Boolean,
    outline: Boolean,

    disabled: Boolean
  },

  data() {
    return {
      showDialog: false
    }
  },

  render
}
