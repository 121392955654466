import billingsDetails from '@/components/services/billings/details'
import billingsGroup001 from '@/components/services/billings/group001'

import { cloneDeep, isEmpty } from 'lodash'

import { BILLINGS_FILTER, currentTab, generateServices, globalErrorHandler, globalErrorProcessor, sudoData } from '@/utils'
import { serviceName } from '@/components/services/billings/utils'

import render from './render'

export default {
  name: 'Template',

  components: {
    [`${serviceName}-details`]: billingsDetails,
    [`${serviceName}-group001`]: billingsGroup001
  },

  mixins: [
    generateServices({
      name: serviceName,

      inputFilter(result) {
        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },
      outputFilter(result) {
        if (result.source !== 'accrual') {
          result.amount = result.amount * -1
        }

        result.amount = parseFloat(result.amount)

        return result
      },
      find: {
        defaultFilter: {
          id: undefined,
          createdAt: undefined,
          $scope: [ 'full' ]
        }
      },
      get: false,
      remove: false,
      update: false
    }),

    currentTab(serviceName),

    sudoData
  ],

  data() {
    return {
      showCreateBilling: false,

      filter: cloneDeep(BILLINGS_FILTER),

      preset: `${serviceName}.details`
    }
  },

  computed: {
    presetNameKebab() {
      return this.preset.replace('.', '-')
    },

    createDataIsValid() {
      return this.restData[serviceName].create.state !== 'loading' && this.restData[serviceName].create.isValid
    },

    tabs() {
      const result = [ serviceName ]
      if (this.checkPermissions('advanced.tasks.find', [ 'reseller', true ])) {
        result.push('tasks')
      }

      return result
    }
  },

  watch: {
    preset() {
      this.filter = cloneDeep(BILLINGS_FILTER)
    }
  },

  mounted() {
    if (!isEmpty(this.$route.params)) {
      this.filter.id = this.$route.params.id
      this.filter.createdAt = this.$route.params.createdAt
    }
  },

  methods: {
    async billingsCreate() {
      if (this.createDataIsValid) {
        try {
          const result = await this.rest[serviceName].create(this.restData[serviceName].create.data)
          if (result) {
            this.showCreateBilling = false
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        }
      }
    }
  },

  render
}
