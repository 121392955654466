import { cloneDeep } from 'lodash'

import { SENDINGS_INCOMING_FILTER, downloadable, generateServices } from '@/utils'
import { inputFilter, serviceName } from '@/components/services/sendings/incoming/utils'

import render from './render'

export default {
  name: 'Details',

  mixins: [
    generateServices({
      name: serviceName,

      inputFilter,

      find: {
        bucketEnabled: true,
        defaultFilter: Object.assign({}, cloneDeep(SENDINGS_INCOMING_FILTER), { $scope: [ 'full' ] }),
        disableWatcherFilter: true
      },
      get: false,
      update: false,
      create: false,
      remove: false
    }),

    downloadable({ permissionAccept: `advanced.${serviceName}.find` })
  ],

  data() {
    return {
      sourceData: undefined,
      showSourceDataDialog: false
    }
  },

  watch: {
    [`restData.${serviceName}.find.filter`]: {
      handler() {
        if (this.restData[serviceName].find.pagination.offset !== 0) {
          this.restData[serviceName].find.pagination.offset = 0
        }
      },
      deep: true
    }
  },

  methods: {
    sourceDataProcess(data) {
      this.sourceData = data
      this.showSourceDataDialog = true
    }
  },

  render
}
