export default {
  title: 'YClients',

  titles: {
    create: 'Create salon'
  },

  labels: {
    salon: 'Salon ID',
    token: 'Token',
    isActive: 'Is active',
    owner: 'Owner',
    error: 'Error',
    errorIsEmpty: 'No errors'
  },

  tooltips: {
    active: 'Active',
    notActive: 'Not active',
    filter: {
      isActive: 'Show deactivated entities'
    },
    updateStatus: 'Update status'
  },

  snackbars: {
    created: 'Salon created',
    updated: 'Salon updated',
    removed: 'Salon removed'
  }
}
