import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep } from 'lodash'

import render from './render'

const defaultValue = {
  rules: [],
  replacement: {
    payload: {
      sender: undefined,
      text: undefined
    }
  }
}

export default {
  name: 'Replacements',

  mixins: [ proxy({ type: 'array' }) ],

  data() {
    return {
      disabled: true,
      editMode: false,
      showDialog: false,

      index: undefined,

      payload: cloneDeep(defaultValue)
    }
  },

  methods: {
    add() {
      this.proxy.push(this.payload)
      this.cancel()
    },
    edit(index) {
      this.editMode = true
      this.index = index
      this.payload = this.proxy[index]
      this.showDialog = true
    },
    remove(index) {
      this.proxy.splice(index, 1)
    },
    update() {
      this.proxy[this.index] = this.payload
      this.cancel()
    },
    cancel() {
      this.index = undefined
      this.editMode = false
      this.payload = cloneDeep(defaultValue)
      this.showDialog = false
    }
  },

  render
}
