import { colors } from '../colors'
import { icons } from '../icons'

export const permissionAccepts = {
  false: false,
  me: 'me',
  reseller: 'reseller',
  manager: 'manager',
  true: true
}

export const permissionColors = {
  [permissionAccepts.false]: colors.error,
  [permissionAccepts.me]: colors.warning,
  [permissionAccepts.reseller]: colors.primary,
  [permissionAccepts.manager]: colors.epic,
  [permissionAccepts.true]: colors.success
}
export const permissionIcons = {
  [permissionAccepts.false]: icons.cancel,
  [permissionAccepts.me]: icons.person,
  [permissionAccepts.manager]: icons.person_add_alt_1,
  [permissionAccepts.reseller]: icons.groups,
  [permissionAccepts.true]: icons.verified
}
