import { moderationColors, moderationStatusIcons, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.sendernames}.labels.name`),
        icon: moderationStatusIcons[this.value.moderation],
        color: moderationColors[this.value.moderation],
        iconTooltip: this.getTranslate(`${services.sendernames}.statuses.${this.value.moderation}`),
        service: services.sendernames,
        pathToTitle: 'name',
        copy: true
      }
    }
  )
}
