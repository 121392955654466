import Actions from '@sigma-legacy-libs/essentials/lib/utils/Actions'

export const serviceName = 'messageRules'

export const outputFilter = result => {
  result.actions = Actions.filterActionsObject(result.actions)

  return result
}

