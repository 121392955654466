import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'Clock',

  computed: {
    ...mapGetters({ date: 'global/date' }),

    timezone() {
      const zone = this.date.getTimezoneOffset() / -60

      return `GMT${zone >= 0 ? '+' : '-'}${zone}`
    }
  },

  mounted() {
    this.startTimeUpdater()
  },

  methods: {
    ...mapActions({ startTimeUpdater: 'global/startTimeUpdater' })
  },

  render
}
