const _ = {
  differenceBy: require('lodash/differenceBy'),
  defaultsDeep: require('lodash/defaultsDeep')
}

export const attach = (result, path) => {
  const attach = result[path]
  const current = result[`_${path}`]
  const detach = _.differenceBy(current, attach, 'id')
  const output = {}

  for (const item of attach) {
    output[item.id] = _.defaultsDeep({ $attach: true }, item)
  }
  for (const item of detach) {
    output[item.id] = _.defaultsDeep({ $attach: false }, item)
  }

  result[path] = Object.values(output).filter(item => item.id)

  delete result[`_${path}`]
}

export default { attach }
