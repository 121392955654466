export default {
  title: 'Health',

  nodes: 'Nodes',
  providers: 'Providers',
  cpu: 'CPU',
  cores: 'Cores',
  memory: 'Memory',
  busy: 'Busy',
  requests: 'Requests',
  totalRequests: 'Total requests',
  rps: 'RPS',
  actions: 'Actions',
  uptime: 'Uptime',
  client: 'Client'
}
