import { DOCUMENT_TEMPLATES_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

export const DEFAULT_DOCUMENT_TEMPLATES_INVOICE_PAYLOAD = {
  requisiteId: undefined,
  companyId: undefined,
  type: DOCUMENT_TEMPLATES_TYPES.invoice,
  data: {
    SERVICE_PRICE: 1000
  }
}
export const DEFAULT_INVOICE_PAYLOAD = {
  amount: 1000,
  requisiteId: undefined,
  companyId: undefined,
  customerId: undefined,
  type: DOCUMENT_TEMPLATES_TYPES.invoice
}
export const DEFAULT_PAYMENT_PAYLOAD = {
  method: undefined,
  amount: 3000,
  acceptOffer: true,
  OwnerId: undefined
}
