import Vue from 'vue'

import { mapActions } from 'vuex'

import render from './render'

export default {
  name: 'Online',

  data() {
    return {
      fullscreen: false,
      showDialog: false,
      validation: false,
      pause: false,

      limit: 100, // слишком большое количество строк приводит к фризам на фронте

      message: {},
      messages: []
    }
  },

  mounted() {
    this.validation = !!JSON.parse(window.localStorage.getItem('sigma:online:sendings.created'))
    this.setSockets()
  },

  methods: {
    ...mapActions({
      setFindSendingFromRouteParams: 'global/setFindSendingFromRouteParams'
    }),

    setSockets() {
      if (!this.validation) {
        return
      }

      Vue.$socket.on('sendings.created', data => {
        if (!this.pause) {
          const message = {
            id: data.id,
            recipient: data.payload.recipient,
            type: data.type
          }
          this.message = message
          this.messages.unshift({
            createdAt: data.createdAt,
            ...message,
            state: data.state.status
          })
          if (this.messages.length > this.limit) {
            this.messages.pop()
          }
        }
      })
      Vue.$socket.on('sendings.updated', data => {
        if (!this.pause) {
          const index = this.messages.findIndex(item => item.id === data.id)
          if (index !== -1) {
            const message = {
              id: data.id,
              recipient: data.payload.recipient,
              type: data.type
            }
            this.messages.splice(index, 1, {
              createdAt: data.createdAt,
              ...message,
              state: data.state.status
            })
          }
        }
      })
    },

    clearMessages() {
      this.messages = []
    },

    goToSendings(message, trigger) {
      if (trigger) {
        const $lt = new Date(message.createdAt)
        $lt.setSeconds($lt.getSeconds() + 1)
        this.$router.push({
          name: 'sendings',
          params: {
            id: message.id,
            createdAt: {
              $lt,
              $gt: message.createdAt
            }
          }
        })
        this.showDialog = false
        this.setFindSendingFromRouteParams(true)
      }
    }
  },

  render
}
