import { sendingTypeColors } from '@/utils'

import messageTypeIcon from '@/components/message/type/icon'

export default function(h) {
  return h(
    'g-chip',
    {
      props: {
        [this.size]: true,
        rounded: this.rounded,
        color: sendingTypeColors[this.value]
      }
    },
    [
      h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { 'grid-template-columns': '14px 1fr' }
        },
        [
          h(
            messageTypeIcon, {
              props: {
                size: 14,
                color: 'white',
                value: this.value
              }
            }
          ),

          this.getTranslate(`sendings.types.${this.value}`)
        ]
      )
    ]
  )
}
