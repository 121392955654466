import render from './render'

export default {
  name: 'TOC',

  props: {
    headings: {
      type: Array,
      default: () => []
    },

    scrollable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    toc() {
      const output = []
      const levels = [ output ]
      for (const heading of this.headings) {
        const level = heading.level - 1
        const item = {
          title: heading.title,
          slug: heading.slug,
          level,
          childs: []
        }
        levels[level - 1].push(item)
        levels.splice(level, levels.length, item.childs)
      }

      return output
    }
  },

  render
}
