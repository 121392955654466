import { generateServices, services, states } from '@/utils'

import render from './render'

export default function(ListId, keyword) {
  return {
    name: 'List',

    mixins: [
      generateServices([
        {
          name: services.keywords,

          find: {
            defaultFilter: {
              $search: keyword,
              ListId
            },
            defaultPagination: { limit: 300 },
            appendMode: true
          },

          remove: {
            redirect: false
          }
        }
      ])
    ],

    data() {
      return {
        showDialog: false,
        dialogType: 'create',

        keywordPayload: {
          id: undefined,
          regexp: undefined,
          ListId
        },

        keyword
      }
    },

    computed: {
      loading() {
        return this.restData[services.keywords].update.state === states.loading
          || this.restData[services.keywords].create.state === states.loading
          || this.restData[services.keywords].remove.state === states.loading
      }
    },

    mounted() {
      this.rest[services.keywords].find({ query: { ListId } })
    },

    methods: {
      clearPayload() {
        this.keywordPayload.id = undefined
        this.keywordPayload.regexp = undefined
        this.keywordPayload.ListId = undefined
      },

      setKeyword() {
        switch (this.dialogType) {
          case 'update':
          case 'create': {
            this.rest[services.keywords][this.dialogType](this.keywordPayload)
            break
          }
          case 'remove': {
            this.rest[services.keywords][this.dialogType](this.keywordPayload.id)
            break
          }
        }

        this.clearPayload()
      }
    },

    render
  }
}
