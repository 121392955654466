import { isEqual } from 'lodash'

import { proxy } from '@/utils'

import render from './render'

export default {
  name: 'NotificationsEmailSMTP',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    loading: Boolean
  },

  data() {
    return {
      passwordVisibility: true
    }
  },

  methods: {
    transmitValue() {
      return
    },

    clickHandler() {
      if (!isEqual(this.proxy, this.value)) {
        this.$emit('input', this.proxy)
      }
    }
  },

  render
}
