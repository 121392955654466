import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: this.$props
    }
  )
}
