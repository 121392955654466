import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Fallback',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    'proxy.type'() {
      switch (this.proxy.type) {
        case 'viber': {
          this.proxy.payload.buttons = undefined
          this.proxy.payload.button = {
            url: undefined,
            text: undefined
          }
          break
        }
        case 'whatsapp': {
          this.proxy.payload.button = undefined
          this.proxy.payload.buttons = []
          break
        }

        default: {
          this.proxy.payload.button = undefined
          this.proxy.payload.buttons = undefined
          break
        }
      }
    }
  },

  render
}
