export default {
  title: 'Настройки пользователя',

  titles: {
    sendings: 'Сообщения',
    billings: 'Финансовые операции',
    referral: 'Реферральная программа',
    websocket: 'Websocket',
    smpp: 'SMPP',
    webhooks: 'Webhooks',
    notifications: 'Уведомления',
    statuses: 'Мэппинг статусов',
    integrations: 'Интеграции'
  },

  subtitles: {
    sendings: {
      duplicates: 'Дубликаты',
      rateLimit: 'Ограничение количества сообщений'
    },

    billings: {
      autoAccrual: 'Автопополнение'
    },

    referral: {
      billings: {
        processedChildOperations: 'Финансовые операции'
      }
    },

    webhooks: {
      general: 'Основное',
      successOn: 'Условия успешной обработки'
    },

    integrations: {
      pyrus: {
        credentials: 'Данные для подключения к Pyrus'
      },
      yclients: {
        applicationCredentials: 'Данные для подключения к YClients'
      }
    },

    notifications: {
      events: {
        balance: {
          limit: 'Порог баланса'
        }
      }
    }
  },

  dialogs: {
    titles: {
      webhooks: {
        onStatusChange: 'При изменении статуса',
        onIncomingMessage: 'Входящие'
      }
    }
  },

  labels: {
    sendings: {
      allowDuplicates: 'Разрешить дубликаты сообщений',
      rateLimit: {
        maxAmount: 'Количество сообщений',
        timeout: 'Временной промежуток'
      },
      duplicates: {
        hashFields: 'Поля для проверки на дубли',
        timeout: 'Время блокировки дублей'
      },
      tilda: {
        sendFromForms: 'Разрешить отправку сообщений из форм Tilda'
      }
    },

    billings: {
      autoAccrual: {
        limit: 'Порог',
        requiredBalance: 'Необходимый баланс'
      },
      overdraft: 'Перерасход',
      processChildrenOperations: 'Обрабатывать финансовые операции дочерних пользователей как свои',
      billOnErrors: 'Тарифицировать СМС в случае ошибки',
      currency: 'Валюта'
    },

    referral: {
      billings: {
        processedChildOperations: {
          source: 'Источник',
          percent: 'Процент',
          limitMin: 'Минимальный лимит',
          limitMax: 'Максимальный лимит'
        }
      }
    },

    smpp: {
      enabled: 'SMPP включён',
      rudeMode: 'Грубый режим SMPP',
      defaultMessageType: 'Тип сообщения по умолчанию для SMPP',
      ipWhitelist: 'Белый список',
      ipAddresses: 'IP адреса',
      confirm: 'Подтверждение сохранения'
    },

    webhooks: {
      title: 'Отправления',
      url: 'URL запроса',
      method: 'Метод запроса',
      headers: 'Заголовки запроса',
      body: 'Тело запроса',
      maxConnections: 'Максимальное количество соединений',
      successOn: {
        status: 'Проверка статуса (regexp)',
        body: 'Проверка тела ответа (regexp)'
      }
    },

    notifications: {
      extraRecipients: 'Получатели уведомлений',
      type: 'Способ уведомления',
      events: {
        balance: {
          limit: {
            host: 'Хост',
            port: 'Порт',
            from: 'От',
            login: 'Имя пользователя',
            password: 'Пароль',
            limit: 'Порог',

            include: {
              overdraft: 'Учтём овердрафт',
              invoice: 'Приложим счёт'
            },

            notInclude: {
              overdraft: 'Не учтём овердрафт',
              invoice: 'Не приложим счёт'
            },

            invoice: {
              amount: 'Сумма счёта'
            }
          }
        },
        providers: {
          health: {
            recipients: 'Получатели уведомлений о состоянии провайдеров'
          }
        }
      }
    },

    websocket: {
      channels: 'Каналы'
    },

    integrations: {
      pyrus: {
        credentials: {
          login: 'Имя пользователя',
          security_key: 'Ключ безопасности'
        }
      },
      yclients: {
        applicationCredentials: {
          id: 'ID приложения',
          token: 'Токен приложения'
        }
      }
    }
  },

  contents: {
    webhooks: {
      onStatusChange: 'При изменении статуса',
      onIncomingMessage: 'Входящие',
      statuses: 'Статусы'
    }
  },

  hints: {
    webhooks: {
      onStatusChange: 'Настройки вебхука на событие изменения статуса сообщения',
      onIncomingMessage: 'Настройки вебхука на событие входящего сообщения'
    },

    referral: {
      billings: {
        processedChildOperations: {
          limits: 'Лимиты - минимальная и максимальная сумма гарантированного вознаграждения при каждой реферальной операции.'
        }
      }
    },

    notifications: {
      events: {
        balance: {
          limit: {
            title: 'Уведомим при балансе {amount}',
            onLimit: 'При балансе',
            noLimit: '<не указан порог>',
            notifyBy: 'уведомлять по',
            noNotifications: '<не уведомлять>',
            overdraft: ' + овердрафт пользователя',
            extraRecipients: 'Уведомления о состоянии баланса всех пользователей системы',
            invoice: 'Приложить счёт на сумму {amount}'
          }
        }
      }
    },

    smpp: {
      ipAddresses: 'Только валидные IP адреса, разделитель "запятая", "перенос строки".'
    },

    warning: 'Изменение настроек не требует обновления пользователя.'
  },

  warnings: {
    smpp: {
      ipAddresses: 'Некоторые из указанных IP адресов не прошли проверку на корректность.<br>Если нужно сохранить эти адреса, убедитесь, что они правильные, затем снова введите их в поле "IP адреса" и нажмите "Сохранить".<br>Если адреса по-прежнему окажутся некорректными, это окно не закроется, и список ниже обновится с новыми некорректными адресами.'
    }
  },

  items: {
    websocket: {
      channels: {
        dbUpdates: 'Обновление базы',
        everything: 'Все',
        health: 'Здоровье платформы',
        sendingStatusUpdates: 'Обноление статуса отправлений',
        providersStatesUpdates: 'Обновление состояний провайдеров'
      }
    }
  },

  snackbars: {
    updated: 'Настройка успешно обновлена.'
  },

  buttons: {
    notifications: {
      events: {
        balance: {
          limit: {
            invoice: {
              attach: 'Приложить счёт',
              detach: 'Открепить счёт'
            }
          }
        }
      }
    }
  }
}
