import { generateUniqueKey } from '@/utils'

import button from '@/components/button'
import squircle from '@/components/icon/squircle'

const className = 'sticker'

function renderIcon(h) {
  if (this.icon || this.iconSVG) {
    return h(
      squircle,
      {
        props: {
          color: this.color,
          icon: this.icon,
          iconSVG: this.iconSVG,
          iconSize: this.iconSize,
          loading: this.loading,
          tooltip: this.iconTooltip
        },
        key: generateUniqueKey()
      }
    )
  }

  if (this.$scopedSlots.icon) {
    return this.$scopedSlots.icon()
  }

  return this.$slots.icon
}

function renderTitle(h) {
  return h(
    'div',
    {
      ref: 'title',
      class: `${className}__title`
    },
    [ this.title ]
  )
}
function renderLabel(h) {
  return h(
    'div',
    {
      class: `${className}__label`
    },
    [ this.label ]
  )
}
function renderContent(h) {
  if (this.hasContent) {
    return h(
      'div',
      {
        class: `${className}__content`,
        directives: this.$directives
      },
      [
        renderTitle.call(this, h),
        renderLabel.call(this, h)
      ]
    )
  }

  return h('div')
}

function renderButton(h) {
  if (this.hasButton) {
    return h(
      button,
      {
        props: {
          icon: this.$button.icon,
          iconSVG: this.$button.iconSVG,
          size: this.$button.size,
          mode: this.$button.mode,
          color: this.$button.color,
          tooltip: this.$button.tooltip,
          loading: this.$button.loading,
          disabled: this.$button.disabled
        },
        on: {
          click: this.buttonClickHandler
        }
      }
    )
  }
}

function renderHolder(h) {
  return h(
    this.hasTo ? 'router-link' : 'div',
    {
      class: `${className}__holder`,
      style: { gridTemplateColumns: this.gridTemplateColumns },
      props: { to: this.$to },
      on: { click: this.clickHandler }
    },
    [
      renderIcon.call(this, h),
      renderContent.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      attrs: { target: this.target },
      class: this.$class,
      style: this.$style,
      ref: className
    },
    [
      renderHolder.call(this, h),
      renderButton.call(this, h)
    ]
  )
}
