import Vue from 'vue'

import tariffsConditions from '@/components/services/tariffs/conditions'
import priceTextField from '@/components/services/tariffs/priceTextField'

import { serviceName } from '@/components/services/tariffs/utils'

function renderHandle(h) {
  return h(
    'div',
    {
      class: 'fjcc faic square--28'
    },
    [
      h(
        'g-icon',
        {
          attrs: { id: 'drag_handle' },
          class: 'drag_handle',
          props: {
            value: 'drag_handle',
            color: 'grey'
          }
        }
      )
    ]
  )
}

function renderRemoveButton(h) {
  return h(
    'g-button',
    {
      class: 'ma-0',
      props: {
        icon: this.removeIcon ? 'delete' : this.proxy.action === 'decrease' ? 'keyboard_arrow_down' : 'keyboard_arrow_up',
        flat: true,
        small: true,
        color: this.proxy.action === 'decrease' || this.removeIcon ? 'error' : 'success'
      },
      on: {
        click: () => {
          Vue.delete(this.target, this.index)
        },
        mouseenter: () => {
          this.removeIcon = true
        },
        mouseleave: () => {
          this.removeIcon = false
        }
      }
    }
  )
}

function renderActionMenu(h) {
  return h(
    'g-menu',
    {
      props: { rounded: true }
    },
    [
      h(
        'div',
        {
          class: `${serviceName}-rules__group-action link`,
          slot: 'activator'
        },
        this.getTranslate(`${serviceName}.actions.${this.proxy.action}`)
      ),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [ 'decrease', 'increase' ].map(action => {
          return h(
            'g-list-item',
            {
              props: {
                color: action === 'decrease' ? 'error' : 'success',
                label: this.getTranslate(`${serviceName}.actions.${action}`),
                dense: true
              },
              on: {
                click: () => {
                  this.proxy.action = action
                }
              }
            }
          )
        })
      )
    ]
  )
}

function renderPriceField(h) {
  return h(
    priceTextField,
    {
      props: {
        value: this.proxy.price,
        source: this.source
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__group-header`
    },
    [
      h(
        'div',
        {
          class: `${serviceName}-rules__group-actions`
        },
        [
          renderHandle.call(this, h),
          renderRemoveButton.call(this, h),
          renderActionMenu.call(this, h)
        ]
      ),

      renderPriceField.call(this, h)
    ]
  )
}

function renderOr(h, index) {
  if (index > 0) {
    return h(
      'div',
      {
        class: `${serviceName}-rules__group-or`
      },
      [
        h(
          'div',
          {
            class: `${serviceName}-rules__group-or-text`
          },
          this.getTranslate('misc.or')
        ),

        h(
          'div',
          {
            class: `${serviceName}-rules__group-or-remove`,
            on: {
              click: () => {
                this.proxy.rules.splice(index, 1)
              }
            }
          },
          this.getTranslate('misc.buttons.remove')
        )
      ]
    )
  }
}

function renderGroups(h) {
  if (this.hasRules) {
    return h(
      'div',
      {
        class: `${serviceName}-rules__group-set`
      },
      this.proxy.rules.map((rule, index) => {
        return h(
          'div',
          {
            class: `${serviceName}-rules__group-set-item`,
            key: `rule-${index}`
          },
          [
            renderOr.call(this, h, index),

            h(
              tariffsConditions,
              {
                props: {
                  value: rule.conditions,
                  type: this.source
                },
                on: {
                  input: event => {
                    rule.conditions = event
                  }
                }
              }
            )
          ]
        )
      })
    )
  }
}

function renderAddCondition(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__group-add link`,
      on: {
        click: () => {
          this.addConditions()
        }
      }
    },
    this.getTranslate(`${serviceName}.${this.proxy.rules.length > 1 ? 'addAnotherSetConditions' : 'addSetConditions'}`)
  )
}

function renderBody(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__group-body`
    },
    [
      renderGroups.call(this, h),
      renderAddCondition.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-rules__group`
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h)
    ]
  )
}
