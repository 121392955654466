import { components, eventHandlers, numberToPxOrString } from '@/utils'

import render from './render'

export default {
  name: components.card,

  mixins: [ eventHandlers ],

  props: {
    color: String,
    title: String,

    flat: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    dashed: {
      type: Boolean,
      default: false
    },
    hover: {
      type: Boolean,
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },

    accent: {
      type: Boolean,
      default: false
    },
    accentColor: {
      type: String,
      default: '#ffffff'
    },
    accentColorName: String,
    accentSize: {
      type: [ String, Number ],
      default: '8px'
    },
    accentPosition: {
      type: String,
      default: 'left',
      validator: value => {
        return ~[ 'left', 'right', 'top', 'bottom' ].indexOf(value)
      }
    },

    minHeight: [ String, Number ],
    maxHeight: [ String, Number ],
    height: [ String, Number ],
    minWidth: [ String, Number ],
    maxWidth: [ String, Number ],
    width: [ String, Number ],

    overflow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    $class() {
      return {
        [components.card]: true,

        [`${components.card}--flat`]: this.flat,
        [`${components.card}--outline`]: this.outline,
        [`${components.card}--dashed`]: this.dashed,
        [`${components.card}--hover`]: this.hover,
        [`${components.card}--rounded`]: this.rounded,
        [`${components.card}--transparent`]: this.transparent,
        [`${components.card}--overflow`]: this.overflow,

        [`${components.card}--accent-${this.accentColorName}`]: !!this.accentColorName
      }
    },
    $style() {
      const result = {
        'background-color': this.color ? this.color : null,
        'min-height': numberToPxOrString(this.minHeight),
        'max-height': numberToPxOrString(this.maxHeight),
        height: numberToPxOrString(this.height),
        'min-width': numberToPxOrString(this.minWidth),
        'max-width': numberToPxOrString(this.maxWidth),
        width: numberToPxOrString(this.width)
      }

      if (this.accent) {
        result[`border-${this.accentPosition}-style`] = 'solid'
        result[`border-${this.accentPosition}-width`] = numberToPxOrString(this.accentSize)
        if (!this.accentColorName) {
          result[`border-${this.accentPosition}-color`] = this.accentColor
        }
      }

      return result
    }
  },

  render
}
