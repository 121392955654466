import { get } from 'lodash'

import { services } from '@/utils'

import sticker from '@/components/misc/sticker'
import tasksType from '@/components/services/sendings/tasks/type'
import tasksCounts from '@/components/services/sendings/tasks/counts'
import tasksState from '@/components/services/tasks/state'
import tasksTitle from '@/components/services/tasks/title'
import tasksActionsUpdate from '@/components/services/tasks/actions/update'
import tasksActionsMenu from '@/components/services/tasks/actions/menu'
import tasksBatchParams from '@/components/services/tasks/batchParams'
import owner from '@/components/serviceTemplate/item/owner'
import date from '@/components/serviceTemplate/item/date'

function renderTasksType(h) {
  return h(
    tasksType,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderTasksState(h) {
  return h(
    tasksState,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderTasksCounts(h, trigger) {
  if (trigger) {
    return h(
      tasksCounts,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderTasksTitle(h, trigger) {
  if (this.viewport.breakpoint.xl || trigger) {
    return h(
      tasksTitle,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderSender(h) {
  return h(
    sticker,
    {
      props: {
        value: get(this.value, 'data.sending.payload.sender'),
        label: this.getTranslate(`${services.sendings}.labels.sender`),
        copy: true,
        borderStyle: 'dashed'
      }
    }
  )
}
function renderText(h) {
  return h(
    sticker,
    {
      props: {
        value: get(this.value, 'data.sending.payload.text'),
        label: this.getTranslate(`${services.sendings}.labels.text`),
        copy: true,
        borderStyle: 'dashed',
        tooltipTimeout: 5 * 1000
      }
    }
  )
}
function renderOwner(h, trigger) {
  if (this.viewport.size.width > 1560 || trigger) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderTasksBatchParams(h) {
  return h(
    tasksBatchParams,
    {
      props: {
        value: this.value
      },
      on: {
        menu: event => {
          this.menuIsShowed = event
        }
      }
    }
  )
}
function renderTasksActionsUpdate(h) {
  return h(
    tasksActionsUpdate,
    {
      props: {
        value: this.value
      }
    }
  )
}
function renderTasksActionsMenu(h) {
  return h(
    tasksActionsMenu,
    {
      props: {
        value: this.value
      },
      on: {
        menu: event => {
          this.menuIsShowed = event
        }
      }
    }
  )
}
function renderActions(h, trigger) {
  if (this.viewport.size.width > 1380 || trigger) {
    return h(
      'div',
      {
        class: `${services.sendings}-tasks__actions hover-child`
      },
      [
        renderTasksBatchParams.call(this, h),
        renderTasksActionsUpdate.call(this, h),
        renderTasksActionsMenu.call(this, h)
      ]
    )
  }
}
function renderDates(h, trigger) {
  if (this.viewport.size.width > 1640 || trigger) {
    return h(
      'div',
      {
        class: 'faic grid-gap--8'
      },
      [ 'createdAt', 'updatedAt' ].map(pathToDate => {
        return h(
          date,
          {
            props: {
              value: this.value,
              pathToDate
            }
          }
        )
      })
    )
  }
}

function renderPanelHeaderContent(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: this.$style
    },
    [
      renderTasksType.call(this, h),
      renderTasksState.call(this, h),
      renderTasksCounts.call(this, h, this.viewport.size.width >= 600)
    ]
  )
}
function renderPanelHeader(h) {
  return h(
    'div',
    {
      class: `${services.sendings}-tasks__panel__header`
    },
    [
      renderPanelHeaderContent.call(this, h),
      renderDates.call(this, h)
    ]
  )
}
function renderPanelContent(h) {
  return h(
    'div',
    {
      class: `${services.sendings}-tasks__panel__content`
    },
    [
      renderTasksCounts.call(this, h, this.viewport.size.width < 600),
      renderTasksTitle.call(this, h, true),
      renderSender.call(this, h),
      renderText.call(this, h),
      renderOwner.call(this, h, true),
      renderDates.call(this, h, true),
      renderActions.call(this, h, true)
    ]
  )
}

export default function(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      'div',
      {
        class: this.$class,
        style: this.$style,
        key: this.value.id
      },
      [
        renderTasksType.call(this, h),
        renderTasksState.call(this, h),
        renderTasksCounts.call(this, h, this.viewport.size.width >= 600),
        renderTasksTitle.call(this, h),
        renderSender.call(this, h),
        renderText.call(this, h),
        renderOwner.call(this, h),
        renderActions.call(this, h),
        renderDates.call(this, h)
      ]
    )
  } else {
    return h(
      'g-expansion-panel',
      {
        scopedSlots: {
          header: () => renderPanelHeader.call(this, h),
          default: () => renderPanelContent.call(this, h)
        },
        key: this.value.id
      }
    )
  }
}
