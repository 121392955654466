import create from '@/components/generator/search/create'
import titleDivider from '@/components/title/divider'

import { states } from '@/utils'

function renderSearchField(h, options) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.restData[options.name].find.filter.$search,
        label: this.getTranslate('commons.labels.search'),
        afterIcon: 'search',
        name: 'search',
        suffix: this.restData[options.name].find.pagination.total,
        loading: this.restData[options.name].find.state === states.loading,
        clearable: true,
        mode: 'outline',
        rounded: true,
        dense: true,
        details: false
      },
      on: {
        input: event => {
          this.restData[options.name].find.filter.$search = event
        }
      }
    }
  )
}

function renderCreateSlot(h, options) {
  if (typeof this.$scopedSlots.create === 'function') {
    return this.$scopedSlots.create()
  } else if (this.$slots.create) {
    return this.$slots.create
  } else {
    return h(
      create(options),
      {
        props: { maxWidth: this.maxWidth },
        scopedSlots: { titleCreate: this.$scopedSlots.titleCreate }
      }
    )
  }
}

function renderActionTitle(h, path) {
  return h(
    'div',
    {
      class: 'search-generator__action-title'
    },
    [ this.getTranslate(path) ]
  )
}

function renderActionSwitch(h, value) {
  return h(
    'g-switch',
    {
      props: {
        value
      }
    }
  )
}

function renderIsActiveAction(h, options) {
  if (options.filterByIsActive) {
    return h(
      'div',
      {
        class: 'search-generator__action',
        on: {
          click: () => {
            this.setIsActive(!this.isActive)
          }
        }
      },
      [
        renderActionTitle.call(this, h, 'commons.filters.isActive.hide'),
        renderActionSwitch.call(this, h, this.isActive)
      ]
    )
  }
}

function renderShowLastVisitedAction(h) {
  return h(
    'div',
    {
      class: 'search-generator__action',
      on: {
        click: () => {
          this.toggleLastVisitedState()
        }
      }
    },
    [
      renderActionTitle.call(this, h, 'commons.filters.lastVisited.show'),
      renderActionSwitch.call(this, h, this.showLastVisited)
    ]
  )
}

function renderFilterAction(h) {
  if (this.$slots.filter || typeof this.$scopedSlots.filter === 'function') {
    return h(
      'div',
      {
        class: 'search-generator__action',
        on: {
          click: () => {
            this.showFilter = !this.showFilter
          }
        }
      },
      [
        renderActionTitle.call(this, h, 'commons.filters.filter.show'),
        renderActionSwitch.call(this, h, this.showFilter)
      ]
    )
  }
}

function renderReportSlot() {
  if (typeof this.$scopedSlots.report === 'function') {
    return this.$scopedSlots.report()
  } else {
    return this.$slots.report
  }
}

function renderActionMenu(h, options) {
  return h(
    'g-menu',
    {
      props: {
        placement: 'bottom-end',
        offsetDistance: -37,
        rounded: true
      }
    },
    [
      h(
        'g-button',
        {
          class: 'ma-0',
          props: {
            icon: 'more_vert',
            flat: true
          },
          slot: 'activator'
        }
      ),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [
          renderIsActiveAction.call(this, h, options),
          renderFilterAction.call(this, h, options),
          renderShowLastVisitedAction.call(this, h, options),
          renderCreateSlot.call(this, h, options),
          renderReportSlot.call(this, h, options)
        ]
      )
    ]
  )
}

function renderSearch(h, options) {
  return h(
    'div',
    {
      class: 'search-generator__search'
    },
    [
      renderSearchField.call(this, h, options),
      renderActionMenu.call(this, h, options)
    ]
  )
}

function renderFilterSlot(h, options) {
  if (typeof this.$scopedSlots.filter === 'function') {
    return this.$scopedSlots.filter({ filter: this.restData[options.name].find.filter })
  }

  return this.$slots.filter
}

function renderFilter(h, options) {
  if (this.showFilter) {
    return h(
      'div',
      {
        class: 'search-generator__filter'
      },
      [ renderFilterSlot.call(this, h, options) ]
    )
  }
}

function renderLastVisitedItems(h, options) {
  if (this.lastVisitedIsShowed) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8 facfs'
      },
      [
        h(
          titleDivider,
          {
            props: {
              value: this.getTranslate('commons.titles.lastVisited')
            }
          }
        ),

        h(
          'g-list',
          {
            props: {
              dense: options.dense,
              transparent: true
            }
          },
          this.lastVisitedIds.map(item => {
            if (typeof this.$scopedSlots.default === 'function') {
              return this.$scopedSlots.default({ item })
            }
          })
        ),

        h(
          titleDivider,
          {
            props: {
              value: this.getTranslate('commons.titles.all')
            }
          }
        )
      ]
    )
  }
}
function renderList(h, options) {
  return h(
    'g-list',
    {
      props: {
        dense: options.dense,
        transparent: true
      }
    },
    this.restData[options.name].find.data.map(item => {
      if (typeof this.$scopedSlots.default === 'function') {
        return this.$scopedSlots.default({ item })
      }
    })
  )
}
function renderItems(h, options) {
  if (this.restData[options.name].find.pagination.total > 0) {
    return renderList.call(this, h, options)
  } else {
    return h('g-empty')
  }
}

function renderLoadMoreButton(h, options) {
  if (this.showLoadMore) {
    return h(
      'div',
      {
        class: 'fjcc facc'
      },
      [
        h(
          'g-button',
          {
            class: 'ma-3',
            props: {
              label: this.getTranslate('misc.loadMore'),
              flat: true,
              rounded: true,
              loading: this.restData[options.name].find.state === states.loading,
              disabled: this.restData[options.name].find.state === states.loading || !this.showLoadMore,
              color: 'primary'
            },
            on: {
              click: () => {
                if (this.restData[options.name].find.state === states.ready && this.showLoadMore) {
                  this.restData[options.name].find.pagination.offset += this.restData[options.name].find.pagination.limit
                }
              }
            }
          }
        )
      ]
    )
  }
}

function renderScrollContent(h, options) {
  return h(
    'div',
    {
      class: 'search-generator__scroll',
      on: { scroll: this.onScroll }
    },
    [
      renderItems.call(this, h, options),
      renderLoadMoreButton.call(this, h, options)
    ]
  )
}

function renderContent(h, options) {
  return h(
    'div',
    {
      class: 'search-generator__content',
      style: { gridTemplateRows: this.gridTemplateRows }
    },
    [
      renderSearch.call(this, h, options),
      renderFilter.call(this, h, options),
      renderLastVisitedItems.call(this, h, options),
      renderScrollContent.call(this, h, options)
    ]
  )
}

export default function(h, options) {
  if (options.show || this.computedShowSearch) {
    return h(
      'div',
      {
        class: 'search-generator'
      },
      [ renderContent.call(this, h, options) ]
    )
  }
}
