import stepper from '@/components/services/sendings/replacement/stepper'

const className = 'replacement'

function renderHeader(h) {
  if (this.type) {
    let type = this.type
    switch (this.type) {
      case 'payload.text': {
        type = 'text'
        break
      }
      case 'payload.sender': {
        type = 'sender'
        break
      }
      case 'state.moderation': {
        type = 'moderation'
        break
      }
    }

    return h(
      'div',
      {
        class: {
          [`${className}-panel__header`]: true,
          [`${className}-panel__header--${type}`]: true
        }
      },
      [ this.getTranslate(`${className}.types.${this.type}`) ]
    )
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: `${className}-panel__content`
    },
    [
      h(
        stepper,
        {
          props: {
            type: this.type,
            value: this.value
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${className}-panel`]: true,
        [`${className}-panel--active`]: this.value.length > 1
      }
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h)
    ]
  )
}
