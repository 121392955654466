import { colors, sizes } from '@/utils'

import squircle from '@/components/icon/squircle'

export default function(h) {
  return h(
    this.$component,
    {
      class: this.$class,
      props: { to: this.$to }
    },
    [
      h(
        squircle,
        {
          props: {
            icon: this.$icon,
            color: colors.grey,
            size: sizes.medium
          }
        }
      )
    ]
  )
}
