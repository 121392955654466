import { buttonModes, colors, components, icons } from '@/utils'

import HeadTitle from '@/components/misc/HeadTitle'
import button from '@/components/button'
import squircle from '@/components/icon/squircle'

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        closeOnClick: true,
        closeOnEsc: true,
        maxWidth: 400
      },
      on: {
        input: event => {
          this.showDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate('commons.titles.confirm.remove') },
          slot: 'header'
        }
      ),

      h(
        'div',
        {
          class: 'pa-3'
        },
        [ this.getTranslate('commons.contents.confirm.remove') ]
      ),

      h(
        'div',
        {
          class: 'pa-2 fjcfe grid-gap--8',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                mode: buttonModes.clear,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                mode: buttonModes.flat,
                color: colors.error
              },
              on: {
                click: async () => {
                  await this.callback()
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderPrefix(h) {
  if (this.component === components['list-item']) {
    return h(
      squircle,
      {
        props: {
          icon: icons.delete,
          color: this.disabled || this.loading ? colors.grey : colors.error
        }
      }
    )
  }
}

export default function(h) {
  return h(
    this.component === 'btn' ? button : this.component,
    {
      class: this.component === 'g-button' ? 'ma-0' : undefined,
      props: this.$props,
      on: { click: event => this.onClick(event) },
      scopedSlots: {
        prefix: () => renderPrefix.call(this, h),
        dialog: () => renderDialog.call(this, h)
      }
    }
  )
}
