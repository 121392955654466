import Vue from 'vue'

import { get } from 'lodash'
import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, icons, permissionPresets } from '@/utils'

import button from '@/components/button'
import SourceBox from '@/components/source/box'
import EventsHistoryDialog from '@/components/services/eventsHistory/dialog'
import analytics from '@/components/services/tasks/analytics'
import HeadTitle from '@/components/misc/HeadTitle'

function renderSourceDataDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showSourceDataDialog,
        close: true,
        rounded: true,
        maxWidth: 1000
      },
      on: {
        input: event => {
          this.showSourceDataDialog = event
        }
      }
    },
    [
      h(
        HeadTitle,
        {
          props: { value: this.getTranslate('commons.titles.data') },
          slot: 'header'
        }
      ),
      h(
        SourceBox,
        {
          props: {
            value: this.value,
            rounded: false
          },
          on: {
            close: event => {
              this.showSourceDataDialog = event
            }
          }
        }
      ),
      h(
        'div',
        {
          class: 'fiac fjcfe pa-2',
          slot: 'footer'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.showSourceDataDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}
function renderSourceListItem(h) {
  if (this.checkPermissions('advanced.tasks.update')) {
    return h(
      'g-list-item',
      {
        props: {
          label: this.getTranslate('misc.sourceData'),
          icon: 'code',
          color: colors.primary,
          dense: true
        },
        on: {
          click: () => {
            this.showSourceDataDialog = !this.showSourceDataDialog
          }
        }
      }
    )
  }
}

function renderStatisticListItem(h) {
  const groupId = get(this.value, 'data.sending.groupId')
  if (this.value.type === 'sendings:bulk' && groupId) {
    return h(
      'g-list-item',
      {
        props: {
          label: this.getTranslate('tasks.labels.analytics'),
          icon: 'assessment',
          dense: true
        },
        on: {
          click: () => {
            const $lt = new Date(this.value.updatedAt)
            $lt.setMinutes($lt.getMinutes() + 1)

            Vue.router.push({
              name: 'sendings',
              query: { currentTab: 'outgoing' },
              params: {
                filter: {
                  groupId,
                  createdAt: {
                    $gt: this.value.createdAt,
                    $lt: $lt.toISOString()
                  }
                }
              }
            })
          }
        }
      }
    )
  }
}

function renderRemoveListItem(h) {
  if (this.checkPermissions('advanced.tasks.remove', permissionPresets.meUp)) {
    if (!~[ TASK_STATES.running, TASK_STATES.scheduled ].indexOf(this.value.state)) {
      return h(
        'g-list-item',
        {
          props: {
            icon: 'delete',
            color: 'error',
            label: this.getTranslate('misc.buttons.remove'),
            dense: true
          },
          on: {
            click: () => {
              this.showConfirmRemoveDialog = true
            }
          }
        }
      )
    }
  }
}

function renderEventsHistoryDialog(h) {
  return h(
    EventsHistoryDialog,
    {
      props: {
        entityId: this.value.id,
        show: this.showEventsHistoryDialog
      },
      on: {
        dialog: event => {
          this.showEventsHistoryDialog = event
        }
      }
    }
  )
}

function renderEventsHistoryListItem(h) {
  if (this.checkPermissions('advanced.apisender.get', permissionPresets.true)) {
    return h(
      'g-list-item',
      {
        props: {
          label: this.getTranslate('eventsHistory.title'),
          icon: 'manage_history',
          dense: true
        },
        on: {
          click: () => {
            this.showEventsHistoryDialog = !this.showEventsHistoryDialog
          }
        }
      }
    )
  }
}

function renderConfirmRemoveDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showConfirmRemoveDialog,
        rounded: true,
        maxWidth: 300
      },
      on: {
        input: event => {
          this.showConfirmRemoveDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-3'
        },
        [ this.getTranslate('tasks.contents.confirm.remove', { title: this.value.title }) ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe faic grid-gap--8'
        },
        [
          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.cancel'),
                mode: buttonModes.clear,
                disabled: this.loading
              },
              on: {
                click: () => {
                  this.showConfirmRemoveDialog = false
                }
              }
            }
          ),

          h(
            button,
            {
              props: {
                label: this.getTranslate('misc.buttons.remove'),
                mode: buttonModes.flat,
                color: colors.error
              },
              on: {
                click: () => {
                  this.removeTask(this.value.id)
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-menu',
    {
      props: {
        value: this.showMenu,
        rounded: true,
        offsetDistance: 8,
        placement: 'bottom-end'
      },
      on: {
        input: event => {
          this.showMenu = event
        }
      }
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            icon: icons.more_vert
          },
          slot: 'activator'
        }
      ),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        [
          renderSourceListItem.call(this, h),
          renderStatisticListItem.call(this, h),
          h(analytics, { props: { value: this.value } }),
          renderEventsHistoryListItem.call(this, h),
          renderRemoveListItem.call(this, h)
        ]
      ),

      renderSourceDataDialog.call(this, h),
      renderEventsHistoryDialog.call(this, h),
      renderConfirmRemoveDialog.call(this, h)
    ]
  )
}
