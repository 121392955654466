import { components } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (this.view === components['list-item']) {
    return h(
      components['list-item'],
      {
        props: {
          label: this.label,
          icon: this.icon,
          color: this.color
        },
        on: {
          click: () => this.clickHandler()
        }
      }
    )
  }

  return h(
    button,
    {
      attrs: { 'data-clipboard-text': `${this.value}` },
      class: `copy-button-${this._uid}`,
      props: {
        mode: this.mode,
        size: this.size,
        color: this.color,
        icon: this.icon,
        iconSVG: !this.icon ? this.iconSVG : undefined,
        iconLibrary: this.iconLibrary,
        tooltip: this.tooltip
      },
      on: {
        click: () => this.clickHandler()
      }
    }
  )
}
