import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'TemplatesFormLite',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    }
  },

  render
}
