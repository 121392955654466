import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import sticker from '@/components/misc/sticker'

import { statesColors, statesIcons } from '@/components/services/tasks/utils'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.$value,
        label: this.getTranslate(`${services.tasks}.labels.state`),
        icon: statesIcons[this.value.state],
        color: statesColors[this.value.state],
        borderStyle: 'dashed',
        tooltip: this.stateTooltip,
        iconTooltip: this.value.state === TASK_STATES.scheduled ? this.stateLabel : this.getTranslate(`${services.tasks}.states.${this.value.state}`)
      }
    }
  )
}
