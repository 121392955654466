export default {
  title: 'Файлы',

  states: {
    error: 'Ошибка загрузки',
    success: 'Файл загружен',
    loading: 'Идёт загрузка файла'
  },

  drop: 'Перетащите файл сюда, чтобы загрузить'
}
