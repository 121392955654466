import { components } from '@/utils'

function renderPreLoader(h, button) {
  if (button.loading) {
    return h(
      'div',
      {
        class: `${components['group-button']}__button-loading`
      },
      [
        h(
          'g-progress',
          {
            props: {
              indeterminate: true,
              size: this.small ? 18 : this.large ? 28 : 24
            }
          }
        )
      ]
    )
  }
}

function renderIconHolder(h, button) {
  if (button.icon) {
    return h(
      'div',
      {
        class: {
          [`${components['group-button']}__button-icon`]: true,
          'square--28': this.small,
          'square--36': button.icon && !this.small && !this.large,
          'square--44': button.icon && this.large
        }
      },
      [ renderIcon.call(this, h, button.icon) ]
    )
  }
}

function renderIcon(h, icon) {
  return h(
    'g-icon',
    {
      props: {
        value: icon,
        size: this.small ? 18 : this.large ? 26 : 22
      }
    }
  )
}

function renderButtonLabel(h, button) {
  if (button.label) {
    return h(
      'div',
      {
        class: `${components['group-button']}__button-label`
      },
      [ button.label ]
    )
  }
}

function renderButton(h, button) {
  return h(
    'button',
    {
      attrs: { type: button.type || 'button' },
      class: {
        [`${components['group-button']}__button`]: true,
        [`${components['group-button']}__button--disabled`]: button.disabled || this.disabled,
        [`${components['group-button']}__button--active`]: button.active && !button.disabled && !this.disabled,
        [`${components['group-button']}__button--loading`]: button.loading,
        [`${components['group-button']}__button--icon`]: !!button.icon,
        [`${components['group-button']}__button--labeled`]: !!button.label,
        [`${components['group-button']}__button--${button.color}`]: !!button.color
      },
      on: button.on,
      directives: button.directives
    },
    [
      renderIconHolder.call(this, h, button),
      renderButtonLabel.call(this, h, button),
      renderPreLoader.call(this, h, button)
    ]
  )
}

function renderButtons(h) {
  if (Array.isArray(this.buttons) && this.buttons.length) {
    return this.buttons.map(button => {
      return renderButton.call(this, h, button)
    })
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${components['group-button']}`]: true,
        [`${components['group-button']}--disabled`]: this.disabled,
        [`${components['group-button']}--small`]: this.small,
        [`${components['group-button']}--large`]: this.large
      },
      style: {
        'grid-template-columns': `repeat(${this.buttons.length}, auto)`
      }
    },
    [ renderButtons.call(this, h) ]
  )
}
