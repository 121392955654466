import { buttonModes, colors } from '@/utils'

import button from '@/components/button'

export default function(h) {
  if (this.proxy && this.check) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: 'grid-template-columns: 1fr 36px'
      },
      [
        h(
          'div',
          {
            class: 'sendings-warning'
          },
          [
            h(
              'div',
              {
                class: 'sendings-warning__body'
              },
              [
                h(
                  'g-icon',
                  {
                    props: {
                      value: 'error',
                      color: 'warning'
                    }
                  }
                ),

                h(
                  'div',
                  {
                    class: 'sendings-warning__content'
                  },
                  [
                    h('div', { class: 'sendings-warning__content-title' }, this.getTranslate('misc.attention')),

                    h(
                      'div',
                      {
                        domProps: {
                          innerHTML: this.warningText
                        }
                      }
                    )
                  ]
                )
              ]
            ),

            h(
              'div',
              {
                class: 'sendings-warning__footer'
              },
              [
                h(
                  button,
                  {
                    props: {
                      label: this.getTranslate('misc.buttons.support'),
                      mode: buttonModes.flat,
                      color: colors.primary
                    },
                    on: {
                      click: () => {
                        this.$router.push({ name: 'support' })
                      }
                    }
                  }
                ),

                h(
                  button,
                  {
                    props: {
                      label: this.getTranslate('misc.buttons.understand'),
                      mode: buttonModes.flat
                    },
                    on: {
                      click: () => {
                        this.proxy = false
                      }
                    }
                  }
                )
              ]
            )
          ]
        )
      ]
    )
  }
}
