import { serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services['otp-settings/channels'],

      backendGeneration: 'nest',

      outputFilter(result) {
        const { additionalSettings } = result
        additionalSettings.lifetime = parseInt(additionalSettings.lifetime)
        additionalSettings.numberOfTries = parseInt(additionalSettings.numberOfTries)
        additionalSettings.code.length = parseInt(additionalSettings.code.length)

        return result
      },

      find: {
        defaultFilter: {
          scopes: [ 'full' ],
          order: [ [ 'createdAt', 'desc' ] ],
          search: undefined,
          channelType: undefined
        }
      },
      get: {
        params: {
          query: {
            scopes: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            scopes: [ 'full' ]
          }
        }
      },
      create: { redirect: true },

      view: 'panels',
      views: [ 'panels' ],

      has: {
        search: false,
        clone: false,
        view: false
      },

      titlePath: 'name'
    })
  ]
}
