import { isEqual, merge } from 'lodash'

import { generateUniqueKey, inputModes, isEmpty } from '@/utils'

export const basic = (component = 'input') => {
  return {
    props: {
      mode: {
        type: String,
        default: inputModes.default,
        validator: value => {
          return Object.values(inputModes).includes(value)
        }
      },

      state: {
        type: String,
        default: undefined,
        validator: value => {
          return [ 'error', 'success', 'active', 'focused', 'disabled' ].includes(value)
        }
      },

      error: String,
      success: String,
      label: String,
      hint: String,
      backgroundColor: String,

      beforeIcon: String,
      beforeIconCallback: Function,

      afterIcon: String,
      afterIconCallback: Function,

      count: [ String, Number ],
      prefix: [ String, Number ],
      suffix: [ String, Number ],

      clearable: {
        type: Boolean,
        default: false
      },
      details: {
        type: Boolean,
        default: true
      },
      flat: {
        type: Boolean,
        default: false
      },
      dense: {
        type: Boolean,
        default: false
      },
      rounded: {
        type: Boolean,
        default: false
      },
      loading: {
        type: Boolean,
        default: false
      },
      validateOnBlur: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      },
      focused: {
        type: Boolean,
        default: false
      },
      focusedClass: {
        type: String,
        default: 'text--primary'
      }
    },

    data() {
      return {
        _errorOnBlur: false,
        _errorOnRequired: false,

        _error: undefined,
        _success: undefined,
        _active: false,
        _focused: false
      }
    },

    computed: {
      $id() {
        return this.id || generateUniqueKey()
      },

      $class() {
        const componentName = component === 'input' ? 'g-text-field' : 'g-textarea'
        const result = {
          [`${componentName}`]: true,

          [`${componentName}--flat`]: this.flat,
          [`${componentName}--dense`]: this.dense,
          [`${componentName}--rounded`]: this.rounded,

          [`${componentName}--error`]: this.isError,
          [`${componentName}--success`]: this.isSuccess,
          [`${componentName}--focused`]: this.isFocused,
          [`${componentName}--active`]: this.isActive || this.isFocused,

          [`${componentName}--labeled`]: !isEmpty(this.label),
          [`${componentName}--filled`]: !isEmpty(this.proxy),

          [`${componentName}--required`]: this.required,
          [`${componentName}--disabled`]: this.disabled,
          [`${componentName}--readonly`]: this.readonly,

          [`${componentName}--clearable`]: this.clearable,

          [`${componentName}--has-before`]: this.hasBefore,
          [`${componentName}--has-after`]: this.hasAfter,

          [`${componentName}--${this.mode}`]: true
        }

        if (component === 'input') {
          result[`${componentName}--time`] = this.type === 'time'
        }

        return result
      },

      $placeholder() {
        if (this.placeholder) {
          const placeholder = [ this.placeholder, this.required && '*' ].filter(Boolean).join(' ')
          switch (this.mode) {
            case 'box':
            case 'solo':
            case 'outline': {
              if (this.details === false && this.error || this.success) {
                return this.error || this.success
              } else {
                return placeholder
              }
            }
            case 'line-label':
            case 'outline-label':
            case 'default':
            default: {
              return placeholder
            }
          }
        }
      },
      $hint() {
        return this.isError ? this.error || this.hint : this.isSuccess ? this.success || this.hint : this.hint
      },

      input() {
        return this.$refs && this.$refs.input && (this.$refs.input.$el || this.$refs.input) || undefined
      },
      $inputProps() {
        const result = merge(
          {},
          this.attributes,
          {
            component,
            id: this.$id,
            value: this.proxy,
            placeholder: this.$placeholder,
            name: this.name,
            mask: this.mask,
            maskOptions: this.maskOptions,
            processValue: this.processValue
          }
        )

        return result
      },
      $inputOn() {
        const result = {
          focus: this._focus,
          blur: this._blur,
          mousedown: this._mousedown,
          mouseup: this._mouseup,
          keypress: this._keypress
        }

        switch (component) {
          case 'input': {
            result.input = event => {
              if (!this.isDisabled) {
                this.proxy = event
              }
            }
            break
          }
          case 'textarea': {
            result.input = event => {
              if (!this.isDisabled) {
                this.proxy = event
                this._delayedResize()
              }
            }
            result.cut = this._delayedResize
            result.paste = () => this._delayedResize
            result.drop = () => this._delayedResize
            result.keydown = () => this._delayedResize
            break
          }
        }

        return result
      },

      isDisabled() {
        return this.disabled || this.readonly || this.state === 'disabled'
      },
      isClearable() {
        return !this.isDisabled && this.clearable && !isEmpty(this.proxy)
      },

      isError() {
        return !!this._data._error || this._data._errorOnBlur || this._data._errorOnRequired || this.state === 'error'
      },
      isSuccess() {
        return !!this._data._success || this.state === 'success'
      },
      isActive() {
        return !!this._data._active || this.state === 'active'
      },
      isFocused() {
        return !!this._data._focused || this.state === 'focused'
      },

      hasBefore() {
        return this.hasBeforeSlot || this.beforeIcon
      },
      hasBeforeSlot() {
        return !!(this.$scopedSlots.before || this.$slots.before)
      },

      hasAfter() {
        return this.hasAfterSlot || this.loading && this.mode !== 'default' || this.isClearable || this.afterIcon
      },
      hasAfterSlot() {
        return !!(this.$scopedSlots.after || this.$slots.after)
      }
    },

    watch: {
      value() {
        this._setErrorOnRequired()
      },
      required() {
        this._setErrorOnRequired()
      },
      error(value) {
        this._data._error = value
      },
      success(value) {
        this._data._success = value
      },
      active(value) {
        this._data._active = value
      },
      focused(value) {
        this._data._focused = value
      }
    },

    methods: {
      _clearCurrentError() {
        this._data._error = false
        this._data._errorOnBlur = false
        this._data._errorOnRequired = false
      },
      _clearCurrentSuccess() {
        this._data._success = false
      },

      _clear() {
        if (!this.isDisabled) {
          this.proxy = this.defaultValue
          if (this.isError) {
            this._clearCurrentError()
          }
          if (this.isSuccess) {
            this._clearCurrentSuccess()
          }
          this._focusInput()
        }
      },

      _setRequired() {
        if (this.required) {
          if (isEmpty(this.proxy) && !isEqual(this.proxy, this.defaultValue)) {
            this.proxy = this.defaultValue
          }
        }
      },
      _setErrorOnRequired() {
        if (this.required) {
          if (isEmpty(this.value)) {
            this._data._errorOnRequired = true
          } else {
            this._data._errorOnRequired = false
          }
        } else {
          this._data._errorOnRequired = false
        }
      },
      _autoFocus() {
        if (this.autofocus && !this.isDisabled) {
          this._focusInput()
          this._setCaretPosition()
        }
      },

      _setCaretPosition() {
        if (!this.isDisabled) {
          if (this.type === 'text') {
            if (this.input && this.input.focus) {
              this.$nextTick(() => {
                this.input.setSelectionRange(this.input.value.length, this.input.value.length)
              })
            }
          }
        }
      },

      _focusInput() {
        if (!this.isDisabled) {
          if (this.input && this.input.focus) {
            this.$nextTick(() => {
              this.input.focus()
            })
          }
        }
      },

      _focus(event) {
        if (!this.isDisabled) {
          this._data._focused = true

          if (this.isError) {
            this._clearCurrentError()
          }

          this.$emit('focus', event)
        }
      },
      _blur(event) {
        if (!this.isDisabled) {
          this._setErrorOnRequired()

          if (this.isError && this.validateOnBlur) {
            this._data._errorOnBlur = true
          }

          this._data._focused = false
          this.$emit('blur', event)
        }
      },

      _mousedown(event) {
        if (!this.isDisabled) {
          this._data._active = true
          this.$emit('mousedown', event)
        }
      },
      _mouseup(event) {
        if (!this.isDisabled) {
          this._data._active = false
          this.$emit('mouseup', event)
        }
      },
      _keypress(event) {
        if (!this.isDisabled) {
          this.$emit('keypress', event)
        }
      },
      _click(event) {
        if (!this.isDisabled) {
          this._focusInput()
          this.$emit('click', event)
        }
      }
    }
  }
}
