import { components } from '@/utils'

export default function(h) {
  return h(
    'main',
    {
      attrs: { id: 'main' },
      class: components.content
    },
    [ this.$slots.default ]
  )
}
