import { components, numberToPxOrString, size } from '@/utils'

import render from './render'

export default {
  name: components.form,

  mixins: [ size ],

  props: {
    action: String,
    acceptCharset: String,

    autocomplete: {
      type: String,
      validator: value => {
        return !!~[ 'off', 'on' ].indexOf(value)
      }
    },

    enctype: {
      type: String,
      default: 'application/x-www-form-urlencoded',
      validator: value => {
        return !!~[ 'application/x-www-form-urlencoded', 'multipart/form-data', 'text/plain' ].indexOf(value)
      }
    },

    method: {
      type: String,
      validator: value => {
        return !!~[ 'post', 'get', 'dialog' ].indexOf(value)
      }
    },

    name: String,

    novalidate: {
      type: Boolean,
      default: true
    },

    rel: String,

    target: {
      type: String,
      default: '_self',
      validator: value => {
        return !!~[ '_self', '_blank', '_parent', '_top' ].indexOf(value)
      }
    }
  },

  computed: {
    form() {
      return this.$refs && this.$refs.form && (this.$refs.form.$el || this.$refs.form)
    },

    $attrs() {
      return {
        id: `g-form-${this._uid}`,
        action: this.action,
        'accept-charset': this.acceptCharset,
        autocomplete: this.autocomplete,
        enctype: this.enctype,
        method: this.method,
        name: this.name,
        novalidate: this.novalidate,
        rel: this.rel,
        target: this.target
      }
    },

    $style() {
      return {
        'min-height': numberToPxOrString(this.minHeight),
        'max-height': numberToPxOrString(this.maxHeight),
        height: numberToPxOrString(this.height),
        'min-width': numberToPxOrString(this.minWidth),
        'max-width': numberToPxOrString(this.maxWidth),
        width: numberToPxOrString(this.width)
      }
    }
  },

  mounted() {
    this.form.addEventListener('valuechange', this.checkFormValidity)
  },

  beforeDestroy() {
    this.form.removeEventListener('valuechange', this.checkFormValidity)
  },

  methods: {
    checkFormValidity() {
      if (this.form) {
        this.$emit('validation', this.form.checkValidity())
      }
    }
  },

  render
}
