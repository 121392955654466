import { components } from '@/utils'

import preloader from '@/components/misc/preloader'

function renderPreloader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: `${components['list-item']}__preloader`
      },
      [
        h(
          preloader,
          {
            props: {
              value: true,
              size: 18
            }
          }
        )
      ]
    )
  }
}

function renderIcon(h, icon) {
  if (icon) {
    return h(
      'div',
      {
        class: `${components['list-item']}__icon`
      },
      [
        h(
          components.icon,
          {
            props: {
              value: icon,
              color: this.computedIconColor,
              size: this.computedIconSize
            }
          }
        )
      ]
    )
  }
}
function renderLabel(h) {
  return h(
    'div',
    {
      class: `${components['list-item']}__label`
    },
    [ this.label || this.$slots.default ]
  )
}
function renderScopedSlot(h, slot) {
  if (this[slot]) {
    return renderIcon.call(this, h, this[slot])
  }

  if (typeof this.$scopedSlots[slot] === 'function') {
    return h(
      'div',
      {
        class: `${components['list-item']}__${slot}`
      },
      this.$scopedSlots[slot]()
    )
  }

  return this.$slots[slot]
}
function renderHolder(h) {
  return h(
    'div',
    {
      class: `${components['list-item']}__holder`
    },
    [
      renderScopedSlot.call(this, h, 'icon'),
      renderScopedSlot.call(this, h, 'prefix'),
      renderLabel.call(this, h),
      renderScopedSlot.call(this, h, 'suffix')
    ]
  )
}

function renderButton(h) {
  if (this.hasButton) {
    return h(
      'div',
      {
        class: {
          [`${components['list-item']}__button`]: true,
          [`${components['list-item']}__button--disabled`]: this.button.disabled || this.disabled,
          [`${components['list-item']}__button--${this.button.color || this.color}`]: !!this.button.color || !!this.color
        },
        on: {
          click: event => {
            event.stopPropagation()
            if (this.button.callback) {
              this.button.callback()
            }
          }
        },

        directives: this.computedButtonTooltip
      },
      [
        h(
          components.icon,
          {
            props: {
              value: this.button.icon,
              color: this.button.iconColor || this.computedIconColor,
              size: this.button.iconSize || this.computedIconSize
            }
          }
        )
      ]
    )
  }
}

function renderDialogSlot() {
  if (typeof this.$scopedSlots.dialog === 'function') {
    return this.$scopedSlots.dialog()
  }

  return this.$slots.dialog
}

export default function(h) {
  return h(
    this.to ? 'router-link' : 'div',
    {
      attrs: { role: 'listitem' },
      class: this.$class,
      props: { to: this.to },
      on: this.$eventHandlers
    },
    [
      renderPreloader.call(this, h),
      renderHolder.call(this, h),
      renderButton.call(this, h),
      renderDialogSlot.call(this, h)
    ]
  )
}
