import Vue from 'vue'

import render from './render'

import { serviceName } from '@/components/services/apisender/utils'
import { globalErrorHandler, globalErrorProcessor } from '@/utils'

export default {
  name: 'ScanQrCode',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      showDialog: false,
      showDeviceLinkDialog: false,
      loading: false,

      error: undefined,
      qrCode: undefined,

      timeout: 0,
      interval: 0
    }
  },

  computed: {
    disabled() {
      return this.loading || this.value.$state === 'authorized' || !this.value.isActive
    }
  },

  mounted() {
    Vue.$socket.on(`${serviceName}.scanQrCode`, ({ id, result }) => {
      if (id && this.value.id !== id) {
        return
      }

      const { qrCode, error, state } = result

      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.loading = false

      if (error) {
        this.qrCode = undefined
        this.error = error
      } else if (qrCode) {
        this.qrCode = qrCode
        this.timeout = setTimeout(() => {
          this.qrCode = undefined
        }, 60 * 1000)
      } else if (state === 'instance-authorized') {
        switch (state) {
          case 'instance-authorized': {
            this.loading = false
            this.showDialog = false
            this.$emit('update')
            break
          }
        }
      }
    })
  },

  beforeDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
  },

  methods: {
    async scanQrCode() {
      try {
        if (this.qrCode) {
          this.showDialog = true
        } else {
          this.error = undefined
          this.loading = true
          const { data } = await Vue.$GRequest.get(`${serviceName}/scanQrCode`, this.value.id)
          if (data && data.result) {
            this.showDialog = true
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        this.loading = false
      }
    }
  },

  render
}
