import { isEmpty, isEqual, omitBy } from 'lodash'

import { EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

export const $order = [ [ 'createdAt', 'desc' ] ]

export function calcChanges(item, nextItem) {
  if (item.type === EVENTSHISTORY_TYPES.created) {
    const newValues = {}
    for (const key in item.data) {
      if (!isEmpty(item.data[key])) {
        newValues[key] = item.data[key]
      }
    }

    return { newValues }
  } else {
    const newValues = omitBy(item.data, (value, key) => isEqual(nextItem.data[key], value))
    const oldValues = Object.keys(newValues).reduce((result, key) => {
      result[key] = nextItem.data[key]

      return result
    }, {})

    return {
      newValues,
      oldValues
    }
  }
}
