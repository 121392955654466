import { inputModes } from '@/utils'

function renderTitleField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${this.serviceName}.labels.title`),
        mode: inputModes['line-label'],
        rounded: true,
        dense: true,
        clearable: true,
        required: true,
        details: false,
        autofocus: true,
        mask: /^.{0,255}$/
      },
      on: {
        input: event => {
          this.proxy.title = event
        }
      }
    }
  )
}

function renderSwitch(h) {
  if (this.showSwitch) {
    return h(
      'g-switch',
      {
        props: {
          value: this.proxy.isActive,
          disabled: this.disabled
        },
        directives: [
          {
            name: 'g-tooltip',
            options: { value: this.getTranslate(`${this.serviceName}.tooltips.isActive`) }
          }
        ],
        on: {
          input: event => {
            this.proxy.isActive = event
          }
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 faic',
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderTitleField.call(this, h),
      renderSwitch.call(this, h)
    ]
  )
}
