import EditButton from '@/components/services/contracts/item/buttons/edit'

export default function(h) {
  return h(
    EditButton,
    {
      props: {
        value: this.value
      }
    }
  )
}
