import OwnerSelect from '@/components/misc/OwnerSelect'

export default function(h) {
  if (this.checkPermissions('advanced.users.get')) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8',
        style: { 'grid-template-columns': '250px' }
      },
      [
        h(
          OwnerSelect,
          {
            props: {
              value: this.proxy.OwnerId,
              mode: 'outline',
              dense: true,
              rounded: true,
              details: false,
              clearable: true
            },
            on: {
              input: event => {
                this.proxy.OwnerId = event
              }
            }
          }
        )
      ]
    )
  }
}
