export default function(h) {
  if (this.locales.length > 1) {
    const on = {
      click: () => {
        const locale = this.locale === 'ru' ? 'en' : 'ru'
        this.setLocale(locale)
      }
    }
    switch (this.as) {
      case 'button': {
        return h(
          'g-button',
          {
            props: {
              icon: 'translate',
              flat: true,
              color: 'grey'
            },
            on
          }
        )
      }

      case 'link':
      default: {
        return h(
          'span',
          {
            class: 'link caption',
            style: { 'user-select': 'none' },
            on
          },
          this.getTranslate(`misc.locales.${this.locale}`)
        )
      }
    }
  }
}
