export default {
  title: 'Documentation',

  categories: {
    platform: 'Platform',
    api: 'API'
  },

  file: {
    title: 'Documentation'
  },

  files: {
    instruction: 'Instruction',
    'HTTP-REST': 'HTTP-REST',
    SMPP: 'SMPP'
  },

  buttons: {
    toc: 'Contents'
  },

  platform: {
    instruction: {
      title: 'Instruction'
    }
  },

  api: {
    'HTTP-REST': {
      title: 'HTTP-REST'
    },
    SMPP: {
      title: 'SMPP'
    }
  }
}
