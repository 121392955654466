import render from './render'

export default {
  name: 'FileInfo',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    readonly: Boolean,

    includeOwner: {
      type: Boolean,
      default: true
    }
  },

  render
}
