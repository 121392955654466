const _ = { get: require('lodash/get') }

export const tableHeaderParser = function(headers = []) {
  return headers.reduce((result, header) => {
    if (_.get(header, 'show', true)) {
      result.push({
        title: this.getTranslate(_.get(header, 'title')),
        value: _.get(header, 'value'),
        align: _.get(header, 'align', 'center'),
        class: _.get(header, 'class', ''),
        sortable: _.get(header, 'sortable', false),
        width: _.get(header, 'width', ''),
        show: _.get(header, 'show', true)
      })
    }

    return result
  }, [])
}

export default { tableHeaderParser }
