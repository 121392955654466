import { buttonModes, services } from '@/utils'

import button from '@/components/button'

function renderIsActiveField(h) {
  return h(
    'g-switch',
    {
      props: {
        value: this.proxy.isActive,
        label: this.getTranslate(`${services.providerGroups}.labels.isActive`)
      },
      on: {
        input: event => {
          this.proxy.isActive = event
        }
      }
    }
  )
}
function renderFlags(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2 border border--rounded border--dashed'
    },
    [ renderIsActiveField.call(this, h) ]
  )
}

function renderMenu(h) {
  return h(
    'g-menu',
    {
      props: {
        closeOnContentClick: false,
        offsetDistance: 8,
        placement: 'bottom-end',
        rounded: true,
        maxWidth: 304,
        width: '100%'
      }
    },
    [
      h(
        button,
        {
          props: {
            icon: 'filter_list',
            mode: buttonModes.flat
          },
          slot: 'activator'
        }
      ),

      h(
        'div',
        {
          class: 'grid grid-gap--8 pa-2'
        },
        [ renderFlags.call(this, h) ]
      )
    ]
  )
}

export default function(h) {
  return renderMenu.call(this, h)
}
