import proxy from '@sigma-legacy-libs/g-proxy'

import { isValidEmail, isValidPassword, isValidPhone, isValidUserName } from '@/utils'

import render from './render'

export default {
  name: 'UsersBasicForm',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: Boolean
  },

  computed: {
    validation() {
      if (!this.proxy.username || !isValidUserName(this.proxy.username)) {
        return false
      }
      if (!this.proxy.password || !isValidPassword(this.proxy.password)) {
        return false
      }
      if (!this.proxy.data.email || !isValidEmail(this.proxy.data.email)) {
        return false
      }
      if (!this.proxy.data.phone || !isValidPhone(this.proxy.data.phone)) {
        return false
      }

      return true
    }
  },

  watch: {
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
