export class RequestCollector {
  constructor(options = {}) {
    this.request = options.request
    this.dataFilter = options.dataFilter
    this.timeout = options.timeout || 50

    this.bucket = []
    this.promise = null

    if (!this.dataFilter) {
      this.dataFilter = data => data
    }
  }

  async getData(data) {
    this.bucket.push(...this.dataFilter(data, this.bucket))

    if (!this.promise) {
      this.promise = new Promise((resolve, reject) => {
        setTimeout(async () => {
          try {
            const bucket = this.bucket

            this.bucket = []
            this.promise = null

            const data = await this.request(bucket)

            resolve(data)
          } catch (error) {
            reject(error)
          }
        }, this.timeout)
      })
    }

    return this.promise
  }
}

export default RequestCollector
