import proxy from '@sigma-legacy-libs/g-proxy'

import { components } from '@/utils'
import { basic, core } from '@/components/g/input/mixins'

import render from './render'

export default {
  name: components['text-field'],

  mixins: [ core(), proxy(), basic() ],

  mounted() {
    this._setRequired()
    this._autoFocus()
  },

  render
}
