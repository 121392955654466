import Vue from 'vue'

import { generateServices, globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

const serviceNameViaPoint = services['otp-settings/widgets'].split('/').join('.')

export default {
  name: 'UserChannels',

  mixins: [
    generateServices({
      name: services['otp-settings/channels'],

      backendGeneration: 'nest',

      get: false,
      create: false,
      update: false,
      remove: false
    })
  ],

  props: {
    UserId: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      loading: false,
      otpChannelsSettings: []
    }
  },

  computed: {
    $disabled() {
      return this.filteredChannels.length === 0
    }
  },

  async mounted() {
    await this.rest[services['otp-settings/channels']].find()
    await this.getChannels()
  },

  methods: {
    async getChannels() {
      try {
        this.loading = false
        const { data } = await Vue.$GRequest.get(
          `/n/${services['otp-settings/channels']}/user-channels`,
          `${this.UserId || this.$route.params.id}`
        )
        if (data) {
          this.otpChannelsSettings = data.map(({ otpChannelSettingsId }) => ({ otpChannelSettingsId }))
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    },

    async attachChannels() {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.update(
          `/n/${services['otp-settings/channels']}/user-channels`,
          `${this.UserId || this.$route.params.id}/bulk`,
          { otpChannelsSettings: this.otpChannelsSettings.filter(({ otpChannelSettingsId }) => !!otpChannelSettingsId) }
        )
        if (data) {
          this.addSnackbar({
            text: this.getTranslate(`${serviceNameViaPoint}.snackbars.channels.attached`),
            type: 'success'
          })
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
