import { TASK_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, services, sizes } from '@/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import HeadTitle from '@/components/misc/HeadTitle'
import OneRowString from '@/components/misc/OneRowString'

function renderRecipientByType(h, item) {
  const props = {
    label: item.title,
    size: sizes.small,
    color: colors.primary
  }

  if (item.id) {
    props.mode = buttonModes.flat

    return h(
      'router-link',
      {
        class: 'link',
        props: {
          to: {
            name: 'contacts.single',
            params: { id: item.id }
          }
        }
      },
      [ h(button, { props }) ]
    )
  }

  if (item.error) {
    props.label = `${item.title}: ${item.error}`
    props.color = colors.error
  }

  return h(tag, { props })
}
function renderRecipients(h, type) {
  return h(
    'div',
    {
      class: 'faic fw grid-gap--8'
    },
    this.analytics[type].map(item => {
      return renderRecipientByType.call(this, h, item)
    })
  )
}
function renderTypeHeader(h, type) {
  if (type) {
    return h(
      OneRowString,
      {
        props: {
          value: this.getTranslate(`${services.tasks}.subheaders.${type}.lists`)
        }
      }
    )
  }
}
function renderRecipientsType(h, type) {
  return h(
    'div',
    {
      class: 'grid faic grid-gap--8'
    },
    [
      renderTypeHeader.call(this, h, type),
      renderRecipients.call(this, h, type)
    ]
  )
}
function renderAnalytics(h) {
  if (Object.keys(this.analytics).length) {
    return h(
      'div',
      {
        class: 'grid px-3'
      },
      Object.keys(this.analytics).map(type => {
        return renderRecipientsType.call(this, h, type)
      })
    )
  }
}
function renderHeader(h) {
  return h(
    HeadTitle,
    {
      props: {
        value: this.getTranslate(`${services.tasks}.titles.analytics`),
        slot: 'header'
      }
    }
  )
}

function renderDialogFooter(h) {
  return h(
    'div',
    {
      class: 'fiac fjcfe pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.close'),
            mode: buttonModes.flat
          },
          on: {
            click: () => {
              this.clearAnalytics()
              this.showDialog = false
            }
          }
        }
      )
    ]
  )
}
function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        maxWidth: 400
      },
      on: {
        input: event => {
          if (event === false) {
            this.clearAnalytics()
          }
          this.showDialog = event
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderAnalytics.call(this, h),
      renderDialogFooter.call(this, h)
    ]
  )
}

function renderListItem(h) {
  return h(
    'g-list-item',
    {
      props: {
        label: this.getTranslate(`${services.tasks}.labels.contactLists`),
        icon: 'quick_contacts_dialer',
        dense: true
      },
      on: {
        click: () => {
          this.analysis()
        }
      },
      scopedSlots: {
        dialog: () => {
          return renderDialog.call(this, h)
        }
      }
    }
  )
}

export default function(h) {
  if (this.value.type === TASK_TYPES['sendings:bulk']) {
    return renderListItem.call(this, h)
  }
}
