import { merge } from 'lodash'
import { BILLING_CURRENCY } from '@sigma-legacy-libs/essentials/lib/constants'

import { projectName } from '../constants'

const switchLocaleByCurrency = currency => {
  switch (currency) {
    // https://sigmasms.slack.com/archives/C03RRLZB8MP/p1704019412846779?thread_ts=1703955290.091869&cid=C03RRLZB8MP
    // case BILLING_CURRENCY.USD: return 'en-US'
    // case BILLING_CURRENCY.EUR: return 'de-DE'
    case BILLING_CURRENCY.RUB:
    default: return 'ru-RU'
  }
}

export const getCurrencyFromStorage = () => {
  try {
    return JSON.parse(window.localStorage.getItem(`${projectName}:settings:billings.currency`))
  } catch {
    return BILLING_CURRENCY.RUB
  }
}

export const getPrice = (value, params, locale) => {
  value = typeof value === 'string' ? Number(value) : value

  if (isNaN(value)) {
    return ''
  }
  const currency = params?.currency ?? getCurrencyFromStorage() ?? BILLING_CURRENCY.RUB
  const options = merge({
    currency,
    style: 'currency',
    currencyDisplay: 'symbol',
    currencySign: 'standard',
    minimumFractionDigits: 0,
    maximumFractionDigits: 4
  }, params)

  const { format } = new Intl.NumberFormat(locale ?? switchLocaleByCurrency(currency), options)

  return format(value)
}

export default {
  getPrice,
  getCurrencyFromStorage
}
