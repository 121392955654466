import FileItemList from '@/components/file/item/list'
import FileItemCard from '@/components/file/item/card'
import FileItemTable from '@/components/file/item/table'

function renderItemByType(h, item) {
  switch (this.type) {
    case 'cards': {
      return h(
        FileItemCard,
        {
          props: {
            value: item,
            readonly: this.readonly,
            currentChosenFileId: this.currentChosenFileId
          },
          on: {
            input: event => {
              this.$emit('input', event)
            },
            dialog: event => {
              this.$emit('dialog', event)
            }
          }
        }
      )
    }
    case 'list': {
      if (this.viewport.breakpoint.mdUp) {
        return h(
          FileItemTable,
          {
            props: {
              value: item,
              readonly: this.readonly,
              currentChosenFileId: this.currentChosenFileId
            },
            on: {
              input: event => {
                this.$emit('input', event)
              },
              dialog: event => {
                this.$emit('dialog', event)
              }
            }
          }
        )
      } else {
        return h(
          FileItemList,
          {
            props: {
              value: item,
              readonly: this.readonly,
              currentChosenFileId: this.currentChosenFileId
            },
            on: {
              input: event => {
                this.$emit('input', event)
              },
              dialog: event => {
                this.$emit('dialog', event)
              }
            }
          }
        )
      }
    }
  }
}

export default function(h) {
  switch (this.type) {
    case 'cards': {
      return h(
        'div',
        {
          class: this.$_class
        },
        this.items.map(item => {
          return renderItemByType.call(this, h, item)
        })
      )
    }
    case 'list': {
      if (this.viewport.breakpoint.mdUp) {
        return h(
          'g-table',
          {
            props: {
              value: this.order,
              items: this.items,
              headers: this.headers,
              noDataText: this.getTranslate('misc.no.data.text')
            },
            on: {
              input: event => {
                this.$emit('order', event)
              }
            },
            scopedSlots: {
              items: ({ item }) => {
                return renderItemByType.call(this, h, item)
              }
            }
          }
        )
      } else {
        return h(
          'g-list',
          {},
          this.items.map(item => {
            return renderItemByType.call(this, h, item)
          })
        )
      }
    }
  }
}
