import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'
import { invoiceStatues, statusColors } from '@/components/services/invoices/utils'

import render from './render'

export default {
  name: 'ButtonsModeration',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    view: {
      type: String,
      default: 'panel',
      validator: value => {
        return ~[ 'panel', 'form' ].indexOf(value)
      }
    },

    status: {
      type: String,
      default: 'paid',
      validator: value => {
        return invoiceStatues.includes(value)
      }
    }
  },

  data() {
    return {
      loading: {
        paid: false,
        rejected: false
      },

      showDialog: false
    }
  },

  computed: {
    isLoading() {
      for (const key in this.loading) {
        if (this.loading[key]) {
          return true
        }
      }

      return false
    }
  },

  methods: {
    async update() {
      if (~[ invoiceStatues.paid, invoiceStatues.rejected ].indexOf(invoiceStatues[this.status])) {
        try {
          this.loading[this.status] = true
          const { data } = await Vue.$GRequest.update(`/n/${services.invoices}`, this.value.id, { status: this.status })
          if (data.status === this.status) {
            this.addSnackbar({
              text: this.getTranslate(`${services.invoices}.snackbars.${this.status}`),
              type: statusColors[this.status]
            })
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.loading[this.status] = false
          this.showDialog = false
        }
      }
    }
  },

  render
}
