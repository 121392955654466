import { get } from 'lodash'

import { MODERATION_VALUES, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, inputModes, permissionPresets, prioritizeAccountInList, services } from '@/utils'

import info from '@/components/misc/info'
import OwnerSelect from '@/components/misc/OwnerSelect'
import ExpandArrow from '@/components/misc/ExpandArrow'
import SendingTypes from '@/components/misc/SendingTypes'
import ModerationStatusBarWithIcon from '@/components/misc/ModerationStatusBarWithIcon'
import requisites from '@/components/services/sendernames/form/requisites'

function renderIsActiveField(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.update`)) {
    return h(
      'div',
      {
        class: 'square--38 faic fjcc'
      },
      [
        h(
          'g-switch',
          {
            props: {
              value: this.proxy.isActive,
              tooltip: this.getTranslate(`${services.sendernames}.labels.isActive`)
            },
            on: {
              input: event => {
                this.proxy.isActive = event
              }
            }
          }
        )
      ]
    )
  }
}

function renderInfo(h) {
  if (this.isNew) {
    if (this.proxy.type === SENDING_TYPES.sms || this.proxy.type === SENDING_TYPES.voice) {
      return h(
        info,
        {
          props: {
            value: this.getTranslate(`${services.sendernames}.warnings.name.${this.proxy.type}`),
            icon: 'warning_amber',
            color: 'warning'
          }
        }
      )
    }
  }
}
function renderSenderNameField(h) {
  if (this.proxy.type) {
    return h(
      'div',
      {
        class: 'faifs grid-gap--8'
      },
      [
        h(
          'g-text-field',
          {
            props: {
              value: this.proxy.name,
              label: this.getTranslate(`${services.sendernames}.labels.name`),
              error: this.proxy.name && !this.senderNameIsValid ? this.getTranslate(`${services.sendernames}.errors.${this.proxy.type}`) : undefined,
              mode: inputModes['line-label'],
              disabled: !this.isNew && !this.checkPermissions(`advanced.${services.sendernames}.update`),
              dense: true,
              rounded: true,
              required: true,
              autofocus: true,
              mask: this.senderNameMask
            },
            on: {
              input: event => {
                this.proxy.name = event
              }
            }
          }
        ),

        renderIsActiveField.call(this, h)
      ]
    )
  }
}
function renderNameBlock(h) {
  return h(
    'div',
    {
      class: 'grid'
    },
    [
      renderInfo.call(this, h),
      renderSenderNameField.call(this, h)
    ]
  )
}

function renderModerationField(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.update`, permissionPresets.resellerUp)) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.moderation,
          label: this.getTranslate(`${services.sendernames}.labels.moderation`),
          items: MODERATION_VALUES.map(moderation => {
            return {
              title: this.getTranslate(`misc.moderation.${moderation}`),
              value: moderation
            }
          }),
          error: this.errors.moderation,
          mode: inputModes['line-label'],
          dense: true,
          rounded: true,
          required: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy.moderation = event
          }
        },
        scopedSlots: {
          item: ({ item }) => {
            return h(
              ModerationStatusBarWithIcon,
              {
                props: {
                  type: item.value,
                  title: item.title
                }
              }
            )
          }
        }
      }
    )
  }
}

function renderTypeField(h) {
  return h(
    SendingTypes,
    {
      props: {
        value: this.proxy.type,
        label: this.getTranslate(`${services.sendernames}.labels.type`),
        permission: `advanced.${services.sendernames}.get`,
        error: this.errors.type,
        mode: inputModes['line-label'],
        required: true
      },
      on: {
        input: event => {
          this.proxy.type = event
        }
      }
    }
  )
}

function renderCommentField(h) {
  return h(
    'g-textarea',
    {
      props: {
        value: this.proxy.meta.comment,
        label: this.getTranslate(`${services.sendernames}.labels.meta.comment`),
        hint: this.getTranslate(`${services.sendernames}.hints.meta.comment`),
        readonly: !this.checkPermissions(`advanced.${services.sendernames}.create`, [ 'me', 'reseller' ]),
        count: this.proxy.meta.comment === undefined ? 0 : this.proxy.meta.comment.length,
        mode: inputModes['line-label'],
        grow: true,
        dense: true,
        rounded: true,
        details: true,
        maxlength: 150,
        required: true
      },
      on: {
        input: event => {
          this.proxy.meta.comment = event
        }
      }
    }
  )
}

function renderWarning(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.create`, [ 'me', 'reseller' ])) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.sendernames}.warnings.meta.comment`),
          icon: 'warning_amber',
          color: 'warning'
        }
      }
    )
  }
}

function renderExampleComment(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.create`, [ 'me', 'reseller' ])) {
    return h(
      info,
      {
        props: {
          value: this.getTranslate(`${services.sendernames}.examples.meta.comment`),
          color: colors.info
        }
      }
    )
  }
}

function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        clearItems: prioritizeAccountInList
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

function renderAdvancedComment(h) {
  switch (true) {
    case this.checkPermissions(`advanced.${services.sendernames}.create`, 'reseller'): {
      return h(
        'div',
        {
          class: 'grid'
        },
        [
          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.resellerComment,
                label: this.getTranslate(`${services.sendernames}.labels.meta.resellerComment`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.resellerComment = event
                }
              }
            }
          ),

          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.resellerCommentPrivate,
                label: this.getTranslate(`${services.sendernames}.labels.meta.resellerCommentPrivate`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.resellerCommentPrivate = event
                }
              }
            }
          )
        ]
      )
    }
    case this.checkPermissions(`advanced.${services.sendernames}.create`): {
      return h(
        'div',
        {
          class: 'grid'
        },
        [
          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.adminComment,
                label: this.getTranslate(`${services.sendernames}.labels.meta.adminComment`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.adminComment = event
                }
              }
            }
          ),

          h(
            'g-textarea',
            {
              props: {
                value: this.proxy.meta.adminCommentPrivate,
                label: this.getTranslate(`${services.sendernames}.labels.meta.adminCommentPrivate`),
                mode: inputModes['line-label'],
                rounded: true,
                grow: true,
                details: false
              },
              on: {
                input: event => {
                  this.proxy.meta.adminCommentPrivate = event
                }
              }
            }
          )
        ]
      )
    }
  }
}

function renderAdvanced(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.create`, permissionPresets.resellerUp)) {
    return h(
      'g-expansion-panels',
      {
        props: {
          outline: true,
          rounded: true
        }
      },
      [
        h(
          'g-expansion-panel',
          {
            scopedSlots: {
              header: ({ expanded }) => {
                return h(
                  'div',
                  {
                    class: 'default-expansion-panel-header pr-0'
                  },
                  [
                    h('div', {}, this.getTranslate('misc.advanced')),
                    h('div', { class: 'ff' }),
                    h(ExpandArrow, { props: { expanded } })
                  ]
                )
              },
              default: () => {
                return h(
                  'div',
                  { class: 'grid pa-3' },
                  [
                    renderOwnerField.call(this, h),
                    renderAdvancedComment.call(this, h)
                  ]
                )
              }
            }
          }
        )
      ]
    )
  }
}

function renderRequisites(h) {
  if (!this.isNew) {
    if (this.checkPermissions('advanced.requisites.get', permissionPresets.resellerUp)) {
      return h(
        requisites,
        {
          props: {
            OwnerId: this.proxy.Owner && this.proxy.Owner.id || this.proxy.OwnerId,
            foreign: this.proxy.$foreign
          }
        }
      )
    }
  }
}

function renderComment(h, comment, { value, color }) {
  return h(
    info,
    {
      props: {
        value: comment,
        icon: value,
        color
      }
    }
  )
}

function renderCommentFromAdmin(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.get`, [ 'me', false ])) {
    const comment = get(this.value, 'meta.adminComment') || get(this.value, 'meta.resellerComment')
    if (comment) {
      return renderComment.call(this, h, comment, {
        value: 'error_outline',
        color: 'error'
      })
    }
  }
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid pa-3'
    },
    [
      renderNameBlock.call(this, h),
      renderModerationField.call(this, h),
      renderTypeField.call(this, h),
      renderCommentField.call(this, h),
      renderWarning.call(this, h),
      renderExampleComment.call(this, h),
      renderAdvanced.call(this, h),
      renderRequisites.call(this, h),
      renderCommentFromAdmin.call(this, h)
    ]
  )
}
