import { colors, getPrice, icons, permissionPresets, services } from '@/utils'
import { statusColors, statusIcons } from '@/components/services/invoices/utils'

import sticker from '@/components/misc/sticker'
import owner from '@/components/serviceTemplate/item/owner'
import date from '@/components/serviceTemplate/item/date'
import balance from '@/components/services/invoices/item/balance'
import buttons from '@/components/services/invoices/item/buttons'

function renderDocNumber(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        pathToTitle: 'docNumber',
        label: this.getTranslate(`${services.invoices}.labels.docNumber`),
        icon: statusIcons[this.value.status],
        color: statusColors[this.value.status],
        service: services.invoices
      }
    }
  )
}

function renderAmount(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.value.amount),
        label: this.getTranslate(`${services.invoices}.labels.amount`),
        color: colors.grey,
        iconSVG: this.globalSettings.billings.currency
      }
    }
  )
}

function renderRequisite(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      sticker,
      {
        props: {
          value: this.value.requisite || this.value.requisiteId,
          label: this.getTranslate(`${services.invoices}.labels.${services.requisites}`),
          icon: icons.feed,
          color: colors.grey,
          service: services.requisites,
          readonly: !this.checkPermissions(`advanced.${services.requisites}.get`, permissionPresets.managerUp)
        }
      }
    )
  }
}

function renderCompany(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      sticker,
      {
        props: {
          value: this.value.company || this.value.companyId,
          label: this.getTranslate(`${services.invoices}.labels.company`),
          icon: icons.work_outline,
          color: colors.grey,
          service: services.companies,
          readonly: !this.checkPermissions(`advanced.${services.companies}.get`, permissionPresets.managerUp)
        }
      }
    )
  }
}

function renderCustomer(h) {
  if (this.hasCustomer) {
    return h(
      owner,
      {
        props: {
          value: this.value,
          pathToData: 'customer',
          label: this.getTranslate(`${services.invoices}.labels.customer`),
          copy: true
        }
      }
    )
  }
}

function renderOwner(h) {
  if (this.hasOwner) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderBalance(h) {
  if (this.value.customerId) {
    return h(
      balance,
      {
        props: {
          UserId: this.value.customerId
        }
      }
    )
  }
}

function renderAdminInfo(h) {
  if (this.viewport.breakpoint.mdUp) {
    if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
      return h(
        'div',
        {
          class: 'grid grid-gap--8',
          style: { gridTemplateColumns: this.value.ownerId ? 'repeat(3, 150px)' : 'repeat(2, 150px)' }
        },
        [
          renderCustomer.call(this, h),
          renderOwner.call(this, h),
          renderBalance.call(this, h)
        ]
      )
    }
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: `${services.invoices}-panel__info`,
      style: { gridTemplateColumns: this.infoGridTemplateColumns }
    },
    [
      renderDocNumber.call(this, h),
      renderAmount.call(this, h),
      renderRequisite.call(this, h),
      renderCompany.call(this, h),
      renderAdminInfo.call(this, h)
    ]
  )
}

function renderButtons(h) {
  return h(
    buttons,
    {
      props: {
        value: this.value,
        view: 'panel'
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.xl) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${services.invoices}-panel`]: true,
        'service-template-panel hover-parent hover-parent--opacity': true
      },
      style: { gridTemplateColumns: this.panelGridTemplateColumns }
    },
    [
      renderInfo.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
