import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, get, merge } from 'lodash'

import { components, generateUniqueKey, globalErrorHandler, globalErrorProcessor, isUUID } from '@/utils'

import RowWithMessageTypeIcon from '@/components/misc/RowWithMessageTypeIcon'

const serviceName = 'sendernames'
const defaultFilter = {
  type: undefined,
  name: undefined,
  isActive: true,
  $limit: 25,
  $search: undefined,
  $scope: [ 'usable' ]
}

export default {
  name: 'SendernameSelect',

  mixins: [ proxy() ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    mode: {
      type: String,
      default: 'outline'
    },
    type: {
      type: String,
      default: undefined
    },
    required: {
      type: Boolean,
      default: true
    },

    itemValue: {
      type: String,
      default: 'name'
    },

    label: String,

    dense: Boolean,
    clearable: Boolean,
    loading: Boolean,
    disabled: Boolean,
    readonly: Boolean,
    forceAnySendername: Boolean
  },

  data() {
    return {
      checkLoading: false
    }
  },

  computed: {
    computedAnySenderName() {
      let result = false
      if (this.forceAnySendername) {
        result = true
      }

      const sendernamesCheck = get(this.globalSettings, 'sendings.sendernamesCheck')
      if (sendernamesCheck) {
        result = sendernamesCheck.indexOf(this.type) === -1
      }

      return result
    },

    computedLoading() {
      return this.loading || this.checkLoading
    }
  },

  watch: {
    type() {
      this.proxy = undefined
    }
  },

  methods: {
    async checkSender(items) {
      const itemsHasProxy = Array.isArray(items) && items.length && items.some(item => item.name === this.proxy)
      if (this.proxy && !this.computedAnySenderName && !itemsHasProxy && !isUUID(this.proxy)) {
        try {
          this.checkLoading = true

          const { data } = await Vue.$GRequest.find(serviceName, {
            query: merge(cloneDeep(defaultFilter), {
              name: this.proxy,
              type: this.type
            })
          })

          if (data.total === 0) {
            this.proxy = undefined
          } else {
            this.proxy = data.data[0].name
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.checkLoading = false
        }
      }
    }
  },

  render(h) {
    return h(
      components.select,
      {
        props: {
          value: this.proxy,
          label: this.label || this.getTranslate('sendings.labels.sender'),
          mode: this.mode,
          service: serviceName,
          query: merge(cloneDeep(defaultFilter), { type: this.type }),
          itemTitle: 'name',
          itemValue: this.itemValue,
          picks: [ 'id', 'name', 'type' ],
          error: this.errors.sender,
          loading: this.computedLoading,
          disabled: this.disabled || this.computedLoading,
          readonly: this.readonly,
          required: this.required,
          combobox: this.computedAnySenderName,
          clearable: this.clearable,
          dense: this.dense,
          rounded: true,
          setOnBlur: true,
          autocomplete: true,
          details: false
        },
        on: {
          input: event => {
            this.proxy = event
          },
          fetched: this.checkSender
        },
        scopedSlots: {
          selection: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.name,
                  dense: this.dense
                }
              }
            )
          },
          item: ({ item }) => {
            return h(
              RowWithMessageTypeIcon,
              {
                props: {
                  value: item.type,
                  title: item.name,
                  as: 'text'
                }
              }
            )
          }
        },
        key: generateUniqueKey()
      }
    )
  }
}
