import { cloneDeep } from 'lodash'

import { calculateDateRange } from '../misc'

export const minMax = {
  data() {
    return {
      firstDate: undefined
    }
  },

  computed: {
    minMax() {
      const result = {
        min: undefined,
        max: undefined
      }

      if (this.firstDate) {
        const { min, max } = calculateDateRange(this.firstDate)
        if (min && max) {
          result.min = min
          result.max = max
        }
      }

      return result
    }
  },

  watch: {
    'proxy.createdAt'(value) {
      const { $gt, $lt } = cloneDeep(value)
      const diff = Math.abs(Date.parse($gt) - Date.parse($lt))
      if (diff > 86400000) {
        this.firstDate = undefined
      }
    }
  }
}

export default { minMax }
