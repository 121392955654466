import render from './render'

export default {
  name: 'ButtonNotifications',

  data() {
    return {
      show: false
    }
  },

  render
}
