import { cloneDeep } from 'lodash'

import render from './render'

export default {
  name: 'ConfirmButton',

  props: {
    value: null,

    margin: {
      type: Object,
      default: () => {
        return {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0
        }
      }
    }
  },

  data() {
    return {
      initialData: undefined
    }
  },

  created() {
    this.initialData = cloneDeep(this.value)
  },

  render
}
