import { isArrayNotEmpty } from '@/utils'

const _ = { get: require('lodash/get') }

const types = [ 'phones', 'emails', 'websites' ]
const icons = {
  phones: 'phone',
  emails: 'email',
  websites: 'language'
}
const typeIcons = {
  fixed: 'business',
  mobile: 'smartphone'
}
const colors = {
  phones: 'primary',
  emails: 'success',
  websites: 'warning'
}

function renderItems(h, contactType) {
  const contacts = _.get(this.globalSettings, 'contacts', {})
  if (isArrayNotEmpty(contacts[contactType])) {
    return h(
      'div',
      {
        class: 'faifs'
      },
      [
        h(
          'g-icon',
          {
            class: 'mr-3 mtpx-6',
            props: {
              value: icons[contactType],
              color: 'grey'
            }
          }
        ),

        h(
          'div',
          {
            class: 'grid grid-cols--1'
          },
          contacts[contactType].map(({ value, type }) => {
            return h(
              'g-button',
              {
                class: 'ma-0',
                props: {
                  label: value,
                  icon: typeIcons[type],
                  rounded: true,
                  outline: true,
                  color: colors[contactType]
                },
                on: {
                  click: () => {
                    switch (contactType) {
                      case 'phones': {
                        window.location.href = `tel:${value}`
                        break
                      }
                      case 'emails': {
                        window.location.href = `mailto:${value}`
                        break
                      }
                      case 'websites': {
                        window.location.href = value.replace(/^(?:(?:https?:)?\/\/)?(.*?)$/, 'https://$1')
                        break
                      }
                    }
                  }
                }
              }
            )
          })
        )
      ]
    )
  }
}

function renderContent(h) {
  return h(
    'g-card',
    {
      class: 'grid grid-cols--1 pa-3',
      props: {
        rounded: true,
        maxWidth: 300
      }
    },
    types.map(type => {
      return renderItems.call(this, h, type)
    })
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
