export const SENDERNAMES_FILTER = {
  $search: undefined,
  $scope: [ 'Owner', 'meta' ],
  type: undefined,
  OwnerId: undefined,
  moderation: undefined,
  isActive: true
}

export default { SENDERNAMES_FILTER }
