import { EVENTSHISTORY_ASSOCIATED_SERVICES, EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'Panel',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    service() {
      const result = {
        title: `${this.value.service}.title`,
        name: this.value.service,
        single: `${this.value.service}.single`,
        entityId: this.value.data.id,
        createdAt: undefined,
        currentTab: undefined
      }

      switch (this.value.service) {
        case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
          result.title = `${services.eventsHistory}.services.contacts`
          result.name = 'contacts',
          result.single = 'contacts.single'
          result.entityId = this.value.data.ListId
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.contactLists: {
          result.name = 'contacts.single',
          result.single = 'contacts.single'
          result.entityId = this.value.data.id
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
          result.title = `${services.eventsHistory}.services.billingsUsers`
          result.name = 'billings'
          result.single = 'billings',
          result.currentTab = 'billings',
          result.createdAt = this.getCreatedAt()
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.tasks: {
          result.name = 'billings'
          result.single = 'billings',
          result.currentTab = this.value.service
          result.createdAt = this.getCreatedAt()
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.templates: {
          result.name = 'create',
          result.single = 'create'
          result.entityId = this.value.data.id
          break
        }
      }

      return result
    },

    computedTo() {
      if (EVENTSHISTORY_TYPES[this.value.type] !== 'removed') {
        return {
          name: this.service.single,
          params: {
            id: this.service.entityId,
            createdAt: this.service.createdAt
          },
          query: { currentTab: this.service.currentTab }
        }
      }
    }
  },

  methods: {
    getCreatedAt() {
      const gt = this.value.data.createdAt
      const lt = new Date(gt).setSeconds(new Date(gt).getSeconds() + 1)

      return {
        $gt: gt,
        $lt: new Date(lt).toISOString()
      }
    }
  },

  render
}
