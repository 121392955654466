import { serviceName } from '@/components/services/servers/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        icon: 'subtitles',
        color: 'grey',
        label: this.getTranslate(`${serviceName}.labels.title`),
        service: serviceName
      }
    }
  )
}
