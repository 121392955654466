import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import { colors, isObjectEmpty, sizes } from '@/utils'

import gTag from '@/components/tag'
import mccmnc from '@/components/trees/mccmnc/readonly'
import simpleTree from '@/components/trees/simple'
import mccmncValue from '@/components/trees/routes/mccmncValue'
import simpleValue from '@/components/trees/routes/simpleValue'
import servicesValue from '@/components/trees/routes/servicesValue'

function renderTitle(h) {
  return h(
    'div',
    {
      class: 'routes-conditions__title'
    },
    [
      h(
        gTag,
        {
          props: {
            label: this.getTranslate('conditions.titles.conditions'),
            size: sizes.tiny,
            color: colors.grey
          }
        }
      )
    ]
  )
}

function renderDialogContent(h) {
  const data = { props: { value: this.dialogData } }
  switch (this.dialogType) {
    case 'simple': return h(simpleTree, data)
    case 'mccmnc': return h(mccmnc, data)
  }
}
function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.showDialog,
        rounded: true,
        maxWidth: 500
      },
      on: {
        input: event => {
          if (event === false) {
            this.dialogData = undefined
            this.dialogType = undefined
          }
          this.showDialog = event
        }
      }
    },
    [
      h(
        'div',
        {
          class: 'pa-2'
        },
        [ renderDialogContent.call(this, h) ]
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                flat: true,
                rounded: true,
                label: this.getTranslate('misc.buttons.close')
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

function renderType(h, tag) {
  return h(
    'div',
    {
      class: 'routes-conditions__condition-type'
    },
    [ this.getTranslate(`conditions.tags.${tag}`) ]
  )
}

function renderValue(h, tag, value) {
  if (value) {
    if (Array.isArray(value) && value.length <= 0) {
      return
    }
    if (isObjectEmpty(value)) {
      return
    }

    switch (tag) {
      case 'text':
      case 'recipient': {
        return h(
          gTag,
          {
            props: {
              label: value,
              size: sizes.tiny
            }
          }
        )
      }

      case 'type':
      case 'group':
      case 'sender': {
        return h(
          simpleValue,
          {
            props: {
              value,
              count: this.viewport.breakpoint.mdUp ? 3 : this.viewport.breakpoint.sm ? 2 : 1
            },
            on: {
              click: () => {
                this.dialogData = value
                this.dialogType = 'simple'
                this.showDialog = true
              }
            }
          }
        )
      }

      case 'keywords':
      case 'contactList': {
        return h(
          servicesValue,
          {
            props: {
              value,
              count: 2,
              service: tag === 'contactList' ? 'contactLists' : 'keywordLists'
            },
            on: {
              click: () => {
                this.dialogData = value
                this.dialogType = 'simple'
                this.showDialog = true
              }
            }
          }
        )
      }

      case 'mccmnc': {
        return h(
          mccmncValue,
          {
            props: {
              value,
              count: 1
            },
            on: {
              click: () => {
                this.dialogData = value
                this.dialogType = 'mccmnc'
                this.showDialog = true
              }
            }
          }
        )
      }
    }
  }
}

function renderCondition(h, tag, value) {
  return h(
    'div',
    {
      class: 'routes-conditions__condition'
    },
    [
      renderType.call(this, h, tag),
      renderValue.call(this, h, tag, value)
    ]
  )
}

function renderConditions(h) {
  const conditions = this.value.reduce((result, { type, path, tag, value }) => {
    const tagMeta = Conditions.tagsMeta[tag || type || path]
    if (tagMeta) {
      result.push(renderCondition.call(this, h, tagMeta.tag, value))
    }

    return result
  }, [])

  return h(
    'div',
    {
      class: 'routes-conditions__items'
    },
    [ conditions ]
  )
}

export default function(h) {
  if (Array.isArray(this.value) && this.value.length) {
    return h(
      'div',
      {
        class: 'routes-conditions'
      },
      [
        renderTitle.call(this, h),
        renderConditions.call(this, h),
        renderDialog.call(this, h)
      ]
    )
  }
}
