import { types } from './sendings'

export default {
  title: 'Тарифы',

  tariff: 'Тариф',
  tariffs: 'Тарифы',
  new: 'Новый тариф',
  create: 'Создать новый тариф',
  activeAt: 'Активен с',
  cycle: 'Цикл',
  per: 'через',
  for: 'для',
  inherited: 'Наследовано из тарифа',
  withOperatorGroups: '(с группой оператора)',
  sendingId: 'ID отправления',
  conditions: 'Условия',
  customize: 'Изменить',
  unconditional: 'Безусловное правило',
  usersOnTariff: 'Пользователи на тарифе',
  addRule: 'Добавить правило',
  addSetConditions: 'Добавить группу условий',
  addAnotherSetConditions: 'Добавить ещё одну группу условий',
  always: 'Всегда',
  otherCases: 'В остальных случаях',
  choosePath: '<Выберите, что проверять>',
  chooseCondition: '<Укажите, как проверять>',
  matchesWith: 'Совпадает с',
  matchesWithRegex: 'регулярным выражением',
  matchesWithMultiple: 'одним из значений',
  operator: 'ни одного оператора | один оператор | {count} оператора(ов)',
  sender: 'ни одного имени отправителя | одно имя отправителя | {count} имени отправителя',

  titles: {
    similar: 'Похожий тариф'
  },

  contents: {
    similar: 'Тариф с таким названием уже существует. Если вы всё равно хотите создать дубль тарифа, то нажмите "Создать". В противном случае нажмите "Закрыть".'
  },

  sources: {
    ...types,

    apisender: 'Подписка API sender',
    hlr: 'HLR'
  },

  add: {
    tariff: 'Добавить тариф'
  },

  cycles: {
    month: 'Месяц',
    week: 'Неделя',
    day: 'День',
    trigger: 'Trigger'
  },

  labels: {
    tariff: 'Тариф',
    title: 'Заголовок',
    source: 'Источник',
    cycle: 'Цикл',
    cycleStart: 'Начало цикла',
    price: 'Цена',
    comment: 'Комментарий',
    owner: 'Владелец',
    activePeriod: {
      start: 'Стартовая дата',
      end: 'Конечная дата'
    },
    actions: 'Действие',
    conditions: {
      path: 'Путь',
      comparator: 'Сравнение',
      value: 'Значение',
      negate: 'Отрицание',
      multiple: 'Выберите из списка',
      regex: 'Впишите регулярное выражение'
    },
    refundOnFailed: 'Возместить при неудаче',
    noPayments: 'Может быть бесплатным'
  },

  tabs: {
    eventsHistory: 'История изменений'
  },

  dialogs: {
    new: 'Новый тариф'
  },

  subheaders: {
    rules: 'Правила',
    conditions: 'Условия',
    activePeriod: {
      title: 'Активный период',
      start: 'Начало активного периода',
      end: 'Конец активного периода'
    },
    cycleStart: 'Начало цикла'
  },

  actions: {
    decrease: 'Списать',
    increase: 'Начислить',
    main: {
      decrease: 'Списывать',
      increase: 'Начислять'
    }
  },

  buttons: {
    add: {
      tariff: 'Добавить тариф',
      rule: 'Добавить правило',
      condition: 'Добавить условие'
    },
    clone: {
      divided: '/ 100'
    }
  },

  tooltips: {
    empty: {
      condition: 'Условие заполнено не полностью'
    },
    custom: 'Кастомизированный'
  },

  snackbars: {
    created: 'Тариф успешно создан.',
    updated: 'Тариф успешно обновлён.',
    removed: 'Тариф успешно удалён.'
  },

  hints: {
    default: 'За сообщение',
    perCall: 'За сегмент',
    perSecond: 'За секунду',
    perPeriod: 'За период'
  }
}
