import { SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

export default {
  name: 'DefaultRules',

  props: {
    rules: {
      type: Array,
      default: () => []
    },

    defaultRule: {
      type: Object,
      default: () => ({})
    },

    source: String
  },

  computed: {
    priceHint() {
      const result = []
      switch (this.source) {
        case 'apisender': {
          result.push(this.getTranslate('tariffs.hints.perPeriod'))
          break
        }
        case SENDING_TYPES.voice: {
          result.push(this.getTranslate('tariffs.hints.perCall'), this.getTranslate('tariffs.hints.perSecond'))
          break
        }
        default: {
          result.push(this.getTranslate('tariffs.hints.default'))
        }
      }

      return result
    }
  },

  render
}
