import owner from '@/components/serviceTemplate/item/owner'

export default function(h) {
  if (this.viewport.size.width >= 640) {
    return h(
      owner,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
