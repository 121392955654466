import { upgradeArray } from '@sigma-legacy-libs/essentials/lib/utils'

import { colors } from '@/utils'

export const serviceName = 'billings'

export const actions = upgradeArray([ 'addFunds', 'charge' ])

export const amountColors = {
  accrual: colors.success,
  subtract: colors.error
}

export const amountIcons = {
  accrual: 'add',
  subtract: 'remove'
}

export default {
  serviceName,
  actions
}
