export default {
  title: 'Быстрый платёж',

  labels: {
    amount: 'Сумма',
    method: 'Метод'
  },

  methods: {
    v1: {
      tinkoff: 'Тинькофф'
    }
  },

  hints: {
    commission: 'Комиссия банка: 3%',
    commissions: 'Комиссия'
  },

  errors: {
    owner: 'Для оплаты в ссылке должен быть указан ID получателя.'
  }
}
