import EntityTable from '@/components/entity/table'
import SourceBox from '@/components/source/box'

function renderDataSourceBox(h) {
  const data = this.value.data
  if (data && Object.keys(data).length) {
    return h(
      'div',
      { class: 'px-3' },
      [
        h(
          SourceBox,
          {
            props: {
              value: data,
              rounded: true
            }
          }
        )
      ]
    )
  }
}

function renderEntityTable(h) {
  return h(
    EntityTable,
    {
      props: {
        rows: this.rows
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid grid-gap--8 py-3'
    },
    [
      renderEntityTable.call(this, h),
      renderDataSourceBox.call(this, h)
    ]
  )
}

