import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Options',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    hasOnStatus() {
      const onStatusIsArray = Array.isArray(this.proxy.onStatus)

      return onStatusIsArray ||
        this.readonly &&
        onStatusIsArray &&
        this.proxy.onStatus.length > 0
    },
    hasOnTimeout() {
      return this.proxy.onTimeout ||
        this.readonly &&
        this.proxy.onTimeout &&
        this.proxy.onTimeout.timeout
    }
  },

  render
}
