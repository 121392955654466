import { sizes } from '@/utils'

const className = 'switcher'

function renderIcon(h) {
  return h(
    'g-icon',
    {
      props: {
        value: this.icons[this.status],
        size: this.size === sizes.small ? 14 : 18
      }
    }
  )
}

function renderLabel(h) {
  return h(
    'div',
    {
      class: `${className}__label`
    },
    [ this.labels[this.status] ]
  )
}

function renderValueByType(h) {
  if (Object.keys(this.labels).length) {
    return renderLabel.call(this, h)
  }

  return renderIcon.call(this, h)
}
function renderValue(h) {
  return h(
    'div',
    {
      class: `${className}__value`
    },
    [ renderValueByType.call(this, h) ]
  )
}
function renderThumb(h) {
  return h(
    'div',
    { class: `${className}__thumb` }
  )
}
function renderRail(h) {
  return h(
    'div',
    {
      class: {
        [`${className}__rail`]: true,
        [`${className}__rail--${this.status}`]: true
      }
    },
    [
      renderValue.call(this, h),
      renderThumb.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      attrs: this.$attrs,
      class: this.$class,
      directives: this.$directives,
      on: {
        click: () => {
          this.toggle()
        }
      }
    },
    [ renderRail.call(this, h) ]
  )
}
