import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'MessageProcessing',

  mixins: [ proxy({ type: 'object' }) ],

  render
}
