import Vue from 'vue'
import pkg from '../../package.json'

import { getLocaleDateString, getLocaleTimeString, services } from '@/utils'

import titleDivider from '@/components/title/divider'

const className = 'debug'

function renderDivider(h, path) {
  if (path) {
    return h(
      titleDivider,
      {
        props: {
          value: this.getTranslate(`${className}.titles.${path}`)
        }
      }
    )
  }

  return h('g-divider')
}

function renderLabel(h, path) {
  return h(
    'div',
    {
      class: `${className}__label`
    },
    this.getTranslate(`${className}.labels.${path}`)
  )
}
function renderTitle(h, path) {
  return h(
    'div',
    {
      class: `${className}__label`
    },
    this.getTranslate(`${className}.titles.${path}`)
  )
}
function renderWsConnection(h, value) {
  return h(
    'div',
    {
      class: `text--${value ? 'success' : 'error'}`
    },
    this.getTranslate(`${className}.ws.${value ? 'connected' : 'disconnected'}`)
  )
}
function renderValueByPath(h, value, path) {
  switch (path) {
    case 'ws.connection': return renderWsConnection.call(this, h, value)
    default: return value
  }
}
function renderValue(h, value, path) {
  return h(
    'div',
    {
      class: `${className}__value`
    },
    [ renderValueByPath.call(this, h, value, path) ]
  )
}
function renderRow(h, path, value) {
  return h(
    'div',
    {
      class: `${className}__row`
    },
    [
      renderLabel.call(this, h, path),
      renderValue.call(this, h, value, path)
    ]
  )
}
function renderTitleRow(h, path) {
  return h(
    'div',
    {
      class: `${className}__row`
    },
    [ renderTitle.call(this, h, path) ]
  )
}

export default {
  name: 'Debug',

  metaInfo: {
    title: 'debug.title'
  },

  data() {
    return {
      ws: {
        connected: Vue.$socket.connected,
        error: undefined
      }
    }
  },

  mounted() {
    Vue.$socket.on('connect', () => Vue.set(this.ws, 'connected', true))
    Vue.$socket.on('disconnect', () => Vue.set(this.ws, 'connected', false))
    Vue.$socket.on('error', error => Vue.set(this.ws, 'error', error))
  },

  render(h) {
    return h(
      'div',
      {
        class: className
      },
      [
        renderRow.call(this, h, 'version', pkg.version),
        renderRow.call(this, h, 'frontend.title', this.globalSettings.frontend.title),
        renderDivider.call(this, h),
        renderRow.call(this, h, 'date', getLocaleDateString(new Date()) + ', ' + getLocaleTimeString(new Date())),
        renderDivider.call(this, h),
        renderTitleRow.call(this, h, 'browser'),
        renderRow.call(this, h, 'viewport', this.viewport.size.height + 'x' + this.viewport.size.width),
        renderRow.call(this, h, 'browser.name', this.browser.name),
        renderRow.call(this, h, 'browser.version', this.browser.version),
        renderDivider.call(this, h),
        renderTitleRow.call(this, h, 'site.address'),
        renderRow.call(this, h, 'protocol', window.location.protocol.replace(':', '')),
        renderRow.call(this, h, 'host', window.location.host),
        renderDivider.call(this, h),
        renderTitleRow.call(this, h, 'ws'),
        renderRow.call(this, h, 'ws.connection', this.ws.connected),
        renderRow.call(this, h, 'ws.error', this.ws.error),
        renderDivider.call(this, h),
        renderTitleRow.call(this, h, 'account'),
        renderRow.call(this, h, 'account.username', this.account.username),
        renderRow.call(this, h, 'account.balance', this.account.balance),
        renderRow.call(this, h, 'account.id', this.account.id),
        renderRow.call(this, h, 'account.sendingTypes', this.sendingTypes.map(type => this.getTranslate(`${services.sendings}.types.${type}`)).join(', '))
      ]
    )
  }
}
