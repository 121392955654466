import Vue from 'vue'

import { debounce, uniqueId } from 'lodash'

import { currentTab, getCreatedAtByPreset, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: services.sendingsModeration,

  mixins: [ currentTab() ],

  data() {
    return {
      filter: {
        OwnerId: undefined,
        pattern: undefined,
        sender: undefined,
        range: {
          $gt: undefined,
          $lt: undefined
        }
      },

      preset: 'today',

      users: [],
      sendings: [],

      interval: undefined,

      usersLoading: false,
      sendingsLoading: false
    }
  },

  computed: {
    tabs() {
      const result = []

      if (this.checkPermissions('advanced.sendings.find', permissionPresets.true)) {
        result.push('sendings')
      }
      if (this.checkPermissions('advanced.sendingsIncoming.get', permissionPresets.true)) {
        result.push('tasks')
      }

      return result
    }
  },

  watch: {
    filter: {
      handler() {
        this.getSendings()
      },
      deep: true
    },

    'filter.range'() {
      this.getUsers()
    },

    preset() {
      this.processFilter()
    }
  },

  mounted() {
    this.processFilter()
    this.interval = setInterval(() => {
      this.getUsers()
    }, 15 * 1000)

    this.currentTab = this.tabs[0]
  },

  beforeDestroy() {
    clearInterval(this.interval)
  },

  methods: {
    async getUsers() {
      if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`, permissionPresets.true)) {
        try {
          this.usersLoading = true
          const { data } = await Vue.$GRequest.find(services.sendingsModeration + '/sendings', {
            query: {
              range: this.filter.range,
              t: Date.now()
            }
          })
          if (data) {
            this.users = data
          }
        } catch (error) {
          globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
        } finally {
          this.usersLoading = false
        }
      }
    },

    getSendings: debounce(
      async function() {
        if (this.checkPermissions(`advanced.${services.sendingsModeration}.get`, permissionPresets.true)) {
          this.sendingsLoading = true
          if (this.filter.OwnerId) {
            try {
              const { data } = await Vue.$GRequest.find(services.sendingsModeration + '/sendings', {
                query: Object.assign({}, this.filter, { t: Date.now() })
              })
              if (data) {
                this.sendings = data.map((item, index) => {
                  item.uniqueKey = uniqueId(`${services.sendingsModeration}-${index}-`)

                  return item
                })
              }
            } catch (error) {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
          this.sendingsLoading = false
        }
      },
      500,
      {
        leading: false,
        trailing: true
      }
    ),

    processFilter() {
      if (this.preset) {
        this.filter.range = getCreatedAtByPreset(this.preset)
      }
    }
  },

  render
}
