export const serviceName = 'providers'

export const stateColors = {
  active: 'success',
  inactive: 'grey',
  connecting: 'primary',
  disconnecting: 'error',
  stopped: 'warning',
  'half-open': 'info',
  'error-code': 'error',
  'error-connection': 'error'
}
export const stateIcons = {
  active: 'check_circle_outline',
  inactive: 'pause_circle_outline',
  connecting: 'sync',
  disconnecting: 'highlight_off',
  stopped: 'do_not_disturb_on',
  'half-open': 'access_time',
  'error-code': 'error_outline',
  'error-connection': 'error_outline'
}

export default {
  serviceName,
  stateColors,
  stateIcons
}
