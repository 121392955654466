import { mapActions } from 'vuex'

const _ = { get: require('lodash/get') }

export const metaInfo = {
  metaInfo() {
    return {
      titleTemplate: titleChunk => {
        const result = [ _.get(this.globalSettings, 'frontend.title') ]

        if (titleChunk) {
          result.push(this.getTranslate(titleChunk))
        }

        return result.filter(v => !!v).join(' | ')
      }
    }
  },

  watch: {
    globalSettings: {
      handler() {
        this.$meta().refresh()
        this.setFrontendColors()
      },
      deep: true
    }
  },

  async mounted() {
    await this.getGlobalSettings()
    this.setFrontendColors()
  },

  methods: {
    ...mapActions({ getGlobalSettings: 'settings/getGlobalSettings' }),

    setFrontendColors() {
      const root = document.documentElement

      let overrideColors = {}

      if (this.isPlatinum) {
        overrideColors = {
          primary: '#607D8B',
          accent: '#CFD8DC',
          secondary: '#904e95'
        }
      }

      const colors = _.get(this.globalSettings, 'frontend.colors', {})
      for (const color in colors) {
        root.style.setProperty(`--${color}`, overrideColors[color] || colors[color])
      }
    }
  }
}

export default { metaInfo }
