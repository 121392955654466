function renderSearch(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.search,
        label: this.getTranslate('commons.labels.search'),
        afterIcon: 'search',
        mode: 'outline',
        dense: true,
        rounded: true,
        clearable: true,
        details: false
      },
      on: {
        input: event => {
          this.search = event
        }
      }
    }
  )
}

function renderHeader(h) {
  return h(
    'div',
    {
      class: 'faic ff'
    },
    [ renderSearch.call(this, h) ]
  )
}

function renderItem(h, item) {
  if (typeof item === 'string' && item) {
    return h(
      'div',
      {
        class: 'tree__row'
      },
      [
        h(
          'div',
          {
            class: 'tree__content'
          },
          [
            h(
              'div',
              {
                class: 'tree__title tree__title'
              },
              item
            )
          ]
        )
      ]
    )
  }
}

function renderList(h) {
  if (Array.isArray(this.values)) {
    if (this.values.length) {
      return h(
        'div',
        {
          class: 'tree__level tree__level--1'
        },
        this.values.map(item => renderItem.call(this, h, item))
      )
    } else {
      return h(
        'div',
        {
          class: 'tree__empty'
        },
        [ h('g-empty') ]
      )
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    [
      renderHeader.call(this, h),
      renderList.call(this, h)
    ]
  )
}
