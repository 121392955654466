export default {
  title: 'Неактивные провайдеры',

  titles: {
    fix: 'Создание дублей неактивных провайдеров',
    confirm: {
      update: 'Подтвердите обновление',
      cloneProvider: 'Подтвердите создание дубля'
    }
  },

  labels: {
    update: {
      check: 'Я прочитал и понял текст подтверждения и хочу обновить список неактивных провайдеров',
      showUpdateDialog: 'Я осознаю что делаю, не показывайте мне больше этот диалог. Запомним только для этого браузера.'
    }
  },

  contents: {
    confirm: {
      update: 'Вы уверены, что хотите обновить список неактивных провайдеров?<br><br>Текущий список будет сброшен и будет совершён ряд новых запросов для формирования нового списка, что может занять некоторое время и негативно сказаться на производительности платформы. Делайте этот запрос не чаще одного раза в 5 минут.<br><br>Если что-то не срабатывает или вам кажется что работает неправильно, обратитесь к разработчикам, не продолжайте делать запросы.',
      cloneProvider: 'Будет создан клон провайдера, со всеми свойствами, но с другим названием. С текущим провайдером никаких действий совершено не будет. После создания будет осуществлён автоматический переход в карточку нового провайдера.'
    }
  },

  tooltips: {
    fix: 'После нажатия на эту кнопку будет создан клон выбранного провайдера.<br>Будут скопированы все свойства старого провайдера, название, сервер, учетные данные и так далее.<br>К названию будет приписан префикс (1), если его ещё не было или же увеличен номер префикса если уже был.<br>Новый провайдер будет создан сразу активированный (isActive === true).<br><br>Провайдер из которого создавался клон будет деактивирован (isActive = false) и удалён из группы провайдеров.<br>На место старого провайдера, в группу провайдеров с тем же приоритетом будет вставлен новый провайдер.',
    cloneProvider: 'Будет создан клон провайдера, со всеми свойствами, но с другим названием. С текущим провайдером никаких действий совершено не будет. После создания будет осуществлён автоматический переход в карточку нового провайдера.'
  },

  warnings: {
    general: 'Это страница поиска групп провайдеров с неактивными провайдерами внутри. Страница экспериментальная!<br>Все кнопки на этой странице так или иначе совершают очень сложные и тяжелые запросы к платформе, поэтому, пожалуйста, не нажимайте на них чаще одной чашки кофе вместе с походом до кофемашины.<br>Если что-то не срабатывает или вам кажется что работает неправильно, обратитесь к разработчикам, не продолжайте делать запросы.'
  }
}
