import { inputModes, services } from '@/utils'

import OwnerSelect from '@/components/misc/OwnerSelect'
import buttonCopy from '@/components/button/copy'

function renderTokenField(h) {
  if (!this.isNew) {
    return h(
      'div',
      {
        class: `${services.tokens}-form__token`,
        style: { gridTemplateColumns: '1fr auto' }
      },
      [
        h(
          'g-text-field',
          {
            props: {
              value: this.proxy.token,
              label: this.getTranslate(`${services.tokens}.labels.token`),
              mode: inputModes['line-label'],
              dense: true,
              rounded: true,
              details: false,
              disabled: true
            }
          }
        ),
        h(
          buttonCopy,
          {
            props: {
              value: this.proxy.token
            }
          }
        )
      ]
    )
  }
}

function renderDescriptionField(h) {
  return h(
    'g-textarea',
    {
      props: {
        value: this.proxy.description,
        label: this.getTranslate(`${services.tokens}.labels.description`),
        hint: this.getTranslate(`${services.tokens}.hints.description`),
        mode: inputModes['line-label'],
        maxlength: 250,
        count: this.proxy.description ? this.proxy.description.length : 0,
        rounded: true,
        dense: true,
        grow: true,
        clearable: true,
        details: true,
        autofocus: true,
        mask: /^.{0,250}$/
      },
      on: {
        input: event => {
          this.proxy.description = event
        }
      }
    }
  )
}

function renderOwnerField(h) {
  return h(
    OwnerSelect,
    {
      props: {
        value: this.proxy.OwnerId,
        details: false,
        dense: true
      },
      on: {
        input: event => {
          this.proxy.OwnerId = event
        }
      }
    }
  )
}

export default function(h) {
  return h(
    'g-form',
    {
      class: `${services.tokens}-form`
    },
    [
      renderTokenField.call(this, h),
      renderDescriptionField.call(this, h),
      renderOwnerField.call(this, h)
    ]
  )
}
