export default {
  title: 'Storage',

  titles: {
    locks: 'Locks',
    properties: 'File properties',
    editLocks: 'Edit locks'
  },

  headers: {
    title: 'Title',
    owner: 'Owner',
    size: 'Size',
    createdAt: 'Created at',
    expiredAt: 'Expired at'
  },

  labels: {
    title: 'Title'
  },

  hints: {
    emptyState: 'No any files found.',
    expire: 'This is a temporary storage. All files have a retention period and will be deleted when it is reached.',
    export: 'Please wait, your PDF file is being generated',
    locks:
      'This file is used in one or several services and will not be deleted automatically after the expiration of the storage period.',
    editData: 'Edit source data of file',
    editMeta: 'Edit metadata of file'
  },

  prefixes: {
    id: 'ID',
    filename: 'Filename',
    size: 'Size',
    owner: 'Owner',
    locked: 'Locked',
    locks: 'Locked in the following services',
    hash: 'Hash',
    duration: 'Duration',
    channels: 'Channels',
    sampleRate: 'Sample Rate',
    bitsPerSample: 'BitsPerSample',
    willBeDeleted: 'File will be deleted',
    createdAt: 'Created at',
    updatedAt: 'Updated at',
    expired: 'Expired at',
    type: 'File type'
  },

  buttons: {
    setExpiredAt: 'Set expired at',
    editLocks: 'Edit locks',
    properties: 'Properties'
  },

  snackbars: {
    created: 'File successfully created.',
    updated: 'File successfully updated.',
    removed: 'File successfully removed.'
  }
}
