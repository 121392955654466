import SourceBox from '@/components/source/box'

import EventsHistoryTab from '@/components/services/eventsHistory/tab'

import { serviceName } from '@/components/services/sendings/utils'

function renderDataSourceTab(h) {
  if (this.value) {
    return h(
      SourceBox,
      {
        props: {
          value: this.value,
          rounded: false
        },
        key: 'source'
      }
    )
  }
}

function renderEventsHistoryTab(h) {
  return h(
    EventsHistoryTab,
    {
      props: {
        entityId: this.value.id
      },
      key: 'eventsHistory'
    }
  )
}

function renderDialog(h) {
  return h(
    'g-dialog',
    {
      props: {
        rounded: true,
        value: this.showDialog,
        maxWidth: this.viewport.breakpoint.mdUp ? 1000 : '100%'
      },
      on: {
        input: event => {
          if (event === false) {
            this.$emit('close', true)
          }
          this.showDialog = event
        }
      }
    },
    [
      h(
        'g-tabs-header',
        {
          props: {
            value: this.currentTab,
            tabs: this.tabs.map(tab => {
              return {
                key: tab,
                title: this.getTranslate(`${serviceName}.tabs.${tab}`)
              }
            })
          },
          on: {
            input: event => {
              this.currentTab = event
            }
          },
          slot: 'header'
        }
      ),

      h(
        'g-tabs',
        {
          props: { value: this.currentTab },
          scopedSlots: {
            body: () => [
              renderDataSourceTab.call(this, h),
              renderEventsHistoryTab.call(this, h)
            ]
          }
        }
      ),

      h(
        'g-card-actions',
        {
          class: 'fjcfe facfe',
          slot: 'footer'
        },
        [
          h(
            'g-button',
            {
              props: {
                label: this.getTranslate('misc.buttons.close'),
                flat: true,
                rounded: true
              },
              on: {
                click: () => {
                  this.showDialog = false
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  return renderDialog.call(this, h)
}
