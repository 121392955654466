import { get } from 'lodash'

import render from './render'

export default {
  name: 'Amount',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    $value() {
      const result = {}
      result.source = get(this.value, 'data.source')
      result.amount = get(this.value, 'data.amount')

      return result
    }
  },

  render
}
