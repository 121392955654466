import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Dialog',

  mixins: [ proxy() ],

  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },

  render
}
