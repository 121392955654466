import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep } from 'lodash'

import render from './render'

const defaultRule = { conditions: [] }

export default {
  name: 'TariffsRulesGroup',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    target: {
      type: Array,
      default: () => []
    },
    index: Number,
    source: String
  },

  data() {
    return {
      removeIcon: false
    }
  },

  computed: {
    hasRules() {
      return this.proxy && Array.isArray(this.proxy.rules) && this.proxy.rules.length
    }
  },

  methods: {
    _inputFilter(data) {
      if (data.rules.length === 0) {
        data.rules.push(cloneDeep(defaultRule))
      }

      return data
    },
    addConditions() {
      this.proxy.rules.push(cloneDeep(defaultRule))
    }
  },

  render
}
