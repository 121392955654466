import { eventHandlers } from '../../../utils/mixins'

import { colors, numberToPxOrString } from '@/utils'

import render from './render'

export default {
  name: 'OverflowString',

  mixins: [ eventHandlers ],

  props: {
    value: {
      type: [ Number, String, Boolean ],
      default: undefined
    },

    color: {
      type: String,
      default: undefined,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    tooltip: {
      type: String,
      default: undefined
    },

    font: {
      type: Object,
      default: () => {
        return {
          size: 11,
          weight: 400
        }
      }
    },

    showTooltip: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      overflow: false,
      directives: undefined
    }
  },

  computed: {
    $class() {
      return {
        ['overflow-string']: true,
        [`overflow-string--${this.color}`]: !!this.color
      }
    }
  },

  watch: {
    overflow() {
      this.setProperties()
      this.setTooltip()
    }
  },

  mounted() {
    this.checkOverflow()
    this.setProperties()
    this.setTooltip()
  },

  updated() {
    this.checkOverflow()
  },

  beforeDestroy() {
    this.directives = undefined
  },

  methods: {
    checkOverflow() {
      if (this.$el) {
        const resizeObserver = new ResizeObserver(entries => {
          for (const entry of entries) {
            window.requestAnimationFrame(() => {
              if (entry.target.clientHeight > 0) {
                this.overflow = true
              } else {
                this.overflow = false
              }
            })
          }
        })
        resizeObserver.observe(this.$el)
      }
    },

    setProperties() {
      if (this.$el) {
        this.$el.style.fontSize = numberToPxOrString(this.font.size)
        this.$el.style.fontWeight = this.font.weight
        this.$el.style.lineHeight = numberToPxOrString(this.font.lineHeight || this.font.size + 2)
      }
    },

    setTooltip() {
      if (this.showTooltip) {
        if (parseInt(this.$el.offsetWidth) < parseInt(this.$el.scrollWidth)) {
          this.directives = [
            {
              name: 'g-tooltip',
              options: {
                value: this.tooltip || this.value,
                placement: 'top'
              }
            }
          ]
        }
      }
    }
  },

  render
}
