import { buttonModes, colors, components, sizes } from '@/utils'

import render from './render'

export default {
  name: 'ButtonCopy',

  props: {
    value: [ String, Number ],

    mode: {
      type: String,
      default: buttonModes.flat,
      validator: value => {
        return Object.values(buttonModes).includes(value)
      }
    },

    size: {
      type: String,
      default: sizes.medium,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    },

    color: {
      type: String,
      default: colors.primary,
      validator: value => {
        return Object.values(colors).includes(value)
      }
    },

    icon: String,
    iconSVG: {
      type: String,
      default: 'copy'
    },
    iconLibrary: String,

    tooltip: String,

    small: Boolean,
    large: Boolean,
    marginless: Boolean,

    label: String,

    view: {
      type: String,
      default: 'btn',
      validator: value => {
        return [ 'btn', components['list-item'] ].includes(value)
      }
    },

    snackbar: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    iconSize() {
      return this.small ? 16 : this.large ? 22 : 18
    }
  },

  methods: {
    clickHandler() {
      navigator.clipboard.writeText(this.value)
      this.addSnackbar(this.snackbar)
    }
  },

  render
}
