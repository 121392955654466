import { get } from 'lodash'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'Comments',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    commentFromAdminOrReseller() {
      return get(this.value, 'meta.adminComment') || get(this.value, 'meta.resellerComment')
    },

    showCommentFromAdminOrReseller() {
      let result = false

      if (this.commentFromAdminOrReseller) {
        result = true
      }

      if (this.checkPermissions(`advanced.${services.sendernames}.get`, [ false, 'me' ])) {
        result = true
      }

      return result
    }
  },

  render
}
