import { dateFilter, toIntegerOrUndefined } from '@/utils'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ dateFilter ],

  computed: {
    gridTemplateColumns() {
      switch (true) {
        case this.viewport.breakpoint.smDown: return '1fr'
        case this.viewport.breakpoint.mdDown: return 'repeat(2, 1fr)'
        case this.viewport.breakpoint.lgUp: return 'repeat(2, 260px) auto'
      }
    }
  },

  methods: {
    _outputFilter(result) {
      result.docNumber = toIntegerOrUndefined(result.docNumber)

      return result
    }
  },

  render
}
