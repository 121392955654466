import { icons } from '@/utils'

import publicDocument from '@/components/services/settings/publicDocument'

export default function(h, { path, serviceName }) {
  return h(
    publicDocument,
    {
      props: {
        value: this.proxy,
        title: this.getTranslate(`${serviceName}.labels.${path}`),
        hint: this.getTranslate(`${serviceName}.hints.${path}`),
        icon: icons.policy
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
