import proxy from '@sigma-legacy-libs/g-proxy'
import Fallbacks from '@sigma-legacy-libs/essentials/lib/utils/Fallbacks'

import { cloneDeep, defaultsDeep, isEqual } from 'lodash'
import { SENDINGS_FALLBACKS_OPTIONS_CLEAN } from '@sigma-legacy-libs/essentials/lib/constants'

import render from './render'

const defaultValue = {
  type: undefined,
  payload: {
    sender: undefined,
    text: undefined
  },
  $options: SENDINGS_FALLBACKS_OPTIONS_CLEAN
}

export default {
  name: 'GeneratorFallbacks',

  mixins: [ proxy({ type: 'array' }) ],

  methods: {
    _inputFilter(data) {
      data = cloneDeep(data)
      if (!Array.isArray(data)) {
        data = []
      }
      if (data.length === 0) {
        data.push(cloneDeep(defaultValue))
      }

      return data.map(item => defaultsDeep(item, cloneDeep(defaultValue)))
    },
    _outputFilter(data) {
      return Fallbacks.clean(data)
    },

    add() {
      this.proxy.push(cloneDeep(defaultValue))
    },
    remove(index) {
      this.proxy.splice(index, 1)
      if (this.proxy.length === 0) {
        this.add()
      }
    },

    transmitValue() {
      const proxy = this._outputFilter(this.proxy)
      if (!isEqual(proxy, this.value)) {
        this.$emit('input', proxy)
      }
    }
  },

  render
}
