import proxy from '@sigma-legacy-libs/g-proxy'

import { currentTab, permissionPresets, services } from '@/utils'

import render from './render'

export default {
  name: 'UsersForm',

  mixins: [ proxy({ type: 'object' }), currentTab('general') ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: Boolean
  },

  data() {
    return {
      showDocumentTemplates: false,

      editable: false,
      passwordVisibility: true,

      currentType: 'sms',
      currentItem: undefined
    }
  },

  computed: {
    tabs() {
      const result = [ 'general' ]

      if (this.checkPermissions('advanced.settingsUser.get', permissionPresets.resellerUp) && !this.isNew) {
        result.push('settingsUser')
      }

      if (this.checkPermissions(`advanced.${services.users}.setTariffs`, permissionPresets.resellerUp)) {
        result.push('tariffs')
      }

      if (this.checkPermissions(`advanced.${services.users}.setRoutingGroups`, permissionPresets.resellerUp)) {
        result.push('routingGroups')
      }

      if (this.checkPermissions(`advanced.${services.users}.update`, permissionPresets.resellerUp) && !this.isNew) {
        result.push('processMessages')
      }

      if (this.checkPermissions('advanced.tokens.get', permissionPresets.meUp) && !this.isNew) {
        result.push('tokens')
      }

      if (this.checkPermissions(`advanced.${services.users}.setPermissions`, permissionPresets.resellerUp) && !this.isNew) {
        result.push('permissions')
      }

      if (this.checkPermissions(`advanced.${services.users}.setOtpChannels`, permissionPresets.resellerUp) && !this.isNew) {
        result.push('userChannels')
      }

      if (!this.isNew) {
        result.push('requisites', 'referral')

        if (this.checkPermissions(`advanced.${services.users}.get`, permissionPresets.meUp)) {
          result.push('recurrentPayments')
        }
      }

      return result
    }
  },

  render
}
