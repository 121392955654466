import render from './render'

export default {
  name: 'InfoTable',

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  render
}
