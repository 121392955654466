import { colors, getPrice, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: getPrice(this.balance),
        label: this.getTranslate(`${services.invoices}.labels.balance`),
        loading: this.balanceLoading,
        color: colors.grey,
        iconSVG: this.globalSettings.billings.currency,
        button: {
          icon: icons.refresh,
          color: colors.grey,
          tooltip: this.getTranslate(`${services.invoices}.tooltips.balance`),
          loading: this.balanceLoading,
          disabled: this.balanceLoading,
          callback: () => this.getBalance()
        }
      }
    }
  )
}
