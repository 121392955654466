import { balance } from '@/utils'

import render from './render'

export default {
  name: 'UsersBalance',

  mixins: [ balance() ],

  props: {
    currency: {
      type: String,
      default: undefined
    }
  },

  computed: {
    $currency() {
      return this.currency || this.globalSettings.billings.currency
    }
  },

  render
}
