import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { fillDependence, permissionPresets, serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.companies,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: services.users,
            permissions: permissionPresets.resellerUp,
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        return result
      },

      find: {
        defaultFilter: {
          OwnerId: undefined,
          $scope: [ 'Owner' ]
        }
      },

      get: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      update: {
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },

      view: 'panels',
      views: [ 'panels' ],

      has: {
        clone: false,
        view: false
      },

      formPaddles: true,
      width: 500
    })
  ]
}
