function renderItemScopedSlot(h, item, index) {
  if (typeof this.$scopedSlots.item === 'function') {
    return this.$scopedSlots.item(item, index)
  }
}

function renderContent(h) {
  if (Array.isArray(this.value) && this.value.length) {
    return this.value.map((item, index) => {
      return renderItemScopedSlot.call(this, h, item, index)
    })
  }
}

export default function(h) {
  return h(
    'div',
    {
      ref: 'draggable'
    },
    renderContent.call(this, h)
  )
}
