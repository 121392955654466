import render from './render'

export default {
  name: 'UsersRequisitesItem',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    gridTemplateColumns() {
      const result = [ ]

      if (this.viewport.breakpoint.xs) {
        result.push('1fr')
      } else {
        result.push('minmax(200px, 350px)')
      }

      if (this.viewport.breakpoint.smUp) {
        result.push('170px', '100px')
      }

      return result.join(' ')
    }
  },

  render
}
