import { components, inputModes } from '@/utils'

import buttonCopy from '@/components/button/copy'

export default function(h) {
  return h(
    components.form,
    {
      class: 'grid grid-gap--8',
      style: { gridTemplateColumns: '1fr 38px 38px' },
      on: {
        validation: event => {
          this.$emit('validation', event)
        }
      }
    },
    [

      h(
        components['text-field'],
        {
          props: {
            value: this.proxy[this.pathToTitle],
            error: this.errors[this.pathToTitle],
            label: this.placeholder || this.getTranslate('commons.labels.title'),
            details: false,
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            required: true
          },
          on: {
            input: event => {
              this.proxy[this.pathToTitle] = event
            },
            keypress: event => {
              if (event.keyCode === 13) {
                this.$emit('submit', this.proxy)
              }
            }
          }
        }
      ),

      h(
        components.switch,
        {
          props: { value: this.proxy[this.pathToIsActive] },
          on: {
            input: event => {
              this.proxy[this.pathToIsActive] = event
            }
          },
          directives: [
            {
              name: 'g-tooltip',
              options: {
                value: this.isActive || this.getTranslate('misc.isActive')
              }
            }
          ]
        }
      ),

      h(
        buttonCopy,
        {
          props: {
            value: this.proxy[this.pathToTitle]
          }
        }
      )
    ]
  )
}
