import { components } from '@/utils'

export default function(h) {
  return h(
    components.input,
    {
      attrs: this.attrs,
      on: { change: this.handler }
    }
  )
}
