export default {
  title: 'Токены',

  titles: {
    create: 'Создать токен',
    update: 'Изменить описание токена',
    remove: 'Удалить токен'
  },

  labels: {
    token: 'Токен',
    description: 'Описание',
    search: 'Поиск',
    owner: 'Владелец'
  },

  hints: {
    notFound: 'Токены не найдены',
    description: 'Максимум 250 символов'
  },

  tooltips: {
    favorites: 'Сделать токен избранным'
  },

  buttons: {
    create: 'Создать новый токен',
    add: 'Добавить токен'
  },

  snackbars: {
    created: 'Токен успешно создан.',
    updated: 'Токен успешно обновлён.',
    removed: 'Токен успешно удалён.'
  }
}
