import { currentTab } from '@/utils'

import render from './render'

export default {
  name: 'ProvidersGroupsSecondCol',

  mixins: [ currentTab('eventsHistory') ],

  computed: {
    tabs() {
      const result = []

      if (this.checkPermissions('advanced.eventsHistory.get', [ true ])) {
        result.push('eventsHistory')
      }

      return result
    }
  },

  render
}
