import { names } from './tts'

export default {
  title: 'Создание отправлений',

  titles: {
    message: 'Сообщение',
    preview: 'Предпросмотр сообщения'
  },

  voice: {
    default: 'Выберите голос',
    generate: 'Сгенерировать аудио',
    voice: 'Голос:',
    names,
    company: {
      aws: 'Amazon',
      yandex: 'Яндекс'
    },
    upload: 'Загрузить файл',
    misc: {
      generatedFile: 'Будет отправлен сгенерированный аудио файл',
      uploadedFile: 'Будет отправлен загруженный файл',
      can: 'Вы также можете',
      ownFile: 'загрузить свой файл',
      textFile: 'сгенерировать аудио файл по тексту'
    },
    timer: 'Подождите {timerCount} сек до следующего запроса'
  },

  attachMenu: {
    attach: 'Прикрепить',
    choose: 'Выбрать файл',
    items: {
      file: 'Файл',
      image: 'Изображение'
    }
  },

  hints: {
    viberTemplates: 'Возможные вариации: Текст, Текст + Кнопка, Текст + Кнопка + Картинка'
  },

  tooltips: {
    createNewUser: 'Создать новое имя отправителя',
    insertVariables: 'Вставить переменные'
  }
}
