import { iMaskProps } from './iMask'

export const core = (component = 'input') => {
  const props = {}
  switch (component) {
    case 'input': {
      props.type = {
        type: String,
        default: 'text',
        validator: value => {
          return !!~[
            'button',
            'checkbox',
            'color',
            'data',
            'datetime-local',
            'email',
            'file',
            'hidden',
            'image',
            'month',
            'number',
            'password',
            'radio',
            'range',
            'reset',
            'search',
            'submit',
            'tel',
            'text',
            'time',
            'url',
            'week'
          ].indexOf(value)
        }
      }
      break
    }
    case 'textarea': {
      props.spellcheck = Boolean
      props.rows = [ String, Number ]
      props.cols = [ String, Number ]
      props.wrap = {
        type: String,
        default: 'soft',
        validator: value => {
          return !!~[ 'soft', 'hard' ].indexOf(value)
        }
      }
      break
    }
  }

  return {
    props: {
      value: null,
      defaultValue: null,

      id: String,
      autocomplete: String,
      accept: String,
      alt: String,
      name: String,
      pattern: String,
      placeholder: String,
      src: String,
      list: String,
      dirname: String,

      size: Number,
      maxlength: Number,
      minlength: Number,

      trim: Boolean,
      checked: Boolean,
      multiple: Boolean,
      disabled: Boolean,
      readonly: Boolean,
      required: Boolean,
      autofocus: Boolean,

      min: [ String, Number ],
      max: [ String, Number ],
      step: [ String, Number ],
      height: [ String, Number ],
      width: [ String, Number ],
      tabindex: [ String, Number ],

      processValue: {
        type: Function,
        default: undefined
      },

      ...props,
      ...iMaskProps
    },

    computed: {
      attributes() {
        const global = [ 'tabindex' ]
        const common = [
          'value',
          'id',
          'autocomplete',
          'autofocus',
          'disabled',
          'form',
          'list',
          'name',
          'readonly',
          'required'
        ]
        const form = [ 'formaction', 'formenctype', 'formmethod', 'formnovalidate', 'formtarget' ]
        const pattern = [ 'maxlength', 'placeholder' ]

        switch (component) {
          case 'input': {
            common.push('type')
            pattern.push('minlength')
            break
          }
          case 'textarea': {
            common.push(...pattern, 'spellcheck', 'rows', 'cols', 'wrap')
            break
          }
        }

        switch (this.type) {
          case 'file':
            common.push('accept', 'capture', 'multiple')
            break

          case 'image':
            common.push(...form, 'alt', 'src', 'height', 'width')
            break

          case 'submit':
            common.push(...form)
            break

          case 'radio':
          case 'checkbox':
            common.push('checked')
            break

          case 'password':
            common.push(...pattern, 'pattern', 'size')
            break

          case 'search':
            common.push(...pattern, 'dirname')
            break

          case 'tel':
            common.push(...pattern, 'pattern', 'size')
            break
          case 'url':
            common.push(...pattern)
            break
          case 'email':
            common.push('multiple', 'size')
            break

          case 'number':
            common.push('max', 'min', 'step')
            break

          case 'text':
            common.push(...pattern, 'pattern', 'dirname', 'size')
            break
        }

        return [ ...global, ...common ].reduce((attributes, attribute) => {
          if (this[attribute] !== undefined) {
            attributes[attribute] = this[attribute]
          }

          return attributes
        }, {})
      }
    }
  }
}
