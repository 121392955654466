import { get } from 'lodash'

import routesTree from '@/components/trees/routes'

export default function(h) {
  if (this.routingGroups) {
    const routingGroup = get(this.value, 'meta._routing.routingGroup')
    const routingRule = get(this.value, 'meta._routing.routingRule')
    const providerGroup = get(this.value, 'meta._routing.providerGroup')
    const provider = get(this.value, 'meta._routing.provider')

    return h(
      routesTree,
      {
        props: {
          value: this.routingGroups || {},
          readonly: true,
          highlight: {
            routingGroups: [ routingGroup ].filter(v => !!v),
            routingRules: [ routingRule ].filter(v => !!v),
            providerGroups: [ providerGroup ].filter(v => !!v),
            providers: [ provider ].filter(v => !!v)
          }
        }
      }
    )
  }
}
