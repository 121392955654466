const noop = () => {}

export const createClicker = function(params = {}) {
  const options = {
    shortClickHandler: params.shortClickHandler || noop,
    longClickHandler: params.longClickHandler || noop,
    longClickDelay: params.longClickDelay || 300,
    longClickPreFire: !!params.longClickPreFire,
    longClickCheckInterval: params.longClickCheckInterval || 10,
    longClickTimerEvent: params.longClickTimerEvent || noop,
    longClickTimerEventThreshold: params.longClickTimerEventThreshold | 300
  }

  let isPressed = false,
      timePressed = 0,
      interval = null,
      initialEvent = {}

  function startClick(event) {
    initialEvent = event
    isPressed = true
    interval = setInterval(() => {
      timePressed += options.longClickCheckInterval

      if (timePressed > options.longClickTimerEventThreshold) {
        options.longClickTimerEvent.call(
          this,
          timePressed,
          options.longClickDelay,
          Math.round(timePressed / options.longClickDelay * 100)
        )
      }

      if (options.longClickPreFire && timePressed > options.longClickDelay) {
        stopClick.call(this)
      }
    }, options.longClickCheckInterval)
  }

  function stopClick() {
    if (interval) {
      clearInterval(interval)
      interval = null
    }
    if (isPressed) {
      isPressed = false

      if (timePressed > options.longClickDelay) {
        options.longClickHandler.call(this, initialEvent)
      } else {
        options.shortClickHandler.call(this, initialEvent)
      }

      timePressed = 0
    }
    initialEvent = {}
  }

  return function clicker(event) {
    event.preventDefault()
    switch (event.type) {
      case 'mousedown':
      case 'touchstart':
        startClick.call(this, event)
        break
      case 'touchend':
      case 'mouseup':
        stopClick.call(this)
        break
    }
  }
}

export default { createClicker }
