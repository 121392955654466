import { CONTACTLIST_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { components, inputModes, services } from '@/utils'

export default function(h) {
  return h(
    'g-form',
    {
      class: 'grid'
    },
    [
      h(
        components['text-field'],
        {
          props: {
            value: this.proxy.title,
            label: this.getTranslate(`${services.contactLists}.labels.title`),
            required: true,
            autofocus: true,
            details: false,
            mode: inputModes['line-label'],
            rounded: true
          },
          on: {
            input: event => {
              this.proxy.title = event
            }
          }
        }
      ),

      h(
        components.select,
        {
          props: {
            value: this.proxy.type,
            label: this.getTranslate(`${services.contactLists}.labels.type`),
            items: CONTACTLIST_TYPES.map(type => ({
              title: this.getTranslate(`contactLists.types.${type}`),
              value: type
            })),
            error: this.errors.type,
            required: true,
            details: false,
            mode: inputModes['line-label'],
            rounded: true
          },
          on: {
            input: event => {
              this.proxy.type = event
            }
          }
        }
      )
    ]
  )
}
