import render from './render'

export default {
  name: 'SimpleTree',

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      search: undefined
    }
  },

  computed: {
    values() {
      return this.value.reduce((result, item) => {
        let check = true
        if (this.search && !~this.search.indexOf(item)) {
          check = false
        }
        if (typeof item !== 'string') {
          check = false
        }
        if (check) {
          result.push(item)
        }

        return result
      }, [])
    }
  },

  render
}
