import Prism from 'prismjs'
import numeral from 'numeral'

import 'prismjs/components/prism-json.js'

import { buttonModes, colors, getLocaleDateString, getLocaleTimeString, inputModes, services, sizes, states } from '@/utils'
import { dialogTypes, fileInfoIcons } from '@/components/file/utils'

import tag from '@/components/tag'
import button from '@/components/button'
import sticker from '@/components/misc/sticker'
import HeadTitle from '@/components/misc/HeadTitle'
import SingleLink from '@/components/misc/SingleLink'
import squircle from '@/components/icon/squircle'

function renderHeader(h) {
  let value = ''
  switch (this.type) {
    case dialogTypes.editMeta:
      value = this.getTranslate(`${services.storage}.titles.properties`)
      break
    case dialogTypes.remove:
      value = this.getTranslate('commons.titles.confirm.remove')
      break
    case dialogTypes.source:
      value = this.getTranslate('commons.titles.data')
      break
  }

  return h(
    HeadTitle,
    {
      props: { value },
      slot: 'header'
    }
  )
}

function renderExpiredAt(h) {
  if (this.checkPermissions(`advanced.${services.storage}.update`)) {
    return h(
      'g-menu',
      {
        props: {
          value: this.showEditExpireMenu,
          closeOnContentClick: false
        },
        on: {
          input: event => {
            this.showEditExpireMenu = event
          }
        }
      },
      [
        h(
          'div',
          {
            class: 'faic fjcfe grid-gap--4',
            slot: 'activator'
          },
          [
            h('div', { class: 'link link--dashed' }, getLocaleDateString(this.proxy.expiredAt)),
            h('div', { class: 'text--grey' }, this.getTranslate('misc.in')),

            getLocaleTimeString(this.proxy.expiredAt)
          ]
        ),

        h(
          'g-date-picker',
          {
            props: {
              value: this.proxy.expiredAt,
              localeTag: this.locale,
              min: new Date(Date.now() + 2 * 24 * 60 * 60 * 1000),
              max: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
              range: false
            },
            on: {
              input: async event => {
                this.proxy.expiredAt = event
                this.showEditExpireMenu = false
              }
            }
          }
        )
      ]
    )
  } else {
    return h(
      'div',
      {
        class: 'faic fjcfe'
      },
      getLocaleDateString(this.proxy.expiredAt)
    )
  }
}
function renderLocks(h) {
  if (this.proxy.locks.length) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        h(
          'div',
          {
            class: 'grid faic fjcc'
          },
          [

            h(
              tag,
              {
                props: {
                  label: this.getTranslate(`${services.storage}.prefixes.locks`),
                  size: sizes.small,
                  color: colors.grey
                }
              }
            )
          ]
        ),

        h(
          'div',
          {
            class: 'grid grid-gap--8'
          },
          this.proxy.locks.map(lock => {
            const [ service, id ] = lock.split(':')
            const index = this.proxy.locks.findIndex(item => item === lock)

            return h(
              sticker,
              {
                props: {
                  value: { title: id },
                  label: service === 'googleDriveApi' ? 'Google Drive API' : this.getTranslate(`${service}.title`),
                  borderStyle: 'dashed',
                  button: {
                    icon: 'lock_open',
                    color: 'secondary',
                    loading: this.restData[services.storage].update.state === states.loading,
                    disabled: this.restData[services.storage].update.state === states.loading,
                    callback: () => {
                      this.proxy.locks.splice(index, 1)
                    }
                  }
                },
                key: id
              }
            )
          })
        )
      ]
    )
  }
}

function renderTitleField(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.title,
        label: this.getTranslate(`${services.storage}.labels.title`),
        mode: inputModes['line-label'],
        dense: true,
        rounded: true,
        details: false,
        clearable: true,
        mask: /^.{0,255}$/
      },
      on: {
        input: event => {
          this.proxy.title = event
        },
        keypress: event => {
          if (event.keyCode === 13) {
            this.updateFile()
          }
        }
      }
    }
  )
}
function renderFileName(h) {
  return h(
    'div',
    {
      class: `${services.storage}-dialog__fileName`
    },
    [
      h(
        'div',
        {
          class: `${services.storage}-dialog__fileInfo-label`
        },
        this.getTranslate(`${services.storage}.prefixes.filename`)
      ),

      h(
        'div',
        {
          class: `${services.storage}-dialog__fileInfo-name`
        },
        this.proxy.filename
      )
    ]
  )
}
function renderInfoRowType(h, type) {
  return h(
    'div',
    {
      class: `${services.storage}-dialog__fileInfo-type`
    },
    [
      h(
        squircle,
        {
          props: {
            icon: fileInfoIcons[type],
            color: colors.grey,
            iconSize: 17
          }
        }
      ),

      h(
        'div',
        {
          class: `${services.storage}-dialog__fileInfo-label`
        },
        this.getTranslate(`${services.storage}.prefixes.${type}`)
      )
    ]
  )
}
function renderInfoRowValueByType(h, type) {
  switch (type) {
    case 'size': {
      return numeral(this.proxy[type]).format('0b')
    }
    case 'createdAt':
    case 'updatedAt': {
      return h(
        'div',
        {
          class: 'faic fjcfe grid-gap--4'
        },
        [
          getLocaleDateString(this.proxy[type]),
          h('span', { class: 'text--grey' }, this.getTranslate('misc.in')),
          getLocaleTimeString(this.proxy[type])
        ]
      )
    }
    case 'owner': {
      return h(
        SingleLink,
        {
          class: 'fjcfe',
          props: { value: this.proxy.Owner },
          on: {
            click: event => {
              event.stopPropagation()
            }
          }
        }
      )
    }
    case 'willBeDeleted': {
      return renderExpiredAt.call(this, h)
    }

    default: {
      return this.proxy[type]
    }
  }
}
function renderInfoRowValue(h, type) {
  return h(
    'div',
    {
      class: `${services.storage}-dialog__fileInfo-content`
    },
    [ renderInfoRowValueByType.call(this, h, type) ]
  )
}
function renderFileInfoRow(h, field) {
  return h(
    'div',
    {
      class: `${services.storage}-dialog__fileInfo-row`
    },
    [
      renderInfoRowType.call(this, h, field),
      renderInfoRowValue.call(this, h, field)
    ]
  )
}
function renderFileInfo(h) {
  return h(
    'div',
    {
      class: `${services.storage}-dialog__fileInfo`
    },
    this.rows.map(field => renderFileInfoRow.call(this, h, field))
  )
}
function renderBody(h) {
  switch (this.type) {
    case 'editMeta': {
      return h(
        'div',
        {
          class: `${services.storage}-dialog__body`
        },
        [
          renderTitleField.call(this, h),
          renderFileName.call(this, h),
          renderFileInfo.call(this, h),
          renderLocks.call(this, h)
        ]
      )
    }
    case 'export': {
      return h(
        'div',
        {
          class: 'fc pt-5 pb-5'
        },
        [
          h(
            'div',
            {
              class: 'fjcc facc ff'
            },
            [
              h('g-progress', {
                props: {
                  indeterminate: true,
                  color: 'grey'
                }
              })
            ]
          ),

          h(
            'div',
            {
              class: 'text-center mt-4'
            },
            this.getTranslate(`${services.storage}.hints.export`)
          )
        ]
      )
    }
    case 'remove': {
      return h(
        'div',
        {
          class: 'pa-3'
        },
        this.getTranslate('commons.contents.confirm.remove')
      )
    }
    case 'edit': {
      return h(
        'iframe',
        {
          attrs: {
            src: this.googleFileURL,
            frameborder: 0
          },
          class: 'position-absolute h--100 w--100'
        }
      )
    }
    case 'source': {
      return h(
        'pre',
        {
          class: 'language-json ma-0 pa-2 w--100',
          style: { 'border-radius': 0 },
          domProps: { innerHTML: Prism.highlight(JSON.stringify(this.proxy, null, 2), Prism.languages.json, 'json') }
        }
      )
    }
  }
}

function renderCancelButton(h) {
  return h(
    button,
    {
      props: {
        label: this.getTranslate('misc.buttons.cancel'),
        mode: buttonModes.flat,
        disabled: this.restData[services.storage].update.state === states.loading
      },
      on: {
        click: () => {
          this.hideDialog()
        }
      }
    }
  )
}
function renderActionButton(h) {
  if (this.type !== dialogTypes.source) {
    return h(
      button,
      {
        props: {
          label: this.getTranslate(`misc.buttons.${this.type === dialogTypes.remove ? this.type : this.type === dialogTypes.edit ? 'save' : 'update'}`),
          loading: this.restData[services.storage].update.state === states.loading,
          disabled: this.restData[services.storage].update.state === states.loading,
          color: this.type === 'remove' ? 'error' : 'primary'
        },
        on: {
          click: () => {
            this[`${this.type}File`]()
          }
        }
      }
    )
  }
}
function renderFooter(h) {
  return h(
    'div',
    {
      class: 'fjcfe grid-gap--8 pa-2',
      style: { gridAutoFlow: 'column' },
      slot: 'footer'
    },
    [
      renderCancelButton.call(this, h),
      renderActionButton.call(this, h)
    ]
  )
}

export default function(h) {
  return h(
    'g-dialog',
    {
      class: {
        [`${services.storage}-dialog`]: true,
        [`${services.storage}-dialog--${this.type}`]: true
      },
      props: this.$props,
      on: {
        input: event => {
          this.hideDialog(event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderBody.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
