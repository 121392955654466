export default {
  title: 'OTP Настройки',

  widgets: {
    title: 'Виджеты',

    titles: {
      create: 'Создать виджет',
      settings: 'Настройки',
      channels: 'Каналы'
    },

    labels: {
      name: 'Название',
      isActive: 'Активен',
      isBlocked: 'Заблокирован',
      captchaIsRequired: 'Требуется капча',
      owner: 'Владелец',
      channels: 'Каналы'
    },

    hints: {
      notFound: 'Виджеты не найдены',
      channels: 'Чтобы каналы успешно привязались к виджету, необходимо выбрать нужные каналы и нажать кнопку "Привязать".'
    },

    tooltips: {
      isActive: {
        true: 'Виджет активен',
        false: 'Виджет не активен'
      },

      isBlocked: {
        true: 'Виджет заблокирован администратором'
      }
    },

    warnings: {
      channels: 'Нет доступных активных каналов. Пожалуйста, обратитесь в службу поддержки.'
    },

    snackbars: {
      created: 'Виджет успешно создан',
      updated: 'Виджет успешно обновлен',
      removed: 'Виджет успешно удален',

      channels: {
        attached: 'Каналы успешно привязаны'
      }
    }
  },

  channels: {
    title: 'Каналы',

    titles: {
      create: 'Создать канал',
      templates: 'Шаблоны',

      additionalSettings: {
        title: 'Дополнительные настройки',
        code: 'Настройки кода'
      }
    },

    labels: {
      name: 'Название',
      channelType: 'Тип канала',
      templates: 'Шаблоны',

      additionalSettings: {
        code: {
          length: 'Длина кода',
          useNumbers: 'Использовать цифры',
          useSymbols: 'Использовать символы',
          useLowerCaseLetters: 'Использовать строчные буквы',
          useUpperCaseLetters: 'Использовать заглавные буквы',
          symbols: 'Символы',
          exclude: 'Исключить',
          excludeSimilarCharacters: 'Исключить похожие символы',
          strict: 'Строгий режим',
          beautify: 'Красивый код'
        },
        lifetime: 'Время жизни кода, сек',
        numberOfTries: 'Количество попыток'
      }
    },

    hints: {
      notFound: 'Каналы не найдены',
      templates: 'Чтобы шаблоны успешно привязались к каналу, необходимо выбрать нужные шаблоны и нажать кнопку "Привязать".',
      additionalSettings: 'Время жизни и количество попыток должно быть больше 0'
    },

    warnings: {
      templates: 'Нет доступных шаблонов.'
    },

    snackbars: {
      created: 'Канал успешно создан',
      updated: 'Канал успешно обновлен',
      removed: 'Канал успешно удален',

      templates: {
        attached: 'Шаблоны успешно привязаны'
      }
    }
  },

  templates: {
    title: 'Шаблоны',

    titles: {
      create: 'Создать шаблон',
      conditions: 'Условия'
    },

    labels: {
      name: 'Название',
      channelType: 'Тип канала',

      additionalSettings: {
        senderId: 'Отправитель',
        template: 'Шаблон'
      }
    },

    hints: {
      notFound: 'Шаблоны не найдены',
      conditions: 'Должно быть заполнено хотя бы одно условие.',

      additionalSettings: {
        template: 'Для подстановки кода используйте {{ code }}'
      }
    },

    snackbars: {
      created: 'Шаблон успешно создан',
      updated: 'Шаблон успешно обновлен',
      removed: 'Шаблон успешно удален'
    }
  }
}
