import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, isEqual } from 'lodash'

export const dateFilter = {
  mixins: [ proxy({ type: 'object' }) ],

  methods: {
    watchProxyHandler() {
      if (!isEqual(this._outputFilter(this.proxy), this.value)) {
        this.transmitValue()
      }
    },

    getDefaultFilter() {
      return undefined
    },

    clearFilter() {
      this.proxy = cloneDeep(this._inputFilter(this.getDefaultFilter()))
    }
  }
}

export default { dateFilter }
