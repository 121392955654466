import title from '@/components/services/providers/item/title'
import comment from '@/components/services/providers/item/comment'
import server from '@/components/services/providers/item/server'
import credentials from '@/components/services/providers/item/credentials'

export default function(h) {
  return h(
    'div',
    {
      class: {
        'providers-item__info': true,
        'providers-item__info--comment': !!this.value.comment && this.viewport.breakpoint.xl
      }
    },
    [
      h(
        title, {
          props: {
            value: this.value
          }
        }
      ),
      h(
        server, {
          props: {
            value: this.value
          }
        }
      ),
      h(
        credentials, {
          props: {
            value: this.value
          }
        }
      ),
      h(
        comment, {
          props: {
            value: this.value
          }
        }
      )
    ]
  )
}
