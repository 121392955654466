import render from './render'

export default {
  name: 'HintRow',

  props: {
    value: String,
    error: String,

    icon: {
      type: String,
      default: 'info'
    },
    color: {
      type: String,
      default: undefined
    },

    right: {
      type: Boolean,
      default: false
    },

    copy: {
      type: Boolean,
      default: true
    }
  },

  render
}
