import Vue from 'vue'

import { upperFirst } from 'lodash'

import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import SingleLink from '@/components/misc/SingleLink'
import SendingTypes from '@/components/misc/SendingTypes'
import titleDivider from '@/components/title/divider'

const iconByModeration = {
  [MODERATION_VALUES.pending]: {
    icon: 'schedule',
    color: 'info'
  },
  [MODERATION_VALUES.approved]: {
    icon: 'done',
    color: 'success'
  },
  [MODERATION_VALUES.rejected]: {
    icon: 'clear',
    color: 'error'
  }
}

function renderTypes(h) {
  return h(
    SendingTypes,
    {
      props: {
        value: this.type,
        permission: `advanced.${services.users}.update`,
        view: 'buttons'
      },
      on: {
        input: event => {
          this.type = event
        }
      }
    }
  )
}

function renderTitle(h, service) {
  return h(
    titleDivider,
    {
      props: {
        value: this.getTranslate(`users.subheaders.${service}`)
      }
    }
  )
}

function renderSwitch(h, service) {
  if (this.checks[service]) {
    return h(
      'g-switch',
      {
        props: {
          value: this.checks[service][this.type],
          label: this.getTranslate(`users.labels.${service}.strict`)
        },
        key: `switch-${service}-${this.type}`,
        on: {
          input: event => {
            Vue.set(this.checks[service], this.type, event)
            this.updateSetting(service)
          }
        }
      }
    )
  }
}

function renderSelect(h, service) {
  const associationName = upperFirst(service)

  return h(
    'g-select',
    {
      props: {
        value: this.proxy[associationName][this.type],
        label: this.getTranslate(`users.labels.${service}.names`),
        query: {
          $scope: [ 'Owner' ],
          type: this.type
        },
        service,
        itemTitle: service === 'sendernames' ? 'name' : 'title',
        autocomplete: true,
        picks: [ 'moderation', 'isActive', 'Owner' ],
        multiple: true,
        mode: 'outline',
        rounded: true,
        details: false
      },
      key: `select-${service}-${this.type}`,
      scopedSlots: {
        selection: ({ item, removeByValue }) => {
          return h(
            'g-chip',
            {
              props: {
                small: true,
                cancelable: true,
                cancelCallback: () => removeByValue(item.id),
                label: item[service === 'sendernames' ? 'name' : 'title'],
                icon: item.moderation && iconByModeration[item.moderation].icon,
                color: item.moderation && iconByModeration[item.moderation].color
              },
              key: item.id
            }
          )
        },
        item: ({ item }) => {
          return h(
            'div',
            {
              class: 'faic w--100'
            },
            [
              item[service === 'sendernames' ? 'name' : 'title'],

              h('div', { class: 'ff' }),

              h(
                SingleLink,
                {
                  class: 'pr-2',
                  props: {
                    value: item.Owner,
                    type: 'text'
                  }
                }
              ),

              h(
                'g-icon',
                {
                  class: 'ml-2 mr-2',
                  props: {
                    value: iconByModeration[item.moderation].icon,
                    color: iconByModeration[item.moderation].color,
                    size: 16
                  }
                }
              ),

              h(
                'g-icon',
                {
                  props: {
                    value: item.isActive ? 'check_box' : 'check_box_outline_blank',
                    color: 'grey',
                    size: 16
                  }
                }
              )
            ]
          )
        }
      },
      on: {
        input: event => {
          Vue.set(this.proxy[associationName], this.type, event)
        }
      }
    }
  )
}

function renderFields(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    this.services.map(service => {
      return h(
        'div',
        {
          class: 'grid grid-cols--1'
        },
        [
          renderTitle.call(this, h, service),
          renderSwitch.call(this, h, service),
          renderSelect.call(this, h, service)
        ]
      )
    })
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-cols--1'
    },
    [
      renderTypes.call(this, h),
      renderFields.call(this, h)
    ]
  )
}
