import Clipboard from 'clipboard'

import render from './render'

export default {
  name: 'Copy',

  props: {
    value: [ String, Number ]
  },

  data() {
    return {
      cp: undefined
    }
  },

  mounted() {
    this.cp = new Clipboard(`.copy-${this._uid}`)
    this.cp.on('success', () => {
      this.addSnackbar({
        text: this.getTranslate('misc.copied'),
        type: 'success'
      })
    })
  },

  beforeDestroy() {
    if (this.cp && typeof this.cp.destroy === 'function') {
      this.cp.destroy()
    }
  },

  render
}
