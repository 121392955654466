import { colors, services, sizes } from '@/utils'

import svgIcon from '@/components/icon/svg'
import squircle from '@/components/icon/squircle'

export default function(h) {
  return h(
    squircle,
    {
      props: {
        color: colors[this.value.type],
        size: sizes.medium,
        tooltip: this.getTranslate(`${services.sendings}.types.${this.value.type}`)
      }
    },
    [
      h(
        svgIcon,
        {
          props: {
            value: this.value.type,
            color: colors[this.value.type]
          }
        }
      )
    ]
  )
}
