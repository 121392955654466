import { TASK_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, getISODate, icons, inputModes } from '@/utils'

import button from '@/components/button'

function renderDatePicker(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      h(
        'g-date-picker',
        {
          props: {
            value: this.changeableDate.date,
            localeTag: this.locale,
            range: false,
            min: new Date().setDate(new Date().getDate() - 1),
            filter: dates => dates.map(date => date && getISODate(date))
          },
          on: {
            input: event => {
              this.changeableDate.date = event
            }
          }
        }
      ),

      h(
        'g-text-field',
        {
          props: {
            value: this.changeableDate.time,
            type: 'time',
            mode: inputModes['line-label'],
            dense: true,
            rounded: true,
            details: false
          },
          on: {
            input: event => {
              this.changeableDate.time = event
            }
          }
        }
      ),

      h(
        'div',
        {
          class: 'fjcfe'
        },
        [
          h(
            button,
            {
              props: {
                label: this.computedDate ? this.getTranslate('tasks.actions.schedule') : this.getTranslate('tasks.actions.start'),
                loading: this.loading,
                disabled: this.loading,
                color: colors.success,
                mode: buttonModes.flat
              },
              on: {
                click: () => {
                  this.updateTask(this.value.id, TASK_STATES.scheduled, this.computedDate)
                }
              }
            }
          )
        ]
      )
    ]
  )
}

export default function(h) {
  if (this.value.state === TASK_STATES.paused) {
    return h(
      'g-menu',
      {
        props: {
          closeOnContentClick: false,
          placement: 'bottom-end',
          offsetDistance: 8,
          rounded: true
        }
      },
      [
        h(
          button,
          {
            props: {
              mode: buttonModes.flat,
              icon: icons.play_arrow,
              color: colors.success
            },
            slot: 'activator'
          }
        ),

        renderDatePicker.call(this, h)
      ]
    )
  }

  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: icons.pause,
        color: colors.secondary,
        disabled: !~[ TASK_STATES.running, TASK_STATES.scheduled ].indexOf(this.value.state)
      },
      on: {
        click: () => {
          this.updateTask(this.value.id, TASK_STATES.paused)
        }
      }
    }
  )
}
