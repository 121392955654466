export const icons = {
  123: '123',
  360: '360',
  '1x_mobiledata': '1x_mobiledata',
  '1k_plus': '1k_plus',
  '1k': '1k',
  '19mp': '19mp',
  '18mp': '18mp',
  '18_up_rating': '18_up_rating',
  '17mp': '17mp',
  '16mp': '16mp',
  '15mp': '15mp',
  '14mp': '14mp',
  '13mp': '13mp',
  '12mp': '12mp',
  '11mp': '11mp',
  '10mp': '10mp',
  '10k': '10k',
  '2k_plus': '2k_plus',
  '24mp': '24mp',
  '23mp': '23mp',
  '22mp': '22mp',
  '21mp': '21mp',
  '20mp': '20mp',
  '2mp': '2mp',
  '2k': '2k',
  '3p': '3p',
  '3mp': '3mp',
  '3k_plus': '3k_plus',
  '3k': '3k',
  '3g_mobiledata': '3g_mobiledata',
  '3d_rotation': '3d_rotation',
  '30fps_select': '30fps_select',
  '30fps': '30fps',
  '4mp': '4mp',
  '4k_plus': '4k_plus',
  '4k': '4k',
  '4g_plus_mobiledata': '4g_plus_mobiledata',
  '4g_mobiledata': '4g_mobiledata',
  '5mp': '5mp',
  '5k_plus': '5k_plus',
  '5k': '5k',
  '5g': '5g',
  '6mp': '6mp',
  '6k_plus': '6k_plus',
  '6k': '6k',
  '6_ft_apart': '6_ft_apart',
  '60fps_select': '60fps_select',
  '60fps': '60fps',
  '7k_plus': '7k_plus',
  '7mp': '7mp',
  '7k': '7k',
  '8k_plus': '8k_plus',
  '8mp': '8mp',
  '8k': '8k',
  '9k_plus': '9k_plus',
  '9mp': '9mp',
  '9k': '9k',
  av_timer: 'av_timer',
  autorenew: 'autorenew',
  autofps_select: 'autofps_select',
  auto_stories: 'auto_stories',
  auto_mode: 'auto_mode',
  auto_graph: 'auto_graph',
  auto_fix_off: 'auto_fix_off',
  auto_fix_normal: 'auto_fix_normal',
  auto_fix_high: 'auto_fix_high',
  auto_delete: 'auto_delete',
  auto_awesome_motion: 'auto_awesome_motion',
  auto_awesome_mosaic: 'auto_awesome_mosaic',
  auto_awesome: 'auto_awesome',
  audiotrack: 'audiotrack',
  audio_file: 'audio_file',
  attribution: 'attribution',
  attractions: 'attractions',
  attachment: 'attachment',
  attach_money: 'attach_money',
  attach_file: 'attach_file',
  attach_email: 'attach_email',
  atm: 'atm',
  assured_workload: 'assured_workload',
  assistant_photo: 'assistant_photo',
  assistant_navigation: 'assistant_navigation',
  assistant_direction: 'assistant_direction',
  assistant: 'assistant',
  assignment_turned_in: 'assignment_turned_in',
  assignment_returned: 'assignment_returned',
  assignment_return: 'assignment_return',
  assignment_late: 'assignment_late',
  assignment_ind: 'assignment_ind',
  assignment: 'assignment',
  assessment: 'assessment',
  aspect_ratio: 'aspect_ratio',
  article: 'article',
  art_track: 'art_track',
  arrow_upward: 'arrow_upward',
  arrow_right_alt: 'arrow_right_alt',
  arrow_right: 'arrow_right',
  arrow_left: 'arrow_left',
  arrow_forward_ios: 'arrow_forward_ios',
  arrow_forward: 'arrow_forward',
  arrow_drop_up: 'arrow_drop_up',
  arrow_drop_down_circle: 'arrow_drop_down_circle',
  arrow_drop_down: 'arrow_drop_down',
  arrow_downward: 'arrow_downward',
  arrow_circle_up: 'arrow_circle_up',
  arrow_circle_right: 'arrow_circle_right',
  arrow_circle_left: 'arrow_circle_left',
  arrow_circle_down: 'arrow_circle_down',
  arrow_back_ios_new: 'arrow_back_ios_new',
  arrow_back_ios: 'arrow_back_ios',
  arrow_back: 'arrow_back',
  area_chart: 'area_chart',
  archive: 'archive',
  architecture: 'architecture',
  apps_outage: 'apps_outage',
  apps: 'apps',
  approval: 'approval',
  apple: 'apple',
  app_shortcut: 'app_shortcut',
  app_settings_alt: 'app_settings_alt',
  app_registration: 'app_registration',
  app_blocking: 'app_blocking',
  api: 'api',
  apartment: 'apartment',
  aod: 'aod',
  announcement: 'announcement',
  animation: 'animation',
  android: 'android',
  anchor: 'anchor',
  analytics: 'analytics',
  amp_stories: 'amp_stories',
  alternate_email: 'alternate_email',
  alt_route: 'alt_route',
  all_out: 'all_out',
  all_inclusive: 'all_inclusive',
  all_inbox: 'all_inbox',
  align_vertical_top: 'align_vertical_top',
  align_vertical_center: 'align_vertical_center',
  align_vertical_bottom: 'align_vertical_bottom',
  align_horizontal_right: 'align_horizontal_right',
  align_horizontal_left: 'align_horizontal_left',
  align_horizontal_center: 'align_horizontal_center',
  album: 'album',
  alarm_on: 'alarm_on',
  alarm_off: 'alarm_off',
  alarm_add: 'alarm_add',
  alarm: 'alarm',
  airport_shuttle: 'airport_shuttle',
  airplay: 'airplay',
  airplanemode_on: 'airplanemode_on',
  airplanemode_off: 'airplanemode_off',
  airplanemode_inactive: 'airplanemode_inactive',
  airplanemode_active: 'airplanemode_active',
  airplane_ticket: 'airplane_ticket',
  airlines: 'airlines',
  airline_stops: 'airline_stops',
  airline_seat_recline_normal: 'airline_seat_recline_normal',
  airline_seat_recline_extra: 'airline_seat_recline_extra',
  airline_seat_legroom_reduced: 'airline_seat_legroom_reduced',
  airline_seat_legroom_normal: 'airline_seat_legroom_normal',
  airline_seat_legroom_extra: 'airline_seat_legroom_extra',
  airline_seat_individual_suite: 'airline_seat_individual_suite',
  airline_seat_flat_angled: 'airline_seat_flat_angled',
  airline_seat_flat: 'airline_seat_flat',
  air: 'air',
  agriculture: 'agriculture',
  ads_click: 'ads_click',
  adobe: 'adobe',
  admin_panel_settings: 'admin_panel_settings',
  adjust: 'adjust',
  adf_scanner: 'adf_scanner',
  addchart: 'addchart',
  add_to_queue: 'add_to_queue',
  add_to_photos: 'add_to_photos',
  add_to_home_screen: 'add_to_home_screen',
  add_to_drive: 'add_to_drive',
  add_task: 'add_task',
  add_shopping_cart: 'add_shopping_cart',
  add_road: 'add_road',
  add_reaction: 'add_reaction',
  add_photo_alternate: 'add_photo_alternate',
  add_moderator: 'add_moderator',
  add_location_alt: 'add_location_alt',
  add_location: 'add_location',
  add_link: 'add_link',
  add_ic_call: 'add_ic_call',
  add_home_work: 'add_home_work',
  add_home: 'add_home',
  add_comment: 'add_comment',
  add_circle_outline: 'add_circle_outline',
  add_circle: 'add_circle',
  add_chart: 'add_chart',
  add_card: 'add_card',
  add_call: 'add_call',
  add_business: 'add_business',
  add_box: 'add_box',
  add_alert: 'add_alert',
  add_alarm: 'add_alarm',
  add_a_photo: 'add_a_photo',
  add: 'add',
  adb: 'adb',
  ad_units: 'ad_units',
  account_tree: 'account_tree',
  account_circle: 'account_circle',
  account_box: 'account_box',
  account_balance_wallet: 'account_balance_wallet',
  account_balance: 'account_balance',
  accessible_forward: 'accessible_forward',
  accessible: 'accessible',
  accessibility_new: 'accessibility_new',
  accessibility: 'accessibility',
  access_time_filled: 'access_time_filled',
  access_time: 'access_time',
  access_alarms: 'access_alarms',
  access_alarm: 'access_alarm',
  ac_unit: 'ac_unit',
  abc: 'abc',
  business_center: 'business_center',
  business: 'business',
  bus_alert: 'bus_alert',
  burst_mode: 'burst_mode',
  bungalow: 'bungalow',
  build_circle: 'build_circle',
  build: 'build',
  bug_report: 'bug_report',
  bubble_chart: 'bubble_chart',
  brush: 'brush',
  brunch_dining: 'brunch_dining',
  browser_updated: 'browser_updated',
  browser_not_supported: 'browser_not_supported',
  browse_gallery: 'browse_gallery',
  broken_image: 'broken_image',
  broadcast_on_personal: 'broadcast_on_personal',
  broadcast_on_home: 'broadcast_on_home',
  brightness_medium: 'brightness_medium',
  brightness_low: 'brightness_low',
  brightness_high: 'brightness_high',
  brightness_auto: 'brightness_auto',
  brightness_7: 'brightness_7',
  brightness_6: 'brightness_6',
  brightness_5: 'brightness_5',
  brightness_4: 'brightness_4',
  brightness_3: 'brightness_3',
  brightness_2: 'brightness_2',
  brightness_1: 'brightness_1',
  breakfast_dining: 'breakfast_dining',
  branding_watermark: 'branding_watermark',
  boy: 'boy',
  border_vertical: 'border_vertical',
  border_top: 'border_top',
  border_style: 'border_style',
  border_right: 'border_right',
  border_outer: 'border_outer',
  border_left: 'border_left',
  border_inner: 'border_inner',
  border_horizontal: 'border_horizontal',
  border_color: 'border_color',
  border_clear: 'border_clear',
  border_bottom: 'border_bottom',
  border_all: 'border_all',
  bookmarks: 'bookmarks',
  bookmark_remove: 'bookmark_remove',
  bookmark_outline: 'bookmark_outline',
  bookmark_border: 'bookmark_border',
  bookmark_added: 'bookmark_added',
  bookmark_add: 'bookmark_add',
  bookmark: 'bookmark',
  book_online: 'book_online',
  book: 'book',
  bolt: 'bolt',
  blur_on: 'blur_on',
  blur_off: 'blur_off',
  blur_linear: 'blur_linear',
  blur_circular: 'blur_circular',
  bluetooth_searching: 'bluetooth_searching',
  bluetooth_drive: 'bluetooth_drive',
  bluetooth_disabled: 'bluetooth_disabled',
  bluetooth_connected: 'bluetooth_connected',
  bluetooth_audio: 'bluetooth_audio',
  bluetooth: 'bluetooth',
  bloodtype: 'bloodtype',
  block_flipped: 'block_flipped',
  block: 'block',
  blinds_closed: 'blinds_closed',
  blinds: 'blinds',
  blender: 'blender',
  biotech: 'biotech',
  bike_scooter: 'bike_scooter',
  bento: 'bento',
  beenhere: 'beenhere',
  bedtime_off: 'bedtime_off',
  bedtime: 'bedtime',
  bedroom_parent: 'bedroom_parent',
  bedroom_child: 'bedroom_child',
  bedroom_baby: 'bedroom_baby',
  bed: 'bed',
  beach_access: 'beach_access',
  battery_unknown: 'battery_unknown',
  battery_std: 'battery_std',
  battery_saver: 'battery_saver',
  battery_full: 'battery_full',
  battery_charging_full: 'battery_charging_full',
  battery_alert: 'battery_alert',
  battery_6_bar: 'battery_6_bar',
  battery_5_bar: 'battery_5_bar',
  battery_4_bar: 'battery_4_bar',
  battery_3_bar: 'battery_3_bar',
  battery_2_bar: 'battery_2_bar',
  battery_1_bar: 'battery_1_bar',
  battery_0_bar: 'battery_0_bar',
  bathtub: 'bathtub',
  bathroom: 'bathroom',
  batch_prediction: 'batch_prediction',
  bar_chart: 'bar_chart',
  ballot: 'ballot',
  balcony: 'balcony',
  balance: 'balance',
  bakery_dining: 'bakery_dining',
  badge: 'badge',
  backup_table: 'backup_table',
  backup: 'backup',
  backspace: 'backspace',
  backpack: 'backpack',
  back_hand: 'back_hand',
  baby_changing_station: 'baby_changing_station',
  cyclone: 'cyclone',
  curtains_closed: 'curtains_closed',
  curtains: 'curtains',
  currency_yuan: 'currency_yuan',
  currency_yen: 'currency_yen',
  currency_rupee: 'currency_rupee',
  currency_ruble: 'currency_ruble',
  currency_pound: 'currency_pound',
  currency_lira: 'currency_lira',
  currency_franc: 'currency_franc',
  currency_exchange: 'currency_exchange',
  currency_bitcoin: 'currency_bitcoin',
  css: 'css',
  cruelty_free: 'cruelty_free',
  crop_square: 'crop_square',
  crop_rotate: 'crop_rotate',
  crop_portrait: 'crop_portrait',
  crop_original: 'crop_original',
  crop_landscape: 'crop_landscape',
  crop_free: 'crop_free',
  crop_din: 'crop_din',
  crop_7_5: 'crop_7_5',
  crop_5_4: 'crop_5_4',
  crop_3_2: 'crop_3_2',
  crop_16_9: 'crop_16_9',
  crop: 'crop',
  crisis_alert: 'crisis_alert',
  crib: 'crib',
  credit_score: 'credit_score',
  credit_card_off: 'credit_card_off',
  credit_card: 'credit_card',
  create_new_folder: 'create_new_folder',
  create: 'create',
  countertops: 'countertops',
  cottage: 'cottage',
  corporate_fare: 'corporate_fare',
  coronavirus: 'coronavirus',
  copyright: 'copyright',
  copy_all: 'copy_all',
  cookie: 'cookie',
  control_point_duplicate: 'control_point_duplicate',
  control_point: 'control_point',
  control_camera: 'control_camera',
  contrast: 'contrast',
  content_paste_search: 'content_paste_search',
  content_paste_off: 'content_paste_off',
  content_paste_go: 'content_paste_go',
  content_paste: 'content_paste',
  content_cut: 'content_cut',
  content_copy: 'content_copy',
  contacts: 'contacts',
  contactless: 'contactless',
  contact_support: 'contact_support',
  contact_phone: 'contact_phone',
  contact_page: 'contact_page',
  contact_mail: 'contact_mail',
  construction: 'construction',
  connecting_airports: 'connecting_airports',
  connected_tv: 'connected_tv',
  connect_without_contact: 'connect_without_contact',
  confirmation_number: 'confirmation_number',
  confirmation_num: 'confirmation_num',
  computer: 'computer',
  compress: 'compress',
  compost: 'compost',
  compass_calibration: 'compass_calibration',
  compare_arrows: 'compare_arrows',
  compare: 'compare',
  commute: 'commute',
  commit: 'commit',
  comments_disabled: 'comments_disabled',
  comment_bank: 'comment_bank',
  comment: 'comment',
  colorize: 'colorize',
  color_lens: 'color_lens',
  collections_bookmark: 'collections_bookmark',
  collections: 'collections',
  coffee_maker: 'coffee_maker',
  coffee: 'coffee',
  code_off: 'code_off',
  code: 'code',
  co_present: 'co_present',
  co2: 'co2',
  cloudy_snowing: 'cloudy_snowing',
  cloud_upload: 'cloud_upload',
  cloud_sync: 'cloud_sync',
  cloud_queue: 'cloud_queue',
  cloud_off: 'cloud_off',
  cloud_download: 'cloud_download',
  cloud_done: 'cloud_done',
  cloud_circle: 'cloud_circle',
  cloud: 'cloud',
  closed_caption_off: 'closed_caption_off',
  closed_caption_disabled: 'closed_caption_disabled',
  closed_caption: 'closed_caption',
  close_fullscreen: 'close_fullscreen',
  close: 'close',
  clear_all: 'clear_all',
  clear: 'clear',
  cleaning_services: 'cleaning_services',
  clean_hands: 'clean_hands',
  class: 'class',
  circle_notifications: 'circle_notifications',
  circle: 'circle',
  church: 'church',
  chrome_reader_mode: 'chrome_reader_mode',
  child_friendly: 'child_friendly',
  child_care: 'child_care',
  chevron_right: 'chevron_right',
  chevron_left: 'chevron_left',
  checkroom: 'checkroom',
  checklist_rtl: 'checklist_rtl',
  checklist: 'checklist',
  check_circle_outline: 'check_circle_outline',
  check_circle: 'check_circle',
  check_box_outline_blank: 'check_box_outline_blank',
  check_box: 'check_box',
  check: 'check',
  chat_bubble_outline: 'chat_bubble_outline',
  chat_bubble: 'chat_bubble',
  chat: 'chat',
  charging_station: 'charging_station',
  change_history: 'change_history',
  change_circle: 'change_circle',
  chalet: 'chalet',
  chair_alt: 'chair_alt',
  chair: 'chair',
  center_focus_weak: 'center_focus_weak',
  center_focus_strong: 'center_focus_strong',
  cell_wifi: 'cell_wifi',
  cell_tower: 'cell_tower',
  celebration: 'celebration',
  category: 'category',
  catching_pokemon: 'catching_pokemon',
  castle: 'castle',
  cast_for_education: 'cast_for_education',
  cast_connected: 'cast_connected',
  cast: 'cast',
  casino: 'casino',
  cases: 'cases',
  carpenter: 'carpenter',
  card_travel: 'card_travel',
  card_membership: 'card_membership',
  card_giftcard: 'card_giftcard',
  car_repair: 'car_repair',
  car_rental: 'car_rental',
  car_crash: 'car_crash',
  candlestick_chart: 'candlestick_chart',
  cancel_schedule_send: 'cancel_schedule_send',
  cancel_presentation: 'cancel_presentation',
  cancel: 'cancel',
  campaign: 'campaign',
  cameraswitch: 'cameraswitch',
  camera_roll: 'camera_roll',
  camera_rear: 'camera_rear',
  camera_outdoor: 'camera_outdoor',
  camera_indoor: 'camera_indoor',
  camera_front: 'camera_front',
  camera_enhance: 'camera_enhance',
  camera_alt: 'camera_alt',
  camera: 'camera',
  call_to_action: 'call_to_action',
  call_split: 'call_split',
  call_received: 'call_received',
  call_missed_outgoing: 'call_missed_outgoing',
  call_missed: 'call_missed',
  call_merge: 'call_merge',
  call_made: 'call_made',
  call_end: 'call_end',
  call: 'call',
  calendar_view_week: 'calendar_view_week',
  calendar_view_month: 'calendar_view_month',
  calendar_view_day: 'calendar_view_day',
  calendar_today: 'calendar_today',
  calendar_month: 'calendar_month',
  calculate: 'calculate',
  cake: 'cake',
  cached: 'cached',
  cable: 'cable',
  cabin: 'cabin',
  dynamic_form: 'dynamic_form',
  dynamic_feed: 'dynamic_feed',
  dvr: 'dvr',
  duo: 'duo',
  dry_cleaning: 'dry_cleaning',
  dry: 'dry',
  drive_folder_upload: 'drive_folder_upload',
  drive_file_rename_outline: 'drive_file_rename_outline',
  drive_file_move_rtl: 'drive_file_move_rtl',
  drive_file_move_outline: 'drive_file_move_outline',
  drive_file_move: 'drive_file_move',
  drive_eta: 'drive_eta',
  draw: 'draw',
  drag_indicator: 'drag_indicator',
  drag_handle: 'drag_handle',
  drafts: 'drafts',
  downloading: 'downloading',
  download_for_offline: 'download_for_offline',
  download_done: 'download_done',
  download: 'download',
  downhill_skiing: 'downhill_skiing',
  double_arrow: 'double_arrow',
  doorbell: 'doorbell',
  door_sliding: 'door_sliding',
  door_front: 'door_front',
  door_back: 'door_back',
  donut_small: 'donut_small',
  donut_large: 'donut_large',
  done_outline: 'done_outline',
  done_all: 'done_all',
  done: 'done',
  domain_verification: 'domain_verification',
  domain_disabled: 'domain_disabled',
  domain_add: 'domain_add',
  domain: 'domain',
  document_scanner: 'document_scanner',
  dock: 'dock',
  do_not_touch: 'do_not_touch',
  do_not_step: 'do_not_step',
  do_not_disturb_on_total_silence: 'do_not_disturb_on_total_silence',
  do_not_disturb_on: 'do_not_disturb_on',
  do_not_disturb_off: 'do_not_disturb_off',
  do_not_disturb_alt: 'do_not_disturb_alt',
  do_not_disturb: 'do_not_disturb',
  do_disturb_on: 'do_disturb_on',
  do_disturb_off: 'do_disturb_off',
  do_disturb_alt: 'do_disturb_alt',
  do_disturb: 'do_disturb',
  dns: 'dns',
  dnd_forwardslash: 'dnd_forwardslash',
  display_settings: 'display_settings',
  discount: 'discount',
  discord: 'discord',
  disc_full: 'disc_full',
  disabled_visible: 'disabled_visible',
  disabled_by_default: 'disabled_by_default',
  dirty_lens: 'dirty_lens',
  directions_walk: 'directions_walk',
  directions_transit_filled: 'directions_transit_filled',
  directions_transit: 'directions_transit',
  directions_train: 'directions_train',
  directions_subway_filled: 'directions_subway_filled',
  directions_subway: 'directions_subway',
  directions_run: 'directions_run',
  directions_railway_filled: 'directions_railway_filled',
  directions_railway: 'directions_railway',
  directions_off: 'directions_off',
  directions_ferry: 'directions_ferry',
  directions_car_filled: 'directions_car_filled',
  directions_car: 'directions_car',
  directions_bus_filled: 'directions_bus_filled',
  directions_bus: 'directions_bus',
  directions_boat_filled: 'directions_boat_filled',
  directions_boat: 'directions_boat',
  directions_bike: 'directions_bike',
  directions: 'directions',
  dinner_dining: 'dinner_dining',
  dining: 'dining',
  difference: 'difference',
  diamond: 'diamond',
  dialpad: 'dialpad',
  dialer_sip: 'dialer_sip',
  devices_other: 'devices_other',
  devices_fold: 'devices_fold',
  devices: 'devices',
  device_unknown: 'device_unknown',
  device_thermostat: 'device_thermostat',
  device_hub: 'device_hub',
  developer_mode: 'developer_mode',
  developer_board_off: 'developer_board_off',
  developer_board: 'developer_board',
  details: 'details',
  desktop_windows: 'desktop_windows',
  desktop_mac: 'desktop_mac',
  desktop_access_disabled: 'desktop_access_disabled',
  desk: 'desk',
  design_services: 'design_services',
  deselect: 'deselect',
  description: 'description',
  departure_board: 'departure_board',
  density_small: 'density_small',
  density_medium: 'density_medium',
  density_large: 'density_large',
  delivery_dining: 'delivery_dining',
  delete_sweep: 'delete_sweep',
  delete_outline: 'delete_outline',
  delete_forever: 'delete_forever',
  delete: 'delete',
  dehaze: 'dehaze',
  deck: 'deck',
  deblur: 'deblur',
  date_range: 'date_range',
  dataset_linked: 'dataset_linked',
  dataset: 'dataset',
  data_usage: 'data_usage',
  data_thresholding: 'data_thresholding',
  data_saver_on: 'data_saver_on',
  data_saver_off: 'data_saver_off',
  data_object: 'data_object',
  data_exploration: 'data_exploration',
  data_array: 'data_array',
  dashboard_customize: 'dashboard_customize',
  dashboard: 'dashboard',
  dark_mode: 'dark_mode',
  dangerous: 'dangerous',
  extension_off: 'extension_off',
  extension: 'extension',
  exposure_zero: 'exposure_zero',
  exposure_plus_2: 'exposure_plus_2',
  exposure_plus_1: 'exposure_plus_1',
  exposure_neg_2: 'exposure_neg_2',
  exposure_neg_1: 'exposure_neg_1',
  exposure_minus_2: 'exposure_minus_2',
  exposure_minus_1: 'exposure_minus_1',
  exposure: 'exposure',
  explore_off: 'explore_off',
  explore: 'explore',
  explicit: 'explicit',
  expand_more: 'expand_more',
  expand_less: 'expand_less',
  expand_circle_down: 'expand_circle_down',
  expand: 'expand',
  exit_to_app: 'exit_to_app',
  event_seat: 'event_seat',
  event_repeat: 'event_repeat',
  event_note: 'event_note',
  event_busy: 'event_busy',
  event_available: 'event_available',
  event: 'event',
  ev_station: 'ev_station',
  euro_symbol: 'euro_symbol',
  euro: 'euro',
  escalator_warning: 'escalator_warning',
  escalator: 'escalator',
  error_outline: 'error_outline',
  error: 'error',
  equalizer: 'equalizer',
  enhanced_encryption: 'enhanced_encryption',
  enhance_photo_translate: 'enhance_photo_translate',
  engineering: 'engineering',
  energy_savings_leaf: 'energy_savings_leaf',
  emoji_transportation: 'emoji_transportation',
  emoji_symbols: 'emoji_symbols',
  emoji_people: 'emoji_people',
  emoji_objects: 'emoji_objects',
  emoji_nature: 'emoji_nature',
  emoji_food_beverage: 'emoji_food_beverage',
  emoji_flags: 'emoji_flags',
  emoji_events: 'emoji_events',
  emoji_emotions: 'emoji_emotions',
  emergency_share: 'emergency_share',
  emergency_recording: 'emergency_recording',
  emergency: 'emergency',
  email: 'email',
  elevator: 'elevator',
  electrical_services: 'electrical_services',
  electric_scooter: 'electric_scooter',
  electric_rickshaw: 'electric_rickshaw',
  electric_moped: 'electric_moped',
  electric_meter: 'electric_meter',
  electric_car: 'electric_car',
  electric_bolt: 'electric_bolt',
  electric_bike: 'electric_bike',
  elderly_woman: 'elderly_woman',
  elderly: 'elderly',
  eject: 'eject',
  egg_alt: 'egg_alt',
  egg: 'egg',
  edit_road: 'edit_road',
  edit_off: 'edit_off',
  edit_notifications: 'edit_notifications',
  edit_note: 'edit_note',
  edit_location_alt: 'edit_location_alt',
  edit_location: 'edit_location',
  edit_calendar: 'edit_calendar',
  edit_attributes: 'edit_attributes',
  edit: 'edit',
  edgesensor_low: 'edgesensor_low',
  edgesensor_high: 'edgesensor_high',
  eco: 'eco',
  east: 'east',
  earbuds_battery: 'earbuds_battery',
  earbuds: 'earbuds',
  e_mobiledata: 'e_mobiledata',
  functions: 'functions',
  fullscreen_exit: 'fullscreen_exit',
  fullscreen: 'fullscreen',
  front_hand: 'front_hand',
  free_cancellation: 'free_cancellation',
  free_breakfast: 'free_breakfast',
  foundation: 'foundation',
  forward_to_inbox: 'forward_to_inbox',
  forward_5: 'forward_5',
  forward_30: 'forward_30',
  forward_10: 'forward_10',
  forward: 'forward',
  forum: 'forum',
  fort: 'fort',
  format_underlined: 'format_underlined',
  format_underline: 'format_underline',
  format_textdirection_r_to_l: 'format_textdirection_r_to_l',
  format_textdirection_l_to_r: 'format_textdirection_l_to_r',
  format_strikethrough: 'format_strikethrough',
  format_size: 'format_size',
  format_shapes: 'format_shapes',
  format_quote: 'format_quote',
  format_paint: 'format_paint',
  format_overline: 'format_overline',
  format_list_numbered_rtl: 'format_list_numbered_rtl',
  format_list_numbered: 'format_list_numbered',
  format_list_bulleted: 'format_list_bulleted',
  format_line_spacing: 'format_line_spacing',
  format_italic: 'format_italic',
  format_indent_increase: 'format_indent_increase',
  format_indent_decrease: 'format_indent_decrease',
  format_color_text: 'format_color_text',
  format_color_reset: 'format_color_reset',
  format_color_fill: 'format_color_fill',
  format_clear: 'format_clear',
  format_bold: 'format_bold',
  format_align_right: 'format_align_right',
  format_align_left: 'format_align_left',
  format_align_justify: 'format_align_justify',
  format_align_center: 'format_align_center',
  fork_right: 'fork_right',
  fork_left: 'fork_left',
  forest: 'forest',
  food_bank: 'food_bank',
  font_download_off: 'font_download_off',
  font_download: 'font_download',
  follow_the_signs: 'follow_the_signs',
  folder_zip: 'folder_zip',
  folder_special: 'folder_special',
  folder_shared: 'folder_shared',
  folder_open: 'folder_open',
  folder_off: 'folder_off',
  folder_delete: 'folder_delete',
  folder_copy: 'folder_copy',
  folder: 'folder',
  foggy: 'foggy',
  fmd_good: 'fmd_good',
  fmd_bad: 'fmd_bad',
  flutter_dash: 'flutter_dash',
  flourescent: 'flourescent',
  flood: 'flood',
  flip_to_front: 'flip_to_front',
  flip_to_back: 'flip_to_back',
  flip_camera_ios: 'flip_camera_ios',
  flip_camera_android: 'flip_camera_android',
  flip: 'flip',
  flight_takeoff: 'flight_takeoff',
  flight_land: 'flight_land',
  flight_class: 'flight_class',
  flight: 'flight',
  flatware: 'flatware',
  flashlight_on: 'flashlight_on',
  flashlight_off: 'flashlight_off',
  flash_on: 'flash_on',
  flash_off: 'flash_off',
  flash_auto: 'flash_auto',
  flare: 'flare',
  flaky: 'flaky',
  flag_circle: 'flag_circle',
  flag: 'flag',
  fitness_center: 'fitness_center',
  fitbit: 'fitbit',
  fit_screen: 'fit_screen',
  first_page: 'first_page',
  fireplace: 'fireplace',
  fire_truck: 'fire_truck',
  fire_hydrant_alt: 'fire_hydrant_alt',
  fire_hydrant: 'fire_hydrant',
  fire_extinguisher: 'fire_extinguisher',
  fingerprint: 'fingerprint',
  find_replace: 'find_replace',
  find_in_page: 'find_in_page',
  filter_vintage: 'filter_vintage',
  filter_tilt_shift: 'filter_tilt_shift',
  filter_none: 'filter_none',
  filter_list_off: 'filter_list_off',
  filter_list_alt: 'filter_list_alt',
  filter_list: 'filter_list',
  filter_hdr: 'filter_hdr',
  filter_frames: 'filter_frames',
  filter_drama: 'filter_drama',
  filter_center_focus: 'filter_center_focus',
  filter_b_and_w: 'filter_b_and_w',
  filter_alt_off: 'filter_alt_off',
  filter_alt: 'filter_alt',
  filter_9_plus: 'filter_9_plus',
  filter_9: 'filter_9',
  filter_8: 'filter_8',
  filter_7: 'filter_7',
  filter_6: 'filter_6',
  filter_5: 'filter_5',
  filter_4: 'filter_4',
  filter_3: 'filter_3',
  filter_2: 'filter_2',
  filter_1: 'filter_1',
  filter: 'filter',
  file_upload: 'file_upload',
  file_present: 'file_present',
  file_open: 'file_open',
  file_download_off: 'file_download_off',
  file_download_done: 'file_download_done',
  file_download: 'file_download',
  file_copy: 'file_copy',
  fiber_smart_record: 'fiber_smart_record',
  fiber_pin: 'fiber_pin',
  fiber_new: 'fiber_new',
  fiber_manual_record: 'fiber_manual_record',
  fiber_dvr: 'fiber_dvr',
  festival: 'festival',
  fence: 'fence',
  female: 'female',
  feedback: 'feedback',
  feed: 'feed',
  featured_video: 'featured_video',
  featured_play_list: 'featured_play_list',
  fax: 'fax',
  favorite_outline: 'favorite_outline',
  favorite_border: 'favorite_border',
  favorite: 'favorite',
  fastfood: 'fastfood',
  fast_rewind: 'fast_rewind',
  fast_forward: 'fast_forward',
  family_restroom: 'family_restroom',
  factory: 'factory',
  fact_check: 'fact_check',
  facebook: 'facebook',
  face_retouching_off: 'face_retouching_off',
  face_retouching_natural: 'face_retouching_natural',
  face: 'face',
  groups: 'groups',
  group_work: 'group_work',
  group_remove: 'group_remove',
  group_off: 'group_off',
  group_add: 'group_add',
  group: 'group',
  grid_view: 'grid_view',
  grid_on: 'grid_on',
  grid_off: 'grid_off',
  grid_goldenratio: 'grid_goldenratio',
  grid_4x4: 'grid_4x4',
  grid_3x3: 'grid_3x3',
  grass: 'grass',
  graphic_eq: 'graphic_eq',
  grain: 'grain',
  grading: 'grading',
  gradient: 'gradient',
  grade: 'grade',
  gps_off: 'gps_off',
  gps_not_fixed: 'gps_not_fixed',
  gps_fixed: 'gps_fixed',
  gpp_maybe: 'gpp_maybe',
  gpp_good: 'gpp_good',
  gpp_bad: 'gpp_bad',
  golf_course: 'golf_course',
  goat: 'goat',
  gite: 'gite',
  girl: 'girl',
  gif_box: 'gif_box',
  gif: 'gif',
  get_app: 'get_app',
  gesture: 'gesture',
  generating_tokens: 'generating_tokens',
  gavel: 'gavel',
  gas_meter: 'gas_meter',
  garage: 'garage',
  games: 'games',
  gamepad: 'gamepad',
  g_translate: 'g_translate',
  g_mobiledata: 'g_mobiledata',
  hvac: 'hvac',
  hub: 'hub',
  https: 'https',
  http: 'http',
  html: 'html',
  how_to_vote: 'how_to_vote',
  how_to_reg: 'how_to_reg',
  houseboat: 'houseboat',
  house_siding: 'house_siding',
  house: 'house',
  hourglass_top: 'hourglass_top',
  hourglass_full: 'hourglass_full',
  hourglass_empty: 'hourglass_empty',
  hourglass_disabled: 'hourglass_disabled',
  hourglass_bottom: 'hourglass_bottom',
  hotel_class: 'hotel_class',
  hotel: 'hotel',
  hot_tub: 'hot_tub',
  horizontal_split: 'horizontal_split',
  horizontal_rule: 'horizontal_rule',
  horizontal_distribute: 'horizontal_distribute',
  home_work: 'home_work',
  home_repair_service: 'home_repair_service',
  home_mini: 'home_mini',
  home_max: 'home_max',
  home_filled: 'home_filled',
  home: 'home',
  holiday_village: 'holiday_village',
  hls_off: 'hls_off',
  hls: 'hls',
  hive: 'hive',
  history_toggle_off: 'history_toggle_off',
  history_edu: 'history_edu',
  history: 'history',
  hiking: 'hiking',
  highlight_remove: 'highlight_remove',
  highlight_off: 'highlight_off',
  highlight_alt: 'highlight_alt',
  highlight: 'highlight',
  high_quality: 'high_quality',
  hide_source: 'hide_source',
  hide_image: 'hide_image',
  hexagon: 'hexagon',
  hevc: 'hevc',
  help_outline: 'help_outline',
  help_center: 'help_center',
  help: 'help',
  height: 'height',
  heat_pump: 'heat_pump',
  heart_broken: 'heart_broken',
  hearing_disabled: 'hearing_disabled',
  hearing: 'hearing',
  health_and_safety: 'health_and_safety',
  healing: 'healing',
  headset_off: 'headset_off',
  headset_mic: 'headset_mic',
  headset: 'headset',
  headphones_battery: 'headphones_battery',
  headphones: 'headphones',
  hdr_weak: 'hdr_weak',
  hdr_strong: 'hdr_strong',
  hdr_plus: 'hdr_plus',
  hdr_on_select: 'hdr_on_select',
  hdr_on: 'hdr_on',
  hdr_off_select: 'hdr_off_select',
  hdr_off: 'hdr_off',
  hdr_enhanced_select: 'hdr_enhanced_select',
  hdr_auto_select: 'hdr_auto_select',
  hdr_auto: 'hdr_auto',
  hd: 'hd',
  hardware: 'hardware',
  handyman: 'handyman',
  handshake: 'handshake',
  hail: 'hail',
  h_plus_mobiledata: 'h_plus_mobiledata',
  h_mobiledata: 'h_mobiledata',
  iso: 'iso',
  iron: 'iron',
  ios_share: 'ios_share',
  invert_colors_on: 'invert_colors_on',
  invert_colors_off: 'invert_colors_off',
  invert_colors: 'invert_colors',
  inventory_2: 'inventory_2',
  inventory: 'inventory',
  interpreter_mode: 'interpreter_mode',
  interests: 'interests',
  integration_instructions: 'integration_instructions',
  install_mobile: 'install_mobile',
  install_desktop: 'install_desktop',
  insights: 'insights',
  insert_photo: 'insert_photo',
  insert_page_break: 'insert_page_break',
  insert_link: 'insert_link',
  insert_invitation: 'insert_invitation',
  insert_emoticon: 'insert_emoticon',
  insert_drive_file: 'insert_drive_file',
  insert_comment: 'insert_comment',
  insert_chart_outlined: 'insert_chart_outlined',
  insert_chart: 'insert_chart',
  input: 'input',
  info_outline: 'info_outline',
  info: 'info',
  indeterminate_check_box: 'indeterminate_check_box',
  incomplete_circle: 'incomplete_circle',
  inbox: 'inbox',
  important_devices: 'important_devices',
  import_export: 'import_export',
  import_contacts: 'import_contacts',
  imagesearch_roller: 'imagesearch_roller',
  image_search: 'image_search',
  image_not_supported: 'image_not_supported',
  image_aspect_ratio: 'image_aspect_ratio',
  image: 'image',
  icecream: 'icecream',
  ice_skating: 'ice_skating',
  join_right: 'join_right',
  join_left: 'join_left',
  join_inner: 'join_inner',
  join_full: 'join_full',
  javascript: 'javascript',
  kitesurfing: 'kitesurfing',
  kitchen: 'kitchen',
  king_bed: 'king_bed',
  keyboard_voice: 'keyboard_voice',
  keyboard_tab: 'keyboard_tab',
  keyboard_return: 'keyboard_return',
  keyboard_option_key: 'keyboard_option_key',
  keyboard_option: 'keyboard_option',
  keyboard_hide: 'keyboard_hide',
  keyboard_double_arrow_up: 'keyboard_double_arrow_up',
  keyboard_double_arrow_right: 'keyboard_double_arrow_right',
  keyboard_double_arrow_left: 'keyboard_double_arrow_left',
  keyboard_double_arrow_down: 'keyboard_double_arrow_down',
  keyboard_control_key: 'keyboard_control_key',
  keyboard_control: 'keyboard_control',
  keyboard_command_key: 'keyboard_command_key',
  keyboard_command: 'keyboard_command',
  keyboard_capslock: 'keyboard_capslock',
  keyboard_backspace: 'keyboard_backspace',
  keyboard_arrow_up: 'keyboard_arrow_up',
  keyboard_arrow_right: 'keyboard_arrow_right',
  keyboard_arrow_left: 'keyboard_arrow_left',
  keyboard_arrow_down: 'keyboard_arrow_down',
  keyboard_alt: 'keyboard_alt',
  keyboard: 'keyboard',
  key_off: 'key_off',
  key: 'key',
  kebab_dining: 'kebab_dining',
  kayaking: 'kayaking',
  lyrics: 'lyrics',
  lunch_dining: 'lunch_dining',
  luggage: 'luggage',
  lte_plus_mobiledata: 'lte_plus_mobiledata',
  lte_mobiledata: 'lte_mobiledata',
  loyalty: 'loyalty',
  low_priority: 'low_priority',
  loupe: 'loupe',
  loop: 'loop',
  looks_two: 'looks_two',
  looks_one: 'looks_one',
  looks_6: 'looks_6',
  looks_5: 'looks_5',
  looks_4: 'looks_4',
  looks_3: 'looks_3',
  looks: 'looks',
  logout: 'logout',
  logo_dev: 'logo_dev',
  login: 'login',
  lock_reset: 'lock_reset',
  lock_person: 'lock_person',
  lock_outline: 'lock_outline',
  lock_open: 'lock_open',
  lock_clock: 'lock_clock',
  lock: 'lock',
  location_searching: 'location_searching',
  location_pin: 'location_pin',
  location_on: 'location_on',
  location_off: 'location_off',
  location_history: 'location_history',
  location_disabled: 'location_disabled',
  location_city: 'location_city',
  local_taxi: 'local_taxi',
  local_shipping: 'local_shipping',
  local_see: 'local_see',
  local_restaurant: 'local_restaurant',
  local_printshop: 'local_printshop',
  local_print_shop: 'local_print_shop',
  local_post_office: 'local_post_office',
  local_police: 'local_police',
  local_play: 'local_play',
  local_pizza: 'local_pizza',
  local_phone: 'local_phone',
  local_pharmacy: 'local_pharmacy',
  local_parking: 'local_parking',
  local_offer: 'local_offer',
  local_movies: 'local_movies',
  local_mall: 'local_mall',
  local_library: 'local_library',
  local_laundry_service: 'local_laundry_service',
  local_hotel: 'local_hotel',
  local_hospital: 'local_hospital',
  local_grocery_store: 'local_grocery_store',
  local_gas_station: 'local_gas_station',
  local_florist: 'local_florist',
  local_fire_department: 'local_fire_department',
  local_drink: 'local_drink',
  local_dining: 'local_dining',
  local_convenience_store: 'local_convenience_store',
  local_car_wash: 'local_car_wash',
  local_cafe: 'local_cafe',
  local_bar: 'local_bar',
  local_attraction: 'local_attraction',
  local_atm: 'local_atm',
  local_airport: 'local_airport',
  local_activity: 'local_activity',
  living: 'living',
  live_tv: 'live_tv',
  live_help: 'live_help',
  list_alt: 'list_alt',
  list: 'list',
  liquor: 'liquor',
  linked_camera: 'linked_camera',
  link_off: 'link_off',
  link: 'link',
  linear_scale: 'linear_scale',
  line_weight: 'line_weight',
  line_style: 'line_style',
  line_axis: 'line_axis',
  lightbulb_outline: 'lightbulb_outline',
  lightbulb_circle: 'lightbulb_circle',
  lightbulb: 'lightbulb',
  light_mode: 'light_mode',
  light: 'light',
  library_music: 'library_music',
  library_books: 'library_books',
  library_add_check: 'library_add_check',
  library_add: 'library_add',
  lens_blur: 'lens_blur',
  lens: 'lens',
  legend_toggle: 'legend_toggle',
  leave_bags_at_home: 'leave_bags_at_home',
  leak_remove: 'leak_remove',
  leak_add: 'leak_add',
  leaderboard: 'leaderboard',
  layers_clear: 'layers_clear',
  layers: 'layers',
  launch: 'launch',
  last_page: 'last_page',
  laptop_windows: 'laptop_windows',
  laptop_mac: 'laptop_mac',
  laptop_chromebook: 'laptop_chromebook',
  laptop: 'laptop',
  language: 'language',
  landslide: 'landslide',
  landscape: 'landscape',
  lan: 'lan',
  label_outline: 'label_outline',
  label_off: 'label_off',
  label_important_outline: 'label_important_outline',
  label_important: 'label_important',
  label: 'label',
  my_location: 'my_location',
  my_library_music: 'my_library_music',
  my_library_books: 'my_library_books',
  my_library_add: 'my_library_add',
  music_video: 'music_video',
  music_off: 'music_off',
  music_note: 'music_note',
  museum: 'museum',
  multitrack_audio: 'multitrack_audio',
  multiple_stop: 'multiple_stop',
  multiline_chart: 'multiline_chart',
  mp: 'mp',
  moving: 'moving',
  movie_filter: 'movie_filter',
  movie_creation: 'movie_creation',
  movie: 'movie',
  move_up: 'move_up',
  move_to_inbox: 'move_to_inbox',
  move_down: 'move_down',
  mouse: 'mouse',
  motorcycle: 'motorcycle',
  motion_photos_paused: 'motion_photos_paused',
  motion_photos_pause: 'motion_photos_pause',
  motion_photos_on: 'motion_photos_on',
  motion_photos_off: 'motion_photos_off',
  motion_photos_auto: 'motion_photos_auto',
  mosque: 'mosque',
  more_vert: 'more_vert',
  more_time: 'more_time',
  more_horiz: 'more_horiz',
  more: 'more',
  moped: 'moped',
  mood_bad: 'mood_bad',
  mood: 'mood',
  monochrome_photos: 'monochrome_photos',
  monitor_weight: 'monitor_weight',
  monitor_heart: 'monitor_heart',
  monitor: 'monitor',
  money_off_csred: 'money_off_csred',
  money_off: 'money_off',
  money: 'money',
  monetization_on: 'monetization_on',
  model_training: 'model_training',
  mode_standby: 'mode_standby',
  mode_of_travel: 'mode_of_travel',
  mode_night: 'mode_night',
  mode_fan_off: 'mode_fan_off',
  mode_edit_outline: 'mode_edit_outline',
  mode_edit: 'mode_edit',
  mode_comment: 'mode_comment',
  mode: 'mode',
  mobiledata_off: 'mobiledata_off',
  mobile_screen_share: 'mobile_screen_share',
  mobile_off: 'mobile_off',
  mobile_friendly: 'mobile_friendly',
  missed_video_call: 'missed_video_call',
  miscellaneous_services: 'miscellaneous_services',
  minor_crash: 'minor_crash',
  minimize: 'minimize',
  military_tech: 'military_tech',
  microwave: 'microwave',
  mic_off: 'mic_off',
  mic_none: 'mic_none',
  mic_external_on: 'mic_external_on',
  mic_external_off: 'mic_external_off',
  mic: 'mic',
  messenger_outline: 'messenger_outline',
  messenger: 'messenger',
  message: 'message',
  merge_type: 'merge_type',
  merge: 'merge',
  menu_open: 'menu_open',
  menu_book: 'menu_book',
  menu: 'menu',
  memory: 'memory',
  meeting_room: 'meeting_room',
  medication_liquid: 'medication_liquid',
  medication: 'medication',
  medical_services: 'medical_services',
  medical_information: 'medical_information',
  mediation: 'mediation',
  media_bluetooth_on: 'media_bluetooth_on',
  media_bluetooth_off: 'media_bluetooth_off',
  maximize: 'maximize',
  masks: 'masks',
  markunread_mailbox: 'markunread_mailbox',
  markunread: 'markunread',
  mark_unread_chat_alt: 'mark_unread_chat_alt',
  mark_email_unread: 'mark_email_unread',
  mark_email_read: 'mark_email_read',
  mark_chat_unread: 'mark_chat_unread',
  mark_chat_read: 'mark_chat_read',
  mark_as_unread: 'mark_as_unread',
  margin: 'margin',
  maps_ugc: 'maps_ugc',
  maps_home_work: 'maps_home_work',
  map: 'map',
  manage_search: 'manage_search',
  manage_history: 'manage_history',
  manage_accounts: 'manage_accounts',
  man: 'man',
  male: 'male',
  mail_outline: 'mail_outline',
  mail_lock: 'mail_lock',
  mail: 'mail',
  numbers: 'numbers',
  now_widgets: 'now_widgets',
  now_wallpaper: 'now_wallpaper',
  notifications_paused: 'notifications_paused',
  notifications_on: 'notifications_on',
  notifications_off: 'notifications_off',
  notifications_none: 'notifications_none',
  notifications_active: 'notifications_active',
  notifications: 'notifications',
  notification_important: 'notification_important',
  notification_add: 'notification_add',
  notes: 'notes',
  note_alt: 'note_alt',
  note_add: 'note_add',
  note: 'note',
  not_started: 'not_started',
  not_listed_location: 'not_listed_location',
  not_interested: 'not_interested',
  not_accessible: 'not_accessible',
  north_west: 'north_west',
  north_east: 'north_east',
  north: 'north',
  nordic_walking: 'nordic_walking',
  noise_control_off: 'noise_control_off',
  noise_aware: 'noise_aware',
  no_transfer: 'no_transfer',
  no_stroller: 'no_stroller',
  no_sim: 'no_sim',
  no_photography: 'no_photography',
  no_meeting_room: 'no_meeting_room',
  no_meals_ouline: 'no_meals_ouline',
  no_meals: 'no_meals',
  no_luggage: 'no_luggage',
  no_food: 'no_food',
  no_flash: 'no_flash',
  no_encryption_gmailerrorred: 'no_encryption_gmailerrorred',
  no_encryption: 'no_encryption',
  no_drinks: 'no_drinks',
  no_crash: 'no_crash',
  no_cell: 'no_cell',
  no_backpack: 'no_backpack',
  no_adult_content: 'no_adult_content',
  no_accounts: 'no_accounts',
  nights_stay: 'nights_stay',
  nightlight_round: 'nightlight_round',
  nightlight: 'nightlight',
  nightlife: 'nightlife',
  night_shelter: 'night_shelter',
  nfc: 'nfc',
  next_week: 'next_week',
  next_plan: 'next_plan',
  newspaper: 'newspaper',
  new_releases: 'new_releases',
  new_label: 'new_label',
  network_wifi_3_bar: 'network_wifi_3_bar',
  network_wifi_2_bar: 'network_wifi_2_bar',
  network_wifi_1_bar: 'network_wifi_1_bar',
  network_wifi: 'network_wifi',
  network_ping: 'network_ping',
  network_locked: 'network_locked',
  network_check: 'network_check',
  network_cell: 'network_cell',
  nest_cam_wired_stand: 'nest_cam_wired_stand',
  nearby_off: 'nearby_off',
  nearby_error: 'nearby_error',
  near_me_disabled: 'near_me_disabled',
  near_me: 'near_me',
  navigation: 'navigation',
  navigate_next: 'navigate_next',
  navigate_before: 'navigate_before',
  nature_people: 'nature_people',
  nature: 'nature',
  nat: 'nat',
  output: 'output',
  outlined_flag: 'outlined_flag',
  outlet: 'outlet',
  outgoing_mail: 'outgoing_mail',
  outdoor_grill: 'outdoor_grill',
  outbox: 'outbox',
  outbound: 'outbound',
  outbond: 'outbond',
  other_houses: 'other_houses',
  open_with: 'open_with',
  open_in_new_off: 'open_in_new_off',
  open_in_new: 'open_in_new',
  open_in_full: 'open_in_full',
  open_in_browser: 'open_in_browser',
  opacity: 'opacity',
  online_prediction: 'online_prediction',
  ondemand_video: 'ondemand_video',
  on_device_training: 'on_device_training',
  oil_barrel: 'oil_barrel',
  offline_share: 'offline_share',
  offline_pin: 'offline_pin',
  offline_bolt: 'offline_bolt',
  push_pin: 'push_pin',
  punch_clock: 'punch_clock',
  published_with_changes: 'published_with_changes',
  publish: 'publish',
  public_off: 'public_off',
  public: 'public',
  psychology_alt: 'psychology_alt',
  psychology: 'psychology',
  propane_tank: 'propane_tank',
  propane: 'propane',
  production_quantity_limits: 'production_quantity_limits',
  private_connectivity: 'private_connectivity',
  privacy_tip: 'privacy_tip',
  priority_high: 'priority_high',
  print_disabled: 'print_disabled',
  print: 'print',
  price_check: 'price_check',
  price_change: 'price_change',
  preview: 'preview',
  present_to_all: 'present_to_all',
  pregnant_woman: 'pregnant_woman',
  precision_manufacturing: 'precision_manufacturing',
  power_settings_new: 'power_settings_new',
  power_off: 'power_off',
  power_input: 'power_input',
  power: 'power',
  post_add: 'post_add',
  portrait: 'portrait',
  portable_wifi_off: 'portable_wifi_off',
  pool: 'pool',
  polymer: 'polymer',
  polyline: 'polyline',
  poll: 'poll',
  policy: 'policy',
  point_of_sale: 'point_of_sale',
  podcasts: 'podcasts',
  plus_one: 'plus_one',
  plumbing: 'plumbing',
  playlist_remove: 'playlist_remove',
  playlist_play: 'playlist_play',
  playlist_add_circle: 'playlist_add_circle',
  playlist_add_check_circle: 'playlist_add_check_circle',
  playlist_add_check: 'playlist_add_check',
  playlist_add: 'playlist_add',
  play_lesson: 'play_lesson',
  play_for_work: 'play_for_work',
  play_disabled: 'play_disabled',
  play_circle_outline: 'play_circle_outline',
  play_circle_filled: 'play_circle_filled',
  play_circle_fill: 'play_circle_fill',
  play_circle: 'play_circle',
  play_arrow: 'play_arrow',
  plagiarism: 'plagiarism',
  place: 'place',
  pix: 'pix',
  pivot_table_chart: 'pivot_table_chart',
  pinch: 'pinch',
  pin_invoke: 'pin_invoke',
  pin_end: 'pin_end',
  pin_drop: 'pin_drop',
  pin: 'pin',
  pie_chart_outlined: 'pie_chart_outlined',
  pie_chart_outline: 'pie_chart_outline',
  pie_chart: 'pie_chart',
  picture_in_picture_alt: 'picture_in_picture_alt',
  picture_in_picture: 'picture_in_picture',
  picture_as_pdf: 'picture_as_pdf',
  piano_off: 'piano_off',
  piano: 'piano',
  php: 'php',
  photo_size_select_small: 'photo_size_select_small',
  photo_size_select_large: 'photo_size_select_large',
  photo_size_select_actual: 'photo_size_select_actual',
  photo_library: 'photo_library',
  photo_filter: 'photo_filter',
  photo_camera_front: 'photo_camera_front',
  photo_camera_back: 'photo_camera_back',
  photo_camera: 'photo_camera',
  photo_album: 'photo_album',
  photo: 'photo',
  phonelink_setup: 'phonelink_setup',
  phonelink_ring: 'phonelink_ring',
  phonelink_off: 'phonelink_off',
  phonelink_lock: 'phonelink_lock',
  phonelink_erase: 'phonelink_erase',
  phonelink: 'phonelink',
  phone_paused: 'phone_paused',
  phone_missed: 'phone_missed',
  phone_locked: 'phone_locked',
  phone_iphone: 'phone_iphone',
  phone_in_talk: 'phone_in_talk',
  phone_forwarded: 'phone_forwarded',
  phone_enabled: 'phone_enabled',
  phone_disabled: 'phone_disabled',
  phone_callback: 'phone_callback',
  phone_bluetooth_speaker: 'phone_bluetooth_speaker',
  phone_android: 'phone_android',
  phone: 'phone',
  phishing: 'phishing',
  pets: 'pets',
  pest_control_rodent: 'pest_control_rodent',
  pest_control: 'pest_control',
  personal_video: 'personal_video',
  personal_injury: 'personal_injury',
  person_search: 'person_search',
  person_remove_alt_1: 'person_remove_alt_1',
  person_remove: 'person_remove',
  person_pin_circle: 'person_pin_circle',
  person_pin: 'person_pin',
  person_outline: 'person_outline',
  person_off: 'person_off',
  person_add_disabled: 'person_add_disabled',
  person_add_alt_1: 'person_add_alt_1',
  person_add_alt: 'person_add_alt',
  person_add: 'person_add',
  person: 'person',
  perm_scan_wifi: 'perm_scan_wifi',
  perm_phone_msg: 'perm_phone_msg',
  perm_media: 'perm_media',
  perm_identity: 'perm_identity',
  perm_device_information: 'perm_device_information',
  perm_device_info: 'perm_device_info',
  perm_data_setting: 'perm_data_setting',
  perm_contact_calendar: 'perm_contact_calendar',
  perm_contact_cal: 'perm_contact_cal',
  perm_camera_mic: 'perm_camera_mic',
  percent: 'percent',
  people_outline: 'people_outline',
  people_alt: 'people_alt',
  people: 'people',
  pentagon: 'pentagon',
  pending_actions: 'pending_actions',
  pending: 'pending',
  pedal_bike: 'pedal_bike',
  paypal: 'paypal',
  payments: 'payments',
  payment: 'payment',
  pause_presentation: 'pause_presentation',
  pause_circle_outline: 'pause_circle_outline',
  pause_circle_filled: 'pause_circle_filled',
  pause_circle: 'pause_circle',
  pause: 'pause',
  pattern: 'pattern',
  password: 'password',
  party_mode: 'party_mode',
  park: 'park',
  paragliding: 'paragliding',
  panorama_wide_angle_select: 'panorama_wide_angle_select',
  panorama_wide_angle: 'panorama_wide_angle',
  panorama_vertical_select: 'panorama_vertical_select',
  panorama_vertical: 'panorama_vertical',
  panorama_photosphere_select: 'panorama_photosphere_select',
  panorama_photosphere: 'panorama_photosphere',
  panorama_horizontal_select: 'panorama_horizontal_select',
  panorama_horizontal: 'panorama_horizontal',
  panorama_fisheye: 'panorama_fisheye',
  panorama_fish_eye: 'panorama_fish_eye',
  panorama: 'panorama',
  pan_tool_alt: 'pan_tool_alt',
  pan_tool: 'pan_tool',
  palette: 'palette',
  paid: 'paid',
  pageview: 'pageview',
  pages: 'pages',
  padding: 'padding',
  quora: 'quora',
  quiz: 'quiz',
  quickreply: 'quickreply',
  quick_contacts_mail: 'quick_contacts_mail',
  quick_contacts_dialer: 'quick_contacts_dialer',
  queue_play_next: 'queue_play_next',
  queue_music: 'queue_music',
  queue: 'queue',
  question_mark: 'question_mark',
  question_answer: 'question_answer',
  query_stats: 'query_stats',
  query_builder: 'query_builder',
  qr_code_scanner: 'qr_code_scanner',
  qr_code_2: 'qr_code_2',
  qr_code: 'qr_code',
  rv_hookup: 'rv_hookup',
  running_with_errors: 'running_with_errors',
  run_circle: 'run_circle',
  rule_folder: 'rule_folder',
  rule: 'rule',
  rtt: 'rtt',
  rsvp: 'rsvp',
  rss_feed: 'rss_feed',
  rowing: 'rowing',
  router: 'router',
  route: 'route',
  rounded_corner: 'rounded_corner',
  roundabout_right: 'roundabout_right',
  roundabout_left: 'roundabout_left',
  rotate_right: 'rotate_right',
  rotate_left: 'rotate_left',
  rotate_90_degrees_cw: 'rotate_90_degrees_cw',
  rotate_90_degrees_ccw: 'rotate_90_degrees_ccw',
  room_service: 'room_service',
  room_preferences: 'room_preferences',
  room: 'room',
  roofing: 'roofing',
  roller_skating: 'roller_skating',
  roller_shades_closed: 'roller_shades_closed',
  roller_shades: 'roller_shades',
  rocket_launch: 'rocket_launch',
  rocket: 'rocket',
  ring_volume: 'ring_volume',
  rice_bowl: 'rice_bowl',
  reviews: 'reviews',
  restore_page: 'restore_page',
  restore_from_trash: 'restore_from_trash',
  restore: 'restore',
  restaurant_menu: 'restaurant_menu',
  restaurant: 'restaurant',
  restart_alt: 'restart_alt',
  reset_tv: 'reset_tv',
  request_quote: 'request_quote',
  request_page: 'request_page',
  report_problem: 'report_problem',
  report_off: 'report_off',
  report_gmailerrorred: 'report_gmailerrorred',
  report: 'report',
  reply_all: 'reply_all',
  reply: 'reply',
  replay_circle_filled: 'replay_circle_filled',
  replay_5: 'replay_5',
  replay_30: 'replay_30',
  replay_10: 'replay_10',
  replay: 'replay',
  repeat_one_on: 'repeat_one_on',
  repeat_one: 'repeat_one',
  repeat_on: 'repeat_on',
  repeat: 'repeat',
  repartition: 'repartition',
  reorder: 'reorder',
  remove_shopping_cart: 'remove_shopping_cart',
  remove_road: 'remove_road',
  remove_red_eye: 'remove_red_eye',
  remove_moderator: 'remove_moderator',
  remove_from_queue: 'remove_from_queue',
  remove_done: 'remove_done',
  remove_circle_outline: 'remove_circle_outline',
  remove_circle: 'remove_circle',
  remove: 'remove',
  remember_me: 'remember_me',
  refresh: 'refresh',
  reduce_capacity: 'reduce_capacity',
  redo: 'redo',
  redeem: 'redeem',
  reddit: 'reddit',
  recycling: 'recycling',
  rectangle: 'rectangle',
  record_voice_over: 'record_voice_over',
  recommend: 'recommend',
  recent_actors: 'recent_actors',
  receipt_long: 'receipt_long',
  receipt: 'receipt',
  real_estate_agent: 'real_estate_agent',
  read_more: 'read_more',
  raw_on: 'raw_on',
  raw_off: 'raw_off',
  rate_review: 'rate_review',
  ramp_right: 'ramp_right',
  ramp_left: 'ramp_left',
  ramen_dining: 'ramen_dining',
  railway_alert: 'railway_alert',
  radio_button_unchecked: 'radio_button_unchecked',
  radio_button_on: 'radio_button_on',
  radio_button_off: 'radio_button_off',
  radio_button_checked: 'radio_button_checked',
  radio: 'radio',
  radar: 'radar',
  r_mobiledata: 'r_mobiledata',
  system_update_tv: 'system_update_tv',
  system_update_alt: 'system_update_alt',
  system_update: 'system_update',
  system_security_update_warning: 'system_security_update_warning',
  system_security_update_good: 'system_security_update_good',
  system_security_update: 'system_security_update',
  sync_problem: 'sync_problem',
  sync_lock: 'sync_lock',
  sync_disabled: 'sync_disabled',
  sync_alt: 'sync_alt',
  sync: 'sync',
  synagogue: 'synagogue',
  switch_video: 'switch_video',
  switch_right: 'switch_right',
  switch_left: 'switch_left',
  switch_camera: 'switch_camera',
  switch_account: 'switch_account',
  switch_access_shortcut_add: 'switch_access_shortcut_add',
  switch_access_shortcut: 'switch_access_shortcut',
  swipe_vertical: 'swipe_vertical',
  swipe_up_alt: 'swipe_up_alt',
  swipe_up: 'swipe_up',
  swipe_right_alt: 'swipe_right_alt',
  swipe_right: 'swipe_right',
  swipe_left_alt: 'swipe_left_alt',
  swipe_left: 'swipe_left',
  swipe_down_alt: 'swipe_down_alt',
  swipe_down: 'swipe_down',
  swipe: 'swipe',
  swap_vertical_circle: 'swap_vertical_circle',
  swap_vert_circle: 'swap_vert_circle',
  swap_vert: 'swap_vert',
  swap_horizontal_circle: 'swap_horizontal_circle',
  swap_horiz: 'swap_horiz',
  swap_calls: 'swap_calls',
  surround_sound: 'surround_sound',
  surfing: 'surfing',
  support_agent: 'support_agent',
  support: 'support',
  supervisor_account: 'supervisor_account',
  supervised_user_circle: 'supervised_user_circle',
  superscript: 'superscript',
  sunny_snowing: 'sunny_snowing',
  sunny: 'sunny',
  summarize: 'summarize',
  subway: 'subway',
  subtitles_off: 'subtitles_off',
  subtitles: 'subtitles',
  subscriptions: 'subscriptions',
  subscript: 'subscript',
  subject: 'subject',
  subdirectory_arrow_right: 'subdirectory_arrow_right',
  subdirectory_arrow_left: 'subdirectory_arrow_left',
  style: 'style',
  stroller: 'stroller',
  strikethrough_s: 'strikethrough_s',
  streetview: 'streetview',
  stream: 'stream',
  straighten: 'straighten',
  straight: 'straight',
  storm: 'storm',
  storefront: 'storefront',
  store_mall_directory: 'store_mall_directory',
  store: 'store',
  storage: 'storage',
  stop_screen_share: 'stop_screen_share',
  stop_circle: 'stop_circle',
  stop: 'stop',
  sticky_note_2: 'sticky_note_2',
  stay_primary_portrait: 'stay_primary_portrait',
  stay_primary_landscape: 'stay_primary_landscape',
  stay_current_portrait: 'stay_current_portrait',
  stay_current_landscape: 'stay_current_landscape',
  start: 'start',
  stars: 'stars',
  star_rate: 'star_rate',
  star_purple500: 'star_purple500',
  star_outline: 'star_outline',
  star_half: 'star_half',
  star_border_purple500: 'star_border_purple500',
  star_border: 'star_border',
  star: 'star',
  stairs: 'stairs',
  stadium: 'stadium',
  stacked_line_chart: 'stacked_line_chart',
  stacked_bar_chart: 'stacked_bar_chart',
  ssid_chart: 'ssid_chart',
  square_foot: 'square_foot',
  square: 'square',
  sports_volleyball: 'sports_volleyball',
  sports_tennis: 'sports_tennis',
  sports_soccer: 'sports_soccer',
  sports_score: 'sports_score',
  sports_rugby: 'sports_rugby',
  sports_motorsports: 'sports_motorsports',
  sports_mma: 'sports_mma',
  sports_martial_arts: 'sports_martial_arts',
  sports_kabaddi: 'sports_kabaddi',
  sports_hockey: 'sports_hockey',
  sports_handball: 'sports_handball',
  sports_gymnastics: 'sports_gymnastics',
  sports_golf: 'sports_golf',
  sports_football: 'sports_football',
  sports_esports: 'sports_esports',
  sports_cricket: 'sports_cricket',
  sports_basketball: 'sports_basketball',
  sports_baseball: 'sports_baseball',
  sports_bar: 'sports_bar',
  sports: 'sports',
  spoke: 'spoke',
  splitscreen: 'splitscreen',
  spellcheck: 'spellcheck',
  speed: 'speed',
  speaker_phone: 'speaker_phone',
  speaker_notes_off: 'speaker_notes_off',
  speaker_notes: 'speaker_notes',
  speaker_group: 'speaker_group',
  speaker: 'speaker',
  spatial_tracking: 'spatial_tracking',
  spatial_audio_off: 'spatial_audio_off',
  spatial_audio: 'spatial_audio',
  space_dashboard: 'space_dashboard',
  space_bar: 'space_bar',
  spa: 'spa',
  south_west: 'south_west',
  south_east: 'south_east',
  south_america: 'south_america',
  south: 'south',
  source: 'source',
  soup_kitchen: 'soup_kitchen',
  sos: 'sos',
  sort_by_alpha: 'sort_by_alpha',
  sort: 'sort',
  solar_power: 'solar_power',
  social_distance: 'social_distance',
  soap: 'soap',
  snowshoeing: 'snowshoeing',
  snowmobile: 'snowmobile',
  snowing: 'snowing',
  snowboarding: 'snowboarding',
  snooze: 'snooze',
  snippet_folder: 'snippet_folder',
  snapchat: 'snapchat',
  sms_failed: 'sms_failed',
  sms: 'sms',
  smoking_rooms: 'smoking_rooms',
  smoke_free: 'smoke_free',
  smartphone: 'smartphone',
  smart_toy: 'smart_toy',
  smart_screen: 'smart_screen',
  smart_display: 'smart_display',
  smart_button: 'smart_button',
  slow_motion_video: 'slow_motion_video',
  slideshow: 'slideshow',
  sledding: 'sledding',
  skip_previous: 'skip_previous',
  skip_next: 'skip_next',
  skateboarding: 'skateboarding',
  sip: 'sip',
  single_bed: 'single_bed',
  sim_card_download: 'sim_card_download',
  sim_card_alert: 'sim_card_alert',
  sim_card: 'sim_card',
  signpost: 'signpost',
  signal_wifi_statusbar_null: 'signal_wifi_statusbar_null',
  signal_wifi_statusbar_connected_no_internet_4: 'signal_wifi_statusbar_connected_no_internet_4',
  signal_wifi_statusbar_4_bar: 'signal_wifi_statusbar_4_bar',
  signal_wifi_off: 'signal_wifi_off',
  signal_wifi_connected_no_internet_4: 'signal_wifi_connected_no_internet_4',
  signal_wifi_bad: 'signal_wifi_bad',
  signal_wifi_4_bar_lock: 'signal_wifi_4_bar_lock',
  signal_wifi_4_bar: 'signal_wifi_4_bar',
  signal_wifi_0_bar: 'signal_wifi_0_bar',
  signal_cellular_off: 'signal_cellular_off',
  signal_cellular_null: 'signal_cellular_null',
  signal_cellular_nodata: 'signal_cellular_nodata',
  signal_cellular_no_sim: 'signal_cellular_no_sim',
  signal_cellular_connected_no_internet_4_bar: 'signal_cellular_connected_no_internet_4_bar',
  signal_cellular_connected_no_internet_0_bar: 'signal_cellular_connected_no_internet_0_bar',
  signal_cellular_alt_2_bar: 'signal_cellular_alt_2_bar',
  signal_cellular_alt_1_bar: 'signal_cellular_alt_1_bar',
  signal_cellular_alt: 'signal_cellular_alt',
  signal_cellular_4_bar: 'signal_cellular_4_bar',
  signal_cellular_0_bar: 'signal_cellular_0_bar',
  sign_language: 'sign_language',
  sick: 'sick',
  shutter_speed: 'shutter_speed',
  shuffle_on: 'shuffle_on',
  shuffle: 'shuffle',
  shower: 'shower',
  show_chart: 'show_chart',
  shortcut: 'shortcut',
  short_text: 'short_text',
  shopping_cart_checkout: 'shopping_cart_checkout',
  shopping_cart: 'shopping_cart',
  shopping_basket: 'shopping_basket',
  shopping_bag: 'shopping_bag',
  shopify: 'shopify',
  shop_two: 'shop_two',
  shop_2: 'shop_2',
  shop: 'shop',
  shield_moon: 'shield_moon',
  shield: 'shield',
  share_location: 'share_location',
  share_arrival_time: 'share_arrival_time',
  share: 'share',
  severe_cold: 'severe_cold',
  settings_voice: 'settings_voice',
  settings_system_daydream: 'settings_system_daydream',
  settings_suggest: 'settings_suggest',
  settings_remote: 'settings_remote',
  settings_power: 'settings_power',
  settings_phone: 'settings_phone',
  settings_overscan: 'settings_overscan',
  settings_input_svideo: 'settings_input_svideo',
  settings_input_hdmi: 'settings_input_hdmi',
  settings_input_composite: 'settings_input_composite',
  settings_input_component: 'settings_input_component',
  settings_input_antenna: 'settings_input_antenna',
  settings_ethernet: 'settings_ethernet',
  settings_display: 'settings_display',
  settings_cell: 'settings_cell',
  settings_brightness: 'settings_brightness',
  settings_bluetooth: 'settings_bluetooth',
  settings_backup_restore: 'settings_backup_restore',
  settings_applications: 'settings_applications',
  settings_accessibility: 'settings_accessibility',
  settings: 'settings',
  set_meal: 'set_meal',
  sentiment_very_satisfied: 'sentiment_very_satisfied',
  sentiment_very_dissatisfied: 'sentiment_very_dissatisfied',
  sentiment_satisfied_alt: 'sentiment_satisfied_alt',
  sentiment_satisfied: 'sentiment_satisfied',
  sentiment_neutral: 'sentiment_neutral',
  sentiment_dissatisfied: 'sentiment_dissatisfied',
  sensors_off: 'sensors_off',
  sensors: 'sensors',
  sensor_window: 'sensor_window',
  sensor_occupied: 'sensor_occupied',
  sensor_door: 'sensor_door',
  send_to_mobile: 'send_to_mobile',
  send_time_extension: 'send_time_extension',
  send_and_archive: 'send_and_archive',
  send: 'send',
  sell: 'sell',
  self_improvement: 'self_improvement',
  select_all: 'select_all',
  segment: 'segment',
  security_update_warning: 'security_update_warning',
  security_update_good: 'security_update_good',
  security_update: 'security_update',
  security: 'security',
  search_off: 'search_off',
  search: 'search',
  sd_storage: 'sd_storage',
  sd_card_alert: 'sd_card_alert',
  sd_card: 'sd_card',
  sd: 'sd',
  scuba_diving: 'scuba_diving',
  screenshot_monitor: 'screenshot_monitor',
  screenshot: 'screenshot',
  screen_share: 'screen_share',
  screen_search_desktop: 'screen_search_desktop',
  screen_rotation_alt: 'screen_rotation_alt',
  screen_rotation: 'screen_rotation',
  screen_lock_rotation: 'screen_lock_rotation',
  screen_lock_portrait: 'screen_lock_portrait',
  screen_lock_landscape: 'screen_lock_landscape',
  scoreboard: 'scoreboard',
  score: 'score',
  science: 'science',
  school: 'school',
  schema: 'schema',
  schedule_send: 'schedule_send',
  schedule: 'schedule',
  scatter_plot: 'scatter_plot',
  scanner: 'scanner',
  scale: 'scale',
  savings: 'savings',
  saved_search: 'saved_search',
  save_as: 'save_as',
  save_alt: 'save_alt',
  save: 'save',
  satellite_alt: 'satellite_alt',
  satellite: 'satellite',
  sanitizer: 'sanitizer',
  sailing: 'sailing',
  safety_divider: 'safety_divider',
  safety_check: 'safety_check',
  type_specimen: 'type_specimen',
  two_wheeler: 'two_wheeler',
  tv_off: 'tv_off',
  tv: 'tv',
  turned_in_not: 'turned_in_not',
  turned_in: 'turned_in',
  turn_slight_right: 'turn_slight_right',
  turn_slight_left: 'turn_slight_left',
  turn_sharp_right: 'turn_sharp_right',
  turn_sharp_left: 'turn_sharp_left',
  turn_right: 'turn_right',
  turn_left: 'turn_left',
  tungsten: 'tungsten',
  tune: 'tune',
  tty: 'tty',
  tsunami: 'tsunami',
  try: 'try',
  troubleshoot: 'troubleshoot',
  trip_origin: 'trip_origin',
  trending_up: 'trending_up',
  trending_neutral: 'trending_neutral',
  trending_flat: 'trending_flat',
  trending_down: 'trending_down',
  travel_explore: 'travel_explore',
  translate: 'translate',
  transit_enterexit: 'transit_enterexit',
  transgender: 'transgender',
  transform: 'transform',
  transfer_within_a_station: 'transfer_within_a_station',
  transcribe: 'transcribe',
  tram: 'tram',
  train: 'train',
  traffic: 'traffic',
  track_changes: 'track_changes',
  toys: 'toys',
  tour: 'tour',
  touch_app: 'touch_app',
  tornado: 'tornado',
  topic: 'topic',
  tonality: 'tonality',
  toll: 'toll',
  token: 'token',
  toggle_on: 'toggle_on',
  toggle_off: 'toggle_off',
  today: 'today',
  toc: 'toc',
  title: 'title',
  tire_repair: 'tire_repair',
  tips_and_updates: 'tips_and_updates',
  timer_off: 'timer_off',
  timer_3_select: 'timer_3_select',
  timer_3: 'timer_3',
  timer_10_select: 'timer_10_select',
  timer_10: 'timer_10',
  timer: 'timer',
  timeline: 'timeline',
  timelapse: 'timelapse',
  time_to_leave: 'time_to_leave',
  tiktok: 'tiktok',
  thunderstorm: 'thunderstorm',
  thumbs_up_down: 'thumbs_up_down',
  thumb_up_off_alt: 'thumb_up_off_alt',
  thumb_up_alt: 'thumb_up_alt',
  thumb_up: 'thumb_up',
  thumb_down_off_alt: 'thumb_down_off_alt',
  thumb_down_alt: 'thumb_down_alt',
  thumb_down: 'thumb_down',
  thermostat_auto: 'thermostat_auto',
  thermostat: 'thermostat',
  theaters: 'theaters',
  theater_comedy: 'theater_comedy',
  texture: 'texture',
  textsms: 'textsms',
  text_snippet: 'text_snippet',
  text_rotation_none: 'text_rotation_none',
  text_rotation_down: 'text_rotation_down',
  text_rotation_angleup: 'text_rotation_angleup',
  text_rotation_angledown: 'text_rotation_angledown',
  text_rotate_vertical: 'text_rotate_vertical',
  text_rotate_up: 'text_rotate_up',
  text_increase: 'text_increase',
  text_format: 'text_format',
  text_fields: 'text_fields',
  text_decrease: 'text_decrease',
  terrain: 'terrain',
  terminal: 'terminal',
  temple_hindu: 'temple_hindu',
  temple_buddhist: 'temple_buddhist',
  telegram: 'telegram',
  taxi_alert: 'taxi_alert',
  task_alt: 'task_alt',
  task: 'task',
  tapas: 'tapas',
  tap_and_play: 'tap_and_play',
  takeout_dining: 'takeout_dining',
  tag_faces: 'tag_faces',
  tag: 'tag',
  tablet_mac: 'tablet_mac',
  tablet_android: 'tablet_android',
  tablet: 'tablet',
  table_view: 'table_view',
  table_rows: 'table_rows',
  table_restaurant: 'table_restaurant',
  table_chart: 'table_chart',
  table_bar: 'table_bar',
  tab_unselected: 'tab_unselected',
  tab: 'tab',
  usb_off: 'usb_off',
  usb: 'usb',
  upload_file: 'upload_file',
  upload: 'upload',
  upgrade: 'upgrade',
  update_disabled: 'update_disabled',
  update: 'update',
  upcoming: 'upcoming',
  unsubscribe: 'unsubscribe',
  unpublished: 'unpublished',
  unfold_more: 'unfold_more',
  unfold_less: 'unfold_less',
  undo: 'undo',
  unarchive: 'unarchive',
  umbrella: 'umbrella',
  u_turn_right: 'u_turn_right',
  u_turn_left: 'u_turn_left',
  vrpano: 'vrpano',
  vpn_lock: 'vpn_lock',
  vpn_key_off: 'vpn_key_off',
  vpn_key: 'vpn_key',
  volunteer_activism: 'volunteer_activism',
  volume_up: 'volume_up',
  volume_off: 'volume_off',
  volume_mute: 'volume_mute',
  volume_down_alt: 'volume_down_alt',
  volume_down: 'volume_down',
  volcano: 'volcano',
  voicemail: 'voicemail',
  voice_over_off: 'voice_over_off',
  voice_chat: 'voice_chat',
  visibility_off: 'visibility_off',
  visibility: 'visibility',
  villa: 'villa',
  vignette: 'vignette',
  view_week: 'view_week',
  view_timeline: 'view_timeline',
  view_stream: 'view_stream',
  view_sidebar: 'view_sidebar',
  view_quilt: 'view_quilt',
  view_module: 'view_module',
  view_list: 'view_list',
  view_kanban: 'view_kanban',
  view_in_ar: 'view_in_ar',
  view_headline: 'view_headline',
  view_day: 'view_day',
  view_cozy: 'view_cozy',
  view_compact_alt: 'view_compact_alt',
  view_compact: 'view_compact',
  view_comfy_alt: 'view_comfy_alt',
  view_comfy: 'view_comfy',
  view_comfortable: 'view_comfortable',
  view_column: 'view_column',
  view_carousel: 'view_carousel',
  view_array: 'view_array',
  view_agenda: 'view_agenda',
  videogame_asset_off: 'videogame_asset_off',
  videogame_asset: 'videogame_asset',
  videocam_off: 'videocam_off',
  videocam: 'videocam',
  video_stable: 'video_stable',
  video_settings: 'video_settings',
  video_library: 'video_library',
  video_label: 'video_label',
  video_file: 'video_file',
  video_collection: 'video_collection',
  video_camera_front: 'video_camera_front',
  video_camera_back: 'video_camera_back',
  video_call: 'video_call',
  vibration: 'vibration',
  vertical_split: 'vertical_split',
  vertical_shades_closed: 'vertical_shades_closed',
  vertical_shades: 'vertical_shades',
  vertical_distribute: 'vertical_distribute',
  vertical_align_top: 'vertical_align_top',
  vertical_align_center: 'vertical_align_center',
  vertical_align_bottom: 'vertical_align_bottom',
  verified_user: 'verified_user',
  verified: 'verified',
  vaping_rooms: 'vaping_rooms',
  vape_free: 'vape_free',
  vaccines: 'vaccines',
  wysiwyg: 'wysiwyg',
  wrong_location: 'wrong_location',
  wrap_text: 'wrap_text',
  workspaces_outline: 'workspaces_outline',
  workspaces_filled: 'workspaces_filled',
  workspaces: 'workspaces',
  workspace_premium: 'workspace_premium',
  work_outline: 'work_outline',
  work_off: 'work_off',
  work_history: 'work_history',
  work: 'work',
  wordpress: 'wordpress',
  woo_commerce: 'woo_commerce',
  woman: 'woman',
  wine_bar: 'wine_bar',
  window: 'window',
  wind_power: 'wind_power',
  wifi_tethering_off: 'wifi_tethering_off',
  wifi_tethering_error_rounded: 'wifi_tethering_error_rounded',
  wifi_tethering_error: 'wifi_tethering_error',
  wifi_tethering: 'wifi_tethering',
  wifi_protected_setup: 'wifi_protected_setup',
  wifi_password: 'wifi_password',
  wifi_off: 'wifi_off',
  wifi_lock: 'wifi_lock',
  wifi_find: 'wifi_find',
  wifi_channel: 'wifi_channel',
  wifi_calling_3: 'wifi_calling_3',
  wifi_calling: 'wifi_calling',
  wifi_2_bar: 'wifi_2_bar',
  wifi_1_bar: 'wifi_1_bar',
  wifi: 'wifi',
  width_wide: 'width_wide',
  width_normal: 'width_normal',
  width_full: 'width_full',
  widgets: 'widgets',
  where_to_vote: 'where_to_vote',
  wheelchair_pickup: 'wheelchair_pickup',
  whatshot: 'whatshot',
  whatsapp: 'whatsapp',
  west: 'west',
  weekend: 'weekend',
  wechat: 'wechat',
  webhook: 'webhook',
  web_stories: 'web_stories',
  web_asset_off: 'web_asset_off',
  web_asset: 'web_asset',
  web: 'web',
  wc: 'wc',
  wb_twilight: 'wb_twilight',
  wb_twighlight: 'wb_twighlight',
  wb_sunny: 'wb_sunny',
  wb_shade: 'wb_shade',
  wb_iridescent: 'wb_iridescent',
  wb_incandescent: 'wb_incandescent',
  wb_cloudy: 'wb_cloudy',
  wb_auto: 'wb_auto',
  waving_hand: 'waving_hand',
  waves: 'waves',
  waterfall_chart: 'waterfall_chart',
  water_drop: 'water_drop',
  water_damage: 'water_damage',
  water: 'water',
  watch_off: 'watch_off',
  watch_later: 'watch_later',
  watch: 'watch',
  wash: 'wash',
  warning_amber: 'warning_amber',
  warning: 'warning',
  warehouse: 'warehouse',
  wallpaper: 'wallpaper',
  wallet_travel: 'wallet_travel',
  wallet_membership: 'wallet_membership',
  wallet_giftcard: 'wallet_giftcard',
  wallet: 'wallet',
  youtube_searched_for: 'youtube_searched_for',
  yard: 'yard',
  zoom_out_map: 'zoom_out_map',
  zoom_out: 'zoom_out',
  zoom_in_map: 'zoom_in_map',
  zoom_in: 'zoom_in'
}

export const iconsIcofont = {
  'angry-monster': 'angry-monster',
  bathtub: 'bathtub',
  'bird-wings': 'bird-wings',
  bow: 'bow',
  castle: 'castle',
  circuit: 'circuit',
  'crown-king': 'crown-king',
  'crown-queen': 'crown-queen',
  dart: 'dart',
  'disability-race': 'disability-race',
  'diving-goggle': 'diving-goggle',
  'eye-open': 'eye-open',
  'flora-flower': 'flora-flower',
  flora: 'flora',
  'gift-box': 'gift-box',
  'halloween-pumpkin': 'halloween-pumpkin',
  'hand-power': 'hand-power',
  'hand-thunder': 'hand-thunder',
  'king-monster': 'king-monster',
  love: 'love',
  'magician-hat': 'magician-hat',
  'native-american': 'native-american',
  'owl-look': 'owl-look',
  phoenix: 'phoenix',
  'robot-face': 'robot-face',
  'sand-clock': 'sand-clock',
  'shield-alt': 'shield-alt',
  'ship-wheel': 'ship-wheel',
  'skull-danger': 'skull-danger',
  'skull-face': 'skull-face',
  snowmobile: 'snowmobile',
  'space-shuttle': 'space-shuttle',
  'star-shape': 'star-shape',
  swirl: 'swirl',
  'tattoo-wing': 'tattoo-wing',
  throne: 'throne',
  'tree-alt': 'tree-alt',
  triangle: 'triangle',
  'unity-hand': 'unity-hand',
  weed: 'weed',
  'woman-bird': 'woman-bird',
  bat: 'bat',
  'bear-face': 'bear-face',
  'bear-tracks': 'bear-tracks',
  bear: 'bear',
  'bird-alt': 'bird-alt',
  'bird-flying': 'bird-flying',
  bird: 'bird',
  birds: 'birds',
  bone: 'bone',
  bull: 'bull',
  'butterfly-alt': 'butterfly-alt',
  butterfly: 'butterfly',
  'camel-alt': 'camel-alt',
  'camel-head': 'camel-head',
  camel: 'camel',
  'cat-alt-1': 'cat-alt-1',
  'cat-alt-2': 'cat-alt-2',
  'cat-alt-3': 'cat-alt-3',
  'cat-dog': 'cat-dog',
  'cat-face': 'cat-face',
  cat: 'cat',
  'cow-head': 'cow-head',
  cow: 'cow',
  crab: 'crab',
  crocodile: 'crocodile',
  'deer-head': 'deer-head',
  'dog-alt': 'dog-alt',
  'dog-barking': 'dog-barking',
  dog: 'dog',
  dolphin: 'dolphin',
  'duck-tracks': 'duck-tracks',
  'eagle-head': 'eagle-head',
  'eaten-fish': 'eaten-fish',
  'elephant-alt': 'elephant-alt',
  'elephant-head-alt': 'elephant-head-alt',
  'elephant-head': 'elephant-head',
  elephant: 'elephant',
  elk: 'elk',
  'fish-1': 'fish-1',
  'fish-2': 'fish-2',
  'fish-3': 'fish-3',
  'fish-4': 'fish-4',
  'fish-5': 'fish-5',
  fish: 'fish',
  'fox-alt': 'fox-alt',
  fox: 'fox',
  'frog-tracks': 'frog-tracks',
  frog: 'frog',
  froggy: 'froggy',
  'giraffe-head-1': 'giraffe-head-1',
  'giraffe-head-2': 'giraffe-head-2',
  'giraffe-head': 'giraffe-head',
  giraffe: 'giraffe',
  'goat-head': 'goat-head',
  gorilla: 'gorilla',
  'hen-tracks': 'hen-tracks',
  'horse-head-1': 'horse-head-1',
  'horse-head-2': 'horse-head-2',
  'horse-head': 'horse-head',
  'horse-tracks': 'horse-tracks',
  jellyfish: 'jellyfish',
  kangaroo: 'kangaroo',
  lemur: 'lemur',
  'lion-head-1': 'lion-head-1',
  'lion-head-2': 'lion-head-2',
  'lion-head': 'lion-head',
  lion: 'lion',
  'monkey-2': 'monkey-2',
  'monkey-3': 'monkey-3',
  'monkey-face': 'monkey-face',
  monkey: 'monkey',
  'octopus-alt': 'octopus-alt',
  octopus: 'octopus',
  owl: 'owl',
  'panda-face': 'panda-face',
  panda: 'panda',
  panther: 'panther',
  'parrot-lip': 'parrot-lip',
  parrot: 'parrot',
  paw: 'paw',
  pelican: 'pelican',
  penguin: 'penguin',
  'pig-face': 'pig-face',
  pig: 'pig',
  'pigeon-1': 'pigeon-1',
  'pigeon-2': 'pigeon-2',
  pigeon: 'pigeon',
  rabbit: 'rabbit',
  rat: 'rat',
  'rhino-head': 'rhino-head',
  rhino: 'rhino',
  rooster: 'rooster',
  seahorse: 'seahorse',
  seal: 'seal',
  'shrimp-alt': 'shrimp-alt',
  shrimp: 'shrimp',
  'snail-1': 'snail-1',
  'snail-2': 'snail-2',
  'snail-3': 'snail-3',
  snail: 'snail',
  snake: 'snake',
  squid: 'squid',
  squirrel: 'squirrel',
  'tiger-face': 'tiger-face',
  tiger: 'tiger',
  turtle: 'turtle',
  whale: 'whale',
  woodpecker: 'woodpecker',
  zebra: 'zebra',
  'brand-acer': 'brand-acer',
  'brand-adidas': 'brand-adidas',
  'brand-adobe': 'brand-adobe',
  'brand-air-new-zealand': 'brand-air-new-zealand',
  'brand-airbnb': 'brand-airbnb',
  'brand-aircell': 'brand-aircell',
  'brand-airtel': 'brand-airtel',
  'brand-alcatel': 'brand-alcatel',
  'brand-alibaba': 'brand-alibaba',
  'brand-aliexpress': 'brand-aliexpress',
  'brand-alipay': 'brand-alipay',
  'brand-amazon': 'brand-amazon',
  'brand-amd': 'brand-amd',
  'brand-american-airlines': 'brand-american-airlines',
  'brand-android-robot': 'brand-android-robot',
  'brand-android': 'brand-android',
  'brand-aol': 'brand-aol',
  'brand-apple': 'brand-apple',
  'brand-appstore': 'brand-appstore',
  'brand-asus': 'brand-asus',
  'brand-ati': 'brand-ati',
  'brand-att': 'brand-att',
  'brand-audi': 'brand-audi',
  'brand-axiata': 'brand-axiata',
  'brand-bada': 'brand-bada',
  'brand-bbc': 'brand-bbc',
  'brand-bing': 'brand-bing',
  'brand-blackberry': 'brand-blackberry',
  'brand-bmw': 'brand-bmw',
  'brand-box': 'brand-box',
  'brand-burger-king': 'brand-burger-king',
  'brand-business-insider': 'brand-business-insider',
  'brand-buzzfeed': 'brand-buzzfeed',
  'brand-cannon': 'brand-cannon',
  'brand-casio': 'brand-casio',
  'brand-china-mobile': 'brand-china-mobile',
  'brand-china-telecom': 'brand-china-telecom',
  'brand-china-unicom': 'brand-china-unicom',
  'brand-cisco': 'brand-cisco',
  'brand-citibank': 'brand-citibank',
  'brand-cnet': 'brand-cnet',
  'brand-cnn': 'brand-cnn',
  'brand-cocal-cola': 'brand-cocal-cola',
  'brand-compaq': 'brand-compaq',
  'brand-debian': 'brand-debian',
  'brand-delicious': 'brand-delicious',
  'brand-dell': 'brand-dell',
  'brand-designbump': 'brand-designbump',
  'brand-designfloat': 'brand-designfloat',
  'brand-disney': 'brand-disney',
  'brand-dodge': 'brand-dodge',
  'brand-dove': 'brand-dove',
  'brand-drupal': 'brand-drupal',
  'brand-ebay': 'brand-ebay',
  'brand-eleven': 'brand-eleven',
  'brand-emirates': 'brand-emirates',
  'brand-espn': 'brand-espn',
  'brand-etihad-airways': 'brand-etihad-airways',
  'brand-etisalat': 'brand-etisalat',
  'brand-etsy': 'brand-etsy',
  'brand-fastrack': 'brand-fastrack',
  'brand-fedex': 'brand-fedex',
  'brand-ferrari': 'brand-ferrari',
  'brand-fitbit': 'brand-fitbit',
  'brand-flikr': 'brand-flikr',
  'brand-forbes': 'brand-forbes',
  'brand-foursquare': 'brand-foursquare',
  'brand-foxconn': 'brand-foxconn',
  'brand-fujitsu': 'brand-fujitsu',
  'brand-general-electric': 'brand-general-electric',
  'brand-gillette': 'brand-gillette',
  'brand-gizmodo': 'brand-gizmodo',
  'brand-gnome': 'brand-gnome',
  'brand-google': 'brand-google',
  'brand-gopro': 'brand-gopro',
  'brand-gucci': 'brand-gucci',
  'brand-hallmark': 'brand-hallmark',
  'brand-hi5': 'brand-hi5',
  'brand-honda': 'brand-honda',
  'brand-hp': 'brand-hp',
  'brand-hsbc': 'brand-hsbc',
  'brand-htc': 'brand-htc',
  'brand-huawei': 'brand-huawei',
  'brand-hulu': 'brand-hulu',
  'brand-hyundai': 'brand-hyundai',
  'brand-ibm': 'brand-ibm',
  'brand-icofont': 'brand-icofont',
  'brand-icq': 'brand-icq',
  'brand-ikea': 'brand-ikea',
  'brand-imdb': 'brand-imdb',
  'brand-indiegogo': 'brand-indiegogo',
  'brand-intel': 'brand-intel',
  'brand-ipair': 'brand-ipair',
  'brand-jaguar': 'brand-jaguar',
  'brand-java': 'brand-java',
  'brand-joomla': 'brand-joomla',
  'brand-kickstarter': 'brand-kickstarter',
  'brand-kik': 'brand-kik',
  'brand-lastfm': 'brand-lastfm',
  'brand-lego': 'brand-lego',
  'brand-lenovo': 'brand-lenovo',
  'brand-levis': 'brand-levis',
  'brand-lexus': 'brand-lexus',
  'brand-lg': 'brand-lg',
  'brand-life-hacker': 'brand-life-hacker',
  'brand-linux-mint': 'brand-linux-mint',
  'brand-linux': 'brand-linux',
  'brand-lionix': 'brand-lionix',
  'brand-loreal': 'brand-loreal',
  'brand-louis-vuitton': 'brand-louis-vuitton',
  'brand-mac-os': 'brand-mac-os',
  'brand-marvel-app': 'brand-marvel-app',
  'brand-mashable': 'brand-mashable',
  'brand-mazda': 'brand-mazda',
  'brand-mcdonals': 'brand-mcdonals',
  'brand-mercedes': 'brand-mercedes',
  'brand-micromax': 'brand-micromax',
  'brand-microsoft': 'brand-microsoft',
  'brand-mobileme': 'brand-mobileme',
  'brand-mobily': 'brand-mobily',
  'brand-motorola': 'brand-motorola',
  'brand-msi': 'brand-msi',
  'brand-mts': 'brand-mts',
  'brand-myspace': 'brand-myspace',
  'brand-mytv': 'brand-mytv',
  'brand-nasa': 'brand-nasa',
  'brand-natgeo': 'brand-natgeo',
  'brand-nbc': 'brand-nbc',
  'brand-nescafe': 'brand-nescafe',
  'brand-nestle': 'brand-nestle',
  'brand-netflix': 'brand-netflix',
  'brand-nexus': 'brand-nexus',
  'brand-nike': 'brand-nike',
  'brand-nokia': 'brand-nokia',
  'brand-nvidia': 'brand-nvidia',
  'brand-omega': 'brand-omega',
  'brand-opensuse': 'brand-opensuse',
  'brand-oracle': 'brand-oracle',
  'brand-panasonic': 'brand-panasonic',
  'brand-paypal': 'brand-paypal',
  'brand-pepsi': 'brand-pepsi',
  'brand-philips': 'brand-philips',
  'brand-pizza-hut': 'brand-pizza-hut',
  'brand-playstation': 'brand-playstation',
  'brand-puma': 'brand-puma',
  'brand-qatar-air': 'brand-qatar-air',
  'brand-qvc': 'brand-qvc',
  'brand-readernaut': 'brand-readernaut',
  'brand-redbull': 'brand-redbull',
  'brand-reebok': 'brand-reebok',
  'brand-reuters': 'brand-reuters',
  'brand-samsung': 'brand-samsung',
  'brand-sap': 'brand-sap',
  'brand-saudia-airlines': 'brand-saudia-airlines',
  'brand-scribd': 'brand-scribd',
  'brand-shell': 'brand-shell',
  'brand-siemens': 'brand-siemens',
  'brand-sk-telecom': 'brand-sk-telecom',
  'brand-slideshare': 'brand-slideshare',
  'brand-smashing-magazine': 'brand-smashing-magazine',
  'brand-snapchat': 'brand-snapchat',
  'brand-sony-ericsson': 'brand-sony-ericsson',
  'brand-sony': 'brand-sony',
  'brand-soundcloud': 'brand-soundcloud',
  'brand-sprint': 'brand-sprint',
  'brand-squidoo': 'brand-squidoo',
  'brand-starbucks': 'brand-starbucks',
  'brand-stc': 'brand-stc',
  'brand-steam': 'brand-steam',
  'brand-suzuki': 'brand-suzuki',
  'brand-symbian': 'brand-symbian',
  'brand-t-mobile': 'brand-t-mobile',
  'brand-tango': 'brand-tango',
  'brand-target': 'brand-target',
  'brand-tata-indicom': 'brand-tata-indicom',
  'brand-techcrunch': 'brand-techcrunch',
  'brand-telenor': 'brand-telenor',
  'brand-teliasonera': 'brand-teliasonera',
  'brand-tesla': 'brand-tesla',
  'brand-the-verge': 'brand-the-verge',
  'brand-thenextweb': 'brand-thenextweb',
  'brand-toshiba': 'brand-toshiba',
  'brand-toyota': 'brand-toyota',
  'brand-tribenet': 'brand-tribenet',
  'brand-ubuntu': 'brand-ubuntu',
  'brand-unilever': 'brand-unilever',
  'brand-vaio': 'brand-vaio',
  'brand-verizon': 'brand-verizon',
  'brand-viber': 'brand-viber',
  'brand-vodafone': 'brand-vodafone',
  'brand-volkswagen': 'brand-volkswagen',
  'brand-walmart': 'brand-walmart',
  'brand-warnerbros': 'brand-warnerbros',
  'brand-whatsapp': 'brand-whatsapp',
  'brand-wikipedia': 'brand-wikipedia',
  'brand-windows': 'brand-windows',
  'brand-wire': 'brand-wire',
  'brand-wordpress': 'brand-wordpress',
  'brand-xiaomi': 'brand-xiaomi',
  'brand-yahoobuzz': 'brand-yahoobuzz',
  'brand-yamaha': 'brand-yamaha',
  'brand-youtube': 'brand-youtube',
  'brand-zain': 'brand-zain',
  'bank-alt': 'bank-alt',
  bank: 'bank',
  barcode: 'barcode',
  'bill-alt': 'bill-alt',
  billboard: 'billboard',
  'briefcase-1': 'briefcase-1',
  'briefcase-2': 'briefcase-2',
  businessman: 'businessman',
  businesswoman: 'businesswoman',
  chair: 'chair',
  coins: 'coins',
  company: 'company',
  'contact-add': 'contact-add',
  'files-stack': 'files-stack',
  'handshake-deal': 'handshake-deal',
  'id-card': 'id-card',
  'meeting-add': 'meeting-add',
  'money-bag': 'money-bag',
  'pie-chart': 'pie-chart',
  'presentation-alt': 'presentation-alt',
  presentation: 'presentation',
  stamp: 'stamp',
  'stock-mobile': 'stock-mobile',
  'chart-arrows-axis': 'chart-arrows-axis',
  'chart-bar-graph': 'chart-bar-graph',
  'chart-flow-1': 'chart-flow-1',
  'chart-flow-2': 'chart-flow-2',
  'chart-flow': 'chart-flow',
  'chart-growth': 'chart-growth',
  'chart-histogram-alt': 'chart-histogram-alt',
  'chart-histogram': 'chart-histogram',
  'chart-line-alt': 'chart-line-alt',
  'chart-line': 'chart-line',
  'chart-pie-alt': 'chart-pie-alt',
  'chart-pie': 'chart-pie',
  'chart-radar-graph': 'chart-radar-graph',
  'architecture-alt': 'architecture-alt',
  architecture: 'architecture',
  barricade: 'barricade',
  bolt: 'bolt',
  bricks: 'bricks',
  'building-alt': 'building-alt',
  'bull-dozer': 'bull-dozer',
  calculations: 'calculations',
  'cement-mix': 'cement-mix',
  'cement-mixer': 'cement-mixer',
  'concrete-mixer': 'concrete-mixer',
  'danger-zone': 'danger-zone',
  drill: 'drill',
  'eco-energy': 'eco-energy',
  'eco-environmen': 'eco-environmen',
  'energy-air': 'energy-air',
  'energy-oil': 'energy-oil',
  'energy-savings': 'energy-savings',
  'energy-solar': 'energy-solar',
  'energy-water': 'energy-water',
  engineer: 'engineer',
  'fire-extinguisher-alt': 'fire-extinguisher-alt',
  'fire-extinguisher': 'fire-extinguisher',
  'fix-tools': 'fix-tools',
  'fork-lift': 'fork-lift',
  'glue-oil': 'glue-oil',
  'hammer-alt': 'hammer-alt',
  hammer: 'hammer',
  'help-robot': 'help-robot',
  'industries-1': 'industries-1',
  'industries-2': 'industries-2',
  'industries-3': 'industries-3',
  'industries-4': 'industries-4',
  'industries-5': 'industries-5',
  industries: 'industries',
  labour: 'labour',
  mining: 'mining',
  'paint-brush': 'paint-brush',
  pollution: 'pollution',
  'power-zone': 'power-zone',
  'radio-active': 'radio-active',
  'recycle-alt': 'recycle-alt',
  'recycling-man': 'recycling-man',
  'safety-hat-light': 'safety-hat-light',
  'safety-hat': 'safety-hat',
  saw: 'saw',
  'screw-driver': 'screw-driver',
  'tools-1': 'tools-1',
  'tools-bag': 'tools-bag',
  'tow-truck': 'tow-truck',
  trolley: 'trolley',
  trowel: 'trowel',
  'under-construction-alt': 'under-construction-alt',
  'under-construction': 'under-construction',
  'vehicle-cement': 'vehicle-cement',
  'vehicle-crane': 'vehicle-crane',
  'vehicle-delivery-van': 'vehicle-delivery-van',
  'vehicle-dozer': 'vehicle-dozer',
  'vehicle-excavator': 'vehicle-excavator',
  'vehicle-trucktor': 'vehicle-trucktor',
  'vehicle-wrecking': 'vehicle-wrecking',
  worker: 'worker',
  'workers-group': 'workers-group',
  wrench: 'wrench',
  'afghani-false': 'afghani-false',
  'afghani-minus': 'afghani-minus',
  'afghani-plus': 'afghani-plus',
  'afghani-true': 'afghani-true',
  afghani: 'afghani',
  'baht-false': 'baht-false',
  'baht-minus': 'baht-minus',
  'baht-plus': 'baht-plus',
  'baht-true': 'baht-true',
  baht: 'baht',
  'bitcoin-false': 'bitcoin-false',
  'bitcoin-minus': 'bitcoin-minus',
  'bitcoin-plus': 'bitcoin-plus',
  'bitcoin-true': 'bitcoin-true',
  bitcoin: 'bitcoin',
  'dollar-flase': 'dollar-flase',
  'dollar-minus': 'dollar-minus',
  'dollar-plus': 'dollar-plus',
  'dollar-true': 'dollar-true',
  dollar: 'dollar',
  'dong-false': 'dong-false',
  'dong-minus': 'dong-minus',
  'dong-plus': 'dong-plus',
  'dong-true': 'dong-true',
  dong: 'dong',
  'euro-false': 'euro-false',
  'euro-minus': 'euro-minus',
  'euro-plus': 'euro-plus',
  'euro-true': 'euro-true',
  euro: 'euro',
  'frank-false': 'frank-false',
  'frank-minus': 'frank-minus',
  'frank-plus': 'frank-plus',
  'frank-true': 'frank-true',
  frank: 'frank',
  'hryvnia-false': 'hryvnia-false',
  'hryvnia-minus': 'hryvnia-minus',
  'hryvnia-plus': 'hryvnia-plus',
  'hryvnia-true': 'hryvnia-true',
  hryvnia: 'hryvnia',
  'lira-false': 'lira-false',
  'lira-minus': 'lira-minus',
  'lira-plus': 'lira-plus',
  'lira-true': 'lira-true',
  lira: 'lira',
  'peseta-false': 'peseta-false',
  'peseta-minus': 'peseta-minus',
  'peseta-plus': 'peseta-plus',
  'peseta-true': 'peseta-true',
  peseta: 'peseta',
  'peso-false': 'peso-false',
  'peso-minus': 'peso-minus',
  'peso-plus': 'peso-plus',
  'peso-true': 'peso-true',
  peso: 'peso',
  'pound-false': 'pound-false',
  'pound-minus': 'pound-minus',
  'pound-plus': 'pound-plus',
  'pound-true': 'pound-true',
  pound: 'pound',
  'renminbi-false': 'renminbi-false',
  'renminbi-minus': 'renminbi-minus',
  'renminbi-plus': 'renminbi-plus',
  'renminbi-true': 'renminbi-true',
  renminbi: 'renminbi',
  'riyal-false': 'riyal-false',
  'riyal-minus': 'riyal-minus',
  'riyal-plus': 'riyal-plus',
  'riyal-true': 'riyal-true',
  riyal: 'riyal',
  'rouble-false': 'rouble-false',
  'rouble-minus': 'rouble-minus',
  'rouble-plus': 'rouble-plus',
  'rouble-true': 'rouble-true',
  rouble: 'rouble',
  'rupee-false': 'rupee-false',
  'rupee-minus': 'rupee-minus',
  'rupee-plus': 'rupee-plus',
  'rupee-true': 'rupee-true',
  rupee: 'rupee',
  'taka-false': 'taka-false',
  'taka-minus': 'taka-minus',
  'taka-plus': 'taka-plus',
  'taka-true': 'taka-true',
  taka: 'taka',
  'turkish-lira-false': 'turkish-lira-false',
  'turkish-lira-minus': 'turkish-lira-minus',
  'turkish-lira-plus': 'turkish-lira-plus',
  'turkish-lira-true': 'turkish-lira-true',
  'turkish-lira': 'turkish-lira',
  'won-false': 'won-false',
  'won-minus': 'won-minus',
  'won-plus': 'won-plus',
  'won-true': 'won-true',
  won: 'won',
  'yen-false': 'yen-false',
  'yen-minus': 'yen-minus',
  'yen-plus': 'yen-plus',
  'yen-true': 'yen-true',
  yen: 'yen',
  'android-nexus': 'android-nexus',
  'android-tablet': 'android-tablet',
  'apple-watch': 'apple-watch',
  'drawing-tablet': 'drawing-tablet',
  earphone: 'earphone',
  'flash-drive': 'flash-drive',
  'game-console': 'game-console',
  'game-controller': 'game-controller',
  'game-pad': 'game-pad',
  game: 'game',
  'headphone-alt-1': 'headphone-alt-1',
  'headphone-alt-2': 'headphone-alt-2',
  'headphone-alt-3': 'headphone-alt-3',
  'headphone-alt': 'headphone-alt',
  headphone: 'headphone',
  'htc-one': 'htc-one',
  imac: 'imac',
  ipad: 'ipad',
  iphone: 'iphone',
  'ipod-nano': 'ipod-nano',
  'ipod-touch': 'ipod-touch',
  'keyboard-alt': 'keyboard-alt',
  'keyboard-wireless': 'keyboard-wireless',
  keyboard: 'keyboard',
  'laptop-alt': 'laptop-alt',
  laptop: 'laptop',
  macbook: 'macbook',
  'magic-mouse': 'magic-mouse',
  'micro-chip': 'micro-chip',
  'microphone-alt': 'microphone-alt',
  microphone: 'microphone',
  monitor: 'monitor',
  mouse: 'mouse',
  'mp3-player': 'mp3-player',
  nintendo: 'nintendo',
  'playstation-alt': 'playstation-alt',
  psvita: 'psvita',
  'radio-mic': 'radio-mic',
  radio: 'radio',
  refrigerator: 'refrigerator',
  'samsung-galaxy': 'samsung-galaxy',
  'surface-tablet': 'surface-tablet',
  'ui-head-phone': 'ui-head-phone',
  'ui-keyboard': 'ui-keyboard',
  'washing-machine': 'washing-machine',
  'wifi-router': 'wifi-router',
  'wii-u': 'wii-u',
  'windows-lumia': 'windows-lumia',
  'wireless-mouse': 'wireless-mouse',
  'xbox-360': 'xbox-360',
  'arrow-down': 'arrow-down',
  'arrow-left': 'arrow-left',
  'arrow-right': 'arrow-right',
  'arrow-up': 'arrow-up',
  'block-down': 'block-down',
  'block-left': 'block-left',
  'block-right': 'block-right',
  'block-up': 'block-up',
  'bubble-down': 'bubble-down',
  'bubble-left': 'bubble-left',
  'bubble-right': 'bubble-right',
  'bubble-up': 'bubble-up',
  'caret-down': 'caret-down',
  'caret-left': 'caret-left',
  'caret-right': 'caret-right',
  'caret-up': 'caret-up',
  'circled-down': 'circled-down',
  'circled-left': 'circled-left',
  'circled-right': 'circled-right',
  'circled-up': 'circled-up',
  collapse: 'collapse',
  'cursor-drag': 'cursor-drag',
  'curved-double-left': 'curved-double-left',
  'curved-double-right': 'curved-double-right',
  'curved-down': 'curved-down',
  'curved-left': 'curved-left',
  'curved-right': 'curved-right',
  'curved-up': 'curved-up',
  'dotted-down': 'dotted-down',
  'dotted-left': 'dotted-left',
  'dotted-right': 'dotted-right',
  'dotted-up': 'dotted-up',
  'double-left': 'double-left',
  'double-right': 'double-right',
  'expand-alt': 'expand-alt',
  'hand-down': 'hand-down',
  'hand-drag': 'hand-drag',
  'hand-drag1': 'hand-drag1',
  'hand-drag2': 'hand-drag2',
  'hand-drawn-alt-down': 'hand-drawn-alt-down',
  'hand-drawn-alt-left': 'hand-drawn-alt-left',
  'hand-drawn-alt-right': 'hand-drawn-alt-right',
  'hand-drawn-alt-up': 'hand-drawn-alt-up',
  'hand-drawn-down': 'hand-drawn-down',
  'hand-drawn-left': 'hand-drawn-left',
  'hand-drawn-right': 'hand-drawn-right',
  'hand-drawn-up': 'hand-drawn-up',
  'hand-grippers': 'hand-grippers',
  'hand-left': 'hand-left',
  'hand-right': 'hand-right',
  'hand-up': 'hand-up',
  'line-block-down': 'line-block-down',
  'line-block-left': 'line-block-left',
  'line-block-right': 'line-block-right',
  'line-block-up': 'line-block-up',
  'long-arrow-down': 'long-arrow-down',
  'long-arrow-left': 'long-arrow-left',
  'long-arrow-right': 'long-arrow-right',
  'long-arrow-up': 'long-arrow-up',
  'rounded-collapse': 'rounded-collapse',
  'rounded-double-left': 'rounded-double-left',
  'rounded-double-right': 'rounded-double-right',
  'rounded-down': 'rounded-down',
  'rounded-expand': 'rounded-expand',
  'rounded-left-down': 'rounded-left-down',
  'rounded-left-up': 'rounded-left-up',
  'rounded-left': 'rounded-left',
  'rounded-right-down': 'rounded-right-down',
  'rounded-right-up': 'rounded-right-up',
  'rounded-right': 'rounded-right',
  'rounded-up': 'rounded-up',
  'scroll-bubble-down': 'scroll-bubble-down',
  'scroll-bubble-left': 'scroll-bubble-left',
  'scroll-bubble-right': 'scroll-bubble-right',
  'scroll-bubble-up': 'scroll-bubble-up',
  'scroll-double-down': 'scroll-double-down',
  'scroll-double-left': 'scroll-double-left',
  'scroll-double-right': 'scroll-double-right',
  'scroll-double-up': 'scroll-double-up',
  'scroll-down': 'scroll-down',
  'scroll-left': 'scroll-left',
  'scroll-long-down': 'scroll-long-down',
  'scroll-long-left': 'scroll-long-left',
  'scroll-long-right': 'scroll-long-right',
  'scroll-long-up': 'scroll-long-up',
  'scroll-right': 'scroll-right',
  'scroll-up': 'scroll-up',
  'simple-down': 'simple-down',
  'simple-left-down': 'simple-left-down',
  'simple-left-up': 'simple-left-up',
  'simple-left': 'simple-left',
  'simple-right-down': 'simple-right-down',
  'simple-right-up': 'simple-right-up',
  'simple-right': 'simple-right',
  'simple-up': 'simple-up',
  'square-down': 'square-down',
  'square-left': 'square-left',
  'square-right': 'square-right',
  'square-up': 'square-up',
  'stylish-down': 'stylish-down',
  'stylish-left': 'stylish-left',
  'stylish-right': 'stylish-right',
  'stylish-up': 'stylish-up',
  'swoosh-down': 'swoosh-down',
  'swoosh-left': 'swoosh-left',
  'swoosh-right': 'swoosh-right',
  'swoosh-up': 'swoosh-up',
  'thin-double-left': 'thin-double-left',
  'thin-double-right': 'thin-double-right',
  'thin-down': 'thin-down',
  'thin-left': 'thin-left',
  'thin-right': 'thin-right',
  'thin-up': 'thin-up',
  abc: 'abc',
  atom: 'atom',
  award: 'award',
  'bell-alt': 'bell-alt',
  'black-board': 'black-board',
  'book-alt': 'book-alt',
  book: 'book',
  brainstorming: 'brainstorming',
  'certificate-alt-1': 'certificate-alt-1',
  'certificate-alt-2': 'certificate-alt-2',
  certificate: 'certificate',
  education: 'education',
  electron: 'electron',
  'fountain-pen': 'fountain-pen',
  'globe-alt': 'globe-alt',
  'graduate-alt': 'graduate-alt',
  graduate: 'graduate',
  'group-students': 'group-students',
  'hat-alt': 'hat-alt',
  hat: 'hat',
  instrument: 'instrument',
  'lamp-light': 'lamp-light',
  medal: 'medal',
  'microscope-alt': 'microscope-alt',
  microscope: 'microscope',
  paper: 'paper',
  'pen-alt-4': 'pen-alt-4',
  'pen-nib': 'pen-nib',
  'pencil-alt-5': 'pencil-alt-5',
  'quill-pen': 'quill-pen',
  'read-book-alt': 'read-book-alt',
  'read-book': 'read-book',
  'school-bag': 'school-bag',
  'school-bus': 'school-bus',
  'student-alt': 'student-alt',
  student: 'student',
  teacher: 'teacher',
  'test-bulb': 'test-bulb',
  'test-tube-alt': 'test-tube-alt',
  university: 'university',
  angry: 'angry',
  astonished: 'astonished',
  confounded: 'confounded',
  confused: 'confused',
  crying: 'crying',
  dizzy: 'dizzy',
  expressionless: 'expressionless',
  'heart-eyes': 'heart-eyes',
  laughing: 'laughing',
  'nerd-smile': 'nerd-smile',
  'open-mouth': 'open-mouth',
  rage: 'rage',
  'rolling-eyes': 'rolling-eyes',
  sad: 'sad',
  'simple-smile': 'simple-smile',
  'slightly-smile': 'slightly-smile',
  smirk: 'smirk',
  'stuck-out-tongue': 'stuck-out-tongue',
  'wink-smile': 'wink-smile',
  worried: 'worried',
  'file-alt': 'file-alt',
  'file-audio': 'file-audio',
  'file-avi-mp4': 'file-avi-mp4',
  'file-bmp': 'file-bmp',
  'file-code': 'file-code',
  'file-css': 'file-css',
  'file-document': 'file-document',
  'file-eps': 'file-eps',
  'file-excel': 'file-excel',
  'file-exe': 'file-exe',
  'file-file': 'file-file',
  'file-flv': 'file-flv',
  'file-gif': 'file-gif',
  'file-html5': 'file-html5',
  'file-image': 'file-image',
  'file-iso': 'file-iso',
  'file-java': 'file-java',
  'file-javascript': 'file-javascript',
  'file-jpg': 'file-jpg',
  'file-midi': 'file-midi',
  'file-mov': 'file-mov',
  'file-mp3': 'file-mp3',
  'file-pdf': 'file-pdf',
  'file-php': 'file-php',
  'file-png': 'file-png',
  'file-powerpoint': 'file-powerpoint',
  'file-presentation': 'file-presentation',
  'file-psb': 'file-psb',
  'file-psd': 'file-psd',
  'file-python': 'file-python',
  'file-ruby': 'file-ruby',
  'file-spreadsheet': 'file-spreadsheet',
  'file-sql': 'file-sql',
  'file-svg': 'file-svg',
  'file-text': 'file-text',
  'file-tiff': 'file-tiff',
  'file-video': 'file-video',
  'file-wave': 'file-wave',
  'file-wmv': 'file-wmv',
  'file-word': 'file-word',
  'file-zip': 'file-zip',
  'cycling-alt': 'cycling-alt',
  cycling: 'cycling',
  dumbbell: 'dumbbell',
  dumbbells: 'dumbbells',
  'gym-alt-1': 'gym-alt-1',
  'gym-alt-2': 'gym-alt-2',
  'gym-alt-3': 'gym-alt-3',
  gym: 'gym',
  'muscle-weight': 'muscle-weight',
  muscle: 'muscle',
  apple: 'apple',
  'arabian-coffee': 'arabian-coffee',
  artichoke: 'artichoke',
  asparagus: 'asparagus',
  avocado: 'avocado',
  'baby-food': 'baby-food',
  banana: 'banana',
  bbq: 'bbq',
  beans: 'beans',
  beer: 'beer',
  'bell-pepper-capsicum': 'bell-pepper-capsicum',
  'birthday-cake': 'birthday-cake',
  bread: 'bread',
  broccoli: 'broccoli',
  burger: 'burger',
  cabbage: 'cabbage',
  carrot: 'carrot',
  'cauli-flower': 'cauli-flower',
  cheese: 'cheese',
  chef: 'chef',
  cherry: 'cherry',
  'chicken-fry': 'chicken-fry',
  chicken: 'chicken',
  cocktail: 'cocktail',
  'coconut-water': 'coconut-water',
  coconut: 'coconut',
  'coffee-alt': 'coffee-alt',
  'coffee-cup': 'coffee-cup',
  'coffee-mug': 'coffee-mug',
  'coffee-pot': 'coffee-pot',
  cola: 'cola',
  corn: 'corn',
  croissant: 'croissant',
  'crop-plant': 'crop-plant',
  cucumber: 'cucumber',
  culinary: 'culinary',
  'cup-cake': 'cup-cake',
  'dining-table': 'dining-table',
  donut: 'donut',
  'egg-plant': 'egg-plant',
  'egg-poached': 'egg-poached',
  'farmer-alt': 'farmer-alt',
  farmer: 'farmer',
  'fast-food': 'fast-food',
  'food-basket': 'food-basket',
  'food-cart': 'food-cart',
  'fork-and-knife': 'fork-and-knife',
  'french-fries': 'french-fries',
  fruits: 'fruits',
  grapes: 'grapes',
  honey: 'honey',
  'hot-dog': 'hot-dog',
  'ice-cream-alt': 'ice-cream-alt',
  'ice-cream': 'ice-cream',
  juice: 'juice',
  ketchup: 'ketchup',
  kiwi: 'kiwi',
  'layered-cake': 'layered-cake',
  'lemon-alt': 'lemon-alt',
  lemon: 'lemon',
  lobster: 'lobster',
  mango: 'mango',
  milk: 'milk',
  mushroom: 'mushroom',
  noodles: 'noodles',
  onion: 'onion',
  orange: 'orange',
  pear: 'pear',
  peas: 'peas',
  pepper: 'pepper',
  'pie-alt': 'pie-alt',
  pie: 'pie',
  pineapple: 'pineapple',
  'pizza-slice': 'pizza-slice',
  pizza: 'pizza',
  plant: 'plant',
  popcorn: 'popcorn',
  potato: 'potato',
  pumpkin: 'pumpkin',
  raddish: 'raddish',
  'restaurant-menu': 'restaurant-menu',
  restaurant: 'restaurant',
  'salt-and-pepper': 'salt-and-pepper',
  sandwich: 'sandwich',
  sausage: 'sausage',
  'soft-drinks': 'soft-drinks',
  'soup-bowl': 'soup-bowl',
  'spoon-and-fork': 'spoon-and-fork',
  steak: 'steak',
  strawberry: 'strawberry',
  'sub-sandwich': 'sub-sandwich',
  sushi: 'sushi',
  taco: 'taco',
  'tea-pot': 'tea-pot',
  tea: 'tea',
  tomato: 'tomato',
  watermelon: 'watermelon',
  wheat: 'wheat',
  'baby-backpack': 'baby-backpack',
  'baby-cloth': 'baby-cloth',
  'baby-milk-bottle': 'baby-milk-bottle',
  'baby-trolley': 'baby-trolley',
  baby: 'baby',
  candy: 'candy',
  'holding-hands': 'holding-hands',
  'infant-nipple': 'infant-nipple',
  'kids-scooter': 'kids-scooter',
  'safety-pin': 'safety-pin',
  'teddy-bear': 'teddy-bear',
  'toy-ball': 'toy-ball',
  'toy-cat': 'toy-cat',
  'toy-duck': 'toy-duck',
  'toy-elephant': 'toy-elephant',
  'toy-hand': 'toy-hand',
  'toy-horse': 'toy-horse',
  'toy-lattu': 'toy-lattu',
  'toy-train': 'toy-train',
  burglar: 'burglar',
  'cannon-firing': 'cannon-firing',
  'cc-camera': 'cc-camera',
  'cop-badge': 'cop-badge',
  cop: 'cop',
  'court-hammer': 'court-hammer',
  court: 'court',
  'finger-print': 'finger-print',
  gavel: 'gavel',
  'handcuff-alt': 'handcuff-alt',
  handcuff: 'handcuff',
  investigation: 'investigation',
  investigator: 'investigator',
  jail: 'jail',
  judge: 'judge',
  'law-alt-1': 'law-alt-1',
  'law-alt-2': 'law-alt-2',
  'law-alt-3': 'law-alt-3',
  'law-book': 'law-book',
  'law-document': 'law-document',
  'law-order': 'law-order',
  'law-protect': 'law-protect',
  'law-scales': 'law-scales',
  law: 'law',
  'lawyer-alt-1': 'lawyer-alt-1',
  'lawyer-alt-2': 'lawyer-alt-2',
  lawyer: 'lawyer',
  legal: 'legal',
  pistol: 'pistol',
  'police-badge': 'police-badge',
  'police-cap': 'police-cap',
  'police-car-alt-1': 'police-car-alt-1',
  'police-car-alt-2': 'police-car-alt-2',
  'police-car': 'police-car',
  'police-hat': 'police-hat',
  'police-van': 'police-van',
  police: 'police',
  'thief-alt': 'thief-alt',
  thief: 'thief',
  'abacus-alt': 'abacus-alt',
  abacus: 'abacus',
  'angle-180': 'angle-180',
  'angle-45': 'angle-45',
  'angle-90': 'angle-90',
  angle: 'angle',
  'calculator-alt-1': 'calculator-alt-1',
  'calculator-alt-2': 'calculator-alt-2',
  calculator: 'calculator',
  'circle-ruler-alt': 'circle-ruler-alt',
  'circle-ruler': 'circle-ruler',
  'compass-alt-1': 'compass-alt-1',
  'compass-alt-2': 'compass-alt-2',
  'compass-alt-3': 'compass-alt-3',
  'compass-alt-4': 'compass-alt-4',
  'golden-ratio': 'golden-ratio',
  'marker-alt-1': 'marker-alt-1',
  'marker-alt-2': 'marker-alt-2',
  'marker-alt-3': 'marker-alt-3',
  marker: 'marker',
  math: 'math',
  'mathematical-alt-1': 'mathematical-alt-1',
  'mathematical-alt-2': 'mathematical-alt-2',
  mathematical: 'mathematical',
  'pen-alt-1': 'pen-alt-1',
  'pen-alt-2': 'pen-alt-2',
  'pen-alt-3': 'pen-alt-3',
  'pen-holder-alt-1': 'pen-holder-alt-1',
  'pen-holder': 'pen-holder',
  pen: 'pen',
  'pencil-alt-1': 'pencil-alt-1',
  'pencil-alt-2': 'pencil-alt-2',
  'pencil-alt-3': 'pencil-alt-3',
  'pencil-alt-4': 'pencil-alt-4',
  pencil: 'pencil',
  'ruler-alt-1': 'ruler-alt-1',
  'ruler-alt-2': 'ruler-alt-2',
  'ruler-compass-alt': 'ruler-compass-alt',
  'ruler-compass': 'ruler-compass',
  'ruler-pencil-alt-1': 'ruler-pencil-alt-1',
  'ruler-pencil-alt-2': 'ruler-pencil-alt-2',
  'ruler-pencil': 'ruler-pencil',
  ruler: 'ruler',
  'rulers-alt': 'rulers-alt',
  rulers: 'rulers',
  'square-root': 'square-root',
  'ui-calculator': 'ui-calculator',
  aids: 'aids',
  'ambulance-crescent': 'ambulance-crescent',
  'ambulance-cross': 'ambulance-cross',
  ambulance: 'ambulance',
  autism: 'autism',
  bandage: 'bandage',
  blind: 'blind',
  'blood-drop': 'blood-drop',
  'blood-test': 'blood-test',
  blood: 'blood',
  'brain-alt': 'brain-alt',
  brain: 'brain',
  capsule: 'capsule',
  crutch: 'crutch',
  disabled: 'disabled',
  'dna-alt-1': 'dna-alt-1',
  'dna-alt-2': 'dna-alt-2',
  dna: 'dna',
  'doctor-alt': 'doctor-alt',
  doctor: 'doctor',
  'drug-pack': 'drug-pack',
  drug: 'drug',
  'first-aid-alt': 'first-aid-alt',
  'first-aid': 'first-aid',
  'heart-beat-alt': 'heart-beat-alt',
  'heart-beat': 'heart-beat',
  heartbeat: 'heartbeat',
  herbal: 'herbal',
  hospital: 'hospital',
  icu: 'icu',
  'injection-syringe': 'injection-syringe',
  laboratory: 'laboratory',
  'medical-sign-alt': 'medical-sign-alt',
  'medical-sign': 'medical-sign',
  'nurse-alt': 'nurse-alt',
  nurse: 'nurse',
  'nursing-home': 'nursing-home',
  'operation-theater': 'operation-theater',
  'paralysis-disability': 'paralysis-disability',
  'patient-bed': 'patient-bed',
  'patient-file': 'patient-file',
  pills: 'pills',
  prescription: 'prescription',
  pulse: 'pulse',
  'stethoscope-alt': 'stethoscope-alt',
  stethoscope: 'stethoscope',
  stretcher: 'stretcher',
  'surgeon-alt': 'surgeon-alt',
  surgeon: 'surgeon',
  tablets: 'tablets',
  'test-bottle': 'test-bottle',
  'test-tube': 'test-tube',
  'thermometer-alt': 'thermometer-alt',
  thermometer: 'thermometer',
  tooth: 'tooth',
  xray: 'xray',
  'ui-add': 'ui-add',
  'ui-alarm': 'ui-alarm',
  'ui-battery': 'ui-battery',
  'ui-block': 'ui-block',
  'ui-bluetooth': 'ui-bluetooth',
  'ui-brightness': 'ui-brightness',
  'ui-browser': 'ui-browser',
  'ui-calendar': 'ui-calendar',
  'ui-call': 'ui-call',
  'ui-camera': 'ui-camera',
  'ui-cart': 'ui-cart',
  'ui-cell-phone': 'ui-cell-phone',
  'ui-chat': 'ui-chat',
  'ui-check': 'ui-check',
  'ui-clip-board': 'ui-clip-board',
  'ui-clip': 'ui-clip',
  'ui-clock': 'ui-clock',
  'ui-close': 'ui-close',
  'ui-contact-list': 'ui-contact-list',
  'ui-copy': 'ui-copy',
  'ui-cut': 'ui-cut',
  'ui-delete': 'ui-delete',
  'ui-dial-phone': 'ui-dial-phone',
  'ui-edit': 'ui-edit',
  'ui-email': 'ui-email',
  'ui-file': 'ui-file',
  'ui-fire-wall': 'ui-fire-wall',
  'ui-flash-light': 'ui-flash-light',
  'ui-flight': 'ui-flight',
  'ui-folder': 'ui-folder',
  'ui-game': 'ui-game',
  'ui-handicapped': 'ui-handicapped',
  'ui-home': 'ui-home',
  'ui-image': 'ui-image',
  'ui-laoding': 'ui-laoding',
  'ui-lock': 'ui-lock',
  'ui-love-add': 'ui-love-add',
  'ui-love-broken': 'ui-love-broken',
  'ui-love-remove': 'ui-love-remove',
  'ui-love': 'ui-love',
  'ui-map': 'ui-map',
  'ui-message': 'ui-message',
  'ui-messaging': 'ui-messaging',
  'ui-movie': 'ui-movie',
  'ui-music-player': 'ui-music-player',
  'ui-music': 'ui-music',
  'ui-mute': 'ui-mute',
  'ui-network': 'ui-network',
  'ui-next': 'ui-next',
  'ui-note': 'ui-note',
  'ui-office': 'ui-office',
  'ui-password': 'ui-password',
  'ui-pause': 'ui-pause',
  'ui-play-stop': 'ui-play-stop',
  'ui-play': 'ui-play',
  'ui-pointer': 'ui-pointer',
  'ui-power': 'ui-power',
  'ui-press': 'ui-press',
  'ui-previous': 'ui-previous',
  'ui-rate-add': 'ui-rate-add',
  'ui-rate-blank': 'ui-rate-blank',
  'ui-rate-remove': 'ui-rate-remove',
  'ui-rating': 'ui-rating',
  'ui-record': 'ui-record',
  'ui-remove': 'ui-remove',
  'ui-reply': 'ui-reply',
  'ui-rotation': 'ui-rotation',
  'ui-rss': 'ui-rss',
  'ui-search': 'ui-search',
  'ui-settings': 'ui-settings',
  'ui-social-link': 'ui-social-link',
  'ui-tag': 'ui-tag',
  'ui-text-chat': 'ui-text-chat',
  'ui-text-loading': 'ui-text-loading',
  'ui-theme': 'ui-theme',
  'ui-timer': 'ui-timer',
  'ui-touch-phone': 'ui-touch-phone',
  'ui-travel': 'ui-travel',
  'ui-unlock': 'ui-unlock',
  'ui-user-group': 'ui-user-group',
  'ui-user': 'ui-user',
  'ui-v-card': 'ui-v-card',
  'ui-video-chat': 'ui-video-chat',
  'ui-video-message': 'ui-video-message',
  'ui-video-play': 'ui-video-play',
  'ui-video': 'ui-video',
  'ui-volume': 'ui-volume',
  'ui-weather': 'ui-weather',
  'ui-wifi': 'ui-wifi',
  'ui-zoom-in': 'ui-zoom-in',
  'ui-zoom-out': 'ui-zoom-out',
  'cassette-player': 'cassette-player',
  cassette: 'cassette',
  forward: 'forward',
  guiter: 'guiter',
  movie: 'movie',
  multimedia: 'multimedia',
  'music-alt': 'music-alt',
  'music-disk': 'music-disk',
  'music-note': 'music-note',
  'music-notes': 'music-notes',
  music: 'music',
  'mute-volume': 'mute-volume',
  pause: 'pause',
  'play-alt-1': 'play-alt-1',
  'play-alt-2': 'play-alt-2',
  'play-alt-3': 'play-alt-3',
  'play-pause': 'play-pause',
  play: 'play',
  record: 'record',
  'retro-music-disk': 'retro-music-disk',
  rewind: 'rewind',
  'song-notes': 'song-notes',
  'sound-wave-alt': 'sound-wave-alt',
  'sound-wave': 'sound-wave',
  stop: 'stop',
  'video-alt': 'video-alt',
  'video-cam': 'video-cam',
  'video-clapper': 'video-clapper',
  video: 'video',
  'volume-bar': 'volume-bar',
  'volume-down': 'volume-down',
  'volume-mute': 'volume-mute',
  'volume-off': 'volume-off',
  'volume-up': 'volume-up',
  'youtube-play': 'youtube-play',
  '2checkout-alt': '2checkout-alt',
  '2checkout': '2checkout',
  'amazon-alt': 'amazon-alt',
  amazon: 'amazon',
  'american-express-alt': 'american-express-alt',
  'american-express': 'american-express',
  'apple-pay-alt': 'apple-pay-alt',
  'apple-pay': 'apple-pay',
  'bank-transfer-alt': 'bank-transfer-alt',
  'bank-transfer': 'bank-transfer',
  'braintree-alt': 'braintree-alt',
  braintree: 'braintree',
  'cash-on-delivery-alt': 'cash-on-delivery-alt',
  'cash-on-delivery': 'cash-on-delivery',
  'diners-club-alt-1': 'diners-club-alt-1',
  'diners-club-alt-2': 'diners-club-alt-2',
  'diners-club-alt-3': 'diners-club-alt-3',
  'diners-club': 'diners-club',
  'discover-alt': 'discover-alt',
  discover: 'discover',
  'eway-alt': 'eway-alt',
  eway: 'eway',
  'google-wallet-alt-1': 'google-wallet-alt-1',
  'google-wallet-alt-2': 'google-wallet-alt-2',
  'google-wallet-alt-3': 'google-wallet-alt-3',
  'google-wallet': 'google-wallet',
  'jcb-alt': 'jcb-alt',
  jcb: 'jcb',
  'maestro-alt': 'maestro-alt',
  maestro: 'maestro',
  'mastercard-alt': 'mastercard-alt',
  mastercard: 'mastercard',
  'payoneer-alt': 'payoneer-alt',
  payoneer: 'payoneer',
  'paypal-alt': 'paypal-alt',
  paypal: 'paypal',
  'sage-alt': 'sage-alt',
  sage: 'sage',
  'skrill-alt': 'skrill-alt',
  skrill: 'skrill',
  'stripe-alt': 'stripe-alt',
  stripe: 'stripe',
  'visa-alt': 'visa-alt',
  'visa-electron': 'visa-electron',
  visa: 'visa',
  'western-union-alt': 'western-union-alt',
  'western-union': 'western-union',
  boy: 'boy',
  'business-man-alt-1': 'business-man-alt-1',
  'business-man-alt-2': 'business-man-alt-2',
  'business-man-alt-3': 'business-man-alt-3',
  'business-man': 'business-man',
  female: 'female',
  'funky-man': 'funky-man',
  'girl-alt': 'girl-alt',
  girl: 'girl',
  group: 'group',
  'hotel-boy-alt': 'hotel-boy-alt',
  'hotel-boy': 'hotel-boy',
  kid: 'kid',
  'man-in-glasses': 'man-in-glasses',
  people: 'people',
  support: 'support',
  'user-alt-1': 'user-alt-1',
  'user-alt-2': 'user-alt-2',
  'user-alt-3': 'user-alt-3',
  'user-alt-4': 'user-alt-4',
  'user-alt-5': 'user-alt-5',
  'user-alt-6': 'user-alt-6',
  'user-alt-7': 'user-alt-7',
  'user-female': 'user-female',
  'user-male': 'user-male',
  'user-suited': 'user-suited',
  user: 'user',
  'users-alt-1': 'users-alt-1',
  'users-alt-2': 'users-alt-2',
  'users-alt-3': 'users-alt-3',
  'users-alt-4': 'users-alt-4',
  'users-alt-5': 'users-alt-5',
  'users-alt-6': 'users-alt-6',
  'users-social': 'users-social',
  users: 'users',
  'waiter-alt': 'waiter-alt',
  waiter: 'waiter',
  'woman-in-glasses': 'woman-in-glasses',
  'search-1': 'search-1',
  'search-2': 'search-2',
  'search-document': 'search-document',
  'search-folder': 'search-folder',
  'search-job': 'search-job',
  'search-map': 'search-map',
  'search-property': 'search-property',
  'search-restaurant': 'search-restaurant',
  'search-stock': 'search-stock',
  'search-user': 'search-user',
  search: 'search',
  '500px': '500px',
  aim: 'aim',
  badoo: 'badoo',
  'baidu-tieba': 'baidu-tieba',
  'bbm-messenger': 'bbm-messenger',
  bebo: 'bebo',
  behance: 'behance',
  blogger: 'blogger',
  bootstrap: 'bootstrap',
  brightkite: 'brightkite',
  cloudapp: 'cloudapp',
  concrete5: 'concrete5',
  delicious: 'delicious',
  designbump: 'designbump',
  designfloat: 'designfloat',
  deviantart: 'deviantart',
  digg: 'digg',
  dotcms: 'dotcms',
  dribbble: 'dribbble',
  dribble: 'dribble',
  dropbox: 'dropbox',
  ebuddy: 'ebuddy',
  ello: 'ello',
  ember: 'ember',
  envato: 'envato',
  evernote: 'evernote',
  'facebook-messenger': 'facebook-messenger',
  facebook: 'facebook',
  feedburner: 'feedburner',
  flikr: 'flikr',
  folkd: 'folkd',
  foursquare: 'foursquare',
  friendfeed: 'friendfeed',
  ghost: 'ghost',
  github: 'github',
  gnome: 'gnome',
  'google-buzz': 'google-buzz',
  'google-hangouts': 'google-hangouts',
  'google-map': 'google-map',
  'google-plus': 'google-plus',
  'google-talk': 'google-talk',
  'hype-machine': 'hype-machine',
  instagram: 'instagram',
  kakaotalk: 'kakaotalk',
  kickstarter: 'kickstarter',
  kik: 'kik',
  kiwibox: 'kiwibox',
  'line-messenger': 'line-messenger',
  line: 'line',
  linkedin: 'linkedin',
  'linux-mint': 'linux-mint',
  'live-messenger': 'live-messenger',
  livejournal: 'livejournal',
  magento: 'magento',
  meetme: 'meetme',
  meetup: 'meetup',
  mixx: 'mixx',
  newsvine: 'newsvine',
  nimbuss: 'nimbuss',
  odnoklassniki: 'odnoklassniki',
  opencart: 'opencart',
  oscommerce: 'oscommerce',
  pandora: 'pandora',
  photobucket: 'photobucket',
  picasa: 'picasa',
  pinterest: 'pinterest',
  prestashop: 'prestashop',
  qik: 'qik',
  qq: 'qq',
  readernaut: 'readernaut',
  reddit: 'reddit',
  renren: 'renren',
  rss: 'rss',
  shopify: 'shopify',
  silverstripe: 'silverstripe',
  skype: 'skype',
  slack: 'slack',
  slashdot: 'slashdot',
  slidshare: 'slidshare',
  smugmug: 'smugmug',
  snapchat: 'snapchat',
  soundcloud: 'soundcloud',
  spotify: 'spotify',
  'stack-exchange': 'stack-exchange',
  'stack-overflow': 'stack-overflow',
  steam: 'steam',
  stumbleupon: 'stumbleupon',
  tagged: 'tagged',
  technorati: 'technorati',
  telegram: 'telegram',
  tinder: 'tinder',
  trello: 'trello',
  tumblr: 'tumblr',
  twitch: 'twitch',
  twitter: 'twitter',
  typo3: 'typo3',
  ubercart: 'ubercart',
  viber: 'viber',
  viddler: 'viddler',
  vimeo: 'vimeo',
  vine: 'vine',
  virb: 'virb',
  virtuemart: 'virtuemart',
  vk: 'vk',
  wechat: 'wechat',
  weibo: 'weibo',
  whatsapp: 'whatsapp',
  xing: 'xing',
  yahoo: 'yahoo',
  yelp: 'yelp',
  youku: 'youku',
  youtube: 'youtube',
  zencart: 'zencart',
  'badminton-birdie': 'badminton-birdie',
  baseball: 'baseball',
  baseballer: 'baseballer',
  'basketball-hoop': 'basketball-hoop',
  basketball: 'basketball',
  'billiard-ball': 'billiard-ball',
  'boot-alt-1': 'boot-alt-1',
  'boot-alt-2': 'boot-alt-2',
  boot: 'boot',
  'bowling-alt': 'bowling-alt',
  bowling: 'bowling',
  canoe: 'canoe',
  'cheer-leader': 'cheer-leader',
  climbing: 'climbing',
  corner: 'corner',
  'field-alt': 'field-alt',
  field: 'field',
  'football-alt': 'football-alt',
  'football-american': 'football-american',
  football: 'football',
  foul: 'foul',
  'goal-keeper': 'goal-keeper',
  goal: 'goal',
  'golf-alt': 'golf-alt',
  'golf-bag': 'golf-bag',
  'golf-cart': 'golf-cart',
  'golf-field': 'golf-field',
  golf: 'golf',
  golfer: 'golfer',
  helmet: 'helmet',
  'hockey-alt': 'hockey-alt',
  hockey: 'hockey',
  'ice-skate': 'ice-skate',
  'jersey-alt': 'jersey-alt',
  jersey: 'jersey',
  jumping: 'jumping',
  kick: 'kick',
  leg: 'leg',
  'match-review': 'match-review',
  'medal-sport': 'medal-sport',
  offside: 'offside',
  'olympic-logo': 'olympic-logo',
  olympic: 'olympic',
  padding: 'padding',
  'penalty-card': 'penalty-card',
  racer: 'racer',
  'racing-car': 'racing-car',
  'racing-flag-alt': 'racing-flag-alt',
  'racing-flag': 'racing-flag',
  'racings-wheel': 'racings-wheel',
  referee: 'referee',
  'refree-jersey': 'refree-jersey',
  'result-sport': 'result-sport',
  'rugby-ball': 'rugby-ball',
  'rugby-player': 'rugby-player',
  rugby: 'rugby',
  'runner-alt-1': 'runner-alt-1',
  'runner-alt-2': 'runner-alt-2',
  runner: 'runner',
  'score-board': 'score-board',
  'skiing-man': 'skiing-man',
  'skydiving-goggles': 'skydiving-goggles',
  'snow-mobile': 'snow-mobile',
  steering: 'steering',
  stopwatch: 'stopwatch',
  substitute: 'substitute',
  swimmer: 'swimmer',
  'table-tennis': 'table-tennis',
  'team-alt': 'team-alt',
  team: 'team',
  'tennis-player': 'tennis-player',
  tennis: 'tennis',
  tracking: 'tracking',
  'trophy-alt': 'trophy-alt',
  trophy: 'trophy',
  'volleyball-alt': 'volleyball-alt',
  'volleyball-fire': 'volleyball-fire',
  volleyball: 'volleyball',
  'water-bottle': 'water-bottle',
  'whistle-alt': 'whistle-alt',
  whistle: 'whistle',
  'win-trophy': 'win-trophy',
  'align-center': 'align-center',
  'align-left': 'align-left',
  'align-right': 'align-right',
  'all-caps': 'all-caps',
  bold: 'bold',
  brush: 'brush',
  'clip-board': 'clip-board',
  'code-alt': 'code-alt',
  'color-bucket': 'color-bucket',
  'color-picker': 'color-picker',
  'copy-invert': 'copy-invert',
  copy: 'copy',
  cut: 'cut',
  'delete-alt': 'delete-alt',
  'edit-alt': 'edit-alt',
  'eraser-alt': 'eraser-alt',
  font: 'font',
  heading: 'heading',
  indent: 'indent',
  'italic-alt': 'italic-alt',
  italic: 'italic',
  'justify-all': 'justify-all',
  'justify-center': 'justify-center',
  'justify-left': 'justify-left',
  'justify-right': 'justify-right',
  'link-broken': 'link-broken',
  outdent: 'outdent',
  'paper-clip': 'paper-clip',
  paragraph: 'paragraph',
  pin: 'pin',
  printer: 'printer',
  redo: 'redo',
  rotation: 'rotation',
  save: 'save',
  'small-cap': 'small-cap',
  'strike-through': 'strike-through',
  'sub-listing': 'sub-listing',
  subscript: 'subscript',
  superscript: 'superscript',
  table: 'table',
  'text-height': 'text-height',
  'text-width': 'text-width',
  trash: 'trash',
  underline: 'underline',
  undo: 'undo',
  'air-balloon': 'air-balloon',
  'airplane-alt': 'airplane-alt',
  airplane: 'airplane',
  'articulated-truck': 'articulated-truck',
  'auto-mobile': 'auto-mobile',
  'auto-rickshaw': 'auto-rickshaw',
  'bicycle-alt-1': 'bicycle-alt-1',
  'bicycle-alt-2': 'bicycle-alt-2',
  bicycle: 'bicycle',
  'bus-alt-1': 'bus-alt-1',
  'bus-alt-2': 'bus-alt-2',
  'bus-alt-3': 'bus-alt-3',
  bus: 'bus',
  cab: 'cab',
  'cable-car': 'cable-car',
  'car-alt-1': 'car-alt-1',
  'car-alt-2': 'car-alt-2',
  'car-alt-3': 'car-alt-3',
  'car-alt-4': 'car-alt-4',
  car: 'car',
  'delivery-time': 'delivery-time',
  'fast-delivery': 'fast-delivery',
  'fire-truck-alt': 'fire-truck-alt',
  'fire-truck': 'fire-truck',
  'free-delivery': 'free-delivery',
  helicopter: 'helicopter',
  'motor-bike-alt': 'motor-bike-alt',
  'motor-bike': 'motor-bike',
  'motor-biker': 'motor-biker',
  'oil-truck': 'oil-truck',
  rickshaw: 'rickshaw',
  'rocket-alt-1': 'rocket-alt-1',
  'rocket-alt-2': 'rocket-alt-2',
  rocket: 'rocket',
  'sail-boat-alt-1': 'sail-boat-alt-1',
  'sail-boat-alt-2': 'sail-boat-alt-2',
  'sail-boat': 'sail-boat',
  scooter: 'scooter',
  'sea-plane': 'sea-plane',
  'ship-alt': 'ship-alt',
  ship: 'ship',
  'speed-boat': 'speed-boat',
  taxi: 'taxi',
  tractor: 'tractor',
  'train-line': 'train-line',
  'train-steam': 'train-steam',
  tram: 'tram',
  'truck-alt': 'truck-alt',
  'truck-loaded': 'truck-loaded',
  truck: 'truck',
  'van-alt': 'van-alt',
  van: 'van',
  yacht: 'yacht',
  '5-star-hotel': '5-star-hotel',
  'air-ticket': 'air-ticket',
  'beach-bed': 'beach-bed',
  beach: 'beach',
  'camping-vest': 'camping-vest',
  'direction-sign': 'direction-sign',
  'hill-side': 'hill-side',
  hill: 'hill',
  hotel: 'hotel',
  'island-alt': 'island-alt',
  island: 'island',
  'sandals-female': 'sandals-female',
  'sandals-male': 'sandals-male',
  travelling: 'travelling',
  breakdown: 'breakdown',
  celsius: 'celsius',
  clouds: 'clouds',
  cloudy: 'cloudy',
  dust: 'dust',
  eclipse: 'eclipse',
  fahrenheit: 'fahrenheit',
  'forest-fire': 'forest-fire',
  'full-night': 'full-night',
  'full-sunny': 'full-sunny',
  'hail-night': 'hail-night',
  'hail-rainy-night': 'hail-rainy-night',
  'hail-rainy-sunny': 'hail-rainy-sunny',
  'hail-rainy': 'hail-rainy',
  'hail-sunny': 'hail-sunny',
  'hail-thunder-night': 'hail-thunder-night',
  'hail-thunder-sunny': 'hail-thunder-sunny',
  'hail-thunder': 'hail-thunder',
  hail: 'hail',
  'hill-night': 'hill-night',
  'hill-sunny': 'hill-sunny',
  hurricane: 'hurricane',
  meteor: 'meteor',
  night: 'night',
  'rainy-night': 'rainy-night',
  'rainy-sunny': 'rainy-sunny',
  'rainy-thunder': 'rainy-thunder',
  rainy: 'rainy',
  'snow-alt': 'snow-alt',
  'snow-flake': 'snow-flake',
  'snow-temp': 'snow-temp',
  snow: 'snow',
  'snowy-hail': 'snowy-hail',
  'snowy-night-hail': 'snowy-night-hail',
  'snowy-night-rainy': 'snowy-night-rainy',
  'snowy-night': 'snowy-night',
  'snowy-rainy': 'snowy-rainy',
  'snowy-sunny-hail': 'snowy-sunny-hail',
  'snowy-sunny-rainy': 'snowy-sunny-rainy',
  'snowy-sunny': 'snowy-sunny',
  'snowy-thunder-night': 'snowy-thunder-night',
  'snowy-thunder-sunny': 'snowy-thunder-sunny',
  'snowy-thunder': 'snowy-thunder',
  'snowy-windy-night': 'snowy-windy-night',
  'snowy-windy-sunny': 'snowy-windy-sunny',
  'snowy-windy': 'snowy-windy',
  snowy: 'snowy',
  'sun-alt': 'sun-alt',
  'sun-rise': 'sun-rise',
  'sun-set': 'sun-set',
  sun: 'sun',
  'sunny-day-temp': 'sunny-day-temp',
  sunny: 'sunny',
  'thunder-light': 'thunder-light',
  tornado: 'tornado',
  'umbrella-alt': 'umbrella-alt',
  umbrella: 'umbrella',
  volcano: 'volcano',
  wave: 'wave',
  'wind-scale-0': 'wind-scale-0',
  'wind-scale-1': 'wind-scale-1',
  'wind-scale-10': 'wind-scale-10',
  'wind-scale-11': 'wind-scale-11',
  'wind-scale-12': 'wind-scale-12',
  'wind-scale-2': 'wind-scale-2',
  'wind-scale-3': 'wind-scale-3',
  'wind-scale-4': 'wind-scale-4',
  'wind-scale-5': 'wind-scale-5',
  'wind-scale-6': 'wind-scale-6',
  'wind-scale-7': 'wind-scale-7',
  'wind-scale-8': 'wind-scale-8',
  'wind-scale-9': 'wind-scale-9',
  'wind-waves': 'wind-waves',
  wind: 'wind',
  'windy-hail': 'windy-hail',
  'windy-night': 'windy-night',
  'windy-raining': 'windy-raining',
  'windy-sunny': 'windy-sunny',
  'windy-thunder-raining': 'windy-thunder-raining',
  'windy-thunder': 'windy-thunder',
  windy: 'windy',
  addons: 'addons',
  'address-book': 'address-book',
  adjust: 'adjust',
  alarm: 'alarm',
  anchor: 'anchor',
  archive: 'archive',
  at: 'at',
  attachment: 'attachment',
  audio: 'audio',
  automation: 'automation',
  badge: 'badge',
  'bag-alt': 'bag-alt',
  bag: 'bag',
  ban: 'ban',
  'bar-code': 'bar-code',
  bars: 'bars',
  basket: 'basket',
  'battery-empty': 'battery-empty',
  'battery-full': 'battery-full',
  'battery-half': 'battery-half',
  'battery-low': 'battery-low',
  beaker: 'beaker',
  beard: 'beard',
  bed: 'bed',
  bell: 'bell',
  beverage: 'beverage',
  bill: 'bill',
  bin: 'bin',
  binary: 'binary',
  binoculars: 'binoculars',
  bluetooth: 'bluetooth',
  bomb: 'bomb',
  'book-mark': 'book-mark',
  box: 'box',
  briefcase: 'briefcase',
  broken: 'broken',
  bucket: 'bucket',
  bucket1: 'bucket1',
  bucket2: 'bucket2',
  bug: 'bug',
  building: 'building',
  'bulb-alt': 'bulb-alt',
  bullet: 'bullet',
  bullhorn: 'bullhorn',
  bullseye: 'bullseye',
  calendar: 'calendar',
  'camera-alt': 'camera-alt',
  camera: 'camera',
  card: 'card',
  'cart-alt': 'cart-alt',
  cart: 'cart',
  cc: 'cc',
  charging: 'charging',
  chat: 'chat',
  'check-alt': 'check-alt',
  'check-circled': 'check-circled',
  check: 'check',
  checked: 'checked',
  'children-care': 'children-care',
  clip: 'clip',
  'clock-time': 'clock-time',
  'close-circled': 'close-circled',
  'close-line-circled': 'close-line-circled',
  'close-line-squared-alt': 'close-line-squared-alt',
  'close-line-squared': 'close-line-squared',
  'close-line': 'close-line',
  'close-squared-alt': 'close-squared-alt',
  'close-squared': 'close-squared',
  close: 'close',
  'cloud-download': 'cloud-download',
  'cloud-refresh': 'cloud-refresh',
  'cloud-upload': 'cloud-upload',
  cloud: 'cloud',
  'code-not-allowed': 'code-not-allowed',
  code: 'code',
  comment: 'comment',
  'compass-alt': 'compass-alt',
  compass: 'compass',
  computer: 'computer',
  connection: 'connection',
  console: 'console',
  contacts: 'contacts',
  contrast: 'contrast',
  copyright: 'copyright',
  'credit-card': 'credit-card',
  crop: 'crop',
  crown: 'crown',
  cube: 'cube',
  cubes: 'cubes',
  'dashboard-web': 'dashboard-web',
  dashboard: 'dashboard',
  data: 'data',
  'database-add': 'database-add',
  'database-locked': 'database-locked',
  'database-remove': 'database-remove',
  database: 'database',
  delete: 'delete',
  diamond: 'diamond',
  'dice-multiple': 'dice-multiple',
  dice: 'dice',
  disc: 'disc',
  diskette: 'diskette',
  'document-folder': 'document-folder',
  'download-alt': 'download-alt',
  download: 'download',
  downloaded: 'downloaded',
  drag: 'drag',
  drag1: 'drag1',
  drag2: 'drag2',
  drag3: 'drag3',
  earth: 'earth',
  ebook: 'ebook',
  edit: 'edit',
  eject: 'eject',
  email: 'email',
  'envelope-open': 'envelope-open',
  envelope: 'envelope',
  eraser: 'eraser',
  error: 'error',
  excavator: 'excavator',
  exchange: 'exchange',
  'exclamation-circle': 'exclamation-circle',
  'exclamation-square': 'exclamation-square',
  'exclamation-tringle': 'exclamation-tringle',
  exclamation: 'exclamation',
  exit: 'exit',
  expand: 'expand',
  'external-link': 'external-link',
  external: 'external',
  'eye-alt': 'eye-alt',
  'eye-blocked': 'eye-blocked',
  'eye-dropper': 'eye-dropper',
  eye: 'eye',
  favourite: 'favourite',
  fax: 'fax',
  'file-fill': 'file-fill',
  film: 'film',
  filter: 'filter',
  'fire-alt': 'fire-alt',
  'fire-burn': 'fire-burn',
  fire: 'fire',
  'flag-alt-1': 'flag-alt-1',
  'flag-alt-2': 'flag-alt-2',
  flag: 'flag',
  'flame-torch': 'flame-torch',
  'flash-light': 'flash-light',
  flash: 'flash',
  flask: 'flask',
  focus: 'focus',
  'folder-open': 'folder-open',
  folder: 'folder',
  'foot-print': 'foot-print',
  garbage: 'garbage',
  'gear-alt': 'gear-alt',
  gear: 'gear',
  gears: 'gears',
  gift: 'gift',
  glass: 'glass',
  globe: 'globe',
  graffiti: 'graffiti',
  grocery: 'grocery',
  hand: 'hand',
  hanger: 'hanger',
  'hard-disk': 'hard-disk',
  'heart-alt': 'heart-alt',
  heart: 'heart',
  history: 'history',
  home: 'home',
  horn: 'horn',
  'hour-glass': 'hour-glass',
  id: 'id',
  image: 'image',
  inbox: 'inbox',
  infinite: 'infinite',
  'info-circle': 'info-circle',
  'info-square': 'info-square',
  info: 'info',
  institution: 'institution',
  interface: 'interface',
  invisible: 'invisible',
  jacket: 'jacket',
  jar: 'jar',
  jewlery: 'jewlery',
  karate: 'karate',
  'key-hole': 'key-hole',
  key: 'key',
  label: 'label',
  lamp: 'lamp',
  layers: 'layers',
  layout: 'layout',
  leaf: 'leaf',
  leaflet: 'leaflet',
  learn: 'learn',
  lego: 'lego',
  lens: 'lens',
  letter: 'letter',
  letterbox: 'letterbox',
  library: 'library',
  license: 'license',
  'life-bouy': 'life-bouy',
  'life-buoy': 'life-buoy',
  'life-jacket': 'life-jacket',
  'life-ring': 'life-ring',
  'light-bulb': 'light-bulb',
  lighter: 'lighter',
  'lightning-ray': 'lightning-ray',
  like: 'like',
  'line-height': 'line-height',
  'link-alt': 'link-alt',
  link: 'link',
  list: 'list',
  listening: 'listening',
  'listine-dots': 'listine-dots',
  'listing-box': 'listing-box',
  'listing-number': 'listing-number',
  'live-support': 'live-support',
  'location-arrow': 'location-arrow',
  'location-pin': 'location-pin',
  lock: 'lock',
  login: 'login',
  logout: 'logout',
  lollipop: 'lollipop',
  'long-drive': 'long-drive',
  look: 'look',
  loop: 'loop',
  luggage: 'luggage',
  lunch: 'lunch',
  lungs: 'lungs',
  'magic-alt': 'magic-alt',
  magic: 'magic',
  magnet: 'magnet',
  'mail-box': 'mail-box',
  mail: 'mail',
  male: 'male',
  'map-pins': 'map-pins',
  map: 'map',
  maximize: 'maximize',
  measure: 'measure',
  medicine: 'medicine',
  'mega-phone': 'mega-phone',
  'megaphone-alt': 'megaphone-alt',
  megaphone: 'megaphone',
  memorial: 'memorial',
  'memory-card': 'memory-card',
  'mic-mute': 'mic-mute',
  mic: 'mic',
  military: 'military',
  mill: 'mill',
  'minus-circle': 'minus-circle',
  'minus-square': 'minus-square',
  minus: 'minus',
  'mobile-phone': 'mobile-phone',
  molecule: 'molecule',
  money: 'money',
  moon: 'moon',
  mop: 'mop',
  muffin: 'muffin',
  mustache: 'mustache',
  'navigation-menu': 'navigation-menu',
  navigation: 'navigation',
  'network-tower': 'network-tower',
  network: 'network',
  news: 'news',
  newspaper: 'newspaper',
  'no-smoking': 'no-smoking',
  'not-allowed': 'not-allowed',
  notebook: 'notebook',
  notepad: 'notepad',
  notification: 'notification',
  numbered: 'numbered',
  opposite: 'opposite',
  optic: 'optic',
  options: 'options',
  package: 'package',
  page: 'page',
  paint: 'paint',
  'paper-plane': 'paper-plane',
  paperclip: 'paperclip',
  papers: 'papers',
  pay: 'pay',
  'penguin-linux': 'penguin-linux',
  pestle: 'pestle',
  'phone-circle': 'phone-circle',
  phone: 'phone',
  picture: 'picture',
  pine: 'pine',
  pixels: 'pixels',
  plugin: 'plugin',
  'plus-circle': 'plus-circle',
  'plus-square': 'plus-square',
  plus: 'plus',
  polygonal: 'polygonal',
  power: 'power',
  price: 'price',
  print: 'print',
  puzzle: 'puzzle',
  'qr-code': 'qr-code',
  queen: 'queen',
  'question-circle': 'question-circle',
  'question-square': 'question-square',
  question: 'question',
  'quote-left': 'quote-left',
  'quote-right': 'quote-right',
  random: 'random',
  recycle: 'recycle',
  refresh: 'refresh',
  repair: 'repair',
  'reply-all': 'reply-all',
  reply: 'reply',
  resize: 'resize',
  responsive: 'responsive',
  retweet: 'retweet',
  road: 'road',
  robot: 'robot',
  royal: 'royal',
  'rss-feed': 'rss-feed',
  safety: 'safety',
  'sale-discount': 'sale-discount',
  satellite: 'satellite',
  'send-mail': 'send-mail',
  server: 'server',
  'settings-alt': 'settings-alt',
  settings: 'settings',
  'share-alt': 'share-alt',
  'share-boxed': 'share-boxed',
  share: 'share',
  shield: 'shield',
  'shopping-cart': 'shopping-cart',
  'sign-in': 'sign-in',
  'sign-out': 'sign-out',
  signal: 'signal',
  'site-map': 'site-map',
  'smart-phone': 'smart-phone',
  soccer: 'soccer',
  'sort-alt': 'sort-alt',
  sort: 'sort',
  space: 'space',
  spanner: 'spanner',
  'speech-comments': 'speech-comments',
  'speed-meter': 'speed-meter',
  'spinner-alt-1': 'spinner-alt-1',
  'spinner-alt-2': 'spinner-alt-2',
  'spinner-alt-3': 'spinner-alt-3',
  'spinner-alt-4': 'spinner-alt-4',
  'spinner-alt-5': 'spinner-alt-5',
  'spinner-alt-6': 'spinner-alt-6',
  spinner: 'spinner',
  spreadsheet: 'spreadsheet',
  square: 'square',
  'ssl-security': 'ssl-security',
  'star-alt-1': 'star-alt-1',
  'star-alt-2': 'star-alt-2',
  star: 'star',
  'street-view': 'street-view',
  'support-faq': 'support-faq',
  'tack-pin': 'tack-pin',
  tag: 'tag',
  tags: 'tags',
  'tasks-alt': 'tasks-alt',
  tasks: 'tasks',
  telephone: 'telephone',
  telescope: 'telescope',
  terminal: 'terminal',
  'thumbs-down': 'thumbs-down',
  'thumbs-up': 'thumbs-up',
  'tick-boxed': 'tick-boxed',
  'tick-mark': 'tick-mark',
  ticket: 'ticket',
  tie: 'tie',
  'toggle-off': 'toggle-off',
  'toggle-on': 'toggle-on',
  'tools-alt-2': 'tools-alt-2',
  tools: 'tools',
  touch: 'touch',
  'traffic-light': 'traffic-light',
  transparent: 'transparent',
  tree: 'tree',
  'unique-idea': 'unique-idea',
  unlock: 'unlock',
  unlocked: 'unlocked',
  'upload-alt': 'upload-alt',
  upload: 'upload',
  'usb-drive': 'usb-drive',
  usb: 'usb',
  'vector-path': 'vector-path',
  'verification-check': 'verification-check',
  'wall-clock': 'wall-clock',
  wall: 'wall',
  wallet: 'wallet',
  'warning-alt': 'warning-alt',
  warning: 'warning',
  'water-drop': 'water-drop',
  web: 'web',
  wheelchair: 'wheelchair',
  'wifi-alt': 'wifi-alt',
  wifi: 'wifi',
  world: 'world',
  zigzag: 'zigzag',
  zipped: 'zipped'
}
