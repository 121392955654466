export default {
  title: 'Ключевое слово',

  one: 'Ключевое слово',
  many: 'Ключевые слова',

  titles: {
    create: 'Создать ключевое слово',
    matches: 'Поиск совпадений'
  },

  labels: {
    title: 'Название',
    value: 'Значение',
    text: 'Текст',
    coincidences: 'Список совпадений:',
    create: 'Создать ключевое слово',
    remove: 'Подтверждение удаления',
    update: 'Редактировать ключевое слово'
  },

  dialogs: {
    new: 'Новый ключевое слово'
  },

  hints: {
    notFound: 'Ключевое слово не найдено'
  },

  contents: {
    confirm: {
      remove: 'Подтвердите удаления {title}?'
    }
  },

  tooltips: {
    isActive: 'Активировать или деактивировать ключевое слово.',
    favorites: 'Добавить в избранное ключевое слово'
  },

  snackbars: {
    created: 'Ключевое слово успешно создано.',
    updated: 'Ключевое слово успешно обновлено.',
    removed: 'Ключевое слово успешно удалено.'
  }
}
