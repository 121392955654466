import copyIcon from '@/components/copy/icon'
import SpacerCell from '@/components/misc/SpacerCell'
import SingleLink from '@/components/misc/SingleLink'
import messageTypeIcon from '@/components/message/type/icon'
import BillingsUpdateStatusButton from '@/components/services/billings/updateStatusButton'
import formatTable from '@/components/format/table'

import { ROOT_ID, getPrice } from '@/utils'

import { serviceName } from '@/components/services/billings/utils'

const _ = { get: require('lodash/get') }

const fixedWidthStyle = {
  'min-width': '130px',
  'max-width': '130px'
}

function renderTypeIcon(h) {
  const [ source, type ] = this.value.source && this.value.source.split('.') || []

  return h(
    messageTypeIcon,
    {
      props: {
        value: type || source,
        translate: this.getTranslate(`tariffs.sources.${type || source}`)
      }
    }
  )
}

function renderTypeIconCell(h) {
  return h(
    'td',
    {
      class: 'pl-3 pr-0'
    },
    [ renderTypeIcon.call(this, h) ]
  )
}

function renderStatusIcon(h) {
  const billingsStatus = {
    pending: {
      name: 'pending',
      color: 'info',
      icon: 'query_builder'
    },
    refunded: {
      name: 'refunded',
      color: 'info',
      icon: 'replay'
    },
    complete: {
      name: 'complete',
      color: 'success',
      icon: 'done'
    },
    invalid: {
      name: 'invalid',
      color: 'error',
      icon: 'clear'
    }
  }

  const status = _.get(this.value, 'status')

  const statusName = _.get(billingsStatus, `${status}.name`)
  const statusIcon = _.get(billingsStatus, `${status}.icon`)
  const statusColor = _.get(billingsStatus, `${status}.color`)

  return h(
    'div',
    {
      class: 'fjcc facc faic square--48',
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`${serviceName}.statuses.${statusName}`)
          }
        }
      ]
    },
    [
      h(
        'g-icon',
        {
          props: {
            color: statusColor,
            value: statusIcon
          }
        }
      )
    ]
  )
}

function renderStatusIconCell(h) {
  return h(
    'td',
    {
      class: 'pa-0'
    },
    [ renderStatusIcon.call(this, h) ]
  )
}

function renderOwner(h) {
  if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
    return h(
      SingleLink,
      {
        props: {
          value: this.value.Owner || this.value.OwnerId
        }
      }
    )
  }
}
function renderOwnerCell(h) {
  if (this.checkPermissions('advanced.users.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'px-0',
        style: fixedWidthStyle
      },
      [ renderOwner.call(this, h) ]
    )
  }
}

function renderCommentCell(h) {
  return h(
    'td',
    {
      class: 'px-2 w--100'
    },
    [ h(SpacerCell, { props: { value: this.value.data.hidden.comment } }) ]
  )
}

function renderTariff(h) {
  const value = _.get(this.value, 'Tariff', _.get(this.value, 'TariffId'))
  if (value) {
    return h(
      SingleLink,
      {
        props: {
          value,
          service: 'tariffs'
        }
      }
    )
  }
}
function renderTariffCell(h) {
  if (this.checkPermissions('advanced.tariffs.get', [ 'reseller', true ])) {
    return h(
      'td',
      {
        class: 'px-2',
        style: fixedWidthStyle
      },
      [ renderTariff.call(this, h) ]
    )
  }
}

function renderAmount(h) {
  return h(
    'div',
    {
      class: 'text-overflow',
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: `${getPrice(this.value.data.price)} x ${this.value.data.multiplier}`
          }
        }
      ]
    },
    getPrice(this.value.amount)
  )
}
function renderAmountCell(h) {
  return h(
    'td',
    {
      class: 'px-2 text-right'
    },
    [ renderAmount.call(this, h) ]
  )
}

function renderUpdateStatusButton(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    return h(
      BillingsUpdateStatusButton,
      {
        props: {
          id: this.value.id,
          status: this.value.status
        }
      }
    )
  }
}

function renderSourceButton() {
  if (this.account.id === ROOT_ID) {
    if (typeof this.$scopedSlots.sourceButton === 'function') {
      return this.$scopedSlots.sourceButton()
    }

    return this.$slots.sourceButton
  }
}

function renderActions(h) {
  return h(
    'div',
    {
      class: 'faic'
    },
    [
      renderSourceButton.call(this),
      renderUpdateStatusButton.call(this, h)
    ]
  )
}
function renderActionsCell(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    return h(
      'td',
      {
        class: 'pa-0'
      },
      [ renderActions.call(this, h) ]
    )
  }
}

function renderFormattedDate(h, date) {
  if (date) {
    return h(
      'div',
      {
        class: 'text--grey text-right',
        style: { 'white-space': 'pre' },
        domProps: { innerHTML: date }
      }
    )
  }
}

function renderRow(h) {
  return h(
    'tr',
    {
      key: this.value.id
    },
    [
      renderTypeIconCell.call(this, h),
      renderStatusIconCell.call(this, h),
      renderOwnerCell.call(this, h),
      renderCommentCell.call(this, h),
      renderActionsCell.call(this, h),
      renderTariffCell.call(this, h),
      renderAmountCell.call(this, h),

      h(
        'td',
        {
          class: 'px-2'
        },
        [ renderFormattedDate.call(this, h, this.value.$formattedCreatedAt) ]
      ),
      h(
        'td',
        {
          class: 'px-2'
        },
        [ renderFormattedDate.call(this, h, this.value.$formattedUpdatedAt) ]
      )
    ]
  )
}

function renderPanel(h) {
  return h(
    'g-expansion-panel',
    {
      scopedSlots: {
        header: () => {
          return h(
            'div',
            {
              class: 'default-expansion-panel-header caption'
            },
            [
              renderTypeIcon.call(this, h),
              renderStatusIcon.call(this, h),

              h(
                'div',
                {
                  class: 'grid grid-gap--2 ff'
                },
                [
                  renderAmount.call(this, h),
                  renderOwner.call(this, h)
                ]
              ),

              h('div', { class: 'ff' }),

              renderFormattedDate.call(this, h, this.value.$formattedCreatedAt)
            ]
          )
        },
        default: () => {
          return h(
            'div',
            {
              class: 'grid grid-cols--1 grid-gap--0 pt-2'
            },
            [
              h(
                formatTable,
                {
                  props: {
                    value: this.value
                  }
                }
              ),

              renderActions.call(this, h)
            ]
          )
        }
      },
      key: this.value.id
    }
  )
}

function renderListItem(h) {
  return h(
    'g-list-item',
    {},
    [
      h(
        'div',
        {
          class: 'faic'
        },
        [
          h(
            'div',
            {
              class: 'fjcc facc faic square--48'
            },
            [ renderTypeIcon.call(this, h) ]
          ),

          renderStatusIcon.call(this, h),

          h(
            'div',
            {
              class: 'fc overflow-hidden'
            },
            [
              renderAmount.call(this, h),

              h(
                'div',
                {
                  class: 'faic'
                },
                [
                  h(
                    copyIcon,
                    {
                      class: 'ml-0',
                      props: {
                        value: this.value.id,
                        tooltip: this.getTranslate('commons.titles.copy'),
                        snackbar: {
                          text: this.getTranslate('commons.snackbars.copied'),
                          type: 'success'
                        }
                      }
                    }
                  ),

                  h('div', { class: 'caption text-overflow text--grey' }, this.value.id)
                ]
              )
            ]
          ),

          h('div', { class: 'ff' }),

          h(
            SingleLink,
            {
              props: {
                value: this.value.Owner,
                type: 'button'
              }
            }
          ),

          renderFormattedDate.call(this, h, this.value.$formattedCreatedAt)
        ]
      )
    ]
  )
}

export default function(h) {
  if (this.value && this.value.id) {
    switch (this.mode) {
      case 'panels': {
        return renderPanel.call(this, h)
      }
      case 'list': {
        return renderListItem.call(this, h)
      }
      case 'table':
      default: {
        return renderRow.call(this, h)
      }
    }
  }
}
