import Vue from 'vue'

import { globalErrorHandler, globalErrorProcessor, services } from '@/utils'

import render from './render'

export default {
  name: 'TaskMenuButton',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  data() {
    return {
      loading: false,

      showMenu: false,
      showSourceDataDialog: false,
      showConfirmRemoveDialog: false,
      showEventsHistoryDialog: false
    }
  },

  watch: {
    showMenu(value) {
      this.$emit('menu', value)
    }
  },

  methods: {
    async removeTask(id) {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.remove(services.tasks, id)
        if (data && data.id) {
          this.addSnackbar({
            text: this.getTranslate(`${services.tasks}.snackbars.removed`),
            type: 'warn'
          })
          this.showConfirmRemoveDialog = false
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
