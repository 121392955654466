import { mapActions, mapGetters } from 'vuex'

import render from './render'

export default {
  name: 'FileInput',

  computed: {
    ...mapGetters({
      token: 'authentication/token',
      attributes: 'file/attributes'
    }),

    attrs() {
      return {
        id: 'file',
        type: 'file',
        hidden: true,
        accept: this.attributes.types,
        multiple: this.attributes.multiple
      }
    }
  },

  methods: {
    ...mapActions({ handler: 'file/handler' })
  },

  render
}
