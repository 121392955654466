const _ = { isEqual: require('lodash/isEqual') }

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': '1fr 36px' }
    },
    [
      this.$slots.default,

      h(
        'g-button',
        {
          style: Object.keys(this.margin).reduce((result, key) => {
            result[`margin-${key}`] = this.margin[key] + 'px'

            return result
          }, {}),
          props: {
            icon: 'done',
            depressed: true,
            disabled: _.isEqual(this.initialData, this.value),
            color: 'success'
          },
          on: {
            click: () => {
              this.initialData = this.value
              this.$emit('input', this.value)
            }
          }
        }
      )
    ]
  )
}
