export default {
  total: 'Total',

  no: {
    data: {
      text: 'No data available'
    }
  },

  contents: {
    lastMonthSum: 'Referral charges for: ',
    referralCode: 'Referral code: '
  },

  headers: {
    amount: 'Amount',
    referral: 'Referral',
    date: 'Date'
  }
}
