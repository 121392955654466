import componentNotFound from '@/components/misc/componentNotFound'

export function page(name) {
  return {
    name,

    metaInfo: {
      title: `${name.split('/').join('.')}.title`
    },

    render(h) {
      try {
        return h(require(`@/components/services/${name}/template`).default)
      } catch (error) {
        return h(componentNotFound)
      }
    }
  }
}

export default { page }
