import { MODERATION_VALUES } from '@sigma-legacy-libs/essentials/lib/constants'

import { buttonModes, colors, services } from '@/utils'

import button from '@/components/button'

function renderApproveButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: 'done',
        color: colors.success,
        disabled: MODERATION_VALUES.approved === this.value.moderation,
        tooltip: this.getTranslate(`${services.sendernames}.tooltips.approve`)
      },
      on: {
        click: () => {
          this.approve()
        }
      }
    }
  )
}

function renderRejectMenuActivator(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: 'clear',
        color: colors.warning,
        disabled: MODERATION_VALUES.rejected === this.value.moderation,
        tooltip: this.getTranslate(`${services.sendernames}.tooltips.rejectForReason`)
      },
      slot: 'activator'
    }
  )
}

function renderRejectMenu(h) {
  return h(
    'g-menu',
    {
      props: {
        rounded: true,
        disabled: MODERATION_VALUES.rejected === this.value.moderation,
        placement: 'bottom',
        width: 190
      }
    },
    [
      renderRejectMenuActivator.call(this, h),

      h(
        'g-list',
        {
          props: {
            dense: true,
            rounded: true
          }
        },
        Object.keys(this.rejectVariants).map(variant => {
          return h(
            'g-list-item',
            {
              props: {
                label: this.getTranslate(`${services.sendernames}.variants.${variant}`),
                dense: true
              },
              on: {
                click: () => {
                  this.reject(variant)
                }
              }
            }
          )
        })
      )
    ]
  )
}

function renderRejectButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        rounded: this.viewport.breakpoint.sm,
        icon: 'cancel',
        color: colors.error,
        disabled: MODERATION_VALUES.rejected === this.value.moderation,
        tooltip: this.getTranslate(`${services.sendernames}.tooltips.reject`)
      },
      on: {
        click: () => {
          this.reject()
        }
      }
    }
  )
}

export default function(h) {
  if (this.checkPermissions(`advanced.${services.sendernames}.get`)) {
    return h(
      'div',
      {
        class: {
          [`${services.sendernames}-item__buttons`]: true,
          'hover-child': this.viewport.breakpoint.mdUp
        }
      },
      [
        renderApproveButton.call(this, h),
        renderRejectMenu.call(this, h),
        renderRejectButton.call(this, h)
      ]
    )
  }
}
