export const serviceWorker = {
  mounted() {
    if (navigator.serviceWorker && navigator.serviceWorker.controller) {
      navigator.serviceWorker.controller.onstatechange = event => {
        if (event.target.state === 'redundant') {
          this.addSnackbar({
            text: this.getTranslate('misc.refreshSite'),
            type: 'info',
            button: {
              props: { label: this.getTranslate('misc.buttons.refresh') },
              on: { click: () => window.location.reload() }
            },
            duration: 60 * 60 * 24
          })
        }
      }
    }
  }
}

export default { serviceWorker }
