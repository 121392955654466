export const names = {
  'aws:maxim': 'Максим',
  'aws:tatyana': 'Татьяна',
  'yandex:oksana': 'Оксана',
  'yandex:jane': 'Джейн',
  'yandex:omazh': 'Омаж',
  'yandex:zahar': 'Захар',
  'yandex:ermil': 'Эрмил',
  'yandex:madirus': 'Мадирус',
  'yandex:lea': 'Леа',
  'yandex:john': 'Джон',
  'yandex:amira': 'Амира',
  'yandex:madi': 'Мади',
  'yandex:nigora': 'Нигора',
  'yandex:alena': 'Алёна',
  'yandex:filipp': 'Филипп',
  'yandex:silaerkan': 'Силеркан',
  'yandex:erkanyavas': 'Эрканавас',
  'yandex:alyss': 'Эйлиш',
  'yandex:nick': 'Ник'
}

export default { names }
