import { isStringNotEmpty } from '@/utils'

import OneRowString from '@/components/misc/OneRowString'

export default function(h) {
  if (isStringNotEmpty(this.value)) {
    return h(
      'div',
      {
        class: 'faic pl-3 pr-3',
        style: {
          'min-height': '52px',
          height: '52px'
        }
      },
      [
        h(
          OneRowString,
          {
            class: 'headline',
            props: { value: this.value }
          }
        )
      ]
    )
  }
}
