import { sizes } from '@/utils'

import tag from '@/components/tag'

function renderCount(h) {
  if (this.isMany) {
    return h(
      tag,
      {
        props: {
          label: `+${this.value.length - this.count}`,
          size: sizes.tiny
        },
        directives: [
          {
            name: 'g-tooltip',
            options: {
              value: this.proxy.reduce((result, item, index) => {
                if (index < 20) {
                  result.push(item.title)
                }

                return result
              }, []).join('\n')
            }
          }
        ]
      }
    )
  }
}

function renderItem(h, { id, title }) {
  if (typeof title === 'string' && title) {
    return h(
      tag,
      {
        class: 'link',
        props: {
          label: title,
          size: sizes.tiny
        },
        on: {
          click: () => {
            this.$router.push({
              name: `${this.service === 'contactLists' ? 'contacts.single' : this.service + '.single'}`,
              params: { id }
            })
          }
        },
        key: id
      }
    )
  }
}

function renderItems(h) {
  return this.proxy.slice(0, this.count).map(item => renderItem.call(this, h, item))
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid-gap--4',
      on: {
        click: () => {
          if (this.isMany) {
            this.$emit('click', true)
          }
        }
      }
    },
    [
      renderItems.call(this, h),
      renderCount.call(this, h)
    ]
  )
}
