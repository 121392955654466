import { services } from '@/utils'

import sticker from '@/components/misc/sticker'
import providersState from '@/components/services/providers/state'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.getTranslate(`${services.providers}.provider`),
        service: services.providers
      },
      scopedSlots: {
        icon: () => {
          return h(
            providersState,
            {
              props: { id: this.value.id }
            }
          )
        }
      }
    }
  )
}
