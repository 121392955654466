import { serviceName } from '@/components/services/yclients/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.error) {
    return h(
      sticker,
      {
        props: {
          value: this.error,
          label: this.getTranslate(`${serviceName}.labels.error`),
          borderStyle: 'dashed'
        }
      }
    )
  }
}

