import Vue from 'vue'
import proxy from '@sigma-legacy-libs/g-proxy'

import { isPhoneNumber, isUUID } from '@sigma-legacy-libs/essentials/lib/validators'
import { REGEXP_IS_EMAIL_SIMPLE, REGEXP_IS_RUSSIAN_NUMBER } from '@sigma-legacy-libs/essentials/lib/constants/regexp'

import { generateServices, globalErrorHandler, globalErrorProcessor } from '@/utils'

import render from './render'

const excludeSymbolsRegex = /[^a-zA-Z\d_.@+\-\n]/gm
const replaceSymbolsRegex = /[,;\r\n]/gm
const plusDigitRegex = /[^\+\d]/g
const replacePlusSevenRegex = /\+?(7|8)(.*)/

export default {
  name: 'SendingsCreateRecipients',

  mixins: [
    proxy({ type: 'object' }),

    generateServices({
      name: 'contactLists',

      find: false,
      get: false,
      update: false,
      remove: false
    })
  ],

  props: { error: String },

  data() {
    return {
      contactLists: {
        show: {
          dialog: false
        },
        loading: {
          create: false
        }
      }
    }
  },

  methods: {
    pasteFilter(value) {
      const separator = '\n'

      return this.checkValues(
        ('' + value)
          .replace(excludeSymbolsRegex, '')
          .replace(replaceSymbolsRegex, separator)
          .split(separator)
      )
    },

    checkValues(data) {
      return data.reduce((result, item) => {
        let value = null

        if (isUUID(item) && ~data.indexOf(item)) {
          value = item
        } else if (REGEXP_IS_RUSSIAN_NUMBER.test(item.replace(plusDigitRegex, ''))) {
          value = item.replace(plusDigitRegex, '').replace(replacePlusSevenRegex, '+7$2')
        } else if (REGEXP_IS_EMAIL_SIMPLE.test(item)) {
          value = item
        } else {
          if (isPhoneNumber(item).isValid) {
            value = isPhoneNumber(item).formatted
          }
        }

        if (value && !~result.indexOf(value)) {
          result.push(value)
        }

        return result
      }, [])
    },

    async createContactList() {
      try {
        if (this.restData.contactLists.create.isValid) {
          this.contactLists.loading.create = true
          const { id } = await this.rest.contactLists.create(this.restData.contactLists.create.data)
          if (id) {
            Vue.router.push({
              name: 'contacts.single',
              params: { id }
            })
          }
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.contactLists.loading.create = false
        this.contactLists.show.dialog = false
      }
    }
  },

  render
}
