import { serviceName } from '@/components/services/requisites/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.value.id) {
    return h(
      sticker,
      {
        props: {
          value: this.value.title,
          label: this.viewport.breakpoint.xs ? this.value.data.legal.inn : this.getTranslate(`${serviceName}.labels.data.legal.name`),
          icon: this.value.isActive ? 'check_circle_outline' : 'error_outline',
          color: this.value.isActive ? 'success' : 'error',
          iconTooltip: this.getTranslate(`${serviceName}.tooltips.icons.${this.value.isActive ? 'isActive' : 'notActive'}`),
          copy: true,
          onClick: () => this.clickHandler()
        }
      }
    )
  }
}
