import { buttonModes, permissionPresets, services, states } from '@/utils'

import button from '@/components/button'
import refreshButton from '@/components/button/refresh'
import HeadTitle from '@/components/misc/HeadTitle'
import item from '@/components/services/payments/item'
import filter from '@/components/services/payments/filter'
import paymentsLog from '@/components/services/paymentLogs/template'
import billingsDetailsItem from '@/components/services/billings/details/item'
import pagination from '@/components/misc/pagination'

function renderPaymentLogsDialog(h) {
  if (this.checkPermissions('advanced.paymentLogs.get')) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: this.viewport.breakpoint.mdUp,
          value: this.showPaymentLogsDialog,
          maxWidth: this.viewport.breakpoint.mdUp ? 800 : '100%'
        },
        on: {
          input: event => {
            if (event === false) {
              this.paymentId = undefined
            }
            this.showPaymentLogsDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${services.payments}.titles.paymentLogs`) },
            slot: 'header'
          }
        ),

        h(paymentsLog({ PaymentId: this.paymentId })),

        h(
          'div',
          {
            class: 'fjcfe facfe pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: buttonModes.flat
                },
                on: {
                  click: () => {
                    this.showPaymentLogsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderBillingsList(h) {
  if (Array.isArray(this.paymentsBillings) && this.paymentsBillings.length) {
    return h(
      'g-list',
      {},
      this.paymentsBillings.map(billing => {
        return h(
          billingsDetailsItem,
          {
            props: {
              value: billing,
              mode: 'list'
            }
          }
        )
      })
    )
  }
}

function renderBillingsDialog(h) {
  if (this.checkPermissions(`advanced.${services.payments}.get`, permissionPresets.resellerUp)) {
    return h(
      'g-dialog',
      {
        props: {
          rounded: this.viewport.breakpoint.mdUp,
          value: this.showBillingsDialog,
          maxWidth: 500
        },
        on: {
          input: event => {
            if (event === false) {
              this.paymentsBillings = []
            }
            this.showBillingsDialog = event
          }
        }
      },
      [
        h(
          HeadTitle,
          {
            props: { value: this.getTranslate(`${services.payments}.titles.billings`) },
            slot: 'header'
          }
        ),

        renderBillingsList.call(this, h),

        h(
          'div',
          {
            class: 'fjcfe facfe pa-2',
            slot: 'footer'
          },
          [
            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.close'),
                  mode: buttonModes.flat
                },
                on: {
                  click: () => {
                    this.showBillingsDialog = false
                  }
                }
              }
            )
          ]
        )
      ]
    )
  }
}

function renderDivider(h) {
  if (Array.isArray(this.restData[services.payments].find.data) && this.restData[services.payments].find.data.length) {
    return h('g-divider')
  }
}

function renderRefreshButton(h) {
  return h(
    refreshButton,
    {
      props: {
        state: this.restData[services.payments].find.state,
        shortClickMethod: () => this.rest[services.payments].find(),
        longClickMethod: () => this.rest[services.payments].find({}, { noCache: true })
      }
    }
  )
}
function renderPagination(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe grid-gap--8'
    },
    [
      h(
        pagination,
        {
          props: {
            value: this.restData[services.payments].find.pagination,
            service: services.payments,
            selectRows: !this.viewport.breakpoint.xs,
            chunk: this.viewport.breakpoint.xs ? 1 : 3
          },
          on: {
            input: event => {
              this.restData[services.payments].find.pagination = event
            }
          }
        }
      ),

      renderRefreshButton.call(this, h)
    ]
  )
}

function renderFilter(h, options) {
  if (options.isPage) {
    return h(
      filter,
      {
        props: {
          value: this.restData[services.payments].find.filter
        },
        on: {
          input: event => {
            this.restData[services.payments].find.filter = event
          }
        }
      }
    )
  }
}

function renderPreloader(h) {
  return h(
    'div',
    {
      class: 'fjcc facc pa-5'
    },
    [
      h(
        'g-progress',
        {
          props: {
            indeterminate: true
          }
        }
      )
    ]
  )
}

function renderHeaderBlock(h, options) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderFilter.call(this, h, options),
      renderPagination.call(this, h, true)
    ]
  )
}

function renderList(h) {
  if (this.restData[services.payments].find.state === states.loading) {
    return renderPreloader.call(this, h)
  } else if (this.restData[services.payments].find.state === states.ready) {
    if (this.restData[services.payments].find.pagination.total > 0) {
      return this.restData[services.payments].find.data.map(value => {
        return h(
          item,
          {
            props: { value },
            key: value.id,
            on: {
              billings: event => {
                this.paymentsBillings = event
                this.showBillingsDialog = true
              },
              paymentLogs: event => {
                this.paymentId = event
                this.showPaymentLogsDialog = true
              }
            }
          }
        )
      })
    }
  }

  return h('g-empty')
}

export default function(h, options) {
  if (this.checkPermissions(`advanced.${services.payments}.get`, permissionPresets.meUp)) {
    return h(
      'div',
      {
        class: 'fc ff'
      },
      [
        renderHeaderBlock.call(this, h, options),
        renderDivider.call(this, h),
        renderList.call(this, h, options),
        renderPaymentLogsDialog.call(this, h),
        renderBillingsDialog.call(this, h)
      ]
    )
  }
}
