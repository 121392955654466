import render from './render'

export default {
  name: 'EditButton',

  props: {
    id: {
      type: String,
      default: undefined
    }
  },

  render
}
