import { services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  if (this.viewport.size.width > 806) {
    if (this.value.Credentials || this.value.CredentialsId) {
      return h(
        sticker,
        {
          props: {
            value: this.value.Credentials || this.value.CredentialsId,
            icon: 'vpn_key',
            color: 'grey',
            label: this.getTranslate(`${services.providers}.labels.credentials`),
            service: services.credentials
          }
        }
      )
    }
  }
}
