import numeral from 'numeral'

import { locales } from '@/utils'

export default {
  namespaced: true,

  state: {
    locale: 'ru',
    locales: locales
  },

  mutations: {
    setLocale(state, locale) {
      if (locale && typeof locale === 'string' && ~locales.indexOf(locale)) {
        document.documentElement.setAttribute('lang', locale)
        window.localStorage.setItem('locale', locale)
        state.locale = locale
        numeral.locale(locale)
      }
    },
    setLocales(state, locales) {
      if (locales && locales.length) {
        state.locales = locales
      }
    }
  },

  actions: {
    setLocale({ commit }, locale) {
      commit('setLocale', locale)
    },
    setLocales({ commit }, locales) {
      commit('setLocales', locales)
    }
  },

  getters: {
    locale: state => state.locale,
    locales: state => state.locales
  }
}
