import { services } from '@/utils'

import pagination from '@/components/misc/pagination'
import buttonRefresh from '@/components/button/refresh'
import item from '@/components/services/sendingsModeration/sendings/item'

function renderRefreshButton(h) {
  return h(
    buttonRefresh,
    {
      props: {
        state: this.loading,
        disabled: this.loading || !this.filter.OwnerId,
        tooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.buttons.refresh`),
        shortClickMethod: () => this.$emit('refresh')
      }
    }
  )
}

function renderPagination(h) {
  return h(
    pagination,
    {
      props: {
        value: this.pagination,
        selectRows: false,
        service: services.sendingsModeration
      },
      on: {
        input: event => {
          this.pagination = event
        }
      }
    }
  )
}

function renderPaginationBlock(h, place) {
  return h(
    'div',
    {
      class: {
        [`${services.sendingsModeration}__pagination ${services.sendingsModeration}__pagination--${place}`]: true,
        [`${services.sendingsModeration}__pagination--fill`]: this.pagination.total > 0
      }
    },
    [
      renderPagination.call(this, h),
      renderRefreshButton.call(this, h)
    ]
  )
}

function renderItem(h, value) {
  return h(
    item,
    {
      props: {
        value,
        filter: this.filter
      },
      key: value.uniqueKey
    }
  )
}

function renderItems(h) {
  if (this.items.length && this.filter.OwnerId) {
    return this.items.map(item => renderItem.call(this, h, item))
  }

  return h('g-empty')
}

export default function(h) {
  return h(
    'g-card',
    {
      class: `${services.sendingsModeration}-sendings`,
      props: {
        rounded: true,
        outline: true
      }
    },
    [
      renderPaginationBlock.call(this, h, 'top'),
      renderItems.call(this, h)
    ]
  )
}
