import proxy from '@sigma-legacy-libs/g-proxy'

import { generateServices, globalErrorHandler, globalErrorProcessor, sendingsOutputFilter, services } from '@/utils'

import render from './render'

export default {
  name: 'TemplatesItem',

  mixins: [
    proxy({ type: 'object' }),

    generateServices({
      name: services.templates,

      outputFilter: sendingsOutputFilter,

      get: false,
      find: false,
      create: false,
      update: {
        manipulateData: false,
        params: {
          query: {
            $scope: [ 'full' ]
          }
        }
      },
      remove: {
        manipulateData: false,
        redirect: false
      }
    })
  ],

  methods: {
    async updateTemplate() {
      try {
        await this.rest[services.templates].update(this.proxy.id, this.proxy)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    },
    async removeTemplate() {
      try {
        await this.rest[services.templates].remove(this.proxy.id)
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      }
    }
  },

  render
}
