import panel from '@/components/services/sendings/replacement/panel'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      h(
        'div',
        {
          class: {
            'grid grid-gap--8': true,
            'grid-cols--3': this.viewport.breakpoint.mdUp
          }
        },
        [ 'type', 'state.moderation', 'payload.sender' ].map(type => {
          const value = this.replacement[type]

          return h(
            panel,
            {
              props: {
                type,
                value,
                expanded: value.length > 1
              }
            }
          )
        })
      ),

      h(
        'div',
        {
          class: {
            'grid grid-gap--8': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [ 'payload.text', 'fallbacks' ].map(type => {
          const value = this.replacement[type]

          return h(
            panel,
            {
              props: {
                type,
                value,
                expanded: value.length > 1
              }
            }
          )
        })
      )
    ]
  )
}
