import { borderStyles, moderationColors, moderationIcons, services } from '@/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.getTranslate(`misc.moderation.${this.value.moderation}`),
        label: this.getTranslate(`${services.patterns}.labels.moderation`),
        icon: moderationIcons[this.value.moderation],
        color: moderationColors[this.value.moderation],
        iconTooltip: this.getTranslate(`misc.moderation.${this.value.moderation}`),
        borderStyle: borderStyles.dashed
      }
    }
  )
}
