import { inputModes } from '@/utils'
import { indexRegExp, serviceName } from '@/components/services/requisites/utils'

function renderFieldMatches(h) {
  if (this.matches) {
    return h(
      'div',
      {
        class: 'faic grid grid-gap--8',
        style: {
          'grid-template-columns': '36px 1fr'
        }
      },
      [
        h(
          'g-switch',
          {
            props: {
              value: this.addressMatches
            },
            on: {
              input: event => {
                this.addressMatches = event
              }
            }
          }
        ),

        h(
          'div',
          {
            class: 'text--grey caption'
          },
          [ this.getTranslate(`${serviceName}.labels.addressMatches`) ]
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--16'
    },
    [
      renderFieldMatches.call(this, h),

      h(
        'div',
        {
          class: {
            'grid grid-gap--16 faifs': true,
            'grid-cols--2': this.viewport.breakpoint.mdUp
          }
        },
        [
          h(
            'div',
            {
              class: 'grid grid-gap--16'
            },
            [
              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.index,
                    label: this.getTranslate(`${serviceName}.labels.index`),
                    error: this.proxy.index && !indexRegExp.test(this.proxy.index) ? this.getTranslate(`${serviceName}.errors.index`) : undefined,
                    mode: inputModes['line-label'],
                    trim: true,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true,
                    disabled: this.addressMatches,
                    validateOnBlur: true,
                    mask: /^\d{1,6}$/
                  },
                  on: {
                    input: event => {
                      this.proxy.index = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.country,
                    label: this.getTranslate(`${serviceName}.labels.country`),
                    disabled: this.addressMatches,
                    mode: inputModes['line-label'],
                    trim: true,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true
                  },
                  on: {
                    input: event => {
                      this.proxy.country = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.city,
                    label: this.getTranslate(`${serviceName}.labels.city`),
                    disabled: this.addressMatches,
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    required: true
                  },
                  on: {
                    input: event => {
                      this.proxy.city = event
                    }
                  }
                }
              )
            ]
          ),

          h(
            'div',
            {
              class: 'grid grid-gap--16'
            },
            [
              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.address,
                    label: this.getTranslate(`${serviceName}.labels.address`),
                    disabled: this.addressMatches,
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false
                  },
                  on: {
                    input: event => {
                      this.proxy.address = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.phone,
                    label: this.getTranslate(`${serviceName}.labels.phone`),
                    disabled: this.addressMatches,
                    type: 'tel',
                    mode: inputModes['line-label'],
                    validateOnBlur: true,
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    mask: /^[\d\s()+-]+$/
                  },
                  on: {
                    input: event => {
                      this.proxy.phone = event
                    }
                  }
                }
              ),

              h(
                'g-text-field',
                {
                  props: {
                    value: this.proxy.phoneAdd,
                    label: this.getTranslate(`${serviceName}.labels.phoneAdd`),
                    disabled: this.addressMatches,
                    mode: inputModes['line-label'],
                    dense: true,
                    rounded: true,
                    clearable: true,
                    details: false,
                    mask: /^[\d\s()+-]+$/
                  },
                  on: {
                    input: event => {
                      this.proxy.phoneAdd = event
                    }
                  }
                }
              )
            ]
          )
        ]
      )
    ]
  )
}
