import { EVENTSHISTORY_ASSOCIATED_SERVICES, EVENTSHISTORY_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { services } from '@/utils'

import render from './render'

export default {
  name: 'TabRow',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    typeColor() {
      let result = 'grey'
      switch (this.value.type) {
        case EVENTSHISTORY_TYPES.created: {
          result = 'success'
          break
        }
        case EVENTSHISTORY_TYPES.updated: {
          result = 'primary'
          break
        }
        case EVENTSHISTORY_TYPES.removed: {
          result = 'error'
          break
        }
      }

      return result
    },

    service() {
      const result = {
        label: `${this.value.service}.title`
      }
      switch (this.value.service) {
        case EVENTSHISTORY_ASSOCIATED_SERVICES.contactListsContacts: {
          result.label = `${services.eventsHistory}.services.contacts`
          break
        }
        case EVENTSHISTORY_ASSOCIATED_SERVICES.billingsUsers: {
          result.label = `${services.eventsHistory}.services.billingsUsers`
          break
        }
      }

      return result
    }
  },

  methods: {
    clickHandler() {
      this.$emit('click')
    }
  },

  render
}
