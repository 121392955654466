import { serviceTemplate, services } from '@/utils'

import { outputFilter } from '@/components/services/messageRules/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.messageRules,
      outputFilter,

      width: 500,
      view: 'panels',
      views: [ 'panels' ],

      has: {
        view: false,
        filter: false
      },

      clone: {
        replacements: {
          isActive: true
        }
      }
    })
  ]
}
