export default {
  title: 'Sign In',

  labels: {
    username: 'Username',
    password: 'Password'
  },

  buttons: {
    logout: 'Logout',
    signin: 'Sign In',
    signup: 'Sign Up'
  }
}
