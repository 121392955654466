import render from './render'

export default {
  name: 'FileOwner',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    readonly: Boolean
  },

  render
}
