import sticker from '@/components/misc/sticker'
import owner from '@/components/serviceTemplate/item/owner'
import date from '@/components/serviceTemplate/item/date'
import buttons from '@/components/services/contracts/item/buttons'

import { serviceName } from '@/components/services/contracts/utils'

function renderTitle(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        label: this.viewport.breakpoint.xs ? this.value.Owner.username : this.getTranslate(`${serviceName}.labels.title`),
        service: 'contracts'
      }
    }
  )
}

function renderRequisite(h) {
  if (this.value.Requisite) {
    return h(
      sticker,
      {
        props: {
          value: this.value.Requisite,
          label: this.getTranslate(`${serviceName}.labels.requisite`),
          service: 'requisites'
        }
      }
    )
  }
}

function renderCompany(h) {
  if (this.value.Company) {
    return h(
      sticker,
      {
        props: {
          value: this.value.Company,
          label: this.getTranslate(`${serviceName}.labels.company`),
          service: 'companies'
        }
      }
    )
  }
}

function renderOwner(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    owner,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderDetails(h) {
  if (this.viewport.breakpoint.mdUp) {
    return h(
      'div',
      {
        class: `${serviceName}-panel__details`,
        style: {
        }
      },
      [
        renderRequisite.call(this, h),
        renderCompany.call(this, h)
      ]
    )
  }
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: `${serviceName}-panel__info`,
      style: {
      }
    },
    [
      renderTitle.call(this, h),
      renderOwner.call(this, h)
    ]
  )
}

function renderButtons(h) {
  return h(
    'div',
    {
      class: 'faic fjcfe'
    },
    [
      h(
        buttons,
        {
          props: {
            value: this.value
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${serviceName}-panel`]: true
      }
    },
    [
      renderInfo.call(this, h),
      renderDetails.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
