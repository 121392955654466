import numeral from 'numeral'
numeral.locale('en')

function renderValue(h, { metric, value }) {
  return h(
    'div',
    {
      class: {
        'fjcc faic': true,
        'square--40': this.mode === 'compact',
        'square--48': this.mode === 'full'
      },
      directives: [
        {
          name: 'g-tooltip',
          options: {
            value: this.getTranslate(`providers.tooltips.presets.${metric}`, { count: value })
          }
        }
      ]
    },
    [
      h(
        'div',
        {
          class: {
            'fz-18': this.mode === 'compact',
            'fz-16': this.mode === 'full',
            'text--grey': value <= 0
          }
        },
        numeral(value).format('0.[00]a')
      )
    ]
  )
}

function renderMetric(h, { metric, value }) {
  switch (this.mode) {
    case 'full': {
      return h(
        'div',
        {
          class: 'faic'
        },
        [
          renderValue.call(this, h, {
            metric,
            value
          }),

          h(
            'div',
            {
              class: 'fc ff pl-3'
            },
            [
              h('div', { class: 'caption mbpx-2' }, this.getTranslate(`providers.chart.labels.${metric}`)),
              h('div', { class: 'small text--grey' }, this.getTranslate(`providers.hints.presets.${metric}`))
            ]
          )
        ]
      )
    }

    case 'compact':
    default: {
      return renderValue.call(this, h, {
        metric,
        value
      })
    }
  }
}

function renderPreloader(h) {
  if (this.loading) {
    return h(
      'div',
      {
        class: 'fjcc faic ff'
      },
      [
        h(
          'g-progress',
          {
            props: {
              indeterminate: true
            }
          }
        )
      ]
    )
  }
}

function renderMetrics(h) {
  if (this.metrics) {
    return h(
      'div',
      {
        class: {
          grid: true,
          'grid-cols--1': this.mode === 'full' && this.viewport.breakpoint.smDown,
          'grid-cols--2': this.mode === 'full' && this.viewport.breakpoint.mdUp,
          'grid-cols--4 grid-gap--32': this.mode === 'compact'
        }
      },
      Object.keys(this.metrics).map(metric => {
        return renderMetric.call(this, h, {
          metric,
          value: this.metrics[metric]
        })
      })
    )
  } else {
    return renderPreloader.call(this, h)
  }
}

function renderContent(h) {
  return h(
    'div',
    {
      class: 'faic position-relative'
    },
    [ renderMetrics.call(this, h) ]
  )
}

export default function(h) {
  return renderContent.call(this, h)
}
