import MarkdownIt from 'markdown-it'

import { cloneDeep, isEqual } from 'lodash'
import { NOTIFICATIONS_AVAILABLE_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { backendServerIP, proxy } from '@/utils'

import Prism from 'prismjs'

import render from './render'

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typography: true,

  highlight(code, lang) {
    const source = Prism.highlight(code, Prism.languages[lang] || Prism.languages.markup, lang)

    return `<pre class="language-${lang || 'markup'}"><code class="language-${lang || 'markup'}">${source}</code></pre>`
  }
})

export default {
  name: 'NotificationTemplateDialog',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    path: String,

    show: Boolean,
    loading: Boolean
  },

  data() {
    return {
      loadingDocument: false,
      showDialog: false,
      showInfoDialog: false,

      html: undefined
    }
  },

  computed: {
    title() {
      return this.path.replace('.manager', '')
    },

    channel() {
      let result
      Object.keys(NOTIFICATIONS_AVAILABLE_TYPES).map(type => {
        if (this.path.includes(type)) {
          result = type
        }
      })

      return result
    }
  },

  watch: {
    show() {
      this.showDialog = !!this.show
    }
  },

  mounted() {
    this.showDialog = !!this.show
    this.getDocument()
  },

  methods: {
    transmitValue() {
      return
    },

    clickHandler() {
      if (!isEqual(this.proxy, this.value)) {
        this.$emit('input', cloneDeep(this.proxy))
      }
    },

    async getDocument() {
      this.loadingDocument = true
      this.html = undefined
      const response = await fetch(`${backendServerIP}/docs/${this.locale}/platform/administrator/settings.md`, { cache: 'no-cache' })
      if (response.ok) {
        this.html = md.render(await response.text())
      }
      this.loadingDocument = false
    }
  },

  render
}
