import { serviceName } from '@/components/services/yclients/utils'

import sticker from '@/components/misc/sticker'

export default function(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value,
        service: serviceName,
        pathToTitle: 'salonId',
        label: this.getTranslate(`${serviceName}.labels.salon`),
        icon: this.status.icon,
        color: this.status.color,
        iconTooltip: this.status.tooltip
      }
    }
  )
}
