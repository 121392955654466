import { cloneDeep } from 'lodash'

import { Draggable } from '@/utils'

import render from './render'

export default {
  name: 'Draggable',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    handle: String,
    disabled: Boolean
  },

  data() {
    return {
      draggable: undefined
    }
  },

  watch: {
    'draggable.eventType'() {
      if (this.draggable.eventType === 'end') {
        const list = cloneDeep(this.value)
        const [ item ] = list.splice(this.draggable.oldIndex, 1)
        list.splice(this.draggable.newIndex, 0, item)
        this.$emit('input', list)
        this.$nextTick(() => this.draggable.setOptions())
      }
    }
  },

  mounted() {
    if (!this.draggable) {
      this.draggable = new Draggable(this.$refs.draggable, this.handle, this.disabled)
    }
  },

  render
}
