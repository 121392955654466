import { inputModes } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      class: 'pt-2 px-2'
    },
    [
      h(
        'g-text-field',
        {
          props: {
            value: this.proxy.title,
            label: this.getTranslate('templates.labels.title'),
            error: this.errors.title,
            mode: inputModes['line-label'],
            rounded: true,
            details: false,
            autofocus: true
          },
          on: {
            input: event => {
              this.proxy.title = event
            },
            keypress: event => {
              if (event.keyCode === 13) {
                this.$emit('submit', this.proxy)
              }
            }
          }
        }
      ),

      this.$slots.default
    ]
  )
}
