import { WEBSOCKET_CHANNELS } from '@sigma-legacy-libs/essentials/lib/constants/misc'

export default function(h, { path, serviceName }) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy,
        label: this.getTranslate(`${serviceName}.labels.${path}`),
        items: WEBSOCKET_CHANNELS.map(channel => {
          return {
            title: this.getTranslate(`${serviceName}.items.${path}.${channel}`),
            value: channel
          }
        }),
        mode: 'outline-label',
        dense: true,
        details: false,
        rounded: true,
        multiple: true
      },
      on: {
        input: event => {
          this.proxy = event
        }
      }
    }
  )
}
