import render from './render'

export default {
  name: 'ModerationStatusBarWithIcon',

  props: {
    title: String,

    type: {
      type: String,
      default: 'approved'
    },

    size: {
      type: Number,
      default: 20
    }
  },

  render
}
