import proxy from '@sigma-legacy-libs/g-proxy'

import { cloneDeep, merge } from 'lodash'

import render from './render'

const defaultValue = {
  key: undefined,
  value: undefined
}

export default function(options) {
  return {
    name: 'ResellersDomains',

    mixins: [ proxy({ type: 'object' }) ],

    methods: {
      add() {
        this.proxy.push(cloneDeep(defaultValue))
      },
      remove(index) {
        this.proxy.splice(index, 1)
        if (this.proxy.length === 0) {
          this.add()
        }
      },

      _inputFilter(data) {
        if (Object.keys(data).length === 0) {
          return [ cloneDeep(defaultValue) ]
        }

        return Object.keys(data).reduce((result, key) => {
          result.push({
            key,
            value: data[key]
          })

          return result
        }, [])
      },
      _outputFilter(data) {
        return data.reduce((result, element) => {
          if (element.key && element.value) {
            merge(result, { [element.key]: element.value })
          }

          return result
        }, {})
      },

      watchValueHandler() {
        return
      },
      watchProxyHandler() {
        return
      }
    },

    render(h) {
      return render.call(this, h, options)
    }
  }
}
