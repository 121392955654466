export default {
  title: 'WAPI',

  titles: {
    create: 'Создание инстанса'
  },

  labels: {
    search: 'Поиск',
    phone: 'Телефон',
    isPaid: 'Статус оплаты',
    isCanceled: 'Автопродление',
    expiredAt: 'Истекает в',
    instance: {
      id: 'ID',
      idInstance: 'Инстанс',
      state: 'Статус инстанса',
      apiKey: 'API ключ',
      apiUrl: 'API url',
      expiration: 'Срок действия'
    },
    owner: 'Владелец'
  },

  states: {
    isPaid: {
      true: 'Оплачен',
      false: 'Не оплачен',
      undefined: 'Не оплачен'
    },
    instance: {
      authorized: 'Авторизован',
      notAuthorized: 'Не авторизован',
      sleep: 'Перезагрузка',
      deleted: 'Удалён',
      undefined: 'Статус не определён'
    }
  },

  hints: {
    create: 'В соответствии с тарифом за создание инстанса с вашего счёта будет списано {payment}.',
    notFound: 'Не найдено ни одного инстанса',
    phoneNotFound: 'Телефон не подтвержден',
    deviceLinkBeta: 'Чтобы подключить устройство к инстансу включите функцию в вашем WhatsApp "Бета-версия для нескольких устройств".',
    logoutInstance: 'Подтвердите деавторизацию инстанса?',
    isActive: 'Инстанс деактивирован администратором. Обратитесь в поддержку.',
    badInstance: 'Неисправный инстанс'
  },

  links: {
    showExample: 'Показать пример',
    requestNewQrCode: 'Запросить новый QR код'
  },

  tooltips: {
    favorites: 'Сделать инстанс избранным',
    isActive: 'Активировать или деактивировать инстанс.',
    scanQrCode: 'Сканировать QR код',
    renewInstance: 'Обновить инстанс',
    logoutInstance: 'Деавторизовать инстанс'
  },

  errors: {
    'instance-expired': 'Срок действия инстанса истёк. Необходимо обновить его с последующей оплатой.',
    'instance-removed': 'Инстанс удалён, перейдите на страницу списка инстансов и обновите её.',
    'instance-connected': 'Инстанс уже подключен, обновите страницу.',
    'instance-starting': 'Инстанс запускается, обновите страницу.',
    'instance-authorized': 'Инстанс уже авторизован, обновите страницу.',
    'phone-already-used': 'Этот телефон уже привязан к другому инстансу.',
    'tariff-required': 'К вашему аккаунту не привязан необходимый тариф. Обратитесь в поддержку.',
    'qr-code-timeout': 'QR код не был отсканирован в требуемое время.',
    'close-connection': 'Соединение закрыто. Если ошибка повторится, обратитесь в поддержку.'
  },

  snackbars: {
    created: 'Инстанс успешно создан.',
    updated: 'Инстанс успешно обновлён.',
    removed: 'Инстанс успешно удалён.'
  }
}
