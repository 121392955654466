function renderPoint(h, index) {
  return h(
    'div',
    {
      class: `preloader-point square--${this.size}`,
      style: {
        opacity: index === this.currentIndex ? 1 : undefined,
        width: this.$size,
        height: this.$size
      }
    }
  )
}

function renderPoints(h) {
  const points = []
  for (let index = 0; index < this.count; index++) {
    points.push(renderPoint.call(this, h, index))
  }

  return points
}

export default function(h) {
  if (this.value) {
    return h(
      'div',
      {
        class: {
          preloader: true,
          [`preloader--${this.color}`]: true
        },
        style: {
          gridTemplateColumns: this.gridTemplateColumns,
          gap: this.gap
        }
      },
      [ renderPoints.call(this, h) ]
    )
  }
}
