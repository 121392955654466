import Vue from 'vue'

import { generateServices, globalErrorHandler, globalErrorProcessor, permissionPresets, services } from '@/utils'

import { errorProcessor, errorProcessorHandler } from '@/components/services/contacts/utils'

import render from './render'

export default {
  name: 'Dialogs',

  mixins: [
    generateServices([
      {
        name: services.contacts,
        outputFilter(result) {
          if (!result.ListId) {
            result.ListId = this.ListId
          }

          for (const key in result) {
            if (result[key] === undefined) {
              result[key] = null
            }
          }

          return result
        },

        find: false,

        errorProcessor: errorProcessorHandler
      },
      {
        name: services.contactLists,

        find: false,
        remove: {
          redirect: services.contacts
        }
      }
    ])
  ],

  props: {
    id: String,
    type: String,

    show: {
      type: Boolean,
      default: false
    },

    ListId: String
  },

  computed: {
    actionByType() {
      if (this.type) {
        const result = {}
        const [ service, method ] = this.type.split('.')
        Object.assign(result, {
          service,
          method
        })

        return result
      }
    }
  },

  watch: {
    id() {
      this.getByType()
    }
  },

  mounted() {
    this.getByType()
  },

  methods: {
    async get(service) {
      if (this.checkPermissions(`advanced.${services.contacts}.get`, permissionPresets.meUp)) {
        if (this.id) {
          try {
            await this.rest[service].get(this.id)
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async create(service) {
      if (this.checkPermissions(`advanced.${services.contacts}.create`, permissionPresets.meUp)) {
        if (this.restData[service].create.isValid) {
          try {
            const result = await this.rest[service].create(this.restData[service].create.data)
            if (result) {
              if (service === services.contactLists) {
                Vue.router.push({
                  name: `${services.contacts}.single`,
                  params: { id: result.id }
                })
              }
              this.$emit('show', false)
            }
          } catch (error) {
            if (service === services.contacts) {
              errorProcessorHandler.call(this, errorProcessor.call(this, error))
            } else {
              globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
            }
          }
        }
      }
    },
    async update(service) {
      if (this.checkPermissions(`advanced.${services.contacts}.update`, permissionPresets.meUp)) {
        if (this.restData[service].update.isValid) {
          try {
            const result = await this.rest[service].update(this.restData[service].get.data.id, this.restData[service].get.data)
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },
    async remove(service) {
      if (this.checkPermissions(`advanced.${services.contacts}.remove`, permissionPresets.meUp)) {
        if (this.id) {
          try {
            const result = await this.rest[service].remove(this.id)
            if (result) {
              this.$emit('show', false)
            }
          } catch (error) {
            globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
          }
        }
      }
    },

    getByType() {
      switch (this.type) {
        case `${services.contacts}.update`: {
          this.get(services.contacts)
          break
        }
        case `${services.contactLists}.update`: {
          this.get(`${services.contactLists}`)
          break
        }
      }
    }
  },

  render
}
