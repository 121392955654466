import { serviceName } from '@/components/services/sendings/incoming/utils'

import render from './render'

export default {
  name: 'Panel',

  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    infoGridTemplateColumns() {
      const result = [ '140px' ]

      if (this.value.payload.text) {
        if (this.viewport.breakpoint.lgUp) {
          result.push('350px')
        } else if (this.viewport.breakpoint.smUp) {
          result.push('1fr')
        }
      }

      if (this.checkPermissions('advanced.users.get')) {
        if (this.viewport.breakpoint.smUp) {
          result.push('auto')
        }
      }

      return result.join(' ')
    },
    panelGridTemplateColumns() {
      const result = [ '1fr' ]

      if (this.viewport.breakpoint.lgUp) {
        if (this.value.File) {
          result.push('auto')
        }
      }

      if (this.checkPermissions(`advanced.${serviceName}.get`)) {
        result.push('36px')
      }

      if (this.viewport.breakpoint.lgUp) {
        result.push('auto')
      }

      return result.join(' ')
    }
  },

  render
}
