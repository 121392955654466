import Vue from 'vue'

import { cloneDeep } from 'lodash'

import { globalErrorHandler, globalErrorProcessor } from '@/utils'

import { serviceName } from '@/components/services/contracts/utils'

import render from './render'

export default {
  name: 'EditButton',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    view: {
      type: String,
      default: 'g-button',
      validator: value => {
        return ~[ 'g-button', 'g-list-item' ].indexOf(value)
      }
    }
  },

  data() {
    return {
      currentEditFile: undefined,
      googleFileURL: undefined,
      showDialog: false,

      loading: false
    }
  },

  computed: {
    $props() {
      const result = {
        icon: 'description'
      }

      switch (this.view) {
        case 'g-button': {
          return Object.assign(result, {
            flat: true,
            rounded: true,
            loading: this.loading,
            disabled: this.loading
          })
        }
        case 'g-list-item': {
          return Object.assign(result, {
            label: this.getTranslate('misc.buttons.edit'),
            dense: true
          })
        }
      }
    },

    $directives() {
      switch (this.view) {
        case 'g-button': {
          return [
            {
              name: 'g-tooltip',
              options: {
                value: this.getTranslate(`${serviceName}.tooltips.edit`),
                placement: 'top'
              }
            }
          ]
        }
      }
    }
  },

  methods: {
    async edit() {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.find(`storage/edit/${this.value.File.id}`, { query: { OwnerId: this.value.File.OwnerId } })
        if (data) {
          this.googleFileURL = data.result
          this.currentEditFile = cloneDeep(this.value.File)
          this.showDialog = true
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
