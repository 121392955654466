import { positiveNumberRegex } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8',
      style: { 'grid-template-columns': 'repeat(2, 110px)' }
    },
    this.proxy.map((price, index) => {
      return h(
        'g-text-field',
        {
          props: {
            value: price,
            suffix: this.getTranslate('currency.RUB.symbol.unicode'),
            hint: this.hint[index],
            mode: 'outline',
            rounded: true,
            dense: true,
            mask: positiveNumberRegex,
            processValue: value => value?.toString().replace(',', '.')
          },
          on: {
            input: event => {
              this.proxy[index] = event
            }
          }
        }
      )
    })
  )
}
