import { colors } from '@/utils'

export const getColor = type => {
  switch (type) {
    case 'apisender.instance.created':
    case 'apisender.instance.connected':
    case 'sendername.approved':
    case 'pattern.approved':
    case 'balance.accrual':
    case 'auth.registration':
    case 'sendings.bulk.created':
    case 'sendings.bulk.resumed':
    case 'subscription.enabled': return colors.success

    case 'apisender.instance.disconnected':
    case 'balance.limit':
    case 'pattern.rejected':
    case 'payments.failed':
    case 'sendername.rejected':
    case 'subscription.renew.failed':
    case 'system.platform.issues':
    case 'system.route.issues': return colors.error

    case 'sendings.bulk.paused':
    case 'balance.daily':
    case 'auth.recover':
    case 'tariff.update': return colors.info

    default: return colors.grey
  }
}
