export default {
  namespaced: true,

  state: {
    dialog: {
      isShowed: false,

      callback: () => {}
    }
  },

  mutations: {
    show(state) {
      state.dialog.isShowed = true
    },
    hide(state) {
      state.dialog.isShowed = false
    },
    toggle(state) {
      state.dialog.isShowed = !state.dialog.isShowed
    },
    setIsShowed(state, trigger) {
      state.dialog.isShowed = trigger
    },
    setCallback(state, callback) {
      state.dialog.callback = callback
    }
  },

  actions: {
    show({ commit }) {
      commit('show')
    },
    hide({ commit }) {
      commit('hide')
    },
    toggle({ commit }) {
      commit('toggle')
    },
    setIsShowed({ commit }, trigger) {
      commit('setIsShowed', trigger)
    },
    setCallback({ commit }, callback) {
      commit('setCallback', callback)
    }
  },

  getters: {
    dialog: state => state.dialog
  }
}
