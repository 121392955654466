import info from '@/components/services/sendernames/item/info'
import comments from '@/components/services/sendernames/item/comments'
import moderationButtons from '@/components/services/sendernames/item/moderation/buttons'
import date from '@/components/serviceTemplate/item/date'

function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderComments(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      comments,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

function renderActions(h) {
  return h(
    moderationButtons,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'service-template-panel hover-parent hover-parent--opacity',
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderInfo.call(this, h),
      renderComments.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
