function renderHeading(h, heading) {
  if (heading) {
    return h(
      'li',
      {
        attrs: { id: `${name}-toc-list-level-${heading.slug}` },
        class: {
          [`${name}-toc-list-level ${name}-toc-list-level--${heading.level} text-overflow`]: true,
          [`${name}-toc-list-level--arrow`]: Array.isArray(heading.childs) && heading.childs.length > 0
        }
      },
      [
        h(
          'router-link',
          {
            props: {
              to: {
                name: `${name}.file`,
                params: { file: this.$route.params.file },
                query: { id: heading.slug }
              }
            }
          },
          heading.title
        ),

        renderHeadingChilds.call(this, h, heading.childs)
      ]
    )
  }
}

function renderHeadings(h, heading) {
  if (heading) {
    return h(
      'ul',
      {
        class: `${name}-toc-list ${name}-toc-list--sub`
      },
      [ renderHeading.call(this, h, heading) ]
    )
  }
}

function renderHeadingChilds(h, childs) {
  if (Array.isArray(childs) && childs.length > 0) {
    return childs.map(heading => {
      return renderHeadings.call(this, h, heading)
    })
  }
}

function renderListOfHeadings(h, file) {
  if (Array.isArray(this.toc) && this.toc.length > 0 && this.$route.params.file === file) {
    return this.toc.map(heading => {
      return renderHeadings.call(this, h, heading)
    })
  }
}

function renderFiles(h, category, files) {
  if (Array.isArray(files) && files.length > 0) {
    return h(
      'ul',
      {
        class: `${name}-toc-list ${name}-toc-list--sub`
      },
      files.map(file => {
        return h(
          'li',
          {
            attrs: { id: `${name}-category-${category}-file-${file}` },
            class: `${name}-toc-list-level ${name}-toc-list-level--file ${name}-toc-list-level--arrow text-overflow`
          },
          [
            h(
              'router-link',
              {
                props: {
                  to: {
                    name: `${name}.file`,
                    params: {
                      category,
                      file
                    }
                  }
                }
              },
              this.getTranslate(`${name}.files.${file}`)
            ),

            renderListOfHeadings.call(this, h, file)
          ]
        )
      })
    )
  }
}

const name = 'documentation'
const levels = [
  {
    category: 'platform',
    files: [ 'instruction' ]
  },
  {
    category: 'api',
    files: [ 'HTTP-REST', 'SMPP' ]
  }
]

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${name}-toc`]: true,
        [`${name}-toc--scrollable`]: this.scrollable
      }
    },
    [
      h(
        'div',
        {
          class: `${name}-toc-holder`
        },
        [
          h(
            'div',
            {
              class: `${name}-toc-list`
            },
            levels.map(({ category, files }) => {
              return [
                h(
                  'h3',
                  {
                    class: `${name}-toc-list-title`
                  },
                  this.getTranslate(`${name}.categories.${category}`)
                ),

                renderFiles.call(this, h, category, files)
              ]
            })
          )
        ]
      )
    ]
  )
}
