import { notificationsColors } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      style: {
        'background-color': `var(--${notificationsColors[this.value]})`,
        'mask-image': `url(/notifications/${this.value}.svg)`,
        'mask-position': 'center',
        'mask-repeat': 'no-repeat',
        'mask-size': 'contain',
        'min-height': `${this.size}px`,
        'max-height': `${this.size}px`,
        height: `${this.size}px`,
        'min-width': `${this.size}px`,
        'max-width': `${this.size}px`,
        width: `${this.size}px`
      },
      directives: [
        {
          name: 'tooltip',
          value: {
            text: this.getTranslate(`sendings.types.${this.value}`),
            placement: 'top'
          }
        }
      ]
    }
  )
}
