import info from '@/components/services/servers/item/info'
import date from '@/components/serviceTemplate/item/date'

function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: 'service-template-panel hover-parent hover-parent--opacity',
      style: { gridTemplateColumns: '1fr auto' }
    },
    [
      renderInfo.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
