export default {
  title: 'Права',

  subtitles: {
    presets: 'Пресеты'
  },

  dialogs: {
    titles: {
      info: 'Информация по уровням прав'
    }
  },

  labels: {
    create: 'Создать',
    check: 'Проверить номер',
    finalize: 'Завершать',
    find: 'Найти',
    generate: 'Генерировать',
    set: 'Установить',
    get: 'Получить',
    getSendingsForTask: 'Получить отправления для задачи',
    remove: 'Удалить',
    update: 'Обновить',
    modify: 'Изменить',
    seek: 'Seek',
    download: 'Скачать',
    upload: 'Загрузить',
    setRead: 'Установить прочитанным',
    setPermissions: 'Задавать права',
    setRoutingGroups: 'Задавать группы маршрутизаций',
    setMessageRuleGroups: 'Задавать группы правил обработки сообщений',
    setSettings: 'Задавать настройки',
    setSendernames: 'Задавать имена отправителей',
    setPatterns: 'Задавать паттерны',
    setTariffs: 'Задавать тарифы',
    showSecrets: 'Показывать секретные права',
    trigger: 'Триггер',
    health: 'Производительность',
    loginAs: 'Войти под',
    request: 'Запрос',
    controlDocuments: 'Работы с документами',
    edit: 'Редактировать',
    viewChangelogs: 'Просматривать список изменений',
    contracts: 'Договоры',
    companies: 'Компании',
    documentTemplates: 'Шаблоны документов',
    refund: 'Возврат платежа',
    presetUsersBalance: 'Возможность скачивать список пользователей',
    matchKeywords: 'Проверять ключевые слова',
    setOtpChannels: 'Задавать каналы OTP'
  },

  hints: {
    false: 'Запрещает любые действия требующие соответствующее право.',
    me: 'Разрешает действия с объектами, которые пользователь создал сам, или ему создал его реселлер или администратор.',
    manager: 'Разрешает действия с объектами, которые принадлежат самому пользователю, либо привязанным к менеджеру пользователям.',
    reseller: 'Разрешает действия с объектами, которые принадлежат самому пользователю, либо дочерним пользователям реселлера.',
    true: 'Разрешает действия с любыми объектами в системе.',
    warning: 'Изменение прав не требует обновления пользователя.'
  },

  tooltips: {
    info: 'Информация по уровням прав'
  },

  buttons: {
    false: 'False',
    me: 'User',
    reseller: 'Reseller'
  },

  services: {
    apisender: 'API sender',
    billings: 'Расчётные счета',
    changelogs: 'Список изменений',
    companies: 'Компании',
    components: 'Компоненты',
    contacts: 'Контакты',
    contracts: 'Договоры',
    credentials: 'Учётные данные',
    dadata: 'Dadata',
    dictionaries: 'Словари',
    documentTemplates: 'Шаблоны документов',
    eventsHistory: 'История событий',
    hlr: 'HLR',
    invoices: 'Счета',
    keywords: 'Ключевые слова',
    messageProcessors: 'Обработчики сообщений',
    messageRuleGroups: 'Группы правил обработки сообщений',
    messageRules: 'Правила обработки сообщений',
    notifications: 'Уведомления',
    operatorGroups: 'Группы операторов',
    patterns: 'Шаблоны для модерации',
    payment: 'Платежи',
    paymentLogs: 'Журнал платежей',
    payments: 'Платежи',
    platform: 'Платформа',
    providerGroups: 'Группы провайдеров',
    providers: 'Провайдеры',
    recurrentPayments: 'Автоплатежи',
    reports: 'Отчеты',
    requisites: 'Реквизиты',
    routingGroups: 'Группы маршрутизаций',
    routingRules: 'Правила маршрутизаций',
    secret: 'Секретные',
    sendernames: 'Имена отправителей',
    sendings: 'Отправления',
    sendingsIncoming: 'Входящие сообщения',
    sendingsModeration: 'Модерация сообщений',
    servers: 'Серверы',
    settings: 'Настройки сайта',
    settingsSite: 'Настройки сайта',
    settingsUser: 'Настройки пользователей',
    storage: 'Хранилище',
    tariffs: 'Тарифы',
    tasks: 'Задачи',
    telemetry: 'Телеметрия',
    templates: 'Шаблоны сообщений',
    tokens: 'Токены',
    userComments: 'Комментарии пользователей',
    users: 'Пользователи',
    yclients: 'YClients',

    'otp-settings': {
      widgets: 'OTP виджеты',
      channels: 'OTP каналы',
      templates: 'OTP шаблоны'
    }
  },

  snackbars: {
    updated: 'Право успешно обновлено'
  },

  tooltips: {
    presets: 'При нажатии на эту кнопку, все права пользователя будут заменены на выбранный пресет, если таковой пресет у конкретного права существует. Изменения не обратимы и будут применены сразу.'
  }
}
