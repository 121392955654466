import { routerReplacer } from '@/utils'

import render from './render'

export default {
  name: 'Settings',

  metaInfo: {
    title: 'settingsSite.title'
  },

  data() {
    return {
      UserId: undefined
    }
  },

  watch: {
    $route: {
      handler() {
        this.setUserIdFromRoute()
      },
      deep: true
    },
    UserId() {
      if (this.UserId) {
        routerReplacer({
          name: 'settings.single',
          params: { UserId: this.UserId }
        })
      } else {
        routerReplacer({
          name: 'settings',
          params: { UserId: undefined }
        })
      }
    }
  },

  mounted() {
    this.setUserIdFromRoute()
  },

  methods: {
    setUserIdFromRoute() {
      const { UserId } = this.$route.params
      if (UserId) {
        this.UserId = UserId
      } else {
        this.UserId = undefined
      }
    }
  },

  render
}
