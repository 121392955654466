import { methods } from './payments'

export default {
  title: 'Recurrent payments',

  titles: {
    create: 'Create recurrent payment',
    payments: 'Payment history',
    empty: {
      title: 'No recurrent payments created',
      sub: 'Create a new recurrent payment using the button below'
    },
    remove: 'Remove confirm',
    unlock: 'Unlock confirm'
  },

  methods,

  types: {
    threshold: 'By threshold',
    frequency: 'Periodically',
    description: {
      threshold: 'Payment upon reaching the threshold',
      frequency: 'Payment once per period'
    }
  },

  statuses: {
    init: 'Initialized',
    ready: 'Ready',
    error: 'Error',
    pause: 'Pause',
    block: 'Block'
  },

  labels: {
    method: 'Payment method',
    amount: 'Amount',
    type: 'Type',
    status: 'Status',
    threshold: 'Threshold',
    frequency: 'Frequency',
    owner: 'Owner'
  },

  contents: {
    threshold: 'Upon reaching the threshold {threshold}',
    frequency: '{frequency}',
    remove: 'Confirm deletion of recurrent payment',
    unlock: 'Confirm unlock of recurrent payment'
  },

  hints: {
    method: 'Choose your payment method',
    amount: 'Enter amounts, greater or equal than threshold',
    type: 'Choose a method to repeat the payment',
    threshold: 'The threshold at which an attempt will be made to write off funds',
    frequency: 'How often to write off funds',
    redirect: 'After clicking on the "Create" button, you will be redirected to the payment page.',
    statuses: {
      init: 'Recurrent payment must be confirmed',
      ready: 'Recurrent payment active',
      pause: 'Recurrent payment on paused',
      error: 'Recurrent payment was completed with an error',
      block: 'Recurrent payment was blocked by error reason'
    },
    notFound: 'No recurrent payments found',
    owner: 'Payments owner',
    types: {
      threshold: 'Payment by threshold',
      frequency: 'Payment by frequency'
    },
    description: {
      amount: 'The default payment amount is equal to the threshold.'
    }
  },

  tooltips: {
    debit: '{amount} funds will be debited.',
    payments: 'Payments history',
    pause: 'Suspend execution',
    continue: 'Continue execution',
    approve: 'Approve payment',
    repeat: 'Repeat approve payment'
  },

  buttons: {
    add: 'Add recurrent payment',
    repeat: 'Repeat payment',
    resume: 'Resume',
    pause: 'Pause',
    payments: 'Payments history',
    confirm: 'Confirm'
  },

  links: {
    confirm: 'Confirm'
  },

  snackbars: {
    created: 'Recurrent payment created',
    updated: 'Recurrent payment updated',
    removed: 'Recurrent payment removed'
  },

  errors: {
    amount: {
      required: 'Amount is required',
      min: 'Amount cannot be equal to or less than zero',
      threshold: 'Amount cannot be less than the threshold'
    },
    threshold: {
      required: 'Threshold is required',
      min: 'Threshold cannot be equal to or less than zero'
    }
  }
}
