import render from './render'

export default {
  name: 'ButtonUpDown',

  data() {
    return {
      scrollTop: 0,
      scrollHeight: 0
    }
  },

  computed: {
    show() {
      return this.viewport.size.height < this.scrollHeight
    }
  },

  watch: {
    $route: {
      handler() {
        setTimeout(() => this.onScroll(), 250)
      },
      deep: true
    }
  },

  mounted() {
    setTimeout(() => this.onScroll(), 250)

    document.addEventListener('scroll', this.onScroll)
  },

  beforeDestroy() {
    document.removeEventListener('scroll', this.onScroll)
  },

  methods: {
    onScroll() {
      this.$nextTick(() => {
        this.scrollTop = document.documentElement.scrollTop
        this.scrollHeight = document.documentElement.scrollHeight
      })
    }
  },

  render
}
