import numeral from 'numeral'

import { permissionPresets } from '@/utils'

import SpacerCell from '@/components/misc/SpacerCell'
import SingleLink from '@/components/misc/SingleLink'
import FileType from '@/components/file/type'
import FileMenu from '@/components/file/menu'

function renderOwner(h) {
  if (this.checkPermissions('advanced.users.get', permissionPresets.resellerUp)) {
    return h(
      'td',
      {},
      [
        h(
          SingleLink,
          {
            props: {
              value: this.value.Owner,
              type: this.readonly ? 'text' : undefined,
              length: 15
            },
            on: {
              click: event => {
                event.stopPropagation()
              }
            }
          }
        )
      ]
    )
  }
}

export default function(h) {
  return h(
    'tr',
    {
      class: {
        file__info: true,
        'file__info--current': this.currentChosenFileId === this.value.id
      },
      on: {
        click: () => {
          if (this.readonly) {
            this.$emit('input', this.value)
          }
        }
      },
      key: 'file-as-row:' + this.value.id
    },
    [
      h(
        'td',
        {},
        [
          h(
            FileType,
            {
              props: {
                value: this.value,
                readonly: this.readonly
              }
            }
          )
        ]
      ),

      h('td', {}, [ h(SpacerCell, { props: { value: this.value.title || this.value.filename } }) ]),

      renderOwner.call(this, h),

      h(
        'td',
        {},
        [
          h(
            'div',
            {
              class: 'text-right fjcfe facfe faic text--grey'
            },
            numeral(this.value.size).format('0b')
          )
        ]
      ),

      h(
        'td',
        {
          class: 'text--grey text-right'
        },
        new Date(this.value.createdAt).toLocaleDateString(this.locale, {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric'
        })
      ),

      h(
        'td',
        {
          on: {
            click: event => {
              event.stopPropagation()
            }
          }
        },
        [
          h(
            FileMenu,
            {
              props: {
                value: this.value,
                readonly: this.readonly
              },
              on: {
                dialog: event => {
                  this.$emit('dialog', event)
                }
              }
            }
          )
        ]
      )
    ]
  )
}
