export default function(h) {
  return h(
    'footer',
    {
      class: this.$class,
      style: this.$style,
      ref: 'footer'
    },
    [ this.$slots.default ]
  )
}
