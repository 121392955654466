export default {
  title: 'Fast pay',

  labels: {
    amount: 'Amount',
    method: 'Method'
  },

  methods: {
    v1: {
      tinkoff: 'Tinkoff'
    }
  },

  hints: {
    commission: 'Bank fee: 3%',
    commissions: 'Fee'
  },

  errors: {
    owner: 'For payment, the link must contain the owner\'s ID.'
  }
}
