import { cloneDeep } from 'lodash'
import { PUBLIC_FIELDS_USER } from '@sigma-legacy-libs/essentials/lib/constants'

import { PATTERNS_FILTER, fillDependence, sendingsOutputFilter, serviceTemplate, services } from '@/utils'

export default {
  mixins: [
    serviceTemplate({
      serviceName: services.patterns,

      async inputFilter(result) {
        if (!result.Owner) {
          await fillDependence.call(this, result, {
            service: 'users',
            permissions: [ 'reseller', true ],
            pathToId: 'OwnerId',
            pathToObject: 'Owner',
            outputPath: 'Owner',
            picks: PUBLIC_FIELDS_USER
          })
        }

        result.OwnerId = result.Owner && result.Owner.id || result.OwnerId

        if (!result.Users) {
          result.Users = []
        }

        return result
      },

      outputFilter: sendingsOutputFilter,

      find: { defaultFilter: cloneDeep(PATTERNS_FILTER) },

      view: 'panels',
      views: [ 'panels' ],

      width: 600,

      has: {
        clone: false,
        favorites: false,
        view: false
      }
    })
  ]
}
