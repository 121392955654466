import { routerQueryReplace, services } from '@/utils'

import filter from '@/components/services/sendingsModeration/filter'
import users from '@/components/services/sendingsModeration/users'
import sendings from '@/components/services/sendingsModeration/sendings'
import tasks from '@/components/services/sendingsModeration/tasks'

function renderFilter(h) {
  return h(
    filter,
    {
      props: {
        value: this.filter,
        preset: this.preset
      },
      on: {
        input: event => {
          this.filter = event
        },
        preset: event => {
          this.preset = event
        }
      }
    }
  )
}

function renderUsers(h) {
  return h(
    users,
    {
      props: {
        users: this.users,
        loading: this.usersLoading
      },
      on: {
        userId: event => {
          this.filter.OwnerId = event
        },
        refresh: () => this.getUsers()
      }
    }
  )
}

function renderSendings(h) {
  return h(
    sendings,
    {
      props: {
        filter: this.filter,
        sendings: this.sendings,
        provider: this.provider,
        loading: this.sendingsLoading
      },
      on: {
        refresh: () => this.getSendings()
      }
    }
  )
}

function renderTab(h) {
  switch (this.currentTab) {
    case 'sendings': {
      return h(
        'div',
        {
          class: 'grid grid-gap--8 grid-rows--2 pa-2'
        },
        [
          renderFilter.call(this, h),

          h(
            'div',
            {
              class: `${services.sendingsModeration}-content`
            },
            [
              renderUsers.call(this, h),
              renderSendings.call(this, h)
            ]
          )
        ]
      )
    }
    case 'tasks': {
      return h(tasks)
    }
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc ff'
    },
    [
      h(
        'g-tabs-header',
        {
          class: 'pl-3',
          props: {
            value: this.currentTabs,
            tabs: this.tabs.map(key => {
              return {
                key,
                title: this.getTranslate(`${services.sendingsModeration}.tabs.${key}`)
              }
            })
          },
          on: {
            input: event => {
              routerQueryReplace({ currentTab: event })
            }
          }
        }
      ),
      h(
        'g-card',
        {
          props: {
            rounded: true,
            outline: true
          }
        },
        [ renderTab.call(this, h) ]
      )
    ]
  )
}
