import { components } from '@/utils'
import { core } from './mixins'

import render from './render'

export default {
  name: components.checkbox,

  mixins: [ core ],

  props: {
    label: String,
    color: String
  },

  render
}
