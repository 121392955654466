import { SENDING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { colors, icons, inputModes } from '@/utils'

import info from '@/components/misc/info'
import ExpandArrow from '@/components/misc/ExpandArrow'

function renderInfo(h, icon, value, trigger = true) {
  if (trigger) {
    return h(
      info,
      {
        props: {
          icon,
          value,
          color: colors.grey
        }
      }
    )
  }
}

function renderStatusSelect(h) {
  if (this.hasOnStatus) {
    return h(
      'g-select',
      {
        props: {
          value: this.proxy.onStatus,
          label: this.getTranslate('sendings.labels.status'),
          hint: this.getTranslate('sendings.labels.options.status'),
          disabled: this.disabled,
          readonly: this.readonly,
          items: SENDING_STATUS.filter(item => item !== SENDING_STATUS.paused).map(item => ({
            title: this.getTranslate(`sendings.statuses.${item}`),
            value: item
          })),
          mode: inputModes.outline,
          rounded: true,
          multiple: true
        },
        on: {
          input: event => {
            this.proxy.onStatus = event
          }
        }
      }
    )
  }
}

function renderTimeout(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.onTimeout.timeout,
        label: this.getTranslate('sendings.labels.time'),
        hint: this.getTranslate('sendings.labels.options.timeout'),
        suffix: this.getTranslate('misc.measure.time.sec').toLowerCase(),
        disabled: this.disabled,
        readonly: this.readonly,
        mode: inputModes.outline,
        rounded: true,
        type: 'number',
        min: 0,
        step: 'any'
      },
      on: {
        input: event => {
          this.proxy.onTimeout.timeout = event
        }
      }
    }
  )
}
function renderExcept(h) {
  return h(
    'g-select',
    {
      props: {
        value: this.proxy.onTimeout.except,
        label: this.getTranslate('sendings.labels.status'),
        hint: this.getTranslate('sendings.labels.options.except'),
        items: SENDING_STATUS.filter(item => item !== SENDING_STATUS.paused).map(item => ({
          title: this.getTranslate(`sendings.statuses.${item}`),
          value: item
        })),
        disabled: this.disabled,
        readonly: this.readonly,
        mode: inputModes.outline,
        rounded: true,
        multiple: true
      },
      on: {
        input: event => {
          this.proxy.onTimeout.except = event
        }
      }
    }
  )
}
function renderOnTimeout(h) {
  if (this.hasOnTimeout) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderInfo.call(
          this,
          h,
          'arrow_drop_down',
          this.getTranslate('misc.or'),
          this.hasOnStatus
        ),
        h(
          'div',
          {
            class: 'grid grid-gap--8',
            style: { 'grid-template-columns': this.viewport.breakpoint.mdUp ? '25% 1fr' : '1fr' }
          },
          [
            renderTimeout.call(this, h),
            renderExcept.call(this, h)
          ]
        )
      ]
    )
  }
}

function renderText(h) {
  return h(
    'g-text-field',
    {
      props: {
        value: this.proxy.onIncomingPayload.text,
        label: this.getTranslate('sendings.labels.text'),
        hint: this.getTranslate('sendings.labels.options.onIncomingPayload.text'),
        mode: inputModes.outline,
        rounded: true,
        disabled: this.disabled,
        readonly: this.readonly
      },
      on: {
        input: event => {
          this.proxy.onIncomingPayload.text = event
        }
      }
    }
  )
}
function renderOnIncomingPayload(h) {
  if (this.proxy.onIncomingPayload) {
    return h(
      'div',
      {
        class: 'grid grid-gap--8'
      },
      [
        renderInfo.call(
          this,
          h,
          icons.arrow_drop_down,
          this.getTranslate('misc.or'),
          this.hasOnStatus && this.hasOnTimeout
        ),
        renderText.call(this, h)
      ]
    )
  }
}

function renderOptions(h) {
  return h(
    'div',
    {
      class: 'grid grid-gap--8 pa-2'
    },
    [
      renderInfo.call(this, h, icons.done, this.getTranslate('sendings.subheaders.resend')),
      renderStatusSelect.call(this, h),
      renderOnTimeout.call(this, h),
      renderOnIncomingPayload.call(this, h)
    ]
  )
}
function renderPanel(h) {
  return h(
    'g-expansion-panel',
    {
      scopedSlots: {
        header: ({ expanded }) => {
          return h(
            'div',
            {
              class: 'default-expansion-panel-header fjcsb pr-0'
            },
            [
              this.getTranslate('sendings.subheaders.options'),
              h(ExpandArrow, { props: { expanded } })
            ]
          )
        }
      }
    },
    [ renderOptions.call(this, h) ]
  )
}

export default function(h) {
  return h(
    'g-expansion-panels',
    {
      props: {
        outline: true,
        rounded: true
      }
    },
    [ renderPanel.call(this, h) ]
  )
}
