import numeral from 'numeral'

import { getLocaleDateString, getLocaleTimeString } from '@/utils'

import SpacerCell from '@/components/misc/SpacerCell'
import FileOwner from '@/components/file/owner'

function renderFileOwner(h) {
  if (this.includeOwner) {
    return h(
      FileOwner,
      {
        props: {
          value: this.value,
          readonly: this.readonly
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: 'fc'
    },
    [
      h(
        SpacerCell,
        {
          class: 'caption',
          props: { value: this.value.title || this.value.filename }
        }
      ),

      h(
        'div',
        {
          class: 'faic fz-10 lh-n text-nowrap text--grey'
        },
        [
          h(
            'g-icon',
            {
              class: 'mr-1',
              props: {
                value: 'dns',
                size: 9
              }
            }
          ),

          numeral(this.value.size).format('0b'),

          h(
            'g-icon',
            {
              class: 'mx-1',
              props: {
                value: 'event',
                size: 9
              }
            }
          ),

          getLocaleDateString(this.value.createdAt),

          h(
            'g-icon',
            {
              class: 'mx-1',
              props: {
                value: 'access_time',
                size: 9
              }
            }
          ),

          getLocaleTimeString(this.value.createdAt)
        ]
      ),

      renderFileOwner.call(this, h)
    ]
  )
}
