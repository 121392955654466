import services from './services'

export default {
  title: 'История событий',

  labels: {
    eventsHistory: 'История изменений',
    type: 'Тип',
    userId: 'Пользователь',
    entityId: 'Сущность',
    service: 'Сервис',
    oldValues: 'Было',
    newValues: 'Стало',
    author: 'Автор',
    changesType: 'Тип',
    created: 'Создано',
    compareId: 'ID сущности'
  },

  updatedValues: {

    ...services,

    data: 'Data',
    isActive: 'Активация/Деактивация',
    updatedAt: 'Обновлено в',
    title: 'Заголовок',
    serverId: 'Сервер',
    credentialsId: 'Учетные данные',
    comment: 'Комментарий',
    properties: 'Свойства',
    replacements: 'Подмена контента',
    createdAt: 'Создано',
    deletedAt: 'Удалено',

    type: 'Тип',
    provider: 'Провайдер',
    name: 'Имя',

    rules: 'Правила',

    description: 'Описание',

    source: 'Источник',
    ownerId: 'Владелец',

    resellerId: 'Реселлер',
    password: 'Пароль',
    referralCode: 'Реферальный код',
    sudo: 'Sudo',
    username: 'Имя пользователя',

    amount: 'Сумма',
    status: 'Статус',
    TariffId: 'Тариф',

    listId: 'ListId',
    date: 'Дата',
    email: 'Email',
    firstName: 'Имя',
    gender: 'Пол',
    id: 'Id',
    lastName: 'Фамилия',
    middleName: 'Отчество',
    phone: 'Телефон',

    fallbacks: 'Переотправления',
    payload: 'Информация',
    recipient: 'Получатель',

    meta: 'Meta',
    moderation: 'Модерация',

    conditions: 'Условия',
    trafficType: 'Тип траффика'
  },

  types: {
    created: 'Создано',
    updated: 'Обновлено',
    removed: 'Удалено'
  },

  services: {
    contacts: 'Контакты',
    billingsUsers: 'Финансовая операция'
  },

  tooltips: {
    historyButton: 'Просмотреть историю изменений'
  }
}
