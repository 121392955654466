import { components, eventHandlers, isHEX } from '@/utils'

import render from './render'

export default {
  name: components.chip,

  mixins: [ eventHandlers ],

  props: {
    label: [ String, Number, Boolean ],

    length: {
      type: Number,
      default: 25
    },

    icon: String,
    color: String,

    link: Boolean,
    tiny: Boolean,
    small: Boolean,
    large: Boolean,
    circle: Boolean,
    outline: Boolean,
    loading: Boolean,
    disabled: Boolean,
    cut: Boolean,

    cancelable: Boolean,
    cancelIcon: {
      type: String,
      default: 'clear'
    },
    cancelCallback: {
      type: Function,
      default: () => undefined
    }
  },

  computed: {
    $class() {
      const result = {
        [`${components.chip}`]: true,

        [`${components.chip}--tiny`]: this.tiny,
        [`${components.chip}--small`]: this.small,
        [`${components.chip}--large`]: this.large,

        [`${components.chip}--circle`]: this.circle,
        [`${components.chip}--outline`]: this.outline,

        [`${components.chip}--icon`]: this.icon,
        [`${components.chip}--prefix`]: this.prefix,
        [`${components.chip}--suffix`]: this.suffix,

        [`${components.chip}--cancelable`]: this.cancelable,

        [`${components.chip}--link`]: this.link,

        [`${components.chip}--loading`]: this.loading,
        [`${components.chip}--disabled`]: this.disabled
      }

      if (!isHEX(this.color)) {
        result[`${components.chip}--${this.color}`] = true
      }

      return result
    },

    $size() {
      switch (true) {
        case this.tiny: return 14
        case this.small: return 18
        case this.large: return 26
        default: return 22
      }
    }
  },

  render
}
