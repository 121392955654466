export default {
  title: 'Вход',

  labels: {
    username: 'Логин',
    password: 'Пароль'
  },

  buttons: {
    logout: 'Выход',
    signin: 'Войти',
    signup: 'Регистрация'
  }
}
