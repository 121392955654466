export default {
  title: 'Contact lists',

  contactList: 'Contact list',

  labels: {
    title: 'Address Book Name',
    type: 'Type',
    exportTo: 'Export to'
  },

  titles: {
    export: 'Export',
    create: 'Create new contact list'
  },

  types: {
    regular: 'Regular',
    blacklist: 'Blacklist'
  },

  exportTypes: {
    new: 'New contact list',
    existing: 'Existing contact list',
    file: 'File'
  },

  dialogs: {
    titles: {
      create: 'Create contact list',
      update: 'Change contact list',
      remove: 'Remove contact list'
    },
    createAndCopy: 'Create and copy contact list'
  },

  emptyState: {
    title: 'Select contact list',
    description: 'Choose contact list on left side or {0}'
  },

  buttons: {
    create: 'Create contact list',
    createAndCopy: 'Create and copy',
    remove: 'Remove'
  },

  tooltips: {
    blacklist: 'Blacklist',
    create: 'Create new contact list',
    remove: 'Remove contact list',
    import: 'Import contacts to the current contact list from a file'
  },

  stepper: {
    headers: {
      1: 'Select type of export',
      2: {
        new: 'Create new contact list and export',
        existing: 'Choose contact list and export',
        file: 'Export to file'
      }
    }
  },

  contents: {
    confirm: {
      remove: 'Are you sure you want to remove contact list?'
    }
  },

  snackbars: {
    created: 'Contact list successfully created.',
    updated: 'Contact list successfully updated.',
    removed: 'Contact list successfully removed.'
  }
}
