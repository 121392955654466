import { permissionPresets } from '@/utils'

import SingleLink from '@/components/misc/SingleLink'

export default function(h) {
  if (this.checkPermissions('advanced.storage.get', permissionPresets.resellerUp) && this.value.Owner) {
    return h(
      'div',
      {
        class: 'faic fz-10 lh-n text-nowrap text--grey'
      },
      [
        h(
          'g-icon',
          {
            class: 'mr-1',
            props: {
              value: 'person',
              size: 9
            }
          }
        ),

        h(
          SingleLink,
          {
            props: {
              value: this.value.Owner,
              type: this.readonly ? 'text' : undefined,
              length: 15
            },
            on: {
              click: event => {
                event.stopPropagation()
              }
            }
          }
        )
      ]
    )
  }
}
