import Vue from 'vue'

import { PROVIDER_STATES } from '@sigma-legacy-libs/essentials/lib/constants'

import { globalErrorHandler, globalErrorProcessor, sizes } from '@/utils'

import { serviceName } from '@/components/services/providers/utils'

import render from './render'

export default {
  name: 'State',

  props: {
    id: {
      type: String,
      required: true
    },

    size: {
      type: String,
      default: sizes.small,
      validator: value => {
        return Object.values(sizes).includes(value)
      }
    }
  },

  data() {
    return {
      state: PROVIDER_STATES.inactive,
      loading: false
    }
  },

  watch: {
    id() {
      this.getState()
    }
  },

  mounted() {
    Vue.$socket.on(`${serviceName}.state.changed`, data => {
      if (this.id === data.providerId) {
        this.state = data.state
      }
    })

    this.getState()
  },

  methods: {
    async getState(id) {
      try {
        this.loading = true
        const { data } = await Vue.$GRequest.get(serviceName, this.id || id, { query: { state: true } })
        if (data && data.result) {
          this.state = data.result
        }
      } catch (error) {
        globalErrorHandler.call(this, globalErrorProcessor.call(this, error))
      } finally {
        this.loading = false
      }
    }
  },

  render
}
