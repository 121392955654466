import { BILLING_STATUS } from '@sigma-legacy-libs/essentials/lib/constants'

import { serviceName } from '@/components/services/billings/utils'

export default function(h) {
  if (this.checkPermissions(`advanced.${serviceName}.update`)) {
    return h(
      'g-menu',
      {
        props: {
          rounded: true,
          disabled: this.$disabled
        },
        key: this.id
      },
      [
        h(
          'g-button',
          {
            props: {
              icon: 'more_vert',
              flat: true,
              loading: this.loading,
              disabled: this.$disabled
            },
            slot: 'activator'
          }
        ),

        h(
          'g-list',
          {
            props: {
              dense: true,
              rounded: true
            }
          },
          BILLING_STATUS.map(status => {
            return h(
              'g-list-item',
              {
                props: {
                  label: this.getTranslate(`${serviceName}.statuses.${status}`),
                  dense: true,
                  disabled: this.status === BILLING_STATUS[status]
                },
                on: {
                  click: () => {
                    this.update(status)
                  }
                }
              }
            )
          })
        )
      ]
    )
  }
}
