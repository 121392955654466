import { components } from '@/utils'

export default function(h) {
  return h(
    'div',
    {
      attrs: { id: 'app' },
      class: components.app
    },
    [ this.$slots.default ]
  )
}
