import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default {
  name: 'Filters',

  mixins: [ proxy({ type: 'object' }) ],

  computed: {
    gridTemplateColumns() {
      if (this.viewport.breakpoint.mdUp) {
        return 'repeat(3, minmax(200px, 250px))'
      }
    }
  },

  render
}
