import proxy from '@sigma-legacy-libs/g-proxy'

import { components, inputModes, services } from '@/utils'

import render from './render'

export default {
  name: 'SendingTypes',

  mixins: [ proxy() ],

  props: {
    permission: String,
    label: String,
    hint: String,
    error: String,
    titlePrefix: String,
    valuePrefix: String,

    items: {
      type: Array,
      default: () => []
    },
    itemsPrepend: {
      type: Array,
      default: () => []
    },
    itemsAppend: {
      type: Array,
      default: () => []
    },
    itemsDisabled: {
      type: Array,
      default: () => []
    },

    format: {
      type: Function,
      default: value => value
    },

    loading: Boolean,
    disabled: Boolean,
    required: Boolean,
    readonly: Boolean,
    multiple: Boolean,

    dense: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },

    mode: {
      type: String,
      default: inputModes.outline,
      validator: value => {
        return Object.values(inputModes).indexOf(value)
      }
    },
    view: {
      type: String,
      default: components.select,
      validator: value => {
        return ~[ components.select, 'buttons' ].indexOf(value)
      }
    }
  },

  computed: {
    $items() {
      const result = []

      if (this.itemsPrepend.length) {
        result.push(...this.itemsPrepend)
      }
      if (this.items.length) {
        result.push(...this.items)
      } else {
        result.push(...this.getSendingTypesByPermission(this.permission))
      }
      if (this.itemsAppend.length) {
        result.push(...this.itemsAppend)
      }

      return result.map(type => {
        return {
          title: this.getTranslate(`${services.sendings}.types.${this.format(type)}`),
          value: type,
          disabled: this.itemsDisabled.includes(type)
        }
      })
    },

    $clearable() {
      if (this.required) {
        return false
      }

      return this.clearable
    }
  },

  methods: {
    proxyHasValue(value) {
      if (Array.isArray(this.proxy) && this.proxy.length) {
        return this.proxy.includes(value)
      } else {
        return this.proxy === value
      }
    },

    eventHandler(value) {
      if (!this.readonly && !this.disabled) {
        if (this.multiple) {
          if (Array.isArray(this.proxy)) {
            const index = this.proxy.findIndex(item => item === value)
            if (index > -1) {
              this.proxy.splice(index, 1)
            } else {
              this.proxy.push(value)
            }
          }
        } else {
          this.proxy = value
        }
      }
    }
  },

  render
}
