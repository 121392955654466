import { borderStyles, colors, getLocaleDateString, getLocaleTimeString, icons, services } from '@/utils'

import sticker from '@/components/misc/sticker'
import actions from '@/components/services/sendingsModeration/actions'

function renderRecipientsCount(h) {
  return h(
    sticker,
    {
      props: {
        value: [ this.value.total, this.value.segments ].join(' / '),
        label: this.getTranslate(`${services.sendingsModeration}.labels.recipientsCount`),
        borderStyle: borderStyles.dashed
      }
    }
  )
}

function renderSender(h) {
  if (this.viewport.breakpoint.smUp) {
    return h(
      sticker,
      {
        props: {
          value: this.value.sender,
          label: this.getTranslate(`${services.sendingsModeration}.labels.sender`),
          borderStyle: borderStyles.dashed,
          copy: true
        }
      }
    )
  }
}

function renderText(h) {
  return h(
    sticker,
    {
      props: {
        value: this.value.text,
        label: this.getTranslate(`${services.sendingsModeration}.labels.text`),
        borderStyle: borderStyles.dashed,
        copy: true
      }
    }
  )
}

function renderOperator(h) {
  if (this.viewport.breakpoint.mdUp && this.hasOperator) {
    return h(
      sticker,
      {
        props: {
          value: this.value.operator,
          label: this.getTranslate(`${services.sendingsModeration}.labels.operator`),
          borderStyle: borderStyles.dashed
        }
      }
    )
  }
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        sending: this.value,
        filter: this.filter
      }
    }
  )
}

function renderDates(h) {
  if (this.viewport.breakpoint.xl) {
    return Object.keys(this.value.createdAt).map(date => {
      return h(
        sticker,
        {
          props: {
            value: getLocaleTimeString(this.value.createdAt[date]),
            label: getLocaleDateString(this.value.createdAt[date]),
            borderStyle: borderStyles.dashed,
            icon: icons.date_range,
            color: colors.grey,
            minWidth: 140,
            width: 140,
            iconTooltip: this.getTranslate(`${services.sendingsModeration}.tooltips.createdAt.${date}`),
            copy: true,
            button: {
              color: colors.primary,
              icon: icons.content_copy,
              tooltip: this.getTranslate('misc.buttons.copy'),
              callback: () => {
                navigator.clipboard.writeText([ getLocaleDateString(this.value.createdAt[date]), getLocaleTimeString(this.value.createdAt[date]) ].join(' '))
              }
            }
          }
        }
      )
    })
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${services.sendingsModeration}-sending hover-parent hover-parent--opacity`]: true
      },
      style: { gridTemplateColumns: this.gridTemplateColumns }
    },
    [
      renderRecipientsCount.call(this, h),
      renderSender.call(this, h),
      renderText.call(this, h),
      renderOperator.call(this, h),
      renderActions.call(this, h),
      renderDates.call(this, h)
    ]
  )
}
