import render from './render'

export default {
  name: 'eventsHistoryDialog',

  props: {
    entityId: {
      type: String,
      default: ''
    },

    show: Boolean
  },

  render
}
