export default {
  title: 'Document templates',

  titles: {
    generate: 'Generate document',
    contract: 'Contract template',
    invoice: 'Invoice template',
    empty: {
      contract: 'No template of contract',
      invoice: 'No template of invoice'
    }
  },

  labels: {
    requisite: 'Your requisites',
    company: 'Contractor',
    type: 'Document type',
    sum: 'Invoice amount'
  },

  singular: {
    requisite: 'Requisite',
    company: 'Contractor',
    type: 'Type'
  },

  contents: {
    confirm: {
      remove: 'Confirm remove document.'
    }
  },

  buttons: {
    download: 'Download document',
    add: {
      template: 'Attach'
    },
    create: {
      requisite: 'Create requisites'
    }
  },

  types: {
    contract: 'Contract',
    invoice: 'Invoice'
  },

  hints: {
    searching: {
      requisites: 'Looking for your requisites...',
      companies: 'Looking for your companies...'
    }
  },

  warnings: {
    notFound: {
      requisites:
        'Unfortunately, we could not find your requisites.<br>Please <a href="/#/account#requisites" class="link link--active link--underline">create</a> a new one and try again.',
      requisitesForAdmin:
        'Unfortunately, we could not find requisites for this user.<br>You can <a href="/#/admin/users/{OwnerId}#requisites" class="link link--active link--underline">create</a> them for him yourself.',
      companies:
        'Unfortunately, we could not find any contractors for selected requisites.<br>Please contact support to resolve this issue.',
      types:
        'Unfortunately, we cannot generate any document for current Contractor.<br>Please contact support to resolve this issue.'
    }
  },

  snackbars: {
    created: 'Document successfully created.',
    updated: 'Document successfully updated.',
    removed: 'Document successfully removed.'
  }
}
