export const serviceName = 'requisites'

export const innRegExp = /^\d{10}$|^\d{12}$/
export const ogrnRegExp = /^\d{13}$|^\d{15}$/
export const kppRegExp = /^\d{9}$/
export const okpoRegExp = /^\d{8}$|^\d{10}$/
export const bikRegExp = /^\d{9}$/
export const rsRegExp = /^\d{20}$/
export const ksRegExp = /^\d{20}$/
export const indexRegExp = /^\d{6}$/

export const opfList = [
  'Индивидуальный предприниматель',
  'Общество с ограниченной ответственностью',
  'Автономная некоммерческая организация',
  'Автономная некоммерческая организация высшего образования',
  'Автономная некоммерческая организация среднего образования',
  'Акционерное общество',
  'Благотворительный фонд',
  'Государственная корпорация',
  'Государственное автономное учреждение здравоохранения',
  'Государственное автономное учреждение здравоохранения Ярославской области',
  'Государственное бюджетное учреждение',
  'Государственное унитарное предприятие Архангельской области',
  'ЖСК',
  'Закрытое акционерное общество',
  'Земельное товарищество',
  'Казачье (казачья) общество',
  'Крестьянское (фермерское) хозяйство',
  'Культурно-исторический фонд',
  'Межрегиональная общественная организация',
  'Межрегиональная общественная организация потребителей',
  'Национальный потребительский кооператив',
  'Некоммерческая организация',
  'Некоммерческое общественное объединение',
  'Некоммерческое объединение',
  'Некоммерческое партнерство',
  'Некоммерческое партнерство потребителей',
  'Некоммерческое товарищество',
  'Некоммерческое учреждение',
  'Общественная организация',
  'Общественная организация инвалидов',
  'Общественная организация молодежи',
  'Общественная организация потребителей',
  'Общественная организация работодателей',
  'Общественный объединение',
  'Общественный фонд',
  'Общество ветеранов',
  'Общество гаражного (гаражных) строительства',
  'Общество защиты прав потребителей',
  'Общество инвалидов',
  'Общество личного подсобного хозяйства',
  'Общество малых акционеров',
  'Общество с дополнительной ответственностью',
  'Общество с ограниченной дополнительной ответственностью',
  'Общество садоводов',
  'Общество социального обслуживания населения',
  'Объединение',
  'Объединение граждан',
  'Объединение дачных некоммерческих объединений',
  'Объединение некоммерческих организаций',
  'Объединение общественных организаций',
  'Объединение объединений',
  'Объединение организаций работников',
  'Объединение садоводческих некоммерческих товариществ',
  'Объединение субъектов Российской Федерации',
  'Опекунство',
  'ОРК',
  'Открытое акционерное общество',
  'Политическая партия',
  'Потребительская кооперация',
  'Потребительский кооператив',
  'Потребительское общество',
  'Производственное кооперативное предприятие',
  'Производственный кооператив',
  'Профсоюзная организация',
  'Публичное акционерное общество',
  'Религиозная организация',
  'Садоводческое некоммерческое товарищество',
  'Садоводческое огородническое или дачное некоммерческое товарищество',
  'Садоводческое огородническое или дачное некоммерческое товарищество совладельцев недвижимости',
  'Сельскохозяйственное обслуживающее кооперативное предприятие',
  'Сельскохозяйственное производственное кооперативное предприятие',
  'Сельскохозяйственное товарищество',
  'Сельскохозяйственный кооператив',
  'Сельскохозяйственный потребительский перерабатывающий сбытовой кооператив',
  'Семейное хозяйство',
  'Служба занятости населения',
  'СНТ',
  'Совместное предприятие',
  'Спортивная организация',
  'Территориальное общественное самоуправление',
  'Товарищество',
  'Товарищество владельцев жилья',
  'Товарищество дачных садоводческих огороднических некоммерческих объединений',
  'Товарищество дольщиков',
  'Товарищество жильцов',
  'Товарищество жильцов недивидентифицированных многоквартирных домов',
  'Товарищество на вере',
  'Товарищество на взаимном страховании',
  'Товарищество наследников',
  'Товарищество садоводов и огородников',
  'Товарищество собственников жилья',
  'Товарищество совладельцев недвижимости',
  'Унитарное предприятие',
  'Учреждение',
  'Федерация некоммерческих организаций',
  'Фонд',
  'Хозяйственное партнерство',
  'Хозяйственное товарищество',
  'Частное учреждение'
]

const shortOpf = {}
for (const opf of opfList) {
  const split = opf.split(' ')
  shortOpf[opf] = split.reduce((result, word) => {
    if (word.length > 1) {
      result += word.substring(0, 1).toUpperCase()
    }

    return result
  }, '')
}
export const opfListShort = shortOpf
