import providersMetrics from '@/components/services/providers/metrics'
import grafanaRange from '@/components/services/grafana/range'

export default function(h) {
  return h(
    'div',
    {
      class: 'faifs ff grid grid-cols--1 ptpx-48'
    },
    [
      h(
        'g-card',
        {
          props: {
            rounded: true,
            width: '100%'
          }
        },
        [
          h(
            grafanaRange,
            {
              props: {
                data: { id: this.$route.params.id },
                presets: [ 'queue', 'speed', 'count', 'delivery_rate' ].map(name => {
                  return {
                    name,
                    label: this.getTranslate(`providers.chart.labels.${name === 'delivery_rate' ? 'deliveryRate' : name}`),
                    preset: `providers_${name}`
                  }
                }),
                hasFilter: true,
                noData: {
                  icon: {
                    show: true,
                    library: undefined,
                    value: 'bar_chart',
                    size: 64
                  }
                }
              }
            }
          )
        ]
      ),

      h(
        'g-card',
        {
          class: 'pa-3',
          props: {
            rounded: true,
            width: '100%'
          }
        },
        [
          h(
            providersMetrics,
            {
              props: {
                id: this.$route.params.id,
                mode: 'full'
              }
            }
          )
        ]
      )
    ]
  )
}
