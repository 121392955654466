import { services } from '@/utils'

import messageTypeIcon from '@/components/message/type/icon'

export default function(h) {
  return h(
    'div',
    {
      class: {
        [`${services.sendings}-tasks__type`]: true,
        [`${services.sendings}-tasks__type--${this.value.data.sending.type}`]: true
      }
    },
    [
      h(
        messageTypeIcon,
        {
          props: {
            value: this.value.data.sending.type
          }
        }
      )
    ]
  )
}
