export const emotionalTones = {
  joyful: 'joyful',
  neutral: 'neutral',
  businesslike: 'businesslike',
  energetic: 'energetic',
  enthusiastic: 'enthusiastic',
  excited: 'excited',
  optimistic: 'optimistic',
  informative: 'informative',
  inspiring: 'inspiring'
}

export const payloadKeys = {
  messageTheme: 'messageTheme',
  occasion: 'occasion',
  smsSegments: 'smsSegments',
  otherChars: 'otherChars',
  additionalNotes1: 'additionalNotes1',
  rephraseText: 'rephraseText',
  addEmojis: 'addEmojis',
  endQuestion: 'endQuestion',
  emotionalTone: 'emotionalTone',
  language: 'language'
}

export const defaultPayload = {
  messageTheme: '',
  occasion: '',
  additionalNotes1: '',
  rephraseText: '',
  addEmojis: true,
  endQuestion: '',
  emotionalTone: '',
  language: undefined
}
