import { RECURRENT_PAYMENTS_STATUSES, RECURRENT_PAYMENTS_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

export const recurrentPaymentIcons = {
  type: {
    [RECURRENT_PAYMENTS_TYPES.threshold]: 'equalizer',
    [RECURRENT_PAYMENTS_TYPES.frequency]: 'event'
  },
  status: {
    [RECURRENT_PAYMENTS_STATUSES.init]: 'schedule',
    [RECURRENT_PAYMENTS_STATUSES.ready]: 'check_circle_outline',
    [RECURRENT_PAYMENTS_STATUSES.pause]: 'pause_circle_outline',
    [RECURRENT_PAYMENTS_STATUSES.error]: 'error_outline',
    [RECURRENT_PAYMENTS_STATUSES.block]: 'block'
  }
}

export const recurrentPaymentsStatusColors = {
  [RECURRENT_PAYMENTS_STATUSES.init]: 'grey',
  [RECURRENT_PAYMENTS_STATUSES.ready]: 'success',
  [RECURRENT_PAYMENTS_STATUSES.pause]: 'primary',
  [RECURRENT_PAYMENTS_STATUSES.error]: 'error',
  [RECURRENT_PAYMENTS_STATUSES.block]: 'error'
}
