export default {
  presets: 'Presets',
  clear: 'Clear filter',

  date: {
    title: 'Date',
    label: 'Filter by date and time',
    from: 'Date from',
    by: 'Date by',
    empty: 'Choose date',

    titles: {
      createdAt: 'Choose a creation date',
      startsAt: 'Choose a start date',
      endsAt: 'Choose an end date'
    },

    labels: {
      createdAt: 'Creation date range',
      startsAt: 'Start date range'
    },

    presets: {
      minutes: {
        5: '5 minutes',
        15: '15 minutes',
        30: '30 minutes'
      },
      hours: {
        1: '1 hour',
        3: '3 hours',
        6: '6 hours'
      },
      yesterday: 'Yesterday',
      today: 'Today',
      last: {
        week: 'Last week',
        month: 'Last month'
      },
      current: {
        week: 'Current week',
        month: 'Current month'
      }
    }
  },

  time: {
    gt: 'Start of time range',
    lt: 'End of time range',
    from: 'Time from',
    by: 'Time by',
    labels: 'Labels',
    hints: 'Hints',

    current: {
      time: 'Current time',
      date: 'Current date'
    },

    tooltips: {
      current: {
        time: 'Set current time'
      }
    }
  }
}
