import proxy from '@sigma-legacy-libs/g-proxy'
import generatePassword from 'generate-password'

import { isValidPassword } from '@/utils'

import render from './render'

export default {
  name: 'PasswordField',

  mixins: [ proxy() ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    required: {
      type: Boolean,
      default: true
    },

    isNew: Boolean
  },

  data() {
    return {
      editable: false,
      passwordVisibility: true
    }
  },

  computed: {
    passwordValidation() {
      if (this.proxy && !isValidPassword(this.proxy)) {
        return this.getTranslate('errors.types.invalid', { field: this.getTranslate('users.labels.password').toLowerCase() })
      }
    }
  },

  methods: {
    passwordGenerator() {
      this.passwordVisibility = false
      this.proxy = generatePassword.generate({
        length: 8,
        numbers: true,
        symbols: false,
        uppercase: true,
        strict: true
      })
    }
  },

  render
}
