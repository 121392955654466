import proxy from '@sigma-legacy-libs/g-proxy'

import { projectName } from '@/utils'

import render from './render'

export default {
  name: 'ViewChanger',

  mixins: [ proxy() ],

  props: {
    serviceName: {
      type: String,
      required: true
    },

    views: {
      type: Array,
      default: () => [ 'table', 'cards', 'panels' ]
    }
  },

  computed: {
    viewPrefix() {
      return `${projectName}:view:${this.serviceName}`
    },
    icon() {
      const iconMapping = {
        table: 'grid_view',
        cards: 'view_module',
        panels: 'view_list'
      }

      return iconMapping[this.proxy]
    }
  },

  mounted() {
    const view = window.localStorage.getItem(this.viewPrefix) || this.proxy
    if (!~this.views.indexOf(view)) {
      this.set(this.views[0])
    } else {
      this.set(view)
    }
  },

  methods: {
    set(view) {
      if (view) {
        this.proxy = view
        window.localStorage.setItem(this.viewPrefix, view)
      }
    }
  },

  render
}
