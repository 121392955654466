import proxy from '@sigma-legacy-libs/g-proxy'

import { REGEXP_SENDERNAME_SMS, REGEXP_SENDERNAME_VOICE, SENDING_TYPES } from '@sigma-legacy-libs/essentials/lib/constants'

import { isStringNotEmpty, services } from '@/utils'

import render from './render'

export default {
  name: 'Form',

  mixins: [ proxy({ type: 'object' }) ],

  props: {
    errors: {
      type: Object,
      default: () => ({})
    },

    isNew: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    senderNameIsValid() {
      if (this.proxy.name) {
        switch (this.proxy.type) {
          case SENDING_TYPES.sms: return REGEXP_SENDERNAME_SMS.test(this.proxy.name)
          case SENDING_TYPES.voice: return REGEXP_SENDERNAME_VOICE.test(this.proxy.name)
        }

        return true
      }

      return false
    },

    senderNameMask() {
      if (this.isNew) {
        switch (this.proxy.type) {
          case SENDING_TYPES.sms: return /^[a-zA-Z0-9!?\-_.()]{0,11}$/
          case SENDING_TYPES.voice: return /^\d{0,15}$/
        }
      }
    },

    validation() {
      let checkComment = false
      if (this.checkPermissions(`advanced.${services.sendernames}.create`, 'me')) {
        checkComment = isStringNotEmpty(this.proxy.meta.comment)
      } else {
        checkComment = true
      }

      return this.senderNameIsValid && checkComment
    }
  },

  watch: {
    'proxy.type'() {
      this.proxy.name = undefined
    },
    validation() {
      this.$emit('validation', this.validation)
    }
  },

  mounted() {
    this.$emit('validation', this.validation)
  },

  render
}
