export default {
  title: 'Имена отправителей',
  sendername: 'Имя отправителя',

  titles: {
    new: 'Новое имя отправителя',
    create: 'Создать новое имя отправителя',
    users: 'Пользователи',
    comments: 'Комментарии'
  },

  headers: {
    moderation: 'Модерация',
    sendername: 'Имя отправителя',
    comment: 'Комментарий'
  },

  labels: {
    name: 'Имя отправителя',
    type: 'Тип отправления',
    moderation: 'Модерация',
    owner: 'Владелец',
    isActive: 'Активировать или деактивировать имя отправителя',
    meta: {
      comment: 'Организация и ИНН',
      adminComment: 'Комментарий администратора',
      adminCommentPrivate: 'Приватный комментарий администратора',
      resellerComment: 'Комментарий реселлера',
      resellerCommentPrivate: 'Приватный комментарий реселлера'
    },
    confirmationCode: 'Введите код',
    searchAndAdd: 'Поиск и добавление'
  },

  statuses: {
    disabled: 'Не запрошено',
    requested: 'Запрошено',
    moderation: 'На модерации',
    approved: 'Одобрено',
    rejected: 'Отклонено',
    pending: 'Ожидание'
  },

  contents: {
    confirm: {
      remove: 'Удалить имя отправителя?'
    }
  },

  hints: {
    meta: {
      comment: 'Минимум 10, максимум 150 символов'
    },
    emptyState: 'Не найдено ни одного имени отправителей',
    confirmationCode: {
      again: 'Запросить код еще раз',
      timer: 'Повторный запрос кода возможен через: {count} сек'
    },
    enterAndAdd: 'Введите имя отправителя и нажмите ENTER чтобы добавить его',
    notFound: 'Имена отправителей не найдены'
  },

  warnings: {
    meta: {
      comment: 'Для успешного прохождения модерации, в поле выше необходимо ввести название вашей организации и ИНН.'
    },

    name: {
      sms: 'Имя должно удовлетворять следующим условиям:<br>- длина от 4 до 11 символов,<br>- строчные или прописные буквы латинского алфавита [a-z, A-Z],<br>- цифры [от 0 до 9],<br>- специальные символы [!, ?, -, _, .],<br>- круглые скобки [( )],<br>- не должно содержать пробелов.',
      voice: 'Имя должно удовлетворять следующим условиям:<br>- длина от 6 до 15 символов,<br>- только цифры.'
    },

    no: {
      names: 'Не найдено ни одного одобренного имени отправителя.<br>Вы можете создать новое нажав на кнопку "+" справа.'
    }
  },

  examples: {
    meta: {
      comment: 'Пример заполнения: ООО "Компания" ИНН 1234567890'
    }
  },

  tooltips: {
    create: 'Создать новое имя отправителя',
    favorites: 'Сделать имя отправителя избранным',
    edit: 'Редактировать имя отправителя',
    filter: {
      isActive: 'Показать деактивированные имена отправителей'
    },
    copy: 'Скопировать имя в буфер обмена',
    approve: 'Одобрить',
    rejectForReason: 'Отклонить по причине...',
    reject: 'Отклонить'
  },

  errors: {
    sms: 'Только латинские буквы или цифры, не более 11 символов',
    voice: 'Только цифры, от 6 до 15 символов'
  },

  buttons: {
    users: 'Пользователи {count}',
    confirmationCode: 'Получить код подтверждения',
    approve: 'Одобрить',
    rejectForReason: 'Отклонить по причине',
    reject: 'Отклонить',
    copy: 'Скопировать имя',
    favorites: {
      add: 'Добавить в избранное',
      remove: 'Убрать из избранного'
    },
    edit: 'Редактировать'
  },

  variants: {
    contact: 'Свяжитесь с нами',
    comment: 'Не верный комментарий',
    length: 'Не верная длина',
    common: 'Общее имя',
    international: 'Международное имя'
  },

  pills: {
    all: 'Доступные',
    checked: 'Выбранные'
  },

  snackbars: {
    created: 'Имя отправителя успешно создано.',
    updated: 'Имя отправителя успешно обновлёно.',
    removed: 'Имя отправителя успешно удалёно.',
    confirmationCode: {
      rateLimit: 'Запрос кода возможен только раз в минуту',
      invalidCode: 'Код неверен',
      success: 'Проверка прошла успешно'
    }
  },

  tabs: {
    eventsHistory: 'История событий'
  }
}
