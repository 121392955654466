import { components } from '@/utils'

import render from './render'

export default {
  name: components.empty,

  props: {
    title: String,
    padless: Boolean,

    size: {
      type: Number,
      default: 64
    }
  },

  render
}
