export default {
  title: 'Контакты',

  copyTo: 'Копировать в',

  titles: {
    custom: {
      fields: 'Произвольные поля'
    }
  },

  labels: {
    list: 'Список контактов',
    phone: 'Телефон',
    email: 'E-mail',
    firstName: 'Имя',
    lastName: 'Фамилия',
    middleName: 'Отчество',
    date: 'Дата рождения',
    gender: 'Пол',
    contactList: 'Список контактов',
    actions: '',
    custom: 'Произвольное поле',
    custom01: 'Произвольное поле 01',
    custom02: 'Произвольное поле 02',
    custom03: 'Произвольное поле 03',
    custom04: 'Произвольное поле 04',
    custom05: 'Произвольное поле 05',
    custom06: 'Произвольное поле 06',
    custom07: 'Произвольное поле 07',
    custom08: 'Произвольное поле 08',
    custom09: 'Произвольное поле 09',
    custom10: 'Произвольное поле 10',
    custom11: 'Произвольное поле 11',
    custom12: 'Произвольное поле 12',
    custom13: 'Произвольное поле 13',
    custom14: 'Произвольное поле 14',
    custom15: 'Произвольное поле 15',
    custom16: 'Произвольное поле 16',
    custom17: 'Произвольное поле 17',
    custom18: 'Произвольное поле 18',
    custom19: 'Произвольное поле 19',
    custom20: 'Произвольное поле 20',
    clear: 'Очистить фильтр'

  },

  dialogs: {
    titles: {
      create: 'Создать контакт',
      update: 'Изменить контакт',
      remove: 'Удалить контакт',
      import: 'Импорт контактов'
    }
  },

  subheaders: {
    custom: 'Произвольные поля',
    date: 'Выберите дату рождения'
  },

  emptyState: {
    emptyAddressBook: 'Не найдено ни одной адресной книги',
    title: 'Нет контактов',
    description: '{0} csv/xls/xlsx файл или {1}'
  },

  contents: {
    confirm: {
      remove: 'Вы уверены что хотите удалить контакт?'
    },
    import: {
      loading: 'Окно закроется автоматически по окончании импорта.',
      success: 'Успешно импортировано: {count}.',
      error: 'К сожалению, нам не удалось импортировать ни одного номера из переданного файла.<br><ul><li>Проверьте, правильно ли названы столбцы в файле. Попробуйте переименовать столбец с номером телефона в "телефон".</li><li>Если у вас не получается загрузить CSV, попробуйте XLSX или XLS. В таком случае записи должны находиться на первом листе / книге.</li><li>Обратитесь в поддержку за помощью! Нам может понадобится ваш файл, чтобы разобраться в ситуации.</li></ul>'
    }
  },

  buttons: {
    add: 'Добавить',
    remove: 'Удалить'
  },

  tooltips: {
    add: 'Добавить один контакт в список контактов',
    remove: 'Удалить контакт из списка контактов'
  },

  errors: {
    phone: 'Не верный формат телефона',
    email: 'Не верный формат e-mail'
  },

  snackbars: {
    created: 'Контакт успешно создан.',
    updated: 'Контакт успешно обновлён.',
    removed: 'Контакт успешно удалён.'
  }
}
