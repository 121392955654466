import Conditions from '@sigma-legacy-libs/essentials/lib/utils/Conditions'

import { buttonModes, colors, inputModes } from '@/utils'

import mccmnc from '@/components/trees/mccmnc'
import groups from '@/components/trees/group'
import groupsReadonly from '@/components/trees/mccmnc/readonly'
import senders from '@/components/trees/sender'
import listReadonly from '@/components/trees/list/readonly'
import sendingType from '@/components/misc/rules/commons/type'
import button from '@/components/button'

function renderHeaderContent(h) {
  if (this.tag) {
    if (this.tagEdit && !this.readonly) {
      return h(
        'g-select',
        {
          props: {
            value: this.tag,
            label: this.getTranslate(`conditions.tags.${this.tag}`),
            items: Conditions.tagsOnly.map(tag => {
              return {
                title: this.getTranslate(`conditions.tags.${tag}`),
                value: tag
              }
            }),
            itemsDisabled: this.tagDisabled,
            mode: 'outline',
            dense: true,
            rounded: true,
            details: false,
            required: true
          },
          on: {
            input: event => {
              this.$emit('tag', event)
            }
          }
        }
      )
    } else {
      return h(
        'div',
        {
          class: 'headline'
        },
        this.getTranslate(`conditions.tags.${this.tag}`)
      )
    }
  }
}
function renderHeader(h) {
  return h(
    'div',
    {
      class: 'pa-3',
      slot: 'header'
    },
    [ renderHeaderContent.call(this, h) ]
  )
}

function renderMainComponent(h) {
  if (this.tag) {
    let component = 'g-select'
    let props

    const defaultProps = {
      value: this.proxy,
      label: this.getTranslate(`conditions.tags.${this.tag}`),
      mode: inputModes['line-label'],
      disabled: this.disabled,
      dense: true,
      details: false,
      rounded: true,
      autofocus: true,
      clearable: true,
      readonly: this.readonly
    }

    switch (this.tag) {
      case 'text': {
        component = 'g-textarea'
        props = Object.assign({}, defaultProps, {
          details: true,
          error: this.error,
          grow: true
        })
        break
      }

      case 'mccmnc': {
        component = this.readonly ? groupsReadonly : mccmnc
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'group': {
        component = this.readonly ? listReadonly : groups
        props = Object.assign({}, { value: this.proxy })
        break
      }

      case 'sender': {
        component = this.readonly ? listReadonly : senders
        props = Object.assign({}, {
          value: this.proxy,
          type: this.type
        })
        break
      }

      case 'contactList': {
        props = Object.assign({}, defaultProps, {
          service: 'contactLists',
          multiple: true,
          autocomplete: true
        })
        break
      }
      case 'keywords': {
        props = Object.assign({}, defaultProps, {
          service: 'keywordLists',
          multiple: true,
          autocomplete: true
        })
        break
      }

      case 'type': {
        component = sendingType({ type: 'array' })
        props = Object.assign({}, defaultProps, { value: this.proxy })
        break
      }

      default: {
        component = 'g-text-field'
        props = Object.assign({}, defaultProps)
        break
      }
    }

    return h(
      component,
      {
        props,
        on: {
          input: event => {
            this.proxy = event
          }
        }
      }
    )
  }
}
function renderAdditionalComponent(h) {
  if (!this.readonly) {
    switch (this.tag) {
      case 'text': {
        return h(
          'g-textarea',
          {
            props: {
              value: this.text,
              label: this.getTranslate('conditions.labels.textToCheck'),
              mode: inputModes['line-label'],
              state: this.text ? this.checkPattern() ? 'success' : 'error' : undefined,
              dense: true,
              rounded: true,
              clearable: true,
              rows: 4,
              grow: true
            },
            on: {
              input: event => {
                this.text = event
              }
            }
          }
        )
      }
      case 'group': {
        return h(
          'div',
          {
            class: 'grid grid-gap--8 faic',
            style: { gridTemplateColumns: '1fr auto' }
          },
          [
            h(
              'g-text-field',
              {
                props: {
                  value: this.pattern,
                  label: this.getTranslate('conditions.labels.group.pattern'),
                  mode: inputModes['line-label'],
                  dense: true,
                  rounded: true,
                  clearable: true,
                  details: false
                },
                on: {
                  input: event => {
                    this.pattern = event
                  }
                }
              }
            ),

            h(
              button,
              {
                props: {
                  label: this.getTranslate('misc.buttons.add'),
                  mode: buttonModes.flat,
                  color: colors.primary,
                  disabled: !this.pattern
                },
                on: {
                  click: () => {
                    this.proxy.push(this.pattern)
                    this.pattern = undefined
                  }
                }
              }
            )
          ]
        )
      }
      case 'contactList': {
        return h(
          'g-textarea',
          {
            props: {
              value: this.phone,
              label: this.getTranslate('conditions.labels.phone'),
              mode: inputModes['line-label'],
              state: this.phoneCheck,
              error: this.error,
              dense: true,
              rounded: true,
              loading: this.phoneCheckLoading,
              clearable: true
            },
            on: {
              input: event => {
                this.phone = event
              }
            }
          }
        )
      }
    }
  }
}
function renderContent(h) {
  return h(
    'div',
    {
      class: 'grid pa-2'
    },
    [
      renderMainComponent.call(this, h),
      renderAdditionalComponent.call(this, h)
    ]
  )
}

function renderFooter(h) {
  return h(
    'div',
    {
      class: 'faic grid-gap--8 pa-2',
      slot: 'footer'
    },
    [
      h(
        button,
        {
          props: {
            mode: buttonModes.flat,
            label: this.getTranslate('misc.buttons.close')
          },
          on: {
            click: () => {
              this.$emit('dialog', false)
            }
          }
        }
      ),

      h('div', { class: 'ff' }),

      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.clear'),
            mode: buttonModes.flat,
            color: colors.error
          },
          on: {
            click: () => {
              this.proxy = undefined
            }
          }
        }
      ),

      h(
        button,
        {
          props: {
            label: this.getTranslate('misc.buttons.save'),
            disabled: this.readonly,
            color: colors.primary
          },
          on: {
            click: () => {
              this.$emit('input', this.proxy)
              this.$emit('dialog', false)
            }
          }
        }
      )
    ]
  )
}

export default function(h) {
  return h(
    'g-dialog',
    {
      props: {
        value: this.show,
        rounded: this.viewport.breakpoint.mdUp,
        maxWidth: 500
      },
      on: {
        input: event => {
          this.$emit('dialog', event)
        }
      }
    },
    [
      renderHeader.call(this, h),
      renderContent.call(this, h),
      renderFooter.call(this, h)
    ]
  )
}
