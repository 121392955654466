import proxy from '@sigma-legacy-libs/g-proxy'

import render from './render'

export default function(options) {
  return {
    name: 'GlobalBlacklistManagerTemporaryAvailableList',

    mixins: [ proxy() ],

    render(h) {
      return render.call(this, h, options)
    }
  }
}
