import { serviceName } from '@/components/services/yclients/utils'

import salon from '@/components/services/yclients/item/salon'
import owner from '@/components/services/yclients/item/owner'
import error from '@/components/services/yclients/item/error'
import actions from '@/components/services/yclients/item/actions'
import date from '@/components/serviceTemplate/item/date'

function renderSalon(h) {
  return h(
    salon, {
      props: {
        value: this.value
      }
    }
  )
}

function renderOwner(h) {
  if (this.viewport.breakpoint.smDown) {
    return
  }

  return h(
    owner,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderError(h) {
  if (this.viewport.breakpoint.xs) {
    return
  }

  return h(
    error,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderInfo(h) {
  return h(
    'div',
    {
      class: `${serviceName}-panel__info`
    },
    [
      renderSalon.call(this, h),
      renderOwner.call(this, h),
      renderError.call(this, h)
    ]
  )
}

function renderActions(h) {
  return h(
    actions,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.smDown) {
    return
  }

  return h(
    date,
    {
      props: {
        value: this.value
      }
    }
  )
}

export default function(h) {
  return h(
    'div',
    {
      class: {
        'service-template-panel': true,
        [`${serviceName}-panel`]: true,
        'hover-parent hover-parent--opacity': this.viewport.breakpoint.mdUp
      }
    },
    [
      renderInfo.call(this, h),
      renderActions.call(this, h),
      renderCreatedAt.call(this, h)
    ]
  )
}
