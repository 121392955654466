import { mccObjectToArray } from '@/components/trees/mccmnc/utils'

import render from './render'

export default {
  name: 'MCCMNCValue',

  mixins: [ mccObjectToArray ],

  computed: {
    isMany() {
      return this.values.length > 1
    }
  },

  render
}
