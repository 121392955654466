import { serviceName } from '@/components/services/providers/utils'

import info from '@/components/services/providers/item/info'
import buttons from '@/components/services/providers/item/buttons'
import date from '@/components/serviceTemplate/item/date'

function renderInfo(h) {
  return h(
    info,
    {
      props: {
        value: this.value,
        view: 'panel'
      }
    }
  )
}

function renderButtons(h) {
  return h(
    buttons,
    {
      props: {
        value: this.value
      }
    }
  )
}

function renderCreatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value
        }
      }
    )
  }
}
function renderUpdatedAt(h) {
  if (this.viewport.breakpoint.lgUp) {
    return h(
      date,
      {
        props: {
          value: this.value,
          pathToDate: 'updatedAt'
        }
      }
    )
  }
}

export default function(h) {
  return h(
    'div',
    {
      class: `${serviceName}-panel service-template-panel hover-parent hover-parent--opacity`
    },
    [
      renderInfo.call(this, h),
      renderButtons.call(this, h),
      renderCreatedAt.call(this, h),
      renderUpdatedAt.call(this, h)
    ]
  )
}
