export default {
  title: 'Производительность',

  nodes: 'Узлы',
  providers: 'Провайдеры',
  cpu: 'Процессор',
  cores: 'Ядра',
  memory: 'Память',
  busy: 'Занятый',
  requests: 'Запросы',
  totalRequests: 'Всего запросов',
  rps: 'Запросы в секунду',
  actions: 'Действия',
  uptime: 'Время работы',
  client: 'Клиент'
}
