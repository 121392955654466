import { components } from '@/utils'

import render from './render'

export default {
  name: components.divider,

  props: {
    type: {
      type: String,
      default: 'horizontal',
      validator: value => {
        return ~[ 'horizontal', 'vertical' ].indexOf(value)
      }
    },

    border: {
      type: Object,
      default: () => {
        return {
          style: 'solid',
          size: 1
        }
      }
    }
  },

  render
}
