import render from './render'

export default {
  name: 'Panel',

  props: {
    value: {
      type: Object,
      default: () => ({})
    },

    sensitivity: {
      type: Boolean,
      default: false
    }
  },

  render
}
