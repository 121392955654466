import { buttonModes, services } from '@/utils'
import { invoiceStatues, statusColors, statusIcons } from '@/components/services/invoices/utils'

import button from '@/components/button'

function renderButton(h) {
  return h(
    button,
    {
      props: {
        mode: buttonModes.flat,
        icon: statusIcons[this.status],
        color: statusColors[this.status],
        loading: this.loading[this.status],
        disabled: this.value.status !== invoiceStatues.pending || this.isLoading,
        tooltip: this.getTranslate(`${services.invoices}.tooltips.${this.status}`)
      },
      on: {
        click: () => {
          this.update()
        }
      }
    }
  )
}

function renderListItem(h) {
  return h(
    'g-list-item',
    {
      props: {
        label: this.getTranslate(`${services.invoices}.labels.${this.status}`),
        prefix: statusIcons[this.status],
        iconColor: statusColors[this.status],
        dense: true,
        disabled: this.value.status !== invoiceStatues.pending || this.isLoading
      },
      on: {
        click: () => {
          this.update()
        }
      }
    }
  )
}

export default function(h) {
  if (this.viewport.breakpoint.lgUp || this.view === 'form') {
    return renderButton.call(this, h)
  } else {
    return renderListItem.call(this, h)
  }
}
